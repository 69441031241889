import React, { useEffect, useState } from 'react';
import { FEATURE_IMAGE_4_URL, SERVER_URL } from '../../../Constants';
import { Button } from 'Views/Components/Button/Button';
import useStore from 'Hooks/useStore';
import { logErrorOnServer } from '../../../Services/Api/_HumanWritten/ClientsideErrorLoggingService';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../Util/TypeGuards';
import If from '../If/If';
import { getContactUsEmailAddress } from '../../../Services/Api/_HumanWritten/EmailTemplateEntityService';

export interface ErrorProps {
	error: Error | null,
}

export function SomethingWentWrong({
	error,
}: ErrorProps) {
	const store = useStore();
	const [contactUsEmailAddress, setContactUsEmailAddress] = useState('');

	useEffect(() => {
		if (isNotNullOrUndefined(error)) {
			logErrorOnServer({
				loggedInUserId: store.userId,
				currentLocation: document.location.href.toString(),
				previousLocation: document.referrer,
				message: error.message,
				stack: error.stack,
			}).then(() => {
				// Do nothing (the error will be logged in the server)
			});
		}
	}, [error]);

	useEffect(() => {
		getContactUsEmailAddress().then(x => {
			setContactUsEmailAddress(x);
		});
	}, []);

	return (
		<>
			<div className="something-went-wrong-page-container">
				<div className="background-container">
					<img className="something-went-wrong-page-background" src={FEATURE_IMAGE_4_URL} alt="bg-img" />
				</div>
				<div className="something-went-wrong-page-elements__container">
					<div className="something-went-wrong-page-elements">
						<div className="something-went-wrong-modal-content">
							<h1 className="title">Whoops</h1>
							<h3 className="subtitle">Something went wrong on our end</h3>
							{/* eslint-disable-next-line max-len */}
							<p className="details">We have documented this matter with our IT department and are
								actively working to resolve it in a timely manner.
							</p>
							{/* eslint-disable-next-line max-len */}
							<p className="details">If this error persists,
								<If condition={!!(window as any).Intercom}>
									<span> </span>
									<span
										className="live-chat__div"
										onKeyDown={() => {
											(window as any).Intercom('show');
										}}
										role="button"
										tabIndex={0}
										onClick={() => {
											(window as any).Intercom('show');
										}}
									>message us through live chat
									</span>.
								</If>
								<If condition={isNullOrUndefined((window as any).Intercom)}> email us at <a href={`mailto:${contactUsEmailAddress}`}>{contactUsEmailAddress}</a> and
									we will assist you further.
								</If>
							</p>
							<p className="details">We apologise for the inconvenience.</p>
						</div>
						<Button onClick={() => {
							document.location.assign(`${SERVER_URL}/home`);
						}}
						>
							Go to home page
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
