import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	EventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';

/**
 * Returns the booking wizard data from the cache using the tokenId
 */
export async function getStoredWizardData(tokenId: string): Promise<BookingWizardData> {
	return axios.get<BookingWizardData>(
		`${SERVER_URL}/api/booking-wizard-cache/get-stored-wizard-data/${tokenId}`,
	).then(x => x.data);
}

/**
 * Returns the booking wizard data from the cache using the tokenId
 */
export async function getStoredEventWizardData(tokenId: string): Promise<EventBookingWizardData> {
	const res = axios.get<EventBookingWizardData>(
		`${SERVER_URL}/api/booking-wizard-cache/get-stored-event-wizard-data/${tokenId}`,
	).then(x => x.data);
	return res;
}

/**
 * Returns a token Id that can be used to access the cached booking wizard data.
 */
export async function setStoredWizardData(bookingWizardData: BookingWizardData): Promise<string> {
	return axios.post<string>(
		`${SERVER_URL}/api/booking-wizard-cache/set-stored-wizard-data`,
		{ data: JSON.stringify(bookingWizardData) },
	).then(x => x.data);
}

/**
 * Returns a token Id that can be used to access the cached booking wizard data.
 */
export async function setStoredEventWizardData(bookingWizardData: EventBookingWizardData): Promise<string> {
	return axios.post<string>(
		`${SERVER_URL}/api/booking-wizard-cache/set-stored-event-wizard-data`,
		{ data: JSON.stringify(bookingWizardData) },
	).then(x => x.data);
}
