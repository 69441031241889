import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import classNames from 'classnames';
import { IssueIcon } from 'Views/Components/_HumanWritten/Icon/IssueIcon';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export interface CheckInOrUndoButtonProps {
	className?: string;
	/**
	 * True displays ticket check in icon, false displays undo icon.
	 */
	forCheckIn: boolean;
	/**
	 * id helps with test selectors i.e. action-btn-{id}
	 */
	id?: string;
	/**
	 * True only shows icon, false displays text with icon.
	 */
	iconOnly?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function CheckInOrUndoButton({
	forCheckIn,
	id,
	className,
	iconOnly = false,
	onClick,
}: CheckInOrUndoButtonProps) {
	return (
		<Button
			buttonProps={{ id: `action-btn-${id}` }}
			className={classNames(className)}
			onClick={onClick}
		>
			{
				forCheckIn
					? (
						<>
							<IssueIcon />
							{!iconOnly && 'Check in'}
						</>
					)
					: (
						<>
							<Icon name="undo" />
							{!iconOnly && 'Undo'}
						</>
					)
			}
		</Button>
	);
}
