import classNames from 'classnames';
import * as React from 'react';

export interface SideBarProps {
	children: React.ReactNode;
	className?: string;
	onClose: () => void;
	interceptorBtnProps?: React.ButtonHTMLAttributes<Element>;
}

export function SideBar({
	children,
	className,
	onClose,
	interceptorBtnProps = {},
}: SideBarProps) {
	return (
		<div className="sidebar">
			<button
				className="sidebar-click-interceptor"
				aria-label="Close modal"
				onClick={onClose}
				onKeyDown={onClose}
				{...interceptorBtnProps}
			/>
			<div className={classNames('sidebar-info', className)}>
				{children}
			</div>
		</div>
	);
}
