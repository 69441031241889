/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsCargoTypeEntity from 'Models/Security/Acl/VisitorsCargoTypeEntity';
import UserCargoTypeEntity from 'Models/Security/Acl/UserCargoTypeEntity';
import StaffCargoTypeEntity from 'Models/Security/Acl/StaffCargoTypeEntity';
import ManagerCargoTypeEntity from 'Models/Security/Acl/ManagerCargoTypeEntity';
import AdminCargoTypeEntity from 'Models/Security/Acl/AdminCargoTypeEntity';
import InvoicedUserCargoTypeEntity from 'Models/Security/Acl/InvoicedUserCargoTypeEntity';
import FixedPriceUserCargoTypeEntity from 'Models/Security/Acl/FixedPriceUserCargoTypeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getMeasurementLabelFromId } from 'Util/_HumanWritten/MeasurementUtils';
// % protected region % [Add any further imports here] end

export interface ICargoTypeEntityAttributes extends IModelAttributes {
	cargoMake: string;
	cargoModel: string;
	disabled: boolean;
	staffOnly: boolean;

	minimumHeightId?: string;
	minimumHeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	minimumLengthId: string;
	minimumLength: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	minimumWeightId: string;
	minimumWeight: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	cargos: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	waitlistItems: Array<
		| Models.WaitlistItemEntity
		| Models.IWaitlistItemEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CargoTypeEntity', 'Cargo Type')
// % protected region % [Customise your entity metadata here] end
export default class CargoTypeEntity extends Model
	implements ICargoTypeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCargoTypeEntity(),
		new UserCargoTypeEntity(),
		new StaffCargoTypeEntity(),
		new ManagerCargoTypeEntity(),
		new AdminCargoTypeEntity(),
		new InvoicedUserCargoTypeEntity(),
		new FixedPriceUserCargoTypeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Make'] on begin
	@Validators.Required()
	@observable
	@attribute<CargoTypeEntity, string>()
	@CRUD({
		name: 'Cargo make',
		description: 'The make of the vehicle or cargo. E.g. Toyota.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cargoMake: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Make'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Model'] on begin
	@Validators.Required()
	@observable
	@attribute<CargoTypeEntity, string>()
	@CRUD({
		name: 'Cargo model',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cargoModel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Model'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	/**
	 * Whether or not the vehicle type is disabled in the application.
	 */
	@observable
	@attribute<CargoTypeEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		description: 'If checked, this vehicle type will not appear as an option on the frontend of the application.',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Staff Only'] on begin
	/**
	 * Whether or not the item is for only staff to see.
	 */
	@observable
	@attribute<CargoTypeEntity, boolean>()
	@CRUD({
		name: 'Staff only',
		description: 'If checked, only staff will be able to see this option on the frontend.',
		displayType: 'hidden',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public staffOnly: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Staff Only'] end

	/**
	 * The minimum height required for this cargo item.
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Height'] on begin
		name: 'Minimum height',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Height'] end
	})
	public minimumHeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumHeight: Models.MeasurementEntity;

	/**
	 * The minimum length required for this cargo item.
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Length'] on begin
		name: 'Minimum length',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Length'] end
	})
	public minimumLengthId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumLength: Models.MeasurementEntity;

	/**
	 * The minimum weight required for this cargo item.
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Weight'] on begin
		name: 'Minimum weight',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Weight'] end
	})
	public minimumWeightId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumWeight: Models.MeasurementEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] on begin
		name: 'Cargos',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.CargoEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargos',
			oppositeEntity: () => Models.CargoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] end
	})
	public cargos: Models.CargoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] on begin
		name: 'Waitlist items',
		displayType: 'hidden',
		order: 90,
		referenceTypeFunc: () => Models.WaitlistItemEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'waitlistItems',
			oppositeEntity: () => Models.WaitlistItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] end
	})
	public waitlistItems: Models.WaitlistItemEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICargoTypeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICargoTypeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.cargoMake !== undefined) {
				this.cargoMake = attributes.cargoMake;
			}
			if (attributes.cargoModel !== undefined) {
				this.cargoModel = attributes.cargoModel;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.staffOnly !== undefined) {
				this.staffOnly = attributes.staffOnly;
			}
			if (attributes.minimumHeightId !== undefined) {
				this.minimumHeightId = attributes.minimumHeightId;
			}
			if (attributes.minimumHeight !== undefined) {
				if (attributes.minimumHeight === null) {
					this.minimumHeight = attributes.minimumHeight;
				} else if (attributes.minimumHeight instanceof Models.MeasurementEntity) {
					this.minimumHeight = attributes.minimumHeight;
					this.minimumHeightId = attributes.minimumHeight.id;
				} else {
					this.minimumHeight = new Models.MeasurementEntity(attributes.minimumHeight);
					this.minimumHeightId = this.minimumHeight.id;
				}
			}
			if (attributes.minimumLengthId !== undefined) {
				this.minimumLengthId = attributes.minimumLengthId;
			}
			if (attributes.minimumLength !== undefined) {
				if (attributes.minimumLength === null) {
					this.minimumLength = attributes.minimumLength;
				} else if (attributes.minimumLength instanceof Models.MeasurementEntity) {
					this.minimumLength = attributes.minimumLength;
					this.minimumLengthId = attributes.minimumLength.id;
				} else {
					this.minimumLength = new Models.MeasurementEntity(attributes.minimumLength);
					this.minimumLengthId = this.minimumLength.id;
				}
			}
			if (attributes.minimumWeightId !== undefined) {
				this.minimumWeightId = attributes.minimumWeightId;
			}
			if (attributes.minimumWeight !== undefined) {
				if (attributes.minimumWeight === null) {
					this.minimumWeight = attributes.minimumWeight;
				} else if (attributes.minimumWeight instanceof Models.MeasurementEntity) {
					this.minimumWeight = attributes.minimumWeight;
					this.minimumWeightId = attributes.minimumWeight.id;
				} else {
					this.minimumWeight = new Models.MeasurementEntity(attributes.minimumWeight);
					this.minimumWeightId = this.minimumWeight.id;
				}
			}
			if (attributes.cargos !== undefined && Array.isArray(attributes.cargos)) {
				for (const model of attributes.cargos) {
					if (model instanceof Models.CargoEntity) {
						this.cargos.push(model);
					} else {
						this.cargos.push(new Models.CargoEntity(model));
					}
				}
			}
			if (attributes.waitlistItems !== undefined && Array.isArray(attributes.waitlistItems)) {
				for (const model of attributes.waitlistItems) {
					if (model instanceof Models.WaitlistItemEntity) {
						this.waitlistItems.push(model);
					} else {
						this.waitlistItems.push(new Models.WaitlistItemEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		minimumHeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		minimumLength {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		minimumWeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		cargos {
			${Models.CargoEntity.getAttributes().join('\n')}
		}
		waitlistItems {
			${Models.WaitlistItemEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			cargos: {},
			waitlistItems: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'waitlistItems',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.cargoMake}, ${this.cargoModel}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	/**
	 * Make Model
	 * Example: `Toyota Hilux` or `Toyota Hilx (Up to 5m)`
	 */
	public getFormattedVehicleMakeModel(measurementId?: string) {
		let result = `${this?.cargoMake} ${this?.cargoModel}`;
		if (measurementId) {
			result += this.getLengthLabelInBrackets(measurementId);
		}
		return result;
	}

	/**
	 * Example: `(Up to 5m)`
	 */
	public getLengthLabelInBrackets(measurementId: string) {
		return ` (${getMeasurementLabelFromId(measurementId)})`;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CargoTypeEntity.prototype, 'created');
CRUD(modifiedAttr)(CargoTypeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
