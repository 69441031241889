import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { GiftCertificateEntity } from 'Models/Entities';
import { formatAsISO } from 'Util/_HumanWritten/DateUtils';
import {
	AddUpdateVoucherDto,
} from 'Views/Components/_HumanWritten/AccountDetails/VoucherSection/GiftVouchersUtils';
import { buildUrl } from 'Util/FetchUtils';

const baseUrl = `${SERVER_URL}/api/entity/GiftCertificateEntity`;

export async function addGiftCertificateToTransaction(transactionId: string, code: string, eventBooking: boolean) {
	return axios.post(`${baseUrl}/add-to-transaction?transactionId=${transactionId}&code=${code}&eventBooking=${eventBooking}`, {
		validateStatus: () => true,
	});
}

/**
 * Removes a gift certificate from a transaction. Determines which gift certificate based on the supplied code and
 * determine the transaction based on the transaction id.
 *
 * Returns: The used value of the gift certificate which was removed from the transaction. If this did not occur then
 * the result will be 0.
 */
export async function removeGiftCertificateFromTransaction(transactionId: string, usageId: string, eventBooking: boolean) {
	return axios.post(`${baseUrl}/remove-from-transaction?transactionId=${transactionId}&usageId=${usageId}&eventBooking=${eventBooking}`, {
		validateStatus: () => true,
	});
}

export function getUsersGiftVouchers(userId: string, skip: number, take: number, showExpiredOrUsed: boolean) {
	return axios.get(
		buildUrl(`${baseUrl}/get-users-gift-vouchers`,
			{
				userId: userId,
				skip: skip.toString(),
				take: take.toString(),
				showInvalid: showExpiredOrUsed ? 'true' : 'false',
			}),
	).then(x => x.data);
}

export function createNewGiftVoucher(voucherDto: AddUpdateVoucherDto) {
	// Ensure we have access to date class methods
	const date = new Date(voucherDto.expiryDate);

	// Ensure the correct date format is provided to the endpoint
	const expiryDate = formatAsISO(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

	const body = { ...voucherDto, expiryDate };

	return axios.post<GiftCertificateEntity>(`${baseUrl}/create-new-gift-voucher`, body, {
		validateStatus: () => true,
	});
}

export function updateGiftVoucher(voucherDto: AddUpdateVoucherDto) {
	// Ensure we have access to date class methods
	const date = new Date(voucherDto.expiryDate);

	// Ensure the correct date format is provided to the endpoint
	const expiryDate = formatAsISO(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

	const body = { ...voucherDto, expiryDate };

	return axios.post(`${baseUrl}/update-gift-voucher`, body, {
		validateStatus: () => true,
	});
}
