import axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import { BOOKING_ENTITY_URL } from 'Constants';
import useAsync from '../../../../Hooks/useAsync';

const ferryBookingTransactionIdStorageKey = 'ferry-booking-transaction-id-key';
const eventBookingTransactionIdStorageKey = 'event-booking-transaction-id-key';

export function getFerryBookingTransactionIdFromStorage(): string | null {
	return sessionStorage?.getItem(ferryBookingTransactionIdStorageKey);
}

export function setFerryBookingTransactionIdInStorage(transactionId: string) {
	sessionStorage.setItem(
		ferryBookingTransactionIdStorageKey,
		transactionId,
	);
}

export function clearFerryBookingTransactionIdFromStorage() {
	sessionStorage.removeItem(ferryBookingTransactionIdStorageKey);
}

export function getEventBookingTransactionIdFromStorage(): string | null {
	return sessionStorage?.getItem(eventBookingTransactionIdStorageKey);
}

export function setEventBookingTransactionIdInStorage(transactionId: string) {
	sessionStorage.setItem(
		eventBookingTransactionIdStorageKey,
		transactionId,
	);
}

export function clearEventBookingTransactionIdFromStorage() {
	sessionStorage.removeItem(eventBookingTransactionIdStorageKey);
}

export function markBookingAsInvoiced(bookingId: string) {
	return axios.get(
		buildUrl(`${BOOKING_ENTITY_URL}/mark-booking-invoiced`, { bookingId }),
	);
}

export function undoBookingInvoiced(bookingId: string) {
	return axios.get(
		buildUrl(`${BOOKING_ENTITY_URL}/undo-booking-invoiced`, { bookingId }),
	);
}

export function forInvoicing(bookingId?: string) {
	if (!bookingId) {
		return;
	}
	const url = buildUrl(`${BOOKING_ENTITY_URL}/for-invoicing`, { id: bookingId });
	const result = axios.get(url).then(x => x.data);
	return result;
}

export function useForInvoicingAsync(bookingId?: string) {
	return useAsync<boolean>(async () => forInvoicing(bookingId), [bookingId]);
}
