import { useEffect, useRef } from 'react';

/**
 * Handle continuous execution with start and stop controls, with auto clean up when component unmounts.
 * @param execute The function to perform every interval.
 * @param delay The duration between executions (in milliseconds).
 * @returns Start and stop controls.
 */
export const useInterval = (execute: () => void, delay: number) => {
	const interval = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		// Clean up
		return () => clearInterval(interval.current);
	}, []);

	return {
		start() {
			clearInterval(interval.current);
			interval.current = setInterval(execute, delay);
		},
		stop() {
			clearInterval(interval.current);
		},
	};
};
