import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FerryTripEntity } from 'Models/Entities';
import { FetchFilteredTrips } from 'Util/_HumanWritten/FerryTrips/FetchFilteredTrips';
import { createDefaultFerryScheduleModel } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleHelpers';
import FerryScheduleInnerWrap from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleInnerWrap';
import { FerryScheduleModel } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleModel';
import { store } from 'Models/Store';
import locationAndRoutesStore from 'Models/LocationAndRoutesStore';

function FerryScheduleOuterWrap() {
	const model = useLocalStore<FerryScheduleModel>(() => createDefaultFerryScheduleModel());
	const tripToEdit = useRef<FerryTripEntity>(new FerryTripEntity());
	// If null, then bulk booking is inactive. An array here means we are currently in bulk booking mode
	const [bulkBookingTrips, setBulkBookingTrips] = useState<null | string[]>(null);
	const [locations, setLocations] = useState<any>(null);
	const [ferryTrips, setFerryTrips] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (locationAndRoutesStore.locations !== null
			&& locationAndRoutesStore.locations.length > 0
			&& (locations === null || locations.length < 1)) {
			setLocations(locationAndRoutesStore.locations);
		}
	}, [locations, locationAndRoutesStore.locations]);

	useEffect(() => {
		fetchFerryTrips();
	}, [model.fromLocation, model.toLocation, model.date, model.numOfDays, model.route.id, store.userId]);

	const fetchFerryTrips = async () => {
		setFerryTrips(undefined);
		setLoading(true);
		try {
			if (model.fromLocation !== '' && model.toLocation !== '') {
				const ferryTripsData = await FetchFilteredTrips(
					model.date as Date,
					model.numOfDays as number,
					model.route.id as string,
					store.userId as string,
				);
				setFerryTrips(ferryTripsData);
			}
			setLoading(false);
		} catch (error) {
			console.error('Error fetching ferry trips:', error);
			setFerryTrips(null);
			setLoading(false);
		}
	};

	return (
		<FerryScheduleInnerWrap
			model={model}
			ferryTrips={ferryTrips}
			locations={locations}
			loading={loading}
			bulkBookingTrips={bulkBookingTrips}
			setBulkBookingTrips={setBulkBookingTrips}
			tripToEdit={tripToEdit}
			refresh={fetchFerryTrips}
		/>
	);
}

export default observer(FerryScheduleOuterWrap);
