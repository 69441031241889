/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable max-len */
import * as React from 'react';
import { action } from 'mobx';
import * as AdminPages from './Pages/Admin/Entity';
import Auth from './Components/Auth/Auth';
import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from './Components/Topbar/Topbar';
import PageLinks from './Pages/Admin/PageLinks';
import StyleguidePage from './Pages/Admin/StyleguidePage';
import Spinner from 'Views/Components/Spinner/Spinner';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import { ADMIN_PAGE_TITLE } from '../ConstantPageNames';

export const EmailBuilderPage = React.lazy(() => import('../modules/emailBuilder/EmailBuilderPage'));
// % protected region % [Add any extra imports here] end

// % protected region % [Customize lazy imports here] off begin
const GraphiQlLazy = React.lazy(() => import('./Pages/Admin/Graphiql'));
// % protected region % [Customize lazy imports here] end

export default class Admin extends React.Component<RouteComponentProps> {
	path = this.props.match.path === '/' ? '' : this.props.match.path;

	// % protected region % [Override componentDidMount here] on begin
	public componentDidMount() {
		this.setAppLocation();
		document.title = ADMIN_PAGE_TITLE;
	}
	// % protected region % [Override componentDidMount here] end

	// % protected region % [Override setAppLocation here] off begin
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}
	// % protected region % [Override setAppLocation here] end

	private adminSwitch = () => {
		// % protected region % [Override access control logic here] off begin
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Redirect to="/404" />;
		}
		// % protected region % [Override access control logic here] end

		return (
			<>
				{
				// % protected region % [Override contents here] off begin
				}
				<PageLinks {...this.props} />
				{
				// % protected region % [Override contents here] end
				}
				<div className="body-content">
					<Switch>
						{/* These routes require a login to view */}

						{/* Admin entity pages */}
						<Route exact path={`${this.path}`} component={AdminPage} />
						<Route path={`${this.path}/User`} component={AllUsersPage} />
						<Route path={`${this.path}/AddOnsPriceEntity`} component={AdminPages.AddOnsPriceEntityPage} />
						<Route path={`${this.path}/AdditionalBookingOptionEntity`} component={AdminPages.AdditionalBookingOptionEntityPage} />
						<Route path={`${this.path}/AdditionalBookingSectionEntity`} component={AdminPages.AdditionalBookingSectionEntityPage} />
						<Route path={`${this.path}/AdminEntity`} component={AdminPages.AdminEntityPage} />
						<Route path={`${this.path}/AlterationEntity`} component={AdminPages.AlterationEntityPage} />
						<Route path={`${this.path}/BackgroundJobConfigEntity`} component={AdminPages.BackgroundJobConfigEntityPage} />
						<Route path={`${this.path}/BookingCutoffEntity`} component={AdminPages.BookingCutoffEntityPage} />
						<Route path={`${this.path}/BookingEntity`} component={AdminPages.BookingEntityPage} />
						<Route path={`${this.path}/CargoDetailEntity`} component={AdminPages.CargoDetailEntityPage} />
						<Route path={`${this.path}/CargoEntity`} component={AdminPages.CargoEntityPage} />
						<Route path={`${this.path}/CargoTypeEntity`} component={AdminPages.CargoTypeEntityPage} />
						<Route path={`${this.path}/CommunicationsRecordEntity`} component={AdminPages.CommunicationsRecordEntityPage} />
						<Route path={`${this.path}/ConfigGeneralEntity`} component={AdminPages.ConfigGeneralEntityPage} />
						<Route path={`${this.path}/ConfigThemeEntity`} component={AdminPages.ConfigThemeEntityPage} />
						<Route path={`${this.path}/CustomTicketTypeEntity`} component={AdminPages.CustomTicketTypeEntityPage} />
						<Route path={`${this.path}/DefaultPricingEntity`} component={AdminPages.DefaultPricingEntityPage} />
						<Route path={`${this.path}/DiscountEntity`} component={AdminPages.DiscountEntityPage} />
						<Route path={`${this.path}/DynamicPriceEntity`} component={AdminPages.DynamicPriceEntityPage} />
						<Route path={`${this.path}/EmailComponentEntity`} component={AdminPages.EmailComponentEntityPage} />
						<Route path={`${this.path}/EmailTemplateEntity`} component={AdminPages.EmailTemplateEntityPage} />
						<Route path={`${this.path}/EventBookingDetailEntity`} component={AdminPages.EventBookingDetailEntityPage} />
						<Route path={`${this.path}/EventDetailEntity`} component={AdminPages.EventDetailEntityPage} />
						<Route path={`${this.path}/EventLabelEntity`} component={AdminPages.EventLabelEntityPage} />
						<Route path={`${this.path}/FeeEntity`} component={AdminPages.FeeEntityPage} />
						<Route path={`${this.path}/FeeOptionEntity`} component={AdminPages.FeeOptionEntityPage} />
						<Route path={`${this.path}/FerryEntity`} component={AdminPages.FerryEntityPage} />
						<Route path={`${this.path}/FerryTripEntity`} component={AdminPages.FerryTripEntityPage} />
						<Route path={`${this.path}/FixedPriceUserEntity`} component={AdminPages.FixedPriceUserEntityPage} />
						<Route path={`${this.path}/GiftCertificateEntity`} component={AdminPages.GiftCertificateEntityPage} />
						<Route path={`${this.path}/GiftCertificateUsageEntity`} component={AdminPages.GiftCertificateUsageEntityPage} />
						<Route path={`${this.path}/HazardousFerryRuleEntity`} component={AdminPages.HazardousFerryRuleEntityPage} />
						<Route path={`${this.path}/InvoicedUserEntity`} component={AdminPages.InvoicedUserEntityPage} />
						<Route path={`${this.path}/LanguageEntity`} component={AdminPages.LanguageEntityPage} />
						<Route path={`${this.path}/LocationEntity`} component={AdminPages.LocationEntityPage} />
						<Route path={`${this.path}/ManagerBookingDiscountEntity`} component={AdminPages.ManagerBookingDiscountEntityPage} />
						<Route path={`${this.path}/ManagerBookingDiscountUsagesEntity`} component={AdminPages.ManagerBookingDiscountUsagesEntityPage} />
						<Route path={`${this.path}/ManagerEntity`} component={AdminPages.ManagerEntityPage} />
						<Route path={`${this.path}/MeasurementEntity`} component={AdminPages.MeasurementEntityPage} />
						<Route path={`${this.path}/NotesEntity`} component={AdminPages.NotesEntityPage} />
						<Route path={`${this.path}/PassengerDetailEntity`} component={AdminPages.PassengerDetailEntityPage} />
						<Route path={`${this.path}/PassengerTypeEntity`} component={AdminPages.PassengerTypeEntityPage} />
						<Route path={`${this.path}/PopupEntity`} component={AdminPages.PopupEntityPage} />
						<Route path={`${this.path}/PriceEntity`} component={AdminPages.PriceEntityPage} />
						<Route path={`${this.path}/RouteEntity`} component={AdminPages.RouteEntityPage} />
						<Route path={`${this.path}/SmsConfigEntity`} component={AdminPages.SmsConfigEntityPage} />
						<Route path={`${this.path}/SmsDeliveryRecordEntity`} component={AdminPages.SmsDeliveryRecordEntityPage} />
						<Route path={`${this.path}/SmsTemplateEntity`} component={AdminPages.SmsTemplateEntityPage} />
						<Route path={`${this.path}/SpacingAddOnEntity`} component={AdminPages.SpacingAddOnEntityPage} />
						<Route path={`${this.path}/StaffEntity`} component={AdminPages.StaffEntityPage} />
						<Route path={`${this.path}/TicketEntity`} component={AdminPages.TicketEntityPage} />
						<Route path={`${this.path}/TowOnDetailEntity`} component={AdminPages.TowOnDetailEntityPage} />
						<Route path={`${this.path}/TowOnEntity`} component={AdminPages.TowOnEntityPage} />
						<Route path={`${this.path}/TowOnTypeEntity`} component={AdminPages.TowOnTypeEntityPage} />
						<Route path={`${this.path}/TransactionEntity`} component={AdminPages.TransactionEntityPage} />
						<Route path={`${this.path}/UploadedImageEntity`} component={AdminPages.UploadedImageEntityPage} />
						<Route path={`${this.path}/UserDisplayNameEntity`} component={AdminPages.UserDisplayNameEntityPage} />
						<Route path={`${this.path}/UserEntity`} component={AdminPages.UserEntityPage} />
						<Route path={`${this.path}/WaitlistItemEntity`} component={AdminPages.WaitlistItemEntityPage} />

						{
						// % protected region % [Add any extra page routes here] on begin
						}
						<Route path={`${this.path}/invoiced-user/:mode/:userId?`} component={AdminPages.InvoicedUserCreationPage} />
						<Route path={`${this.path}/email-builder`}>
							<React.Suspense fallback={<Spinner />}>
								<EmailBuilderPage />
							</React.Suspense>
						</Route>
						{
						// % protected region % [Add any extra page routes here] end
						}
					</Switch>
				</div>
				{
				// % protected region % [Add any admin footer content here] off begin
				}
				{
				// % protected region % [Add any admin footer content here] end
				}
			</>
		);
	}

	public render() {
		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] off begin
					}
					<Topbar currentLocation="admin" />
					{
					// % protected region % [Modify Topbar] end
					}
					<Switch>
						<Route path={`${this.path}/styleguide`}>
							<Auth {...this.props}>
								<div className="admin">
									<PageLinks {...this.props} />
								</div>
								<div className="frontend">
									<div className="body-content">
										<StyleguidePage {...this.props} />
									</div>
								</div>
							</Auth>
						</Route>
						<Route>
							<div className="admin">
								<Auth {...this.props}>
									<Switch>
										{
										// % protected region % [Modify top level admin routing here] off begin
										}
										<Route path={`${this.path}/graphiql`}>
											<React.Suspense fallback={<Spinner />}>
												<GraphiQlLazy />
											</React.Suspense>
										</Route>
										<Route component={this.adminSwitch} />
										{
										// % protected region % [Modify top level admin routing here] end
										}
									</Switch>
								</Auth>
							</div>
						</Route>
					</Switch>
				</div>
			</>
		);
	}
}
