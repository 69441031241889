import * as React from 'react';
import { Flex } from 'Views/Components/Flex/Flex';
import { Text, TextColor } from 'Views/Components/Text/Text';

export interface CheckInListRowDetailProps {
	label: React.ReactNode;
	value?: number;
	className?: string;
	color?: TextColor;
}

export function CheckInListRowDetail(props: CheckInListRowDetailProps) {
	const {
		label,
		value,
		className,
		color,
	} = props;

	return (
		<Flex className={className}>
			<Text size="sm" color={color ?? 'gray'} className="row-detail-label">
				{label}
			</Text>
			<Text
				size="sm"
				weight="600"
				wordBreak="keep-all"
				className="ml-xxs row-detail-value"
			>
				{value ?? '?'}
			</Text>
		</Flex>
	);
}
