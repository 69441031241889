import * as React from 'react';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import ReportTableLineItem, { ReportTableItemProps } from 'Views/Components/_HumanWritten/Reports/ReportTableLineItem';

export interface ReportTableProps {
	listOfReports: ReportTableItemProps[],
}

function ReportsTable({
	listOfReports,
}: ReportTableProps) {
	const reports: React.ReactNode[] = [];

	if (listOfReports !== null) {
		listOfReports.forEach(report => {
			reports.push(
				<ReportTableLineItem
					reportTitle={report.reportTitle}
					selectedReportType={report.selectedReportType}
					id={report.id}
					key={report.id}
				/>,
			);
		});
	}

	return (
		<>
			<If condition={listOfReports === null}>
				<LottieSpinner />
			</If>
			<If condition={listOfReports !== null}>
				<div className="reports__table">
					<table>
						<thead>
							<tr>
								<th>
									Type
								</th>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<th className="export-button__header" />
							</tr>
						</thead>
						<tbody>
							{reports}
						</tbody>
					</table>
				</div>
			</If>
		</>
	);
}

export default ReportsTable;
