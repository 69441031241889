import { getFerryTripExpandString } from '../FerryTrips/FetchTripsUtils';
import {
	AdditionalBookingOptionEntity,
	AlterationEntity, BookingEntity,
	CargoDetailEntity,
	CargoEntity,
	CargoTypeEntity,
	FeeEntity,
	ManagerBookingDiscountEntity,
	ManagerBookingDiscountUsagesEntity,
	PassengerDetailEntity, PassengerDetailTicket, PassengerTypeEntity,
	TicketEntity,
	TowOnDetailEntity,
	TowOnEntity,
	TowOnTypeEntity,
	TransactionEntity,
} from 'Models/Entities';
import {
	getAdditionalBookingOptionsAttributes,
	getBookedSummaryAttributes,
	getBookingSummariesAttributes,
	getFerryTripAttributes,
} from 'Services/CustomGql/BookingSummaryType';
import { stringIsEmpty } from '../../TypeGuards';

export enum BookingOrderByOption {
	NAME,
	EMAIL,
	BOOKING_ID,
	TRIP_DATE,
	CREATED_DATE,
	AMOUNT,
	STATUS,
}

export function getDynamicPricingAttributes(userId: string | null = null) {
	const userIdToUse = stringIsEmpty(userId) ? null : `"${userId}"`;
	return `
		dynamicAdultPassengerPrice (userId: ${userIdToUse})
		dynamicChildPassengerPrice (userId: ${userIdToUse})
		dynamicInfantPassengerPrice (userId: ${userIdToUse})
		dynamicPriceD (userId: ${userIdToUse})
		dynamicPriceE (userId: ${userIdToUse})
		dynamicPriceF (userId: ${userIdToUse})
		dynamicPriceG (userId: ${userIdToUse})
		dynamicPriceH (userId: ${userIdToUse})
		dynamicMeasurementPrices (userId: ${userIdToUse}) {
			measurementId
			amount
			measurementType
			measurementSpace
		}
	`;
}

export function getDefaultBookingExpands(userId: string | null, fetchBookingSummary: boolean = true) {
	return `
		id
		${BookingEntity.getAttributes().join('\n')}
		isEventBooking
		user {
			id
			email
			firstName
			lastName
			phone
			userDisplayName {
				invoicedUser
			}
		}
		alterations {
			${AlterationEntity.getAttributes().join('\n')}
			transaction {
				${TransactionEntity.getAttributes().join('\n')}
			}
			managerBookingDiscounts {
				${ManagerBookingDiscountEntity.getAttributes().join('\n')}
			}
			managerBookingDiscountUsages {
				${ManagerBookingDiscountUsagesEntity.getAttributes().join('\n')}
			}
			fees {
				${FeeEntity.getAttributes().join('\n')}
			}
			ferryTrip {
				${getFerryTripExpandString(null, userId)}
			}
			tickets {
				id
				${TicketEntity.getAttributes().join('\n')}
				additionalBookingOption {
					${AdditionalBookingOptionEntity.getAttributes().join('\n')}
				}
				passengerDetails {
					${PassengerDetailTicket.getAttributes().join('\n')}
					passengerDetail {
						${PassengerDetailEntity.getAttributes().join('\n')}
						passengerType {
							${PassengerTypeEntity.getAttributes().join('\n')}
						}
					}
				}
				cargoDetail {
					${CargoDetailEntity.getAttributes().join('\n')}
					cargo {
						${CargoEntity.getAttributes().join('\n')}
						cargoType {
							${CargoTypeEntity.getAttributes().join('\n')}
						}
					}
				}
				towOnDetail {
					${TowOnDetailEntity.getAttributes().join('\n')}
					towOn {
						${TowOnEntity.getAttributes().join('\n')}
						towOnType {
							${TowOnTypeEntity.getAttributes().join('\n')}
						}
					}
				}
			}
		}
		bookedSummary {
			${getBookedSummaryAttributes(false, userId)}
			${getFerryTripAttributes(userId)}
			${getAdditionalBookingOptionsAttributes(userId)}
		}
		${fetchBookingSummary ? getBookingSummary(userId) : ''}
		cancellationFee
		cancellationCutoffHours
		alterationFee
	`;
}

export function getBookingSummary(userId: string | null) {
	return `bookingSummaries {
		${getBookingSummariesAttributes(userId)}
	}`;
}
export function getBookingExpandString(
	queryId?: string,
	excludeReturnBookings?: boolean,
	userId: string | null = null,
	includeBookingSummaries: boolean = true,
): string {
	const currentTripExpands = `
		#${queryId ?? ''}
		${getDefaultBookingExpands(userId, includeBookingSummaries)}
	`;
	const returnBookingsExpands = `
		returnBookingFor {
			${getDefaultBookingExpands(userId, includeBookingSummaries)}
		}
		returnBooking {
			${getDefaultBookingExpands(userId, includeBookingSummaries)}
		}
	`;

	return `
		${currentTripExpands}
		${excludeReturnBookings ? '' : returnBookingsExpands}
	`;
}

export function getCartBookingsExpandString(
	queryId?: string,
	excludeReturnBookings?: boolean,
	userId: string | null = null,
	includeAlterations: boolean = true,
): string {
	const currentTripExpands = `
		#${queryId ?? ''}
		id
		humanReadableId
		created
		desynced
		bookingStatus
		transactionStatus
		isEventBooking
		user {
			id
			email
			userDisplayName {
				invoicedUser
			}
		}
		${includeAlterations ? getAlterationString(userId) : ''}
		bookingSummaries {
			${getBookingSummariesAttributes(userId)}
		}
		cancellationFee
		cancellationCutoffHours
		alterationFee
	`;
	const returnBookingsExpands = `
		returnBooking {
			id
			humanReadableId
			created
			bookingStatus
			transactionStatus
			isEventBooking
			user {
				id
				userDisplayName {
					invoicedUser
				}
			}
			${includeAlterations ? getAlterationString(userId) : ''}
			bookingSummaries {
				${getBookingSummariesAttributes(userId)}
			}
			cancellationFee
			cancellationCutoffHours
			alterationFee
		}
	`;

	return `
		${currentTripExpands}
		${excludeReturnBookings ? '' : returnBookingsExpands}
	`;
}

function getAlterationString(userId: string | null) {
	return `alterations {
			id
			${AlterationEntity.getAttributes().join('\n')}
			fees {
				${FeeEntity.getAttributes().join('\n')}
			}
			tickets {
				id
				${TicketEntity.getAttributes().join('\n')}
				additionalBookingOption {
					id
					${AdditionalBookingOptionEntity.getAttributes().join('\n')}
				}
				passengerDetails {
					${PassengerDetailTicket.getAttributes().join('\n')}
					passengerDetail {
						${PassengerDetailEntity.getAttributes().join('\n')}
						passengerType {
							${PassengerTypeEntity.getAttributes().join('\n')}
						}
					}
				}
				cargoDetail {
					id
					${CargoDetailEntity.getAttributes().join('\n')}
					cargo {
						id
						${CargoEntity.getAttributes().join('\n')}
						cargoType {
							id
							${CargoTypeEntity.getAttributes().join('\n')}
						}
					}
				}
				towOnDetail {
					id
					${TowOnDetailEntity.getAttributes().join('\n')}
					towOn {
						id
						${TowOnEntity.getAttributes().join('\n')}
						towOnType {
							id
							${TowOnTypeEntity.getAttributes().join('\n')}
						}
					}
				}
			}
			ferryTrip {
				${getFerryTripExpandString(null, userId)}
			}
		}`;
}
