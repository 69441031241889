import React from 'react';

interface ScannerSwitchButtonProps {
	children: React.ReactNode;
	onToggle(e?: React.MouseEvent<HTMLButtonElement>): void;
}

export default function ScannerSwitchButton({ children, onToggle }: ScannerSwitchButtonProps) {
	return (
		<div className="check-in-scanner__top-anchor">
			<button className="check-in-scanner__switch-btn" onClick={onToggle}>
				{children}
			</button>
		</div>
	);
}
