import axios from 'axios';
import { PRICING_API } from 'Constants';
import { FerryBookingCreationDto } from '../BookingService/FerryTripBookingService';
import { LinkedBookingPricingResult, TransactionPricingResult } from './PricingResult';
import { buildUrl } from 'Util/FetchUtils';

export interface BasePriceFerryTripsDto {
	bookingCreationDto: FerryBookingCreationDto,
	ferryTripIds: string[],
	departureBooking: boolean,
}

export async function FetchBasePriceForFerryTrips(
	request: BasePriceFerryTripsDto,
): Promise<{ [key: string]: number }> {
	try {
		const result = await axios.post<{ [key: string]: number }>(
			`${PRICING_API}/get-base-ticket-price`, request);
		return result.data;
	} catch (e) {
		console.error(e);
		return {};
	}
}

export async function FetchBookingPricing(
	request: FerryBookingCreationDto | null,
	isAlteration: boolean,
): Promise<TransactionPricingResult | null> {
	try {
		const result = await axios.post(
			`${PRICING_API}/get-booking-pricing`, request);
		const bookingPrices = result.data as LinkedBookingPricingResult[];
		return {
			linkedBookings: bookingPrices,
			forInvoicing: false, // isn't used in this fetch
			isAlteration: isAlteration,
			transactionId: '', // isn't used in this fetch
			totalPrice: bookingPrices.reduce((total, booking) => {
				return total + (booking.departureBooking?.totalPrice ?? 0) + (booking.returnBooking?.totalPrice ?? 0);
			}, 0),
		} as TransactionPricingResult;
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function FetchTransactionPricing(
	transactionId: string,
	includeCreditCardFee: boolean,
	forCheckIn: boolean,
): Promise<TransactionPricingResult | null> {
	try {
		const result = await axios.get(
			buildUrl(`${PRICING_API}/get-transaction-pricing`, {
				transactionId,
				includeCreditCardFee: includeCreditCardFee.toString(),
				forCheckIn: forCheckIn.toString(),
			}));
		return result.data as TransactionPricingResult;
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function FetchTrailerCancellationFee(
	bookingId: string,
	alterReturn: boolean,
): Promise<number> {
	try {
		const result = await axios.get(
			buildUrl(`${PRICING_API}/get-trailer-cancellation-fee`, {
				bookingId,
				alterReturn: alterReturn.toString(),
			}));
		return result.data as number;
	} catch (e) {
		console.error(e);
		return 0;
	}
}
