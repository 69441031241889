import React, { useEffect, useState } from 'react';
import { StaffBookingsCollection } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsCollection';
import { StaffBookingsDatePicker } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsDatePicker';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { StaffBookingsFilterList } from './StaffBookingsFilterList';
import { Button, Colors } from 'Views/Components/Button/Button';
import { bookingTransactionStatus } from 'Models/Enums';
import { FetchBookingsWithFilters } from 'Util/_HumanWritten/Bookings/FetchBookingsWithFilters';
import { BookingOrderByOption } from 'Util/_HumanWritten/Bookings/FetchBookingsUtils';
import {
	AlterationEntity,
	BookingEntity, LocationEntity, RouteEntity,
	TransactionEntity,
	UserEntity,
} from '../../../../Models/Entities';
import { getBookedSummaryAttributes, getFerryTripAttributes } from '../../../../Services/CustomGql/BookingSummaryType';

export interface StaffBookingsComponentProps {
	userId?: string;
	resultsPerPage?: number;
	onResultsPage?: boolean;
}

export interface StaffBookingsFilters {
	startDate?: Date;
	endDate?: Date;
	showPastBookings: boolean;
	paidFilter: boolean,
	invoicedFilter: boolean,
	awaitingFilter: boolean,
	cancelledFilter: boolean,
	orderBy: BookingOrderByOption;
	orderByDescending: boolean;
	pageNo: number;
}

function getStatusFilters(filters: StaffBookingsFilters) {
	const listOfFilters: bookingTransactionStatus[] = [];
	if (filters.paidFilter) {
		listOfFilters.push('PAID');
	}
	if (filters.awaitingFilter) {
		listOfFilters.push('AWAITING');
	}
	if (filters.invoicedFilter) {
		listOfFilters.push('INVOICED');
	}
	if (filters.cancelledFilter) {
		listOfFilters.push('CANCELLED');
	}
	return listOfFilters;
}

export function StaffBookingsComponent({
	userId,
	resultsPerPage,
	onResultsPage,
}: StaffBookingsComponentProps) {
	const perPage = isNotNullOrUndefined(resultsPerPage) ? resultsPerPage : 10;
	const [filters, setFilters] = useState<StaffBookingsFilters>({
		showPastBookings: false,
		paidFilter: true,
		invoicedFilter: true,
		awaitingFilter: true,
		cancelledFilter: true,
		orderBy: BookingOrderByOption.TRIP_DATE,
		orderByDescending: false,
		pageNo: 0,
	});

	const [bookings, setBookings] = useState<{ data: BookingEntity[], count: number } | null>(null);
	const [showFilters, setShowFilters] = useState<boolean>(false);

	useEffect(() => {
		setBookings(null);
		FetchBookingsWithFilters({
			excludeReturnBookings: true,
			excludePastBookings: !filters.showPastBookings,
			startDate: filters.startDate,
			endDate: filters.endDate,
			statusFilters: getStatusFilters(filters),
			skip: perPage * filters.pageNo,
			take: perPage,
			orderBy: filters.orderBy,
			orderByDescending: filters.orderByDescending,
			userId: userId,
			//
			// We want cargoInfo.id to exist in the bookedSummary so we can tell if the booking has a vehicle or not
			//
			expandString: `
				bookingStatus
				transactionStatus
				isEventBooking
				bookingListSummary {
					id
					humanReadableId
					status
					route {
						${RouteEntity.getAttributes().join('\n')}
						departure {
							${LocationEntity.getAttributes().join('\n')}
						}
						destination {
							${LocationEntity.getAttributes().join('\n')}
						}
					}
					tripDateTime
					totalCost
					createdDate
					name
					email
					bookingType
					paidPrice
					eventName
					cargoDriverDetailsString
					cargoRegoAndTrailerDetailsString
					passengerDetailsString
				}
			`,
		}).then(result => {
			setBookings(result);
		}).catch(_ => {
			setBookings({ data: [], count: 0 });
		});
	}, [filters, userId]);

	const classes = ['staff-bookings'];
	if (!!userId) {
		classes.push('search-results');
	}

	return (
		<div className={classNames(classes)}>
			<div className="staff-bookings__header">
				<If condition={onResultsPage}>
					<h4>
						Bookings
					</h4>
				</If>
				<If condition={!onResultsPage}>
					<h2 className="staff-bookings__title">
						Bookings
					</h2>
				</If>
				<div className="staff-bookings__header-button-list">
					<div className="staff-bookings__header-button">
						<StaffBookingsDatePicker
							filters={filters}
							setFilters={newFilters => {
								newFilters.pageNo = 0;
								setFilters(newFilters);
							}}
						/>
					</div>
					<div className="staff-booking__filter-panel-container">
						<div className="staff-bookings__header-button">
							<Button
								className="staff-bookings__filter-button"
								icon={{ icon: 'filter', iconPos: 'icon-left' }}
								colors={Colors.None}
								onClick={() => {
									setShowFilters(!showFilters);
								}}
							>
								Filter list
							</Button>
						</div>
						<If condition={showFilters}>
							<StaffBookingsFilterList
								filters={filters}
								setFilters={newFilters => {
									newFilters.pageNo = 0;
									setFilters(newFilters);
								}}
								setShowFilters={setShowFilters}
							/>
						</If>
					</div>
				</div>
			</div>
			<StaffBookingsCollection
				className="staff-bookings__table"
				bookings={bookings?.data ?? null}
				totalBookingCount={bookings?.count ?? 0}
				perPage={perPage}
				filters={filters}
				setFilters={setFilters}
			/>
		</div>
	);
}
