import { stringNotEmpty } from 'Util/TypeGuards';

interface CargoId {
	cargoId: string;
}

type PossibleCargoIdItem = Partial<CargoId>;
type CargoIdItem = CargoId;

const prepareCargoId = (cargoId: string) => {
	return (
		cargoId
			.toUpperCase()
			// consider persisting replaced characters for possible character mapping
			.replace(/[^A-Z0-9]/g, '')
	);
};

const prepareCargoIdItems = <T extends PossibleCargoIdItem>(items: T[]): (T & CargoIdItem)[] => {
	const cargoItems: (T & CargoIdItem)[] = [];

	for (const item of items) {
		if (stringNotEmpty(item.cargoId)) {
			const newItem: T & CargoIdItem = { ...item, cargoId: prepareCargoId(item.cargoId) };
			cargoItems.push(newItem);
		}
	}

	return cargoItems;
};

/**
 * Find item that matches any of the searchTerms based on cargoId attribute. Return null if none found.
 */
export const searchCargoId = <T extends PossibleCargoIdItem>(
	searchTerms: string[],
	items: T[],
): (T & CargoIdItem) | null => {
	const processedItems = prepareCargoIdItems(items);

	for (const term of searchTerms) {
		const formattedTerm = prepareCargoId(term);
		const match = processedItems.find(x => x.cargoId === formattedTerm);
		if (match) {
			return match;
		}
	}

	return null;
};
