import React, { useEffect, useState } from 'react';
import { FEATURE_IMAGE_4_URL, SERVER_URL } from '../../../Constants';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import queryString from 'querystring';
import { store } from '../../../Models/Store';
import If from '../If/If';
import { isNullOrUndefined } from '../../../Util/TypeGuards';
import { getContactUsEmailAddress } from '../../../Services/Api/_HumanWritten/EmailTemplateEntityService';

export function InvalidToken() {
	const queryParams = store.routerHistory.location.search.substring(1);
	const params = queryString.parse(queryParams);
	const error = params.error?.toString() ?? '';
	const [contactUsEmailAddress, setContactUsEmailAddress] = useState('');

	useEffect(() => {
		getContactUsEmailAddress().then(x => {
			setContactUsEmailAddress(x);
		});
	}, []);

	return (
		<>
			<div className="something-went-wrong-page-container">
				<div className="background-container">
					<img className="something-went-wrong-page-background" src={FEATURE_IMAGE_4_URL} alt="bg-img" />
				</div>
				<div className="something-went-wrong-page-elements__container">
					<div className="something-went-wrong-page-elements">
						<div className="something-went-wrong-modal-content">
							<h1 className="title">Whoops</h1>
							<If condition={error === 'invalid-token'}>
								<h3 className="subtitle">Your link has expired or is invalid</h3>
								<p className="details">Please try changing your email address again by logging
									in with your <strong>original email address</strong>.
								</p>
							</If>
							<If condition={error === 'used-token'}>
								<h3 className="subtitle">Your email address has already been changed</h3>
								<p className="details">Please try logging in with your <strong>new email address</strong>.</p>
							</If>
							<p className="details">If this error persists,
								<If condition={!!(window as any).Intercom}>
									<span> </span>
									<span
										className="live-chat__div"
										onKeyDown={() => {
											(window as any).Intercom('show');
										}}
										role="button"
										tabIndex={0}
										onClick={() => {
											(window as any).Intercom('show');
										}}
									>message us through live chat
									</span>.
								</If>
								<If condition={isNullOrUndefined((window as any).Intercom)}> email us at <a href={`mailto:${contactUsEmailAddress}`}>{contactUsEmailAddress}</a> and
									we will assist you further.
								</If>
							</p>
						</div>
						<Button onClick={() => {
							document.location.assign(`${SERVER_URL}/home`);
						}}
						>
							Go to home page
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
