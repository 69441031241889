import React from 'react';
import { observable } from 'mobx';
import { NumberTextField } from '../NumberTextBox/NumberTextBox';
import { AdditionalBookingOptionEntity, MeasurementEntity, PassengerTypeEntity } from 'Models/Entities';
import { confirmModal } from '../Modal/ModalUtils';
import { savePassengerTypeDefaultPrices } from 'Services/Api/_HumanWritten/PassengerTypeService';
import { newMeasurement } from '../../../Services/Api/_HumanWritten/MeasurementService';

interface DefaultPriceModalState {
	ferryTripPrice?: number;
	fixedPriceUserPrice?: number;
}

interface DefaultPriceModalProps {
	state: DefaultPriceModalState;
}

function DefaultPriceModal({ state }: DefaultPriceModalProps) {
	const [errors, setErrors] = React.useState<{ [x: string]: string | undefined}>({});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
		const value = Number(e.currentTarget.value);
		if (value && value < 0) {
			setErrors(x => {
				return { ...x, [key]: 'Please enter a value more than $0' };
			});
			return;
		}
		if (errors[key]) {
			setErrors(x => {
				return { ...x, [key]: undefined };
			});
		}
	};

	return (
		<div className="default-price-modal-content">
			<p>
				Please enter a default prices for this new entity type.
				This will be applied to all ferry trips and fixed price users.
			</p>
			<div className="custom-number-input-field">
				<NumberTextField
					className="default-price-textfield"
					model={state}
					modelProperty="ferryTripPrice"
					label="Ferry trip price"
					placeholder="0"
					isRequired
					showErrors
					onAfterChange={e => handleChange(e, 'ferryTripPrice')}
					errors={errors.ferryTripPrice}
				/>
				<NumberTextField
					className="default-price-textfield"
					model={state}
					modelProperty="fixedPriceUserPrice"
					label="Fixed user price"
					placeholder="0"
					isRequired
					showErrors
					onAfterChange={e => handleChange(e, 'invoicedUserPrice')}
					errors={errors.invoicedUserPrice}
				/>
			</div>
		</div>
	);
}

export async function setDefaultPriceForPassengerType(model: PassengerTypeEntity) {
	const state = observable<DefaultPriceModalState>({});

	await confirmModal(
		'Enter default price',
		<DefaultPriceModal state={state} />,
		{
			// Throw error when modal not confirmed
			resolveOnCancel: false,
		},
	);

	await savePassengerTypeDefaultPrices(
		model.id,
		state.ferryTripPrice ?? 0,
		state.fixedPriceUserPrice ?? 0,
	);
}

export async function setDefaultPriceForMeasurement(model: MeasurementEntity) {
	const state = observable<DefaultPriceModalState>({});

	await confirmModal(
		'Enter default price',
		<DefaultPriceModal state={state} />,
		{
			// Throw error when modal not confirmed
			resolveOnCancel: false,
		},
	);

	await newMeasurement({
		measurement: model,
		fixedPriceUserDefaultPrice: state.fixedPriceUserPrice ?? 0,
		ferryTripDefaultPrice: state.ferryTripPrice ?? 0,
	});
}
