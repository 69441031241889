import * as React from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { toggleFerryTripOpenState } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import alertToast from 'Util/ToastifyUtils';
import { useState } from 'react';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { checkInStore } from '../context/CheckInContext';
import If from 'Views/Components/If/If';

export interface CheckInOpenCloseProps {
	tripId: string;
	checkInClosed: boolean;
	tripDeparted: boolean;
}

async function onClick(tripId: string, checkInClosed: boolean, setLoading: (loading: boolean) => void) {
	confirmModal(
		checkInClosed ? 'Open online bookings?' : 'Close online bookings?',
		checkInClosed
			? 'This will allow customers to make an online booking on this ferry trip.'
			: 'This will prevent customers from making online bookings on this ferry trip.',
	).then(async () => {
		setLoading(true);
		const result = await toggleFerryTripOpenState(tripId);
		if (result) {
			alertToast(`Bookings ${checkInClosed ? 'opened' : 'closed'}`, 'success');
			await checkInStore.loadFerryTrip(tripId);
			setLoading(false);
		} else {
			setLoading(false);
			alertToast(`Could not ${checkInClosed ? 'open' : 'close'} bookings`, 'error');
		}
	});
}

export function CheckInOpenClose(props: CheckInOpenCloseProps) {
	const {
		tripId,
		checkInClosed = false,
		tripDeparted,
	} = props;

	const [loading, setLoading] = useState<boolean>(false);

	return (
		<div className="check-in-open-closed__container">
			<If condition={tripDeparted}>
				<p className="description__text">Ferry trip <strong>departed</strong></p>
			</If>
			<If condition={!tripDeparted}>
				<p className="description__text">Online bookings are
					<strong>{
						// eslint-disable-next-line no-nested-ternary
						loading
							? (
								checkInClosed
									? ' Opening...'
									: ' Closing...'
							)
							: (checkInClosed
								? ' Closed'
								: ' Open')
					}
					</strong>
				</p>
				<Button
					className="edit-filter-button"
					colors={Colors.Primary}
					display={Display.Solid}
					onClick={async () => {
						await onClick(tripId, checkInClosed, setLoading);
					}}
					disabled={loading || tripDeparted}
				>
					{/* eslint-disable-next-line no-nested-ternary */}
					{loading
						? (checkInClosed
							? 'Opening...'
							: 'Closing...')
						: (checkInClosed
							? 'Enable bookings'
							: 'Disable bookings')}
				</Button>
			</If>
		</div>
	);
}
