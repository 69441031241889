import * as React from 'react';
import { ReactElement } from 'react';

export interface EventSectionProps {
	title: string;
	body: string | ReactElement;
	width?: string;
}
export function EventDetailSection({ title, body, width = '100%' }: EventSectionProps) {
	let bodyToUse = body;
	if (typeof body === 'string') {
		bodyToUse = (
			<p>{body}</p>
		);
	}
	return (
		<>
			<div className="event-detailed-view__section" style={{ maxWidth: width }}>
				<p className="event-detailed-view__section-title">{title}</p>
				<div className="event-detailed-view__section-body">{bodyToUse}</div>
			</div>
			<div className="underline" />
		</>
	);
}
