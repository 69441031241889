import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import classNames from 'classnames';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import useCheckInStore from 'Hooks/useCheckInStore';
import { CargoEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { getMeasurementValueFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { BookingFormEditSectionProps } from './BookingFormEdit';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import BookingFormGroupHeader from '../BookingFormGroupHeader';
import LatestVehiclesList from 'Views/Components/_HumanWritten/LatestCargosList/LatestVehiclesList';

function BookingFormEditVehicle({
	mode,
	groupHeaders,
	errors,
	options,
	setOptions,
	resetContinue,
	oneInputPerRow = false,
}: BookingFormEditSectionProps) {
	const checkInStore = useCheckInStore();
	const { isMobile } = useDeviceDetect();
	const isVehicleBooking = groupHeaders.vehicles.show;
	const state = useLocalStore<{ vehicles: CargoEntity[] }>(() => {
		return { vehicles: [] };
	});
	const { formState } = checkInStore;
	const formRef = React.useRef<HTMLDivElement | null>(null);

	const checkSelectedLength = () => {
		if (formState.cargoMake && formState.cargoModel) {
			// Get vehicle
			const [cargoType] = options.allCargoTypes
				.filter(x => (
					x.cargoMake === formState.cargoMake
						&& x.cargoModel === formState.cargoModel
				));

			if (cargoType && cargoType.minimumLengthId) {
				const minimumLength = getMeasurementValueFromId(cargoType.minimumLengthId);

				// Update formState length if required
				runInAction(() => {
					if (formState.vehicleLengthId) {
						const length = getMeasurementValueFromId(formState.vehicleLengthId);
						if (length < minimumLength) {
							formState.vehicleLengthId = cargoType.minimumLengthId;
						}
					} else {
						formState.vehicleLengthId = checkInStore.ferryTrip.getMinimumMeasurement()?.id;
					}
				});
			}

			if (cargoType && cargoType.minimumWeightId) {
				const minimumWeight = getMeasurementValueFromId(cargoType.minimumWeightId);

				// Update formState weight if required
				runInAction(() => {
					if (formState.vehicleWeightId) {
						const weight = getMeasurementValueFromId(formState.vehicleWeightId);
						if (weight < minimumWeight) {
							formState.vehicleWeightId = cargoType.minimumWeightId;
						}
					} else {
						formState.vehicleWeightId = checkInStore.ferryTrip.getMinimumMeasurement('WEIGHT')?.id;
					}
				});
			}
		}
	};

	return (
		<div ref={formRef}>
			<BookingFormGroupHeader
				title={whiteLabelStore.vehicleLabelPascalCase}
				config={{
					model: groupHeaders.vehicles,
					modelProperty: 'show',
					onAfterChange: checked => {
						if (!checked && groupHeaders.trailers.show) {
							groupHeaders.trailers.setShow(false);
						}
						resetContinue();
						runInAction(() => {
							if (!checked) {
								return;
							}
							if (!formState.vehicleLengthId) {
								formState.vehicleLengthId = checkInStore.ferryTrip.getMinimumMeasurement()?.id;
							}
							if (stringIsEmpty(formState.driverFirstName)) {
								formState.driverFirstName = formState.user.firstName;
							}
							if (stringIsEmpty(formState.driverLastName)) {
								formState.driverLastName = formState.user.lastName;
							}
							if (stringIsEmpty(formState.driverPhone)) {
								formState.driverPhone = formState.user.phone;
							}
							if (formState.adultsCount < whiteLabelStore.minAdultsForVehicle) {
								formState.adultsCount = whiteLabelStore.minAdultsForVehicle;
							}
						});
					},
					disabled: groupHeaders.vehicles.disabled,
					showCloseBtn: stringNotEmpty(formState.cargoMake),
					onClose: () => {
						runInAction(() => {
							formState.cargoMake = '';
							formState.cargoModel = '';
							formState.cargoIdentification = '';
						});
					},
				}}
				className="grid"
			>
				<Combobox
					className={classNames('vehicle-make-combobox', { 'column-1': !oneInputPerRow })}
					model={formState}
					modelProperty="cargoMake"
					label={whiteLabelStore.vehicleMakeLabelPascalCase}
					placeholder={whiteLabelStore.vehicleMakePlaceholderPascalCase}
					options={options.cargoMakeOptions ?? []}
					searchable
					onAfterChange={() => {
						// Only show models based on selected vehicle make
						setOptions(currentOptions => {
							const vehicleModels = currentOptions.allCargoTypes
								.filter(x => x.cargoMake === formState.cargoMake);
							const [first] = vehicleModels;
							runInAction(() => {
								formState.cargoModel = first.cargoModel;
								formState.vehicleWeightId = first.minimumWeightId;
							});
							return {
								...currentOptions,
								cargoModelOptions: currentOptions.allCargoTypes
									.filter(x => x.cargoMake === formState.cargoMake)
									.map(x => ({ display: x.cargoModel, value: x.cargoModel })),
							};
						});
						resetContinue('cargoMake');
						checkSelectedLength();

						// Hide mobile keyboard after selecting option
						if (isMobile) {
							setTimeout(() => {
								(formRef.current?.querySelector('.vehicle-make-combobox input') as HTMLInputElement)
									.blur();
							}, 0);
						}
					}}
					errors={errors.cargoMake}
				/>
				<If condition={stringNotEmpty(formState.cargoMake)}>
					<Combobox
						className={classNames('vehicle-model-combobox', { 'column-2': !oneInputPerRow })}
						model={formState}
						modelProperty="cargoModel"
						label={whiteLabelStore.vehicleModelLabelPascalCase}
						placeholder={whiteLabelStore.vehicleModelPlaceholderPascalCase}
						options={options.cargoModelOptions ?? []}
						searchable
						isDisabled={stringIsEmpty(formState.cargoMake)}
						onAfterChange={() => {
							resetContinue('cargoModel');
							checkSelectedLength();

							// Hide mobile keyboard after selecting option
							if (isMobile) {
								setTimeout(() => {
									(formRef.current?.querySelector('.vehicle-model-combobox input') as HTMLInputElement)
										.blur();
								}, 0);
							}
						}}
						errors={errors.cargoModel}
					/>
					{whiteLabelStore.config.vehicleLengthEnabled && (
						<>
							<Combobox
								className={classNames({ 'column-1': !oneInputPerRow }, 'length-field')}
								model={formState}
								modelProperty="vehicleLengthId"
								label="Length"
								placeholder="Select length type"
								options={
									checkInStore?.ferryTrip?.getFilteredMeasurements('LENGTH')
										?.sort((a, b) => (a.value < b.value ? -1 : 1))
										?.map(option => {
											if (option.value && formState.cargoMake && formState.cargoModel) {
												const [cargoType] = options.allCargoTypes
													.filter(x => (
														x.cargoMake === formState.cargoMake
														&& x.cargoModel === formState.cargoModel
													));

												if (cargoType && cargoType.minimumLengthId) {
													const optionLength = getMeasurementValueFromId(option.id);
													const minimumLength = getMeasurementValueFromId(
														cargoType.minimumLengthId,
													);
													if (optionLength < minimumLength) {
														return {
															display: option.label,
															value: option.id,
															disabled: true,
														};
													}
												}
											}
											return {
												display: option.label,
												value: option.id,
												disabled: false,
											};
										}) ?? []
								}
								searchable={false}
								onAfterChange={() => resetContinue('vehicleLengthId')}
								errors={errors.vehicleLengthId}
							/>
							<Combobox
								className={classNames({ 'column-2': !oneInputPerRow }, 'weight-field')}
								model={formState}
								modelProperty="vehicleWeightId"
								label="Weight"
								placeholder="Select weight type"
								options={
									checkInStore?.ferryTrip?.getFilteredMeasurements('WEIGHT')
										?.sort((a, b) => (a.value < b.value ? -1 : 1))
										?.map(option => {
											if (option.value && formState.cargoMake && formState.cargoModel) {
												const [cargoType] = options.allCargoTypes
													.filter(x => (
														x.cargoMake === formState.cargoMake
														&& x.cargoModel === formState.cargoModel
													));

												if (cargoType && cargoType.minimumWeightId) {
													const optionWeight = getMeasurementValueFromId(option.id);
													const minimumWeight = getMeasurementValueFromId(
														cargoType.minimumWeightId,
													);
													if (optionWeight < minimumWeight) {
														return {
															display: option.label,
															value: option.id,
															disabled: true,
														};
													}
												}
											}
											return {
												display: option.label,
												value: option.id,
												disabled: false,
											};
										}) ?? []
								}
								searchable={false}
								onAfterChange={() => resetContinue('vehicleWeightId')}
								errors={errors.vehicleWeightId}
							/>
						</>
					)}
					<TextField
						className={classNames({ 'column-1': !oneInputPerRow }, 'rego-field')}
						label={whiteLabelStore.regoLabelPascalCase}
						name="cargoIdentification"
						model={formState}
						modelProperty="cargoIdentification"
						placeholder={whiteLabelStore.regoPlaceholderPascalCase}
						onAfterChange={() => resetContinue('cargoIdentification')}
						errors={isVehicleBooking ? errors.cargoIdentification : undefined}
					/>
				</If>
				<If
					condition={stringIsEmpty(formState.cargoMake)
						&& whiteLabelStore.config.previousVehicleSelectionEnabled}
				>
					<LatestVehiclesList
						userId={formState.userId}
						update={x => {
							runInAction(() => {
								state.vehicles = x;
							});
						}}
						onSelect={cargoInfo => {
							if (!cargoInfo) {
								return;
							}
							resetContinue([
								'cargoMake',
								'cargoModel',
								'cargoIdentification',
								'vehicleLengthId',
								'vehicleWeightId',
								'driverFirstName',
								'driverLastName',
								'driverPhone',
							]);
							const { cargoMake, cargoModel } = cargoInfo.cargoType;
							runInAction(() => {
								formState.cargoMake = cargoMake;
								formState.cargoModel = cargoModel;
								formState.cargoIdentification = cargoInfo.cargoIdentification;
								formState.vehicleLengthId = cargoInfo.selectedLengthId;
								formState.vehicleWeightId = cargoInfo.selectedWeightId;

								const cargoDetails = cargoInfo.cargoDetails
									.sort((a, b) => (a.created < b.created ? -1 : 1))[0];
								if (cargoDetails) {
									formState.driverFirstName = cargoDetails.contactFirstName;
									formState.driverLastName = cargoDetails.contactLastName;
									formState.driverPhone = cargoDetails.contactPhone;
								}
							});
							// Update vehicle model options
							setOptions(currentOptions => {
								return {
									...currentOptions,
									cargoModelOptions: options.allCargoTypes
										.filter(x => x.cargoMake === cargoMake)
										.map(x => ({ display: x.cargoModel, value: x.cargoModel })),
								};
							});
						}}
					/>
				</If>
			</BookingFormGroupHeader>
		</div>
	);
}

export default observer(BookingFormEditVehicle);
