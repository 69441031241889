import React from 'react';
import { completePayment } from 'Services/Api/_HumanWritten/PaymentService';
import { stringIsEmpty } from 'Util/TypeGuards';
import { PostPaymentRender } from './PostPaymentRender';

export interface PostPaymentProps {
	onSuccess: (transactionId: string) => void;
	onPaymentFailed: (errorCode: string) => void;
	className?: string;
	paymentIntentId?: string;
}

export function PostPayment({
	onSuccess,
	onPaymentFailed,
	className,
	paymentIntentId,
}: PostPaymentProps) {
	React.useEffect(() => {
		if (stringIsEmpty(paymentIntentId)) {
			return;
		}
		completePayment(paymentIntentId)
			.then(result => {
				onSuccess(result.data);
			})
			.catch(e => {
				onPaymentFailed(e.response?.data ?? 'Error');
			});
	}, [paymentIntentId]);

	return (
		<PostPaymentRender className={className} />
	);
}
