import { create } from 'zustand';

import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { ScannerActionEvent, ScannerActionMap } from '../Types/scannerAction';
import { ScannerMode, ScannerModeMap } from '../Types/scannerMode';

interface UseScannerProps {
	bookings: CheckInBookingOverviewDto[];
	mode: ScannerMode;
	dispatch(event: ScannerActionEvent): void;
}

export const useScanner = create<UseScannerProps>((set, get) => ({
	mode: ScannerModeMap.QrCode,
	bookings: [],
	devMode: false,
	dispatch(event) {
		switch (event.action) {
			case ScannerActionMap.StartScanner: {
				const { bookings } = event;
				set({ bookings });
				break;
			}
			case ScannerActionMap.ToggleMode: {
				set(prevState => ({
					mode: prevState.mode === ScannerModeMap.QrCode ? ScannerModeMap.Rego : ScannerModeMap.QrCode,
					imageBase64: undefined,
				}));
				break;
			}
			default:
				throw new Error('Unhandled scanner event');
		}
	},
}));
