import React from 'react';
import { BaseSpaceModal } from './BaseSpaceModal';

export interface WeightModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void
}

export function WeightModal({
	showModal,
	onClickSelect,
	onClickCross,
}: WeightModalProps) {
	return (
		<BaseSpaceModal
			showModal={showModal}
			onClickCross={onClickCross}
			onClickSelect={onClickSelect}
			textToDisplay={[
				'Your vehicle weight exceeds the space available on this ferry so you will need to select another '
				+ 'time slot.',
			]}
		/>
	);
}
