import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { BookingWizardData } from '../../BookingWizardData';
import { wizardModeOptions } from 'Models/Enums';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Text } from 'Views/Components/Text/Text';
import { AddOnsCollection } from './AddOnCollection';
import { SelectedTrips } from '../../BookingWizardWrap';
import { RouteEntity } from 'Models/Entities';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

export const AddOnsContext = React.createContext<AddOnsTabProps>({} as AddOnsTabProps);

export interface AddOnsTabProps {
	wizardData: BookingWizardData;
	route: RouteEntity;
	selectedTrips: SelectedTrips;
	onUpdateWizardData: (wizardData: BookingWizardData) => void;
}

function AddOnsTab(props: AddOnsTabProps) {
	const { wizardData, onUpdateWizardData } = props;

	return (
		<div className="booking-wizard-tab">
			<h2 className="booking-wizard__tab-header mb-xs">
				Add-ons
				<Cross wizardData={wizardData} />
			</h2>
			<Text className="pb-sm" color="gray">
				{whiteLabelStore.addOnTabSubtitle}
			</Text>
			<AddOnsContext.Provider value={props}>
				<AddOnsCollection isVehicleOnly={false} userId={wizardData.userId} />
			</AddOnsContext.Provider>
			{whiteLabelStore.passengerNoteEnabled && (
				<TextField
					name="note"
					model={wizardData}
					modelProperty="note"
					label={whiteLabelStore.passengerBookingNoteLabel}
					placeholder={whiteLabelStore.passengerBookingNotePlaceholder}
					onAfterChange={() => {
						const newData = { ...wizardData };
						onUpdateWizardData(newData);
					}}
				/>
			)}
		</div>
	);
}

export default observer(AddOnsTab);

function Cross({ wizardData }: { wizardData: BookingWizardData}) {
	if (wizardData.wizardMode !== wizardModeOptions.ALTERATION) {
		return null;
	}

	return (
		<span>
			<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
				<Icon name="cross" classname="icon" />
			</Link>
		</span>
	);
}
