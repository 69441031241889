import { UserEntity } from 'Models/Entities';
import { changeUserGroups } from '../../../../../Services/Api/AccountService';

export async function saveUser(user: UserEntity, originalUser: UserEntity, isManager: boolean) {
	const relationPath = isManager ? { prices: {}, addOnsPrices: {} } : {};

	await user.save(relationPath);
}

export async function updateUserType(user: UserEntity, originalUser: UserEntity) {
	if (user.userDisplayNameId !== originalUser.userDisplayNameId) {
		const newRoles: string[] = [];
		if (user.userDisplayNameId === null) {
			newRoles.push('User');
		} else if (user.userDisplayName?.invoicedUser) {
			newRoles.push('InvoicedUser');
		} else if (!user.userDisplayName?.invoicedUser) {
			newRoles.push('FixedPriceUser');
		}
		await changeUserGroups(user.userName, newRoles);
	}
}

export const password = {
	password: '************',
};
