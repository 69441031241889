import React, { ReactNode } from 'react';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import If from '../../If/If';
import { InvoiceRender } from './Invoicerender';
import Pagination from '../../Pagination/Pagination';
import { UserInvoicesDto } from '../../../../Services/Api/_HumanWritten/InvoiceService';

export interface UserInvoiceListCollectionProps {
	invoices: UserInvoicesDto[] | null;
	totalInvoiceCount: number;
	perPage: number;
	pageNum: number;
	setPageNum: (pageNum: number) => void;
}

export function UserInvoicesCollection({
	invoices,
	totalInvoiceCount,
	perPage,
	pageNum,
	setPageNum,
}: UserInvoiceListCollectionProps) {
	const invoiceRenders: ReactNode[] = [];

	if (invoices !== null) {
		invoices
			.forEach(invoice => {
				invoiceRenders.push(
					<InvoiceRender
						key={invoice.invoiceId}
						invoice={invoice}
					/>,
				);
			});
	}

	return (
		<div className="basic-table__black">
			<div className="invoices-list__table-headers">
				<table>
					<thead>
						<tr>
							<th>
								Creation date
							</th>
							<th>
								Invoice number
							</th>
							<th>
								Total
							</th>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<th />
						</tr>
					</thead>
					<tbody>
						{invoiceRenders}
					</tbody>
				</table>
				<If condition={invoices === null}>
					<LottieSpinner />
				</If>
				<If condition={invoices !== null && invoiceRenders.length === 0}>
					<p className="no-content">
						No invoices
					</p>
				</If>
				<If condition={invoiceRenders.length > 0}>
					<div className="collection-component">
						<section className="pagination collection__pagination">
							<section className="collection__load">
								<Pagination
									perPage={perPage}
									pageNo={pageNum}
									totalRecords={totalInvoiceCount}
									onPageChange={pageNo => {
										setPageNum(pageNo);
									}}
									showGoToPageBox={false}
								/>
							</section>
						</section>
					</div>
				</If>
			</div>
		</div>
	);
}
