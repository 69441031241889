import React, { useRef } from 'react';
import moment from 'moment';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import classNames from 'classnames';
import Tabs, { ITabConfig } from 'Views/Components/Tabs/Tabs';
import { action } from 'mobx';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { wizardModeOptions } from 'Models/Enums';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { unselectInvalidDateTicket } from 'Util/_HumanWritten/TicketSelectionValidationUtils';
import { daysOfWeekLong, getDayOfTheWeek } from 'Util/_HumanWritten/DayOfWeekUtils';
import { ferryTripFilterDepartedTicketTab } from 'Util/_HumanWritten/TicketSelectionUtils';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import {
	checkDateColumnClassName,
	checkLeftDate,
	getNewDate,
} from 'Util/_HumanWritten/DateSelectorUtils';
import {
	getMinimumFerryTicketPriceFromListOfFerryTrips,
} from 'Util/_HumanWritten/PriceCalculations/PriceFunctionSelectors/GetMinimumFerryTicketPriceFromListOfFerryTrips';
import {
	FilteredTripCards,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/FilteredTripCards';

export interface TripSelectionCalendarProps {
	wizardData: BookingWizardData;
	ferryTrips: TicketsTabTrip[] | null;
	departure: boolean;
	saveChanges: (data: BookingWizardData) => void;
	selectedTrips: SelectedTrips;
	isStaff: boolean;
	refreshTrips?: () => void;
	priceByFerryTrip: { [key: string]: number };
	loading: boolean;
}

export function TripSelectionCalendar({
	wizardData,
	ferryTrips,
	departure,
	saveChanges,
	selectedTrips,
	isStaff,
	refreshTrips,
	priceByFerryTrip,
	loading,
}: TripSelectionCalendarProps) {
	const listOfTabs: ITabConfig[] = [];
	const departureReturnDate = departure ? wizardData.startDate : wizardData.endDate;
	const firstDay = (moment(departureReturnDate).add(-2, 'days').toDate()).getDay();
	const firstDate = (moment(departureReturnDate).add(-2, 'days').toDate());

	const leftTab = (
		<Button
			className="move-date-left-button"
			display={Display.Text}
			colors={Colors.Alternate}
			sizes={Sizes.ExtraLarge}
			icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
			onClick={() => changeDateByWeek(false)}
			disabled={(isStaff ? false : checkLeftDate(departureReturnDate as Date, 1))}
		/>
	);
	const rightTab = (
		<Button
			className="move-date-right-button"
			display={Display.Text}
			colors={Colors.Alternate}
			sizes={Sizes.ExtraLarge}
			icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
			onClick={() => changeDateByWeek(true)}
		/>
	);

	// Check if the proposed change of 5 days is a valid change, if it is not return todays date
	const changeDateByWeek = action((increase: boolean) => {
		const newData = { ...wizardData };
		if (departure) {
			newData.startDate = getNewDate(departureReturnDate as Date, increase, 5, isStaff);
			newData.ticketSelectionStartDate = newData.startDate;
			unselectInvalidDateTicket(newData);
		} else {
			newData.endDate = getNewDate(departureReturnDate as Date, increase, 5, isStaff);
			newData.ticketSelectionEndDate = newData.endDate;
		}

		saveChanges(newData);
	});

	for (let i = 0, index = firstDay; i < daysOfWeekLong.length - 2; i++, index++) {
		if (index === 7) {
			index = 0;
		}
		const day = getDayOfTheWeek(index, false);
		const currentDay = new Date(firstDate);
		currentDay.setDate(firstDate.getDate() + i);

		const filteredFerryList = ferryTripFilterDepartedTicketTab(
			ferryTrips ?? [],
			currentDay,
			wizardData.tabSelected,
		);

		const filteredPriceByFerryTrip = Object.keys(priceByFerryTrip)
			.filter(key => filteredFerryList.map(x => x.id).includes(key))
			.reduce((obj, key) => {
				obj[key] = priceByFerryTrip[key];
				return obj;
			}, {} as { [key: string]: number });

		const tabName = (
			<div className={classNames(
				'filtered-trip-calendar-header',
				checkDateColumnClassName(currentDay, filteredFerryList)
					? ''
					: 'no-trips',
			)}
			>
				<p className="date-header">{moment(currentDay).format('DD')}</p>
				<p className="day-header">{day.toUpperCase()}</p>
				<h6 className="from-price">{filteredFerryList.length > 0 ? 'From' : 'No trips'}</h6>
				<h1 className="from-price">
					<If condition={loading}>
						<CustomSpinner
							containerClassname="min-height"
							loaderClassname="mini"
						/>
					</If>
					<If condition={!loading}>
						{isNotNullOrUndefined(filteredFerryList) && filteredFerryList.length > 0
							? formatPriceDisplay(
								getMinimumFerryTicketPriceFromListOfFerryTrips(filteredPriceByFerryTrip),
								wizardData.wizardMode === wizardModeOptions.ALTERATION,
								false,
							)
							: '-'}
					</If>
				</h1>
			</div>
		);
		const tabComponent = (
			<div className="trip-card-column-container">
				<If condition={ferryTrips !== null}>
					<FilteredTripCards
						list={filteredFerryList}
						wizardData={wizardData}
						departure={departure}
						saveChanges={saveChanges}
						departureTripTime={selectedTrips.departingTrip?.departureDateTime ?? null}
						isStaff={isStaff}
						locationInApp="sidebar"
						refreshTrips={refreshTrips}
						priceByFerryTrip={priceByFerryTrip}
						loading={loading}
					/>
				</If>
				<If condition={ferryTrips === null}>
					<LottieSpinner />
				</If>
			</div>
		);
		listOfTabs.push({
			component: tabComponent,
			name: tabName,
			key: currentDay.toString(),
			disabled: isStaff ? false : currentDay < new Date() && currentDay.getDate() < new Date().getDate(),
		});
	}

	return (
		<div className="trip-selection-container">
			<Tabs
				tabs={listOfTabs}
				leftTab={leftTab}
				rightTab={rightTab}
				defaultTab={2}
				onTabClicked={action((tabIndex, event) => {
					const newData = { ...wizardData };
					if (departure) {
						newData.ticketSelectionStartDate = moment(listOfTabs[tabIndex].key).toDate();
					} else {
						newData.ticketSelectionEndDate = moment(listOfTabs[tabIndex].key).toDate();
					}
					saveChanges(newData);
				})}
			/>
		</div>
	);
}
