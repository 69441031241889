import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { FerryTripEntity } from 'Models/Entities';
import { useFerriesAndRoutes } from 'Util/_HumanWritten/FerryTripModalUtils';
import { generateVehiclePassengerManifest } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import If from 'Views/Components/If/If';
import { Button } from 'Views/Components/Button/Button';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { DateTimePicker } from 'Views/Components/DateTimePicker/DateTimePicker';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { upperCaseFirst } from 'Util/StringUtils';
import TripDetailsStartDateTimeSelector from 'Views/Components/_HumanWritten/FerrySchedule/Components/FerryTripStartDateTimeSelector';

export interface IDetailsTabProps {
	ferryTrip: FerryTripEntity;
	hidePanel?: () => void;
	isDisabled: boolean;
	showGenerateManifest?: boolean;
	enableStartTripInput?: boolean;
}

function FerryTripDetailsTabComponent({
	ferryTrip,
	isDisabled,
	showGenerateManifest = false,
	enableStartTripInput = false,
}: IDetailsTabProps) {
	const response = useFerriesAndRoutes();

	useEffect(action(() => {
		if (response.type === 'data') {
			const [ferries, routes] = response.data;
			if (ferries.length === 1) {
				ferryTrip.ferryId = ferries[0].id;
			}
			const route = routes.find(x => x.id === ferryTrip.routeId);
			if (route) {
				ferryTrip.route = route;
			}
		}
	}), [ferryTrip, response.data, response.type]);

	if (response.type === 'loading') {
		return <></>;
	}

	if (response.type === 'error') {
		return <div>Something went wrong {response.error}</div>;
	}

	const [ferryList, routeList] = response.data;

	const ferryCapacityModel = {
		vehicleCapacity: ferryList.find(x => x.id === ferryTrip.ferryId)?.vehicleCapacity?.toString() ?? '-',
		passengerCapacity: ferryList.find(x => x.id === ferryTrip.ferryId)?.passengerCapacity?.toString() ?? '-',
		towingCapacity: ferryList.find(x => x.id === ferryTrip.ferryId)?.towingCapacity?.toString() ?? '-',
		weightCapacity: ferryList.find(x => x.id === ferryTrip.ferryId)?.weightCapacity?.toString() ?? '-',
	};

	const generateManifest = async () => {
		await generateVehiclePassengerManifest(ferryTrip);
	};

	return (
		<div className="custom-sidebar__tab-content details-tab">
			<div className="custom-sidebar__section">
				<If condition={showGenerateManifest}>
					<Button className="generate-manifest" onClick={generateManifest}>
						Generate {whiteLabelStore.config?.vehicleLabel?.toLowerCase()}/passenger manifest
					</Button>
				</If>
				<h6 className="custom-sidebar__section-header">Trip details</h6>
				<div className="custom-sidebar__section-inputs">
					<TripDetailsStartDateTimeSelector
						ferryTrip={ferryTrip}
						isDisabled={enableStartTripInput ? false : isDisabled}
					/>
					<DateTimePicker
						key="end-date-time-selector"
						className="end-date-time-selector"
						id="end-date-time-selector"
						model={ferryTrip}
						modelProperty="arrivalDateTime"
						label="End trip"
						placeholder="Trip arrival date"
						isDisabled
						humanFriendly
						altFormat="d M Y H:i"
					/>
					<Combobox
						key="route-dropdown-key"
						className="ferry-route"
						model={ferryTrip}
						modelProperty="routeId"
						label="Route"
						placeholder="Select route"
						options={routeList.map(x => {
							return { display: x.name, value: x.id };
						})}
						isDisabled={isDisabled}
						isRequired={!isDisabled}
						searchable
					/>
					<Combobox
						key="ferry-dropdown-key"
						className="ferry-trip-ferry"
						model={ferryTrip}
						modelProperty="ferryId"
						label="Ferry"
						placeholder="Select ferry"
						options={ferryList.map(x => {
							return { display: x.name, value: x.id };
						})}
						isDisabled={ferryList.length <= 1 || isDisabled}
						isRequired={!isDisabled}
						searchable
					/>
				</div>
			</div>
			<div className="custom-sidebar__section">
				<h6 className="custom-sidebar__section-header">Ferry capacity</h6>
				<div className="custom-sidebar__section-inputs">
					<TextField
						className="vehicle-capacity"
						id="vehicle-capacity"
						model={ferryCapacityModel}
						modelProperty="vehicleCapacity"
						label={`${upperCaseFirst(whiteLabelStore.config.vehicleLabel.toLowerCase())} capacity`}
						isDisabled
					/>
					{whiteLabelStore.config.trailersEnabled && (
						<TextField
							className="trailer-capacity"
							id="trailer-capacity"
							model={ferryCapacityModel}
							modelProperty="towingCapacity"
							label="Trailer capacity"
							isDisabled
						/>
					)}
					<TextField
						className="passenger-capacity"
						id="passenger-capacity"
						model={ferryCapacityModel}
						modelProperty="passengerCapacity"
						label="Passenger capacity"
						isDisabled
					/>
					<TextField
						className="weight-capacity"
						id="weight-capacity"
						model={ferryCapacityModel}
						modelProperty="weightCapacity"
						label="Weight capacity"
						isDisabled
					/>
				</div>
			</div>
		</div>
	);
}

export default observer(FerryTripDetailsTabComponent);
