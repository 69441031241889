/**
 * Super simple pluralisation string transformer.
 */
export const toPlural = (text: string): string => {
	if (!!!text || text.length === 0) {
		return '';
	}
	if (text.charAt(text.length - 1).toLowerCase() === 's') {
		return `${text}es`;
	}
	return `${text}s`;
};
