import * as React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../Icon/Icon';
import { BookingDetailSection, SectionProps } from './BookingDetailSection';
import { upperCaseFirst } from '../../../../../../Util/StringUtils';

export function CustomerInfoSection({
	booking,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const sectionContent = (
		<>
			<Link
				to={`/search-results/${booking.userId}`}
				className="customer-info hide-underline"
			>
				{`${upperCaseFirst(booking.user.firstName)} ${upperCaseFirst(booking.user.lastName)}`}
			</Link>
			<p>
				{booking.user.email}
			</p>
			<p>
				{booking.user.phone}
			</p>
		</>
	);

	return (
		<BookingDetailSection
			sectionName="Customer details"
			sectionContent={sectionContent}
			sectionClassName="customer-details"
			icon={{
				name: 'file',
				classname: 'file',
			}}
			modificationRules={{
				canEdit: false,
				editType: 'passenger',
				onEdit: () => {},
				isInvoiced: false,
			}}
		/>
	);
}
