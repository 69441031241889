/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsFeeOptionReferenceManyToMany from '../Security/Acl/VisitorsFeeOptionReferenceManyToMany';
import UserFeeOptionReferenceManyToMany from '../Security/Acl/UserFeeOptionReferenceManyToMany';
import StaffFeeOptionReferenceManyToMany from '../Security/Acl/StaffFeeOptionReferenceManyToMany';
import ManagerFeeOptionReferenceManyToMany from '../Security/Acl/ManagerFeeOptionReferenceManyToMany';
import AdminFeeOptionReferenceManyToMany from '../Security/Acl/AdminFeeOptionReferenceManyToMany';
import InvoicedUserFeeOptionReferenceManyToMany from '../Security/Acl/InvoicedUserFeeOptionReferenceManyToMany';
import FixedPriceUserFeeOptionReferenceManyToMany from '../Security/Acl/FixedPriceUserFeeOptionReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRouteFeeOptionAttributes extends IModelAttributes {
	routeId: string;
	feeOptionId: string;

	route: Models.RouteEntity | Models.IRouteEntityAttributes;
	feeOption: Models.FeeOptionEntity | Models.IFeeOptionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('RouteFeeOption')
export default class RouteFeeOption
	extends Model
	implements IRouteFeeOptionAttributes {
	public static acls: IAcl[] = [
		new VisitorsFeeOptionReferenceManyToMany(),
		new UserFeeOptionReferenceManyToMany(),
		new StaffFeeOptionReferenceManyToMany(),
		new ManagerFeeOptionReferenceManyToMany(),
		new AdminFeeOptionReferenceManyToMany(),
		new InvoicedUserFeeOptionReferenceManyToMany(),
		new FixedPriceUserFeeOptionReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public routeId: string;

	@observable
	@attribute()
	public feeOptionId: string;

	@observable
	@attribute({ isReference: true })
	public route: Models.RouteEntity;

	@observable
	@attribute({ isReference: true })
	public feeOption: Models.FeeOptionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRouteFeeOptionAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.routeId) {
				this.routeId = attributes.routeId;
			}
			if (attributes.feeOptionId) {
				this.feeOptionId = attributes.feeOptionId;
			}

			if (attributes.route) {
				if (attributes.route instanceof Models.RouteEntity) {
					this.route = attributes.route;
					this.routeId = attributes.route.id;
				} else {
					this.route = new Models.RouteEntity(attributes.route);
					this.routeId = this.route.id;
				}
			} else if (attributes.routeId !== undefined) {
				this.routeId = attributes.routeId;
			}

			if (attributes.feeOption) {
				if (attributes.feeOption instanceof Models.FeeOptionEntity) {
					this.feeOption = attributes.feeOption;
					this.feeOptionId = attributes.feeOption.id;
				} else {
					this.feeOption = new Models.FeeOptionEntity(attributes.feeOption);
					this.feeOptionId = this.feeOption.id;
				}
			} else if (attributes.feeOptionId !== undefined) {
				this.feeOptionId = attributes.feeOptionId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
