import React from 'react';
import { EventContinueButton } from 'Views/Components/_HumanWritten/EventsBookingWizard/BottomBar/EventContinueButton';
import { EventBackButton } from 'Views/Components/_HumanWritten/EventsBookingWizard/BottomBar/EventBackButton';
import { EventBookingWizardData } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { EventBookingWizardTab } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizard';
import {
	AcceptTandCsCheckbox,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/AcceptTandCsCheckbox';

export interface EventBookingWizardBottomBarProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (newData: EventBookingWizardData) => void;
	tabs: EventBookingWizardTab[],
	currentTabIndex: number,
	onClickContinue: () => void;
	onClickBack: () => void;
	isIpad: boolean
	onTimerExpiry: () => void;
	ticketsPageUrl: string;
	continueBtnText?: string;
	continueBtnLoadingText?: string;
	continueBtnProps?: React.ButtonHTMLAttributes<Element>;
	disableContinueButton: boolean;
	tabIsLoading: boolean;
}

export function EventBookingWizardBottomBar({
	tabs,
	currentTabIndex,
	onClickContinue,
	onClickBack,
	isIpad,
	wizardData,
	onUpdateWizardData,
	onTimerExpiry,
	ticketsPageUrl,
	continueBtnText,
	continueBtnLoadingText,
	continueBtnProps,
	disableContinueButton,
	tabIsLoading,
}: EventBookingWizardBottomBarProps) {
	const showTsAndCs = tabs[currentTabIndex].showTAndCsButton;
	const renderMobileButtons = () => {
		return (
			<>
				{ showTsAndCs
					? (
						<>
							<AcceptTandCsCheckbox
								checked={wizardData.acceptedTsAndCs}
								setChecked={(checked: boolean) => {
									const newData = { ...wizardData };
									newData.acceptedTsAndCs = checked;
									onUpdateWizardData(newData);
								}}
							/>
							<EventContinueButton
								onClick={onClickContinue}
								disabled={!wizardData.acceptedTsAndCs || disableContinueButton}
								buttonProps={continueBtnProps}
							>
								{continueBtnText}
							</EventContinueButton>
						</>
					)
					: (
						<>
							<EventBackButton
								onClick={onClickBack}
								tabs={tabs}
								currentTabIndex={currentTabIndex}
								isIpad={isIpad}
								onTimerExpiry={onTimerExpiry}
								ticketsPageUrl={ticketsPageUrl}
								bulkBooking={false}
								forEventBooking
							/>
							<EventContinueButton
								onClick={onClickContinue}
								buttonProps={continueBtnProps}
								disabled={disableContinueButton}
							>
								{tabIsLoading ? continueBtnLoadingText : continueBtnText}
							</EventContinueButton>
						</>
					)}
			</>
		);
	};

	const renderWebButtons = () => {
		if (showTsAndCs) {
			return (
				<>
					<AcceptTandCsCheckbox
						checked={wizardData.acceptedTsAndCs}
						setChecked={(checked: boolean) => {
							const newData = { ...wizardData };
							newData.acceptedTsAndCs = checked;
							onUpdateWizardData(newData);
						}}
					/>
					<EventContinueButton
						onClick={onClickContinue}
						key="t-and-cs-continue-btn"
						disabled={(showTsAndCs && !wizardData.acceptedTsAndCs) || disableContinueButton}
						buttonProps={continueBtnProps}
					>
						{continueBtnText}
					</EventContinueButton>
				</>
			);
		}
		return (
			<>
				<EventBackButton
					onClick={onClickBack}
					tabs={tabs}
					currentTabIndex={currentTabIndex}
					isIpad={isIpad}
					key="back--btn"
					onTimerExpiry={onTimerExpiry}
					ticketsPageUrl={ticketsPageUrl}
					bulkBooking={false}
					forEventBooking
				/>
				<EventContinueButton
					key="continue-btn"
					onClick={onClickContinue}
					disabled={disableContinueButton ?? false}
					buttonProps={continueBtnProps}
				>
					{tabIsLoading ? continueBtnLoadingText : continueBtnText}
				</EventContinueButton>
			</>
		);
	};

	return (
		<div className="event-booking-wizard-step-bottom-bar">
			<div className="event-booking-wizard-step-btn-group bottom-navigation-btn-group">
				{ isIpad ? renderMobileButtons() : renderWebButtons()}
			</div>
		</div>
	);
}
