import passengerTypeStore from 'Models/PassengerTypeStore';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import { transformBookingToBookingFormState } from '../../FerryCheckIn/BookingForm/BookingFormUtils';
import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { ScannerResultData } from '../Types/scannerResultState';
import { toPlural } from './toPlural';

export const checkInBookingOverviewDtoToScannerResultData = (booking: CheckInBookingOverviewDto): ScannerResultData => {
	return {
		checkedIn: booking.checkedIn,
		id: booking.id,
		driver: `${booking.bookedSummary.driverFirstName} ${booking.bookedSummary.driverLastName}`,
		cargoId: booking.bookedSummary.cargoInfo?.cargoIdentification,
		firstTimer: booking.firstTimeOnRoute,
		tag: booking.user.userDisplayName?.displayName, // userDisplayName could be null
		passengers: passengerTypeStore.getPassengerInfoFromFormState(transformBookingToBookingFormState(booking), true),
		cargoLengthLabel: booking.bookedSummary.cargoInfo?.selectedLength.label,
		cargoLengthValue: booking.bookedSummary.cargoInfo?.selectedLength.value,
		cargoMake: booking.bookedSummary.cargoInfo?.cargoType.cargoMake,
		cargoModel: booking.bookedSummary.cargoInfo?.cargoType.cargoModel,
		towOnLengthLabel: booking.bookedSummary.towOnInfo?.selectedLength.label,
		towOnLengthValue: booking.bookedSummary.towOnInfo?.selectedLength.value,
		addOns: (booking.bookedSummary.additionalBookingOptions ?? [])
			.map(({ quantity, option }) => {
				const name = option.name.toLowerCase();
				const { order } = option;
				return {
					order,
					quantity,
					label: quantity > 1 ? toPlural(name) : name,
				};
			})
			.sort(compareFnByNumberAsc(x => x.order)),
	};
};
