import addOnStore from 'Models/AddOnStore';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { SelectedTrips } from '../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { VisibilityStatus } from '../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import { TabSelected } from '../../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export function getFerryAndRouteIds(trips: SelectedTrips, mode: 'departing' | 'returning' | 'all' = 'all'): string[] {
	const ids: (string | undefined)[] = [];
	if (mode === 'departing' || mode === 'all') {
		ids.push(trips.departingTrip?.routeId);
		ids.push(trips.departingTrip?.ferryId);
	}
	if (mode === 'returning' || mode === 'all') {
		ids.push(trips.returningTrip?.routeId);
		ids.push(trips.returningTrip?.ferryId);
	}

	return ids.filter(x => x !== undefined) as string[];
}

export function getAddOnsTabVisibility(tabSelected: TabSelected, trips: SelectedTrips): VisibilityStatus {
	if (tabSelected === 'vehicle') {
		return VisibilityStatus.EXCLUDED;
	}

	if (!addOnStore.hasPassengerAddOns) {
		if (whiteLabelStore.passengerNoteEnabled) {
			// Even if there are no passenger add-ons, we need the Add-ons tab for additional details textbox
			return VisibilityStatus.VISIBLE;
		}
		return VisibilityStatus.EXCLUDED;
	}

	const paths = window.location.pathname.split('/');
	if (paths.length !== 0 && paths[paths.length - 1] === 'add-ons') {
		return VisibilityStatus.VISIBLE;
	}

	// Only show Add-ons tab if the trip the user has selected has additional booking options available

	if (trips.departingTrip === undefined && trips.returningTrip === undefined) {
		return VisibilityStatus.EXCLUDED;
	}

	const ids = getFerryAndRouteIds(trips);

	if (ids.length === 0) {
		return VisibilityStatus.EXCLUDED;
	}

	const ferriesAndRoutes = addOnStore.getFerriesAndRoutes();
	for (const id of ids) {
		if (ferriesAndRoutes.has(id)) {
			return VisibilityStatus.VISIBLE;
		}
	}

	return VisibilityStatus.EXCLUDED;
}
