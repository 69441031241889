import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { UserEntity } from 'Models/Entities';
import { Display, Sizes } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { DetailFieldsReadOnly } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/DetailFieldsReadOnly';
import { password } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/AccountFieldsUtils';
import { AccountDetailsTabHeader } from 'Views/Components/_HumanWritten/AccountDetails/AccountDetailsTabHeader';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';

export interface UserAccountFieldsProps {
	user: UserEntity;
	mode: EntityFormMode;
	setMode: (mode: EntityFormMode) => void;
	staffView?: boolean;
	specialState: {
		email: string,
		phone: string,
	}
	errors: FormErrors<UserEntity>;
	setErrors: (errors: FormErrors<UserEntity>) => void;
}

function DetailFields({
	user,
	mode,
	setMode,
	staffView,
	specialState,
	errors,
	setErrors,
}: UserAccountFieldsProps) {
	if (mode === EntityFormMode.VIEW) {
		return (
			<DetailFieldsReadOnly
				user={user}
				mode={mode}
				setMode={setMode}
				updatePasswordButton={updatePasswordLink(staffView ?? false, user)}
			/>
		);
	}
	return (
		<div className="user-account-fields">
			<AccountDetailsTabHeader headerText="Account details" />
			<TextField
				id="first-name"
				className="first-name"
				model={user}
				modelProperty="firstName"
				label="First name"
				isRequired
				placeholder="First name"
				errors={errors.firstName}
			/>
			<TextField
				id="last-name"
				className="last-name"
				model={user}
				modelProperty="lastName"
				label="Last name"
				isRequired
				placeholder="Last name"
				errors={errors.lastName}
			/>
			<div className="user-profile-password">
				<TextField
					id="password"
					className="password-view"
					model={password}
					modelProperty="password"
					label="Password"
					isRequired
					isReadOnly
					inputClassName="password-input"
					sideButton={updatePasswordLink(staffView ?? false, user)}
					errors={errors.password}
				/>
			</div>
			<TextField
				id="email"
				className="email"
				model={specialState}
				modelProperty="email"
				label="Email address"
				isRequired
				placeholder="Email address"
				isDisabled={store.isStaff && !staffView}
				errors={errors.email}
			/>
			<TextField
				id="phone"
				className="phone"
				model={specialState}
				modelProperty="phone"
				label="Mobile number"
				isRequired
				placeholder="Mobile number"
				errors={errors.phone}
			/>
			<TextField
				id="postcode"
				className="postcode"
				model={user}
				modelProperty="postcode"
				label="Postcode"
				isRequired
				placeholder="Postcode"
				errors={errors.postcode}
			/>
		</div>
	);
}

export function updatePasswordLink(staffView: boolean, user: UserEntity) {
	return (
		<Link
			to={{
				pathname: '/update-password',
				state: staffView
					? { email: user.email, name: user.firstName }
					: undefined,
			}}
			className={classNames('btn', 'update-password', Display.Solid, Sizes.Small, 'side-button')}
		>
			Update
		</Link>
	);
}

export default observer(DetailFields);
