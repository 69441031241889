/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminEventBookingDetailEntity from 'Models/Security/Acl/AdminEventBookingDetailEntity';
import ManagerEventBookingDetailEntity from 'Models/Security/Acl/ManagerEventBookingDetailEntity';
import StaffEventBookingDetailEntity from 'Models/Security/Acl/StaffEventBookingDetailEntity';
import UserEventBookingDetailEntity from 'Models/Security/Acl/UserEventBookingDetailEntity';
import InvoicedUserEventBookingDetailEntity from 'Models/Security/Acl/InvoicedUserEventBookingDetailEntity';
import FixedPriceUserEventBookingDetailEntity from 'Models/Security/Acl/FixedPriceUserEventBookingDetailEntity';
import VisitorsEventBookingDetailEntity from 'Models/Security/Acl/VisitorsEventBookingDetailEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEventBookingDetailEntityAttributes extends IModelAttributes {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;

	alteration?: Models.AlterationEntity |
		Models.IAlterationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EventBookingDetailEntity', 'Event Booking Detail')
// % protected region % [Customise your entity metadata here] end
export default class EventBookingDetailEntity extends Model
	implements IEventBookingDetailEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminEventBookingDetailEntity(),
		new ManagerEventBookingDetailEntity(),
		new StaffEventBookingDetailEntity(),
		new UserEventBookingDetailEntity(),
		new InvoicedUserEventBookingDetailEntity(),
		new FixedPriceUserEventBookingDetailEntity(),
		new VisitorsEventBookingDetailEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * The first name of the person making the event booking.
	 */
	@Validators.Required()
	@observable
	@attribute<EventBookingDetailEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	/**
	 * The last name of the person making the event booking.
	 */
	@Validators.Required()
	@observable
	@attribute<EventBookingDetailEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	/**
	 * The email address of the person making the event booking.
	 */
	@Validators.Required()
	@observable
	@attribute<EventBookingDetailEntity, string>()
	@CRUD({
		name: 'Email',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * The phone number of the person making the event booking.
	 */
	@Validators.Required()
	@observable
	@attribute<EventBookingDetailEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alteration',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.AlterationEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'alterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alteration?: Models.AlterationEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEventBookingDetailEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEventBookingDetailEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.alteration !== undefined) {
				if (attributes.alteration === null) {
					this.alteration = attributes.alteration;
				} else if (attributes.alteration instanceof Models.AlterationEntity) {
					this.alteration = attributes.alteration;
				} else {
					this.alteration = new Models.AlterationEntity(attributes.alteration);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		alteration {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			alteration: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'alteration',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EventBookingDetailEntity.prototype, 'created');
CRUD(modifiedAttr)(EventBookingDetailEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
