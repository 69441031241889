import { useState, useEffect } from 'react';

function useDeviceDetect() {
	const checkForMobileDevice = () => {
		const windowWidth = window.innerWidth;
		return windowWidth <= 769.5;
	};

	const checkForIpadDevice = () => {
		const windowWidth = window.innerWidth;
		return windowWidth <= 820;
	};

	const checkForFerryScheduleFilterSize = () => {
		const windowWidth = window.innerWidth;
		return windowWidth <= 1180;
	};

	const [isMobile, setIsMobile] = useState(checkForMobileDevice());
	const [isIpad, setIsIpad] = useState(checkForIpadDevice());
	const [ferryScheduleFilterSize, setFerryScheduleFilterSize] = useState(checkForFerryScheduleFilterSize());

	useEffect(() => {
		const handlePageResized = () => {
			setIsMobile(checkForMobileDevice);
			setIsIpad(checkForIpadDevice);
			setFerryScheduleFilterSize(checkForFerryScheduleFilterSize);
		};

		window.addEventListener('resize', handlePageResized);
		window.addEventListener('orientationchange', handlePageResized);
		window.addEventListener('load', handlePageResized);
		window.addEventListener('reload', handlePageResized);

		return () => {
			window.removeEventListener('resize', handlePageResized);
			window.removeEventListener('orientationchange', handlePageResized);
			window.removeEventListener('load', handlePageResized);
			window.removeEventListener('reload', handlePageResized);
		};
	}, []);

	return {
		isMobile,
		isIpad,
		ferryScheduleFilterSize,
	};
}

export default useDeviceDetect;
