import React, { useEffect, useState } from 'react';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	clearEventBookingWizardData,
	clearOldEventBookingWizardData,
	EventBookingWizardData,
	getEventBookingWizardData, getEventBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import {
	EventBookingWizardWrapInner,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventBookingWizardWrapInner';
import { BookingWizardWrapProps } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { GetEventDetails } from 'Services/Api/_HumanWritten/EventDetailEntityService';
import { EventDetailEntity } from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import {
	clearEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	getCachedEventBookingWizardData,
} from '../FerryTripBookingWizard/BookingWizardHelpers';
import { useHistory } from 'react-router';

export function EventBookingWizardWrap(props: BookingWizardWrapProps) {
	const { queryParams } = props;
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [wizardData, setWizardData] = useState<EventBookingWizardData>();
	const [eventDetails, setEventDetails] = useState<EventDetailEntity | null>(null);

	useEffect(() => {
		setLoading(true);
		if (getEventBookingWizardDataCacheToken() != null) {
			getCachedEventBookingWizardData()
				.then(data => {
					if (data === null) {
						return;
					}
					setWizardData(data);
					setLoading(false);
				})
				.catch(e => {
					console.error(e);
					// history.push('/upcoming-events');
				});
		} else {
			const existingWizardData = getEventBookingWizardData();
			if (isNotNullOrUndefined(queryParams?.eventId) && queryParams?.eventId !== existingWizardData?.eventId) {
				clearEventBookingTransactionIdFromStorage();
				clearOldEventBookingWizardData();
				clearEventBookingWizardData();
			}
			setWizardData(getEventBookingWizardData(queryParams?.eventId as string));
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNotNullOrUndefined(wizardData) && wizardData?.eventId !== null) {
			GetEventDetails(wizardData.eventId).then(x => {
				setEventDetails(new EventDetailEntity(x));
			});
		}
	}, [wizardData?.eventId]);

	if (loading || isNullOrUndefined(wizardData) || isNullOrUndefined(eventDetails)) {
		return <LottieSpinner />;
	}

	return (
		<EventBookingWizardWrapInner
			eventDetails={eventDetails}
			bookingWizardData={wizardData}
			setBookingWizardData={setWizardData}
		/>
	);
}
