import * as React from 'react';
import { FerryTripEntity } from 'Models/Entities';
import { Link, useHistory } from 'react-router-dom';
import If from 'Views/Components/If/If';
import useStore from 'Hooks/useStore';
import classNames from 'classnames';
import { useState } from 'react';
import { Button } from 'Views/Components/Button/Button';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	getMinimumPriceFromFerryTripDynamicPrices,
	getMinimumPriceFromFerryTripPrices,
} from 'Util/_HumanWritten/PriceUtils';
import {
	calculateAvailabilityFromFerryTrip,
	FerryTripAvailability,
} from 'Util/_HumanWritten/CapacityCalculationUtils';
import {
	getCSSClassForFerryScheduleTripCardStatus,
	getTripTimeFromFerryTrip,
} from 'Util/_HumanWritten/TicketsTabTripUtils';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { themeStore } from '../../../../Models/ThemeStore';

export interface TripCardProps {
	ferryTrip: FerryTripEntity,
	isMobile: boolean,
	index: number,
	editUrl: string,
	bookingWizardUrl: string;
	tripToEdit: React.MutableRefObject<FerryTripEntity | null>;
	bulkBookingTrips: string[] | null;
	onClickTrip: (tripId: string) => void;
}

function bulkBookingTripSelectable(
	bulkBookingTrips: string[] | null,
	availability: FerryTripAvailability,
	isManager: boolean,
	isInvoicedUser: boolean,
	departed: boolean,
	tripIsClosed: boolean,
): boolean {
	if (bulkBookingTrips === null) {
		return false;
	}

	if (availability.passenger < 1 || availability.vehicle < 1) {
		return isManager;
	}

	return !(isInvoicedUser && (departed || tripIsClosed));
}

export function FerryTripCard({
	ferryTrip,
	isMobile,
	index,
	bookingWizardUrl,
	tripToEdit,
	bulkBookingTrips,
	onClickTrip,
}: TripCardProps) {
	const store = useStore();
	const history = useHistory();

	const availability = calculateAvailabilityFromFerryTrip(ferryTrip);
	const tripHasDeparted = !ferryTrip.departureDateTime || (ferryTrip.departureDateTime < new Date());
	const tripIsClosed = ferryTrip.closed;
	const disabledTripCard = tripHasDeparted || tripIsClosed;

	const [toggle, setToggle] = useState<boolean>(false);

	const bulkBookingSelectable: boolean = bulkBookingTripSelectable(
		bulkBookingTrips,
		availability,
		store.isManager,
		store.isInvoicedUser,
		tripHasDeparted,
		tripIsClosed,
	);

	const classnames = classNames(
		'trip-card-tile',
		`row-${index + 1}`,
		getCSSClassForFerryScheduleTripCardStatus(ferryTrip, availability),
		// Show 'book now' button when user is staff
		{ 'staff-view': store.isStaff },
	);

	const onClick = () => {
		if (bulkBookingSelectable) {
			onClickTrip(ferryTrip.id);
		}

		if (store.isStaff) {
			setToggle(state => !state);
			return;
		}

		if (isMobile && !disabledTripCard) {
			store.routerHistory.push(bookingWizardUrl);
		}
	};

	const tripCardClasses = [classnames];
	const bulkBookingSelectorClasses = ['bulk-booking__selector'];
	const bulkBookingSelected = isNotNullOrUndefined(bulkBookingTrips) && bulkBookingTrips.includes(ferryTrip.id);

	if (bulkBookingTrips !== null) {
		tripCardClasses.push('trip-card__bulk-booking');

		if (bulkBookingSelected) {
			tripCardClasses.push('bulk-booking__selected');
			bulkBookingSelectorClasses.push('active');
		}
		if (!bulkBookingSelectable) {
			tripCardClasses.push('bulk-booking__not-selectable');
		}
	}

	const price = () => {
		if (whiteLabelStore.defaultBookingType === 'vehicle' && ferryTrip.ferry?.vehicleCapacity > 0) {
			return Math.floor(
				getMinimumPriceFromFerryTripDynamicPrices(ferryTrip.dynamicMeasurementPrices)?.amount
				?? getMinimumPriceFromFerryTripPrices(ferryTrip.prices)?.amount
				?? 0,
			);
		}
		return ferryTrip.adultPassengerPrice;
	};

	const showSoldOut = (availability.passenger === 0 && availability.vehicle < 1)
		|| (availability.passenger < whiteLabelStore.minAdultsForVehicle);

	let { notes } = ferryTrip;
	if (!store.isStaff) {
		notes = notes?.filter(x => x.showToCustomers);
	}

	return (
		<div
			className={classNames(tripCardClasses)}
			id={ferryTrip.id}
			onClick={onClick}
			role="button"
			tabIndex={index}
			onKeyDown={() => undefined}
		>
			<div className="trip-card__header">
				<h5 className="trip-card-departure-time">
					{getTripTimeFromFerryTrip(ferryTrip)}
					<If condition={notes?.length > 0}>
						<CustomTooltip
							id="notes-tool-tip"
							toolTipPosition="top-right"
							imageId={themeStore.getDefaultFerryTripIcon}
						>
							{notes.map(x => (
								<p key={x.id}>{x.content}</p>
							))}
						</CustomTooltip>
					</If>
				</h5>
				<p className={classNames('trip-card-price', { hide: isMobile })}>
					{`From $${price()}`}
				</p>
			</div>
			{(!disabledTripCard || store.isStaff) && (
				<div className="trip-card-spaces-remaining-container">
					<p className={`spaces-remaining-label ${showSoldOut ? 'visibility-hidden' : ''}`}>Space left:</p>
					<div className="spaces-remaining-amount">
						{showSoldOut
							? <div>SOLD OUT</div>
							: (
								<>
									<div className="passenger-capacity icon-chevron-passenger icon-left">
										{availability.passenger > 0 ? availability.passenger : 'X'}
									</div>
									<If condition={availability.totalVehicle > 0}>
										<div className={`vehicle-capacity icon-left ${disabledTripCard
											? 'icon-custom-vehicle-disabled'
											: whiteLabelStore.vehicleIconDarkClassName}`}
										>
											{availability.vehicle >= 1
												&& availability.passenger >= whiteLabelStore.minAdultsForVehicle
												? availability.vehicle
												: 'X'}
										</div>
										{availability.vehicle >= 1
											&& availability.passenger >= whiteLabelStore.minAdultsForVehicle
										&& whiteLabelStore.config.trailersEnabled
										&& (
											<div className="trailer-capacity icon-chevron-trailer icon-left">
												{availability.trailer > 0
													? <span className="available-trailer icon-tick icon-only" />
													: 'X'}
											</div>
										)}
									</If>
								</>
							)}
					</div>
				</div>
			)}
			<If condition={bulkBookingTrips === null}>
				<div className={`trip-card-book-now-button-container ${!store.isStaff ? 'top-margin' : ''}`}>
					<If condition={store.isStaff}>
						<Button
							onClick={e => {
								e.stopPropagation();
								tripToEdit.current = ferryTrip;
								history.push(`/ferry-schedule/edit/${ferryTrip.id}`);
							}}
							className="edit-ferry-trip-button hide-underline"
						>
							View
						</Button>
					</If>
					<Link
						to={bookingWizardUrl}
						className={`${store.isStaff
							? 'edit-ferry-trip-button'
							: 'book-now-button'} hide-underline`}
					>
						Book {ferryTrip.hidden ? <span className="hidden-text">(private)</span> : ''}
					</Link>
					<If condition={store.isStaff}>
						<Link to={`/check-in/${ferryTrip.id}`} className="book-now-button hide-underline">
							Check in
						</Link>
					</If>
				</div>
				<If condition={isMobile && toggle}>
					<div className="mobile-action-btns">
						<Link
							to={bookingWizardUrl}
							className="btn btn--primary secondary-btn hide-underline"
						>
							Book
						</Link>
						<Link
							to={`/check-in/${ferryTrip.id}`}
							className="btn btn--primary primary-btn hide-underline"
						>
							Check in
						</Link>
					</div>
				</If>
			</If>
			<If condition={(disabledTripCard && !store.isStaff)}>
				<div className="disabled-ferry-container">
					<p className="disabled-ferry-message">
						{tripHasDeparted ? 'Departed' : 'Closed'}
					</p>
				</div>
			</If>
			<If condition={bulkBookingSelectable}>
				<div className="bulk-booking__selector-wrap">
					<span className={classNames(bulkBookingSelectorClasses)}>
						<span className="bulk-booking__selector-internal" />
					</span>
				</div>
			</If>
		</div>
	);
}
