import { ScrollToElement } from './ScrollToElement';

export function ScrollToError(scrollTo: ScrollLogicalPosition = 'start') {
	// We need to do this so that it executes after the setErrors is completed
	// otherwise it will execute before the errors are set and not work as intended.
	setTimeout(() => {
		const myElement = document.querySelectorAll('.input-group--error');
		ScrollToElement(myElement, scrollTo);
	}, 0);
}
