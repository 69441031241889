import React from 'react';
import { observer } from 'mobx-react';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { IPriceLineItem } from 'Services/Api/_HumanWritten/PricingService/PricingResult';

export interface GiftUsageLinesProps {
	giftCertificateUsages: IPriceLineItem[];
	onClearGiftCertificate?: ((id: string) => void);
}

function GiftUsageLines({ giftCertificateUsages, onClearGiftCertificate }: GiftUsageLinesProps) {
	const giftUsageToDisplay = giftCertificateUsages.filter(x => x.price !== 0);
	return (
		<>
			{giftUsageToDisplay.map(usage => (
				<CartCalculationLineItem
					keyToUse={usage?.id}
					lineItemDisplayName={usage.description}
					lineItemPrice={formatPriceDisplay(usage.price, true)}
					className="gift-certificate"
					hasRemovalButton={isNotNullOrUndefined(onClearGiftCertificate)}
					removalOnClick={() => {
						if (isNotNullOrUndefined(onClearGiftCertificate) && isNotNullOrUndefined(usage?.id)) {
							return onClearGiftCertificate(usage.id);
						}
						return () => {};
					}}
				/>
			))}
		</>
	);
}

export default observer(GiftUsageLines);
