import classNames from 'classnames';
import useStore from 'Hooks/useStore';
import { store as staticStore } from 'Models/Store';
import * as React from 'react';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { IModalProps } from 'Views/Components/Modal/Modal';

type DefaultModalClassNames = 'base-modal-width' | 'base-modal-width-lg';

/**
 * Same functionality as `modal.show`, but sets a standard modal width using classname.
 *
 * Typically, the classnames used are
 *
 *    - base-modal-width for width of 90% (max 368px)
 *    - base-modal-width-lg for width of 95% (max 668px)
 */
export function showModal(args: {
	key: string,
	content: React.ReactNode,
	className?: DefaultModalClassNames,
	modalProps?: Partial<IModalProps>,
}) {
	const {
		key,
		content,
		className = 'base-modal-width',
		modalProps,
	} = args;

	staticStore.modal.show(
		key,
		content,
		{ className, ...modalProps },
	);
}

export interface BaseModalContentProps {
	className?: string;
	title: React.ReactNode;
	body: React.ReactNode;
	footer?: React.ReactNode;
}

export function BaseModalContent({
	title,
	body,
	className,
	footer,
}: BaseModalContentProps) {
	const store = useStore();
	return (
		<div className={classNames('base-modal', className)}>
			<div key="header" className="modal__header">
				<p key="header-item" className="modal__title">{title}</p>
				<Button
					key="cross-close-icon"
					className="close-icon-btn"
					display={Display.Text}
					colors={Colors.Disabled}
					onClick={() => store.modal.hide()}
					icon={{
						icon: 'cross',
						iconPos: 'icon-right',
					}}
				/>
			</div>
			<div key="body" className="modal__body">
				{body}
			</div>
			<div key="footer" className="modal__footer">
				{footer}
			</div>
		</div>
	);
}
