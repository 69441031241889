import React, { useEffect } from 'react';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Link } from 'react-router-dom';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { ScrollToItem } from 'Validators/Functions/HumanWritten/ScrollToElement';
import { stringNotEmpty } from 'Util/TypeGuards';
import { EventDetailEntity } from '../../../../../../Models/Entities';

export type TermsAndConditionsType = 'vehicle' | 'passenger' | 'event';
export interface TermsAndConditionsProps {
	acceptedTsandCs: boolean;
	navigateToNextStep: () => void;
	isMobile: boolean;
	termsAndConditionsType: TermsAndConditionsType;
	isAlteration: boolean;
	bookingToEditId?: string;
	eventDetails?: EventDetailEntity;
}

export function TermsAndConditions({
	acceptedTsandCs,
	navigateToNextStep,
	isMobile,
	termsAndConditionsType,
	isAlteration,
	bookingToEditId,
	eventDetails,
}: TermsAndConditionsProps) {
	if (acceptedTsandCs) {
		navigateToNextStep();
	}

	let termsAndConditions = termsAndConditionsType === 'vehicle'
		? whiteLabelStore.vehicleAngGeneralTerms
		: whiteLabelStore.passengerAngGeneralTerms;

	if (termsAndConditionsType === 'event') {
		termsAndConditions = eventDetails?.termsAndConditions ?? 'N/a';
	}

	useEffect(() => {
		ScrollToItem('.terms-and-conditions-title', 'end');
	}, []);

	return (
		<div className={`terms-and-conditions-container ${termsAndConditionsType === 'event' ? 'event-terms-and-conditions' : ''}`}>
			{
				isMobile
					? (
						<h2 className="booking-wizard__tab-header terms-and-conditions-title">
							Terms & Conditions
							<If condition={isAlteration && stringNotEmpty(bookingToEditId)}>
								<div>
									<Link to={`/bookings/${bookingToEditId}`}>
										<Icon name="cross" classname="icon" />
									</Link>
								</div>
							</If>
						</h2>
					)
					: (
						<h2 className="booking-wizard__tab-header terms-and-conditions-title">Terms & Conditions
							<If condition={isAlteration && stringNotEmpty(bookingToEditId)}>
								<div>
									<Link to={`/bookings/${bookingToEditId}`}>
										<Icon name="cross" classname="icon" />
									</Link>
								</div>
							</If>
						</h2>
					)
			}
			{/* eslint-disable react/no-danger */}
			{/* nosemgrep: eslint.react-dangerouslysetinnerhtml */}
			<div className="terms-and-conditions__content">
				<div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
			</div>
			{/* eslint-enable react/no-danger */}
		</div>
	);
}
