import { useState } from 'react';

export const useBoolean = (defaultValue = false) => {
	const [value, setValue] = useState(defaultValue);
	const on = () => setValue(true);
	const off = () => setValue(false);
	const toggle = () => setValue(state => !state);
	return {
		value,
		on,
		off,
		toggle,
	};
};
