/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsTicketReferenceManyToMany from '../Security/Acl/VisitorsTicketReferenceManyToMany';
import UserTicketReferenceManyToMany from '../Security/Acl/UserTicketReferenceManyToMany';
import StaffTicketReferenceManyToMany from '../Security/Acl/StaffTicketReferenceManyToMany';
import ManagerTicketReferenceManyToMany from '../Security/Acl/ManagerTicketReferenceManyToMany';
import AdminTicketReferenceManyToMany from '../Security/Acl/AdminTicketReferenceManyToMany';
import InvoicedUserTicketReferenceManyToMany from '../Security/Acl/InvoicedUserTicketReferenceManyToMany';
import FixedPriceUserTicketReferenceManyToMany from '../Security/Acl/FixedPriceUserTicketReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPassengerDetailTicketAttributes extends IModelAttributes {
	passengerDetailId: string;
	ticketId: string;

	passengerDetail: Models.PassengerDetailEntity | Models.IPassengerDetailEntityAttributes;
	ticket: Models.TicketEntity | Models.ITicketEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('PassengerDetailTicket')
export default class PassengerDetailTicket
	extends Model
	implements IPassengerDetailTicketAttributes {
	public static acls: IAcl[] = [
		new VisitorsTicketReferenceManyToMany(),
		new UserTicketReferenceManyToMany(),
		new StaffTicketReferenceManyToMany(),
		new ManagerTicketReferenceManyToMany(),
		new AdminTicketReferenceManyToMany(),
		new InvoicedUserTicketReferenceManyToMany(),
		new FixedPriceUserTicketReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public passengerDetailId: string;

	@observable
	@attribute()
	public ticketId: string;

	@observable
	@attribute({ isReference: true })
	public passengerDetail: Models.PassengerDetailEntity;

	@observable
	@attribute({ isReference: true })
	public ticket: Models.TicketEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IPassengerDetailTicketAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.passengerDetailId) {
				this.passengerDetailId = attributes.passengerDetailId;
			}
			if (attributes.ticketId) {
				this.ticketId = attributes.ticketId;
			}

			if (attributes.passengerDetail) {
				if (attributes.passengerDetail instanceof Models.PassengerDetailEntity) {
					this.passengerDetail = attributes.passengerDetail;
					this.passengerDetailId = attributes.passengerDetail.id;
				} else {
					this.passengerDetail = new Models.PassengerDetailEntity(attributes.passengerDetail);
					this.passengerDetailId = this.passengerDetail.id;
				}
			} else if (attributes.passengerDetailId !== undefined) {
				this.passengerDetailId = attributes.passengerDetailId;
			}

			if (attributes.ticket) {
				if (attributes.ticket instanceof Models.TicketEntity) {
					this.ticket = attributes.ticket;
					this.ticketId = attributes.ticket.id;
				} else {
					this.ticket = new Models.TicketEntity(attributes.ticket);
					this.ticketId = this.ticket.id;
				}
			} else if (attributes.ticketId !== undefined) {
				this.ticketId = attributes.ticketId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
