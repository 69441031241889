import React from 'react';
import { feeType } from 'Models/Enums';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { observer } from 'mobx-react';
import { TransactionPricingResult } from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import If from 'Views/Components/If/If';
import GstLine from './CartLineItems/GstLine';
import AddedFeeLines from './CartLineItems/AddedFeeLines';
import CreditCardFeeLine from './CartLineItems/CreditCardFeeLine';
import GiftUsageLines from './CartLineItems/GiftUsageLines';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import { Button } from 'Views/Components/Button/Button';
import { RenderAddServiceFeeModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/AddFeeModal';
import {
	useBookingWizardPricingCacheStore,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardPricingCacheStore';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	CartCalculationLineItem,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/CartCalculationLineItem';
import {
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	BookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface TripBookingSummaryTotalProps {
	transactionPricingResults?: TransactionPricingResult;
	afterPayment: boolean;
	cartView: boolean;
	onClearGiftCertificate?: (id: string) => void;
	onClearFee?: (feeId: string) => void;
	onRemoveManagerDiscount?: () => void;
	onRemoveFee?: (feeType: feeType, departureDiscountAmount?: number, returnDiscountAmount?: number) => void;
	tripSummaryLocation: tripSummaryLocationType;
	wizardData?: BookingWizardData;
	refetch?: boolean;
	setRefetch?: (refetch: boolean) => void;
	bookingIds?: string[];
}

function TripBookingSummaryTotal({
	transactionPricingResults,
	afterPayment,
	cartView,
	onClearGiftCertificate,
	onRemoveManagerDiscount,
	onRemoveFee,
	tripSummaryLocation,
	wizardData,
	onClearFee,
	refetch,
	setRefetch,
	bookingIds,
}: TripBookingSummaryTotalProps) {
	const isCartView = cartView || afterPayment;
	const transactionId = getFerryBookingTransactionIdFromStorage() ?? undefined;

	const { getTotalBookingPrice } = useBookingWizardPricingCacheStore();

	let departurePriceIncrease = 0;
	let returnPriceIncrease = 0;
	let totalAlterationFee = 0;
	let basePriceIncrease = 0;
	let totalTrailerFee = 0;

	if (transactionPricingResults?.linkedBookings !== null && wizardData?.wizardMode === 'ALTERATION') {
		transactionPricingResults?.linkedBookings?.forEach(
			x => {
				totalAlterationFee += (x.departureBooking.priceLineItems
					.find(y => y.key === 'AlterationFee')?.price ?? 0);
				departurePriceIncrease += (x.departureBooking.priceLineItems
					.find(y => y.key === 'BaseCargo')?.price ?? 0);
				departurePriceIncrease += (x.departureBooking.priceLineItems
					.find(y => y.key === 'BaseTowOn')?.price ?? 0);
				departurePriceIncrease += (x.departureBooking.priceLineItems
					.find(y => y.key === 'TicketPriceIncrease')?.price ?? 0);
				totalTrailerFee += (x.departureBooking.priceLineItems
					.find(y => y.key === 'TowOnCancellationFee')?.price ?? 0);

				totalAlterationFee += (x.returnBooking?.priceLineItems
					?.find(y => y.key === 'AlterationFee')?.price ?? 0);
				returnPriceIncrease += (x.returnBooking?.priceLineItems
					?.find(y => y.key === 'BaseCargo')?.price ?? 0);
				returnPriceIncrease += (x.returnBooking?.priceLineItems
					?.find(y => y.key === 'BaseTowOn')?.price ?? 0);
				returnPriceIncrease += (x.returnBooking?.priceLineItems
					?.find(y => y.key === 'TicketPriceIncrease')?.price ?? 0);
				totalTrailerFee += (x.returnBooking?.priceLineItems
					.find(y => y.key === 'TowOnCancellationFee')?.price ?? 0);

				basePriceIncrease = departurePriceIncrease + returnPriceIncrease;
			});
	}

	return (
		<div className="total-booking-price">
			<If condition={!isCartView}>
				<CartCalculationLineItem
					keyToUse="subtotal-line-item-key"
					lineItemDisplayName="TOTAL:"
					lineItemPrice={formatPriceDisplay(
						(transactionPricingResults?.totalPrice ?? 0) + getTotalBookingPrice(
							bookingIds,
							isNotNullOrUndefined(wizardData?.bulkBookingTripIds),
							wizardData?.bookingToEdit),
						transactionPricingResults?.isAlteration ?? false,
					)}
					className="total-line-item"
				/>
			</If>
			<If condition={isCartView && isNotNullOrUndefined(transactionPricingResults)}>
				<CartCalculationLineItem
					keyToUse="subtotal-line-item-key"
					lineItemDisplayName="Subtotal (inc. GST):"
					lineItemPrice={formatPriceDisplay(
						(transactionPricingResults?.subtotalPrice ?? 0),
						transactionPricingResults?.isAlteration ?? false,
					)}
					className="subtotal-line-item"
				/>
				<AddedFeeLines
					fees={transactionPricingResults?.priceLineItems
						?.filter(x => x.key === 'ServiceFee') ?? []}
					onRemoveFee={onClearFee}
				/>
				<If condition={store.isStaff && totalAlterationFee > 0}>
					<CartCalculationLineItem
						lineItemDisplayName="Alteration fee:"
						lineItemPrice={formatPriceDisplay(
							totalAlterationFee,
							false,
						)}
						hasRemovalButton={store.isManager && tripSummaryLocation !== 'booking-success'}
						removalOnClick={() => {
							if (store.isManager && onRemoveFee) {
								onRemoveFee('ALTERATION');
							}
						}}
					/>
				</If>
				<If condition={store.isStaff && totalTrailerFee > 0}>
					<CartCalculationLineItem
						lineItemDisplayName="Trailer removal fee:"
						lineItemPrice={formatPriceDisplay(
							totalTrailerFee,
							false,
						)}
						hasRemovalButton={store.isManager && tripSummaryLocation !== 'booking-success'}
						removalOnClick={() => {
							if (store.isManager && onRemoveFee) {
								onRemoveFee('CANCELLATION');
							}
						}}
					/>
				</If>
				<If condition={store.isManager
					&& isNotNullOrUndefined(transactionPricingResults?.priceLineItems?.find(x => x.key === 'ManagerDiscount'))}
				>
					<CartCalculationLineItem
						lineItemDisplayName="Manager discount:"
						lineItemPrice={formatPriceDisplay(
							transactionPricingResults?.priceLineItems?.find(x => x.key === 'ManagerDiscount')?.price ?? 0,
							transactionPricingResults?.isAlteration ?? false,
						)}
						hasRemovalButton={store.isManager && tripSummaryLocation !== 'booking-success'}
						removalOnClick={() => {
							if (store.isManager && onRemoveManagerDiscount) {
								onRemoveManagerDiscount();
							}
						}}
					/>
				</If>
				<If condition={store.isManager
					&& basePriceIncrease > 0
					&& !transactionPricingResults?.priceLineItems?.some(x => x.key === 'ManagerDiscount')}
				>
					<CartCalculationLineItem
						lineItemDisplayName="Ticket price increase:"
						lineItemPrice={formatPriceDisplay(
							basePriceIncrease,
							false,
						)}
						hasRemovalButton={store.isManager && tripSummaryLocation !== 'booking-success'}
						removalOnClick={() => {
							if (store.isManager && onRemoveFee) {
								onRemoveFee('OTHER', departurePriceIncrease, returnPriceIncrease);
							}
						}}
					/>
				</If>
				<GiftUsageLines
					giftCertificateUsages={transactionPricingResults?.priceLineItems
						?.filter(x => x.key === 'GiftCertificate') ?? []}
					onClearGiftCertificate={onClearGiftCertificate}
				/>
				<CreditCardFeeLine
					lineItem={transactionPricingResults?.priceLineItems
						?.find(x => x.key === 'CreditCard') ?? null}
				/>
				<GstLine
					lineItem={transactionPricingResults?.priceLineItems
						?.find(x => x.key === 'Gst') ?? null}
				/>
				<CartCalculationLineItem
					keyToUse="total-line-item-key"
					lineItemDisplayName="TOTAL (inc. GST):"
					lineItemPrice={formatPriceDisplay(
						(transactionPricingResults?.totalPrice ?? 0),
						transactionPricingResults?.isAlteration ?? false,
					)}
					className="total-cart-line"
				/>
			</If>
			<If condition={store.isStaff && (tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in')}>
				<Button
					className="override-total-amount-button"
					onClick={() => {
						RenderAddServiceFeeModal(
							(transactionPricingResults?.subtotalPrice ?? 0),
							tripSummaryLocation,
							wizardData?.wizardMode ?? 'CREATE',
							transactionPricingResults?.priceLineItems
								?.filter(x => x.key === 'ServiceFee')
								?.filter(x => stringNotEmpty(x.id))
								?.map(x => x.id ?? '') ?? [],
							transactionId,
						).then(async () => {
							if (setRefetch) {
								setRefetch(!refetch);
							}
						});
					}}
				>
					Add service fee
				</Button>
			</If>
		</div>
	);
}

export default observer(TripBookingSummaryTotal);
