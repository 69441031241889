import RouteMapRenderableObject
	from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapRenderableObject';
import { RouteEntity } from 'Models/Entities';
import { ILocationAndRoutes, RouteData } from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapHandler';
import RouteMapIcon from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapIcon';

const DESTINATION_ICON = 0xeafa;

export default class RouteMapDestination extends RouteMapIcon implements RouteMapRenderableObject {
	update(route: RouteEntity, locationsAndRoutes: ILocationAndRoutes, routeData: RouteData) {
		const currentLocation = locationsAndRoutes.locations
			.find(l => l.id === route.destinationId);

		this.location = routeData.to;
		this.locationName = currentLocation?.name ?? '';
		this.locationAddress = currentLocation?.address ?? '';

		this.updateScale();
	}

	render(timestamp: number): boolean {
		const circleResult = this.renderCircle();
		const iconResult = this.renderIcon(DESTINATION_ICON);
		const locationResult = this.renderLocation();
		const addressResult = (this.isSelected && this.renderAddress());

		return circleResult || iconResult || locationResult || addressResult;
	}
}
