/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { SERVER_URL } from 'Constants';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'querystring';
import Spinner from 'Views/Components/Spinner/Spinner';
import If from 'Views/Components/If/If';
import axios from 'axios';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { observable, action } from 'mobx';
import alert from '../../Util/ToastifyUtils';
import { store } from 'Models/Store';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';
// % protected region % [Add any extra imports or interfaces here] on begin
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { RedirectLoader } from 'Views/Components/_HumanWritten/RedirectLoader/RedirectLoader';
import { IUserResult } from 'Models/Store';
import { REGISTRATION_CONFIRM_PAGE_TITLE } from 'ConstantPageNames';
import {
	getBookingWizardDataCacheToken,
	saveBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	getEventBookingWizardDataCacheToken,
	saveEventBookingWizardDataCacheToken,
} from '../Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
// % protected region % [Add any extra imports or interfaces here] end

/**
 * Logs the user out of the application
 *
 * Can take a location query param that will redirect after the logout occurs
 */
// % protected region % [Customise class signature and class properties] on begin
@observer
export default class RegistrationConfirmPage extends React.Component<RouteComponentProps> {
	@observable
	private routeToRedirect: string = '/ferry-schedule';

	// % protected region % [Customise class signature and class properties] end
	@observable
	private loading: boolean = false;

	@observable
	private confirmed: boolean = false;

	private confirmEmailData: { token: string, email: string };

	// % protected region % [Override componentDidMount here] on begin
	public componentDidMount() {
		const { location } = this.props;
		const {
			token,
			username,
		} = queryString.parse(location.search.substring(1));

		this.confirmEmailData = {
			token: token as string,
			email: username as string,
		};

		// Automatically continue with registration
		this.onClickConfirm();
	}
	// % protected region % [Override componentDidMount here] end

	public render() {
		// % protected region % [Override render here] on begin
		document.title = REGISTRATION_CONFIRM_PAGE_TITLE;
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;
		if (this.confirmed) {
			let subtitle = 'An exciting adventure awaits...';
			if (getBookingWizardDataCacheToken() !== null) {
				this.routeToRedirect = '/booking-wizard/tickets';
				subtitle = 'Taking you back to where you left off...';
			}
			if (getEventBookingWizardDataCacheToken() !== null) {
				this.routeToRedirect = '/event-booking-wizard/tickets';
				subtitle = 'Taking you back to where you left off...';
			}
			return (
				<RedirectLoader
					title="Good to go!"
					subtitle={subtitle}
					to={this.routeToRedirect}
				/>
			);
		}
		return <></>;
		// % protected region % [Override render here] end
	}

	private onLoginClick = () => {
		// % protected region % [Override onLoginClick here] off begin
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		if (redirect) {
			store.routerHistory.push(`/login?redirect=${redirect}`);
		}
		store.routerHistory.push('/login');
		// % protected region % [Override onLoginClick here] end
	};

	@action
	private onClickConfirm = () => {
		// % protected region % [Override onClickConfirm here] on begin
		this.loading = true;
		axios.post<IUserResult>(`${SERVER_URL}/api/register/confirm-email`, this.confirmEmailData)
			.then(action(response => {
				this.loading = false;
				this.confirmed = true;
				store.setLoggedInUser(response.data);
				const { id } = queryString.parse(this.props.location.search.substring(1));
				if (isNotNullOrUndefined(id) && id !== '') {
					if (this.props.location.pathname.includes('event-booking-wizard')) {
						saveEventBookingWizardDataCacheToken(id.toString());
					} else {
						saveBookingWizardDataCacheToken(id.toString());
					}
				}
			}))
			.catch(action(response => {
				this.loading = false;
				const errorMessages = getErrorMessages(response).map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Email Confirmation was not successful</h6>
						{errorMessages}
					</div>,
					'error',
				);
			}));
		// % protected region % [Override onClickConfirm here] end
	};

	// % protected region % [Add class methods here] off begin
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
