import { IConfigGeneralEntityAttributes } from './Entities';
import { upperCaseFirst } from 'Util/StringUtils';

export class WhiteLabelStore {
	config: IConfigGeneralEntityAttributes;

	constructor(config: IConfigGeneralEntityAttributes) {
		this.config = config;
	}

	public get vehicleLabelPascalCase() {
		if (this.config?.vehicleLabel) {
			return upperCaseFirst(this.config.vehicleTripSelectionButtonLabel.toLowerCase());
		}
		return 'Vehicle';
	}

	public get passengerLabelPascalCase() {
		if (this.config?.vehicleLabel) {
			return upperCaseFirst(this.config.passengerTripSelectionButtonLabel.toLowerCase());
		}
		return 'Walk-on';
	}

	public get vehicleLabelLowerCase() {
		if (this.config?.vehicleLabel) {
			return this.config.vehicleLabel.toLowerCase();
		}
		return 'Vehicle';
	}

	public get minAdultsForVehicle() {
		if (this.config?.vehicleBookingRequiresAdult) {
			return 1;
		}
		return 0;
	}

	public get driverLabel() {
		return this.config.driverDetailsTitle;
	}

	public get driverLabelPascalCase() {
		if (this.driverLabel) {
			return upperCaseFirst(this.driverLabel.toLowerCase());
		}
		return 'Driver';
	}

	public get regoLabelPascalCase() {
		if (this.config.driverDetailsRegoLabel) {
			return upperCaseFirst(this.config.driverDetailsRegoLabel.toLowerCase());
		}
		return 'Rego';
	}

	public get regoPlaceholderPascalCase() {
		if (this.config.driverDetailsRegoPlaceholder) {
			return upperCaseFirst(this.config.driverDetailsRegoPlaceholder.toLowerCase());
		}
		return 'Rego';
	}

	public get vehicleMakeLabelPascalCase() {
		if (this.config.vehicleMakeLabel) {
			return upperCaseFirst(this.config.vehicleMakeLabel.toLowerCase());
		}
		return 'Make';
	}

	public get vehicleMakePlaceholderPascalCase() {
		if (this.config.vehicleMakeInputPlaceholder) {
			return upperCaseFirst(this.config.vehicleMakeInputPlaceholder.toLowerCase());
		}
		return 'Make';
	}

	public get vehicleModelLabelPascalCase() {
		if (this.config.vehicleModelLabel) {
			return upperCaseFirst(this.config.vehicleModelLabel.toLowerCase());
		}
		return 'Model';
	}

	public get vehicleModelPlaceholderPascalCase() {
		if (this.config.vehicleModelInputPlaceholder) {
			return upperCaseFirst(this.config.vehicleModelInputPlaceholder.toLowerCase());
		}
		return 'Make';
	}

	public get defaultRouteId() {
		return this.config.defaultRouteId;
	}

	/**
	 * Typically used like:
	 *
	 *     <div className={whiteLabelStore.vehicleIconDarkClassName} />
	 */
	public get vehicleIconDarkClassName() {
		return 'icon-custom-vehicle-dark';
	}

	public get vehicleIconDarkLargeClassName() {
		return 'icon-custom-vehicle-dark-large';
	}

	/**
	 * Typically used like:
	 *
	 *     <div className={whiteLabelStore.vehicleIconLightClassName} />
	 */
	public get vehicleIconLightClassName() {
		return 'icon-custom-vehicle-light';
	}

	public get passengerNoteEnabled() {
		return this.config.passengerNoteEnabled ?? false;
	}

	public get vehicleNoteEnabled() {
		return this.config.cargoNoteEnabled ?? false;
	}

	public get bookingNoteEnabled() {
		return this.passengerNoteEnabled || this.vehicleNoteEnabled;
	}

	public get cargoBookingNoteLabel() {
		return this.config.cargoBookingNoteLabel ?? 'Additional details';
	}

	public get cargoBookingNotePlaceholder() {
		return this.config.cargoBookingNotePlaceholder ?? 'Enter details';
	}

	public get passengerBookingNoteLabel() {
		return this.config.passengerBookingNoteLabel ?? 'Additional details';
	}

	public get passengerBookingNotePlaceholder() {
		return this.config.passengerBookingNotePlaceholder ?? 'Enter details';
	}

	public get addOnTabSubtitle() {
		return this.config.addOnTabSubtitle ?? `
			Some baggage requires more space than others. Let us know what you'll be bringing on board so
			we can make sure there is room.
		`;
	}

	public get GetVehicleDefaultTripType() {
		return (this.config.vehicleDefaultToReturn ?? true) ? 'return' : 'one way';
	}

	public get GetPassengerDefaultTripType() {
		return (this.config.passengersDefaultToReturn ?? true) ? 'return' : 'one way';
	}

	public get vehicleBookingShouldHaveNoPassengers() {
		return this.config.vehicleBookingRequiresAdult === false ?? false;
	}

	public get defaultBookingType() {
		return this.config.defaultBookingType === 'CARGO' ? 'vehicle' : 'passenger';
	}

	public get vehicleAngGeneralTerms() {
		return this.config.vehicleTerms + this.config.generalTerms;
	}

	public get passengerAngGeneralTerms() {
		return this.config.passengerTerms + this.config.generalTerms;
	}

	public get eventTerms() {
		return this.config.defaultEventTerms;
	}
}

export const whiteLabelStore = new WhiteLabelStore(window.whitelabelData);
