import React from 'react';
import { AdditionalBookingOptionEntity } from 'Models/Entities';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import { AddOnsContext } from './AddOnTab';
import InputCounter from 'Views/Components/_HumanWritten/PassengerAmount/InputCounter';
import { tripDirectionTypes } from 'Models/Enums';
import { AdditionalOption, BookingWizardData, getOldFerryBookingWizardData } from '../../BookingWizardData';

export interface AddOnOptionProps {
	addOn: AdditionalBookingOptionEntity;
	direction: tripDirectionTypes;
}

export function AddOnOption({ addOn, direction }: AddOnOptionProps) {
	const {
		wizardData,
		onUpdateWizardData,
	} = React.useContext(AddOnsContext);

	const onAdd = () => {
		const newWizardData = { ...wizardData };
		const addOns = getWizardDataAddOns(wizardData, direction);
		const option = addOns.find(x => x.optionId === addOn.id);

		if (option) {
			option.amount += 1;
		} else {
			addOns.push({
				optionId: addOn.id,
				optionName: addOn.name,
				optionPrice: addOn.staticPrice,
				gstExempt: addOn.excludeGST,
				amount: 1,
			});
		}

		onUpdateWizardData(newWizardData);
	};

	const onSubtract = () => {
		const newWizardData = { ...wizardData };
		const addOns = getWizardDataAddOns(wizardData, direction);
		const option = addOns.find(x => x.optionId === addOn.id);

		if (option) {
			option.amount -= 1;
		}

		onUpdateWizardData(newWizardData);
	};

	const subtractDisabled = () => {
		const oldWizardData = getOldFerryBookingWizardData();
		const addOns = getWizardDataAddOns(wizardData, direction);
		let minimum = 0;

		if (addOn.removable !== true) {
			const originalOption = direction === 'DEPARTURE'
				? oldWizardData?.departingTripOptions?.find(x => x.optionId === addOn.id)
				: oldWizardData?.returningTripOptions?.find(x => x.optionId === addOn.id);

			minimum = originalOption?.amount ?? 0;
		}

		const option = addOns.find(x => x.optionId === addOn.id);
		if (option) {
			return option.amount <= minimum;
		}

		return false;
	};

	const addDisabled = () => {
		const addOns = getWizardDataAddOns(wizardData, direction);
		const option = addOns.find(x => x.optionId === addOn.id);

		if (option) {
			if (addOn.isSingular) {
				return option.amount > 0;
			}
		}

		return false;
	};

	const amount = () => {
		const addOns = getWizardDataAddOns(wizardData, direction);
		const option = addOns.find(x => x.optionId === addOn.id);

		if (option) {
			return option.amount;
		}

		return 0;
	};

	return (
		<div className="input-counter--box">
			<InputCounter
				title={addOn.name}
				subtitle={displayAsAud(addOn.staticPrice)}
				tooltip={addOn.information}
				amount={amount()}
				subtractDisabled={subtractDisabled()}
				addDisabled={addDisabled()}
				onAdd={onAdd}
				onSubtract={onSubtract}
			/>
		</div>
	);
}

function getWizardDataAddOns(wizardData: BookingWizardData, direction: tripDirectionTypes): AdditionalOption[] {
	if (direction === 'DEPARTURE') {
		return wizardData.departingTripOptions;
	}
	return wizardData.returningTripOptions;
}
