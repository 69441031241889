import { LocationEntity } from '../../../Models/Entities';
import axios from 'axios';
import { buildUrl } from '../../../Util/FetchUtils';
import { LOCATION_ENTITY_URL } from '../../../Constants';
import { ILocationEntityAttributes } from 'Models/Entities/LocationEntity';

export async function FetchLocations(): Promise<LocationEntity[]> {
	const results = await axios.get(
		buildUrl(`${LOCATION_ENTITY_URL}/get-all-locations`),
	);
	return results.data.map((x: Partial<ILocationEntityAttributes> | undefined) => new LocationEntity(x));
}
