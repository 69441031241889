import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { FEATURE_IMAGE_5_URL } from 'Constants';
import { Link } from 'react-router-dom';
import queryString from 'querystring';
import { useState } from 'react';
import { fetchGiftCertificateUsagesByTransactionId } from 'Util/_HumanWritten/FetchGiftCertificates';
import { getGiftCertificateRedeemText } from 'Services/Api/_HumanWritten/ConfigGeneralService';
import { GiftCertificateUsageEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import moment from 'moment/moment';
import useAsync from 'Hooks/useAsync';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { store } from 'Models/Store';
import { BOOKING_CANCEL_SUCCESS_PAGE_TITLE } from 'ConstantPageNames';

export default function BookingCancelSuccessPage(props: RouteComponentProps) {
	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<BookingCancelSuccess routerProps={props} />
		</SecuredPage>
	);
}

export interface BookingCancelSuccessProps {
	routerProps: RouteComponentProps;
}

export function BookingCancelSuccess({ routerProps }: BookingCancelSuccessProps) {
	document.title = BOOKING_CANCEL_SUCCESS_PAGE_TITLE;

	const queryParams = routerProps.location.search.substring(1);
	const params = queryString.parse(queryParams);
	const transactionId = params.id?.toString() ?? '';
	const userName = `${params.userFirstName?.toString() ?? ''} ${params.userLastName?.toString() ?? ''}`;

	const [
		zeroUsageGiftCertificates,
		setZeroUsageGiftCertificates,
	] = useState<GiftCertificateUsageEntity | undefined>(undefined);
	const [redeemText, setRedeemText] = useState('');

	const response = useAsync(async () => {
		if (transactionId !== '') {
			return fetchGiftCertificateUsagesByTransactionId(transactionId ?? '')
				.then(usages => {
					setZeroUsageGiftCertificates(usages.data.find(x => x.usedValue === 0));
					getGiftCertificateRedeemText().then(x => {
						setRedeemText(x.data);
					});
				})
				.catch(_ => {
					setZeroUsageGiftCertificates(undefined);
				});
		}
	}, []);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	return (
		<div className="body-content booking-cancel-success">
			<img className="background-img" src={FEATURE_IMAGE_5_URL} alt="bg-img" />
			<NavigationWrapper className="booking-success__nav" {...routerProps} />
			<div className="booking-success__content">
				<div className="content__header">
					<h2>Trip cancelled</h2>
					<h5>Hope to see you another time!</h5>
				</div>
				<BookingCancelSuccessBodyComponent
					redeemText={redeemText}
					giftCertificateUsage={zeroUsageGiftCertificates}
					userName={userName}
				/>
				<Link className="go-to-schedule__button" to="/ferry-schedule">
					Go to schedule
				</Link>
			</div>
		</div>
	);
}

export interface CancelSuccessBodySectionProps {
	redeemText?: string,
	giftCertificateUsage?: GiftCertificateUsageEntity,
	userName?: string;
}

export function BookingCancelSuccessBodyComponent({
	redeemText,
	giftCertificateUsage,
	userName,
}: CancelSuccessBodySectionProps) {
	return (
		<>
			<If condition={giftCertificateUsage === undefined}>
				<NoRefundComponent userName={userName} />
			</If>
			<If condition={giftCertificateUsage !== undefined}>
				<RefundComponent
					redeemText={redeemText}
					giftCertificateUsage={giftCertificateUsage}
					userName={userName}
				/>
			</If>
		</>
	);
}
export function NoRefundComponent({ userName }: CancelSuccessBodySectionProps) {
	const body = [
		'Your request for cancellation has been successful.',
		'We hope to see you in paradise soon.',
		'In the meantime, if there is anything else we can do for you don\'t hesitate to contact us.',
	].join(' ');

	return (
		<div className="content__body">
			<h4 className="no-refund__header-text">{store.isStaff
				? `${userName}'s`
				: 'Your'} trip has been cancelled.
			</h4>
			<p className="no-refund__body-text">
				{body}
			</p>
		</div>
	);
}

export function RefundComponent({
	redeemText, giftCertificateUsage, userName,
}: CancelSuccessBodySectionProps) {
	const { giftCertificate } = giftCertificateUsage as GiftCertificateUsageEntity;
	return (
		<div className="content__body">
			<div className="gift-voucher-section booking-cancel-voucher">
				<h2>{store.isStaff
					? `${userName}'s`
					: 'Your'} account has been credited.
				</h2>
				<div className="credit-voucher">
					<h3 className="gift-voucher-voucher-number">
						Ferry voucher #{giftCertificate?.code}
					</h3>
					<h2 className="gift-voucher-credited-amount">
						${giftCertificate?.initialValue?.toFixed(2)}
					</h2>
					<p className="gift-voucher-expiry-date">
						Expires: {moment(giftCertificate?.expiryDate)
							.format('DD MMM YYYY')}
					</p>
				</div>
				<div
					className="redeem-text-section"
					dangerouslySetInnerHTML={{ __html: redeemText ?? '<></>' }}
				/>
			</div>
		</div>
	);
}
