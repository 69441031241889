import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import Icon from '../../../Icon/Icon';
import { BookingDetailSection, SectionProps } from './BookingDetailSection';

export function TripTimeInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const ferryTrip = booking?.bookedSummary?.ferryTrip;

	const sectionContent = (
		<>
			<p className="route-name">
				{ferryTrip?.getFormattedTripRoute()}
			</p>
			<p className="trip-date-time">
				<span>{ferryTrip?.getFormattedTripDateLong()}, </span>
				<span>{ferryTrip?.getTripTimeFormatted()}</span>
			</p>
		</>
	);

	return (
		<BookingDetailSection
			sectionName="Trip details"
			sectionContent={sectionContent}
			sectionClassName="trip-time"
			icon={{
				name: 'calendar',
				classname: 'calendar',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'trip-time',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
