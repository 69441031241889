import * as React from 'react';
import { useMemo } from 'react';
import { useLottie } from 'lottie-react';
import defaultSpinner from './LottieFiles/default-steering-wheel.json';
import customerSpinner from './LottieFiles/custom-steering-wheel.json';
import { themeStore } from '../../../../Models/ThemeStore';
import { SetLottieColour } from './LottieColourChanger';
import classnames from 'classnames';

export interface SteeringWheelSpinnerProps {
	postPayment?: boolean;
}

export function SteeringWheelSpinner({
	postPayment,
}: SteeringWheelSpinnerProps) {
	const options = useMemo(() => {
		// Set the colour of the spinner depending on the application brand colouring
		const colouredSpinner = SetLottieColour(
			customerSpinner,
			themeStore?.config?.brandColourPrimary,
		);

		return {
			loop: true,
			autoplay: true,
			animationData: colouredSpinner ?? defaultSpinner,
		};
	}, []);

	const { View } = useLottie(options);
	const classNames = ['steering-wheel-loading-spinner'];

	if (postPayment) {
		classNames.push('post-payment');
	}

	return (
		<div className={classnames(classNames)}>
			{View}
		</div>
	);
}
