/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import classNames from 'classnames';
import { Button, Display } from '../Button/Button';
// % protected region % [Add any extra index imports here] off begin
// % protected region % [Add any extra index imports here] end

// % protected region % [Override ITabConfig here] on begin
export interface ITabConfig {
	name: React.ReactNode,
	component: React.ReactNode,
	key: string,
	className?: string,
	disabled?: boolean,
}
// % protected region % [Override ITabConfig here] end

// % protected region % [Override ITabsProps here] on begin
export interface ITabsProps {
	tabs: Array<ITabConfig>;
	leftTab?: React.ReactNode,
	rightTab?: React.ReactNode,
	className?: string;
	innerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
	defaultTab?: number;
	currentTab?: number;
	onTabClicked?: (tabIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
// % protected region % [Override ITabsProps here] end

// % protected region % [Override ITabHeaderProps here] on begin
interface ITabHeaderProps {
	tabState: { currentTab: number };
	tabIdx: number;
	tabChanged?: (() => void);
	onTabClicked?: (tabIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	children: React.ReactNode;
	disabled?: boolean;
}
// % protected region % [Override ITabHeaderProps here] end

// % protected region % [Add any extra interfaces here] off begin
// % protected region % [Add any extra interfaces here] end

class TabHeader extends React.Component<ITabHeaderProps> {
	// % protected region % [Override TabHeader render here] on begin
	public render() {
		const { children, disabled } = this.props;
		return <Button display={Display.Text} onClick={this.onTabClicked} disabled={disabled}>{children}</Button>;
	}
	// % protected region % [Override TabHeader render here] end

	// % protected region % [Override TabHeader onTabClicked here] off begin
	@action
	private onTabClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (this.props.tabState.currentTab !== this.props.tabIdx && this.props.tabChanged) {
			this.props.tabChanged();
		}
		this.props.tabState.currentTab = this.props.tabIdx;
		if (this.props.onTabClicked) {
			this.props.onTabClicked(this.props.tabIdx, event);
		}
	}
	// % protected region % [Override TabHeader onTabClicked here] end

	// % protected region % [Add any extra TabHeader functions here] off begin
	// % protected region % [Add any extra TabHeader functions here] end
}

@observer
class Tabs extends React.Component<ITabsProps> {
	// % protected region % [Override Tabs tabState here] off begin
	@observable
	public tabState = {
		currentTab: 0,
	};
	// % protected region % [Override Tabs tabState here] end

	// % protected region % [Override get currentTab here] off begin
	@computed
	public get currentTab() {
		const { currentTab } = this.props;
		return currentTab ?? this.tabState.currentTab;
	}
	// % protected region % [Override get currentTab here] end

	// % protected region % [Override Tabs constructor here] off begin
	constructor(props: ITabsProps) {
		super(props);

		const { defaultTab } = this.props;

		if (defaultTab) {
			this.tabState.currentTab = defaultTab;
		}
	}
	// % protected region % [Override Tabs constructor here] end

	// % protected region % [Add any extra Tabs functions here] off begin
	// % protected region % [Add any extra Tabs functions here] end

	// % protected region % [Override Tabs render here] on begin
	public render() {
		const {
			innerProps,
			tabs,
			leftTab,
			rightTab,
			className,
			onTabClicked,
		} = this.props;

		return (
			<>
				<nav {...innerProps} className={classNames('tabs', className)}>
					<ul>
						{leftTab}
						{tabs.map((tab, idx) => {
							return (
								<li
									key={tab.key}
									className={classNames(
										'tab-header',
										this.currentTab === idx ? ' selected' : '',
										tab.disabled ? ' disabled' : '',
									)}
								>
									<TabHeader
										tabState={this.tabState}
										tabIdx={idx}
										onTabClicked={onTabClicked}
										disabled={tab.disabled}
									>
										{tab.name}
									</TabHeader>
								</li>
							);
						})}
						{rightTab}
					</ul>
				</nav>
				<div className={classNames(tabs[this.currentTab].className, 'tab-body-section')}>
					{tabs[this.currentTab].component}
				</div>
			</>
		);
	}
	// % protected region % [Override Tabs render here] end
}

// % protected region % [Override export Tabs here] off begin
export default Tabs;
// % protected region % [Override export Tabs here] end
