import * as React from 'react';
import { BaseModalContentProps, BaseModalContent } from './BaseModalContents';
import useStore from 'Hooks/useStore';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import Icon from '../../Icon/Icon';

export interface SingleActionModalContentProps extends Omit<BaseModalContentProps, 'footer'> {
	onConfirm?: () => void;
	confirmContent?: React.ReactNode;
	autoClose?: boolean;
}

export function SingleActionModalContent({
	title,
	body,
	className,
	onConfirm,
	confirmContent,
	autoClose = true,
}: SingleActionModalContentProps) {
	const store = useStore();

	const defaultConfirmButton = () => {
		return (
			<Button
				key="confirm"
				className="confirm"
				display={Display.Solid}
				colors={Colors.Secondary}
				sizes={Sizes.Medium}
				onClick={() => {
					if (onConfirm !== undefined) {
						onConfirm();
					}
					if (autoClose) {
						store.modal.hide();
					}
				}}
			>
				{confirmContent || 'Ok'}
				<Icon name="arrow-right" />
			</Button>
		);
	};

	const footer = confirmContent === undefined || typeof confirmContent === 'string'
		? defaultConfirmButton()
		: confirmContent;

	return (
		<BaseModalContent
			title={title}
			body={body}
			className={className}
			footer={footer}
		/>
	);
}
