import * as React from 'react';
import { useState } from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { addGiftCertificateToTransaction } from 'Services/Api/_HumanWritten/GiftCertificateService';
import alertToast from 'Util/ToastifyUtils';
import classNames from 'classnames';
import { fetchGiftCertificateUsagesByTransactionId } from 'Util/_HumanWritten/FetchGiftCertificates';
import { GiftCertificateUsageEntity } from 'Models/Entities';
import { observer } from 'mobx-react';

export interface EventPromoGiftCardProps {
	className?: string;
	transactionId: string;
	onAddGiftCertificate?: () => void;
	/**
	 * If defined, list of GiftCertificateUsageEntity related to transactionId is fetched and passed into this
	 * callback function.
	 */
	updateGiftCertUsages?: (newEntities: GiftCertificateUsageEntity[]) => Promise<void>;
}

interface GiftCertificateProps {
	code: string;
	loading: boolean;
}

export function EventPromoGiftCard({
	className,
	transactionId,
	onAddGiftCertificate,
	updateGiftCertUsages,
}: EventPromoGiftCardProps) {
	const [certificateProps, setCertificateProps] = useState<GiftCertificateProps>({
		code: '',
		loading: false,
	});

	const fetchUsages = async () => {
		try {
			const { data } = await fetchGiftCertificateUsagesByTransactionId(transactionId);
			return data ?? [];
		} catch (e) {
			console.error(e);
			return [];
		}
	};

	const onApply = async () => {
		// Start loading
		setCertificateProps(prevState => ({
			...prevState,
			loading: true,
		}));

		try {
			await addGiftCertificateToTransaction(transactionId, certificateProps.code, true);

			if (onAddGiftCertificate) {
				onAddGiftCertificate();
			}
			if (updateGiftCertUsages) {
				const usages = await fetchUsages();
				updateGiftCertUsages(usages);
			}

			// Reset state
			setCertificateProps({ code: '', loading: false });
			alertToast('Voucher applied!', 'success');
		} catch (e: any) {
			const errorCode: string = e.response?.data ?? '';
			let toastMessage: string = 'Invalid voucher';

			// Reset state
			setCertificateProps({ code: '', loading: false });

			switch (errorCode) {
				case 'BAD_CODE':
					toastMessage = 'Gift certificate does not exist';
					break;
				case 'EMPTY':
					toastMessage = 'Gift certificate has no remaining value';
					break;
				case 'EXPIRED':
					toastMessage = 'Gift certificate is expired';
					break;
				case 'NOT_REQUIRED':
					toastMessage = 'Transaction does not require payment';
					break;
			}

			alertToast(toastMessage, 'error');
		}
	};

	return (
		<div className={classNames('promo-gift-cert-container', className)}>
			<h3 className="promo-gift-cert-header">Promotion or voucher</h3>
			<div className="promo-gift-cert-field">
				<TextField
					model={certificateProps}
					modelProperty="code"
					placeholder="Enter voucher code here"
					onAfterChange={event => {
						setCertificateProps({
							code: event.target.value,
							loading: false,
						});
					}}
					inputProps={{
						onKeyUp: event => {
							if (event.nativeEvent.code === 'Enter') { // enter
								onApply();
							}
						},
					}}
				/>
				<Button
					disabled={certificateProps.loading}
					className="apply-promo-button"
					display={Display.Solid}
					colors={certificateProps.loading ? Colors.Disabled : Colors.Secondary}
					onClick={onApply}
				>
					{certificateProps.loading ? 'Applying...' : 'Apply'}
				</Button>
			</div>
		</div>
	);
}

export default observer(EventPromoGiftCard);
