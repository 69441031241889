import * as React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import classNames from 'classnames';
import { action } from 'mobx';
import { FerryTripEntity, RouteEntity } from 'Models/Entities';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { FerryTripCards } from 'Views/Components/_HumanWritten/FerryTripCard/FerryTripCards';
import Tabs from 'Views/Components/Tabs/Tabs';
import { store } from 'Models/Store';
import queryString from 'querystring';
import { daysOfWeekShort, getDayOfTheWeek } from 'Util/_HumanWritten/DayOfWeekUtils';
import { ferryTripFilterDeparted } from 'Util/_HumanWritten/TicketSelectionUtils';
import { FerryScheduleModel } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleModel';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import {
	checkDateColumnClassName,
	checkLeftDate,
	getNewDate,
	isSameDate,
} from 'Util/_HumanWritten/DateSelectorUtils';

export interface MobileTripCalendarProps<T> {
	model: FerryScheduleModel;
	ferryTrips?: FerryTripEntity[];
	selectedRoute: RouteEntity;
	tripToEdit: React.MutableRefObject<FerryTripEntity | null>;
}

// eslint-disable-next-line prefer-arrow-callback,func-names
export const MobileTripCalendar = observer(function<T> (
	{
		model,
		ferryTrips,
		selectedRoute,
		tripToEdit,
	}: MobileTripCalendarProps<T>,
) {
	const listOfTabs = [];
	const firstDay = (model.date).getDay();
	const firstDate = (model.date);

	const isStaff = store.isStaff || store.isManager || store.isAdmin;

	const leftTab = (
		<Button
			className="move-date-left-button"
			display={Display.Text}
			colors={Colors.None}
			sizes={Sizes.Large}
			icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
			onClick={() => changeDateByWeek(false)}
			disabled={(isStaff ? false : checkLeftDate(model.date, 1))}
		/>
	);
	const rightTab = (
		<Button
			className="move-date-right-button"
			display={Display.Text}
			colors={Colors.None}
			sizes={Sizes.Large}
			icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
			onClick={() => changeDateByWeek(true)}
		/>
	);

	const bookingWizardUrlGenerator = React.useCallback((date: Date) => {
		return (ferryTrip: FerryTripEntity) => {
			// All values in search string must be a string
			const searchStringModel = {
				date: date.toDateString(),
				from: model.fromLocation,
				to: model.toLocation,
				route: model.route.id,
				ferryTripId: ferryTrip.id,
				fromLocationName: selectedRoute.departure?.name ?? '',
				toLocationName: selectedRoute.destination?.name ?? '',
				type: ferryTrip?.ferry?.vehicleCapacity > 0 ? whiteLabelStore?.defaultBookingType : 'passenger',
			};
			const searchString = queryString.stringify(searchStringModel);
			return `/booking-wizard?${searchString}`;
		};
	}, [model.fromLocation, model.route.id, model.toLocation]);

	const changeDateByWeek = action((increase: boolean) => {
		// eslint-disable-next-line dot-notation
		model.date = getNewDate(model.date as Date, increase, 7, store.isStaff);
	});

	for (let i = 0, index = firstDay; i < daysOfWeekShort.length; i++, index++) {
		if (index === 7) {
			index = 0;
		}
		const day = getDayOfTheWeek(index, true);
		const currentDay = new Date(firstDate);
		currentDay.setDate(firstDate.getDate() + i);

		// Only show the most recently departed if the user is not a staff member
		// If the user is a staff member show all of the trips for the day
		let filteredFerryList = !store.isStaff
			? ferryTripFilterDeparted(currentDay, ferryTrips)
			: ferryTrips?.filter(filteredTrip => isSameDate(filteredTrip.startDate, currentDay));
		if (isNullOrUndefined(ferryTrips)) {
			filteredFerryList = undefined;
		}

		const tabName = (
			<div className={classNames('calendar-header-mobile', (
				checkDateColumnClassName(currentDay, ferryTrips) ? '' : 'no-trips'))}
			>
				<p className="day-header">{day}</p>
				<h6 className="date-header">{moment(currentDay).format('DD')}</h6>
			</div>
		);
		const tabComponent = (
			<div className="trip-card-column-container">
				<FerryTripCards
					list={filteredFerryList}
					isMobile
					wizardUrlGenerator={bookingWizardUrlGenerator(currentDay)}
					tripToEdit={tripToEdit}
					bulkBookingTrips={null}
					onClickTrip={() => {}}
				/>
			</div>
		);
		listOfTabs.push(
			{
				component: tabComponent,
				name: tabName,
				key: day,
			},
		);
	}

	return (
		<div className="calendar-container-mobile">
			<Tabs tabs={listOfTabs} leftTab={leftTab} rightTab={rightTab} />
		</div>
	);
});
