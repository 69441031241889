export interface FerryTripImportResponseData {
	tripCount: number;
	successCount: number;
	failCount: number;
}

export interface FerryTripCsvError {
	line: number;
	dateAndTime: string;
	reason: string;
}

export interface IFerryTripImportResponse {
	data: FerryTripImportResponseData;
	errors: FerryTripCsvError[];
}

export class FerryTripImportResponse implements IFerryTripImportResponse {
	data: FerryTripImportResponseData;
	errors: FerryTripCsvError[];

	constructor(ferryTripImportResponse: IFerryTripImportResponse) {
		this.data = ferryTripImportResponse.data;
		this.errors = ferryTripImportResponse.errors;
	}

	hasErrors(): boolean {
		return this.errors.length > 0;
	}
}
