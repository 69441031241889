import * as React from 'react';
import { observer } from 'mobx-react';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { action, observable } from 'mobx';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import { cacheType, cacheTypeOptions } from 'Models/Enums';
import { clearCache, searchCache } from 'Services/Api/_HumanWritten/CacheService';
import If from '../../If/If';
import { TextField } from '../../TextBox/TextBox';

export interface CacheModal {
	cacheModel: {
		selectedCache: string,
		ferryTripId?: string,
	},
	toClearCache: boolean,
}

export const CacheModalContent = observer(({
	cacheModel,
	toClearCache,
}: CacheModal) => {
	const options = Object.keys(cacheTypeOptions) as cacheType[];

	return (
		<div>
			<p>Select a cache to {toClearCache ? 'clear' : 'search'}.</p>
			<Combobox
				model={cacheModel}
				modelProperty="selectedCache"
				label={`Cache to ${toClearCache ? 'clear' : 'search'}`}
				placeholder={`Select cache to ${toClearCache ? 'clear' : 'search'}`}
				options={options.map(x => {
					return {
						display: x,
						value: x,
					};
				})}
				searchable
				isRequired
				inputProps={{ upward: false }}
			/>
			<If condition={!toClearCache && (cacheModel.selectedCache === 'FERRY_TRIPS' || cacheModel.selectedCache === 'CAPACITY')}>
				<TextField
					model={cacheModel}
					modelProperty="ferryTripId"
					label="Ferry trip id"
					placeholder="Enter ferry trip id"
					isRequired
				/>
			</If>
		</div>
	);
});

export const RenderClearCacheModal = async (toClearCache: boolean = true) => {
	const cacheModel = observable({
		selectedCache: '',
		ferryTripId: '',
	});

	const ObservableModalContent = observer(() => {
		return (
			<CacheModalContent
				cacheModel={cacheModel}
				toClearCache={toClearCache}
			/>
		);
	});

	const confirmed = await modalWithCustomInput(
		`${toClearCache ? 'Clear' : 'Search'} cache`,
		<ObservableModalContent />,
		{
			cancelText: 'Cancel',
			confirmText: 'Confirm',
			actionClassName: 'cache-modal',
			resolveOnCancel: false,
			onConfirm: action(() => {
				return true;
			}),
		},
	);

	if (!confirmed || stringIsEmpty(cacheModel.selectedCache)) {
		return;
	}

	if (toClearCache && stringNotEmpty(cacheModel.selectedCache)) {
		await clearCache(cacheModel.selectedCache as cacheType);
	} else if (!toClearCache && stringNotEmpty(cacheModel.selectedCache)) {
		const res = await searchCache(
			cacheModel.selectedCache as cacheType,
			cacheModel.ferryTripId);

		if (stringNotEmpty(cacheModel.ferryTripId)) {
			console.log(`${cacheModel.selectedCache} (${cacheModel.ferryTripId}):`, res.data);
		} else {
			console.log(`${cacheModel.selectedCache}:`, res.data);
		}
		return res.data;
	}
};
