import { store } from 'Models/Store';
import { EventDetailEntity } from '../../../../Models/Entities';
import { isNullOrUndefined } from '../../../../Util/TypeGuards';

const EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY = 'event-booking-wizard-data';
const OLD_EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY = 'old-event-booking-wizard-data';
const EVENT_WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY = 'cached-event-booking-wizard-data-token';

/* eslint-disable @typescript-eslint/ban-types */
export type WizardErrors<T> = {
	[K in keyof T]?: string;
};

export interface EventBookingWizardCartFields {
	wizardData: EventBookingWizardData;
	eventDetails: EventDetailEntity;
}

export interface SelectedTicketsDto {
	customTicketTypeId: string;
	quantity: number;
}

export interface EventBookingWizardData {
	acceptedTsAndCs: boolean;
	eventId: string;
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	selectedTickets: SelectedTicketsDto[];
	bookingToEditId?: string;
}

export function getDefaultEventWizardData(eventId?: string): EventBookingWizardData {
	return {
		acceptedTsAndCs: false,
		eventId: eventId ?? '',
		userId: (!store.isStaff ? store.userId : '') ?? '',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		selectedTickets: [],
		bookingToEditId: '',
	};
}

export function saveEventBookingWizardDataToLocalStorage(data: EventBookingWizardData) {
	sessionStorage.setItem(EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
}

export function getEventBookingWizardData(eventId?: string): EventBookingWizardData {
	const fetchedData = sessionStorage.getItem(EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY);

	if (fetchedData === null) {
		const newData = getDefaultEventWizardData(eventId);

		saveEventBookingWizardDataToLocalStorage(newData);
		return newData;
	}

	return JSON.parse(fetchedData);
}

export function clearEventBookingWizardData() {
	sessionStorage.removeItem(EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY);
}

export function saveOldEventBookingWizardData(data: EventBookingWizardData) {
	sessionStorage.setItem(OLD_EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
}

export function clearOldEventBookingWizardData() {
	sessionStorage.removeItem(OLD_EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY);
}

/*
* Save the token for the booking wizard data retrieval from the cache.
*/
export function saveEventBookingWizardDataCacheToken(token: string) {
	localStorage.setItem(EVENT_WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
}

/*
* Get the token from local storage used to fetch the booking wizard data from the cache.
*/
export function getEventBookingWizardDataCacheToken(): string | null {
	const fetchedData = localStorage.getItem(EVENT_WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY);

	return fetchedData === null ? null : JSON.parse(fetchedData);
}

/*
* Remove the token from local storage once it has been used.
*/
export function removeEventBookingWizardDataCacheToken() {
	localStorage.removeItem(EVENT_WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY);
}

export function getOldEventBookingWizardData(): EventBookingWizardData | null {
	const fetchedData = sessionStorage.getItem(OLD_EVENT_WIZARD_DATA_LOCAL_STORAGE_KEY);

	return fetchedData === null ? null : JSON.parse(fetchedData);
}

/**
 * Returns e.g. '1 adult, 2 children, 1 infant'.
 */
export function getCustomTicketInfoFromWizardData(wizardData: EventBookingWizardData, eventDetails: EventDetailEntity): string {
	const items: string[] = [];

	// eslint-disable-next-line array-callback-return
	wizardData.selectedTickets.map(ticket => {
		const customTicketType = eventDetails.customTicketTypes
			.find(x => x.id === ticket.customTicketTypeId);
		if (isNullOrUndefined(customTicketType)) {
			return;
		}

		if (ticket.quantity > 0) {
			// Only include passengers with a count
			const label = ticket.quantity === 1
				? customTicketType.singularName
				: customTicketType.pluralName;
			items.push(`${ticket.quantity} ${label}`);
		}
	});

	return items.join(', ');
}
