import * as React from 'react';
import { observer } from 'mobx-react';
import { EventLabelEntity, UploadedImageEntity } from '../../../../../Models/Entities';
import { DateTimePicker } from '../../../DateTimePicker/DateTimePicker';
import { TextField } from '../../../TextBox/TextBox';
import { NumberTextField } from '../../../NumberTextBox/NumberTextBox';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import FileUpload from '../../../FileUpload/FileUpload';
import { isNotNullOrUndefined } from '../../../../../Util/TypeGuards';
import If from '../../../If/If';
import { FileUploadPreview } from '../../../FileUpload/UploadPreview';
import { runInAction } from 'mobx';
import { EventSideBarProps, EventSideBarPropsErrors } from './EventSideBar';
import { Combobox } from '../../../Combobox/Combobox';
import { validateField } from '../../../../../Validators/Functions/HumanWritten/ValidateForm';
import { eventDetailsValidationRules, eventSidebarPropsValidationRules } from './ValidationRules';
import moment from 'moment';

export interface IDetailsTabProps {
	state: EventSideBarProps;
	eventLabels: EventLabelEntity[];
	hidePanel?: () => void;
	isDisabled: boolean;
	errors: EventSideBarPropsErrors;
}

function EventDetailsTabComponent({
	state,
	eventLabels,
	isDisabled,
	errors,
}: IDetailsTabProps) {
	return (
		<div className="custom-sidebar__tab-content details-tab">
			<div className="custom-sidebar__section">
				<h6 className="custom-sidebar__section-header">Event details</h6>
				<div className="custom-sidebar__section-inputs">
					<TextField
						className="event-name"
						id="event-name"
						model={state.eventDetails}
						modelProperty="name"
						label="Title"
						placeholder="Enter event name"
						isRequired
						isDisabled={isDisabled}
						errors={errors?.name}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'name', errors, eventDetailsValidationRules);
						}}
					/>
					<Combobox
						className="event-label"
						model={state.eventDetails}
						modelProperty="eventLabelId"
						label="Event label"
						placeholder="Select event label"
						isDisabled={isDisabled}
						options={eventLabels.map(x => {
							const iconSrc = `/api/files/${x.iconId}`;

							return {
								display: (
									<div className="event-label__container">
										<img
											className="event-icon"
											src={`/api/files/${x?.iconId}`}
											alt="event-icon"
											style={{
												filter: 'invert(1) grayscale(1) brightness(0) saturate(100%)',
												WebkitFilter: 'invert(1) grayscale(1) brightness(0) saturate(100%)',
											}}
										/>
										{x.label}
									</div>
								),
								value: x.id,
							};
						})}
						searchable
						isRequired
						errors={errors?.eventLabelId}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'eventLabelId', errors, eventDetailsValidationRules);
						}}
						inputProps={{
							upward: false,
						}}
					/>
					<NumberTextField
						model={state.eventDetails}
						modelProperty="totalCapacity"
						label="Total capacity"
						placeholder="Enter max capacity"
						isRequired
						isDisabled={isDisabled}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'totalCapacity', errors, eventDetailsValidationRules);
						}}
						errors={errors?.totalCapacity}
					/>
					<DateTimePicker
						key="start-date-time-selector"
						className="start-date-time-selector"
						id="start-date-time-selector"
						model={state.eventDetails!.ferryTrip}
						modelProperty="startDate"
						label="Start date and time"
						placeholder="Event start date and time"
						humanFriendly
						altFormat="d M Y H:i"
						isRequired
						isDisabled={isDisabled}
						onAfterChange={dates => {
							runInAction(() => {
								if (state.eventDetails!.ferryTrip!.startDate > state.eventDetails!.ferryTrip!.endDate) {
									// eslint-disable-next-line prefer-destructuring
									state.eventDetails!.ferryTrip!.endDate = dates[0];
								}
							});
						}}
					/>
					<DateTimePicker
						key="end-date-time-selector"
						className="end-date-time-selector"
						id="end-date-time-selector"
						model={state.eventDetails!.ferryTrip}
						modelProperty="endDate"
						label="End date and time"
						placeholder="Event end end date and time"
						humanFriendly
						isReadOnly={isDisabled}
						altFormat="d M Y H:i"
						isRequired
						isDisabled={isDisabled}
						minDate={(moment(state.eventDetails!.ferryTrip!.startDate).hour(0).minute(0)).toISOString()}
						errors={errors.endDate}
					/>
					<TextArea
						model={state.eventDetails}
						modelProperty="description"
						label="Description"
						placeholder="Enter event description"
						isRequired
						isDisabled={isDisabled}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'description', errors, eventDetailsValidationRules);
						}}
						errors={errors?.description}
					/>
					<TextField
						className="event-address"
						id="event-address"
						model={state.eventDetails}
						modelProperty="address"
						label="Address"
						placeholder="Enter event address"
						isRequired
						isDisabled={isDisabled}
						errors={errors?.address}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'address', errors, eventDetailsValidationRules);
						}}
					/>
					<TextField
						className="event-google-map-url"
						id="event-event-google-map-url"
						model={state.eventDetails}
						modelProperty="googleMapUrl"
						label="Google map URL"
						placeholder="Enter google map url"
						isRequired
						isDisabled={isDisabled}
						errors={errors?.googleMapUrl}
						onAfterChange={() => {
							validateField(state.eventDetails!, 'googleMapUrl', errors, eventDetailsValidationRules);
						}}
					/>
					<If condition={!isDisabled}>
						<div className="custom-sidebar__upload-container">
							<p className="separate-label">Header image (max 1)<span className="required">*</span></p>
							<If condition={!isDisabled}>
								<FileUpload
									model={state}
									modelProperty="headerImage"
									label="Header image (max 1)"
									labelVisible={false}
									isRequired
									disableUploadButton
									onChange={file => {
										state.headerImage = new UploadedImageEntity({
											image: file,
										});
										validateField(state, 'headerImage', errors, eventSidebarPropsValidationRules);
										return true;
									}}
									isDisabled={isNotNullOrUndefined(state.headerImage)}
									errors={errors?.headerImage}
								/>
							</If>
							<If condition={isNotNullOrUndefined(state.headerImage)}>
								<FileUploadPreview
									fileName="header-img.png"
									imagePreview
									fileBlob={state.headerImage?.image as File ?? null}
									onDelete={() => {
										runInAction(() => {
											state.headerImage = null;
										});
									}}
								/>
							</If>
						</div>
						<div className="custom-sidebar__upload-container">
							<p className="separate-label">Supporting images (max 5)<span className="required">*</span></p>
							<div className="custom-sidebar__upload-container">
								<FileUpload
									model={state}
									modelProperty="supportingImages"
									label="Supporting images (max 5)"
									labelVisible={false}
									isRequired
									disableUploadButton
									onChange={file => {
										state.supportingImages.push(new UploadedImageEntity({
											image: file,
										}));
										validateField(state, 'supportingImages', errors, eventSidebarPropsValidationRules);
										return true;
									}}
									isDisabled={state.supportingImages.length === 5}
									errors={errors?.supportingImages}
								/>
								<If condition={state.supportingImages?.length !== 0}>
									<div className="uploaded-images__container">
										{state.supportingImages.map((x, index) => {
											return (
												<FileUploadPreview
													fileName={`support-img-${index + 1}.png`}
													imagePreview
													fileBlob={x.image as File}
													onDelete={() => {
														runInAction(() => {
															state.supportingImages = state.supportingImages
																.filter(y => y.image !== x.image);
														});
													}}
												/>
											);
										})}
									</div>
								</If>
							</div>
						</div>
					</If>
				</div>
			</div>
		</div>
	);
}

export default observer(EventDetailsTabComponent);
