import axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import { TRANSACTION_ENTITY_URL } from 'Constants';

export interface PaymentTabPricesDto {
	totalPrice: number,
	creditCardSurcharge: number,
}

export async function getPaymentTabTotalAmount(isEventBooking: boolean, transactionId?: string):
	Promise<PaymentTabPricesDto | null> {
	if (transactionId == null) {
		return null;
	}

	const url = buildUrl(`${TRANSACTION_ENTITY_URL}/total-price`, {
		id: transactionId,
		isEventBooking: isEventBooking ? 'true' : 'false',
	});

	const result = await axios.get(url);
	return result.data as PaymentTabPricesDto;
}
