import React, { useState } from 'react';
import { store } from 'Models/Store';
import handleNavigationAway from '../Views/Components/_HumanWritten/AccountDetails/HandleNavigationAway';
import { UnregisterCallback } from 'history';

export interface useAccountNavigationAwayProps {
	needConfirm: boolean,
	confirmationFunction: () => void,
}

let GLOBAL_UNBLOCK: UnregisterCallback | undefined;

const handleTabClose = (event: BeforeUnloadEvent) => {
	event.preventDefault();
	event.returnValue = '';
	return ('Are you sure you want to leave?');
};

export function useAccountNavigationAway({ needConfirm, confirmationFunction }: useAccountNavigationAwayProps) {
	const handleRouterHistory = () => {
		if (!needConfirm) {
			if (GLOBAL_UNBLOCK !== undefined) {
				GLOBAL_UNBLOCK();
			}

			return;
		}

		const _unblock = store?.routerHistory?.block(nextLocation => {
			handleNavigationAway(needConfirm, confirmationFunction).then(result => {
				if (result) {
					_unblock();
					store.routerHistory.push(nextLocation);
				}
			});

			return false;
		});

		store.routerHistory.listen(_unblock);
		GLOBAL_UNBLOCK = _unblock;
	};

	React.useEffect(() => {
		if (needConfirm) {
			window.addEventListener('beforeunload', handleTabClose);
		}

		handleRouterHistory();

		if (!needConfirm) {
			window.removeEventListener('beforeunload', handleTabClose);
			return;
		}

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, [store?.routerHistory?.location, needConfirm]);
}
