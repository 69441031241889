import * as React from 'react';
import { stringNotEmpty } from 'Util/TypeGuards';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { useSearchTerm } from './CheckInView';
import { useAutoFocus } from 'Hooks/useAutoFocus';
import { whiteLabelStore } from 'Models/WhiteLabelStore';

const CheckInSearchInputId = 'check-in-search-input';

export interface CheckInSearchProps {
	isVehicleCheckIn: boolean;
}

export function CheckInSearch({ isVehicleCheckIn }: CheckInSearchProps) {
	// Auto focus on search input when location state has autoFocus = true.
	// inputId must have '-field' suffix for TextField component.
	useAutoFocus(`${CheckInSearchInputId}-field`);

	const { model, setSearchTerm } = useSearchTerm(x => ({
		model: x.model,
		setSearchTerm: x.setSearchTerm,
	}));

	const identificationString = whiteLabelStore.regoLabelPascalCase.toLowerCase();

	return (
		<div className="check-in-list__search">
			<Icon name="search" classname="check-in-list__search-icon" />
			<TextField
				id={CheckInSearchInputId}
				model={model}
				modelProperty="searchTerm"
				className="check-in-list__search-input"
				placeholder={
					isVehicleCheckIn
						? `Search by ${identificationString}, name, phone or email`
						: 'Search by name, phone or email'
				}
				clickToClear={stringNotEmpty(model.searchTerm)}
				onClickToClear={e => {
					setSearchTerm(e.currentTarget.value);
				}}
				autoFocus={stringNotEmpty(model.searchTerm)}
				onAfterChange={e => {
					setSearchTerm(e.currentTarget.value);
				}}
			/>
		</div>
	);
}
