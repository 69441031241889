import { PaymentError } from 'Views/Components/_HumanWritten/Payment/usePayment';

// Functions are exported so that we can check that they are being called with the correct props in tests
export function getPaymentModalTitle(error: PaymentError): string {
	switch (error) {
		case PaymentError.ERROR:
			return 'An error has occurred';
		case PaymentError.NO_PAYMENT_ERROR:
			return 'Could not alter booking';
		case PaymentError.TIMEOUT:
			return 'Cannot accept payment';
		default:
			return '';
	}
}

export function getPaymentModalContents(error: PaymentError): string {
	switch (error) {
		case PaymentError.ERROR:
			return 'We could not complete your booking at this time.\nPlease try again later';
		case PaymentError.NO_PAYMENT_ERROR:
			// eslint-disable-next-line max-len
			return 'We could not complete your alteration at this time. Your booking has not been changed.\nPlease try again later';
		case PaymentError.TIMEOUT:
			return 	'We are not currently accepting payments due to an issue with our payment provider.\n'
				+ 'Please try again later';
		default:
			return '';
	}
}
