import axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import { CACHE_API } from 'Constants';
import { cacheType } from 'Models/Enums';
import { stringNotEmpty } from 'Util/TypeGuards';

export function clearCache(cacheTypeEnum: cacheType) {
	const res = axios.delete(
		buildUrl(`${CACHE_API}/clear`, {
			cacheType: cacheTypeEnum.toString(),
		}),
	);
	return res;
}

export async function searchCache(cacheTypeEnum: cacheType, ferryTripId?: string) {
	const res = await axios.get(
		buildUrl(`${CACHE_API}/get`, {
			cacheType: cacheTypeEnum.toString(),
			...(stringNotEmpty(ferryTripId) && { ferryTripId }),
		}),
	);
	return res;
}
