import React, { useEffect, useState } from 'react';
import If from 'Views/Components/If/If';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import classnames from 'classnames';
import {
	BookingWizardTab,
	SidebarStatus,
	VisibilityStatus,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import {
	EventBookingTimerWrap,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/Components/EventBookingTimerWrap';

export interface EventBackButtonProps {
	onClick: () => void;
	isIpad: boolean;
	tabs: BookingWizardTab[];
	currentTabIndex: number;
	onTimerExpiry: () => void;
	ticketsPageUrl: string;
	bulkBooking: boolean;
	forEventBooking: boolean;
}

export function EventBackButton({
	onClick,
	isIpad,
	tabs,
	currentTabIndex,
	onTimerExpiry,
	ticketsPageUrl,
	forEventBooking,
	bulkBooking,
}: EventBackButtonProps) {
	const [stepWithNoSidebar, setStepWithNoSidebar] = useState(
		tabs[currentTabIndex].sidebarStatus === SidebarStatus.NO_SIDEBAR,
	);

	// We may have had to omit some tabs, so we can't directly check that our current index is 0
	const firstStep: boolean = tabs.findIndex(tab => tab.visibility !== VisibilityStatus.EXCLUDED) === currentTabIndex;

	const classNames = ['event-booking-wizard-back-btn', 'back-btn'];

	if (stepWithNoSidebar) {
		classNames.push('no-sidebar');
	}
	if (firstStep && !bulkBooking) {
		classNames.push('hide-back-button');
	}

	const { sidebarStatus } = tabs[currentTabIndex];

	useEffect(() => {
		setStepWithNoSidebar(sidebarStatus === SidebarStatus.NO_SIDEBAR);
	}, [
		sidebarStatus,
	]);

	let buttonText = 'Back';
	if (firstStep) {
		buttonText = '';
		if (bulkBooking) {
			buttonText = 'Cancel';
		}
	}

	return (
		<If condition={(!firstStep && isIpad) || !isIpad}>
			<Button
				className={classnames(classNames)}
				display={Display.Solid}
				sizes={Sizes.Large}
				colors={Colors.Black}
				onClick={onClick}
			>
				<If condition={!isIpad}>
					<EventBookingTimerWrap
						pageWithSidebar={!stepWithNoSidebar}
						onTimerExpiry={onTimerExpiry}
						isIpad={isIpad}
						ticketsPageUrl={ticketsPageUrl}
						forEventBooking={forEventBooking}
					/>
				</If>
				{buttonText}
			</Button>
		</If>
	);
}
