import * as React from 'react';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';
import { store as staticStore } from 'Models/Store';

export type CheckInMoveConfirmationModalContentsProps = Pick<DualActionModalContentProps, 'onConfirm'>;

export function CheckInMoveConfirmationModalContents({
	onConfirm,
}: CheckInMoveConfirmationModalContentsProps) {
	return (
		<DualActionModalContent
			title="Move to current trip"
			body="Are you sure you want to move this user to the current trip?"
			onConfirm={onConfirm}
			fixedCancelWidth
		/>
	);
}

export function showCheckInMoveConfirmationModal(props: CheckInMoveConfirmationModalContentsProps) {
	staticStore.modal.show(
		'check-in-move-confirmation-booking',
		<CheckInMoveConfirmationModalContents {...props} />,
	);
}
