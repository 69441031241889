import * as React from 'react';

export function CheckInSearchEmpty() {
	return (
		<div key="search-empty" className="search__empty">
			<h4>No results</h4>
			<p className="search__empty__subtitle search-table">
				There are no results that match your search.
			</p>
		</div>
	);
}

export function CheckInNoAddOns() {
	return (
		<div key="search-empty" className="search__empty">
			<h4>No bookings</h4>
			<p className="search__empty__subtitle search-table">
				There are no bookings that have the selected add-ons.
			</p>
		</div>
	);
}
