import * as React from 'react';
import { FerryTripCard } from './FerryTripCard';
import { FerryTripEntity } from 'Models/Entities';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

interface FerryTripCardsProps {
	list?: FerryTripEntity[];
	isMobile: boolean;
	wizardUrlGenerator?: (ferryTrip: FerryTripEntity) => string;
	tripToEdit: React.MutableRefObject<FerryTripEntity | null>;
	bulkBookingTrips: string[] | null;
	onClickTrip: (tripId: string) => void;
}

export const FerryTripCards = ({
	list,
	isMobile,
	wizardUrlGenerator,
	tripToEdit,
	bulkBookingTrips,
	onClickTrip,
}: FerryTripCardsProps) => {
	const bookingWizardUrl = (trip: FerryTripEntity): string => {
		if (wizardUrlGenerator === undefined) {
			return '/booking-wizard';
		}
		return wizardUrlGenerator(trip);
	};

	if (isNullOrUndefined(list)) {
		return isMobile ? <LottieSpinner /> : <></>;
	}
	if (list?.length === 0) {
		return <div className="no-trips-available">No trips available</div>;
	}
	return (
		<>
			{
				list.map((trip, index) => (
					<FerryTripCard
						ferryTrip={trip}
						isMobile={isMobile}
						index={index}
						key={trip.id}
						editUrl={`/ferry-schedule/edit/${trip.id}`}
						bookingWizardUrl={bookingWizardUrl(trip)}
						tripToEdit={tripToEdit}
						bulkBookingTrips={bulkBookingTrips}
						onClickTrip={onClickTrip}
					/>
				))
			}
		</>
	);
};
