import * as React from 'react';
import { observer } from 'mobx-react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { CustomLocationState, isQrCodeRedirectLocationState } from 'Models/_HumanWritten/LocationState';
import { useCheckInRoutesWatch } from 'Hooks/useCheckInRoutesWatch';
import { SecuredGroups } from 'Models/Security/UserGroups';
import EventCheckInScanner from '../Components/_HumanWritten/CheckIn/EventCheckIn/EventCheckInScanner';
import EventCheckInView from '../Components/_HumanWritten/CheckIn/EventCheckIn/EventCheckInView';
import {
	CHECK_IN_PAGE_TITLE,
	CHECK_IN_ADD_BOOKING_PAGE_TITLE,
	CHECK_IN_MOVE_BOOKING_PAGE_TITLE,
	CHECK_IN_REVIEW_BOOKING_PAGE_TITLE,
	CHECK_IN_SCAN_QR_PAGE_TITLE,
	CHECK_IN_SELECT_CUSTOMER_PAGE_TITLE,
	CHECK_IN_VIEW_BOOKING_PAGE_TITLE,
} from '../../ConstantPageNames';
import {
	eventCheckInStore,
	EventCheckInStoreContext,
} from '../Components/_HumanWritten/CheckIn/EventCheckIn/EventContext/EventCheckInContext';
import {
	Route,
	Switch,
	Redirect,
	RouteComponentProps,
	useHistory,
} from 'react-router-dom';

export interface CheckInRouteParams {
	/**
	 * The ferry trip entity ID
	 */
	id: string;
}

function EventCheckInPage(props: RouteComponentProps<CheckInRouteParams>) {
	const title = window.location.href;
	if (title.includes('booking')) {
		document.title = CHECK_IN_VIEW_BOOKING_PAGE_TITLE;
	} else if (title.includes('select-customer')) {
		document.title = CHECK_IN_SELECT_CUSTOMER_PAGE_TITLE;
	} else if (title.includes('add')) {
		document.title = CHECK_IN_ADD_BOOKING_PAGE_TITLE;
	} else if (title.includes('review')) {
		document.title = CHECK_IN_REVIEW_BOOKING_PAGE_TITLE;
	} else if (title.includes('move')) {
		document.title = CHECK_IN_MOVE_BOOKING_PAGE_TITLE;
	} else if (title.includes('scan')) {
		document.title = CHECK_IN_SCAN_QR_PAGE_TITLE;
	} else {
		document.title = CHECK_IN_PAGE_TITLE;
	}
	const {
		match: {
			path, // Extract path template /check-in/:id for route
			params: { id: eventId },
		},
	} = props;
	const history = useHistory();
	const locationState = history.location.state as CustomLocationState;

	const response = useAsync(() => eventCheckInStore.loadEvent(eventId), [eventId]);

	React.useEffect(() => {
		if (
			//
			// Is loading ferry trip complete?
			//
			response.type === 'data'
			//
			// Is previous route from QrCodeRedirectPage?
			//
			&& locationState && isQrCodeRedirectLocationState(locationState) && locationState.bookingId
		) {
			eventCheckInStore.checkInBooking(locationState.bookingId, true);
		}
	}, [response.type, locationState]);

	useCheckInRoutesWatch();

	if (response.type === 'error') {
		alertToast('Could not find event', 'error');
		return <Redirect to="/404" />;
	}

	if (response.type === 'loading' || !eventCheckInStore.eventDetails) {
		return (
			<div className="body-content check-in">
				<LottieSpinner />
			</div>
		);
	}

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<EventCheckInStoreContext.Provider value={eventCheckInStore}>
				<Switch>
					<Route path={`${path}/scan`}>
						<EventCheckInScanner />
					</Route>
					<Route path={path}>
						<EventCheckInView />
					</Route>
				</Switch>
			</EventCheckInStoreContext.Provider>
		</SecuredPage>
	);
}

export default observer(EventCheckInPage);
