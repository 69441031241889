import React from 'react';
import classNames from 'classnames';
import { BookingWizardTab } from '../BookingWizard';
import { FerryProgressBarLink } from './FerryProgressBarLink';

export interface FerryProgressBarStyleSectionProps {
	completedSection: boolean;
	isLastValidStep: boolean;
	sidebarExists: boolean;
	tabs: BookingWizardTab[];
	onClickTab?: (tab: BookingWizardTab) => void;
	isMobile: boolean;
	disabled: boolean;
}

export function FerryProgressBarStyledSection({
	completedSection,
	isLastValidStep,
	sidebarExists,
	tabs,
	onClickTab,
	isMobile,
	disabled,
}: FerryProgressBarStyleSectionProps) {
	let sectionStyleType = 'uncompleted';

	if (completedSection) {
		sectionStyleType = 'completed';
		if (isLastValidStep) {
			sectionStyleType = 'finished';
		}
	}

	const showUncompletedSpacers = !completedSection && tabs.length > 0;
	const showCompletedRightSpace = completedSection && isLastValidStep;
	const sideBarExist = sidebarExists
		? 'booking-wizard-progress-bar-with-sidebar'
		: 'booking-wizard-progress-bar-without-sidebar';

	const progressBarStyle = classNames('booking-wizard-progress-bar-section', sectionStyleType, sideBarExist);

	return (
		<div className={progressBarStyle}>
			{completedSection ? <div className="left-spacer" /> : null}
			{showUncompletedSpacers ? <div className="uncompleted-left-spacer" /> : null}
			{
				tabs.map((tab, index) => (
					<FerryProgressBarLink
						key={tab.name}
						tab={tab}
						totalTabCount={tabs.length}
						index={index}
						completedSection={completedSection}
						onClickTab={() => {
							if (onClickTab && !disabled) {
								onClickTab(tab);
							}
						}}
						isMobile={isMobile}
					/>
				))
			}
			{showUncompletedSpacers ? <div className="right-spacer" /> : null }
			{showCompletedRightSpace ? <div className="right-spacer" /> : null }
		</div>
	);
}
