import React from 'react';
import { IUserResult, store } from '../../Models/Store';
import { UserGroup } from '../../Models/Security/UserGroups';
import useStore from 'Hooks/useStore';
import * as uuid from 'uuid';

export const customer: IUserResult = createIUserResult({
	groups: [
		{
			name: 'User',
			hasBackendAccess: false,
		},
	],
});

export const admin: IUserResult = createIUserResult({
	groups: [
		{
			name: 'Admin',
			hasBackendAccess: true,
		},
	],
});

export const staff: IUserResult = createIUserResult({
	groups: [
		{
			name: 'Staff',
			hasBackendAccess: false,
		},
	],
});

export const manager: IUserResult = createIUserResult({
	groups: [
		{
			name: 'Manager',
			hasBackendAccess: true,
		},
	],
});

export function mockUseStore(user: IUserResult) {
	store.setLoggedInUser(user);
	(useStore as jest.Mock).mockReturnValue(
		{
			loggedIn: !!user,
			isStaff: store.userGroups.some(ug => (
				ug.name === 'Staff'
				|| ug.name === 'Manager'
				|| ug.name === 'Admin'
				|| ug.name === 'Super Administrators')),
			isManager: store.userGroups.some(ug => (
				ug.name === 'Manager'
				|| ug.name === 'Admin'
				|| ug.name === 'Super Administrators')),
			isAdmin: store.userGroups.some(ug => (
				ug.name === 'Admin'
				|| ug.name === 'Super Administrators')),
			isSuper: store.userGroups.some(ug => (
				ug.name === 'Super Administrators')),
			modal: {
				show: jest.fn(),
				hide: jest.fn(),
			},
			userData: {
				firstName: user?.user?.firstName ?? '',
				lastName: user?.user?.lastName ?? '',
				phone: user?.user?.phone ?? '',
			},
			updateBookingPrice: jest.fn(),
			setCartPriceDifference: jest.fn(),
			setCartPriceWithoutSurcharge: jest.fn(),
			setCartPriceWithSurcharge: jest.fn(),
		},
	);
}
export function createIUserResult(
	userInfo: Partial<IUserResult>,
) {
	return {
		user: {
			id: userInfo.id ?? 'rand-user-id',
			email: userInfo.email ?? 'test@example.com',
			firstName: userInfo.user?.firstName ?? 'FirstName',
			lastName: userInfo.user?.lastName ?? 'LastName',
			userName: userInfo.user?.userName ?? 'test-user-name',
			twoFactorEnabled: false,
			phone: userInfo.user?.phone ?? '0404040404',
			postcode: userInfo.user?.postcode ?? '4000',
			profilePictureId: '',
			profilePicture: new Blob(),
			bookings: [],
			vehicles: [],
			giftCertificates: [],
			notes: [],
			legacyId: 0,
			latestVehicles: [],
			newEmailAddress: '',
			needsPasswordReset: false,
			waitlistItems: [],
			adultPrice: 32,
			childPrice: 22,
			infantPrice: 0,
			prices: [],
			addOnsPrices: [],
			disabled: false,
			priceD: 10,
			priceE: 10,
			priceF: 10,
			priceG: 10,
			priceH: 10,
			address: '',
			communicationsRecords: [],
			noteAboutUsers: [],
		},
		type: 'user-data',
		id: uuid.v4(),
		userName: 'test-user-name',
		email: 'test@example.com',
		groups: [
			{
				name: userInfo.groups?.[0]?.name ?? 'User',
				hasBackendAccess: userInfo.groups?.[0]?.hasBackendAccess ?? false,
			},
		],
	} as IUserResult;
}

export function setLoggedInUser(user: IUserResult) {
	store.setLoggedInUser(user);
}

export function getLoggedInUser() {
	return store.userData;
}

export function createUserFromString(userGroup: UserGroup) {
	switch (userGroup) {
		case 'User':
			return customer;
		case 'Staff':
			return staff;
		case 'Manager':
			return manager;
		case 'Admin':
			return admin;
		case 'Super Administrators':
			return admin;
		default:
			return customer;
	}
}
