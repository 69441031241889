import React from 'react';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { observer } from 'mobx-react';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import {
	EventBookingPricesDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';

export interface GstLineProps {
	prices: EventBookingPricesDto | null;
}

function GstLine({
	prices,
}: GstLineProps) {
	const gst = prices?.gstPrice ?? 0;

	if (gst === 0) {
		<></>;
	}

	return (
		<CartCalculationLineItem
			keyToUse="event-gst-line-item"
			className="gst-line-item"
			lineItemDisplayName="GST:"
			lineItemPrice={formatPriceDisplay(
				gst,
				false,
				true,
			)}
		/>
	);
}

export default observer(GstLine);
