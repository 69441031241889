import * as React from 'react';
import { observer } from 'mobx-react';
import {
	EventSideBarProps,
	EventSideBarPropsErrors,
} from 'Views/Components/_HumanWritten/Events/EventSideBar/EventSideBar';
import { validateField } from '../../../../../Validators/Functions/HumanWritten/ValidateForm';
import { eventDetailsValidationRules } from './ValidationRules';
import { TextArea } from '../../../TextArea/TextArea';

export interface IPriceTabProps {
	state: EventSideBarProps;
	isDisabled: boolean;
	errors: EventSideBarPropsErrors;
}

function EventsTermsAndConditionsTab({
	state,
	isDisabled,
	errors,
}: IPriceTabProps) {
	return (
		<div className="custom-sidebar__tab-content terms-and-conditions-tab">
			<h6>Terms and conditions</h6>
			<TextArea
				model={state.eventDetails}
				modelProperty="termsAndConditions"
				label="Terms and conditions"
				placeholder="Enter terms and conditions"
				isRequired
				isDisabled={isDisabled}
				onAfterChange={() => {
					validateField(state.eventDetails!, 'termsAndConditions', errors, eventDetailsValidationRules);
				}}
				errors={errors?.termsAndConditions}
			/>
		</div>
	);
}

export default observer(EventsTermsAndConditionsTab);
