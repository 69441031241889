import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import QrCodeRedirect from 'Views/Components/_HumanWritten/QrCodeRedirect/QrCodeRedirect';
import { SecuredGroups } from 'Models/Security/UserGroups';

function QrCodeRedirectPage(props: RouteComponentProps) {
	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<div className="body-content">
				<QrCodeRedirect />
			</div>
		</SecuredPage>
	);
}

export default QrCodeRedirectPage;
