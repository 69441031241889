/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsCargoDetailEntity from 'Models/Security/Acl/VisitorsCargoDetailEntity';
import UserCargoDetailEntity from 'Models/Security/Acl/UserCargoDetailEntity';
import StaffCargoDetailEntity from 'Models/Security/Acl/StaffCargoDetailEntity';
import ManagerCargoDetailEntity from 'Models/Security/Acl/ManagerCargoDetailEntity';
import AdminCargoDetailEntity from 'Models/Security/Acl/AdminCargoDetailEntity';
import InvoicedUserCargoDetailEntity from 'Models/Security/Acl/InvoicedUserCargoDetailEntity';
import FixedPriceUserCargoDetailEntity from 'Models/Security/Acl/FixedPriceUserCargoDetailEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICargoDetailEntityAttributes extends IModelAttributes {
	contactFirstName: string;
	contactLastName: string;
	contactPhone: string;

	cargoId: string;
	cargo: Models.CargoEntity | Models.ICargoEntityAttributes;
	tickets: Array<
		| Models.TicketEntity
		| Models.ITicketEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CargoDetailEntity', 'Cargo Detail')
// % protected region % [Customise your entity metadata here] end
export default class CargoDetailEntity extends Model
	implements ICargoDetailEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCargoDetailEntity(),
		new UserCargoDetailEntity(),
		new StaffCargoDetailEntity(),
		new ManagerCargoDetailEntity(),
		new AdminCargoDetailEntity(),
		new InvoicedUserCargoDetailEntity(),
		new FixedPriceUserCargoDetailEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Contact First Name'] off begin
	/**
	 * The first name of the contact for the cargo.
	 */
	@Validators.Required()
	@observable
	@attribute<CargoDetailEntity, string>()
	@CRUD({
		name: 'Contact First Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactFirstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Contact Last Name'] off begin
	/**
	 * The last name of the contact for the cargo.
	 */
	@Validators.Required()
	@observable
	@attribute<CargoDetailEntity, string>()
	@CRUD({
		name: 'Contact Last Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactLastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Contact Phone'] off begin
	/**
	 * The phone number of the contact for the cargo.
	 */
	@Validators.Required()
	@observable
	@attribute<CargoDetailEntity, string>()
	@CRUD({
		name: 'Contact Phone',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactPhone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact Phone'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] off begin
		name: 'Cargo',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.CargoEntity,
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] end
	})
	public cargoId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public cargo: Models.CargoEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] off begin
		name: 'Tickets',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.TicketEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tickets',
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.TicketEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICargoDetailEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICargoDetailEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.contactFirstName !== undefined) {
				this.contactFirstName = attributes.contactFirstName;
			}
			if (attributes.contactLastName !== undefined) {
				this.contactLastName = attributes.contactLastName;
			}
			if (attributes.contactPhone !== undefined) {
				this.contactPhone = attributes.contactPhone;
			}
			if (attributes.cargoId !== undefined) {
				this.cargoId = attributes.cargoId;
			}
			if (attributes.cargo !== undefined) {
				if (attributes.cargo === null) {
					this.cargo = attributes.cargo;
				} else if (attributes.cargo instanceof Models.CargoEntity) {
					this.cargo = attributes.cargo;
					this.cargoId = attributes.cargo.id;
				} else {
					this.cargo = new Models.CargoEntity(attributes.cargo);
					this.cargoId = this.cargo.id;
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.TicketEntity) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.TicketEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		cargo {
			${Models.CargoEntity.getAttributes().join('\n')}
		}
		tickets {
			${Models.TicketEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			tickets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'tickets',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CargoDetailEntity.prototype, 'created');
CRUD(modifiedAttr)(CargoDetailEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
