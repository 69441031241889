import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export function NoneCheckedInEvent() {
	return (
		<div className="check-in__empty">
			<Icon name="check-box" />
			<h4>Nothing to see here</h4>
		</div>
	);
}
