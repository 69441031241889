/* eslint-disable dot-notation */
import {
	AlterationEntity,
	FerryTripEntity,
	PassengerTypeEntity,
	UserEntity,
} from 'Models/Entities';
import { PassengerTypeKey } from 'Models/Enums';
import { Model } from 'Models/Model';
import { fetchPassengerTypes } from 'Util/_HumanWritten/PassengerTypeStoreUtils';
import { upperCaseFirst } from 'Util/StringUtils';
import { isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { FerryBookingCreationDto } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	BookingFormState,
	IBookingFormState,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/BookingFormState';

interface IPassengerTypeStore {
	passengerTypes: PassengerTypeEntity[];
	retrieveAsync(refresh?: boolean): Promise<void>;

	// Property name
	getPriceProperty<T extends Model>(key: PassengerTypeKey, model: T): string;
	getChangeProperty(key: PassengerTypeKey): string;
	getWizardDataProperty(key: PassengerTypeKey): string
	getBookingSummaryProperty(key: PassengerTypeKey): string;
	getBookingDtoProperty(key: PassengerTypeKey): string;

	// Properties
	getSingularName(key: PassengerTypeKey, capitalise?: boolean): string;
	getPluralName(key: PassengerTypeKey, capitalise?: boolean): string;
	getDescription(key: PassengerTypeKey): string;
	getAbbreviation(key: PassengerTypeKey): string;
	getTooltip(key: PassengerTypeKey): string | undefined;
	getDisabled(key: PassengerTypeKey): boolean;
	getQuantity(key: PassengerTypeKey): number;

	// Totals
	getTotalFromBookingDto(dto: FerryBookingCreationDto | BookingFormState): number;
	getTotalFromBookingSummary(summary: BookingSummary): number;
	getTotalFromWizardData(wizardData: BookingWizardData): number;

	// Passenger info
	getPassengerInfoFromWizardData(wizardData: BookingWizardData): string;
	getPassengerInfoFromBookingSummary(summary: BookingSummary | null, shortName?: boolean): string;
	getPassengerInfoFromFormState(formState: IBookingFormState, shortName?: boolean): string;

	// Check-in
	checkInTableHeaders: string;
	checkInTableNullRow: string;
	getCheckInTableRow(summary?: BookingSummary): string | null;
}

export class PassengerTypeStore implements IPassengerTypeStore {
	/**
	 * These are all the passenger types in the database.
	 */
	private _passengerTypes: PassengerTypeEntity[] = [];

	/**
	 * These passenger types are not disabled and will appear throughout the application.
	 */
	private _enabledPassengerTypes: PassengerTypeEntity[] = [];

	/**
	 * True means the passenger types has been fetched, false otherwise.
	 */
	private _fetched: boolean = false;

	private _passengerTypeByKey: Map<PassengerTypeKey, PassengerTypeEntity> = new Map();

	public async retrieveAsync(refresh = false) {
		if (refresh) {
			this._fetched = false;
		}
		if (this._fetched) {
			return;
		}

		const passengerTypes = await fetchPassengerTypes();

		// Sort by PassengerTypeEntity.order
		this._passengerTypes = passengerTypes.sort((a, b) => a.order - b.order);
		this._enabledPassengerTypes = this._passengerTypes.filter(x => !x.disabled);

		for (const x of passengerTypes) {
			this._passengerTypeByKey.set(x.passengerTypeKey, x);
		}

		this._fetched = true;
	}

	/**
	 * Returns the ordered list of passenger types that are being used throughout the application.
	 */
	public get passengerTypes() {
		return this._enabledPassengerTypes;
	}

	/**
	 * Returns the model property name of the price for the passenger type based on the entity.
	 * Typically used for FerryTrip, Alteration, and User entities.
	 */
	public getPriceProperty<T extends Model>(key: PassengerTypeKey, model: T): string {
		const modelName = model.getModelName();

		if (new UserEntity().getModelName() === modelName) {
			switch (key) {
				case 'A':
					return 'adultPrice';
				case 'B':
					return 'childPrice';
				case 'C':
					return 'infantPrice';
			}
		}

		const containsPassenger = [new AlterationEntity().getModelName(), new FerryTripEntity().getModelName()];

		if (containsPassenger.includes(modelName)) {
			switch (key) {
				case 'A':
					return 'adultPassengerPrice';
				case 'B':
					return 'childPassengerPrice';
				case 'C':
					return 'infantPassengerPrice';
			}
		}

		return `price${key}`;
	}

	/**
	 * Returns the model property name of the change for the passenger type based on the entity.
	 * Typically used for Alteration entity.
	 */
	public getChangeProperty(key: PassengerTypeKey): string {
		switch (key) {
			case 'A':
				return 'adultPassengerChange';
			case 'B':
				return 'childPassengerChange';
			case 'C':
				return 'infantPassengerChange';
			default:
				return `change${key}`;
		}
	}

	/**
	 * Returns the model property name for the given passenger type of `BookingWizardData`.
	 */
	public getWizardDataProperty(key: PassengerTypeKey): string {
		switch (key) {
			case 'A':
				return 'adultsCount';
			case 'B':
				return 'childrenCount';
			case 'C':
				return 'infantCount';
			default:
				// return `totalPassenger${key}`;
				return `passengerCount${key}`;
		}
	}

	/**
	 * Returns the model property name for the given passenger type of `BookingSummary`.
	 */
	public getBookingSummaryProperty(key: PassengerTypeKey): string {
		switch (key) {
			case 'A':
				return 'adultPassengerCount';
			case 'B':
				return 'childPassengerCount';
			case 'C':
				return 'infantPassengerCount';
			default:
				return `passengerCount${key}`;
		}
	}

	/**
	 * Returns the model property name for the given passenger type of `FerryBookingCreationDto`.
	 */
	public getBookingDtoProperty(key: PassengerTypeKey): string {
		switch (key) {
			case 'A':
				return 'adultsCount';
			case 'B':
				return 'childrenCount';
			case 'C':
				return 'infantCount';
			default:
				return `passengerCount${key}`;
		}
	}

	public getSingularName(key: PassengerTypeKey, capitalise = true): string {
		const passenger = this._passengerTypeByKey.get(key);
		const name = passenger?.singularName ?? 'Passenger';
		const value = name.toLowerCase();

		if (capitalise) {
			return upperCaseFirst(value);
		}

		return value;
	}

	public getShortName(key: PassengerTypeKey, capitalise = true): string {
		const passenger = this._passengerTypeByKey.get(key);
		const name = passenger?.abbreviation ?? 'P';
		const value = name.toLowerCase();

		if (capitalise) {
			return upperCaseFirst(value);
		}

		return value;
	}

	public getPluralName(key: PassengerTypeKey, capitalise = true): string {
		const passenger = this._passengerTypeByKey.get(key);
		const name = passenger?.pluralName ?? passenger?.singularName ?? 'Passengers';
		const value = name.toLowerCase();

		if (capitalise) {
			return upperCaseFirst(value);
		}

		return value;
	}

	public getDescription(key: PassengerTypeKey): string {
		return this._passengerTypeByKey.get(key)?.description ?? '';
	}

	public getAbbreviation(key: PassengerTypeKey): string {
		const passenger = this._passengerTypeByKey.get(key);
		if (passenger && stringNotEmpty(passenger.abbreviation)) {
			return passenger.abbreviation;
		}
		return key;
	}

	public getTooltip(key: PassengerTypeKey): string | undefined {
		const passenger = this._passengerTypeByKey.get(key);
		if (passenger?.tooltipEnabled) {
			return passenger?.tooltipInfo;
		}
		return undefined;
	}

	public getDisabled(key: PassengerTypeKey): boolean {
		return this._passengerTypeByKey.get(key)?.disabled ?? true;
	}

	public getQuantity(key: PassengerTypeKey): number {
		return this._passengerTypeByKey.get(key)?.quantity ?? 1;
	}

	/**
	 * Returns sum of passengers in data model (only include for passenger types that are not disabled).
	 */
	public getTotalFromBookingDto(dto: FerryBookingCreationDto): number {
		let total = 0;
		for (const passengerType of this.passengerTypes) {
			const propertyName = this.getBookingDtoProperty(passengerType.passengerTypeKey);
			const units = dto[propertyName] ?? 0;
			const passengerCount = units * this.getQuantity(passengerType.passengerTypeKey);
			total += passengerCount;
		}
		return total;
	}

	/**
	 * Returns sum of passengers in data model (only include for passenger types that are not disabled).
	 */
	public getTotalFromBookingSummary(summary: BookingSummary): number {
		let total = 0;
		for (const passengerType of this.passengerTypes) {
			const propertyName = this.getBookingSummaryProperty(passengerType.passengerTypeKey);
			const units = summary[propertyName] ?? 0;
			const passengerCount = units * this.getQuantity(passengerType.passengerTypeKey);
			total += passengerCount;
		}
		return total;
	}

	/**
	 * Returns sum of passengers in data model (only include for passenger types that are not disabled).
	 */
	public getTotalFromWizardData(wizardData: BookingWizardData): number {
		let total = 0;
		for (const passengerType of this.passengerTypes) {
			const propertyName = this.getWizardDataProperty(passengerType.passengerTypeKey);
			const units = wizardData[propertyName] ?? 0;
			const passengerCount = units * this.getQuantity(passengerType.passengerTypeKey);
			total += passengerCount;
		}
		return total;
	}

	/**
	 * Returns e.g. '1 adult, 2 children, 1 infant'.
	 */
	public getPassengerInfoFromWizardData(wizardData: BookingWizardData): string {
		const items: string[] = [];

		for (const passengerType of this.passengerTypes) {
			const key = passengerType.passengerTypeKey;
			const property = this.getWizardDataProperty(key);
			const count: number | undefined = wizardData[property];
			if (count) {
				// Only include passengers with a count
				const label = count === 1
					? this.getSingularName(key, false)
					: this.getPluralName(key, false);
				items.push(`${count} ${label}`);
			}
		}

		return items.join(', ');
	}

	/**
	 * Returns e.g. '1 adult, 2 children, 1 infant'.
	 */
	public getPassengerInfoFromBookingSummary(
		summary: BookingSummary | null | IBookingFormState,
		shortName: boolean = false,
	) {
		if (isNullOrUndefined(summary)) {
			return '';
		}
		const items: string[] = [];

		for (const passengerType of this.passengerTypes) {
			const key = passengerType.passengerTypeKey;
			const property = this.getBookingSummaryProperty(key);
			const count: number | undefined = summary[property];
			if (count) {
				// Only include passengers with a count
				let label = count === 1
					? this.getSingularName(key, false)
					: this.getPluralName(key, false);
				if (shortName) {
					label = this.getShortName(key);
					items.push(`${count}${label}`);
				} else {
					items.push(`${count} ${label}`);
				}
			}
		}

		return items.join(', ');
	}

	public getPassengerInfoFromFormState(formState: IBookingFormState, shortName: boolean = false) {
		if (isNullOrUndefined(formState)) {
			return '';
		}
		const items: string[] = [];

		if (formState.adultsCount !== 0) {
			let label = formState.adultsCount === 1
				? this.getSingularName('A', false)
				: this.getPluralName('A', false);
			if (shortName) {
				label = this.getShortName('A');
				items.push(`${formState.adultsCount}${label}`);
			} else {
				items.push(`${formState.adultsCount} ${label}`);
			}
		}
		if (formState.childrenCount !== 0) {
			let label = formState.childrenCount === 1
				? this.getSingularName('B', false)
				: this.getPluralName('B', false);
			if (shortName) {
				label = this.getShortName('B');
				items.push(`${formState.childrenCount}${label}`);
			} else {
				items.push(`${formState.childrenCount} ${label}`);
			}
		}
		if (formState.infantCount !== 0) {
			let label = formState.infantCount === 1
				? this.getSingularName('C', false)
				: this.getPluralName('C', false);
			if (shortName) {
				label = this.getShortName('C');
				items.push(`${formState.infantCount}${label}`);
			} else {
				items.push(`${formState.infantCount} ${label}`);
			}
		}
		if (formState.passengerCountD !== 0) {
			let label = formState.passengerCountD === 1
				? this.getSingularName('D', false)
				: this.getPluralName('D', false);
			if (shortName) {
				label = this.getShortName('D');
				items.push(`${formState.passengerCountD}${label}`);
			} else {
				items.push(`${formState.passengerCountD} ${label}`);
			}
		}
		if (formState.passengerCountE !== 0) {
			let label = formState.passengerCountE === 1
				? this.getSingularName('E', false)
				: this.getPluralName('E', false);
			if (shortName) {
				label = this.getShortName('E');
				items.push(`${formState.passengerCountE}${label}`);
			} else {
				items.push(`${formState.passengerCountE} ${label}`);
			}
		}
		if (formState.passengerCountF !== 0) {
			let label = formState.passengerCountF === 1
				? this.getSingularName('F', false)
				: this.getPluralName('F', false);
			if (shortName) {
				label = this.getShortName('F');
				items.push(`${formState.passengerCountF}${label}`);
			} else {
				items.push(`${formState.passengerCountF} ${label}`);
			}
		}
		if (formState.passengerCountG !== 0) {
			let label = formState.passengerCountG === 1
				? this.getSingularName('G', false)
				: this.getPluralName('G', false);
			if (shortName) {
				label = this.getShortName('G');
				items.push(`${formState.passengerCountG}${label}`);
			} else {
				items.push(`${formState.passengerCountG} ${label}`);
			}
		}
		return items.join(', ');
	}

	/**
	 * Returns e.g. "A | C | I"
	 */
	public get checkInTableHeaders() {
		const headers: string[] = [];
		for (const passenger of this.passengerTypes) {
			headers.push(this.getAbbreviation(passenger.passengerTypeKey));
		}
		return headers.join(' | ');
	}

	/**
	 * Returns e.g. "- | - | -"
	 */
	public get checkInTableNullRow() {
		return new Array(this.passengerTypes.length).fill('-').join(' | ');
	}

	/**
	 * Returns e.g. "1 | - | 3"
	 */
	public getCheckInTableRow(summary?: BookingSummary) {
		if (!summary) {
			return null;
		}
		const values: string[] = [];
		for (const passenger of this.passengerTypes) {
			const propertyName = this.getBookingSummaryProperty(passenger.passengerTypeKey);
			const value = summary[propertyName] as number | undefined;
			if (value) {
				values.push(value.toString());
			} else {
				// Use '-' as placeholder when value is undefined or 0
				values.push('-');
			}
		}
		return values.join(' | ');
	}
}

const passengerTypeStore: IPassengerTypeStore = new PassengerTypeStore();
export default passengerTypeStore;
