import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { checkInHubUrl, logLevel } from 'Views/Components/_HumanWritten/CheckIn/CheckInHub/Constants';
import { CheckInRequestType } from 'Views/Components/_HumanWritten/CheckIn/CheckInHub/Types/CheckInRequestType';
import { CheckInResponseType } from 'Views/Components/_HumanWritten/CheckIn/CheckInHub/Types/CheckInResponseType';
import { ConnectionStatus } from 'Views/Components/_HumanWritten/CheckIn/CheckInHub/Types/ConnectionStatus';
import {
	EventCheckInStore,
} from 'Views/Components/_HumanWritten/CheckIn/EventCheckIn/EventContext/EventCheckInContext';

export default class EventCheckInSocket {
	private readonly context: EventCheckInStore;
	private readonly eventId: string;

	private connection: HubConnection;

	private isConnected: boolean = false;
	private isSubscribed: boolean = false;
	private isReconnecting: boolean = false;
	private retryCount: number = 0;
	private retryTimout: NodeJS.Timeout;

	private bookingIdsToIgnore: Set<string> = new Set();
	private bookingUpdateQueue: Map<string, (() => Promise<void>)[]> = new Map();
	private performingUpdateForBooking: Set<string> = new Set();

	constructor(context: EventCheckInStore) {
		this.context = context;
		this.eventId = context.eventId;

		this.openConnection().then(async () => {
			await this.subscribeToFerryTrip();
		});
	}

	public getEventId() {
		return this.eventId;
	}

	public addBookingToIgnore(bookingId: string) {
		this.bookingIdsToIgnore.add(bookingId);
	}

	public removeBookingToIgnore(bookingId: string) {
		this.bookingIdsToIgnore.delete(bookingId);
	}

	public getConnectionStatus(): ConnectionStatus {
		if (this.isSubscribed) {
			return ConnectionStatus.Connected;
		}
		if (this.isReconnecting) {
			return ConnectionStatus.Reconnecting;
		}
		return ConnectionStatus.Disconnected;
	}

	public async getConnection() {
		if (!this.connection) {
			await this.openConnection();
		}

		return this.connection;
	}

	public async openConnection() {
		if (this.connection) {
			return;
		}

		this.connection = new HubConnectionBuilder()
			.withUrl(checkInHubUrl, {
				withCredentials: true,
				transport: HttpTransportType.WebSockets,
				skipNegotiation: true,
			})
			.configureLogging(logLevel)
			.build();

		try {
			await this.connection.start();

			this.initEventHandlers();
		} catch (err: any) {
			console.error(err.toString());
			this.retryConnection();
		}
	}

	public async subscribeToFerryTrip() {
		if (!this.isConnected) {
			await this.openConnection();
		}

		// Subscribe to the ferry trip associated with the event
		const ferryTripId = this.context.eventDetails?.ferryTripId;

		await this.connection.send(
			CheckInRequestType[CheckInRequestType.SubscribeToCheckIn],
			ferryTripId,
		);
	}

	public async closeConnection() {
		await this.connection.stop();
	}

	private retryConnection() {
		if (this.retryTimout) {
			return;
		}

		this.retryTimout = setTimeout(async () => {
			this.retryCount++;
			await this.openConnection();
		}, this.getRetryDelay());
	}

	private getRetryDelay() {
		return 2 ** this.retryCount * 1000;
	}

	private initEventHandlers() {
		this.addConnectionStatusListeners();

		this.connection.on(CheckInResponseType[CheckInResponseType.SubscribedToCheckIn], () => {
			this.isSubscribed = true;
		});

		this.connection.on(CheckInResponseType[CheckInResponseType.BookingCheckedIn],
			this.onBookingCheckedIn.bind(this));
		this.connection.on(CheckInResponseType[CheckInResponseType.BookingCheckedOut],
			this.onBookingCheckedOut.bind(this));
		this.connection.on(CheckInResponseType[CheckInResponseType.BookingAdded],
			this.onBookingAdded.bind(this));
		this.connection.on(CheckInResponseType[CheckInResponseType.BookingUpdated],
			this.onBookingUpdated.bind(this));
		this.connection.on(CheckInResponseType[CheckInResponseType.BookingRemoved],
			this.onBookingRemoved.bind(this));
		this.connection.on(CheckInResponseType[CheckInResponseType.OnlineBookingStatusChanged],
			this.onOnlineBookingStatusChanged.bind(this));
	}

	private addConnectionStatusListeners() {
		this.connection.onclose(() => {
			this.isConnected = false;
			this.isSubscribed = false;
			this.isReconnecting = false;
		});

		this.connection.onreconnecting(() => {
			this.isReconnecting = true;
		});

		this.connection.onreconnected(() => {
			this.isConnected = true;
			this.isReconnecting = false;
		});
	}

	private async onBookingCheckedIn(bookingId: string) {
		if (this.isIgnoringBooking(bookingId)) {
			return;
		}

		this.addBookingToUpdateQueue(bookingId, async () => {
			await this.context.checkInBooking(bookingId, true, {
				skipUpdateServer: true,
				triggerAlert: false,
				refresh: false,
			});
		});

		await this.executeNextUpdateQueued(bookingId);
	}

	private async onBookingCheckedOut(bookingId: string) {
		if (this.isIgnoringBooking(bookingId)) {
			return;
		}

		this.addBookingToUpdateQueue(bookingId, async () => {
			await this.context.checkInBooking(bookingId, false, {
				skipUpdateServer: true,
				triggerAlert: false,
				refresh: false,
			});
		});

		await this.executeNextUpdateQueued(bookingId);
	}

	private async onBookingRemoved(bookingId: string) {
		if (this.isIgnoringBooking(bookingId)) {
			return;
		}

		this.addBookingToUpdateQueue(bookingId, async () => {
			this.context.removeBooking(bookingId);
		});

		await this.executeNextUpdateQueued(bookingId);
	}

	private async onBookingAdded(bookingId: string) {
		if (this.isIgnoringBooking(bookingId)) {
			return;
		}

		this.addBookingToUpdateQueue(bookingId, async () => {
			await this.context.checkInBooking(bookingId, false, {
				skipUpdateServer: true,
				triggerAlert: false,
				refresh: true,
			});
		});

		await this.executeNextUpdateQueued(bookingId);
	}

	private async onBookingUpdated(bookingId: string) {
		if (this.isIgnoringBooking(bookingId)) {
			return;
		}

		this.addBookingToUpdateQueue(bookingId, async () => {
			await this.context.checkInBooking(bookingId, false, {
				skipUpdateServer: true,
				triggerAlert: false,
				refresh: true,
			});
		});

		await this.executeNextUpdateQueued(bookingId);
	}

	private async onOnlineBookingStatusChanged() {
		await this.context.loadEvent(this.eventId);
	}

	/**
	 * For now, we are maintaining the existing logic for check-ins and how they update bookings (this is to ensure that
	 * even if the WebSocket connections fail, the check-in process will continue to work). However, when a user performs
	 * an action on a booking, they also receive a notification of the change through the SignalR connection. This causes
	 * the frontend to perform a double update on the booking. To prevent this from happening, we keep track of which
	 * booking is being updated by the current user and ignore SignalR updates regarding that specific booking.
	 *
	 * @param bookingId the booking id that we want to check
	 * @private
	 */
	private isIgnoringBooking(bookingId: string) {
		return this.bookingIdsToIgnore.has(bookingId);
	}

	private addBookingToUpdateQueue(bookingId: string, method: () => Promise<void>) {
		if (this.bookingUpdateQueue.has(bookingId)) {
			this.bookingUpdateQueue.set(bookingId, [...this.bookingUpdateQueue.get(bookingId)!, method]);
		} else {
			this.bookingUpdateQueue.set(bookingId, [method]);
		}
	}

	private async executeNextUpdateQueued(bookingId: string) {
		// If we are already performing an update for this booking, do nothing
		if (this.performingUpdateForBooking.has(bookingId)) {
			return;
		}

		// Protect against another update being executed while we are waiting for the current update to complete
		this.performingUpdateForBooking.add(bookingId);

		while (this.bookingUpdateQueue.has(bookingId)) {
			const nextMethod = this.bookingUpdateQueue.get(bookingId)?.shift();

			if (!!nextMethod) {
				// Disabling the eslint rule in this instance because we want to block each method executing so that they
				// are executed sequentially.
				// eslint-disable-next-line no-await-in-loop
				await nextMethod();
			} else {
				// There are no more methods to execute for this booking
				this.bookingUpdateQueue.delete(bookingId);
			}
		}

		// We are done executing the update for this booking
		this.performingUpdateForBooking.delete(bookingId);
	}
}
