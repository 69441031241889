import React from 'react';
import { observer } from 'mobx-react';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import { IPriceLineItem } from 'Services/Api/_HumanWritten/PricingService/PricingResult';

export interface AddedFeeLinesProps {
	fees: IPriceLineItem[];
	onRemoveFee?: ((id: string) => void);
}

function AddedFeeLines({ fees, onRemoveFee }: AddedFeeLinesProps) {
	const feesToDisplay = fees?.filter(x => x.price > 0);
	const { isStaff } = store;

	return (
		<>
			{feesToDisplay
				?.map(fee => (
					<CartCalculationLineItem
						keyToUse={fee?.id}
						lineItemDisplayName={fee.description}
						lineItemPrice={formatPriceDisplay(fee.price, true)}
						className="service-fee__line-item"
						hasRemovalButton={isNotNullOrUndefined(onRemoveFee) && fee.removable && isStaff}
						removalOnClick={() => {
							if (isNotNullOrUndefined(onRemoveFee) && isNotNullOrUndefined(fee.id)) {
								return onRemoveFee(fee.id);
							}
							return () => {};
						}}
					/>
				))}
		</>
	);
}

export default observer(AddedFeeLines);
