import Axios from 'axios';
import { SERVER_URL } from 'Constants';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';

export function getTermsAndConditions() {
	const transactionId = getFerryBookingTransactionIdFromStorage();
	return Axios.get(
		`${SERVER_URL}/api/entity/ConfigGeneralEntity/t-and-cs?transactionId=${transactionId}`,
	);
}

export function getGiftCertificateRedeemText() {
	return Axios.get(
		`${SERVER_URL}/api/entity/ConfigGeneralEntity/redeem-text`,
	);
}
