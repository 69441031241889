import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export function CheckInSearchStart() {
	return (
		<div key="search-start" className="search__empty">
			<Icon name="search" />
			<h4>Start by searching</h4>
			<p className="search__empty__subtitle search-table">
				Search by name, booking ID, rego or mobile number to find a customer&apos;s booking.
			</p>
		</div>
	);
}
