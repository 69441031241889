import React from 'react';
import * as uuid from 'uuid';
import { BookingEntity, EventDetailEntity } from 'Models/Entities';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	EventBreakdownDescription,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/EventBreakdownDescription';
import { EventBookingWizardData } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { GetFormattedDate, GetFormattedTime } from 'Views/Components/_HumanWritten/Events/EventCards/EventUtils';
import {
	EventBookingPricesDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';

export interface EventBreakdownProps {
	wizardData: EventBookingWizardData;
	eventDetails: EventDetailEntity;
	bookingToEdit: BookingEntity | null;
	tripSummaryLocation: tripSummaryLocationType;
	afterPayment: boolean;
	prices: EventBookingPricesDto | null;
}

export function EventBreakdown({
	wizardData,
	bookingToEdit = null,
	tripSummaryLocation,
	afterPayment,
	eventDetails,
	prices,
}: EventBreakdownProps) {
	return (
		<div key={uuid.v4()} className="departing event-card">
			<p className="route-name">
				{eventDetails.name}
			</p>
			<div className="event-details-overview">
				<div className="event-details-breakdown">
					<p>
						{GetFormattedDate(eventDetails.ferryTrip!.startDate, eventDetails.ferryTrip!.endDate)}
					</p>
					<p>
						{GetFormattedTime(eventDetails.ferryTrip!.startTime, eventDetails.ferryTrip!.endTime)}
					</p>
					<p className="booked-event-passenger-details">
						<EventBreakdownDescription
							wizardData={wizardData}
							eventDetails={eventDetails}
						/>
					</p>
				</div>
				<p className="base-total-cost">{formatPriceDisplay(prices?.subtotalPrice ?? 0, false, true)}</p>
			</div>
			<div className="direction-total-cost">
				<p>Total</p>
				<p>{formatPriceDisplay(prices?.subtotalPrice ?? 0, false, true)}</p>
			</div>
		</div>
	);
}
