import jsQR from 'jsqr';
import React, { useEffect } from 'react';

import { useAbortController } from 'Hooks/useAbortController';
import { useInterval } from 'Hooks/useInterval';
import alertToast from 'Util/ToastifyUtils';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { CameraFrameTopOffset, QR_CAPTURE_ERROR, QrCodeDimensions } from '../../Constants';
import { useCheckInQrCode } from '../../Hooks/useCheckInQrCode';
import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { useScanner } from '../../Stores/useScanner';
import { ScannerActionMap } from '../../Types/scannerAction';
import { captureHorizontallyCentered } from '../../Utils/captureHorizontallyCentered';
import CameraFrame from '../CameraFrame';
import ScannerSwitchButton from '../ScannerSwitchButton';

const frame = QrCodeDimensions;

interface QrCodeScannerProps {
	videoRef: React.RefObject<HTMLVideoElement>;
	disabled?: boolean;
}

export default function QrCodeScanner({ videoRef, disabled = true }: QrCodeScannerProps) {
	const abortControllerRef = useAbortController();
	const { goToCheckIn } = useScannerLocation();
	const dispatch = useScanner(x => x.dispatch);
	const { processQrCode } = useCheckInQrCode();

	const capture = () => {
		let canvas: HTMLCanvasElement;
		try {
			canvas = captureHorizontallyCentered(videoRef, frame.width, frame.height, CameraFrameTopOffset);
		} catch (err) {
			console.error('Could not draw image on canvas from video');
			alertToast(QR_CAPTURE_ERROR, 'error', undefined, { autoClose: 2200 });
			goToCheckIn();
			return;
		}

		try {
			const abortController = new AbortController();
			abortControllerRef.current = abortController;

			const ctx = canvas.getContext('2d');
			if (!ctx) {
				throw new Error('Missing canvas');
			}

			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			const code = jsQR(imageData.data, imageData.width, imageData.height);

			if (code) {
				stop();
				processQrCode(code.data).finally(start);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const { start, stop } = useInterval(capture, 300);

	useEffect(() => {
		if (!disabled) {
			start();
		}
	}, [disabled]);

	const handleClicks = {
		onClose() {
			goToCheckIn();
		},
		onToggle(e?: React.MouseEvent<HTMLButtonElement>) {
			e?.stopPropagation();
			dispatch({ action: ScannerActionMap.ToggleMode });
		},
	};

	return (
		<>
			<CameraFrame width={frame.width} height={frame.height} topOffset={CameraFrameTopOffset} />
			<button className="check-in-scanner__close-btn" onClick={handleClicks.onClose}>
				<Icon name="cross" classname="check-in-scanner__cross" />
			</button>
			<ScannerSwitchButton onToggle={handleClicks.onToggle}>Switch to REGO PLATE</ScannerSwitchButton>
			<h6 className="check-in-scanner__description" style={{ top: `${frame.height + CameraFrameTopOffset}rem` }}>
				Scan ticket
			</h6>
		</>
	);
}
