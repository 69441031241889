import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { EVENTS_LIST_VIEW_PAGE_TITLE } from '../../../ConstantPageNames';
import EventCardList from '../../Components/_HumanWritten/Events/EventCards/EventCardList';

export default function EventsListView(props: RouteComponentProps) {
	document.title = EVENTS_LIST_VIEW_PAGE_TITLE;

	return (
		<>
			<NavigationWrapper className="events-page__nav" {...props} />
			<div className="body-content">
				<EventCardList {...props} upcoming />
			</div>
		</>
	);
}
