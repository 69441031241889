import React from 'react';
import moment from 'moment';
import { action, observable } from 'mobx';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import alertToast from 'Util/ToastifyUtils';
import { createNewGiftVoucher, updateGiftVoucher } from 'Services/Api/_HumanWritten/GiftCertificateService';
import { store } from 'Models/Store';
import GiftVoucherModalContent, { GiftVoucherState } from './GiftVoucherModalContent';
import { isNotNullOrUndefined } from '../../../../../Util/TypeGuards';

export type VoucherStatus = 'VALID' | 'EXPIRED' | 'USED' | 'USEDEXPIRED';

export interface GiftVoucherUsageDto {
	humanReadableId: string,
	amount: number,
}

export interface FetchedGiftVouchersDto {
	giftVouchers: GiftVoucherDto[];
	count: number;
}

export interface GiftVoucherDto {
	id: string;
	userId: string;
	code: string;
	status: VoucherStatus;
	remainingValue: number;
	initialValue: number;
	expiryDate: Date;
	giftCertificateUsages: GiftVoucherUsageDto[];
	price?: number;
	transactionId?: string;
	invoiced?: boolean;
}

export interface AddUpdateVoucherDto {
	userId?: string,
	voucherAmount: number,
	expiryDate: Date,
	voucherId?: string,
	price?: number;
	invoiced?: boolean;
}
export interface GiftVouchersProps {
	reload: () => void;
	listOfGiftCertificates?: FetchedGiftVouchersDto,
}

/**
 * Starts the user flow to create a voucher. The flow can involve multiple pop-ups and navigating to a payment page.
 *
 * @param userId The owner of the gift voucher.
 * @param refresh The callback function to refetch the gift vouchers.
 */
export const startCreatingVoucher = async (userId: string, refresh: () => void) => {
	const state = observable<GiftVoucherState>({
		// Set amounts to be undefined so the textfields can show the placeholders
		voucherAmount: undefined as any,
		paymentAmount: undefined as any,
		expiryDate: moment(new Date()).add(1, 'year').toDate(),
		paymentMethod: 'pay',
	});

	const confirmed = await modalWithCustomInput(
		'Create gift voucher',
		<GiftVoucherModalContent state={state} editMode={false} />,
		{
			actionClassName: 'create-gift-certificate-modal',
			cancelText: 'Close',
			confirmText: 'Create voucher',
			resolveOnCancel: true,
			onConfirm: action(() => {
				const voucherError = state.voucherAmount === undefined || state.voucherAmount <= 0;
				const paymentError = state.paymentAmount === undefined || state.paymentAmount < 0;

				if (paymentError) {
					state.paymentAmountError = 'Please enter a value more than $0';
				}

				if (voucherError) {
					state.voucherAmountError = 'Please enter a value more than $0';
				}

				return !voucherError && !paymentError;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	if (state.paymentMethod === 'pay' && isNotNullOrUndefined(state.paymentAmount) && state.paymentAmount > 0) {
		// create gift cert and transaction
		const { data } = await createNewGiftVoucher({
			userId: userId,
			expiryDate: state.expiryDate,
			voucherAmount: state.voucherAmount,
			price: state.paymentAmount ?? 0,
			invoiced: false,
		});

		// TODO: show a loading modal?

		// navigate to payment page
		store.routerHistory.push({
			pathname: `/gift-payment/${data.transaction?.id}`,
			search: `?RedirectUrl=${store.routerHistory.location.pathname}`,
		});
	} else {
		try {
			alertToast('Processing gift voucher...', 'info');
			await createNewGiftVoucher({
				userId: userId,
				expiryDate: state.expiryDate,
				voucherAmount: state.voucherAmount,
				price: state.paymentAmount ?? 0,
				invoiced: true,
			});
			alertToast('Successfully created gift voucher', 'success');
			refresh();
		} catch (e) {
			alertToast('Unable to create gift certificate', 'error');
		}
	}
};

interface editVoucherOptions {
	userId: string;
	refresh: () => void;
	voucherId: string;
	voucherAmount: number;
	amountRemaining: number;
	expiryDate: Date;
}

/**
 * Starts the user flow to edit a voucher. The flow can involve multiple pop-ups.
 */
export async function startUpdatingVoucher({
	userId,
	refresh,
	voucherId,
	voucherAmount,
	amountRemaining,
	expiryDate,
}: editVoucherOptions) {
	const state = observable<GiftVoucherState>({
		voucherAmount: amountRemaining ?? 0,
		expiryDate: expiryDate ?? moment(new Date()).add(1, 'year').toDate(),
	});

	const confirmed = await modalWithCustomInput(
		'Edit gift voucher',
		<GiftVoucherModalContent state={state} editMode />,
		{
			actionClassName: 'create-gift-certificate-modal',
			cancelText: 'Close',
			confirmText: 'Confirm update',
			resolveOnCancel: true,
			onConfirm: action(() => {
				const voucherError = state.voucherAmount === undefined || state.voucherAmount < 0;

				if (voucherError) {
					state.voucherAmountError = 'Please enter a value of $0 or more';
				}

				return !voucherError;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	try {
		await updateGiftVoucher({
			// Initial value + the difference between the new remaining amount and the original remaining amount
			voucherAmount: (voucherAmount ?? 0) + (state.voucherAmount - (amountRemaining ?? 0)),
			expiryDate: state.expiryDate,
			voucherId: voucherId,
			userId: userId,
		});
		alertToast('Updated gift certificate', 'success');
		refresh();
	} catch (e) {
		alertToast('Unable to update gift certificate', 'error');
	}
}
