import React from 'react';
import { AxiosResponse } from 'axios';
import { store } from 'Models/Store';
import { resetReservationTimer } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { onWizardSuccess } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import alertToast from 'Util/ToastifyUtils';
import { AWAITING_PAYMENT_TIMEOUT } from 'Views/Pages/AwaitEwayPaymentPage';
import { PaymentError } from 'Views/Components/_HumanWritten/Payment/usePayment';
import {
	getLastPaymentAttempt,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Payment/PaymentTabUtils';
import {
	PaymentProviderTransactionDetails,
	completePaymentBulkBooking,
	setupPayment,
} from 'Services/Api/_HumanWritten/PaymentService';

interface usePaymentTabParams {
	transactionId: string | null,
	paymentCompletedUrl: string,
	bookingForAlteration: boolean,
	errorCode?: string | null;
	eventBooking: boolean;
	bulkBooking: boolean;
	returnTrip: boolean;
	userId?: string;
}

export function usePaymentTab(params: usePaymentTabParams) {
	const {
		transactionId,
		paymentCompletedUrl,
		bookingForAlteration,
		errorCode,
		eventBooking,
		bulkBooking,
		returnTrip,
		userId,
	} = params;

	const [accessCodeError, setAccessCodeError] = React.useState<PaymentError>(PaymentError.NO_ERROR);
	const [transactionDetails, setTransactionDetails] = React.useState<PaymentProviderTransactionDetails | null>(null);

	const handleAccidentalRefresh = () => {
		const lastPaymentAttempt = getLastPaymentAttempt();
		if (!errorCode && lastPaymentAttempt) {
			const now = new Date();
			const fifteenSecondsAgo = new Date(now.getTime() - AWAITING_PAYMENT_TIMEOUT);

			// True means they've only attempted within the last 15 seconds
			if (lastPaymentAttempt >= fifteenSecondsAgo) {
				store.routerHistory.replace('/awaiting-payment');
				return true;
			}
		}
		return false;
	};

	React.useEffect(() => {
		if (transactionId === null) {
			// If our previous validation works as expected we should never get here, but it is a possibility that we
			// need to account for. In this scenario the user will not be able to proceed
			setAccessCodeError(PaymentError.ERROR);
			return;
		}

		if (transactionId !== '') {
			// If this fails, it is because the timer is expired. If that is the case then generateAccessCode will
			// handle the problem
			resetReservationTimer(transactionId).catch(err => {});
		}

		if (bulkBooking) { // wizardData?.bulkBookingTripIds !== undefined) {
			completePaymentBulkBooking(transactionId).then(x => {
				onWizardSuccess(store, transactionId, false, false, false, userId);
			}).catch(_ => {
				alertToast('Completing bulk booking failed');
				store.routerHistory.push('/cart');
			});
			return;
		}

		if (handleAccidentalRefresh()) {
			return;
		}

		const accessCodePromise = new Promise((resolve, reject) => {
			setupPayment(transactionId, paymentCompletedUrl, eventBooking)
				.then(details => {
					if (details.status === 201) {
						// 201 means no payment required, so navigate user straight to success page
						onWizardSuccess(
							store,
							transactionId,
							bookingForAlteration,
							returnTrip,
							eventBooking,
							userId,
							true,
						);
						store.routerHistory.push(details.data);
					}
					const parsedDetails: PaymentProviderTransactionDetails = details.data;
					resolve(parsedDetails);
				}).catch((err: AxiosResponse) => {
				// eslint-disable-next-line dot-notation,no-prototype-builtins
					if (err !== null && err['response'].status === 409) {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject(PaymentError.NO_PAYMENT_ERROR);
					} else {
						// eslint-disable-next-line prefer-promise-reject-errors
						reject(PaymentError.ERROR);
					}
				});
		});

		const timeoutPromise = new Promise((_, reject) => {
			setTimeout(() => {
				// We reject here instead of resolving because this ensures that we don't have to do additional
				// checking in the promise.then to see whether the transaction details are there. It ensures that
				// our one successful scenario is handled in .then and that all potential issues are handled in
				// .catch
				// eslint-disable-next-line prefer-promise-reject-errors
				reject(PaymentError.TIMEOUT);
			}, 30000);
		});

		// This inclusion will make dev work on this stuff easier, as without this if we try to put a breakpoint in
		// the access code method then the payment will time out because the 5 seconds will have been reached
		// By setting timeout to false we can easily turn it off during development
		const promiseArray = [accessCodePromise];
		const timeout = true;
		if (timeout) {
			promiseArray.push(timeoutPromise);
		}

		Promise.race(promiseArray)
			.then(details => {
				// Because of the way that we have set up these promises, the promise will only resolve if we get
				// transaction details. If we get an error of any kind then
				setTransactionDetails(details as PaymentProviderTransactionDetails);
			})
			.catch((error: PaymentError) => {
				setAccessCodeError(error);
			});
	}, []);

	return {
		accessCodeError,
		transactionDetails,
	};
}
