import { createContext } from 'react';
import { action, observable, runInAction } from 'mobx';
import { getEventBooking, updateCheckedInStatus } from 'Services/Api/_HumanWritten/CheckInService';
import * as EventCheckInUtils from '../EventCheckInUtils';
import { EventDto } from '../EventCheckInEntities/EventDto';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import EventCheckInSocket from 'Views/Components/_HumanWritten/CheckIn/EventCheckIn/EventContext/EventCheckInSocket';

export interface EventCheckInBookingOptions {
	refresh?: boolean;
	triggerAlert?: boolean;

	// Skip sending a request to the server to update the booking (e.g. when only the client needs to update the display)
	skipUpdateServer?: boolean;
}

export interface IEventCheckInStore {
	eventId: string;
	eventDetails: EventDto;
	bookings: EventCheckInBookingOverviewDto[];
	showFilters: boolean;
	/**
	 * True if the loaded ferry trip has not departed based on the trip's departure time OR the staff member has
	 * confirmed that it is ok to board a departed trip. When false, a warning popup will appear when
	 * 'Scan QR code' button is clicked.
	 */
	skipFerryHasDepartedModal: boolean;
	/**
	 * Load the event and booking entities. This is usually only triggered once during start of CheckInPage.
	 * @param id The event id.
	 */
	loadEvent: (id: string) => Promise<void>;
	setBookings: (x: EventCheckInBookingOverviewDto[]) => void;
	/**
	 * Update `skipFerryHasDepartedModal` to true so that the popup doesn't appear again.
	 */
	confirmCheckInForDepartedTrip: () => void;
	/**
	 * Updates booking.checkedIn, re-renders bookings, and display check-in alert.
	 * May display error if booking not found from list.
	 */
	checkInBooking: (id: string, checkedIn: boolean, options?: EventCheckInBookingOptions) => Promise<void>;
	/**
	 * Whether or not to show the event booking filters sidebar.
	 */
	setShowFilters: (showFilters: boolean) => void;

	getCheckInConnection: () => EventCheckInSocket;
}

export class EventCheckInStore implements IEventCheckInStore {
	@observable
	eventId: string;

	@observable
	bookings: EventCheckInBookingOverviewDto[];

	@observable
	showFilters: boolean = false;

	@observable
	eventDetails: EventDto;

	@observable
	skipFerryHasDepartedModal: boolean;

	private checkInSocket: EventCheckInSocket;

	public getCheckInConnection() {
		return this.checkInSocket;
	}

	@action
	async loadEvent(id: string) {
		this.eventId = id;
		const response = await EventCheckInUtils.fetchEventCheckInData(id);
		if (response) {
			runInAction(() => {
				this.bookings = response.bookingSummaries.filter(x => new EventCheckInBookingOverviewDto(x));
				this.eventDetails = response.eventDetailDto;
				this.skipFerryHasDepartedModal = (new Date()) > this.eventDetails.startDateTime;
			});

			this.createCheckInSocket();
		}
	}

	@action
	setBookings(x: EventCheckInBookingOverviewDto[]) {
		this.bookings = x;
	}

	@action
	setShowFilters() {
		this.showFilters = !this.showFilters;
	}

	@action
	confirmCheckInForDepartedTrip() {
		this.skipFerryHasDepartedModal = false;
	}

	@action
	async checkInBooking(id: string, checkedIn: boolean, options: EventCheckInBookingOptions = {}) {
		const {
			refresh = false,
			triggerAlert = true,
			skipUpdateServer = false,
		} = options;

		let booking: EventCheckInBookingOverviewDto;
		let added = false;
		const bookingIndex = this.bookings.findIndex(x => x.id === id);

		if (bookingIndex === -1) {
			//
			// Fetch booking from server and add to list
			//
			const newBooking = await getEventBooking(id);
			added = true;
			booking = new EventCheckInBookingOverviewDto(newBooking.data);
		} else if (refresh) {
			//
			// Refetch same booking for latest data
			//
			const sameBooking = await getEventBooking(id);
			this.bookings[bookingIndex] = new EventCheckInBookingOverviewDto(sameBooking.data);
			booking = this.bookings[bookingIndex];
		} else {
			//
			// Use existing booking
			//
			booking = this.bookings[bookingIndex];
		}

		// Update checkedIn status if required
		booking.checkedIn = checkedIn;
		if (!skipUpdateServer) {
			await updateCheckedInStatus({
				bookingId: booking.id,
				checkedIn: checkedIn,
				ferryTripId: this.eventDetails?.ferryTripId,
			});
		}

		// Re-render list
		if (added) {
			this.setBookings([...this.bookings, booking].sort(EventCheckInUtils.sortByFullName()));
		} else {
			this.setBookings([...eventCheckInStore.bookings]);
		}

		if (triggerAlert) {
			EventCheckInUtils.checkInAlert(booking, booking.checkedIn);
		}
	}

	removeBooking(id: string) {
		this.setBookings(this.bookings.filter(x => x.id !== id));
	}

	private async createCheckInSocket() {
		if (this.checkInSocket) {
			// Do nothing if we already have a socket for this ferry trip
			if (this.checkInSocket.getEventId() === this.eventId) {
				return;
			}

			await this.checkInSocket.closeConnection();
		}
		this.checkInSocket = new EventCheckInSocket(this);
	}
}

/**
 * This should only be used in the root of the component tree that uses CheckInStoreContext.Provider.
 *
 * Otherwise, use useCheckInStore hook to access check-in related data.
 */
export const eventCheckInStore: IEventCheckInStore = new EventCheckInStore();
export const EventCheckInStoreContext = createContext<IEventCheckInStore>(eventCheckInStore);
