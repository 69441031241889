import React from 'react';
import { CartSummary } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartSummary';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { useHistory } from 'react-router';
import { BookingEntity } from 'Models/Entities';
import {
	resetReservationTimer,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	BookingWizardData,
	clearOldBookingWizardData,
	getDefaultWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface CartTabProps {
	wizardData: BookingWizardData;
	selectedTrips: SelectedTrips;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	setSelectedTrips: (trips: SelectedTrips) => void;
	isIpad: boolean;
	/**
	 * This function's only purpose is to re-render the whole component...
	 */
	setRefresh: (refresh: boolean) => void;
	refresh: boolean;
	setTabIsLoading?: (isLoading: boolean) => void;
}

export function CartTab({
	wizardData,
	selectedTrips,
	onUpdateWizardData,
	setSelectedTrips,
	isIpad,
	setRefresh,
	refresh,
	setTabIsLoading,
}: CartTabProps) {
	const history = useHistory();
	const onAddNewBooking = (oldWizardData: BookingWizardData) => {
		const transactionId = getFerryBookingTransactionIdFromStorage();
		if (transactionId !== null) {
			resetReservationTimer(transactionId).then(() => {
				const newData = getDefaultWizardData();
				newData.tabSelected = oldWizardData.tabSelected;
				newData.fromLocationId = oldWizardData.fromLocationId;
				newData.toLocationId = oldWizardData.toLocationId;
				newData.startDate = oldWizardData.startDate;
				newData.endDate = oldWizardData.endDate;
				newData.ticketSelectionStartDate = oldWizardData.ticketSelectionStartDate;
				newData.ticketSelectionEndDate = oldWizardData.ticketSelectionEndDate;
				newData.userId = oldWizardData.userId;

				onUpdateWizardData(newData);
				clearOldBookingWizardData();
				history.push(isIpad ? '/booking-wizard/search' : '/booking-wizard/tickets');
			});
		}
	};

	return (
		<CartSummary
			wizardData={wizardData}
			selectedTrips={selectedTrips}
			onUpdateWizardData={onUpdateWizardData}
			setSelectedTrips={setSelectedTrips}
			onAddNewBooking={onAddNewBooking}
			setRefresh={setRefresh}
			refresh={refresh}
			tripSummaryLocation="cart"
			setTabIsLoading={setTabIsLoading}
		/>
	);
}
