/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminUploadedImageEntity from 'Models/Security/Acl/AdminUploadedImageEntity';
import ManagerUploadedImageEntity from 'Models/Security/Acl/ManagerUploadedImageEntity';
import StaffUploadedImageEntity from 'Models/Security/Acl/StaffUploadedImageEntity';
import UserUploadedImageEntity from 'Models/Security/Acl/UserUploadedImageEntity';
import InvoicedUserUploadedImageEntity from 'Models/Security/Acl/InvoicedUserUploadedImageEntity';
import FixedPriceUserUploadedImageEntity from 'Models/Security/Acl/FixedPriceUserUploadedImageEntity';
import VisitorsUploadedImageEntity from 'Models/Security/Acl/VisitorsUploadedImageEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IUploadedImageEntityAttributes extends IModelAttributes {
	imageId: string;
	image: Blob;
	description: string;

	headerImageEventDetailId?: string;
	headerImageEventDetail?: Models.EventDetailEntity | Models.IEventDetailEntityAttributes;
	supportingImageEventDetailId?: string;
	supportingImageEventDetail?: Models.EventDetailEntity | Models.IEventDetailEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('UploadedImageEntity', 'Uploaded Image')
// % protected region % [Customise your entity metadata here] end
export default class UploadedImageEntity extends Model
	implements IUploadedImageEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminUploadedImageEntity(),
		new ManagerUploadedImageEntity(),
		new StaffUploadedImageEntity(),
		new UserUploadedImageEntity(),
		new InvoicedUserUploadedImageEntity(),
		new FixedPriceUserUploadedImageEntity(),
		new VisitorsUploadedImageEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Image'] off begin
	/**
	 * The image that is uploaded.
	 */
	@Validators.Required()
	@observable
	@attribute<UploadedImageEntity, string>({ file: 'image' })
	@CRUD({
		name: 'Image',
		displayType: 'file',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'image',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="The image that is uploaded." style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public imageId: string;

	@observable
	public image: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Image'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * A description for the image.
	 */
	@observable
	@attribute<UploadedImageEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'hidden',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Header Image Event Detail'] on begin
		name: 'Header Image Event Detail',
		displayType: 'hidden',
		order: 30,
		referenceTypeFunc: () => Models.EventDetailEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'headerImageEventDetails',
			oppositeEntity: () => Models.EventDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Header Image Event Detail'] end
	})
	public headerImageEventDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public headerImageEventDetail: Models.EventDetailEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Supporting Image Event Detail'] on begin
		name: 'Supporting Image Event Detail',
		displayType: 'hidden',
		order: 40,
		referenceTypeFunc: () => Models.EventDetailEntity,
		// % protected region % [Modify props to the crud options here for reference 'Supporting Image Event Detail'] end
	})
	public supportingImageEventDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public supportingImageEventDetail: Models.EventDetailEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IUploadedImageEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUploadedImageEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.image !== undefined) {
				this.image = attributes.image;
			}
			if (attributes.imageId !== undefined) {
				this.imageId = attributes.imageId;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.headerImageEventDetailId !== undefined) {
				this.headerImageEventDetailId = attributes.headerImageEventDetailId;
			}
			if (attributes.headerImageEventDetail !== undefined) {
				if (attributes.headerImageEventDetail === null) {
					this.headerImageEventDetail = attributes.headerImageEventDetail;
				} else if (attributes.headerImageEventDetail instanceof Models.EventDetailEntity) {
					this.headerImageEventDetail = attributes.headerImageEventDetail;
					this.headerImageEventDetailId = attributes.headerImageEventDetail.id;
				} else {
					this.headerImageEventDetail = new Models.EventDetailEntity(attributes.headerImageEventDetail);
					this.headerImageEventDetailId = this.headerImageEventDetail.id;
				}
			}
			if (attributes.supportingImageEventDetailId !== undefined) {
				this.supportingImageEventDetailId = attributes.supportingImageEventDetailId;
			}
			if (attributes.supportingImageEventDetail !== undefined) {
				if (attributes.supportingImageEventDetail === null) {
					this.supportingImageEventDetail = attributes.supportingImageEventDetail;
				} else if (attributes.supportingImageEventDetail instanceof Models.EventDetailEntity) {
					this.supportingImageEventDetail = attributes.supportingImageEventDetail;
					this.supportingImageEventDetailId = attributes.supportingImageEventDetail.id;
				} else {
					this.supportingImageEventDetail = new Models.EventDetailEntity(attributes.supportingImageEventDetail);
					this.supportingImageEventDetailId = this.supportingImageEventDetail.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		headerImageEventDetail {
			${Models.EventDetailEntity.getAttributes().join('\n')}
		}
		supportingImageEventDetail {
			${Models.EventDetailEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'headerImageEventDetail',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UploadedImageEntity.prototype, 'created');
CRUD(modifiedAttr)(UploadedImageEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
