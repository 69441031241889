/* eslint-disable max-len */
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import moment from 'moment/moment';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { store } from 'Models/Store';
import { RouteEntity } from 'Models/Entities';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import If from 'Views/Components/If/If';
import {
	FilteredTripCard,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/FilteredTripCard';
import {
	TripSelectionCalendar,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Tickets/TripSelectionCalendar';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { wizardModeOptions } from 'Models/Enums';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Link } from 'react-router-dom';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';
import { SingleActionModalContent } from 'Views/Components/_HumanWritten/Modal/Base/SingleActionModalContent';
import useStore from 'Hooks/useStore';
import { formatTripFilterInfo } from 'Util/_HumanWritten/BookingWizardDataUtils';
import { calculateTotalPassengers } from 'Util/_HumanWritten/CapacityCalculationUtils';
import {
	GetTicketsTabData,
	TicketsTabStateData,
	TicketsTabTrip,
} from 'Services/Api/_HumanWritten/BookingWizardDataService';
import {
	isDateBeforeToday,
	isSameDate,
	isSameRoute,
} from 'Util/_HumanWritten/TicketSelectionValidationUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	BasePriceFerryTripsDto,
	FetchBasePriceForFerryTrips,
} from 'Services/Api/_HumanWritten/PricingService/PricingService';
import {
	dataToFerryTripBookingDto,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import alertToast from 'Util/ToastifyUtils';
import { useAbortController } from '../../../../../../Hooks/useAbortController';

export interface TicketSelectionTabProps {
	wizardData: BookingWizardData;
	saveChanges: (newData: BookingWizardData) => void;
	route: RouteEntity | null;
	selectedTrips: SelectedTrips;
}

export function TicketSelectionTab({
	wizardData,
	saveChanges,
	route,
	selectedTrips,
}: TicketSelectionTabProps) {
	const { isIpad } = useDeviceDetect();
	const { isStaff, isManager } = useStore();
	const abortControllerRef = useAbortController();

	const vehicleTrip = wizardData.tabSelected === 'vehicle';
	const oldWizardData = getOldFerryBookingWizardData();

	const [trips, setTrips] = useState<TicketsTabStateData>({
		departureTickets: null,
		returningTickets: null,
	});

	const [priceByDepartureFerryTrip, setPriceByDepartureFerryTrip] = useState<{ [key: string]: number }>({});
	const [priceByReturnFerryTrip, setPriceByReturnFerryTrip] = useState<{ [key: string]: number }>({});

	const [departureLoading, setDepartureLoading] = useState<boolean>(true);
	const [destinationLoading, setDestinationLoading] = useState<boolean>(true);

	const currentStartDate = useRef(wizardData.startDate);
	const currentEndDate = useRef(wizardData.endDate);
	const currentFromLocation = useRef(wizardData.fromLocationId);
	const currentToLocation = useRef(wizardData.toLocationId);
	const firstRender = useRef(true);
	const onlyAlteringReturn = wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false;

	// Clear all vehicle data from the booking wizard if it is no longer a vehicle ticket
	// Or else the pricing will be affected
	useEffect(() => {
		if (!vehicleTrip) {
			const newData = { ...wizardData };
			newData.cargoTypeId = '';
			newData.cargoMake = '';
			newData.vehicleLengthId = '';
			newData.trailerTypeId = 'NO_TRAILER';
			newData.trailerLengthId = '';
			newData.departingTripOptions = [];
			newData.returningTripOptions = [];
			saveChanges(newData);
		}

		// We only want to execute this logic when the tabSelected value changes (vehicle or passenger)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wizardData.tabSelected]);

	useEffect(() => {
		if (store?.redirectedFromWaitlistEmail === true && trips.departureTickets !== null && trips.returningTickets !== null) {
			if (wizardData.departureTicketId === '') {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="You've just missed out"
						body={<h5>Spaces fill up quick, sorry about that. You&apos;re still on the waitlist so we&apos;ll email you when space becomes available.</h5>}
						confirmContent="OK"
					/>,
				});
			} else {
				showModal({
					key: 'waitlist-availability-modal',
					content: <SingleActionModalContent
						title="Spaces available!"
						body={<h5>Good news - there is space available on this ferry, so we&apos;ve preselected your trip for you to book with ease.</h5>}
						confirmContent="Let's go"
					/>,
				});
			}
			store.setRedirectedFromWaitlistEmail(false);
		}
	}, [trips.departureTickets, trips.returningTickets]);

	const checkIfValidSelectedFerryTicket = () => {
		if (trips.departureTickets === null || trips.returningTickets === null) {
			return;
		}

		const newData = { ...wizardData };
		// Check if DEPARTURE ticket is still a valid selection
		// If there is a selected departure ticket AND either the route is not the same OR the amount of
		// passengers no longer fit on the trip, unselect the ticket
		const departureFerryTrip = trips.departureTickets.find(x => x.id === newData.departureTicketId);
		if (newData.departureTicketId !== '' && isNotNullOrUndefined(departureFerryTrip) && isNotNullOrUndefined(selectedTrips.departingTrip)) {
			if (!isSameRoute(newData, selectedTrips, true)) {
				newData.departureTicketId = '';
			}

			let remainingDepartingCapacity = departureFerryTrip.passengerSpacesAvailable;
			if (newData.departureTicketId === oldWizardData?.departureTicketId
				&& isNotNullOrUndefined(remainingDepartingCapacity)) {
				remainingDepartingCapacity += calculateTotalPassengers(oldWizardData);
			}
			if (
				isNotNullOrUndefined(remainingDepartingCapacity)
				&& calculateTotalPassengers(newData) > remainingDepartingCapacity
				&& (!isStaff || (isStaff && !isDateBeforeToday(newData.startDate)))
				&& !isManager
			) {
				newData.departureTicketId = '';
			}
		}

		// Check if RETURN ticket is still a valid selection
		// If there is a selected return ticket AND either the route is not the same OR the amount of
		// passengers no longer fit on the trip, unselect the ticket
		const returnFerryTrip = trips.returningTickets.find(x => x.id === newData.returningTicketId);
		if (newData.returningTicketId !== '' && isNotNullOrUndefined(returnFerryTrip) && isNotNullOrUndefined(selectedTrips.returningTrip)) {
			if (!isSameRoute(newData, selectedTrips, false)) {
				newData.returningTicketId = '';
			}

			let remainingReturningCapacity = returnFerryTrip.passengerSpacesAvailable;
			if (isNotNullOrUndefined(oldWizardData) && newData.returningTicketId === oldWizardData?.returningTicketId
				&& isNotNullOrUndefined(remainingReturningCapacity)) {
				remainingReturningCapacity += calculateTotalPassengers(oldWizardData);
			}
			if (
				isNotNullOrUndefined(remainingReturningCapacity)
				&& calculateTotalPassengers(newData) > remainingReturningCapacity
				&& (!isStaff || (isStaff && !isDateBeforeToday(newData.endDate)))
				&& !isManager
			) {
				newData.returningTicketId = '';
			}
		}

		// Saving the changes to the wizard data so that the cart is in sync.
		saveChanges(newData);
	};

	// This effect will un-select departing/returning ferry trips that are no longer valid
	// when the ferry filters are changed (route, vehicle/towOn and passengers only)
	useEffect(() => {
		checkIfValidSelectedFerryTicket();
	}, [
		saveChanges,
		selectedTrips.returningTrip,
		selectedTrips.departingTrip,
		wizardData.fromLocationId,
		wizardData.toLocationId,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
		wizardData.adultsCount,
		wizardData.childrenCount,
		wizardData.infantCount,
		wizardData.passengerCountD,
		wizardData.passengerCountE,
		wizardData.passengerCountF,
		wizardData.passengerCountG,
		wizardData.passengerCountH,
	]);

	// This effect will un-select departing/returning ferry trips that are no longer valid
	// when the ferry filters are changed (start and end date only)
	useEffect(() => {
		const newData = { ...wizardData };
		// If DEPARTURE ticket date is not the same as start date, unselect the ticket
		// OR if altering the return ticket of a return trip ONLY,
		// then unselect the ticket if the date is not the same as the endDate
		if ((newData.departureTrip !== false
				&& newData.departureTicketId !== ''
				&& !isSameDate(wizardData, selectedTrips, true)
				&& selectedTrips.departingTrip?.startDate !== undefined)
			|| (newData.departureTrip === false
				&& !(moment(selectedTrips.departingTrip?.startDate).format('YYYY-MM-DD')
					=== moment(wizardData.endDate).format('YYYY-MM-DD'))
				&& selectedTrips.departingTrip?.startDate !== undefined)
		) {
			newData.departureTicketId = '';
		}

		// If DESTINATION ticket date is not the same as end date, unselect the ticket
		if (newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined
			&& !isSameDate(wizardData, selectedTrips, false)
		) {
			newData.returningTicketId = '';
		}
		// Saving the changes to the wizard data so that the cart is in sync.
		saveChanges(newData);
	}, [
		saveChanges,
		wizardData.startDate,
		wizardData.endDate,
	]);

	// This effect will un-select a returning trip if a departing trip that leaves after it is selected
	useEffect(() => {
		const newData = { ...wizardData };

		if (newData.departureTicketId !== '' && selectedTrips.departingTrip !== undefined
			&& newData.returningTicketId !== '' && selectedTrips.returningTrip !== undefined) {
			if (selectedTrips.departingTrip.departureDateTime >= selectedTrips.returningTrip.departureDateTime) {
				newData.returningTicketId = '';
				saveChanges(newData);
			}
		}
	}, [
		saveChanges,
		selectedTrips.departingTrip,
		selectedTrips.returningTrip,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
	]);

	// Helper function to fetch trips
	const fetchTripsForDateRange = async (
		startDate: Date,
		endDate: Date,
		isDeparture: boolean,
	) => {
		const dates = [];
		const currentDate = new Date(startDate);

		// Collect all dates in the range
		while (currentDate <= endDate) {
			dates.push(new Date(currentDate)); // Store each date
			currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
		}

		// Let's say the 3rd date is the one the user is focused on initially
		const focusedIndex = 2; // 3rd date (0-indexed)
		const reorderedDates = [];

		// Add the 3rd date first
		if (dates[focusedIndex]) {
			reorderedDates.push(dates[focusedIndex]);
		}

		// Add the surrounding dates alternately from the center (focusedIndex)
		let leftIndex = focusedIndex - 1;
		let rightIndex = focusedIndex + 1;

		while (leftIndex >= 0 || rightIndex < dates.length) {
			if (leftIndex >= 0) {
				reorderedDates.push(dates[leftIndex]);
				leftIndex--;
			}
			if (rightIndex < dates.length) {
				reorderedDates.push(dates[rightIndex]);
				rightIndex++;
			}
		}

		// Check if there's an existing controller and abort the previous request
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		// Create a new AbortController for the current fetch
		abortControllerRef.current = new AbortController();
		const { signal } = abortControllerRef.current;

		// Fetch data in the new order
		for (const dateToFetch of reorderedDates) {
			// eslint-disable-next-line no-await-in-loop
			const data = await GetTicketsTabData(
				signal,
				wizardData.fromLocationId,
				wizardData.toLocationId,
				dateToFetch,
				isDeparture,
				onlyAlteringReturn,
				wizardData.bookingToEdit ?? null,
				store.isStaff,
				store.isStaff ? wizardData.userId : store.userId,
			);

			setTrips(prevTrips => {
				if (isDeparture) {
					return {
						...prevTrips,
						departureTickets: [...(prevTrips.departureTickets || []), ...(data.departureTickets || [])],
					};
				}

				return {
					...prevTrips,
					returningTickets: [...(prevTrips.returningTickets || []), ...(data.returningTickets || [])],
				};
			});
		}
	};

	const updateTrips = async (updateDeparture: boolean, updateReturn: boolean) => {
		// const fetchPromises: Promise<TicketsTabData>[] = [];

		// Always update departure trips if updateDeparture is true
		const shouldUpdateDeparture = updateDeparture
			&& !(wizardData.wizardMode === 'ALTERATION'
				&& wizardData.departureTrip === false
				&& updateReturn);

		// Only update return trips if:
		// 1. updateReturn is true
		// 2. In CREATE mode AND the trip type is `return`
		// OR in ALTERATION mode AND the user is altering the return trip or both directions
		const shouldUpdateReturn = updateReturn
			&& ((wizardData.wizardMode === 'CREATE' && wizardData.tripType === 'return')
				|| (wizardData.wizardMode === 'ALTERATION'
					&& wizardData.departureTrip !== true));

		setTrips({
			departureTickets: shouldUpdateDeparture || onlyAlteringReturn ? null : trips.departureTickets,
			returningTickets: shouldUpdateReturn && !onlyAlteringReturn ? null : trips.returningTickets,
		});

		try {
			if (shouldUpdateDeparture) {
				const startDate = new Date(moment(wizardData.startDate).add(-2, 'day').format('YYYY-MM-DD'));
				const endDate = new Date(moment(wizardData.startDate).add(2, 'day').format('YYYY-MM-DD'));

				// Fetch departure trips sequentially
				await fetchTripsForDateRange(startDate, endDate, true);
			}

			// Date ranges for return trips
			if (shouldUpdateReturn) {
				const startDate = new Date(moment(wizardData.endDate).add(-2, 'day').format('YYYY-MM-DD'));
				const endDate = new Date(moment(wizardData.endDate).add(2, 'day').format('YYYY-MM-DD'));

				// Fetch return trips sequentially
				await fetchTripsForDateRange(startDate, endDate, onlyAlteringReturn);
			}
		} catch (error: any) {
			if (error?.name === 'CanceledError' || error.code === 'ERR_CANCELED') {
				// Do nothing
			} else {
				setTrips({
					departureTickets: shouldUpdateDeparture || onlyAlteringReturn ? [] : trips.departureTickets,
					returningTickets: shouldUpdateReturn && !onlyAlteringReturn ? [] : trips.returningTickets,
				});
				alertToast('Could not fetch trips', 'error');
			}
		}
	};

	useEffect(() => {
		if (!onlyAlteringReturn && (
			firstRender.current
			|| wizardData.fromLocationId !== currentFromLocation.current
			|| wizardData.toLocationId !== currentToLocation.current
			|| (wizardData.startDate !== currentStartDate.current && wizardData.endDate !== currentEndDate.current)
		)
		) {
			updateTrips(true, true);
			currentStartDate.current = wizardData.startDate;
			currentEndDate.current = wizardData.endDate;
			currentFromLocation.current = wizardData.fromLocationId;
			currentToLocation.current = wizardData.toLocationId;
		}
	}, [wizardData.fromLocationId, wizardData.toLocationId, wizardData.startDate, wizardData.endDate, wizardData.bookingToEdit]);

	useEffect(() => {
		if (!firstRender.current && currentStartDate.current !== wizardData.startDate) {
			updateTrips(true, false).then(() => {
				currentStartDate.current = wizardData.startDate;
			});
		}
	}, [wizardData.startDate]);

	useEffect(() => {
		if ((!firstRender.current && currentEndDate.current !== wizardData.endDate) || (onlyAlteringReturn && firstRender.current)) {
			updateTrips(false, true).then(() => {
				if (wizardData.wizardMode !== 'ALTERATION') {
					currentEndDate.current = wizardData.endDate;
				}
			});
		}
	}, [wizardData.endDate]);

	// We want to update the return trips when the trip type changes
	// The `updateTrips` function will only update the return trips if it meets the criteria
	useEffect(() => {
		if (!firstRender.current) {
			updateTrips(false, true).then(() => {
				currentEndDate.current = wizardData.endDate;
			});
		}
	}, [wizardData.tripType]);

	// Previously there was effect code here to preselect a trip based on the query parameters. this is not needed in
	// the new version of the code so the effect has been removed

	useEffect(() => {
		firstRender.current = false;
	}, []);

	// We need 2 different debounced pricing requests because the departure and return pricing requests are different
	// and if we don't separate them out, the return pricing request will be triggered when the departure pricing
	// request is in progress and result in it being ignored
	const debouncedDeparturePricingRequest = useCallback(
		AwesomeDebouncePromise(
			(allTickets: TicketsTabTrip[], departure: boolean, wizData: BookingWizardData) => {
				return FetchBasePriceForFerryTrips({
					bookingCreationDto: dataToFerryTripBookingDto(wizData, false),
					ferryTripIds: allTickets.map(x => x.id),
					departureBooking: departure,
				} as BasePriceFerryTripsDto);
			},
			500,
		),
		[],
	);

	const debouncedReturnPricingRequest = useCallback(
		AwesomeDebouncePromise(
			(allTickets: TicketsTabTrip[], departure: boolean, wizData: BookingWizardData) => {
				return FetchBasePriceForFerryTrips({
					bookingCreationDto: dataToFerryTripBookingDto(wizData, false),
					ferryTripIds: allTickets.map(x => x.id),
					departureBooking: departure,
				} as BasePriceFerryTripsDto);
			},
			500,
		),
		[],
	);

	useEffect(() => {
		setDepartureLoading(true);
		if (isNotNullOrUndefined(trips.departureTickets) && trips.departureTickets.length > 0) {
			debouncedDeparturePricingRequest(trips.departureTickets, true, wizardData).then(x => {
				setPriceByDepartureFerryTrip(x);
				setDepartureLoading(false);
			});
		} else {
			setPriceByDepartureFerryTrip({});
			setDepartureLoading(false);
		}
	}, [
		trips.departureTickets,
		wizardData.tabSelected,
		wizardData.adultsCount,
		wizardData.childrenCount,
		wizardData.infantCount,
		wizardData.passengerCountD,
		wizardData.passengerCountE,
		wizardData.passengerCountF,
		wizardData.passengerCountG,
		wizardData.passengerCountH,
	]);

	useEffect(() => {
		setDestinationLoading(true);
		if (isNotNullOrUndefined(trips.returningTickets) && trips.returningTickets.length > 0 && wizardData.tripType === 'return') {
			debouncedReturnPricingRequest(trips.returningTickets, false, wizardData).then(x => {
				setPriceByReturnFerryTrip(x);
				setDestinationLoading(false);
			});
		} else {
			setPriceByReturnFerryTrip({});
			setDestinationLoading(false);
		}
	}, [
		trips.returningTickets,
		wizardData.tabSelected,
		wizardData.adultsCount,
		wizardData.childrenCount,
		wizardData.infantCount,
		wizardData.passengerCountD,
		wizardData.passengerCountE,
		wizardData.passengerCountF,
		wizardData.passengerCountG,
		wizardData.passengerCountH,
	]);

	const selectedDepartingTrip = trips.departureTickets?.find(x => x.id === wizardData.departureTicketId);
	const selectedReturningTrip = trips.returningTickets?.find(x => x.id === wizardData.returningTicketId);

	// This will handle the scenario where the user clicks the book now button on a trip that
	if (
		stringIsEmpty(wizardData.bookingToEdit)
		&& !isManager
		&& selectedDepartingTrip !== undefined
		&& (
			(wizardData.tabSelected === 'vehicle' && (selectedDepartingTrip?.vehicleSpacesAvailable ?? 0) < 1)
			|| (wizardData.tabSelected === 'passenger' && (selectedDepartingTrip?.passengerSpacesAvailable ?? 0) === 0)
		)) {
		const newData = { ...wizardData };
		newData.departureTicketId = '';
		saveChanges(newData);
	}

	return (
		<div className="booking-wizard-tab ticket-selection-tab-container">
			<h2 className="booking-wizard__tab-header ticket-tab-text">
				<If condition={wizardData.wizardMode === wizardModeOptions.CREATE}>
					Select your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip !== undefined}>
					{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip ? 'Edit your departing ferry ticket' : 'Edit your returning ferry ticket'}
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION && wizardData.departureTrip === undefined}>
					Edit your ferry tickets
				</If>
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
					<div>
						<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
							<Icon name="cross" classname="icon" />
						</Link>
					</div>
				</If>
			</h2>
			<div className="ticket-tab__info inline-button-container ticket-tab-text">
				<If condition={isIpad}>
					<p className="ticket-tab__info__text">
						For {
							formatTripFilterInfo(wizardData)
						}
					</p>
					<Button
						className="edit-filter-button"
						colors={Colors.Alternate}
						display={Display.Text}
						onClick={() => store.routerHistory.push('/booking-wizard/search')}
					>
						Edit
					</Button>
				</If>
			</div>
			<h4 className="ticket-tab__sub-header ticket-tab-text">
				{wizardData.wizardMode === 'ALTERATION' && wizardData.departureTrip === false ? 'Returning' : 'Departing'} Trip
			</h4>
			<If condition={!isNullOrUndefined(route)}>
				<h6 className="ticket-tab-text sub-text">
					{route?.departure?.name} to {route?.destination?.name},&nbsp;
					{moment(wizardData.wizardMode === 'ALTERATION'
					&& wizardData.departureTrip === false
						? wizardData.ticketSelectionEndDate
						: wizardData.ticketSelectionStartDate)
						.format('DD MMM YYYY')}
				</h6>
			</If>
			<If condition={isNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId === ''}>
				<TripSelectionCalendar
					wizardData={wizardData}
					ferryTrips={trips.departureTickets}
					departure={wizardData.departureTrip !== false}
					saveChanges={saveChanges}
					selectedTrips={selectedTrips}
					isStaff={isStaff}
					refreshTrips={() => updateTrips(true, true)}
					priceByFerryTrip={priceByDepartureFerryTrip}
					loading={departureLoading}
				/>
			</If>
			<If condition={isNotNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId !== ''}>
				<FilteredTripCard
					className="selected-trip-card-tile"
					departure={wizardData.departureTrip !== false}
					ticketsTabTrip={selectedDepartingTrip!}
					index={0}
					key={wizardData.departureTicketId}
					wizardData={wizardData}
					saveChanges={saveChanges}
					departureTripTime={null}
					isStaff={isStaff}
					locationInApp="sidebar"
					priceByFerryTrip={priceByDepartureFerryTrip}
					loading={departureLoading}
				/>
			</If>
			<If condition={isNullOrUndefined(selectedDepartingTrip) && wizardData.departureTicketId !== ''}>
				<div className="spinner-container">
					<LottieSpinner className="relative" />
				</div>
			</If>
			<If condition={wizardData.tripType === 'return'}>
				<div className="ticket-tab__sub-header return-booking inline-button-container ticket-tab-text">
					<h4 className="">Returning Trip</h4>
					<If condition={wizardData.wizardMode !== 'ALTERATION'}>
						<Button
							className="edit-filter-button"
							colors={Colors.Red}
							display={Display.Text}
							onClick={() => {
								const newData = { ...wizardData };
								newData.tripType = 'one way';
								newData.returningTicketId = '';
								saveChanges(newData);
							}}
						>
							Remove
						</Button>
					</If>
				</div>
				<h6 className="ticket-tab-text sub-text">
					{route?.destination?.name} to {route?.departure?.name},&nbsp;
					{moment(wizardData.ticketSelectionEndDate).format('DD MMM YYYY')}
				</h6>
				<If condition={isNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId === ''}>
					<TripSelectionCalendar
						wizardData={wizardData}
						ferryTrips={trips.returningTickets}
						departure={false}
						saveChanges={saveChanges}
						selectedTrips={selectedTrips}
						isStaff={isStaff}
						refreshTrips={() => updateTrips(true, true)}
						priceByFerryTrip={priceByReturnFerryTrip}
						loading={destinationLoading}
					/>
				</If>
				<If condition={isNotNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId !== ''}>
					<FilteredTripCard
						className="selected-trip-card-tile"
						departure={false}
						ticketsTabTrip={selectedReturningTrip!}
						index={1}
						key={wizardData.returningTicketId}
						wizardData={wizardData}
						saveChanges={saveChanges}
						departureTripTime={selectedDepartingTrip?.startDate ?? null}
						isStaff={isStaff}
						locationInApp="sidebar"
						priceByFerryTrip={priceByReturnFerryTrip}
						loading={destinationLoading}
					/>
				</If>
				<If condition={isNullOrUndefined(selectedReturningTrip) && wizardData.returningTicketId !== ''}>
					<div className="spinner-container">
						<LottieSpinner className="relative" />
					</div>
				</If>
			</If>
		</div>
	);
}
