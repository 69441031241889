/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsNoteReferenceManyToMany from '../Security/Acl/VisitorsNoteReferenceManyToMany';
import UserNoteReferenceManyToMany from '../Security/Acl/UserNoteReferenceManyToMany';
import StaffNoteReferenceManyToMany from '../Security/Acl/StaffNoteReferenceManyToMany';
import ManagerNoteReferenceManyToMany from '../Security/Acl/ManagerNoteReferenceManyToMany';
import AdminNoteReferenceManyToMany from '../Security/Acl/AdminNoteReferenceManyToMany';
import InvoicedUserNoteReferenceManyToMany from '../Security/Acl/InvoicedUserNoteReferenceManyToMany';
import FixedPriceUserNoteReferenceManyToMany from '../Security/Acl/FixedPriceUserNoteReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IUserNoteAttributes extends IModelAttributes {
	userId: string;
	noteId: string;

	user: Models.UserEntity | Models.IUserEntityAttributes;
	note: Models.NotesEntity | Models.INotesEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('UserNote')
export default class UserNote
	extends Model
	implements IUserNoteAttributes {
	public static acls: IAcl[] = [
		new VisitorsNoteReferenceManyToMany(),
		new UserNoteReferenceManyToMany(),
		new StaffNoteReferenceManyToMany(),
		new ManagerNoteReferenceManyToMany(),
		new AdminNoteReferenceManyToMany(),
		new InvoicedUserNoteReferenceManyToMany(),
		new FixedPriceUserNoteReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public userId: string;

	@observable
	@attribute()
	public noteId: string;

	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;

	@observable
	@attribute({ isReference: true })
	public note: Models.NotesEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IUserNoteAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.userId) {
				this.userId = attributes.userId;
			}
			if (attributes.noteId) {
				this.noteId = attributes.noteId;
			}

			if (attributes.user) {
				if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			} else if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}

			if (attributes.note) {
				if (attributes.note instanceof Models.NotesEntity) {
					this.note = attributes.note;
					this.noteId = attributes.note.id;
				} else {
					this.note = new Models.NotesEntity(attributes.note);
					this.noteId = this.note.id;
				}
			} else if (attributes.noteId !== undefined) {
				this.noteId = attributes.noteId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
