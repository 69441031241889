import { Symbols } from 'Symbols';
import { initValidators, IModelAttributeValidationError, ErrorType } from '../Util';
import { Model } from 'Models/Model';

export default function validate() {
	return (target: Record<string | symbol, any>, key: string) => {
		initValidators(target, key);
		target[Symbols.validatorMap][key].push('Colour');
		target[Symbols.validator].push(
			(model: Model): Promise<IModelAttributeValidationError | null> => new Promise(resolve => {
				if (model[key] === null || model[key] === undefined) {
					resolve(null);
				} else {
					resolve(isColour(model[key])
						? null
						: {
							errorType: ErrorType.INVALID,
							errorMessage: 'The value is not a valid hex colour',
							attributeName: key,
							target: model,
						});
				}
			}),
		);
	};
}

export function isColour(colour: string): boolean {
	return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
		.test(colour);
}
