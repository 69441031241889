import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { CheckInSearchStart } from '../CheckInTable/CheckInSearchStart';
import { CheckInSearchEmpty } from '../CheckInTable/CheckInSearchEmpty';
import { runInAction } from 'mobx';
import { BookingEntity } from 'Models/Entities';
import { CheckInSearchTableProps } from '../CheckInTable/CheckInSearchTable';
import CheckInSearchListRow from './CheckInSearchListRow';
import { Flex } from 'Views/Components/Flex/Flex';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { Text } from 'Views/Components/Text/Text';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

function CheckInSearchList(props: CheckInSearchTableProps) {
	const {
		bookings,
		isLoading,
		isLoadingMore,
		canLoadMore,
		onSeeMore,
	} = useCheckInSearchList(props);

	if (isLoading) {
		return <LottieSpinner />;
	}

	if (bookings === undefined) {
		return <CheckInSearchStart />;
	}

	if (bookings.length === 0) {
		return <CheckInSearchEmpty />;
	}

	let endOfList = canLoadMore
		? (
			<Button
				display={Display.Solid}
				colors={Colors.Alternate}
				onClick={onSeeMore}
				disabled={isLoadingMore}
			>
				See more
			</Button>
		)
		: <Text>End of results</Text>;

	if (isLoadingMore) {
		endOfList = <Text className="mb-md">Loading...</Text>;
	}

	return (
		<Flex direction="col" className="check-in-list" flex={1}>
			{
				bookings.map(x => (
					<CheckInSearchListRow key={x.id} booking={x} />
				))
			}
			<Flex justify="center" className="pt-md">
				{endOfList}
			</Flex>
		</Flex>
	);
}

export default observer(CheckInSearchList);

function useCheckInSearchList({ state: checkInMoveState, fetch }: CheckInSearchTableProps) {
	const state = useLocalStore<{
		bookings?: BookingEntity[];
		isLoading: boolean;
		isLoadingMore: boolean;
		canLoadMore: boolean;
	}>(() => ({
		isLoading: false,
		isLoadingMore: false,
		canLoadMore: true,
	}));

	/**
	 * This useEffect is only triggered when search term is updated.
	 */
	React.useEffect(() => {
		if (checkInMoveState.searchTerm === undefined) {
			return;
		}

		// This solves the problem where the component unmounts before the result has been processed
		let subscribed = true;

		const fetchData = async () => {
			runInAction(() => {
				checkInMoveState.pageNo = 0;
				state.isLoading = true;
			});
			const result = await fetch(checkInMoveState.searchTerm || '', checkInMoveState.pageNo);
			if (subscribed) {
				runInAction(() => {
					checkInMoveState.totalRecords = result.countBookingEntitys.number;
					state.bookings = result.bookingEntitys;
					state.canLoadMore = result.bookingEntitys.length !== 0;
					state.isLoading = false;
				});
			}
		};

		fetchData();

		return () => {
			subscribed = false;
		};
	}, [checkInMoveState, checkInMoveState.searchTerm, fetch, state]);

	const onSeeMore = React.useCallback(async () => {
		runInAction(() => {
			checkInMoveState.pageNo += 1;
			state.isLoadingMore = true;
		});

		const result = await fetch(checkInMoveState.searchTerm || '', checkInMoveState.pageNo);
		runInAction(() => {
			if (result.bookingEntitys.length === 0) {
				state.canLoadMore = false;
			} else {
				const currentBookings = state.bookings ?? [];
				state.bookings = [...currentBookings, ...result.bookingEntitys];
				if (state.bookings.length === result.countBookingEntitys.number) {
					state.canLoadMore = false;
				}
			}
			state.isLoadingMore = false;
		});
	}, [checkInMoveState, fetch, state]);

	return {
		bookings: state.bookings,
		canLoadMore: state.canLoadMore,
		isLoadingMore: state.isLoadingMore,
		isLoading: state.isLoading,
		onSeeMore,
	};
}
