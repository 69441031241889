/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsHazardousFerryRuleEntity from 'Models/Security/Acl/VisitorsHazardousFerryRuleEntity';
import UserHazardousFerryRuleEntity from 'Models/Security/Acl/UserHazardousFerryRuleEntity';
import StaffHazardousFerryRuleEntity from 'Models/Security/Acl/StaffHazardousFerryRuleEntity';
import ManagerHazardousFerryRuleEntity from 'Models/Security/Acl/ManagerHazardousFerryRuleEntity';
import AdminHazardousFerryRuleEntity from 'Models/Security/Acl/AdminHazardousFerryRuleEntity';
import InvoicedUserHazardousFerryRuleEntity from 'Models/Security/Acl/InvoicedUserHazardousFerryRuleEntity';
import FixedPriceUserHazardousFerryRuleEntity from 'Models/Security/Acl/FixedPriceUserHazardousFerryRuleEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IHazardousFerryRuleEntityAttributes extends IModelAttributes {
	ruleName: string;
	maxVehiclePassengers: number;
	preventWalkOnPassengers: boolean;
	tooltipContent: string;
	showTooltip: boolean;
	iconId: string;
	icon: Blob;
	stripedTripCardColour: boolean;

	ferriess: Array<
		| Models.FerryEntity
		| Models.IFerryEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('HazardousFerryRuleEntity', 'Hazardous Ferry Rule')
// % protected region % [Customise your entity metadata here] end
export default class HazardousFerryRuleEntity extends Model
	implements IHazardousFerryRuleEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsHazardousFerryRuleEntity(),
		new UserHazardousFerryRuleEntity(),
		new StaffHazardousFerryRuleEntity(),
		new ManagerHazardousFerryRuleEntity(),
		new AdminHazardousFerryRuleEntity(),
		new InvoicedUserHazardousFerryRuleEntity(),
		new FixedPriceUserHazardousFerryRuleEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Rule Name'] on begin
	/**
	 * The name of the rule. Used to differentiate the rules.
	 */
	@Validators.Required()
	@observable
	@attribute<HazardousFerryRuleEntity, string>()
	@CRUD({
		name: 'Rule name',
		description: 'The name of the hazardous rule.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public ruleName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Rule Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Vehicle Passengers'] on begin
	/**
	 * The maximum number of passengers allowed in a vehicle booking on a hazardous ferry.
	 */
	@Validators.Integer()
	@observable
	@attribute<HazardousFerryRuleEntity, number>()
	@CRUD({
		name: 'Max vehicle passengers',
		description: 'The maximum passengers allowed in a vehicle for a hazardous ferry trip.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public maxVehiclePassengers: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Vehicle Passengers'] end

	// % protected region % [Modify props to the crud options here for attribute 'Prevent Walk On Passengers'] on begin
	/**
	 * Whether or not to prevent walk on passengers on the hazardous ferry.
	 */
	@observable
	@attribute<HazardousFerryRuleEntity, boolean>()
	@CRUD({
		name: 'Prevent walk on passengers',
		description: 'Whether or not to prevent walk on passengers on the hazardous ferry trip.',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public preventWalkOnPassengers: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Prevent Walk On Passengers'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Content'] on begin
	/**
	 * The  content that will appear when a user changes a booking to hazardous.
	 */
	@observable
	@attribute<HazardousFerryRuleEntity, string>()
	@CRUD({
		name: 'Tooltip content',
		description: 'The content that will appear for the tooltip on the vehicle tab of the booking wizard.',
		displayType: 'textarea',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tooltipContent: string;
	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Content'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Tooltip'] on begin
	/**
	 * Whether or not to show the tooltip when a user changes a booking to hazardous.
	 */
	@observable
	@attribute<HazardousFerryRuleEntity, boolean>()
	@CRUD({
		name: 'Show tooltip',
		description: 'Whether or not to show the tooltip on the vehicle tab of the booking wizard.',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showTooltip: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show Tooltip'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] on begin
	/**
	 * The icon that will display for this hazardous ferry rules.
	 */
	@observable
	@attribute<HazardousFerryRuleEntity, string>({ file: 'icon' })
	@CRUD({
		name: 'Icon',
		description: 'The icon that will show for a ferry trip that has a ferry with this rule assigned to it.',
		displayType: 'file',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'icon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="The icon that will display for this hazardous ferry rules." style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public iconId: string;

	@observable
	public icon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Striped Trip Card Colour'] on begin
	/**
	 * Whether or not the trip card colour should be striped, to show it is hazardous.
	 */
	@observable
	@attribute<HazardousFerryRuleEntity, boolean>()
	@CRUD({
		name: 'Striped trip card colour',
		description: 'Whether or not to show the trip card colour as striped (white and other colour).',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public stripedTripCardColour: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Striped Trip Card Colour'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferries'] on begin
		name: 'Ferries with this rule',
		displayType: 'reference-multicombobox',
		order: 80,
		isReadonly: true,
		referenceTypeFunc: () => Models.FerryEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ferriess',
			oppositeEntity: () => Models.FerryEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ferries'] end
	})
	public ferriess: Models.FerryEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IHazardousFerryRuleEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IHazardousFerryRuleEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.ruleName !== undefined) {
				this.ruleName = attributes.ruleName;
			}
			if (attributes.maxVehiclePassengers !== undefined) {
				this.maxVehiclePassengers = attributes.maxVehiclePassengers;
			}
			if (attributes.preventWalkOnPassengers !== undefined) {
				this.preventWalkOnPassengers = attributes.preventWalkOnPassengers;
			}
			if (attributes.tooltipContent !== undefined) {
				this.tooltipContent = attributes.tooltipContent;
			}
			if (attributes.showTooltip !== undefined) {
				this.showTooltip = attributes.showTooltip;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.iconId !== undefined) {
				this.iconId = attributes.iconId;
			}
			if (attributes.stripedTripCardColour !== undefined) {
				this.stripedTripCardColour = attributes.stripedTripCardColour;
			}
			if (attributes.ferriess !== undefined && Array.isArray(attributes.ferriess)) {
				for (const model of attributes.ferriess) {
					if (model instanceof Models.FerryEntity) {
						this.ferriess.push(model);
					} else {
						this.ferriess.push(new Models.FerryEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		ferriess {
			${Models.FerryEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			ferriess: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'ferriess',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(HazardousFerryRuleEntity.prototype, 'created');
CRUD(modifiedAttr)(HazardousFerryRuleEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
