import React, { useEffect, useState } from 'react';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { GiftCertificateEntity, UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { Button } from 'Views/Components/Button/Button';
import Pagination from 'Views/Components/Pagination/Pagination';
import If from 'Views/Components/If/If';
import { useGiftVouchersAsync } from 'Hooks/useGiftVouchersAsync';
import { FetchedGiftVouchersDto, startCreatingVoucher } from './GiftVouchersUtils';
import { GiftVouchers } from './GiftVouchersInner';
import { AccountDetailsTabHeader } from '../AccountDetailsTabHeader';
import { observable, runInAction } from 'mobx';
import { Toggle } from '../../Toggle/Toggle';
import { getUsersGiftVouchers } from '../../../../../Services/Api/_HumanWritten/GiftCertificateService';

interface AccountDetailsVouchersProps {
	user?: UserEntity;
}

function AccountDetailsVouchers({ user }: AccountDetailsVouchersProps) {
	const perPage = 4;
	const [pageNo, setPageNo] = useState<number>(0);
	const [checkboxState, setCheckboxState] = useState<boolean>(false);
	const [giftCertificates, setGiftCertificates] = useState<FetchedGiftVouchersDto | undefined>(undefined);

	const toggleState = observable({
		showExpiredOrUsedCredits: checkboxState,
	});

	useEffect(() => {
		fetchGiftCertificates();
	}, [checkboxState, pageNo]);

	const fetchGiftCertificates = async () => {
		if (isNotNullOrUndefined(user)) {
			const gcs = await getUsersGiftVouchers(user.id, pageNo * 4, 4, checkboxState);
			setGiftCertificates(gcs);
		}
	};

	// const response = useGiftVouchersAsync(user, pageNo, perPage, checkboxState);

	// if (response.type === 'error') {
	// 	alertToast('Could not fetch gift vouchers', 'error');
	// }
	//
	// const listOfGiftCertificates = response.data;

	const handleCreateVoucher = () => {
		if (isNotNullOrUndefined(user)) {
			startCreatingVoucher(user.id, fetchGiftCertificates);
		}
	};

	return (
		<>
			<div className="account-page-ferry-vouchers__header-container">
				<AccountDetailsTabHeader headerText="Ferry credits" />
				{store.isStaff && (
					<div className="credit-voucher__manager-buttons">
						<Toggle
							className="credit-voucher__toggle"
							id="credit-voucher-toggle"
							model={toggleState}
							modelProperty="showExpiredOrUsedCredits"
							labelVisible
							labelOn="Expired/Used"
							labelOff="Valid"
							onChecked={(event, checked) => {
								runInAction(() => {
									setCheckboxState(checked);
								});
							}}
						/>
						<Button
							className="add-ferry-voucher__button"
							onClick={handleCreateVoucher}
							icon={{ icon: 'add', iconPos: 'icon-left' }}
						>
							Create credit
						</Button>
					</div>
				)}
			</div>
			<GiftVouchers reload={fetchGiftCertificates} listOfGiftCertificates={giftCertificates} />
			<If condition={isNotNullOrUndefined(giftCertificates) && giftCertificates?.count > 4}>
				<div className="collection-component">
					<section className="gift-vouchers__pagination collection__pagination">
						<section className="collection__load">
							<Pagination
								perPage={perPage}
								pageNo={pageNo}
								totalRecords={giftCertificates?.count ?? 0}
								onPageChange={pageNum => {
									setPageNo(pageNum);
								}}
								showGoToPageBox={false}
								showFirstArrow={false}
								showLastArrow={false}
								leftArrowIcon="arrow-left"
								rightArrowIcon="arrow-right"
							/>
						</section>
					</section>
				</div>
			</If>
		</>
	);
}

export default AccountDetailsVouchers;
