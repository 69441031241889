import { confirmModal } from '../../Modal/ModalUtils';
import React from 'react';

export default async function handleNavigationAway(
	needConfirm: boolean,
	confirmationFunction: () => void,
): Promise<boolean> {
	if (needConfirm) {
		return confirmModal(
			'Discard account changes?',
			<h5>
				You have unsaved account details that will be lost if you leave the page. Are you sure you want to
				leave?
			</h5>,
			{
				cancelText: 'Cancel',
				confirmText: 'Discard changes',
			},
		).then(() => {
			confirmationFunction();
			return true;
		}).catch(() => {
			return false;
		});
	}

	return new Promise(resolve => {
		confirmationFunction();
		resolve(true);
	});
}
