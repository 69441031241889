const TRANSACTION_PRICE_KEY = 'transaction-price-storage-key';
export function setTransactionPriceInStorage(price: number) {
	sessionStorage.setItem(TRANSACTION_PRICE_KEY, price.toString(10));
}

export function clearStorageTransactionPrice() {
	sessionStorage.removeItem(TRANSACTION_PRICE_KEY);
}

export function getTransactionPrice(): number | null {
	const storageItem = sessionStorage.getItem(TRANSACTION_PRICE_KEY);

	if (storageItem === null) {
		return null;
	}

	const price = Number(storageItem);

	return Number.isNaN(price) ? null : price;
}
