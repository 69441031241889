/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsUserEntity from 'Models/Security/Acl/VisitorsUserEntity';
import UserUserEntity from 'Models/Security/Acl/UserUserEntity';
import StaffUserEntity from 'Models/Security/Acl/StaffUserEntity';
import ManagerUserEntity from 'Models/Security/Acl/ManagerUserEntity';
import AdminUserEntity from 'Models/Security/Acl/AdminUserEntity';
import InvoicedUserUserEntity from 'Models/Security/Acl/InvoicedUserUserEntity';
import FixedPriceUserUserEntity from 'Models/Security/Acl/FixedPriceUserUserEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { getFullName } from 'Util/StringUtils';
// % protected region % [Add any further imports here] end

export interface IUserEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	firstName: string;
	lastName: string;
	phone: string;
	postcode: string;
	address: string;
	profilePictureId: string;
	profilePicture: Blob;
	legacyId: number;
	newEmailAddress: string;
	needsPasswordReset: boolean;
	adultPrice: number;
	childPrice: number;
	infantPrice: number;
	disabled: boolean;
	priceD: number;
	priceE: number;
	priceF: number;
	priceG: number;
	priceH: number;
	stripeAccountId: string;

	addOnsPrices: Array<
		| Models.AddOnsPriceEntity
		| Models.IAddOnsPriceEntityAttributes
	>;
	bookings: Array<
		| Models.BookingEntity
		| Models.IBookingEntityAttributes
	>;
	cargos: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	communicationsRecords: Array<
		| Models.CommunicationsRecordEntity
		| Models.ICommunicationsRecordEntityAttributes
	>;
	giftCertificates: Array<
		| Models.GiftCertificateEntity
		| Models.IGiftCertificateEntityAttributes
	>;
	noteAboutUsers: Array<
		| Models.NotesEntity
		| Models.INotesEntityAttributes
	>;
	notes: Array<
		| Models.UserNote
		| Models.IUserNoteAttributes
	>;
	prices: Array<
		| Models.PriceEntity
		| Models.IPriceEntityAttributes
	>;
	userDisplayNameId?: string;
	userDisplayName?: Models.UserDisplayNameEntity | Models.IUserDisplayNameEntityAttributes;
	waitlistItems: Array<
		| Models.WaitlistItemEntity
		| Models.IWaitlistItemEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	/**
	 * This is a custom GraphQL resolver (see UserEntityType.cs).
	 *
	 * Returns the user's top three vehicles used in their latest alterations (unique by rego).
	 */
	latestVehicles: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('UserEntity', 'User')
// % protected region % [Customise your entity metadata here] end
export default class UserEntity extends Model
	implements IUserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsUserEntity(),
		new UserUserEntity(),
		new StaffUserEntity(),
		new ManagerUserEntity(),
		new AdminUserEntity(),
		new InvoicedUserUserEntity(),
		new FixedPriceUserUserEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] on begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email address',
		displayType: 'displayfield',
		order: 40,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] on begin
	@CRUD({
		name: 'Two Factor Enabled',
		displayType: 'hidden',
		order: 80,
		createFieldType: 'hidden',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] on begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 50,
		createFieldType: 'password',
		inputProps: {
			isRequired: true,
		},
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] on begin
	@Validators.Custom('Password Match', (e: string, target: UserEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm password',
		displayType: 'hidden',
		order: 60,
		createFieldType: 'password',
		inputProps: {
			isRequired: true,
		},
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'First name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] on begin
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Last name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] on begin
	@Validators.Required()
	@Validators.Phone() // Must be a valid phone number (from any country)
	@Validators.Unique()
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Mobile number',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] on begin
	/**
	 * The postcode that the user resides in
	 */
	@Validators.Integer()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Postcode',
		displayType: 'textfield',
		order: 70,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public postcode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Postcode'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address'] on begin
	/**
	 * The address of the user.
	 */
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Address',
		displayType: 'textfield',
		order: 90,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Profile Picture'] off begin
	@observable
	@attribute<UserEntity, string>({ file: 'profilePicture' })
	@CRUD({
		name: 'Profile Picture',
		displayType: 'file',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'profilePicture',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A User" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public profilePictureId: string;

	@observable
	public profilePicture: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Profile Picture'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'New Email Address'] on begin
	/**
	 * Email address used for when a user tries to change their account email address.
	 */
	@Validators.Email()
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'New Email Address',
		displayType: 'hidden',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public newEmailAddress: string;
	// % protected region % [Modify props to the crud options here for attribute 'New Email Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Needs Password Reset'] on begin
	/**
	 * Initially true for migrated users
	 */
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Needs Password Reset',
		displayType: 'hidden',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public needsPasswordReset: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Needs Password Reset'] end

	// % protected region % [Modify props to the crud options here for attribute 'Adult Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Adult Price',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public adultPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Adult Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Child Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Child Price',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public childPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Child Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Infant Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Infant Price',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public infantPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Infant Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 170,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price D'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Price D',
		displayType: 'textfield',
		order: 180,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceD: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price D'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price E'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Price E',
		displayType: 'textfield',
		order: 190,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceE: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price E'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price F'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Price F',
		displayType: 'textfield',
		order: 200,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceF: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price F'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price G'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Price G',
		displayType: 'textfield',
		order: 210,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceG: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price G'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price H'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Price H',
		displayType: 'textfield',
		order: 220,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceH: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price H'] end

	// % protected region % [Modify props to the crud options here for attribute 'Stripe Account Id'] off begin
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Stripe Account Id',
		displayType: 'textfield',
		order: 230,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public stripeAccountId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Stripe Account Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] on begin
		name: 'Add Ons Prices',
		displayType: 'hidden',
		order: 230,
		referenceTypeFunc: () => Models.AddOnsPriceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'addOnsPrices',
			oppositeEntity: () => Models.AddOnsPriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] end
	})
	public addOnsPrices: Models.AddOnsPriceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Booking'] on begin
		name: 'Bookings',
		displayType: 'hidden',
		order: 230,
		referenceTypeFunc: () => Models.BookingEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'bookings',
			oppositeEntity: () => Models.BookingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Booking'] end
	})
	public bookings: Models.BookingEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] on begin
		name: 'Cargos',
		displayType: 'hidden',
		order: 250,
		referenceTypeFunc: () => Models.CargoEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargos',
			oppositeEntity: () => Models.CargoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo'] end
	})
	public cargos: Models.CargoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Communications Record'] on begin
		name: 'Communications Records',
		displayType: 'hidden',
		order: 250,
		referenceTypeFunc: () => Models.CommunicationsRecordEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'communicationsRecords',
			oppositeEntity: () => Models.CommunicationsRecordEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Communications Record'] end
	})
	public communicationsRecords: Models.CommunicationsRecordEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] on begin
		name: 'Gift Certificates',
		displayType: 'hidden',
		order: 120,
		referenceTypeFunc: () => Models.GiftCertificateEntity,
		createFieldType: 'hidden',
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'giftCertificates',
			oppositeEntity: () => Models.GiftCertificateEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] end
	})
	public giftCertificates: Models.GiftCertificateEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Note About User'] off begin
		name: 'Note About Users',
		displayType: 'reference-multicombobox',
		order: 290,
		referenceTypeFunc: () => Models.NotesEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'noteAboutUsers',
			oppositeEntity: () => Models.NotesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Note About User'] end
	})
	public noteAboutUsers: Models.NotesEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Note'] on begin
		name: 'Note',
		displayType: 'hidden',
		order: 140,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.UserNote,
		optionEqualFunc: makeJoinEqualsFunc('noteId'),
		createFieldType: 'hidden',
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'userEntity',
			oppositeEntityName: 'notesEntity',
			relationName: 'user',
			relationOppositeName: 'note',
			entity: () => Models.UserEntity,
			joinEntity: () => Models.UserNote,
			oppositeEntity: () => Models.NotesEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Note'] end
	})
	public notes: Models.UserNote[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price'] on begin
		name: 'Prices',
		displayType: 'hidden',
		order: 270,
		referenceTypeFunc: () => Models.PriceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'prices',
			oppositeEntity: () => Models.PriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Price'] end
	})
	public prices: Models.PriceEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User Display Name'] on begin
		name: 'User Display Name',
		displayType: 'reference-combobox',
		headerColumn: true,
		order: 280,
		referenceTypeFunc: () => Models.UserDisplayNameEntity,
		// % protected region % [Modify props to the crud options here for reference 'User Display Name'] end
	})
	public userDisplayNameId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public userDisplayName: Models.UserDisplayNameEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] on begin
		name: 'Waitlist Items',
		displayType: 'hidden',
		order: 300,
		referenceTypeFunc: () => Models.WaitlistItemEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'waitlistItems',
			oppositeEntity: () => Models.WaitlistItemEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Waitlist Item'] end
	})
	public waitlistItems: Models.WaitlistItemEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	// See interface for latestVehicles definition
	@observable
	public latestVehicles: Models.CargoEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IUserEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUserEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.postcode !== undefined) {
				this.postcode = attributes.postcode;
			}
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.profilePicture !== undefined) {
				this.profilePicture = attributes.profilePicture;
			}
			if (attributes.profilePictureId !== undefined) {
				this.profilePictureId = attributes.profilePictureId;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.newEmailAddress !== undefined) {
				this.newEmailAddress = attributes.newEmailAddress;
			}
			if (attributes.needsPasswordReset !== undefined) {
				this.needsPasswordReset = attributes.needsPasswordReset;
			}
			if (attributes.adultPrice !== undefined) {
				this.adultPrice = attributes.adultPrice;
			}
			if (attributes.childPrice !== undefined) {
				this.childPrice = attributes.childPrice;
			}
			if (attributes.infantPrice !== undefined) {
				this.infantPrice = attributes.infantPrice;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.priceD !== undefined) {
				this.priceD = attributes.priceD;
			}
			if (attributes.priceE !== undefined) {
				this.priceE = attributes.priceE;
			}
			if (attributes.priceF !== undefined) {
				this.priceF = attributes.priceF;
			}
			if (attributes.priceG !== undefined) {
				this.priceG = attributes.priceG;
			}
			if (attributes.priceH !== undefined) {
				this.priceH = attributes.priceH;
			}
			if (attributes.stripeAccountId !== undefined) {
				this.stripeAccountId = attributes.stripeAccountId;
			}
			if (attributes.addOnsPrices !== undefined && Array.isArray(attributes.addOnsPrices)) {
				for (const model of attributes.addOnsPrices) {
					if (model instanceof Models.AddOnsPriceEntity) {
						this.addOnsPrices.push(model);
					} else {
						this.addOnsPrices.push(new Models.AddOnsPriceEntity(model));
					}
				}
			}
			if (attributes.bookings !== undefined && Array.isArray(attributes.bookings)) {
				for (const model of attributes.bookings) {
					if (model instanceof Models.BookingEntity) {
						this.bookings.push(model);
					} else {
						this.bookings.push(new Models.BookingEntity(model));
					}
				}
			}
			if (attributes.cargos !== undefined && Array.isArray(attributes.cargos)) {
				for (const model of attributes.cargos) {
					if (model instanceof Models.CargoEntity) {
						this.cargos.push(model);
					} else {
						this.cargos.push(new Models.CargoEntity(model));
					}
				}
			}
			if (attributes.communicationsRecords !== undefined && Array.isArray(attributes.communicationsRecords)) {
				for (const model of attributes.communicationsRecords) {
					if (model instanceof Models.CommunicationsRecordEntity) {
						this.communicationsRecords.push(model);
					} else {
						this.communicationsRecords.push(new Models.CommunicationsRecordEntity(model));
					}
				}
			}
			if (attributes.giftCertificates !== undefined && Array.isArray(attributes.giftCertificates)) {
				for (const model of attributes.giftCertificates) {
					if (model instanceof Models.GiftCertificateEntity) {
						this.giftCertificates.push(model);
					} else {
						this.giftCertificates.push(new Models.GiftCertificateEntity(model));
					}
				}
			}
			if (attributes.noteAboutUsers !== undefined && Array.isArray(attributes.noteAboutUsers)) {
				for (const model of attributes.noteAboutUsers) {
					if (model instanceof Models.NotesEntity) {
						this.noteAboutUsers.push(model);
					} else {
						this.noteAboutUsers.push(new Models.NotesEntity(model));
					}
				}
			}
			if (attributes.notes !== undefined && Array.isArray(attributes.notes)) {
				for (const model of attributes.notes) {
					if (model instanceof Models.UserNote) {
						this.notes.push(model);
					} else {
						this.notes.push(new Models.UserNote(model));
					}
				}
			}
			if (attributes.prices !== undefined && Array.isArray(attributes.prices)) {
				for (const model of attributes.prices) {
					if (model instanceof Models.PriceEntity) {
						this.prices.push(model);
					} else {
						this.prices.push(new Models.PriceEntity(model));
					}
				}
			}
			if (attributes.userDisplayNameId !== undefined) {
				this.userDisplayNameId = attributes.userDisplayNameId;
			}
			if (attributes.userDisplayName !== undefined) {
				if (attributes.userDisplayName === null) {
					this.userDisplayName = attributes.userDisplayName;
				} else if (attributes.userDisplayName instanceof Models.UserDisplayNameEntity) {
					this.userDisplayName = attributes.userDisplayName;
					this.userDisplayNameId = attributes.userDisplayName.id;
				} else {
					this.userDisplayName = new Models.UserDisplayNameEntity(attributes.userDisplayName);
					this.userDisplayNameId = this.userDisplayName.id;
				}
			}
			if (attributes.waitlistItems !== undefined && Array.isArray(attributes.waitlistItems)) {
				for (const model of attributes.waitlistItems) {
					if (model instanceof Models.WaitlistItemEntity) {
						this.waitlistItems.push(model);
					} else {
						this.waitlistItems.push(new Models.WaitlistItemEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.latestVehicles !== undefined && Array.isArray(attributes.latestVehicles)) {
				for (const model of attributes.latestVehicles) {
					if (model instanceof Models.CargoEntity) {
						this.latestVehicles.push(model);
					} else {
						this.latestVehicles.push(new Models.CargoEntity(model));
					}
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		notes {
			${Models.UserNote.getAttributes().join('\n')}
			note {
				${Models.NotesEntity.getAttributes().join('\n')}
			}
		}
		addOnsPrices {
			${Models.AddOnsPriceEntity.getAttributes().join('\n')}
		}
		bookings {
			${Models.BookingEntity.getAttributes().join('\n')}
		}
		giftCertificates {
			${Models.GiftCertificateEntity.getAttributes().join('\n')}
		}
		prices {
			${Models.PriceEntity.getAttributes().join('\n')}
		}
		cargos {
			${Models.CargoEntity.getAttributes().join('\n')}
		}
		waitlistItems {
			${Models.WaitlistItemEntity.getAttributes().join('\n')}
		}
		userDisplayName {
			${Models.UserDisplayNameEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			notes: {},
			addOnsPrices: {},
			bookings: {},
			communicationsRecords: {},
			giftCertificates: {},
			prices: {},
			vehicles: {},
			waitlistItems: {},
		};

		if (formMode === 'create') {
			relationPath.password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'addOnsPrices',
							'communicationsRecords',
							'giftCertificates',
							'prices',
							'waitlistItems',
							'notes',
							'userDisplayName',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getFullName(showLastNameFirst = false) {
		return getFullName(this.firstName, this.lastName, showLastNameFirst);
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UserEntity.prototype, 'created');
CRUD(modifiedAttr)(UserEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
