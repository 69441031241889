import React, { useEffect, useState } from 'react';
import {
	PaymentProviderTransactionDetails,
} from 'Services/Api/_HumanWritten/PaymentService';
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckOutForm } from './CheckOutForm';
import { Appearance } from '@stripe/stripe-js/dist/stripe-js/elements-group';
import { themeStore } from '../../../../Models/ThemeStore';
import paymentStore from '../../../../Models/PaymentStore';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import alertToast from '../../../../Util/ToastifyUtils';
import { usePaymentAmount } from './usePaymentAmount';

export interface CheckOutFormWrapperProps {
	transactionId: string;
	paymentDetails: PaymentProviderTransactionDetails | null;
	onPageLoaded: () => void;
	userId?: string;
	isAlteration: boolean;
	eventBooking: boolean;
	setTabIsLoading: (loading: boolean) => void;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
	disableContinueButton?: boolean;
	tabIsLoading?: boolean;
	redirectUrl: string;
	includeSubmitButtons?: boolean;
}

// Load Stripe with the dynamically generated publishable key
let stripePromise: Promise<Stripe | null> | null = null;

const initializeStripe = async () => {
	if (!stripePromise) {
		try {
			const { publishableKey, accountId } = paymentStore;
			stripePromise = loadStripe(
				publishableKey,
				{
					stripeAccount: accountId,
				},
			);
		} catch (error) {
			console.error('Error fetching Stripe publishable key:', error);
			stripePromise = Promise.resolve(null);
		}
	}
	return stripePromise;
};

export function CheckOutFormWrapper({
	transactionId,
	paymentDetails,
	onPageLoaded,
	userId,
	isAlteration,
	eventBooking,
	setTabIsLoading,
	setDisableContinueButton,
	disableContinueButton,
	tabIsLoading,
	redirectUrl,
	includeSubmitButtons = false,
}: CheckOutFormWrapperProps) {
	const [totalPrice, setTotalPrice] = useState<number | null>(null);
	const [triggerRecalculation, setTriggerRecalculation] = useState(false);
	const [showSurchargeModal, setShowSurchargeModal] = useState<boolean>(false);
	const [message, setMessage] = useState<string | null>(null);

	const response = usePaymentAmount(eventBooking, transactionId, undefined, triggerRecalculation);

	useEffect(() => {
		const initStripe = async () => {
			await initializeStripe();
		};
		initStripe();
	}, []);

	useEffect(() => {
		if (response.type === 'data') {
			setTotalPrice((response?.data?.totalPrice ?? 0) + (response.data?.creditCardSurcharge ?? 0));
		}
	}, [response]);

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response.type === 'error') {
		alertToast('Could not fetch total amount', 'error');
		return <></>;
	}

	if (totalPrice === null || totalPrice === 0) {
		return <></>;
	}

	const appearance = {
		theme: 'flat',
		variables: {
			colorPrimary: themeStore.config.brandColourPrimary,
		},
	} as Appearance;

	const options = {
		mode: 'payment',
		amount: Math.trunc(totalPrice * 100),
		currency: paymentStore.currency,
		setup_future_usage: 'off_session',
		appearance,
	} as StripeElementsOptions;

	return (
		<div className="checkout-form-wrapper">
			<Elements key={`elements-${totalPrice}`} options={options} stripe={stripePromise}>
				<CheckOutForm
					transactionId={transactionId}
					paymentIntentId={null}
					isAlteration={isAlteration}
					isEventBooking={eventBooking}
					userId={userId}
					setTabIsLoading={setTabIsLoading}
					setDisableContinueButton={setDisableContinueButton}
					disableContinueButton={disableContinueButton}
					tabIsLoading={tabIsLoading}
					redirectUrl={redirectUrl}
					includeSubmitButtons={includeSubmitButtons}
					triggerRecalculation={triggerRecalculation}
					setTriggerRecalculation={setTriggerRecalculation}
					setShowSurchargeModal={setShowSurchargeModal}
					showSurchargeModal={showSurchargeModal}
					message={message}
					setMessage={setMessage}
				/>
			</Elements>
		</div>
	);
}
