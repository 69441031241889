import * as React from 'react';
import { observer } from 'mobx-react';
import { CustomTicketTypeEntity } from 'Models/Entities';
import CustomTicketPricingTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/CustomTicketPricingTable';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';
import If from 'Views/Components/If/If';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	EventSideBarProps,
	EventSideBarPropsErrors,
} from 'Views/Components/_HumanWritten/Events/EventSideBar/EventSideBar';

export interface IPriceTabProps {
	state: EventSideBarProps;
	isDisabled: boolean;
	errors: EventSideBarPropsErrors;
}

function EventPriceTabComponent({
	state,
	isDisabled,
	errors,
}: IPriceTabProps) {
	return (
		<div className="custom-sidebar__tab-content pricing-tab">
			<h6>
				Pricing
				<If condition={isNotNullOrUndefined(errors?.customTicketTypes)}>
					<span className="input-group__error-text">* {errors?.customTicketTypes}</span>
				</If>
			</h6>
			<CustomTicketPricingTable
				state={state}
				model={state.customTicketTypes}
				isReadOnly={isDisabled}
				onDelete={(ticketToRemove: CustomTicketTypeEntity) => {
					runInAction(() => {
						// eslint-disable-next-line no-param-reassign
						state.customTicketTypes = state.customTicketTypes.filter(x => x !== ticketToRemove);
					});
				}}
				errors={errors}
			/>
			<If condition={!isDisabled}>
				<Button
					display={Display.Outline}
					colors={Colors.Black}
					className="add-another-type__button"
					onClick={() => {
						runInAction(() => {
							state.customTicketTypes.push(new CustomTicketTypeEntity({
								singularName: '',
								pluralName: '',
								price: 0,
							}));
						});
					}}
				>
					Add another type
				</Button>
			</If>
		</div>
	);
}

export default observer(EventPriceTabComponent);
