import { BookingOrderByOption, getBookingExpandString } from './FetchBookingsUtils';
import { bookingTransactionStatus, bookingType } from '../../../Models/Enums';
import { BookingEntity, RouteEntity } from '../../../Models/Entities';
import { IOrderByCondition, IWhereCondition } from '../../../Views/Components/ModelCollection/ModelQuery';
import { PATH_TO_DEPARTURE_DATETIME_OF_BOOKING } from '../../../Services/CustomGql/BookingEntityType';
import moment from 'moment';

export interface BookingListSummaryDto {
	id: string;
	humanReadableId: string;
	status: bookingTransactionStatus
	route: RouteEntity;
	tripDateTime: Date;
	totalCost: number;
	createdDate: Date;
	name: string;
	email: string;
	bookingType: bookingType;
	paidPrice: number
	eventName: string;
	iconId: string;
	cargoDriverDetailsString: string;
	cargoRegoAndTrailerDetailsString: string;
	passengerDetailsString: string;
}

export async function FetchBookingsWithFilters(options: {
	excludeReturnBookings: boolean,
	excludePastBookings: boolean,
	startDate?: Date,
	endDate?: Date,
	statusFilters: bookingTransactionStatus[],
	skip?: number,
	take?: number,
	orderBy?: BookingOrderByOption,
	orderByDescending?: boolean,
	userId?: string,
	expandString?: string,
}): Promise<{ data: BookingEntity[], count: number }> {
	const {
		excludeReturnBookings,
		excludePastBookings,
		startDate,
		endDate,
		statusFilters,
		skip,
		take,
		orderBy,
		orderByDescending,
		userId,
		expandString,
	} = options;

	const whereArgs: IWhereCondition<BookingEntity>[][] = [
		[
			//
			// We do not include bookings that are expired or removed because the booking summary is null
			// And it is not required to be displayed
			//
			{
				path: 'bookingStatus',
				comparison: 'notIn',
				value: ['EXPIRED_RESERVATION'],
			},
		],
		[
			{
				path: 'transactionStatus',
				comparison: 'in',
				value: statusFilters,
			},
		],
	];

	if (!!startDate) {
		whereArgs.push([
			{
				path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
				comparison: 'greaterThanOrEqual',
				value: moment(startDate.setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm:ss'),
			},
		]);
	}
	if (!!endDate) {
		whereArgs.push([
			{
				path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
				comparison: 'lessThanOrEqual',
				value: moment(endDate.setHours(23, 59, 59, 999)).format('YYYY-MM-DDTHH:mm:ss'),
			},
		]);
	}

	if (!startDate && !endDate) {
		whereArgs.push([
			{
				path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
				comparison: excludePastBookings ? 'greaterThan' : 'lessThan',
				value: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
			},
		]);
	}

	if (!!userId) {
		whereArgs.push([
			{
				path: 'userId',
				comparison: 'equal',
				value: userId,
			},
		]);
	}

	const orderByArgs: IOrderByCondition<BookingEntity>[] = [];
	let orderByPath: string | null = null;

	switch (orderBy) {
		case BookingOrderByOption.EMAIL:
			orderByPath = 'user.email';
			break;
		case BookingOrderByOption.BOOKING_ID:
			orderByPath = 'humanReadableId';
			break;
		case BookingOrderByOption.TRIP_DATE:
			orderByPath = PATH_TO_DEPARTURE_DATETIME_OF_BOOKING;
			break;
		case BookingOrderByOption.CREATED_DATE:
			orderByPath = 'created';
			break;
	}

	if (orderByPath !== null) {
		orderByArgs.push({ path: orderByPath, descending: orderByDescending });
		if (orderBy !== BookingOrderByOption.TRIP_DATE) {
			orderByArgs.push({ path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING, descending: false });
		}
		orderByArgs.push({ path: 'id', descending: false });
	}

	try {
		const results = await BookingEntity.fetchAndCount<BookingEntity>(
			{
				args: whereArgs as IWhereCondition<BookingEntity>[][],
				orderBy: orderByArgs,
				skip: skip,
				take: take,
			},
			expandString ?? getBookingExpandString('staff-bookings', excludeReturnBookings, null, false),
			'list',
		);
		return results;
	} catch (e) {
		console.error(e);
		return { data: [], count: 0 };
	}
}
