import { useEffect, useState } from 'react';
import {
	FetchBookingPricing,
	FetchTransactionPricing,
} from '../Services/Api/_HumanWritten/PricingService/PricingService';
import { BookingWizardData } from '../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	dataToFerryTripBookingDto,
	FerryBookingCreationDto,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	TransactionPricingResult,
} from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from '../Util/TypeGuards';
import { getFerryBookingTransactionIdFromStorage } from '../Services/Api/_HumanWritten/BookingService/BookingService';

function useFetchBookingPriceBreakdown(
	location: tripSummaryLocationType,
	dependencies: any[],
	transactionId?: string,
	wizardData?: BookingWizardData,
	includeCreditCardFee: boolean = true,
) {
	const [data, setData] = useState<TransactionPricingResult | null>(null);

	const useBookingForPriceCalculation = location === 'booking-success' || location === 'check-in' || location === 'cart';
	const convertedWizardData: FerryBookingCreationDto | null = isNotNullOrUndefined(wizardData)
		? dataToFerryTripBookingDto(wizardData, false, useBookingForPriceCalculation)
		: null;
	const fetchData = async () => {
		if (location === 'cart' || location === 'check-in' || location === 'booking-success') {
			const transactionIdToUse = transactionId ?? getFerryBookingTransactionIdFromStorage();
			if (stringIsEmpty(transactionIdToUse)) {
				return;
			}
			const includeCCFee = (location === 'booking-success' || location === 'check-in') && includeCreditCardFee;
			const response = await FetchTransactionPricing(
				transactionIdToUse,
				includeCCFee,
				location === 'check-in');
			setData(response);
		} else {
			const response = await FetchBookingPricing(
				convertedWizardData,
				wizardData?.wizardMode === 'ALTERATION');
			setData(response);
		}
	};

	useEffect(() => {
		fetchData();
	}, [...dependencies]);

	return data;
}

export default useFetchBookingPriceBreakdown;
