import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FerryTripEntity } from 'Models/Entities';
import FerryTripSideBar from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripSideBar';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { store } from 'Models/Store';

export interface FerryTripSideBarWrapperProps {
	trip: FerryTripEntity;
	/**
	 * Refetch data for the schedule page.
	 */
	refetch: () => void;
}

function FerryTripSideBarWrapper({ trip, refetch }: FerryTripSideBarWrapperProps) {
	//
	// Important to create a new instance of ferry trip while using the ferry trip sidebar as it involves changing its
	// properties. If not, then any chances made will is update the stored data in the ferry schedule component.
	//
	// Example - changing date time will visually make the trip change time in the schedule page
	//
	const copiedFerryTrip = new FerryTripEntity(trip);

	const state = useLocalStore(() => ({
		ferryTrip: copiedFerryTrip,
	}));

	return (
		<FerryTripSideBar
			ferryTrip={state.ferryTrip}
			originalFerryTrip={trip}
			hidePanel={(confirm: boolean, needRefetch: boolean) => {
				if (!confirm) {
					if (needRefetch) {
						refetch();
					}
					store.routerHistory.push('/ferry-schedule');
					return;
				}

				confirmModal(
					'Discard trip changes?',
					// eslint-disable-next-line max-len
					'You have unsaved trip details that will be lost if you leave the page. Are you sure you want to leave?',
					{
						confirmText: 'Discard changes',
					},
				)
					.then(() => {
						store.routerHistory.push('/ferry-schedule');
					})
					.catch(() => {});
			}}
		/>
	);
}

export default observer(FerryTripSideBarWrapper);
