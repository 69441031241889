import React from 'react';
import { store } from 'Models/Store';
import { getEventBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { EventBookingWizardData } from './EventsBookingWizardData';

const validPaths: string[] = [];

const pathsBeforePayment: string[] = [];

export interface useEventBookingWizardWatchProps {
	bookingWizardData: EventBookingWizardData;
	paymentDetailsEntered: boolean;
}

export function useEventBookingWizardWatch({ bookingWizardData, paymentDetailsEntered }: useEventBookingWizardWatchProps) {
	const goToAwaitingPayment = (params: {
		previousLocationPath: string;
		nextLocationPath: string;
	}) => {
		const { previousLocationPath, nextLocationPath } = params;

		if (!(
			paymentDetailsEntered
			&& previousLocationPath.includes('/event-booking-wizard/payment')
			&& pathsBeforePayment.find(x => nextLocationPath.includes(x))
		)) {
			return false;
		}

		const transactionId = getEventBookingTransactionIdFromStorage();
		if (isNullOrUndefined(transactionId)) {
			return false;
		}

		return true;
	};

	const handleTabClose = (event: BeforeUnloadEvent) => {
		event.preventDefault();
		event.returnValue = '';
		return ('Are you sure you want to leave?');
	};

	const handleRouterHistory = () => {
		if (window.location.href.includes('/event-booking-wizard/post-payment')) {
			return;
		}

		const previousLocationPath = store.routerHistory.location.pathname;
		const unblock = store.routerHistory.block(nextLocation => {
			const { pathname } = nextLocation;

			// Typically triggered when user clicks 'Pay now' but accidentally refresh
			if (goToAwaitingPayment({ previousLocationPath, nextLocationPath: pathname })) {
				unblock();
				store.routerHistory.replace('/awaiting-payment');
				return false;
			}

			// Allow users to navigate to a valid pathname
			// OR
			// If they are in alteration mode, allow them to navigate to a bookings/ID
			if (
				validPaths.find(x => pathname.includes(x))
				|| (
					pathname.includes('/bookings/')
				)
			) {
				//
				// We want to allow the user to navigate to the new pathname. Calling unblock will
				// unsubscribe listening to routerHistory.
				//
				unblock();
				//
				// Important to use history.replace so that when the user clicks back on the browser, they will
				// be navigated to previous visible tab.
				//
				if (previousLocationPath.includes('/event-booking-wizard/reservation')) {
					store.routerHistory.replace(nextLocation);
					return;
				}
				store.routerHistory.push(nextLocation);
			} else {
				// handleNavigation(bookingWizardData.bookingToEdit).then(result => {
				// 	if (result) {
				// 		unblock();
				// 		store.routerHistory.push(nextLocation);
				// 	}
				// });
			}

			return false;
		});

		store.routerHistory.listen(unblock);
	};

	React.useEffect(() => {
		if (!(paymentDetailsEntered && window.location.href.includes('/event-booking-wizard/payment'))) {
			// Only pass this guard if
			// - wizard is in alteration mode
			// - or doing payment
			return undefined;
		}

		if (!window.location.href.includes('/event-booking-wizard/payment')) {
			// We don't have a beforeunload during payment tab because submitting the payment form will have to leave
			// the page, which we don't want to prevent
			window.addEventListener('beforeunload', handleTabClose);
		}

		handleRouterHistory();

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, [store.routerHistory.location, paymentDetailsEntered]);
}
