import * as React from 'react';
import { store } from 'Models/Store';
import { upperCaseFirst } from 'Util/StringUtils';
import alertToast from 'Util/ToastifyUtils';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import { ReportTableItemProps } from './ReportTableLineItem';
import ReportsTable from './ReportsTable';
import { useReportableAddOns } from '../../../../Services/Api/_HumanWritten/ReportService';
import { REPORTS_PAGE_TITLE } from '../../../../ConstantPageNames';

export type ReportType = 'occupancy'
	| 'invoice'
	| 'revenue'
	| 'takings-breakdown'
	| 'gift-certificates'
	| 'additional-option'
	| 'customer'
	| 'operation'
	| 'cash'
	| 'eftpos';

const GiftCertReportItem: ReportTableItemProps = {
	id: 'giftCert',
	reportTitle: 'Gift certificates report',
	selectedReportType: 'gift-certificates',
};

const RevenueReportItem: ReportTableItemProps = {
	id: 'revenue',
	reportTitle: 'Revenue report',
	selectedReportType: 'revenue',
};

const TakingsBreakdownReportItem: ReportTableItemProps = {
	id: 'takings-breakdown',
	reportTitle: 'Takings breakdown report',
	selectedReportType: 'takings-breakdown',
};

const InvoiceReportItem: ReportTableItemProps = {
	id: 'invoice',
	reportTitle: 'Invoice report',
	selectedReportType: 'invoice',
};

const CustomerReportItem: ReportTableItemProps = {
	id: 'customer-report',
	reportTitle: 'Customer report',
	selectedReportType: 'customer',
};

const OccupancyReportItem: ReportTableItemProps = {
	id: 'occupancy',
	reportTitle: 'Occupancy report',
	selectedReportType: 'occupancy',
};

const OperationReportItem: ReportTableItemProps = {
	id: 'operation',
	reportTitle: 'Operation report',
	selectedReportType: 'operation',
};

const CashReportItem: ReportTableItemProps = {
	id: 'cash-report',
	reportTitle: 'Cash report',
	selectedReportType: 'cash',
};

const EftposReportItem: ReportTableItemProps = {
	id: 'eftpos-report',
	reportTitle: 'Eftpos report',
	selectedReportType: 'eftpos',
};

function Reports() {
	const response = useReportableAddOns();

	if (response.type === 'error') {
		alertToast('Could not fetch additional option reports', 'error');
	}

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	const reports: ReportTableItemProps[] = [];

	if (store.isManager) {
		reports.push(RevenueReportItem);
		reports.push(TakingsBreakdownReportItem);
		reports.push(CashReportItem);
		reports.push(EftposReportItem);
	}

	reports.push(InvoiceReportItem);
	reports.push(CustomerReportItem);
	reports.push(GiftCertReportItem);
	reports.push(OccupancyReportItem);
	reports.push(OperationReportItem);

	if (response.type === 'data') {
		if (response.data.length > 0) {
			response.data.forEach(x => {
				reports.push({
					reportTitle: upperCaseFirst(x.name),
					selectedReportType: 'additional-option',
					id: x.id,
				});
			});
		}
	}

	return (
		<div className="reports__container">
			<h2 className="reports__page-header">
				Reports
			</h2>
			<ReportsTable listOfReports={reports} />
		</div>
	);
}

export default Reports;
