export type UserGroup =
	'Super Administrators'
	| 'Admin'
	| 'Manager'
	| 'Staff'
	| 'InvoicedUser'
	| 'FixedPriceUser'
	| 'User'
	| 'Visitor';

export class SecuredGroups {
	private _groups: Set<UserGroup> = new Set();

	public static get create(): SecuredGroups {
		return new SecuredGroups();
	}

	all() {
		this._groups = new Set([
			'Super Administrators',
			'Admin',
			'Manager',
			'Staff',
			'InvoicedUser',
			'FixedPriceUser',
			'User',
			'Visitor',
		]);
		return this;
	}

	onlySuper() {
		this._groups = new Set(['Super Administrators']);
		return this;
	}

	onlyAdmin() {
		this._groups = new Set(['Super Administrators', 'Admin']);
		return this;
	}

	onlyManager() {
		this._groups = new Set(['Super Administrators', 'Admin', 'Manager']);
		return this;
	}

	onlyStaff() {
		this._groups = new Set(['Super Administrators', 'Admin', 'Manager', 'Staff']);
		return this;
	}

	onlyLoggedIn() {
		this._groups = new Set([
			'Super Administrators',
			'Admin',
			'Manager',
			'Staff',
			'InvoicedUser',
			'FixedPriceUser',
			'User',
		]);
		return this;
	}

	add(group: UserGroup) {
		this._groups.add(group);
		return this;
	}

	remove(group: UserGroup) {
		this._groups.delete(group);
		return this;
	}

	get groups(): UserGroup[] {
		return Array.from(this._groups);
	}
}
