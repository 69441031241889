import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { BOOKING_SUCCESS_PAGE_TITLE } from '../../ConstantPageNames';
import {
	EventBookingSuccessOuterWrapper,
} from '../Components/_HumanWritten/EventBookingSuccessPage/EventBookingSuccessOuterWrapper';

export default function EventBookingSuccessPage(props: RouteComponentProps) {
	document.title = BOOKING_SUCCESS_PAGE_TITLE;

	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<EventBookingSuccessOuterWrapper routerProps={props} />
		</SecuredPage>
	);
}
