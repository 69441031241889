import React from 'react';
import { StaffBookingsFilters } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsComponent';
import { DisplayType } from 'Views/Components/Models/Enums';
import moment from 'moment';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { DateRangePicker } from 'Views/Components/DateRangePicker/DateRangePicker';
import { store } from 'Models/Store';

export interface StaffBookingsDatePickerProps {
	filters: StaffBookingsFilters;
	setFilters: (newFilters: StaffBookingsFilters) => void;
}

function getDateRangeRender(startDate: Date, endDate: Date) {
	return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
}

export function StaffBookingsDatePicker({
	filters,
	setFilters,
}: StaffBookingsDatePickerProps) {
	const model = {
		startDate: filters.startDate ?? new Date(),
		endDate: filters.endDate ?? new Date(),
	};

	const datesSelected = !!filters.startDate && !!filters.endDate;

	return (
		<DateRangePicker
			id="staff-bookings-date-picker"
			model={model}
			modelProperty="range"
			className="staff-bookings__date-range-picker"
			displayType={DisplayType.BLOCK}
			minDate="today"
			flatpickrOptions={{
				minDate: store.isStaff ? '' : 'today',
				monthSelectorType: 'static',
				allowInput: false,
				locale: {
					firstDayOfWeek: 1,
					weekdays: {
						shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
						longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
					},
				},
			}}
			flatpickrProps={{
				value: datesSelected ? [model.startDate, model.endDate] : undefined,
				onChange: dates => {
					const [startDate, endDate] = dates;
					if (!!startDate && !!endDate) {
						const newFilters = { ...filters };
						newFilters.pageNo = 0;
						newFilters.startDate = startDate;
						newFilters.endDate = endDate;

						if (startDate < new Date()) {
							newFilters.showPastBookings = true;
						}

						setFilters(newFilters);
					}
				},
				render: (props, ref) => {
					return (
						<div className="staff-bookings__date-picker-text-wrap">
							<span className="icon-calendar icon-left" />
							<div className="staff-bookings__date-picker-text" ref={ref}>
								<p>
									{datesSelected ? getDateRangeRender(model.startDate, model.endDate) : 'All dates'}
								</p>
							</div>
							<If condition={datesSelected}>
								<Button
									className="staff-bookings__clear-dates"
									icon={{ icon: 'cross', iconPos: 'icon-right' }}
									onClick={() => {
										const newFilters = { ...filters };
										newFilters.pageNo = 0;
										newFilters.startDate = undefined;
										newFilters.endDate = undefined;

										setFilters(newFilters);
									}}
								/>
							</If>
						</div>
					);
				},
			}}
		/>
	);
}
