import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import { NotesEntity } from '../../../Models/Entities';
import { locationType, noteType } from '../../../Models/Enums';

export interface noteInfoDto {
	noteId: string,
	userName: string,
	creationDateTime: Date,
	content: string,
	noteType: noteType,
	locationType: locationType,
}
export interface customBookingNoteDto {
	bookingId: string,
	noteContent: string,
}

export interface customUserNoteDto {
	userId: string,
	noteContent: string,
}

export interface AddNoteToFerryTrip {
	ferryTripId: string,
	noteContent: string,
	showToCustomers: boolean,
}

export function getAllNotesForBooking(bookingId: string) {
	const notes = axios.get(
		`${SERVER_URL}/api/entity/NotesEntity/get-all-notes-for-booking?bookingId=${bookingId}`,
	).then(x => x.data);
	return notes;
}

export function getAllNotesForUser(userId: string) {
	const notes = axios.get(
		`${SERVER_URL}/api/entity/NotesEntity/get-all-notes-for-user?userId=${userId}`,
	).then(x => x.data);
	return notes;
}

export function addCustomStaffBookingNote(customNoteInfo: customBookingNoteDto) {
	const notes = axios.post(
		`${SERVER_URL}/api/entity/NotesEntity/add-custom-staff-booking-note`,
		customNoteInfo,
	).then(x => x.data);
	return notes;
}

export function addCustomStaffUserNote(customNoteInfo: customUserNoteDto) {
	const notes = axios.post(
		`${SERVER_URL}/api/entity/NotesEntity/add-custom-staff-user-note`,
		customNoteInfo,
	).then(x => x.data);
	return notes;
}

/**
 * Gets all the notes for a ferry trip
 * @param ferryTripId - the ferry trip id of notes to get.
 */
export async function getAllFerryTripNotes(ferryTripId: string) {
	return axios.get(
		`${SERVER_URL}/api/entity/NotesEntity/get-all-ferry-trip-notes?ferryTripId=${ferryTripId}`,
	);
}

/**
 * Removes a note from a ferry trip
 * @param noteId - the id of the note to remove from the ferry trip.
 */
export async function removeNoteFromFerryTrip(noteId: string) {
	return axios.post(
		`${SERVER_URL}/api/entity/NotesEntity/remove-from-ferry-trip?noteId=${noteId}`,
	);
}

/**
 * Adds a note to a ferry trip
 * @param addNoteToFerryTripDto - contains ferryTripId, noteContent and showToCustomers.
 */
export async function addNoteToFerryTrip(addNoteToFerryTripDto: AddNoteToFerryTrip) {
	return axios.post(
		`${SERVER_URL}/api/entity/NotesEntity/add-to-ferry-trip`,
		addNoteToFerryTripDto,
	);
}

/**
 * Adds a note to a ferry trip
 * @param addNoteToFerryTripDto - contains ferryTripId, noteContent and showToCustomers.
 */
export async function saveAllNotes(notes: NotesEntity[]) {
	return axios.post(
		`${SERVER_URL}/api/entity/NotesEntity/save-all-notes`,
		notes,
	);
}
