import * as React from 'react';
import { observer } from 'mobx-react';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import If from 'Views/Components/If/If';
import moment from 'moment';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { store } from 'Models/Store';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import {
	GiftVoucherDto,
	GiftVoucherUsageDto,
	startUpdatingVoucher,
} from '../AccountDetails/VoucherSection/GiftVouchersUtils';

export interface GiftCertificateDetailsModalProps {
	giftVoucherDto: GiftVoucherDto;
}

export const RenderGiftCertificateUsages = (giftCertificateUsage: GiftVoucherUsageDto[]) => {
	const usageLineItems: ReactNode[] = [];
	giftCertificateUsage.forEach(x => {
		usageLineItems.push(
			<div className="gift-certificate-details__usage-line-item" key={x.humanReadableId}>
				<p className="bold">{`#${x.humanReadableId ?? ''}`}</p>
				<p className="grey">{`- $${x.amount.toFixed(2)}`}</p>
			</div>,
		);
	});
	return usageLineItems;
};

export const GiftCertificateDetailsModalContent = observer(({
	giftVoucherDto,
}: GiftCertificateDetailsModalProps) => {
	const overviewClassName = classNames(
		'gift-certificate-details__overview',
		giftVoucherDto.status !== 'VALID' ? 'invalid' : 'valid',
	);

	return (
		<div className="gift-certificate-details__content">
			<div className={overviewClassName}>
				<h4>{giftVoucherDto.code}</h4>
				<h5>{`$${giftVoucherDto.remainingValue.toFixed(2)}/${giftVoucherDto.initialValue?.toFixed(2)}`}</h5>
				<p>{`${(giftVoucherDto.status === 'EXPIRED' || giftVoucherDto.status === 'USEDEXPIRED')
					? 'Expired:'
					: 'Expires'} ${moment(giftVoucherDto.expiryDate).format('DD MMM YYYY')}`}
				</p>
			</div>
			<div className="gift-certificate-details__activity-summary">
				<p className="header">Activity</p>
				<If condition={giftVoucherDto.giftCertificateUsages.length === 0 && !giftVoucherDto.price}>
					<p className="nothing-to-display__message">
						Nothing to display
					</p>
				</If>
				<If condition={giftVoucherDto.giftCertificateUsages.length > 0}>
					{RenderGiftCertificateUsages(giftVoucherDto.giftCertificateUsages)}
				</If>
				{giftVoucherDto.price && (
					// Display paid amount of gift voucher
					<div className="gift-certificate-details__usage-line-item">
						<p className="bold">#{giftVoucherDto.transactionId}</p>
						<p className="bold">{giftVoucherDto.invoiced === true ? 'Invoiced' : 'Paid'}</p>
						<p className="grey">{displayAsAud(giftVoucherDto.price)}</p>
					</div>
				)}
				<div className="gift-certificate-details__end-of-list" />
			</div>
		</div>
	);
});

export const RenderGiftCertificateDetailsModal = async (
	giftVoucherDto: GiftVoucherDto,
	refresh: () => void,
) => {
	const modalTitle = 'Voucher summary';
	const editButtonText: string = 'Edit voucher';
	const cancelButtonText: string = 'Back';

	let modalContent: JSX.Element;
	const ObservableModalContent = observer(() => {
		return (
			<GiftCertificateDetailsModalContent
				giftVoucherDto={giftVoucherDto}
			/>
		);
	});
	// eslint-disable-next-line prefer-const
	modalContent = <ObservableModalContent />;
	const confirmed = await modalWithCustomInput(
		modalTitle,
		modalContent,
		{
			cancelText: cancelButtonText,
			confirmText: store.isStaff ? editButtonText : '',
			actionClassName: 'gift-certificate-details-modal',
			resolveOnCancel: true,
		},
	);

	if (!confirmed) {
		return;
	}

	await startUpdatingVoucher({
		userId: giftVoucherDto.userId,
		refresh: refresh,
		voucherId: giftVoucherDto.id,
		voucherAmount: giftVoucherDto.initialValue,
		amountRemaining: giftVoucherDto.remainingValue,
		expiryDate: giftVoucherDto.expiryDate,
	});
};
