/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import InputWrapper, { InputType } from '../Inputs/InputWrapper';
import { action, observable } from 'mobx';
import { DisplayType } from '../Models/Enums';
import * as uuid from 'uuid';
import InputsHelper from '../Helpers/InputsHelper';
import classNames from 'classnames';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export interface IPasswordProps<T> {
	model: T;
	modelProperty: string;
	id?: string;
	name?: string;
	className?: string;
	displayType?: DisplayType;
	label?: React.ReactNode;
	labelVisible?: boolean;
	isRequired?: boolean;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	disablePreview?: boolean;
	tooltip?: string;
	subDescription?: string;
	placeholder?: string;
	clickToClear?: boolean;
	errors?: string | string[];
	onAfterChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onChangeAndBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	inputProps?: React.InputHTMLAttributes<Element>;
	passwordPreviewIcon?: string;
	// % protected region % [Add any extra IPasswordProps here] on begin
	inputRef?: React.LegacyRef<HTMLInputElement> | undefined;
	// % protected region % [Add any extra IPasswordProps here] end
}

@observer
export default class Password<T> extends React.Component<IPasswordProps<T>, any> {
	// % protected region % [Customise defaultProps here] off begin
	public static defaultProps = {
		inputProps: {},
		className: '',
	};
	// % protected region % [Customise defaultProps here] end

	// % protected region % [Customise uuid here] off begin
	private uuid = uuid.v4();
	// % protected region % [Customise uuid here] end

	// % protected region % [Customise valueWhenFocused here] off begin
	private valueWhenFocused: string = '';
	// % protected region % [Customise valueWhenFocused here] end

	// % protected region % [Customise showPassword here] off begin
	@observable
	private showPassword: boolean = false;
	// % protected region % [Customise showPassword here] end

	// % protected region % [Customise setShowPassword here] off begin
	@action
	private setShowPassword(showPassword: boolean) {
		this.showPassword = showPassword;
	}
	// % protected region % [Customise setShowPassword here] end

	// % protected region % [Customise render here] on begin
	public render() {
		const {
			model,
			modelProperty,
			name,
			label,
			isDisabled,
			isReadOnly,
			isRequired,
			disablePreview,
			tooltip,
			subDescription,
			placeholder,
			id = this.uuid.toString(),
			className,
			labelVisible,
			inputProps,
			inputRef,
			displayType,
			errors,
			passwordPreviewIcon = 'icon-look',
		} = this.props;

		const fieldId = `${id}-field`;
		const ariaLabel = InputsHelper.getAriaLabel(label, labelVisible);
		const ariaDescribedby = InputsHelper.getAriaDescribedBy(id, tooltip, subDescription);

		return (
			<InputWrapper
				inputType={InputType.PASSWORD}
				id={id || this.uuid.toString()}
				inputId={fieldId}
				className={classNames(className, 'password-container')}
				displayType={displayType}
				isRequired={isRequired}
				tooltip={tooltip}
				subDescription={subDescription}
				label={label}
				labelVisible={(labelVisible === undefined) ? true : labelVisible}
				errors={errors}
			>
				<input
					type={this.showPassword ? 'text' : 'password'}
					name={name}
					id={fieldId}
					value={model[modelProperty] ? model[modelProperty] : ''}
					onChange={this.onChange}
					onBlur={this.onBlur}
					placeholder={placeholder ?? ariaLabel}
					disabled={isDisabled}
					readOnly={isReadOnly}
					ref={inputRef}
					aria-label={ariaLabel}
					aria-describedby={ariaDescribedby}
					{...inputProps}
				/>
				{
					disablePreview
						? null
						: (
							<span
								className={`icon-right ${passwordPreviewIcon} password-input-icon icon icon-only`}
								onMouseEnter={() => this.setShowPassword(true)}
								onMouseLeave={() => this.setShowPassword(false)}
							/>
						)
				}
			</InputWrapper>
		);
	}
	// % protected region % [Customise render here] end

	// % protected region % [Customise onChange here] off begin
	@action
	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.model[this.props.modelProperty] = event.target.value;
		if (this.props.onAfterChange) {
			this.props.onAfterChange(event);
		}
	}
	// % protected region % [Customise onChange here] end

	// % protected region % [Customise onBlur here] off begin
	@action
	private onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (this.valueWhenFocused !== event.target.value && this.props.onChangeAndBlur) {
			this.props.onChangeAndBlur(event);
		}
	}
	// % protected region % [Customise onBlur here] end
}
