import * as React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import moment from 'moment';
import queryString from 'querystring';
import { FerryTripCards } from 'Views/Components/_HumanWritten/FerryTripCard/FerryTripCards';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import FerryTripSideBarWrapper from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripSideBarWrapper';
import { store } from 'Models/Store';
import If from 'Views/Components/If/If';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { FerryScheduleModel } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleModel';
import {
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router';
import {
	FerryTripEntity,
	MeasurementEntity,
	PriceEntity,
	RouteEntity,
} from 'Models/Entities';
import { daysOfWeekLong, getDayOfTheWeek } from 'Util/_HumanWritten/DayOfWeekUtils';
import { checkDateColumnClassName, isSameDate } from 'Util/_HumanWritten/DateSelectorUtils';
import { ferryTripFilterDeparted } from 'Util/_HumanWritten/TicketSelectionUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';

export interface WebTripCalendarProps {
	model: FerryScheduleModel;
	ferryTrips?: FerryTripEntity[];
	selectedDate: string;
	selectedRoute: RouteEntity;
	refetch: () => void;
	tripToEdit: React.MutableRefObject<FerryTripEntity | null>;
	bulkBookingTrips: string[] | null;
	onClickTrip: (tripId: string) => void;
}

function WebTripCalendar({
	model,
	ferryTrips,
	selectedDate,
	selectedRoute,
	refetch,
	tripToEdit,
	bulkBookingTrips,
	onClickTrip,
}: WebTripCalendarProps) {
	const match = useRouteMatch();
	const history = useHistory();
	const path = match.path === '/' ? '' : match.path;

	const listOfDays = [];
	const firstDay = (model.date).getDay();
	const firstDate = (model.date);
	const today = new Date();
	const onClickHandler = action((event: React.MouseEvent<HTMLButtonElement>, date: Date) => {
		model[selectedDate] = date.toString();

		tripToEdit.current = new FerryTripEntity({
			startDate: date,
			startTime: moment(date).minutes(0).toDate(),
			routeId: selectedRoute.id,
			prices: store.measurements.map(x => new PriceEntity({
				measurementId: x.id,
				measurement: new MeasurementEntity({
					id: x.id,
					label: x.label,
					value: x.value,
					measurementType: x.measurementType,
				}),
			})),
		});

		history.push(`${path}/create`);
	});

	/**
	 * Creates the booking wizard url for the given ferry trip. The additional url parameters allows the
	 * auto-selection in the wizard.
	 */
	const bookingWizardUrlGenerator = React.useCallback((date: Date) => {
		return (ferryTrip: FerryTripEntity) => {
			// All values in search string must be a string
			const searchStringModel = {
				date: date.toDateString(),
				from: model.fromLocation,
				to: model.toLocation,
				route: model.route.id,
				ferryTripId: ferryTrip.id,
				fromLocationName: selectedRoute.departure?.name ?? '',
				toLocationName: selectedRoute.destination?.name ?? '',
				type: ferryTrip.ferry.vehicleCapacity > 0 ? whiteLabelStore?.defaultBookingType : 'passenger',
			};
			const searchString = queryString.stringify(searchStringModel);
			return `/booking-wizard?${searchString}`;
		};
	}, [model.fromLocation, model.route.id, model.toLocation]);

	for (let i = 0, index = firstDay; i < daysOfWeekLong.length; i++, index++) {
		if (index === 7) {
			index = 0;
		}

		const day = getDayOfTheWeek(index, false).toUpperCase();
		const currentDay = new Date(firstDate);
		currentDay.setDate(firstDate.getDate() + i);
		const isToday = isSameDate(today, currentDay);

		// Only show the most recently departed if the user is not a staff member
		// If the user is a staff member show all of the trips for the day
		let filteredFerryList: FerryTripEntity[] | undefined = !store.isStaff
			? ferryTripFilterDeparted(currentDay, ferryTrips)
			: ferryTrips?.filter(filteredTrip => isSameDate(filteredTrip.startDate, currentDay));

		if (isNullOrUndefined(ferryTrips)) {
			filteredFerryList = undefined;
		}

		listOfDays.push(
			<div
				className={`day-column ${i === 0 ? 'first-day' : ''}
				${isToday ? 'today' : ''} ${day}`}
				key={currentDay.toString()}
			>
				<div className={`day-header ${checkDateColumnClassName(currentDay, ferryTrips) ? '' : 'no-trips'}`}>
					<h6 className="day-name-header">
						{isToday ? 'TODAY' : day}
					</h6>
					<h1 className="date-number-header">{moment(currentDay).format('DD')}</h1>
				</div>
				<div
					className={`trip-card-column-container ${i === 0 ? 'first-day' : ''}`}
				>
					<If condition={store.isManager && bulkBookingTrips === null}>
						<div className="add-ferry-trip-button-container">
							<Button
								className="add-ferry-trip-button"
								display={Display.Outline}
								colors={Colors.None}
								key={currentDay.toString()}
								onClick={event => onClickHandler(event, currentDay)}
							>
								+
							</Button>
						</div>
					</If>
					<FerryTripCards
						list={filteredFerryList}
						isMobile={false}
						wizardUrlGenerator={bookingWizardUrlGenerator(currentDay)}
						tripToEdit={tripToEdit}
						bulkBookingTrips={bulkBookingTrips}
						onClickTrip={onClickTrip}
					/>
				</div>
				<If condition={filteredFerryList === undefined}>
					<LottieSpinner />
				</If>
			</div>,
		);
	}

	return (
		<div className="calendar-container">
			{listOfDays}
			<Switch>
				<Route
					path={`${path}/create`}
					render={() => {
						if (tripToEdit.current) {
							return <FerryTripSideBarWrapper trip={tripToEdit.current} refetch={refetch} />;
						}
						return null;
					}}
				/>
				<Route
					path={`${path}/edit/:id`}
					render={props => {
						if (tripToEdit.current?.id) {
							return <FerryTripSideBarWrapper trip={tripToEdit.current} refetch={refetch} />;
						}

						// eslint-disable-next-line react/prop-types
						tripToEdit.current = new FerryTripEntity(ferryTrips?.find(x => x.id === props.match.params.id));
						return <FerryTripSideBarWrapper trip={tripToEdit.current} refetch={refetch} />;
					}}
				/>
			</Switch>
		</div>
	);
}

export default observer(WebTripCalendar);
