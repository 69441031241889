import { CustomTicketTypeEntity, UploadedImageEntity } from 'Models/Entities';
import { action } from 'mobx';
import moment from 'moment/moment';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import * as Models from 'Models/Entities';

export interface IEventDto {
	/**
	 * The Id of the event.
	 * Used for fetching the bookings and the re-fetch of the event.
	 */
	id: string;
	/**
	 * The Id of the ferry trip.
	 * Used for opening/closing the event.
	 */
	ferryTripId: string;
	/**
	 * The start date/time of the event.
	 */
	startDateTime: Date;
	/**
	 * The end date/time of the event.
	 */
	endDateTime: Date;
	/**
	 * Whether or not the event is hidden.
	 */
	hidden: boolean;
	/**
	 * Whether or not the event is closed for online bookings.
	 */
	closed: boolean;
	/**
	 * The name of the event.
	 */
	name: string;
	/**
	 * The description of the event.
	 */
	description: string;
	/**
	 * The google map url of the event.
	 */
	googleMapUrl: string;
	/**
	 * Total capacity of the event.
	 */
	totalCapacity: number;
	/**
	 * The custom ticket types of the event.
	 */
	customTicketTypes: CustomTicketTypeEntity[];
	/**
	 * The event label id of the event.
	 */
	eventLabelId: string;
	/**
	 * The supporting images of the event.
	 */
	supportingImages: UploadedImageEntity[];
	/**
	 * The header image of the event.
	 */
	headerImage: UploadedImageEntity;
}

export class EventDto implements IEventDto {
	constructor(attributes?: Partial<IEventDto>) {
		this.assignAttributes(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	private assignAttributes(attributes?: Partial<IEventDto>) {
		if (isNullOrUndefined(attributes)) {
			return;
		}
		if (isNotNullOrUndefined(attributes.id)) {
			this.id = attributes.id;
		}
		if (isNotNullOrUndefined(attributes.ferryTripId)) {
			this.ferryTripId = attributes.ferryTripId;
		}
		if (isNotNullOrUndefined(attributes.startDateTime)) {
			if (attributes.startDateTime === null) {
				this.startDateTime = attributes.startDateTime;
			} else {
				this.startDateTime = moment(attributes.startDateTime).toDate();
			}
		}
		if (isNotNullOrUndefined(attributes.endDateTime)) {
			if (attributes.endDateTime === null) {
				this.endDateTime = attributes.endDateTime;
			} else {
				this.endDateTime = moment(attributes.endDateTime).toDate();
			}
		}
		if (isNotNullOrUndefined(attributes.hidden)) {
			this.hidden = attributes.hidden;
		}
		if (isNotNullOrUndefined(attributes.closed)) {
			this.closed = attributes.closed;
		}
		if (isNotNullOrUndefined(attributes.name)) {
			this.name = attributes.name;
		}
		if (isNotNullOrUndefined(attributes.description)) {
			this.description = attributes.description;
		}
		if (isNotNullOrUndefined(attributes.googleMapUrl)) {
			this.googleMapUrl = attributes.googleMapUrl;
		}
		if (isNotNullOrUndefined(attributes.totalCapacity)) {
			this.totalCapacity = attributes.totalCapacity;
		}
		if (isNotNullOrUndefined(attributes.customTicketTypes)) {
			for (const model of attributes.customTicketTypes) {
				if (isNullOrUndefined(this.customTicketTypes)) {
					this.customTicketTypes = [];
				}
				if (model instanceof Models.CustomTicketTypeEntity) {
					this.customTicketTypes.push(model);
				} else {
					this.customTicketTypes.push(new Models.CustomTicketTypeEntity(model));
				}
			}
		}
		if (isNotNullOrUndefined(attributes.eventLabelId)) {
			this.eventLabelId = attributes.eventLabelId;
		}
		if (isNotNullOrUndefined(attributes.supportingImages)) {
			for (const model of attributes.supportingImages) {
				if (isNullOrUndefined(this.supportingImages)) {
					this.supportingImages = [];
				}
				if (model instanceof Models.UploadedImageEntity) {
					this.supportingImages.push(model);
				} else {
					this.supportingImages.push(new Models.UploadedImageEntity(model));
				}
			}
		}
		if (isNotNullOrUndefined(attributes.headerImage)) {
			this.headerImage = attributes.headerImage;
		}
	}

	id: string;
	ferryTripId: string;
	startDateTime: Date;
	endDateTime: Date;
	hidden: boolean;
	closed: boolean;
	name: string;
	description: string;
	googleMapUrl: string;
	totalCapacity: number;
	customTicketTypes: CustomTicketTypeEntity[];
	eventLabelId: string;
	supportingImages: UploadedImageEntity[];
	headerImage: UploadedImageEntity;
}
