import * as React from 'react';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { DisplayType } from 'Views/Components/Models/Enums';
import { CustomTooltip } from 'Views/Components/_HumanWritten/CustomTooltip/CustomTooltip';

interface ITrailerCheckbox {
	hasTrailer: boolean;
	setHasTrailer: () => void;
}

export default class TrailerCheckbox extends React.Component<ITrailerCheckbox> {
	private onCheckboxChecked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const { setHasTrailer } = this.props;
		setHasTrailer();
	}

	public render() {
		const { hasTrailer } = this.props;
		return (
			<div className="trailer-checkbox__container">
				<Checkbox
					className="trailer-checkbox-wrapper"
					model={{ trailer: hasTrailer }}
					modelProperty="trailer"
					label={"I'm towing a trailer"}
					displayType={DisplayType.BLOCK}
					onChecked={this.onCheckboxChecked}
				/>
				<CustomTooltip classNameBox="trailer-tooltip">
					Additional costs for trailers will be confirmed when providing the vehicle details.
				</CustomTooltip>
			</div>
		);
	}
}
