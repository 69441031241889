import React from 'react';
import { BookingEntity } from 'Models/Entities';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import useStore from 'Hooks/useStore';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { Link } from 'react-router-dom';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { observer } from 'mobx-react';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { action } from 'mobx';
import { LinkedBookingPricingResult } from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import { ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { useBookingWizardPricingCacheStore } from '../../BookingWizardPricingCacheStore';
import {
	BookingBreakdown,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/BookingBreakdown';
import {
	BookingWizardCartFields,
	BookingWizardData, clearBookingWizardData,
	saveOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { useHistory } from 'react-router';

export type tripSummaryLocationType = 'cart' | 'sidebar' | 'booking-success' | 'check-in';

export interface TripBookingSummaryCardProps {
	wizardData?: BookingWizardData;
	onUpdateWizardData?: (newData: BookingWizardData) => void;
	booking: BookingEntity;
	cartFields: BookingWizardCartFields;
	refresh?: () => void;
	count: number;
	bookingInfo?: LinkedBookingPricingResult;
	tripSummaryLocation: tripSummaryLocationType;
	selectedTrips?: SelectedTrips;
	setSelectedTrips?: (trips: SelectedTrips) => void;
	preProcessedCartFields?: BookingWizardCartFields[];
	removePreProcessedBooking: (bookingId: string) => void;
	isAlteration: boolean;
	onRemoveBooking: (bookingId: string) => Promise<boolean>;
	redirectToNextPage?: () => void;
	afterPayment: boolean;
	cartView: boolean;
}

function TripBookingSummaryCard({
	wizardData,
	onUpdateWizardData,
	booking,
	cartFields,
	refresh,
	count,
	bookingInfo,
	tripSummaryLocation,
	setSelectedTrips,
	isAlteration,
	onRemoveBooking,
	redirectToNextPage,
	afterPayment,
	cartView,
	preProcessedCartFields,
	removePreProcessedBooking,
}: TripBookingSummaryCardProps) {
	const store = useStore();
	const history = useHistory();
	const { isIpad } = useDeviceDetect();

	const { getBookingPrice } = useBookingWizardPricingCacheStore();

	const onEdit = action((newBookingToEdit: BookingWizardCartFields) => {
		if (setSelectedTrips) {
			setSelectedTrips(newBookingToEdit.selectedTrips);
		}

		if (onUpdateWizardData) {
			saveOldBookingWizardData(newBookingToEdit.wizardData);
			onUpdateWizardData(newBookingToEdit.wizardData);
		}
	});

	const shouldShowEditButtons = () => {
		return (tripSummaryLocation === 'cart' && !isAlteration)
			|| (tripSummaryLocation === 'sidebar' && isCurrentBooking());
	};

	const canEditBooking = () => {
		return wizardData?.bulkBookingTripIds === undefined;
	};

	const handleEdit = () => {
		onEdit(cartFields);
		store.routerHistory.push(isIpad ? '/booking-wizard/search' : '/booking-wizard/tickets');
	};

	const handleRemove = async () => {
		if (preProcessedCartFields !== undefined) {
			if (preProcessedCartFields.length <= 1) {
				clearBookingWizardData();
				history.push('/ferry-schedule');
			} else {
				removePreProcessedBooking(cartFields.wizardData.departureTicketId);
			}

			return;
		}
		await handleRegularRemove();
	};

	const handleRegularRemove = async () => {
		const confirmed = await confirmModal(
			'Remove ferry ticket?',
			"This action will release your booking and can't be undone.",
			{ cancelText: 'Back', confirmText: 'Confirm' },
		);

		if (confirmed) {
			await handleBookingRemoval();
		}
	};

	const handleBookingRemoval = async () => {
		// Unselect the selected ferry trips for the active booking
		clearSelectedTrips();
		updateWizardDataIfNecessary();
		const success = await onRemoveBooking(cartFields.wizardData.bookingToEdit ?? '');

		if (success && refresh) {
			refresh();
		}

		if (redirectToNextPage) {
			redirectToNextPage();
		}
	};

	const clearSelectedTrips = () => {
		if (cartFields.wizardData.departureTicketId) cartFields.selectedTrips.departingTrip = undefined;
		if (cartFields.wizardData.returningTicketId) cartFields.selectedTrips.returningTrip = undefined;
	};

	const updateWizardDataIfNecessary = () => {
		if (shouldUpdateWizardData()) {
			const newData = updateWizardData();
			if (onUpdateWizardData) {
				onUpdateWizardData(newData);
			}
		}
	};

	const shouldUpdateWizardData = () => {
		return (
			wizardData
			&& wizardData.bulkBookingTripIds
			&& wizardData.bulkBookingBookingIds
			&& booking
		);
	};

	const updateWizardData = () => {
		const newData = { ...wizardData };
		newData.bulkBookingTripIds = newData.bulkBookingTripIds?.filter(
			id => id !== booking?.bookingSummaries.recentSummary.ferryTrip.id,
		);
		newData.bulkBookingBookingIds = newData.bulkBookingBookingIds
			?.filter(id => id !== cartFields.wizardData.bookingToEdit);
		return newData as BookingWizardData;
	};

	const shouldShowPrice = () => {
		return (tripSummaryLocation === 'sidebar' || afterPayment) && !isCurrentBooking();
	};

	const isCurrentBooking = () => {
		return wizardData?.bookingToEdit === cartFields.wizardData.bookingToEdit;
	};

	return (
		<div key={`${count}-booking-card`} className="booking-summary-card__container">
			<div className="booking-summary-card__header">
				{/* If after payment, displays a link to booking (using the booking human readable id)
				    Else, displays a booking count (E.g. Booking 1) */}
				<h5>
					{isAlteration ? 'Update' : 'Booking'}{' '}
					{afterPayment && booking
						? (
							<Link to={`/bookings/${booking.id}`}>
								#{booking.humanReadableId}
							</Link>
						) : (
							count + 1
						)}
				</h5>

				{shouldShowEditButtons() && !isAlteration && (
					<ButtonGroup>
						{canEditBooking() && (
							<Button
								className="edit-button"
								colors={Colors.Secondary}
								display={Display.Text}
								onClick={handleEdit}
							>
								Edit
							</Button>
						)}
						<Button
							className="remove-button"
							colors={Colors.Red}
							display={Display.Text}
							onClick={handleRemove}
						>
							Remove
						</Button>
					</ButtonGroup>
				)}

				{shouldShowPrice() && (
					<p className="line-item-price">
						{formatPriceDisplay(
							booking.id && !afterPayment
								? getBookingPrice(booking.id)
								: (bookingInfo?.departureBooking.totalPrice ?? 0)
									+ (bookingInfo?.returnBooking?.totalPrice ?? 0),
							isAlteration,
						)}
					</p>
				)}
			</div>
			<If condition={
				afterPayment
				|| cartView
				|| (!cartView
					&& isCurrentBooking())
			}
			>
				<BookingBreakdown
					key="departure-breakdown"
					cartData={cartFields}
					departureTrip
					bookingInfo={bookingInfo?.departureBooking}
				/>
				<If
					condition={
						cartFields.wizardData.returningTicketId !== ''
						|| (
							cartFields.wizardData.tripType === 'return'
							&& (
								wizardData?.returningTicketId !== ''
								&& wizardData?.returningTicketId !== undefined
							)
						)
					}
				>
					<If condition={afterPayment}>
						<div className="booking-summary-card__header">
							<h5>
								{isAlteration ? 'Update' : 'Booking'} {
									afterPayment && booking
										? (
											<Link to={`/bookings/${booking.returnBooking?.id}`}>
												#{booking.returnBooking?.humanReadableId}
											</Link>
										)
										: count + 1
								}
							</h5>
						</div>
					</If>
					<BookingBreakdown
						key="return-breakdown"
						cartData={cartFields}
						departureTrip={false}
						bookingInfo={bookingInfo?.returnBooking ?? undefined}
					/>
				</If>
			</If>
		</div>
	);
}

export default observer(TripBookingSummaryCard);
