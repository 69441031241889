import React from 'react';
import { observer } from 'mobx-react';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import { IPriceLineItem } from 'Services/Api/_HumanWritten/PricingService/PricingResult';

export interface CreditCardFeeLineProps {
	lineItem: IPriceLineItem | null;
}

function CreditCardFeeLine({
	lineItem,
}: CreditCardFeeLineProps) {
	if (lineItem === null) {
		return null;
	}

	return (
		<CartCalculationLineItem
			keyToUse="credit-card-surcharge-line-item"
			className="credit-card-surcharge-line-item"
			lineItemDisplayName={lineItem.description}
			lineItemPrice={formatPriceDisplay(lineItem.price, true, true, true)}
		/>
	);
}

export default observer(CreditCardFeeLine);
