import React from 'react';
import { StaffBookingsFilters } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsComponent';
import { Toggle } from 'Views/Components/_HumanWritten/Toggle/Toggle';
import If from 'Views/Components/If/If';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { observable } from 'mobx';
import { UseOutsideClick } from 'Hooks/UseOutsideClick';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

export interface StaffBookingsFilterListProps {
	filters: StaffBookingsFilters;
	setFilters: (newFilters: StaffBookingsFilters) => void;
	setShowFilters: (showFilter: boolean) => void;
}

export function StaffBookingsFilterList({
	filters,
	setFilters,
	setShowFilters,
}: StaffBookingsFilterListProps) {
	const model = observable({
		showPastBookings: filters.showPastBookings ?? false,
		paidFilter: filters.paidFilter ?? true,
		invoicedFilter: filters.invoicedFilter ?? true,
		awaitingFilter: filters.awaitingFilter ?? true,
		cancelledFilter: filters.cancelledFilter ?? true,
	});

	const handleCloseFilterPanel = () => {
		const newFilters = { ...filters };

		// Update the filters based on user selection
		newFilters.showPastBookings = model.showPastBookings;
		newFilters.paidFilter = model.paidFilter;
		newFilters.invoicedFilter = model.invoicedFilter;
		newFilters.awaitingFilter = model.awaitingFilter;
		newFilters.cancelledFilter = model.cancelledFilter;

		// Close the panel
		setShowFilters(false);
		setFilters(newFilters);
	};

	const ref = UseOutsideClick(handleCloseFilterPanel);

	return (
		<div
			className="staff-booking__filter-panel"
			ref={ref}
		>
			<p>Bookings</p>
			<If condition={!filters.startDate && !filters.endDate}>
				<Toggle
					className="staff-booking__toggle-past-bookings"
					id="staff-bookings-toggle"
					model={model}
					modelProperty="showPastBookings"
					labelVisible
					labelOn="Show past bookings"
					labelOff="Show past bookings"
					onChecked={(_, checked) => {
						model.showPastBookings = checked;
					}}
				/>
			</If>
			<p>Status</p>
			<Checkbox
				id="paid-checkbox-id"
				model={model}
				modelProperty="paidFilter"
				label="Paid"
				onChecked={(_, value) => {
					model.paidFilter = value;
				}}
			/>
			<Checkbox
				id="awaiting-checkbox-id"
				model={model}
				modelProperty="awaitingFilter"
				label="Awaiting"
				onChecked={(_, value) => {
					model.awaitingFilter = value;
				}}
			/>
			<Checkbox
				id="invoiced-checkbox-id"
				model={model}
				modelProperty="invoicedFilter"
				label="Invoiced"
				onChecked={(_, value) => {
					model.invoicedFilter = value;
				}}
			/>
			<Checkbox
				id="cancelled-checkbox-id"
				model={model}
				modelProperty="cancelledFilter"
				label="Cancelled"
				onChecked={(_, value) => {
					model.cancelledFilter = value;
				}}
			/>
			<Button
				className="apply-filters-button"
				onClick={handleCloseFilterPanel}
				display={Display.Solid}
				colors={Colors.Secondary}
			>
				Apply filters
			</Button>
		</div>
	);
}
