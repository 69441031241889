import LightFH from '../../../images/powered-by-ferryhawk-white.png';
import DarkFH from '../../../images/powered-by-ferryhawk-black.png';
import GreyFH from '../../../images/powered-by-ferryhawk-grey.png';
import * as React from 'react';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

export interface PoweredByFerryHawkProps {
	light?: boolean;
	showOnMobile?: boolean;
	absolute?: boolean;
}

export function PoweredByFerryHawk({
	light = true,
	showOnMobile = true,
	absolute = true,
}: PoweredByFerryHawkProps) {
	const { isMobile } = useDeviceDetect();

	if (!showOnMobile && isMobile) {
		return <></>;
	}
	return (
		<a
			href="https://www.ferryhawk.com.au/"
			className={`powered-by-ferryhawk hide-underline ${absolute ? 'absolute' : 'relative'}`}
		>
			<img
				src={light ? LightFH : GreyFH}
				alt="Visit ferryhawk.com.au"
			/>
		</a>
	);
}
