import React from 'react';
import { observer } from 'mobx-react';
import usePayment from 'Views/Components/_HumanWritten/Payment/usePayment';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { CheckOutFormWrapper } from './CheckOutFormWrapper';
import { isNullOrUndefined } from '../../../../Util/TypeGuards';

interface PaymentProps {
	transactionId?: string;
	redirectUrl?: string;
	className?: string;
	errorCode?: string;
	isEventBooking: boolean;
}

function StripePayment({
	transactionId,
	redirectUrl,
	className,
	errorCode,
	isEventBooking,
}: PaymentProps) {
	const [disableContinueButton, setDisableContinueButton] = React.useState(false);
	const [tabIsLoading, setTabIsLoading] = React.useState(false);

	const { response, error } = usePayment(
		isEventBooking,
		transactionId,
		redirectUrl,
	);

	if (isNullOrUndefined(transactionId)) {
		return <></>;
	}

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response.type === 'error' || response.data === undefined) {
		return <></>;
	}

	return (
		<>
			<CheckOutFormWrapper
				transactionId={transactionId}
				paymentDetails={response.data}
				onPageLoaded={() => {}}
				userId={undefined}
				isAlteration={false}
				eventBooking={false}
				setTabIsLoading={setTabIsLoading}
				tabIsLoading={tabIsLoading}
				setDisableContinueButton={setDisableContinueButton}
				disableContinueButton={disableContinueButton}
				redirectUrl={response.data.formActionUrl}
				includeSubmitButtons
			/>
		</>
	);
}

export default observer(StripePayment);
