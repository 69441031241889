import { wizardModeOptions } from 'Models/Enums';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	BookingWizardData,
	saveBookingWizardDataToLocalStorage,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export function OnBulkBookTrips(bulkBookingTrips: string[], fromLocation: string, toLocation: string) {
	const bookingWizardData: BookingWizardData = {
		bulkBookingTripIds: bulkBookingTrips,
		acceptedTsAndCs: false,
		bookingToEdit: '',
		wizardMode: wizardModeOptions.CREATE,
		departureTrip: true,
		associatedTripDateTime: undefined,
		startDate: new Date(),
		endDate: new Date(),
		adultsCount: whiteLabelStore.minAdultsForVehicle,
		childrenCount: 0,
		infantCount: 0,
		trailerCheckboxSelected: false,
		tabSelected: 'vehicle',
		tripType: 'one way',
		fromLocationId: fromLocation,
		toLocationId: toLocation,
		ticketSelectionStartDate: new Date(),
		ticketSelectionEndDate: new Date(),
		departureTicketId: '',
		returningTicketId: '',
		userId: '',
		driverFirstName: '',
		driverLastName: '',
		driverPhone: '',
		cargoIdentification: '',
		hiredVehicle: false,
		cargoTypeId: '',
		cargoMake: '',
		cargoModel: '',
		vehicleLengthId: '',
		vehicleWeightId: '',
		trailerTypeId: 'NO_TRAILER',
		trailerLengthId: '',
		departingTripOptions: [],
		returningTripOptions: [],
		departingCancellationFee: 0,
		returningCancellationFee: 0,
	};
	saveBookingWizardDataToLocalStorage(bookingWizardData);
}
