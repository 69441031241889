import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import useStore from 'Hooks/useStore';
import { observer } from 'mobx-react';
import If from 'Views/Components/If/If';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import LinkAsButton from 'Views/Components/_HumanWritten/LinkAsButton/LinkAsButton';
import useScreenSize from 'Hooks/useScreenSize';
import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { EventCheckInActionButtons } from './EventCheckInButtons/EventCheckInActionButtons';
import useEventCheckInStore from 'Hooks/useEventCheckInStore';
import { formatDateDayMonth, formatShortTime } from 'Util/_HumanWritten/TimeUtils';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';

export interface EventCheckInTopNavProps {
	showButtons?: boolean;
	/**
	 * state must be provided if showButtons is true.
	 */
	state?: {
		showCheckedIn: boolean;
	};
}

function EventCheckInTopNav({ state, showButtons = false }: EventCheckInTopNavProps) {
	const store = useStore();
	const routes = useCheckInRoutes(true);
	const eventCheckInStore = useEventCheckInStore();
	const { eventDetails } = eventCheckInStore;
	const { isIpadMiniLandscape, isMobile } = useScreenSize();
	const [toRoute, setToRoute] = useState<string>('/upcoming-events');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const { pathname } = store.routerHistory.location;
		if (pathname === routes.base || pathname.includes('booking')) {
			setToRoute(routes.eventDetailedView);
		} else {
			setToRoute(routes.base);
		}
	}, [store.routerHistory.location, store.routerHistory.location.search]);

	return (
		<div className="check-in__top-nav flex-end">
			<div className="check-in__top-nav__back flex">
				<LinkAsButton
					to={toRoute}
					display={Display.Text}
					colors={Colors.Black}
				>
					<Icon name="chevron-left" classname="mb-xs" />
					<div className="check-in__top-nav__back__text">
						<span className="event-name">
							{eventDetails.name}
						</span>
						<span className="text--grey">
							{formatDateDayMonth(eventDetails.startDateTime)}, {formatShortTime(eventDetails.startDateTime)}
							<If condition={!isMobile}>
								{` - ${formatShortTime(eventDetails.endDateTime)}`}
							</If>
						</span>
					</div>
				</LinkAsButton>
			</div>
			<If condition={showButtons}>
				<div className="check-in__top-nav__actions flex">
					<Button
						className="refresh"
						display={Display.Text}
						colors={Colors.Secondary}
						onClick={async () => {
							await runInAction(async () => {
								setLoading(true);
								await eventCheckInStore.loadEvent(eventCheckInStore.eventId);
								setLoading(false);
							});
						}}
						disabled={loading}
					>
						<Icon name="refresh-cw" classname={loading ? 'loading' : ''} />
					</Button>
					<If condition={!isIpadMiniLandscape}>
						<Button
							className={`check-in__action-btn__toggle-check-in ${state?.showCheckedIn ? 'checked' : 'unchecked'}`}
							display={Display.Text}
							colors={Colors.Secondary}
							sizes={Sizes.ExtraLarge}
							onClick={() => {
								if (state) {
									runInAction(() => {
										state.showCheckedIn = !state?.showCheckedIn;
									});
								}
							}}
							icon={{ icon: 'check-box', iconPos: 'icon-left' }}
						/>
						<div className="border__wrapper">
							<div className="border" />
						</div>
						<LinkAsButton
							to={`${store.routerHistory.location.pathname}/scan`}
							className="check-in__action-btn__camera"
							display={Display.Solid}
							colors={Colors.Secondary}
							sizes={Sizes.Medium}
						>
							Scan QR code
						</LinkAsButton>
					</If>
				</div>
				<If condition={isIpadMiniLandscape}>
					<EventCheckInActionButtons state={state} />
				</If>
			</If>
		</div>
	);
}

export default observer(EventCheckInTopNav);
