/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBackgroundJobConfigEntity from 'Models/Security/Acl/VisitorsBackgroundJobConfigEntity';
import UserBackgroundJobConfigEntity from 'Models/Security/Acl/UserBackgroundJobConfigEntity';
import StaffBackgroundJobConfigEntity from 'Models/Security/Acl/StaffBackgroundJobConfigEntity';
import ManagerBackgroundJobConfigEntity from 'Models/Security/Acl/ManagerBackgroundJobConfigEntity';
import AdminBackgroundJobConfigEntity from 'Models/Security/Acl/AdminBackgroundJobConfigEntity';
import InvoicedUserBackgroundJobConfigEntity from 'Models/Security/Acl/InvoicedUserBackgroundJobConfigEntity';
import FixedPriceUserBackgroundJobConfigEntity from 'Models/Security/Acl/FixedPriceUserBackgroundJobConfigEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from '../Store';
// % protected region % [Add any further imports here] end

export interface IBackgroundJobConfigEntityAttributes extends IModelAttributes {
	automaticallyCancelUnpaidBookings: boolean;
	hoursToHoldUnpaidBooking: number;
	sendReminderEmailOne: boolean;
	sendEmailReminderTwo: boolean;
	reminderEmailOneHoursBeforeDeparture: number;
	reminderEmailTwoHoursBeforeDeparture: number;
	sendInvoiceReport: boolean;
	sendTransactionReport: boolean;
	automaticallyCancelBookingsNotCheckedIn: boolean;
	cancelNotCheckedInBookingsHoursAfterDeparture: number;
	blockSendingOfAllEmails: boolean;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BackgroundJobConfigEntity', 'Background Job Config')
// % protected region % [Customise your entity metadata here] end
export default class BackgroundJobConfigEntity extends Model
	implements IBackgroundJobConfigEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBackgroundJobConfigEntity(),
		new UserBackgroundJobConfigEntity(),
		new StaffBackgroundJobConfigEntity(),
		new ManagerBackgroundJobConfigEntity(),
		new AdminBackgroundJobConfigEntity(),
		new InvoicedUserBackgroundJobConfigEntity(),
		new FixedPriceUserBackgroundJobConfigEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Automatically Cancel Unpaid Bookings'] on begin
	/**
	 * Whether or not to automatically cancel bookings that have not been paid for by the deadline.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Automatically cancel unpaid bookings',
		displayType: 'hidden',
		order: 10,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public automaticallyCancelUnpaidBookings: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Automatically Cancel Unpaid Bookings'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hours To Hold Unpaid Booking'] on begin
	/**
	 * The hours that an unpaid booking will be held for, before it is cancelled.
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<BackgroundJobConfigEntity, number>()
	@CRUD({
		name: 'Hours to hold unpaid booking',
		displayType: 'hidden',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public hoursToHoldUnpaidBooking: number;
	// % protected region % [Modify props to the crud options here for attribute 'Hours To Hold Unpaid Booking'] end

	// % protected region % [Modify props to the crud options here for attribute 'Send Reminder Email One'] on begin
	/**
	 * Whether or not to send reminder email one to customers.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Send booking reminder email one',
		description: 'Whether or not to send reminder email one.',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sendReminderEmailOne: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Send Reminder Email One'] end

	// % protected region % [Modify props to the crud options here for attribute 'Send Email Reminder Two'] on begin
	/**
	 * Whether or not to send reminder email two to customers.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Send booking reminder email two',
		description: 'Whether or not to send reminder email two.',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sendEmailReminderTwo: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Send Email Reminder Two'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reminder Email One Hours Before Departure'] on begin
	/**
	 * The number of hours before departure that reminder email one will be triggered.
	 */
	@Validators.Integer()
	@observable
	@attribute<BackgroundJobConfigEntity, number>()
	@CRUD({
		name: 'Reminder email one days before departure',
		description: 'The number of days before departure you want the booking reminder to send.',
		displayType: 'textfield',
		order: 60,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reminderEmailOneHoursBeforeDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reminder Email One Hours Before Departure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reminder Email Two Hours Before Departure'] on begin
	/**
	 * The number of hours before departure that reminder email two will be triggered.
	 */
	@Validators.Integer()
	@observable
	@attribute<BackgroundJobConfigEntity, number>()
	@CRUD({
		name: 'Reminder email two days before departure',
		description: 'The number of days before departure you want the booking reminder to send.',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public reminderEmailTwoHoursBeforeDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Reminder Email Two Hours Before Departure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Send Invoice Report'] on begin
	/**
	 * Whether or not to send the invoice report daily to the specified email address.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Cancel failed transactions background job',
		displayType: 'checkbox',
		description: 'Whether or not to cancel failed transactions (note this should only be used on testing environments).',
		isReadonly: !store.isSuper,
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sendInvoiceReport: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Send Invoice Report'] end

	// % protected region % [Modify props to the crud options here for attribute 'Send Transaction Report'] on begin
	/**
	 * The email address that will receive the transaction report daily.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Send admin email reports',
		description: 'Whether or not to send the failed transactions report and ferry overbooking emails.',
		displayType: 'checkbox',
		headerColumn: true,
		order: 50,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sendTransactionReport: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Send Transaction Report'] end

	// % protected region % [Modify props to the crud options here for attribute 'Automatically Cancel Bookings Not Checked In'] off begin
	/**
	 * Cancel bookings that have not been checked in within a certain time since departure.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Automatically Cancel Bookings Not Checked In',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public automaticallyCancelBookingsNotCheckedIn: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Automatically Cancel Bookings Not Checked In'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cancel Not Checked In Bookings Hours After Departure'] off begin
	/**
	 * How many hours after departure should the non-checked-in bookings be cancelled?
	 */
	@Validators.Integer()
	@observable
	@attribute<BackgroundJobConfigEntity, number>()
	@CRUD({
		name: 'Cancel Not Checked In Bookings Hours After Departure',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public cancelNotCheckedInBookingsHoursAfterDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Cancel Not Checked In Bookings Hours After Departure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Block Sending Of All Emails'] off begin
	/**
	 * Whether or not to block the sending of all emails.
	 */
	@observable
	@attribute<BackgroundJobConfigEntity, boolean>()
	@CRUD({
		name: 'Block Sending Of All Emails',
		displayType: 'checkbox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public blockSendingOfAllEmails: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Block Sending Of All Emails'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBackgroundJobConfigEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBackgroundJobConfigEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.automaticallyCancelUnpaidBookings !== undefined) {
				this.automaticallyCancelUnpaidBookings = attributes.automaticallyCancelUnpaidBookings;
			}
			if (attributes.hoursToHoldUnpaidBooking !== undefined) {
				this.hoursToHoldUnpaidBooking = attributes.hoursToHoldUnpaidBooking;
			}
			if (attributes.sendReminderEmailOne !== undefined) {
				this.sendReminderEmailOne = attributes.sendReminderEmailOne;
			}
			if (attributes.sendEmailReminderTwo !== undefined) {
				this.sendEmailReminderTwo = attributes.sendEmailReminderTwo;
			}
			if (attributes.reminderEmailOneHoursBeforeDeparture !== undefined) {
				this.reminderEmailOneHoursBeforeDeparture = attributes.reminderEmailOneHoursBeforeDeparture;
			}
			if (attributes.reminderEmailTwoHoursBeforeDeparture !== undefined) {
				this.reminderEmailTwoHoursBeforeDeparture = attributes.reminderEmailTwoHoursBeforeDeparture;
			}
			if (attributes.sendInvoiceReport !== undefined) {
				this.sendInvoiceReport = attributes.sendInvoiceReport;
			}
			if (attributes.sendTransactionReport !== undefined) {
				this.sendTransactionReport = attributes.sendTransactionReport;
			}
			if (attributes.automaticallyCancelBookingsNotCheckedIn !== undefined) {
				this.automaticallyCancelBookingsNotCheckedIn = attributes.automaticallyCancelBookingsNotCheckedIn;
			}
			if (attributes.cancelNotCheckedInBookingsHoursAfterDeparture !== undefined) {
				this.cancelNotCheckedInBookingsHoursAfterDeparture = attributes.cancelNotCheckedInBookingsHoursAfterDeparture;
			}
			if (attributes.blockSendingOfAllEmails !== undefined) {
				this.blockSendingOfAllEmails = attributes.blockSendingOfAllEmails;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BackgroundJobConfigEntity.prototype, 'created');
CRUD(modifiedAttr)(BackgroundJobConfigEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
