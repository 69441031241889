import * as React from 'react';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { BookingSummary } from '../../../../BookingSummaryDto';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';

export function CustomTicketInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	usePassengerTypes();

	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const description = getCustomTicketInfo(booking.bookedSummary);
	const sectionContent = (
		<p className="custom-ticket-types">
			{description}
		</p>
	);

	return (
		<BookingDetailSection
			sectionName="Tickets"
			sectionContent={sectionContent}
			sectionClassName="tickets"
			icon={{
				name: 'person',
				classname: 'person',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'tickets',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
/**
 * Returns e.g. '1 adult, 2 children, 1 infant'.
 */
export function getCustomTicketInfo(
	summary: BookingSummary,
	shortName: boolean = false,
) {
	if (isNullOrUndefined(summary)
		|| isNullOrUndefined(summary.selectedTickets)
		|| isNullOrUndefined(summary.ferryTrip?.eventDetail?.customTicketTypes)) {
		return '';
	}
	const items: string[] = [];

	for (const customTicket of summary.selectedTickets!) {
		const qty = customTicket.quantity;
		const customTicketType = summary.ferryTrip.eventDetail.customTicketTypes.find(x => x.id === customTicket.customTicketTypeId);
		if (qty > 0 && isNotNullOrUndefined(customTicketType)) {
			// Only include passengers with a count
			let label = qty === 1
				? customTicketType.singularName
				: customTicketType.pluralName;
			if (shortName) {
				label = customTicket?.customTicketTypeId;
				items.push(`${qty}${label}`);
			} else {
				items.push(`${qty} ${label}`);
			}
		}
	}

	return items.join(', ');
}
