import * as React from 'react';
import { useState } from 'react';
import { FerryTripImportSideBarContents } from './FerryTripImportSideBarContents';
import { SideBar } from '../SideBar';
import { FerryTripImportSideBarConfirmation } from './FerryTripImportSideBarConfirmation';
import { FerryTripImportResponse } from 'Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';

export interface FerryTripImportSideBarProps {
	onClose: (isSuccess: boolean, response?: FerryTripImportResponse) => void;
}

export function FerryTripImportSideBar({
	onClose,
}: FerryTripImportSideBarProps) {
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [file, setFile] = useState<File | undefined>();
	const [importResponse, setImportResponse] = useState<FerryTripImportResponse | undefined>();

	return (
		<>
			<SideBar className="ferry-trip-import-side-bar" onClose={() => onClose(false)}>
				{
					showConfirmation && importResponse
						? (
							<FerryTripImportSideBarConfirmation
								file={file}
								onCancel={() => {
									setImportResponse(undefined);
									setShowConfirmation(false);
								}}
								onConfirm={response => onClose(true, response)}
								importResponse={importResponse}
							/>
						)
						: (
							<FerryTripImportSideBarContents
								file={file}
								setFile={setFile}
								importResponse={importResponse}
								setImportResponse={setImportResponse}
								onClose={() => onClose(false)}
								onContinue={() => setShowConfirmation(true)}
							/>
						)
				}
			</SideBar>
		</>
	);
}
