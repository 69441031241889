/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsFerryEntity from 'Models/Security/Acl/VisitorsFerryEntity';
import UserFerryEntity from 'Models/Security/Acl/UserFerryEntity';
import StaffFerryEntity from 'Models/Security/Acl/StaffFerryEntity';
import ManagerFerryEntity from 'Models/Security/Acl/ManagerFerryEntity';
import AdminFerryEntity from 'Models/Security/Acl/AdminFerryEntity';
import InvoicedUserFerryEntity from 'Models/Security/Acl/InvoicedUserFerryEntity';
import FixedPriceUserFerryEntity from 'Models/Security/Acl/FixedPriceUserFerryEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IFerryEntityAttributes extends IModelAttributes {
	name: string;
	passengerCapacity: number;
	weightCapacity: number;
	vehicleCapacity: number;
	towingCapacity: number;
	maximumHeight: number;
	isHazardous: boolean;

	additionalBookingSectionss: Array<
		| Models.FerriesAdditionalBookingSections
		| Models.IFerriesAdditionalBookingSectionsAttributes
	>;
	hazardousFerryRuleId?: string;
	hazardousFerryRule?: Models.HazardousFerryRuleEntity | Models.IHazardousFerryRuleEntityAttributes;
	ferryTrips: Array<
		| Models.FerryTripEntity
		| Models.IFerryTripEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FerryEntity', 'Ferry')
// % protected region % [Customise your entity metadata here] end
export default class FerryEntity extends Model
	implements IFerryEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFerryEntity(),
		new UserFerryEntity(),
		new StaffFerryEntity(),
		new ManagerFerryEntity(),
		new AdminFerryEntity(),
		new InvoicedUserFerryEntity(),
		new FixedPriceUserFerryEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<FerryEntity, string>()
	@CRUD({
		name: 'Ferry name',
		description: 'The name of the ferry.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger Capacity'] on begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryEntity, number>()
	@CRUD({
		name: 'Passenger capacity',
		description: 'The total passenger capacity on this ferry.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Weight Capacity'] on begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryEntity, number>()
	@CRUD({
		name: 'Weight capacity',
		description: 'The total weight capacity for this ferry.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public weightCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Weight Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Capacity'] on begin
	/**
	 * The  maximum combined vehicle & towOn spaces
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryEntity, number>()
	@CRUD({
		name: 'Cargo capacity',
		description: 'The total cargo spaces available for this ferry.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public vehicleCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Vehicle Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Towing Capacity'] on begin
	/**
	 * The maximum number of trailers that can be placed on this ferry
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FerryEntity, number>()
	@CRUD({
		name: 'Towing capacity',
		description: 'The total towing capacity for this ferry (note: this capacity will come from the cargo capacity '
			+ 'and is not a separate capacity).',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public towingCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Towing Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Maximum Height'] on begin
	/**
	 * The maximum height a ferry can fit.
	 */
	@Validators.Numeric()
	@observable
	@attribute<FerryEntity, number>()
	@CRUD({
		name: 'Maximum height',
		displayType: 'hidden',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public maximumHeight: number;
	// % protected region % [Modify props to the crud options here for attribute 'Maximum Height'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Hazardous'] on begin
	/**
	 * Whether or not a ferry is a hazardous ferry.
	 */
	@observable
	@attribute<FerryEntity, boolean>()
	@CRUD({
		name: 'Is hazardous',
		description: 'If checked, a ferry trip will display a hazardous symbol and have new booking rules '
			+ 'associated to it.',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isHazardous: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Hazardous'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Sections'] on begin
		name: 'Additional booking sections',
		description: 'Add on sections that are linked to this ferry.',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.FerriesAdditionalBookingSections,
		optionEqualFunc: makeJoinEqualsFunc('additionalBookingSectionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'ferryEntity',
			oppositeEntityName: 'additionalBookingSectionEntity',
			relationName: 'ferries',
			relationOppositeName: 'additionalBookingSections',
			entity: () => Models.FerryEntity,
			joinEntity: () => Models.FerriesAdditionalBookingSections,
			oppositeEntity: () => Models.AdditionalBookingSectionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Sections'] end
	})
	public additionalBookingSectionss: Models.FerriesAdditionalBookingSections[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Hazardous Ferry Rule'] on begin
		name: 'Hazardous ferry rule',
		description: 'The hazardous rules that apply to this ferry.',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.HazardousFerryRuleEntity,
		// % protected region % [Modify props to the crud options here for reference 'Hazardous Ferry Rule'] end
	})
	public hazardousFerryRuleId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public hazardousFerryRule: Models.HazardousFerryRuleEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] on begin
		name: 'Ferry Trips',
		displayType: 'hidden',
		order: 60,
		referenceTypeFunc: () => Models.FerryTripEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ferryTrips',
			oppositeEntity: () => Models.FerryTripEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] end
	})
	public ferryTrips: Models.FerryTripEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFerryEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFerryEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.passengerCapacity !== undefined) {
				this.passengerCapacity = attributes.passengerCapacity;
			}
			if (attributes.weightCapacity !== undefined) {
				this.weightCapacity = attributes.weightCapacity;
			}
			if (attributes.vehicleCapacity !== undefined) {
				this.vehicleCapacity = attributes.vehicleCapacity;
			}
			if (attributes.towingCapacity !== undefined) {
				this.towingCapacity = attributes.towingCapacity;
			}
			if (attributes.maximumHeight !== undefined) {
				this.maximumHeight = attributes.maximumHeight;
			}
			if (attributes.isHazardous !== undefined) {
				this.isHazardous = attributes.isHazardous;
			}
			if (attributes.additionalBookingSectionss !== undefined && Array.isArray(attributes.additionalBookingSectionss)) {
				for (const model of attributes.additionalBookingSectionss) {
					if (model instanceof Models.FerriesAdditionalBookingSections) {
						this.additionalBookingSectionss.push(model);
					} else {
						this.additionalBookingSectionss.push(new Models.FerriesAdditionalBookingSections(model));
					}
				}
			}
			if (attributes.hazardousFerryRuleId !== undefined) {
				this.hazardousFerryRuleId = attributes.hazardousFerryRuleId;
			}
			if (attributes.hazardousFerryRule !== undefined) {
				if (attributes.hazardousFerryRule === null) {
					this.hazardousFerryRule = attributes.hazardousFerryRule;
				} else if (attributes.hazardousFerryRule instanceof Models.HazardousFerryRuleEntity) {
					this.hazardousFerryRule = attributes.hazardousFerryRule;
					this.hazardousFerryRuleId = attributes.hazardousFerryRule.id;
				} else {
					this.hazardousFerryRule = new Models.HazardousFerryRuleEntity(attributes.hazardousFerryRule);
					this.hazardousFerryRuleId = this.hazardousFerryRule.id;
				}
			}
			if (attributes.ferryTrips !== undefined && Array.isArray(attributes.ferryTrips)) {
				for (const model of attributes.ferryTrips) {
					if (model instanceof Models.FerryTripEntity) {
						this.ferryTrips.push(model);
					} else {
						this.ferryTrips.push(new Models.FerryTripEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		additionalBookingSectionss {
			${Models.FerriesAdditionalBookingSections.getAttributes().join('\n')}
			additionalBookingSections {
				${Models.AdditionalBookingSectionEntity.getAttributes().join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			additionalBookingSectionss: {},
			ferryTrips: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'additionalBookingSectionss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FerryEntity.prototype, 'created');
CRUD(modifiedAttr)(FerryEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
