import { BookingEntity } from 'Models/Entities';
import {
	EventBookingWizardData, SelectedTicketsDto,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { getMostRecentBookedOrReservedAlteration } from '../AlterationSortingUtils';

// Converts a bookingEntity to an instance of BookingWizardData to be used in the local storage
// primarily for editing bookings from the cart tab
export function eventBookingToWizardData(
	booking: BookingEntity,
): EventBookingWizardData {
	const mostRecentAlteration = getMostRecentBookedOrReservedAlteration(booking.alterations);
	return {
		eventId: mostRecentAlteration.ferryTrip.eventDetailId,
		userId: booking.userId,
		firstName: booking.user.firstName,
		lastName: booking.user.lastName,
		email: booking.user.email,
		phone: booking.user.phone,
		selectedTickets: mostRecentAlteration.tickets.map(ticket => {
			return {
				customTicketTypeId: ticket.customTicketTypeId,
				customTicketType: ticket.customTicketType,
				quantity: ticket.amount,
			} as SelectedTicketsDto;
		}),
	} as EventBookingWizardData;
}
