import { UserEntity } from 'Models/Entities';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { isEmail } from 'Validators/Functions/Email';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';

export type FormErrors<T> = {
	[K in keyof T]?: string;
};

export const validateInvoicedUserForm = (invoicedUser: UserEntity) => {
	const newErrors: FormErrors<UserEntity> = {};

	if (stringIsEmpty(invoicedUser.firstName)) {
		newErrors.firstName = 'First name required';
	}
	if (stringIsEmpty(invoicedUser.lastName)) {
		newErrors.lastName = 'Last name required';
	}
	if (stringIsEmpty(invoicedUser.email)) {
		newErrors.email = 'Email required';
	} else if (!isEmail(invoicedUser.email)) {
		newErrors.email = 'Enter a valid email address (xx@xx.xx)';
	}
	if (stringIsEmpty(invoicedUser.phone)) {
		newErrors.phone = 'Phone required';
	} else if (!IsValidPhone(invoicedUser.phone)) {
		newErrors.phone = 'Enter a valid phone (+61 / 04)';
	}
	if (stringIsEmpty(invoicedUser.postcode)) {
		newErrors.postcode = 'Postcode required';
	}
	for (const passengerType of passengerTypeStore.passengerTypes) {
		const key = passengerType.passengerTypeKey;
		const modelProperty = passengerTypeStore.getPriceProperty(key, invoicedUser);
		if (!passengerType.disabled && stringIsEmpty(invoicedUser[modelProperty])) {
			newErrors[modelProperty] = 'Required';
		}
	}
	for (const x of invoicedUser.prices) {
		if (stringIsEmpty(newErrors.prices)) {
			if (isNullOrUndefined(x.amount)) {
				newErrors.prices = 'All prices are required';
			}
		}
	}
	for (const x of invoicedUser.addOnsPrices) {
		if (stringIsEmpty(newErrors.addOnsPrices)) {
			if (stringIsEmpty(x.price as unknown as string)) {
				newErrors.addOnsPrices = 'All add on prices are required';
			}
		}
	}
	return newErrors;
};
