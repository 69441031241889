import { whiteLabelStore } from '../../Models/WhiteLabelStore';
import { isNotNullOrUndefined } from '../TypeGuards';
import passengerTypeStore from '../../Models/PassengerTypeStore';
import { BookingWizardData } from '../../Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export function formatTripFilterInfo(wizardData: BookingWizardData): string {
	const itemsArray = [];
	const {
		tabSelected,
		trailerCheckboxSelected,
		adultsCount,
		childrenCount,
		infantCount,
		passengerCountD,
		passengerCountE,
		passengerCountF,
		passengerCountG,
		passengerCountH,
	} = wizardData;

	if (tabSelected === 'vehicle') {
		itemsArray.push(
			`1 ${whiteLabelStore.vehicleLabelLowerCase}`,
		);
	}
	if (trailerCheckboxSelected) {
		itemsArray.push(
			'1 trailer',
		);
	}
	if (isNotNullOrUndefined(adultsCount) && adultsCount !== 0) {
		itemsArray.push(
			adultsCount > 1
				? `${adultsCount} ${passengerTypeStore.getPluralName('A')}`
				: `${adultsCount} ${passengerTypeStore.getSingularName('A')}`,
		);
	}
	if (isNotNullOrUndefined(childrenCount) && childrenCount !== 0) {
		itemsArray.push(
			childrenCount > 1
				? `${childrenCount} ${passengerTypeStore.getPluralName('B')}`
				: `${childrenCount} ${passengerTypeStore.getSingularName('B')}`,
		);
	}
	if (isNotNullOrUndefined(infantCount) && infantCount !== 0) {
		itemsArray.push(
			infantCount > 1
				? `${infantCount} ${passengerTypeStore.getPluralName('C')}`
				: `${infantCount} ${passengerTypeStore.getSingularName('C')}`,
		);
	}

	if (isNotNullOrUndefined(passengerCountD) && passengerCountD !== 0) {
		itemsArray.push(
			passengerCountD > 1
				? `${passengerCountD} ${passengerTypeStore.getPluralName('D')}`
				: `${passengerCountD} ${passengerTypeStore.getSingularName('D')}`,
		);
	}
	if (isNotNullOrUndefined(passengerCountE) && passengerCountE !== 0) {
		itemsArray.push(
			passengerCountE > 1
				? `${passengerCountE} ${passengerTypeStore.getPluralName('E')}`
				: `${passengerCountE} ${passengerTypeStore.getSingularName('E')}`,
		);
	}
	if (isNotNullOrUndefined(passengerCountF) && passengerCountF !== 0) {
		itemsArray.push(
			passengerCountF > 1
				? `${passengerCountF} ${passengerTypeStore.getPluralName('F')}`
				: `${passengerCountF} ${passengerTypeStore.getSingularName('F')}`,
		);
	}
	if (isNotNullOrUndefined(passengerCountG) && passengerCountG !== 0) {
		itemsArray.push(
			passengerCountG > 1
				? `${passengerCountG} ${passengerTypeStore.getPluralName('G')}`
				: `${passengerCountG} ${passengerTypeStore.getSingularName('G')}`,
		);
	}
	if (isNotNullOrUndefined(passengerCountH) && passengerCountH !== 0) {
		itemsArray.push(
			passengerCountH > 1
				? `${passengerCountH} ${passengerTypeStore.getPluralName('H')}`
				: `${passengerCountH} ${passengerTypeStore.getSingularName('H')}`,
		);
	}
	return itemsArray.join(', ').toLowerCase();
}
