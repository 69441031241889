import { observable, runInAction } from 'mobx';
import { fetchAccountId, fetchCurrency, fetchPublishableKey } from '../Services/Api/_HumanWritten/PaymentService';
import { loadStripe, Stripe } from '@stripe/stripe-js';

interface IPaymentStore {
	publishableKey: string;
	accountId: string;
	currency: string;
	// stripePromise: Promise<Stripe | null>;
	retrieveAsync(refresh?: boolean): Promise<void>;
}

export class PaymentStore implements IPaymentStore {
	/**
	 * The publishableKey used for Stripe payments.
	 */
	@observable
	private _publishableKey: string = '';

	/**
	 * The connected accountId  to send the payments to.
	 */
	@observable
	private _accountId: string = '';

	/**
	 * The currency to pay in.
	 */
	@observable
	private _currency: string = '';

	/**
	 * The stripePromise used for Stripe payments.
	 */
	// @observable
	// private _stripePromise: Promise<Stripe | null>;

	/**
	 * True means the publishableKey and accountId has been fetched, false otherwise.
	 */
	private _fetched: boolean = false;

	public async retrieveAsync(refresh = false) {
		if (refresh) {
			this._fetched = false;
		}
		if (this._fetched) {
			return;
		}

		// Fetch the publishable key and account id
		const [publishableKey, accountId, currency] = await Promise.all([
			fetchPublishableKey(),
			fetchAccountId(),
			fetchCurrency(),
		]);

		runInAction(() => {
			this._publishableKey = publishableKey;
			this._accountId = accountId;
			this._currency = currency;
			// if (publishableKey && accountId) {
			// 	this._stripePromise = loadStripe(
			// 		publishableKey,
			// 		{
			// 			stripeAccount: accountId,
			// 		},
			// 	);
			// }
			this._fetched = true;
		});
	}

	public get publishableKey() {
		return this._publishableKey;
	}

	public get accountId() {
		return this._accountId;
	}

	public get currency() {
		return this._currency;
	}

	// public get stripePromise() {
	// 	return this._stripePromise;
	// }
}

const paymentStore: IPaymentStore = new PaymentStore();
export default paymentStore;
