import axios from 'axios';
import { buildUrl } from '../../../Util/FetchUtils';
import { EVENT_DETAIL_ENTITY_URL } from '../../../Constants';

export async function GetEventDetails(eventId: string) {
	const res = await axios.get(
		buildUrl(`${EVENT_DETAIL_ENTITY_URL}/get-event-by-id`, { eventId }),
	);
	return res.data[0];
}

export async function GetAllEvents(skip: number) {
	const res = await axios.get(
		buildUrl(`${EVENT_DETAIL_ENTITY_URL}/get-all-events`, { skip: skip.toString() }),
	);
	return res.data;
}
