import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import { TicketsTabTrip } from './BookingWizardDataService';
import { Client } from 'graphql-ws';
import { ErrorInfo } from 'react';

export const errorLoggingBaseUrl = `${SERVER_URL}/api/error-logging`;

export interface ClientsideErrorDto {
	currentLocation: string;
	previousLocation: string;
	loggedInUserId?: string;
	message: string;
	stack?: string;
}

/**
 * Logs an error on the server from the clientside exception that was thrown.
 * @param errorInfo The info to log on the server.
 */
export async function logErrorOnServer(errorInfo: ClientsideErrorDto) {
	return axios.post(
		`${errorLoggingBaseUrl}/log-clientside-error`,
		errorInfo,
		{
			validateStatus: () => true,
		},
	);
}
