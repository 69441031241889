import * as React from 'react';
import { BaseSpaceModal } from './BaseSpaceModal';

export interface TrailerModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void
}

export function TrailerModal({
	showModal,
	onClickSelect,
	onClickCross,
}: TrailerModalProps) {
	return (
		<BaseSpaceModal
			showModal={showModal}
			onClickCross={onClickCross}
			onClickSelect={onClickSelect}
			textToDisplay={[
				'Your trailer length exceeds the space available on the ferry.',
			]}
		/>
	);
}
