import React from 'react';
import { observer } from 'mobx-react';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { CartCalculationLineItem } from './CartCalculationLineItem';
import {
	EventBookingPricesDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import { isNullOrUndefined } from '../../../../../../../Util/TypeGuards';

export interface CreditCardFeeLineProps {
	prices: EventBookingPricesDto | null;
}

function CreditCardFeeLine({
	prices,
}: CreditCardFeeLineProps) {
	const displayPrice = formatPriceDisplay(
		prices?.surchargePrice ?? 0,
		false,
		true,
		true,
	);

	if (isNullOrUndefined(prices) || prices.surchargePrice <= 0) {
		return <></>;
	}

	return (
		<CartCalculationLineItem
			keyToUse="event-credit-card-surcharge-line-item"
			className="credit-card-surcharge-line-item"
			lineItemDisplayName="Credit Card Surcharge:"
			lineItemPrice={displayPrice}
		/>
	);
}

export default observer(CreditCardFeeLine);
