/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsAlterationReferenceManyToMany from '../Security/Acl/VisitorsAlterationReferenceManyToMany';
import UserAlterationReferenceManyToMany from '../Security/Acl/UserAlterationReferenceManyToMany';
import StaffAlterationReferenceManyToMany from '../Security/Acl/StaffAlterationReferenceManyToMany';
import ManagerAlterationReferenceManyToMany from '../Security/Acl/ManagerAlterationReferenceManyToMany';
import AdminAlterationReferenceManyToMany from '../Security/Acl/AdminAlterationReferenceManyToMany';
import InvoicedUserAlterationReferenceManyToMany from '../Security/Acl/InvoicedUserAlterationReferenceManyToMany';
import FixedPriceUserAlterationReferenceManyToMany from '../Security/Acl/FixedPriceUserAlterationReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDiscountAlterationAttributes extends IModelAttributes {
	discountId: string;
	alterationId: string;

	discount: Models.DiscountEntity | Models.IDiscountEntityAttributes;
	alteration: Models.AlterationEntity | Models.IAlterationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('DiscountAlteration')
export default class DiscountAlteration
	extends Model
	implements IDiscountAlterationAttributes {
	public static acls: IAcl[] = [
		new VisitorsAlterationReferenceManyToMany(),
		new UserAlterationReferenceManyToMany(),
		new StaffAlterationReferenceManyToMany(),
		new ManagerAlterationReferenceManyToMany(),
		new AdminAlterationReferenceManyToMany(),
		new InvoicedUserAlterationReferenceManyToMany(),
		new FixedPriceUserAlterationReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public discountId: string;

	@observable
	@attribute()
	public alterationId: string;

	@observable
	@attribute({ isReference: true })
	public discount: Models.DiscountEntity;

	@observable
	@attribute({ isReference: true })
	public alteration: Models.AlterationEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IDiscountAlterationAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.discountId) {
				this.discountId = attributes.discountId;
			}
			if (attributes.alterationId) {
				this.alterationId = attributes.alterationId;
			}

			if (attributes.discount) {
				if (attributes.discount instanceof Models.DiscountEntity) {
					this.discount = attributes.discount;
					this.discountId = attributes.discount.id;
				} else {
					this.discount = new Models.DiscountEntity(attributes.discount);
					this.discountId = this.discount.id;
				}
			} else if (attributes.discountId !== undefined) {
				this.discountId = attributes.discountId;
			}

			if (attributes.alteration) {
				if (attributes.alteration instanceof Models.AlterationEntity) {
					this.alteration = attributes.alteration;
					this.alterationId = attributes.alteration.id;
				} else {
					this.alteration = new Models.AlterationEntity(attributes.alteration);
					this.alterationId = this.alteration.id;
				}
			} else if (attributes.alterationId !== undefined) {
				this.alterationId = attributes.alterationId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
