import * as React from 'react';
import classNames from 'classnames';

export enum BadgeColor {
	New = 'badge--new',
	Blue = 'badge--primary',
	Pink = 'badge--secondary',
	Awaiting = 'booking-status awaiting-status',
	Invoiced = 'booking-status invoiced-status',
	Cancelled = 'booking-status cancelled-status',
}

export interface BadgeProps {
	className?: string;
	text: string;
	color?: BadgeColor;
	as?: React.ElementType,
}

export function Badge({
	as = 'div',
	className,
	text,
	color = BadgeColor.Blue,
}: BadgeProps) {
	const Component = as;
	return (
		<Component className={classNames('badge', color, className)}>
			{text}
		</Component>
	);
}
