import axios, { AxiosResponse } from 'axios';
import { SERVER_URL } from 'Constants';
import { UserEntity } from 'Models/Entities';

/**
 * This endpoint is for a staff member to create a user entity and send a reset password email to the user.
 *
 * When resolved, it returns the id of the newly created user entity.
 */
export function registerForCustomer(user: UserEntity): Promise<AxiosResponse<string>> {
	return axios.post(
		`${SERVER_URL}/api/register/user-entity-via-staff`,
		{
			...user.toJSON(),
			//
			// The endpoint is expecting UserEntityRegistrationModel in the body. So the password field is required,
			// but is not used in the endpoint.
			//
			password: 'placeholder',
		},
	);
}
