import * as React from 'react';
import { GiftVoucherCard } from './GiftVoucherCard';
import { ReactNode } from 'react';
import { GiftVoucherDto, GiftVouchersProps } from './GiftVouchersUtils';
import { isNullOrUndefined } from '../../../../../Util/TypeGuards';

export const GiftVouchers = ({
	reload,
	listOfGiftCertificates,
}: GiftVouchersProps) => {
	return (
		// eslint-disable-next-line max-len
		<div className={`gift-voucher-list__container ${(listOfGiftCertificates?.count ?? 0) > 4 ? 'pagination-applied' : ''}`}>
			{giftVouchersInner(reload, listOfGiftCertificates?.giftVouchers)}
		</div>
	);
};

export const giftVouchersInner = (refresh: () => void, giftVouchers?: GiftVoucherDto[]) => {
	const vouchers: ReactNode[] = [];
	if (isNullOrUndefined(giftVouchers)) {
		return <></>;
	}
	if (giftVouchers?.length > 0) {
		giftVouchers.forEach((x: GiftVoucherDto) => {
			vouchers.push(
				<GiftVoucherCard key={x.id} giftVoucher={x} refresh={refresh} />,
			);
		});

		return vouchers;
	}
	return (
		<div className="no-vouchers__container">
			<p>No credits to display</p>
		</div>
	);
};
