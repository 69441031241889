import * as React from 'react';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { GetFormattedDate, GetFormattedDateTime, GetFormattedTime } from '../../../../Events/EventCards/EventUtils';
import { Link } from 'react-router-dom';

export function EventDateTimeInfoSection({
	booking,
	canEdit,
	onEdit,
	isInvoiced,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const ferryTrip = booking?.bookedSummary?.ferryTrip;

	const sectionContent = (
		<>
			<Link
				className="event-booking__header hide-underline"
				to={`/event-details/${ferryTrip.eventDetail?.id}`}
			>
				<p className="event-name">
					{ferryTrip?.getEventName()}
				</p>
			</Link>
			<p className="event-date">
				{GetFormattedDate(
					ferryTrip.startDate,
					ferryTrip.endDate)}
			</p>
			<p className="event-time">
				{GetFormattedTime(
					ferryTrip.startTime,
					ferryTrip.endTime)}
			</p>
		</>
	);

	return (
		<BookingDetailSection
			sectionName="Date/time"
			sectionContent={sectionContent}
			sectionClassName="event-date-time"
			icon={{
				name: 'calendar',
				classname: 'calendar',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'event-date-time',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
