import React from 'react';
import { observer } from 'mobx-react';
import { FerryTripEntity, UserEntity } from 'Models/Entities';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import passengerTypeStore from 'Models/PassengerTypeStore';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';
import If from 'Views/Components/If/If';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';
import { isNullOrUndefined } from 'Util/TypeGuards';

interface PassengerTableProps {
	model: UserEntity | FerryTripEntity;
	isReadOnly?: boolean;
	accountsPage?: boolean;
	setNeedConfirm?: (value: boolean) => void;
	errors?: FormErrors<UserEntity>;
}

function PassengerTable({
	model,
	isReadOnly = false,
	accountsPage = false,
	setNeedConfirm,
	errors,
}: PassengerTableProps) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableCell textAlign="left">Ticket type</TableCell>
					<If condition={!accountsPage}>
						<TableCell textAlign="left">Space units</TableCell>
					</If>
					<TableCell textAlign="left">Cost</TableCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{passengerTypeStore.passengerTypes.map(x => {
					const key = x.passengerTypeKey;
					const name = passengerTypeStore.getSingularName(key);
					const quantity = passengerTypeStore.getQuantity(key);
					return (
						<TableRow key={x.id}>
							<TableCell textAlign="left">{name}</TableCell>
							<If condition={!accountsPage}>
								<TableCell textAlign="left">{quantity}</TableCell>
							</If>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field icon-money icon-left"
									id={`passenger-price-${key}`}
									model={model}
									modelProperty={passengerTypeStore.getPriceProperty(key, model)}
									isDisabled={isReadOnly}
									placeholder="0"
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
									}}
									errors={isNullOrUndefined(model[passengerTypeStore.getPriceProperty(key, model)])
										? errors?.adultPrice
										: ''}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}

export default observer(PassengerTable);
