import { ROUTE_ENTITY_URL } from 'Constants';
import axios from 'axios';
import { buildUrl } from '../../../Util/FetchUtils';
import { IRouteEntityAttributes, RouteEntity } from '../../../Models/Entities';

export async function fetchRouteByLocation(fromLocationId?: string, toLocationId?: string): Promise<RouteEntity> {
	const results = await axios.get(
		buildUrl(`${ROUTE_ENTITY_URL}/route-by-locations`, {
			fromLocationId: fromLocationId ?? '',
			toLocationId: toLocationId ?? '',
		}),
	);

	return new RouteEntity(results.data);
}

export async function fetchAllRoutes(): Promise<RouteEntity[]> {
	const results = await axios.get(buildUrl(`${ROUTE_ENTITY_URL}/fetch-all-routes`));

	return results.data.map((route: Partial<IRouteEntityAttributes>) => new RouteEntity(route));
}
