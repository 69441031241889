/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsLocationEntity from 'Models/Security/Acl/VisitorsLocationEntity';
import UserLocationEntity from 'Models/Security/Acl/UserLocationEntity';
import StaffLocationEntity from 'Models/Security/Acl/StaffLocationEntity';
import ManagerLocationEntity from 'Models/Security/Acl/ManagerLocationEntity';
import AdminLocationEntity from 'Models/Security/Acl/AdminLocationEntity';
import InvoicedUserLocationEntity from 'Models/Security/Acl/InvoicedUserLocationEntity';
import FixedPriceUserLocationEntity from 'Models/Security/Acl/FixedPriceUserLocationEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ILocationEntityAttributes extends IModelAttributes {
	name: string;
	shortName: string;
	timezone: string;
	address: string;
	longitude: number;
	latitude: number;
	disabled: boolean;

	departureRoutes: Array<
		| Models.RouteEntity
		| Models.IRouteEntityAttributes
	>;
	destinationRoutes: Array<
		| Models.RouteEntity
		| Models.IRouteEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('LocationEntity', 'Location')
// % protected region % [Customise your entity metadata here] end
export default class LocationEntity extends Model
	implements ILocationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsLocationEntity(),
		new UserLocationEntity(),
		new StaffLocationEntity(),
		new ManagerLocationEntity(),
		new AdminLocationEntity(),
		new InvoicedUserLocationEntity(),
		new FixedPriceUserLocationEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'Location name',
		description: 'The name of this location. This will appear around the application for customers to see.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Short Name'] on begin
	@Validators.Required()
	@Validators.Length(3, 3)
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'Location short name (max 3 chars)',
		description: 'The short name of the location to display on smaller screens.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public shortName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Short Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Timezone'] on begin
	/**
	 * The timezone of the location
	 */
	@Validators.Required()
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'Location timezone',
		description: 'The timezone of the location. Note: it must use a TZ identifier and be spelt and formatted '
			+ 'correctly or it will not work. E.g. Australia/Brisbane',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public timezone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Timezone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address'] on begin
	/**
	 * The address of the locations port or office.
	 */
	@Validators.Required()
	@observable
	@attribute<LocationEntity, string>()
	@CRUD({
		name: 'Address',
		description: 'The address of the location. This will be used in emails to let users know where the port is.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] on begin
	@Validators.Numeric()
	@observable
	@attribute<LocationEntity, number>()
	@CRUD({
		name: 'Longitude',
		displayType: 'hidden',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public longitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Longitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] on begin
	@Validators.Numeric()
	@observable
	@attribute<LocationEntity, number>()
	@CRUD({
		name: 'Latitude',
		displayType: 'hidden',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public latitude: number;
	// % protected region % [Modify props to the crud options here for attribute 'Latitude'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	@observable
	@attribute<LocationEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Departure Route'] on begin
		name: 'Departure routes',
		description: 'The routes that this location is linked to as the departure location.',
		displayType: 'reference-multicombobox',
		order: 70,
		isReadonly: true,
		referenceTypeFunc: () => Models.RouteEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'departureRoutes',
			oppositeEntity: () => Models.RouteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Departure Route'] end
	})
	public departureRoutes: Models.RouteEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Destination Route'] on begin
		name: 'Destination routes',
		description: 'The routes that this location is linked to as the destination location.',
		displayType: 'reference-multicombobox',
		order: 80,
		isReadonly: true,
		referenceTypeFunc: () => Models.RouteEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'destinationRoutes',
			oppositeEntity: () => Models.RouteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Destination Route'] end
	})
	public destinationRoutes: Models.RouteEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ILocationEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ILocationEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.shortName !== undefined) {
				this.shortName = attributes.shortName;
			}
			if (attributes.timezone !== undefined) {
				this.timezone = attributes.timezone;
			}
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.longitude !== undefined) {
				this.longitude = attributes.longitude;
			}
			if (attributes.latitude !== undefined) {
				this.latitude = attributes.latitude;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.departureRoutes !== undefined && Array.isArray(attributes.departureRoutes)) {
				for (const model of attributes.departureRoutes) {
					if (model instanceof Models.RouteEntity) {
						this.departureRoutes.push(model);
					} else {
						this.departureRoutes.push(new Models.RouteEntity(model));
					}
				}
			}
			if (attributes.destinationRoutes !== undefined && Array.isArray(attributes.destinationRoutes)) {
				for (const model of attributes.destinationRoutes) {
					if (model instanceof Models.RouteEntity) {
						this.destinationRoutes.push(model);
					} else {
						this.destinationRoutes.push(new Models.RouteEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		departureRoutes {
			${Models.RouteEntity.getAttributes().join('\n')}
			${Models.RouteEntity.getFiles().map(f => f.name).join('\n')}
		}
		destinationRoutes {
			${Models.RouteEntity.getAttributes().join('\n')}
			${Models.RouteEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			departureRoutes: {},
			destinationRoutes: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(LocationEntity.prototype, 'created');
CRUD(modifiedAttr)(LocationEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
