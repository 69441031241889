import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import { UserDisplayNameEntity, UserEntity } from '../../../Models/Entities';
import { IUserEntityAttributes } from 'Models/Entities/UserEntity';

export async function addInvoicedUser(user: UserEntity) {
	return axios.post(
		`${SERVER_URL}/api/entity/UserEntity/add-invoiced-user`,
		user.toJSON({ prices: {}, addOnsPrices: {} }),
		{
			validateStatus: () => true,
		},
	);
}

export async function fetchInvoicedUser(userId: string) {
	const { data } = await axios.get<IUserEntityAttributes>(
		`${SERVER_URL}/api/entity/UserEntity/fetch-invoiced-user?userId=${userId}`,
	);

	return new UserEntity(data);
}

export async function fetchUserById(userId: string) {
	const { data } = await axios.get<IUserEntityAttributes>(
		`${SERVER_URL}/api/entity/UserEntity/fetch-user-by-id?userId=${userId}`,
	);

	return new UserEntity(data);
}

export async function updateUser() {
	const { data } = await axios.get<UserDisplayNameEntity[]>(
		`${SERVER_URL}/api/entity/UserEntity/fetch-user-display-types`,
	);

	return data as UserDisplayNameEntity[];
}

export async function fetchUserDisplayTypes() {
	const { data } = await axios.get<UserDisplayNameEntity[]>(
		`${SERVER_URL}/api/entity/UserEntity/fetch-user-display-types`,
	);

	return data as UserDisplayNameEntity[];
}
