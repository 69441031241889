import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import CalendarSvg from 'images/calender.svg';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import { DisplayType } from 'Views/Components/Models/Enums';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import { RadioAlignment, RadioButtonGroup } from '../../../RadioButton/RadioButtonGroup';

export interface GiftVoucherState {
	voucherAmount: number;
	expiryDate: Date;
	paymentMethod?: 'invoice' | 'pay';
	paymentAmount?: number;
	voucherAmountError?: string,
	paymentAmountError?: string;
}

export interface GiftVoucherModalProps {
	state: GiftVoucherState,
	editMode?: boolean
}

function GiftVoucherModalContent({
	state,
	editMode = false,
}: GiftVoucherModalProps) {
	const resetError = action((key: string) => {
		state[key] = undefined;
	});

	return (
		<div className="gift-voucher-modal-content">
			<NumberTextField
				className={classNames(
					'currency-prefix voucher-value',
					{ 'row-1 column-1': !editMode },
					{ 'column-span-all': editMode },
				)}
				id="remaining-value"
				model={state}
				modelProperty="voucherAmount"
				label={editMode ? 'Remaining value' : 'Voucher value'}
				placeholder="0.00"
				onAfterChange={() => resetError('voucherAmountError')}
				errors={state.voucherAmountError}
				showErrors={false}
			/>
			<DatePicker
				className={classNames(
					'gift-voucher-modal-content--date-picker',
					{ 'row-1 column-2': !editMode },
					{ 'column-span-all': editMode },
				)}
				model={state}
				modelProperty="single"
				displayType={DisplayType.BLOCK}
				flatpickrOptions={{
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
				flatpickrProps={{
					value: [state.expiryDate],
					onChange: dates => {
						const [expiryDate] = dates;
						runInAction(() => {
							if (expiryDate !== undefined) {
								state.expiryDate = expiryDate;
							}
						});
					},
					render: (props, ref) => {
						return (
							<div className="create-gift-voucher-date-selector__container" ref={ref}>
								<CustomDateRangeFilter
									model={state}
								/>
							</div>
						);
					},
				}}
			/>
			{!editMode && (
				<>
					<NumberTextField
						className="currency-prefix column-span-all"
						id="payment-amount"
						model={state}
						modelProperty="paymentAmount"
						label="Payment amount"
						placeholder="0.00"
						onAfterChange={() => resetError('paymentAmountError')}
						errors={state.paymentAmountError}
						showErrors={false}
					/>
					<RadioButtonGroup
						name="group1"
						className="payment-method_radio-button column-span-all"
						alignment={RadioAlignment.HORIZONTAL}
						model={state}
						modelProperty="paymentMethod"
						label="Payment type"
						options={[
							{ display: 'Pay now', value: 'pay' },
							{ display: 'Invoice', value: 'invoice' },
						]}
					/>
				</>
			)}
		</div>
	);
}

export default observer(GiftVoucherModalContent);

interface ICustomDateRangeFilterProps {
	model: GiftVoucherState;
}

function CustomDateRangeFilter({ model }: ICustomDateRangeFilterProps) {
	return (
		<>
			<p className="create-gift-voucher__label">Expiry date</p>
			<div className="create-gift-voucher-date-selector__input">
				<span>
					{`${moment(model.expiryDate)
						.format('DD/MM/YYYY')
						.toUpperCase()}`}
				</span>
				<img
					className="date-calendar-icon"
					src={CalendarSvg}
					alt="depart-calendar-icon"
				/>
			</div>
		</>
	);
}
