import { FerryTripEntity } from 'Models/Entities';
import moment from 'moment';
import { fetchFilteredFerryTrips } from '../../../Services/Api/_HumanWritten/FerryTripEntityService';
import { IFerryTripEntityAttributes } from 'Models/Entities/FerryTripEntity';

export async function FetchFilteredTrips(
	startDate: Date,
	numOfDays: number,
	routeId: string,
	userId: string | null = null,
): Promise<FerryTripEntity[]> {
	const newDate = new Date(startDate);
	const startTimeDate = moment(new Date(startDate)).toISOString();
	const endTimeDate = moment(new Date(newDate.setDate(newDate.getDate() + numOfDays))).toISOString();

	try {
		const results = await fetchFilteredFerryTrips(
			startTimeDate,
			endTimeDate,
			routeId,
			userId,
		);

		return results.data.map((z: Partial<IFerryTripEntityAttributes> | undefined) => new FerryTripEntity(z));
	} catch (e) {
		console.error(e);
		return [];
	}
}
