import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { BookingEntity } from 'Models/Entities';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import alertToast from 'Util/ToastifyUtils';
import { upperCaseFirst } from 'Util/StringUtils';
import { BookingCardDescription } from 'Views/Components/_HumanWritten/Bookings/BookingCards/BookingCardDescription';
import If from 'Views/Components/If/If';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import moment from 'moment';
import { formatAMPM } from '../../../../../Util/_HumanWritten/TimeUtils';

export interface BookingCardProps {
	booking: BookingEntity;
	editUrl: string;
}

export function BookingCard(props: BookingCardProps) {
	const { booking, editUrl } = props;

	if (isNullOrUndefined(booking.bookingListSummary)) {
		alertToast(
			'Booking is missing details',
			'error',
			undefined,
			{ autoClose: 3000 },
		);
		return <Redirect to="/404" />;
	}

	return (
		<div className="booking-card-container">
			<BookingCardDetails booking={booking} />
			<Link to={editUrl} className="view-booking-detail-section__edit-button hide-underline">
				{isBeforeNow(booking.bookingListSummary.tripDateTime)
					|| booking?.bookingStatus === 'CANCELLED'
					? 'View booking'
					: 'View/Edit booking'}
			</Link>
		</div>
	);
}

function BookingCardDetails({ booking }: { booking: BookingEntity }) {
	const { isMobile, isIpad } = useDeviceDetect();

	if (isNullOrUndefined(booking.bookingListSummary) || isNullOrUndefined(booking.transactionStatus)) {
		alertToast(
			'Booking is missing details',
			'error',
			undefined,
			{ autoClose: 3000 },
		);
		return <Redirect to="/404" />;
	}

	const {
		passengerDetailsString,
		cargoDriverDetailsString,
		cargoRegoAndTrailerDetailsString,
		eventName,
		totalCost,
		tripDateTime,
		route,
		iconId,
	} = booking.bookingListSummary;

	const status = booking.transactionStatus;

	const hasVehicleOnDesktop = stringNotEmpty(cargoDriverDetailsString) && !isIpad && !isMobile;
	const regoClass = hasVehicleOnDesktop ? 'booked-trip-vehicle-details' : '';
	const driverClass = hasVehicleOnDesktop ? 'driver-details' : 'driver-details-mobile';

	let routeName = `${route?.departure?.name} to ${route?.destination?.name}`;
	const iconClass = booking?.returnBooking !== null || booking?.returnBookingForId === null
		? 'icon-next'
		: 'icon-back';

	if (stringNotEmpty(eventName)) {
		routeName = eventName;
	}

	return (
		<div className="booking">
			<div className="booking-header">
				<If condition={stringNotEmpty(eventName)}>
					<h6 className="event-name event-icon">
						<img
							className="event-icon"
							src={`/api/files/${iconId}`}
							alt="event-icon"
						/>
						{routeName}
					</h6>
				</If>
				<If condition={!stringNotEmpty(eventName)}>
					<h6 className={`route-name ${iconClass}  icon-left`}>
						{routeName}
					</h6>
				</If>
				<div className={`booking-status ${status?.toLowerCase()}-status`}>
					{status && upperCaseFirst(status === 'PAID'
						? 'BOOKED'.toLowerCase()
						: status.toLowerCase())}
				</div>
			</div>
			<div className="ticket-body" key={booking.id}>
				<div className="booked-trip-time-date">
					<p className="bold date">{moment(tripDateTime).format('DD MMM YYYY')}</p>
					<p className="bold time">{`${formatAMPM(tripDateTime) ?? ''}`}</p>
				</div>
				<p className="booking-id-booking-tile">ID #{booking.humanReadableId}</p>
				<p className="booked-trip-passenger-details">
					<>{passengerDetailsString}</>
				</p>
				{stringNotEmpty(cargoDriverDetailsString) && (
					<p className={driverClass}>{cargoDriverDetailsString}</p>
				)}
				{stringNotEmpty(cargoRegoAndTrailerDetailsString) && (
					<p className={regoClass}>{cargoRegoAndTrailerDetailsString}</p>
				)}
				<p className="booked-ticket-cost">{formatPriceDisplay(totalCost, false)}</p>
			</div>
		</div>
	);
}
