import React from 'react';
import { observer } from 'mobx-react';
import { WaitlistItemEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { upperCaseFirst } from 'Util/StringUtils';
import { Button } from 'Views/Components/Button/Button';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { bookingType } from 'Models/Enums';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';
import { removeFromWaitlist } from 'Services/Api/_HumanWritten/WaitlistService';

interface WaitlistTableProps {
	waitlistItems: WaitlistItemEntity[];
	setWaitlitsItems: (waitlistItems: WaitlistItemEntity[]) => void;
}

function WaitlistTable({
	waitlistItems,
	setWaitlitsItems,
}: WaitlistTableProps) {
	return (
		<>
			<Table>
				<TableHeader>
					<TableRow>
						<TableCell textAlign="left">Email address</TableCell>
						<TableCell textAlign="left">Type</TableCell>
						<TableCell textAlign="right" />
					</TableRow>
				</TableHeader>
				<If condition={waitlistItems.length > 0}>
					<TableBody>
						{waitlistItems
							.sort((w1, w2) => w1.email.toLowerCase() > w2.email.toLowerCase() ? 1 : -1)
							.map(x => {
								return (
									<TableRow key={x.id}>
										<TableCell textAlign="left">{x.email.toLowerCase()}</TableCell>
										<TableCell textAlign="left">{upperCaseFirst(getBookingTypeString(
											x.bookingType,
											x.cargoType?.minimumLength?.label,
										))}
										</TableCell>
										<TableCell textAlign="right">
											<Button
												className="clear-line-item__btn"
												icon={{ icon: 'cross', iconPos: 'icon-top' }}
												onClick={() => {
													confirmModal(
														'Remove from waitlist?',
														// eslint-disable-next-line max-len
														<p>Are you sure you want to remove <strong>{x.email.toLowerCase()}</strong> from the <strong>{getBookingTypeString(x.bookingType)}</strong> waitlist for this ferry trip?</p>,
													).then(() => {
														removeFromWaitlist(x.id);
														setWaitlitsItems(waitlistItems.filter(y => y.id !== x.id));
													});
												}}
											/>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</If>
			</Table>
			<If condition={waitlistItems.length === 0}>
				<p className="empty-waitlist__message">No users on the waitlist yet...</p>
			</If>
		</>
	);
}

const getBookingTypeString = (type: bookingType, length?: string) => {
	switch (type) {
		case 'PASSENGER_ONLY':
			return 'passenger';
		case 'VEHICLE':
			return `vehicle ${isNotNullOrUndefined(length) ? `(${length?.toLowerCase()})` : ''}`;
		case 'TRAILER':
			return 'trailer';
		default:
			return 'unknown';
	}
};

export default observer(WaitlistTable);
