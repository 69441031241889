import { useEffect, useRef } from 'react';

import { getUserMedia } from '../Utils/getUserMedia';
import { useBoolean } from 'Hooks/useBoolean';

interface UseVideoRefProps {
	onSetupError?(error: unknown): void;
}

export const useVideoRef = ({ onSetupError }: UseVideoRefProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { value: isReady, on } = useBoolean(false);

	useEffect(() => {
		// Start camera
		let stream: MediaStream;
		getUserMedia()
			.then(res => {
				stream = res;
				if (videoRef.current) {
					videoRef.current.srcObject = res;
				}
				on();
			})
			.catch(err => {
				console.error('Error accessing media devices.', err);
				onSetupError?.(err);
			});

		// Clean up: stop streaming when component unmounts
		return () => {
			if (stream) {
				stream.getTracks().forEach(track => track.stop());
			}
		};
	}, []);

	return { videoRef, isReady };
};
