/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsBookingReferenceManyToMany from '../Security/Acl/VisitorsBookingReferenceManyToMany';
import UserBookingReferenceManyToMany from '../Security/Acl/UserBookingReferenceManyToMany';
import StaffBookingReferenceManyToMany from '../Security/Acl/StaffBookingReferenceManyToMany';
import ManagerBookingReferenceManyToMany from '../Security/Acl/ManagerBookingReferenceManyToMany';
import AdminBookingReferenceManyToMany from '../Security/Acl/AdminBookingReferenceManyToMany';
import InvoicedUserBookingReferenceManyToMany from '../Security/Acl/InvoicedUserBookingReferenceManyToMany';
import FixedPriceUserBookingReferenceManyToMany from '../Security/Acl/FixedPriceUserBookingReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface INoteBookingAttributes extends IModelAttributes {
	noteId: string;
	bookingId: string;

	note: Models.NotesEntity | Models.INotesEntityAttributes;
	booking: Models.BookingEntity | Models.IBookingEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('NoteBooking')
export default class NoteBooking
	extends Model
	implements INoteBookingAttributes {
	public static acls: IAcl[] = [
		new VisitorsBookingReferenceManyToMany(),
		new UserBookingReferenceManyToMany(),
		new StaffBookingReferenceManyToMany(),
		new ManagerBookingReferenceManyToMany(),
		new AdminBookingReferenceManyToMany(),
		new InvoicedUserBookingReferenceManyToMany(),
		new FixedPriceUserBookingReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public noteId: string;

	@observable
	@attribute()
	public bookingId: string;

	@observable
	@attribute({ isReference: true })
	public note: Models.NotesEntity;

	@observable
	@attribute({ isReference: true })
	public booking: Models.BookingEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<INoteBookingAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.noteId) {
				this.noteId = attributes.noteId;
			}
			if (attributes.bookingId) {
				this.bookingId = attributes.bookingId;
			}

			if (attributes.note) {
				if (attributes.note instanceof Models.NotesEntity) {
					this.note = attributes.note;
					this.noteId = attributes.note.id;
				} else {
					this.note = new Models.NotesEntity(attributes.note);
					this.noteId = this.note.id;
				}
			} else if (attributes.noteId !== undefined) {
				this.noteId = attributes.noteId;
			}

			if (attributes.booking) {
				if (attributes.booking instanceof Models.BookingEntity) {
					this.booking = attributes.booking;
					this.bookingId = attributes.booking.id;
				} else {
					this.booking = new Models.BookingEntity(attributes.booking);
					this.bookingId = this.booking.id;
				}
			} else if (attributes.bookingId !== undefined) {
				this.bookingId = attributes.bookingId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
