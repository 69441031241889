import React, { ReactNode, useEffect, useState } from 'react';
import { Button } from 'Views/Components/Button/Button';
import classNames from 'classnames';
import { TextField } from 'Views/Components/TextBox/TextBox';
import If from 'Views/Components/If/If';
import { SearchResultOption } from 'Views/Components/_HumanWritten/Search/SearchResultOption';
import { UserEntity } from 'Models/Entities';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { userSearchFetch } from 'Util/_HumanWritten/UserSearchFetch';
import useStore from 'Hooks/useStore';

export function Search() {
	const store = useStore();
	const [hideInput, setHideInput] = useState<boolean>(true);
	const [searchText, setSearchText] = useState<string>('');
	const [users, setUsers] = useState<UserEntity[] | null>(null);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);

	const searchUsers = (text: string) => {
		userSearchFetch(text)
			.then(results => {
				setUsers(results.map(x => new UserEntity(x)));
			})
			.catch(err => {
				setUsers([]);
			});
	};

	const model = {
		text: searchText,
	};
	const buttonClasses = ['search-nav-bar__search-btn'];
	const inputClasses = ['search-nav-bar__input'];

	if (hideInput) {
		inputClasses.push('hidden');
	} else {
		buttonClasses.push('fixed-pos');
	}

	const debouncedUserSearch = React.useCallback(AwesomeDebouncePromise(searchUsers, 500), []);

	useEffect(() => {
		if (searchText !== '') {
			debouncedUserSearch(searchText);
		}
	}, [searchText]);

	const userRender: ReactNode[] = [];
	if (users !== null && users.length > 0) {
		users.forEach(user => {
			userRender.push(
				<SearchResultOption
					key={user.id}
					id={user.id}
					name={user.getFullName() ?? ''}
					email={user.email}
					onClick={() => {
						setHideInput(true);
						setSearchText('');
						setUsers(null);
						setSelectedIndex(0);
					}}
				/>,
			);
		});
	}

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			className="search-nav-bar"
			onClick={event => {
				event.stopPropagation();
				event.preventDefault();
			}}
		>
			<Button
				onClick={event => {
					event.stopPropagation();
					event.preventDefault();
					setHideInput(false);
				}}
				className={classNames(buttonClasses)}
				icon={{ icon: 'search', iconPos: 'icon-left' }}
			/>
			<If condition={!hideInput}>
				<TextField
					model={model}
					modelProperty="text"
					placeholder="Search"
					className={classNames(inputClasses)}
					clickToClear
					id="search-input"
					autoFocus
					onClickToClear={event => {
						event.stopPropagation();
						event.preventDefault();
						setHideInput(true);
						setSearchText('');
						setUsers(null);
						setSelectedIndex(0);
					}}
					onAfterChange={event => {
						event.stopPropagation();
						event.preventDefault();
						setSearchText(event.target.value);
					}}
				/>
				<If condition={users !== null}>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<div
						className="search-nav-bar__results"
						onClick={event => {
							event.preventDefault();
							event.stopPropagation();
						}}
					>
						<If condition={users?.length === 0}>
							<div className="search-nav-bar__result disabled">
								<p>
									No results
								</p>
							</div>
						</If>
						{userRender}
					</div>
				</If>
			</If>
		</div>
	);
}
