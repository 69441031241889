import React from 'react';
import { BookingEntity, RouteEntity } from 'Models/Entities';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TransactionStatusRender } from 'Views/Components/_HumanWritten/StaffBookings/TransactionStatusRender';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { getTruncatedPrice } from 'Util/_HumanWritten/PriceUtils';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import { BookingListSummaryDto } from '../../../../Util/_HumanWritten/Bookings/FetchBookingsWithFilters';
import { bookingType } from '../../../../Models/Enums';

export interface BookingRenderProps {
	booking: BookingEntity;
}

function getBookingUserName(summary: BookingSummary | null): string {
	return isNotNullOrUndefined(summary)
		? `${summary.userFirstName} ${summary.userLastName}`
		: '-';
}

function getTripDateTimeString(date: Date): string {
	const militaryTime = whiteLabelStore.config.timeFormat === 'TWENTY_FOUR_HOUR_TIME';

	return isNotNullOrUndefined(date)
		? moment(date).format(militaryTime ? 'DD/MM/YY HH:mm' : 'DD/MM/YY h:mmA')
		: '-';
}

function getRouteString(route: RouteEntity | null): string {
	return isNotNullOrUndefined(route) && isNotNullOrUndefined(route.departure) && isNotNullOrUndefined(route.destination)
		? `${route.departure.shortName} > ${route.destination.shortName}`
		: '-';
}

function getCreatedDateString(booking: BookingListSummaryDto): string {
	return moment(booking.createdDate).format('DD/MM/YY');
}

function getAmountString(
	booking: BookingListSummaryDto,
): string {
	if (booking.status === 'CANCELLED') {
		return '-';
	}

	const totalPrice = getTruncatedPrice(booking.totalCost, true).toFixed(2);
	let paidPrice = getTruncatedPrice(booking.paidPrice, true).toFixed(2);
	if (booking.status === 'INVOICED') {
		paidPrice = totalPrice;
	}

	return `${paidPrice}/${totalPrice}`;
}

function getTypeString(typeOfBooking: bookingType) {
	// eslint-disable-next-line no-nested-ternary
	if (typeOfBooking === 'PASSENGER_ONLY') {
		return 'PAX';
	}

	if (typeOfBooking === 'EVENT_BOOKING') {
		return 'EVENT';
	}

	return whiteLabelStore?.config?.vehicleShortName !== ''
		? whiteLabelStore?.config?.vehicleShortName
		: 'VH';
}

export function BookingRender({
	booking,
}: BookingRenderProps) {
	if (isNullOrUndefined(booking.bookingListSummary)) {
		return null;
	}

	const { bookingListSummary } = booking;

	return (
		<tr>
			<td>
				{bookingListSummary.name}
			</td>
			<td className="user-email__column">
				{bookingListSummary.email}
			</td>
			<td>
				{booking.humanReadableId}
			</td>
			<td className="booking-type__column">
				{getTypeString(bookingListSummary.bookingType)}
			</td>
			<td>
				{getRouteString(bookingListSummary.route)}
			</td>
			<td>
				{getTripDateTimeString(bookingListSummary.tripDateTime)}
			</td>
			<td>
				{getCreatedDateString(bookingListSummary)}
			</td>
			<td>
				{getAmountString(bookingListSummary)}
			</td>
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<td className="staff-bookings__status-cell">
				<TransactionStatusRender status={bookingListSummary.status} />
			</td>
			<td className="view-button__column">
				<Link
					to={`/bookings/${bookingListSummary.id}`}
					className="link-view-booking link-rm-txt-dec hide-underline"
				>
					View
				</Link>
			</td>
		</tr>
	);
}
