import React from 'react';

import { AdditionalBookingSectionEntity } from 'Models/Entities';
import { tripDirectionTypes } from 'Models/Enums';
import { stringIsEmpty } from 'Util/TypeGuards';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import If from 'Views/Components/If/If';
import { Text } from 'Views/Components/Text/Text';
import { AddOnOption } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/AddOn/AddOnOption';

export interface AddOnSectionProps {
	addOnSection: AdditionalBookingSectionEntity;
	direction: tripDirectionTypes;
}

export function AddOnSection({ addOnSection, direction }: AddOnSectionProps) {
	const addOns = addOnSection.additionalBookingOptions.sort(compareFnByNumberAsc(x => x.order));

	return (
		<div className="mb-xs section">
			<Text className="mb-xxs section-name" size="xs">
				{addOnSection.name}
			</Text>
			<If condition={!stringIsEmpty(addOnSection.information)}>
				<Text className="mb-xs section-info" size="xs" color="gray">
					{addOnSection.information}
				</Text>
			</If>
			{addOns.map(x => (
				<AddOnOption key={x.id} addOn={x} direction={direction} />
			))}
		</div>
	);
}
