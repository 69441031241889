import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'Views/Components/Button/Button';
import { runInAction } from 'mobx';

export interface StyledRadioButtonProps {
	radioButtonOneLabel: string;
	radioButtonTwoLabel: string;
	paymentMethod: paymentMethodType,
	setPaymentMethod: (paymentMethod: paymentMethodType) => void;
}

export type paymentMethodType = 'Cash' | 'Eftpos';

function StyledRadioButton({
	radioButtonOneLabel, radioButtonTwoLabel, paymentMethod, setPaymentMethod,
}: StyledRadioButtonProps) {
	return (
		<>
			<div
				className="payment-type-btn-group"
			>
				<Button
					className={`cash-button ${paymentMethod === 'Cash'
						? 'selected-btn'
						: 'unselected-btn'}`}
					onClick={() => {
						runInAction(() => {
							setPaymentMethod('Cash');
						});
					}}
				>
					{radioButtonOneLabel}
				</Button>
				<Button
					className={`eftpos-button ${paymentMethod === 'Eftpos'
						? 'selected-btn'
						: 'unselected-btn'}`}
					onClick={() => {
						runInAction(() => {
							setPaymentMethod('Eftpos');
						});
					}}
				>
					{radioButtonTwoLabel}
				</Button>
			</div>
		</>
	);
}

export default observer(StyledRadioButton);
