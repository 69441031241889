import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { GreenCircleTickIcon } from 'Views/Components/_HumanWritten/Icon/GreenTickIcon';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { ToastOptions } from 'react-toastify';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import { FormatPhone } from 'Util/StringUtils';
import {
	BookingEntity,
} from 'Models/Entities';
import { EventDto } from './EventCheckInEntities/EventDto';
import {
	EventCheckInBookingOverviewDto,
	IEventCheckInBookingOverviewDto,
} from './EventCheckInEntities/EventCheckInOverviewDto';
import { getEventCheckInData } from 'Services/Api/_HumanWritten/CheckInService';
import { eventCheckInStore } from './EventContext/EventCheckInContext';

// ==================================================== Models ===================================================== //

export interface DisableContinueState {
	continue: boolean;
}

export enum EventCheckInSorter {
	Default = 'Default',
	Fullname = 'Fullname',
	FullnameDesc = 'FullnameDesc',
}

// ================================================== GQL Fetches ================================================== //

interface FetchDataResult {
	eventDetailDto: EventDto;
	bookingSummaries: EventCheckInBookingOverviewDto[];
}

/**
 * Returns event and booked bookings based on event id.
 */
export async function fetchEventCheckInData(eventId?: string): Promise<FetchDataResult | null> {
	if (!eventId) {
		return new Promise(resolve => resolve(null));
	}

	const response = await getEventCheckInData(eventId);

	// Process entities as a class to access class functions.
	const eventDetails = new EventDto(response?.data.eventDetails);
	const bookings = response?.data?.checkInBookingOverviews
		?.filter((x: any) => isNotNullOrUndefined(x))
		?.map((x: Partial<IEventCheckInBookingOverviewDto> | undefined) => new EventCheckInBookingOverviewDto(x));
	const filteredbookings = filterBookedBookings(bookings);

	return {
		eventDetailDto: eventDetails,
		bookingSummaries: filteredbookings,
	};
}

/**
 * Process has three steps in order:
 * - Filters bookings by BOOKED
 * - Instantiate class for each
 * - Order list by full name
 * @param bookings This list of bookings may not have instantiated classes.
 */
function filterBookedBookings(bookings: EventCheckInBookingOverviewDto[]): EventCheckInBookingOverviewDto[] {
	const bookedBookings = bookings;
	bookedBookings.sort(newSortByFullName());

	return bookedBookings;
}
// =================================================== Reducers ==================================================== //

export function getTotalCustomTicketPassengers(bookings: EventCheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		if (isNullOrUndefined(booking.bookedSummary.selectedTickets)) {
			return currentValue;
		}

		let runningTotal = 0;
		for (const ticket of booking.bookedSummary.selectedTickets) {
			runningTotal += ticket.quantity;
		}

		return currentValue + runningTotal;
	}, 0);
}

export function checkedInCustomTicketPassengers(bookings: EventCheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.checkedIn) {
			return currentValue;
		}

		if (!booking.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		if (isNullOrUndefined(booking.bookedSummary.selectedTickets)) {
			return currentValue;
		}

		let runningTotal = 0;
		for (const ticket of booking.bookedSummary.selectedTickets) {
			runningTotal += ticket.quantity;
		}

		return currentValue + runningTotal;
	}, 0);
}

// ========================================== Table cell transformations =========================================== //

export function transformCheckInStatus(booking: BookingEntity | EventCheckInBookingOverviewDto) {
	if (booking.checkedIn) {
		return <GreenCircleTickIcon />;
	}
	return null;
}

/**
 * If booking has a vehicle, driver name is used.
 *
 * Otherwise user account name is used.
 */
export function transformFullName(booking: BookingEntity | EventCheckInBookingOverviewDto) {
	const name = booking.getFullName(true);

	if (!name) {
		return null;
	}

	return (
		<div className="fullname-cell">
			{name}
		</div>
	);
}

export function transformMobileNumber(booking: EventCheckInBookingOverviewDto) {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	const { primaryPhone } = booking.bookedSummary;

	let phone = null;

	if (stringNotEmpty(primaryPhone)) {
		phone = primaryPhone;
	} else {
		phone = booking.user.phone;
	}

	if (phone === '') {
		return '-';
	}

	return FormatPhone(phone);
}

export function transformEmail(booking: EventCheckInBookingOverviewDto) {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	const { primaryEmail } = booking.bookedSummary;

	let email = null;

	if (stringNotEmpty(primaryEmail)) {
		email = primaryEmail;
	} else {
		email = booking.user.phone;
	}

	if (email === '') {
		return '-';
	}

	return email;
}

export function transformPassengerTicketHeader() {
	const values: string[] = [];
	for (const ticket of eventCheckInStore.eventDetails.customTicketTypes) {
		const value = ticket.singularName[0];
		values.push(value.toString());
	}
	return values.join(' | ');
}

export function transformPassengerTickets(booking: BookingEntity | EventCheckInBookingOverviewDto) {
	const summary = booking.bookedSummary;
	if (!summary) {
		return null;
	}
	const values: string[] = [];
	for (const ticket of eventCheckInStore.eventDetails.customTicketTypes) {
		const value = summary.selectedTickets?.find(x => x.customTicketTypeId === ticket.id)?.quantity ?? 0;
		if (value) {
			values.push(value.toString());
		} else {
			// Use '-' as placeholder when value is undefined or 0
			values.push('-');
		}
	}
	return values.join(' | ');
}

/**
 * Returns first letter of booking's assigned name (vehicle booking uses driver last name,
 * passenger booking uses user's last name).
 */
export function firstLetterLastName(booking: BookingEntity | EventCheckInBookingOverviewDto) {
	const bookingName = booking.getFullName(true) ?? '';
	return bookingName.charAt(0);
}

// ================================================ Sort functions ================================================= //

export type BookingSorter = (a: EventCheckInBookingOverviewDto, b: EventCheckInBookingOverviewDto) => number;
export function newSortByFullName(descending: boolean = false): BookingSorter {
	return (a: EventCheckInBookingOverviewDto, b: EventCheckInBookingOverviewDto) => {
		// Convert to upper case so that it sorts based on letters, not characters
		// e.g. Kate and karlo appears in the same sub-section, otherwise there will be two groups,
		// one for lowercase, another for uppercase
		const fullnameA = a.getFullName()?.toUpperCase() ?? '';
		const fullnameB = b.getFullName()?.toUpperCase() ?? '';
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByFullName(descending: boolean = false): BookingSorter {
	return (a: BookingEntity | EventCheckInBookingOverviewDto, b: BookingEntity | EventCheckInBookingOverviewDto) => {
		// Convert to upper case so that it sorts based on letters, not characters
		// e.g. Kate and karlo appears in the same sub-section, otherwise there will be two groups,
		// one for lowercase, another for uppercase
		const fullnameA = (a?.getFullName(true) ?? '').toUpperCase();
		const fullnameB = (b?.getFullName(true) ?? '').toUpperCase();
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

// ================================================ Other functions ================================================ //

export const CHECK_IN_ALERT_CONFIG: ToastOptions = {
	autoClose: 2200,
	pauseOnFocusLoss: false,
	pauseOnHover: false,
	position: 'top-left',
};

/**
 * Display notification for two actions.
 *
 * If checkedIn is true, message says "John Smith checked in".
 *
 * Otherwise, message says "John Smith undo check-in".
 *
 * Notification will last 2 seconds and appears top-left of screen.
 */
export function checkInAlert(booking: BookingEntity | EventCheckInBookingOverviewDto, checkedIn: boolean) {
	alertToast(
		// Uses driver name, otherwise user name.
		booking.getFullName(true),
		checkedIn ? 'success' : undefined,
		checkedIn ? 'Checked in' : 'Undo check-in',
		CHECK_IN_ALERT_CONFIG,
	);
	if (checkedIn) {
		PlayCheckInAudio();
	}
}
