import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import Icon from '../../../Icon/Icon';
import { BookingEntity } from '../../../../../../Models/Entities';
import If from '../../../../If/If';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { EditType } from '../BookingDetailedView';

export interface SectionProps {
	booking: BookingEntity;
	canEdit: boolean;
	onCancel?: () => void;
	onEdit: (editType: EditType,) => void;
	isInvoiced?: boolean | null;
}

export interface DetailSectionProps {
	icon: IconProps;
	sectionName: string;
	sectionContent: JSX.Element;
	sectionClassName?: string;
	modificationRules: ModificationProps;
}

export interface ModificationProps {
	canEdit: boolean;
	onCancel?: () => void;
	onEdit: (editType: EditType) => void;
	editType: EditType;
	isInvoiced?: boolean | null;
}

export interface IconProps {
	name?: string;
	classname?: string;
	overrideIcon?: JSX.Element;
}

export function BookingDetailSection({
	sectionName,
	sectionContent,
	sectionClassName,
	modificationRules,
	icon,
}: DetailSectionProps) {
	const {
		canEdit,
		isInvoiced,
		onEdit,
		editType,
	} = modificationRules;

	return (
		<div className={`booking-detail-section__container ${sectionClassName}-section__container`}>
			<div className="booking-detail-section__header-container">
				<If condition={isNotNullOrUndefined(icon.overrideIcon)}>
					{icon.overrideIcon}
				</If>
				<If condition={isNullOrUndefined(icon.overrideIcon) && isNotNullOrUndefined(icon.name)}>
					<Icon name={icon.name!} classname={`booking-detail-section__icon ${icon.classname}`} />
				</If>
				<p className="booking-detail-section__header">{sectionName}</p>
			</div>
			<div className="booking-detail-section__body">{sectionContent}</div>
			{canEdit && !isInvoiced && (
				<Button
					className="booking-detail-section__edit-button"
					onClick={() => onEdit(editType)}
				>
					Edit
				</Button>
			)}
		</div>
	);
}
