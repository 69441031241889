import * as React from 'react';
import {
	RouteComponentProps,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import queryString from 'querystring';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { SecuredGroups } from 'Models/Security/UserGroups';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { FEATURE_IMAGE_2_URL } from 'Constants';
import BookingWizardBackground from 'Views/Components/_HumanWritten/BookingWizardBG/BookingWizardBackground';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { PostPayment } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/PostPayment/PostPayment';
import StripePayment from '../Components/_HumanWritten/Payment/StripePayment';
/* eslint-disable camelcase */

export interface PaymentRouteParams {
	id: string;
}

function PaymentPage(props: RouteComponentProps<PaymentRouteParams>) {
	const {
		match,
		location,
		history,
		staticContext,
	} = props;

	const {
		match: {
			params: { id: transactionId },
		},
	} = props;

	const params = queryString.parse(location.search.substring(1));
	const {
		AccessCode,
		RedirectUrl,
		ErrorCode,
		payment_intent,
	} = params;

	const onSuccess = () => {
		alertToast('Successfully created gift voucher', 'success');
		store.routerHistory.replace(RedirectUrl as string ?? '/home');
	};

	const onPaymentFailed = (errorCode?: string) => {
		let newParams = `?RedirectUrl=${RedirectUrl}`;
		if (errorCode) {
			newParams += `&ErrorCode=${errorCode}`;
		}

		store.routerHistory.replace({
			search: newParams,
		});

		console.error(errorCode);
	};

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<NavigationWrapper
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className="body-content">
				<BookingWizardBackground src={FEATURE_IMAGE_2_URL} />
				{((AccessCode && typeof AccessCode === 'string')
					|| (payment_intent && typeof payment_intent === 'string')) && (
					<PostPayment
						className="payment-page"
						onSuccess={onSuccess}
						onPaymentFailed={onPaymentFailed}
						paymentIntentId={payment_intent.toString()}
					/>
				)}
				{!payment_intent && (
					<StripePayment
						className="payment-page"
						transactionId={transactionId}
						redirectUrl={RedirectUrl as string}
						errorCode={ErrorCode as string | undefined}
						isEventBooking={false}
					/>
				)}
			</div>
		</SecuredPage>
	);
}

export default observer(PaymentPage);
