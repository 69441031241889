export function delayPromise(milli: number = 1000): Promise<void> {
	return new Promise<void>(resolve => setTimeout(resolve, milli));
}

/**
 * This is used to add a minimum time to complete a promise. Also useful to keep this in a seperate
 * file for ease of mocking.
 */
export function delayPromiseAll<T>(milli: number, promise: Promise<T>) {
	return Promise.all([
		promise,
		delayPromise(milli),
	]);
}
