import * as React from 'react';
import { showModal } from './Base/BaseModalContents';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';

export type CheckInDiscardChangesModalContentsProps = Pick<DualActionModalContentProps, 'onConfirm'>;

export function CheckInDiscardChangesModalContents({
	onConfirm,
}: CheckInDiscardChangesModalContentsProps) {
	return (
		<DualActionModalContent
			title="Discard changes"
			body="Are you sure you want to discard your unsaved changes?"
			onConfirm={onConfirm}
			fixedCancelWidth
		/>
	);
}

export function showCheckInDiscardChangesModal(props: CheckInDiscardChangesModalContentsProps) {
	showModal({
		key: 'add-move-check-in-booking',
		content: <CheckInDiscardChangesModalContents {...props} />,
	});
}
