import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import passengerTypeStore from 'Models/PassengerTypeStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import Icon from '../../../../Icon/Icon';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';

export function PassengerInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	usePassengerTypes();

	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const description = passengerTypeStore.getPassengerInfoFromBookingSummary(booking.bookedSummary);
	const sectionContent = (
		<p className="passengers-on-trip">
			{description}
		</p>
	);

	return (
		<BookingDetailSection
			sectionName="Passengers"
			sectionContent={sectionContent}
			sectionClassName="passengers"
			icon={{
				name: 'person',
				classname: 'person',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'passenger',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
