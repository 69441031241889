import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface CloseIconButtonProps {
	className?: string;
	onClick?: () => void;
	isDisabled?: boolean;
}

export function CloseIconButton({
	className,
	onClick,
	isDisabled = false,
}: CloseIconButtonProps) {
	return (
		<button className={classNames('close-icon-btn', className)} onClick={onClick} disabled={isDisabled}>
			<Icon name="cross" />
		</button>
	);
}
