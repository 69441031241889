import { Alignment, ButtonGroup } from '../../../../../Button/ButtonGroup';
import { Button } from '../../../../../Button/Button';
import { wizardModeOptions } from '../../../../../../../Models/Enums';
import * as React from 'react';
import { TripDetailsModel } from './TripDetails';

export interface TripTypeButtonsProps {
	typeSelected: string;
	onTabSelected: (direction: 'one way' | 'return') => void;
	model: TripDetailsModel;
}

export function TripTypeButtons({
	typeSelected,
	onTabSelected,
	model,
}: TripTypeButtonsProps) {
	return (
		<ButtonGroup className="trip-type-btn-group" alignment={Alignment.HORIZONTAL}>
			<Button
				className={typeSelected === 'one way' ? 'selected-btn' : 'unselected-btn'}
				onClick={() => onTabSelected('one way')}
				disabled={model.wizardData.wizardMode === wizardModeOptions.ALTERATION}
			>
				ONE WAY
			</Button>
			<Button
				className={typeSelected === 'return' ? 'selected-btn' : 'unselected-btn'}
				onClick={() => onTabSelected('return')}
				disabled={model.wizardData.wizardMode === wizardModeOptions.ALTERATION}
			>
				RETURN
			</Button>
		</ButtonGroup>
	);
}
