import React from 'react';
import classNames from 'classnames';

type TextSizeCustom = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
type TextWeight = 'normal' | '500' | '600' | '700';
export type TextColor = 'grey-7' | 'high-emphasis' | 'gray';
type TextWordBreak = 'normal' | 'break-all' | 'keep-all' | 'break-word';
type TextLineHeight = 1;

export interface TextProps {
	children: React.ReactNode;
	color?: TextColor;
	weight?: TextWeight;
	size?: TextSizeCustom;
	wordBreak?: TextWordBreak;
	lineHeight?: TextLineHeight;
	className?: string;
	innerProps?: React.HTMLAttributes<HTMLParagraphElement>;
}

export function Text(props: TextProps) {
	const {
		children,
		color,
		size: sizeProp,
		weight: weightProp,
		wordBreak: wordBreakProp,
		lineHeight: lineHeightProp,
		className,
		innerProps,
	} = props;

	const weight = weightProp
		? `weight-${weightProp}`
		: '';
	const size = sizeProp
		? `text-size-${sizeProp}`
		: '';
	const wordBreak = wordBreakProp
		? `text-word-break-${wordBreakProp}`
		: '';
	const lineHeight = lineHeightProp
		? `text-line-height-${lineHeightProp}`
		: '';

	const clazz = [
		'text',
		'm-none',
		'p-none',
		weight,
		size,
		wordBreak,
		lineHeight,
		{ 'grey-7': color === 'grey-7' || color === 'gray' },
		{ 'high-emphasis': color === 'high-emphasis' },
		className,
	];

	return (
		<p {...innerProps} className={classNames(clazz)}>
			{children}
		</p>
	);
}
