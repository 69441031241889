import React from 'react';

import { ScannerResultData } from '../../Types/scannerResultState';
import { Badge } from '../../../FerryCheckIn/CheckInTable/Badge';

type DetailsProps = Pick<
	ScannerResultData,
	| 'driver'
	| 'cargoId'
	| 'firstTimer'
	| 'tag'
	| 'passengers'
	| 'cargoLengthLabel'
	| 'cargoLengthValue'
	| 'towOnLengthLabel'
	| 'towOnLengthValue'
	| 'addOns'
>;

export default function Details({
	driver,
	cargoId,
	firstTimer,
	tag,
	passengers,
	cargoLengthLabel,
	cargoLengthValue,
	towOnLengthLabel,
	towOnLengthValue,
	addOns,
}: DetailsProps) {
	return (
		<>
			<div className="scanner-result__detail">
				<p className="scanner-result__label">Driver</p>
				<div className="row">
					<h3>{driver}</h3>
					{firstTimer && <Badge as="span" text="NEW" />}
					{tag && <Badge as="span" text={tag} />}
				</div>
			</div>
			<div className="scanner-result__detail">
				<p className="scanner-result__label">Rego</p>
				<h4>{cargoId}</h4>
			</div>
			<div className="scanner-result__detail">
				<p className="scanner-result__label">Passengers</p>
				<h4>{passengers}</h4>
			</div>
			{cargoLengthValue && cargoLengthLabel && (
				<div className="scanner-result__detail">
					<p className="scanner-result__label">Vehicle</p>
					<h6>{`${cargoLengthLabel} (${cargoLengthValue})`}</h6>
				</div>
			)}
			{towOnLengthValue && towOnLengthLabel && (
				<div className="scanner-result__detail">
					<p className="scanner-result__label">Trailer</p>
					<h6>{`${towOnLengthLabel} (${towOnLengthValue})`}</h6>
				</div>
			)}
			{addOns.length > 0 && (
				<div className="scanner-result__detail">
					<p className="scanner-result__label">Add ons</p>
					{addOns.map(({ label, quantity }) => (
						<div className="scanner-result__detail" key={label}>
							<h6>{`${quantity}x ${label}`}</h6>
						</div>
					))}
				</div>
			)}
		</>
	);
}
