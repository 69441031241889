import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { DisplayType } from 'Views/Components/Models/Enums';
import React from 'react';

interface AcceptTandCsCheckboxProps {
	checked: boolean,
	setChecked: (checked: boolean) => void;
}

export function AcceptTandCsCheckbox({ checked, setChecked }: AcceptTandCsCheckboxProps) {
	return (
		<Checkbox
			className="t-and-cs-checkbox-wrapper"
			model={{ accepted: checked }}
			modelProperty="accepted"
			label="I accept all of the above Terms and Conditions"
			displayType={DisplayType.BLOCK}
			onChecked={(event, value) => {
				setChecked(value);
			}}
		/>

	);
}
