import React, { useEffect } from 'react';
import { UserEntity } from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import {
	getEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	CustomLocationState,
	isRegisterCustomerLocationState,
} from 'Models/_HumanWritten/LocationState';
import {
	WizardErrors,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { stringNotEmpty } from 'Util/TypeGuards';
import { EventBookingWizardData } from '../../EventsBookingWizardData';
import EventCustomerSelection from '../../../CustomerSelection/EventCustomerSelection';

export interface EventCustomerTabProps {
	wizardData: EventBookingWizardData;
	onUpdateData: (wizardData: EventBookingWizardData) => void;
	errors: WizardErrors<EventBookingWizardData>;
	locationState?: CustomLocationState;
	onUpdateErrors: (newErrors: WizardErrors<EventBookingWizardData>) => void;
}

function EventCustomerTab({
	wizardData,
	errors,
	locationState,
	onUpdateErrors,
	onUpdateData,
}: EventCustomerTabProps) {
	const userId = useLocalStore<{ value: string }>(() => {
		// Check if the previous route was from RegisterCustomer component
		if (locationState && isRegisterCustomerLocationState(locationState)	&& locationState.userId) {
			if (wizardData.userId !== locationState.userId) {
				// onUpdateData is unnecessary because wizardData.userId can be updated without re-render
				wizardData.userId = locationState.userId;
			}
		}
		return { value: wizardData.userId };
	});

	// If the staff member has already added bookings to the cart,
	// then they will no longer be able to change the customer details
	// (unless they remove all the bookings from the cart)
	const isDisabled = getEventBookingTransactionIdFromStorage() !== null && stringNotEmpty(wizardData.userId);

	useEffect(() => {
		if (wizardData.userId !== userId.value) {
			// onUpdateData is unncessary because wizardData.userId can be updated without re-render
			wizardData.userId = userId.value;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, userId.value]);

	return (
		<div className="booking-wizard-tab customer-selection event-booking">
			<h2 className="event-booking-wizard__tab-header">{isDisabled
				? 'Customer details'
				: 'Who are you booking for?'}
			</h2>
			<div className="customer-selection__details-section">
				<EventCustomerSelection
					model={userId}
					modelProperty="value"
					backUrl="/event-booking-wizard/customer"
					disabled={isDisabled}
					combobox={{
						errors: errors.userId,
						inputProps: {
							upward: false,
						},
						onAfterChange: () => {
							const newErrors = { ...errors };
							delete newErrors.userId;
							onUpdateErrors(newErrors);
						},
					}}
					onSelect={(customer: UserEntity) => {}}
					wizardData={wizardData}
					onUpdateData={onUpdateData}
				/>
			</div>
		</div>
	);
}

export default observer(EventCustomerTab);
