import { action } from 'mobx';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { bookingStatus, bookingTransactionStatus } from 'Models/Enums';
import { UserEntity } from 'Models/Entities';
import { getFullName } from 'Util/StringUtils';
import * as Models from 'Models/Entities';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';

export interface IEventCheckInBookingOverviewDto {
	id: string;
	humanReadableId: string;
	user: UserEntity;
	transactionStatus: bookingTransactionStatus;
	bookingStatus: bookingStatus;
	checkedIn: boolean;
	isInvoiced: boolean;
	bookedSummary: BookingSummary;
}

export class EventCheckInBookingOverviewDto implements IEventCheckInBookingOverviewDto {
	constructor(attributes?: Partial<IEventCheckInBookingOverviewDto>) {
		this.assignAttributes(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	private assignAttributes(attributes?: Partial<IEventCheckInBookingOverviewDto>) {
		if (isNullOrUndefined(attributes)) {
			return;
		}
		if (isNotNullOrUndefined(attributes.id)) {
			this.id = attributes.id;
		}
		if (isNotNullOrUndefined(attributes.humanReadableId)) {
			this.humanReadableId = attributes.humanReadableId;
		}
		if (isNotNullOrUndefined(attributes.isInvoiced)) {
			this.isInvoiced = attributes.isInvoiced;
		}
		if (isNotNullOrUndefined(attributes.checkedIn)) {
			this.checkedIn = attributes.checkedIn;
		}
		if (isNotNullOrUndefined(attributes.user)) {
			if (attributes.user === null) {
				this.user = attributes.user;
			} else if (attributes.user instanceof Models.UserEntity) {
				this.user = attributes.user;
			} else {
				this.user = new Models.UserEntity(attributes.user);
			}
		}
		if (isNotNullOrUndefined(attributes.bookedSummary)) {
			//
			// New alteration booking summary section
			//
			this.bookedSummary = new BookingSummary({
				userFirstName: attributes.bookedSummary.userFirstName,
				userLastName: attributes.bookedSummary.userLastName,
				primaryEmail: attributes.bookedSummary.primaryEmail,
				primaryPhone: attributes.bookedSummary.primaryPhone,
				primaryFirstName: attributes.bookedSummary.primaryFirstName,
				primaryLastName: attributes.bookedSummary.primaryLastName,
				selectedTickets: attributes.bookedSummary.selectedTickets,
			});
		}
		if (isNotNullOrUndefined(attributes.bookingStatus)) {
			this.bookingStatus = attributes.bookingStatus;
		}
		if (isNotNullOrUndefined(attributes.transactionStatus)) {
			this.transactionStatus = attributes.transactionStatus;
		}
	}

	id: string;
	humanReadableId: string;
	bookedSummary: BookingSummary;
	user: UserEntity;
	transactionStatus: bookingTransactionStatus;
	bookingStatus: bookingStatus;
	checkedIn: boolean;
	isInvoiced: boolean;

	public getFullName() {
		if (!this.user || !this.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		const { primaryFirstName, primaryLastName } = this.bookedSummary;

		if (stringNotEmpty(primaryFirstName) && stringNotEmpty(primaryLastName)) {
			return getFullName(primaryFirstName, primaryLastName, true);
		}

		return getFullName(this.user.firstName, this.user.lastName, true);
	}

	static createBlank() {
		return new EventCheckInBookingOverviewDto({
			user: new Models.UserEntity(),
			bookedSummary: new BookingSummary({
				userFirstName: '',
				userLastName: '',
				eventId: '',
				primaryEmail: '',
				primaryPhone: '',
				primaryFirstName: '',
				primaryLastName: '',
				selectedTickets: [],
			}),
		});
	}
}
