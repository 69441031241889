import { GiftCertificateUsageEntity } from '../../Models/Entities';

export async function fetchGiftCertificateUsagesByTransactionId(transactionId: string) {
	return GiftCertificateUsageEntity.fetchAndCount<GiftCertificateUsageEntity>({
		args: [
			[
				{
					path: 'transactionId',
					comparison: 'equal',
					value: transactionId,
				},
			],
		],
	},
	`
			giftCertificate {
				id
				code
				expiryDate
				initialValue
				user {
					id
					firstName
					lastName
					email
				}
			}
		`);
}
