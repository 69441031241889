import React, { ReactNode, useEffect, useState } from 'react';
import { fetchStoreSingular } from 'Util/_HumanWritten/FetchStoreSingular';
import Auth from 'Views/Components/Auth/Auth';
import {
	BookingWizardData,
	saveBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import useStore from 'Hooks/useStore';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { useLocation } from 'react-router-dom';
import { setStoredWizardData } from 'Services/Api/_HumanWritten/BookingWizardCacheService';

export interface AuthStepProps {
	wizardData: BookingWizardData;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	children: ReactNode;
}

export function AuthStep({ wizardData, onUpdateWizardData, children }: AuthStepProps) {
	const store = useStore();
	const location = useLocation();

	const [loading, setLoading] = useState(!store.loggedIn);
	const [redisId, setRedisId] = useState<string | null>(null);

	useEffect(() => {
		if (!store.loggedIn) {
			setStoredWizardData(wizardData).then(response => {
				setRedisId(response);
				saveBookingWizardDataCacheToken(response);
				setLoading(false);
			});
		}
	}, []);

	if (loading) {
		return <LottieSpinner />;
	}

	return (
		<Auth
			onAfterSuccess={() => {
				// If that is the value for userId, then it hasn't been set previously, which means that the user
				// wasn't logged in before they successfully authenticated
				if (wizardData.userId === '') {
					const store2 = fetchStoreSingular();
					if (!store2.isAdmin && !store.isStaff && !store2.isManager) {
						const newData = { ...wizardData };
						// default value is only needed because of the typing. If auth was successful then the
						// userId will be set in the store
						newData.userId = store.userId ?? '';
						newData.driverFirstName = store2.userData?.firstName ?? '';
						newData.driverLastName = store2.userData?.lastName ?? '';
						newData.driverPhone = store2.userData?.phone ?? '';

						onUpdateWizardData(newData);
					}
				}
			}}
			redirectLocation={`${location.pathname}${redisId != null ? encodeURIComponent(`?id=${redisId}`) : ''}`}
		>
			{children}
		</Auth>
	);
}
