import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { Button } from 'Views/Components/Button/Button';
import { RenderReportsDateSelectionModal } from '../Modal/ReportsDateSelectionModal';
import { ReportType } from './Reports';

export interface ReportTableItemProps {
	reportTitle: string,
	selectedReportType: ReportType,
	id?: string,
}

function ReportTableLineItem({
	reportTitle,
	selectedReportType,
	id,
}: ReportTableItemProps) {
	const state = useLocalStore(() => ({
		loading: false,
	}));

	const onInternalClick = async () => {
		try {
			await onButtonClick(state, selectedReportType, id);
			runInAction(() => {
				state.loading = false;
			});
		} catch (e: any) {
			runInAction(() => {
				state.loading = false;
			});
		}
	};

	return (
		<tr>
			<td>
				{reportTitle}
			</td>
			<td className="export-button__column">
				<Button
					onClick={onInternalClick}
					className={`link-export-report link-rm-txt-dec hide-underline ${state.loading ? 'disabled' : ''}`}
					disabled={state.loading}
				>
					{state.loading ? 'Downloading...' : 'Export'}
				</Button>
			</td>
		</tr>
	);
}

export default observer(ReportTableLineItem);

async function onButtonClick(state: { loading: boolean }, reportType: ReportType, id?: string) {
	await RenderReportsDateSelectionModal(state, reportType, id);
}
