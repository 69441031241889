import * as React from 'react';
import classNames from 'classnames';
import { store } from 'Models/Store';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';

export interface IConfirmModalOptions {
	/** The text to be placed in the confirm button */
	confirmText?: string;
	/** The text to be placed in the cancel button */
	cancelText?: string
	/** Class name on the modal */
	className?: string;
	/** Class name on the header div */
	headerClassName?: string;
	/** Class name on the message div */
	messageClassName?: string;
	/** Class name on the action div */
	actionClassName?: string;
	/** Class name for the modal overlay */
	overlayClassName?: string;
	/**
	 * If this option is set to true, when the cancel button is pressed the promise is resolved with a value of false
	 * instead being rejected.
	 */
	resolveOnCancel?: boolean;
	/** Boolean indicating if the overlay should close the modal. Defaults to true. */
	shouldCloseOnOverlayClick?: boolean;
	/** Boolean indicating if pressing the esc key should close the modal */
	shouldCloseOnEsc?: boolean;
	/** Function that returns a boolean of whether the model is no closed after click */
	onConfirm?: () => boolean;
}

/**
 * Opens a confirm modal which gives a user an option to confirm or cancel
 * @param title The title on the modal
 * @param message The message to display in the modal
 * @param options Extra options for the modal
 * @returns A promise that is resolved on the user confirming or rejected on the user cancelling
 */
export function modalWithCustomInput(title: string, message: React.ReactNode, options: IConfirmModalOptions = {
	onConfirm: () => true,
}) {
	return new Promise<boolean>((resolve, reject) => {
		const onConfirm = () => {
			if (options.onConfirm?.() ?? true) {
				store.modal.hide();
				resolve(true);
			}
		};

		const onCancel = () => {
			store.modal.hide();
			if (options.resolveOnCancel) {
				resolve(false);
			} else {
				reject();
			}
		};

		const confirmText = options.confirmText ?? 'Confirm';
		const cancelText = options.cancelText ?? 'Cancel';

		const confirmDom = (
			<>
				<div key="header" className={classNames('modal__header', options.headerClassName)}>
					<h2 key="header-item" className="modal__title">{title}</h2>
					<Button
						key="header-cancel"
						className="modal--close"
						icon={{ icon: 'close', iconPos: 'icon-left' }}
						display={Display.Text}
						colors={Colors.Black}
						onClick={onCancel}
						labelVisible={false}
					>
						{cancelText}
					</Button>
				</div>
				<div key="message" className={classNames('modal__message', options.messageClassName)}>
					{message}
				</div>
				<div key="actions" className={classNames('modal__actions', options.actionClassName)}>
					<If condition={options.cancelText !== ''}>
						<Button
							className="modal--cancel"
							key="cancel"
							onClick={onCancel}
							display={Display.Solid}
							colors={Colors.Black}
							sizes={Sizes.Medium}
						>
							{cancelText}
						</Button>
					</If>
					<If condition={options.confirmText !== ''}>
						<Button
							className="modal--confirm"
							key="confirm"
							onClick={onConfirm}
							display={Display.Solid}
							colors={options.cancelText !== '' ? Colors.Secondary : Colors.Black}
							sizes={Sizes.Medium}
						>
							{confirmText}
						</Button>
					</If>
				</div>
			</>
		);

		store.modal.show(title, confirmDom, {
			className: classNames('confirm-modal', options.className),
			overlayClassName: options.overlayClassName,
			shouldCloseOnEsc: options.shouldCloseOnEsc,
			shouldCloseOnOverlayClick: options.shouldCloseOnOverlayClick,
			onRequestClose: onCancel,
		});
	});
}
