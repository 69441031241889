import * as React from 'react';
import { BaseSpaceModal } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Modals/BaseSpaceModal';

export interface NoSpacesModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void;
}

export function NoSpacesModal({
	showModal,
	onClickSelect,
	onClickCross,
}: NoSpacesModalProps) {
	return (
		<BaseSpaceModal
			showModal={showModal}
			onClickCross={onClickCross}
			onClickSelect={onClickSelect}
			label="This event is fully booked"
			buttonText="Okay"
			textToDisplay={[
				'Please select another event or join the waitlist for this event.',
			]}
		/>
	);
}
