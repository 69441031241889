import create from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { isNullOrUndefined } from 'Util/TypeGuards';

interface BookingPrice {
	[bookingId: string]: number; // Booking ID as key, price as value
}

interface UseBookingWizardPricingCacheStoreProps {
	bookingPrices: BookingPrice; // Dictionary of booking IDs and their prices
	setBookingPrice: (bookingId: string, price: number) => void;
	removeBookingPrice: (bookingId: string) => void;
	getBookingPrice: (bookingId: string) => number;
	getTotalBookingPrice: (
		bookingIds: string[] | undefined,
		isBulkBooking: boolean,
		bookingIdToExclude?: string) => number;
	clearAllBookings: () => void;
}

export const useBookingWizardPricingCacheStore = create<
	UseBookingWizardPricingCacheStoreProps,
	[['zustand/persist', unknown]]
>(
	persist(
		(set, get) => ({
			bookingPrices: {},
			setBookingPrice: (bookingId: string, price: number) => {
				set(state => ({
					bookingPrices: {
						...state.bookingPrices,
						[bookingId]: price,
					},
				}));
			},
			removeBookingPrice: (bookingId: string) => {
				const { [bookingId]: _, ...remainingBookings } = get().bookingPrices;
				set({ bookingPrices: remainingBookings });
			},
			getBookingPrice: (bookingId: string) => {
				return get().bookingPrices[bookingId] || 0;
			},
			getTotalBookingPrice: (
				bookingIds: string[] | undefined,
				isBulkBooking: boolean = false,
				bookingIdToExclude?: string) => {
				if (isNullOrUndefined(bookingIds) || isBulkBooking) {
					return 0;
				}
				return bookingIds.reduce((total, bookingId) => {
					if (bookingId === bookingIdToExclude) {
						return total;
					}
					const price = get().bookingPrices[bookingId];
					return total + (price || 0); // Add price if exists, otherwise 0
				}, 0);
			},
			clearAllBookings: () => {
				set({ bookingPrices: {} });
			},
		}),
		{
			name: 'booking-pricing-cache',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);
