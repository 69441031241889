import React, { ReactNode } from 'react';
import { TransactionEntity } from '../../../../Models/Entities';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../../Constants';
import { downloadInvoice, UserInvoicesDto } from '../../../../Services/Api/_HumanWritten/InvoiceService';
import { Button } from '../../Button/Button';

export interface InvoiceRenderProps {
	invoice: UserInvoicesDto;
}

function getCreatedDateFormattedString(
	date: Date,
): string {
	return moment(date).format('DD/MM/YYYY');
}

function getAmountString(
	price: number,
): string {
	return `$${price.toFixed(2)}`;
}

export function InvoiceRender({
	invoice,
}: InvoiceRenderProps) {
	const timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<tr>
			<td className="invoice-createdd-date__column">
				{getCreatedDateFormattedString(invoice.createdDate)}
			</td>
			<td className="invoice-id__column">
				#{invoice.humanReadableId}
			</td>
			<td className="invoice-total__column">
				{getAmountString(invoice.invoiceAmount)}
			</td>
			<td className="download-button__column">
				<Button
					onClick={() => downloadInvoice(invoice.invoiceId, false)}
					className="link-download-invoice link-rm-txt-dec hide-underline"
				>
					Download
				</Button>
			</td>
		</tr>
	);
}
