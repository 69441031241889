import React, { useState } from 'react';
import useStore from 'Hooks/useStore';
import { RegisterCustomerLocationState } from 'Models/_HumanWritten/LocationState';
import { sendActivateAccountRequest } from 'Services/Api/AccountService';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import alertToast from 'Util/ToastifyUtils';
import { CustomLocationState, isCustomerSelectionLocationState } from 'Models/_HumanWritten/LocationState';
import { useHistory } from 'react-router';

export interface RegisterCustomerSentProps {
	email: string;
	userId: string;
}

export function RegisterCustomerSent({ email, userId }: RegisterCustomerSentProps) {
	const history = useHistory();
	const locationState = history.location.state as CustomLocationState;
	const backUrl = locationState && isCustomerSelectionLocationState(locationState) && locationState.backUrl
		? locationState.backUrl
		: undefined;

	const store = useStore();
	const [loading, setLoading] = useState(false);

	const onResend = async () => {
		try {
			setLoading(true);
			await sendActivateAccountRequest(email);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			alertToast('Failed to send verification email', 'error');
		}
	};

	return (
		<div className="register-customer__body register-customer__body--sent">
			<h1>Verification email sent</h1>
			<p className="body--content">
				We&apos;ve sent an email to the customer&apos;s inbox. The customer will need to verify their account
				to access the booking confirmation via the application.
			</p>
			<p>
				<strong>Didn&apos;t get an email?</strong>&nbsp;
				It can take a couple minutes to arrive. You can click below to re-send the email.
			</p>
			<div className="actions actions--col">
				<Button
					display={Display.Solid}
					sizes={Sizes.Medium}
					colors={Colors.Primary}
					buttonProps={{ id: 'back_btn' }}
					onClick={() => {
						//
						// The customer tab requires the userId to auto select the newly created user.
						//
						const registerCustomerlocationState: RegisterCustomerLocationState = { userId: userId };
						store.routerHistory.push(backUrl ?? '/booking-wizard/Customer', registerCustomerlocationState);
					}}
				>
					Back to booking
				</Button>
				<Button
					display={Display.Solid}
					sizes={Sizes.Medium}
					colors={Colors.Secondary}
					buttonProps={{ id: 'resend_btn' }}
					onClick={onResend}
					disabled={loading}
				>
					{loading ? 'Sending...' : 'Re-send verification email'}
				</Button>
			</div>
		</div>
	);
}
