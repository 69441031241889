import { FeeOptionEntity } from 'Models/Entities';
import { wizardModes } from 'Models/Enums';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';

export function GetFilteredFees(
	fees: FeeOptionEntity[],
	tripSummaryLocation: tripSummaryLocationType,
	wizardMode: wizardModes,
	transactionFeeIdsAlreadyApplied: string[],
) {
	return fees
		?.filter(x => !x.disabled)
		?.filter(x => transactionFeeIdsAlreadyApplied?.includes(x.id) === false)
		?.filter(x => {
			return tripSummaryLocation === 'check-in'
				? x.checkInFee
				: x.bookingWizardFee;
		})
		?.filter(x => {
			return wizardMode === 'ALTERATION'
				? x.alterBookingFee
				: x.newBookingFee;
		});
}
