/* eslint-disable no-await-in-loop */
import { isAxiosError, isCancel } from 'axios';
import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useAbortController } from 'Hooks/useAbortController';
import { useBoolean } from 'Hooks/useBoolean';
import alertToast from 'Util/ToastifyUtils';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import LoadingEllipses from 'Views/Components/_HumanWritten/Lottie/LoadingEllipses/LoadingEllipses';
import { Button } from 'Views/Components/Button/Button';
import {
	CameraFrameTopOffset,
	CYCLES_PER_SCANNING_ATTEMPT,
	REGO_CAPTURE_ERROR,
	RegoPlateDimensions,
	SCANNING_THROTTLE_DELAY,
} from '../../Constants';
import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { trackRegoScans } from '../../Monitoring/trackRegoScans';
import { readLicensePlate } from '../../Services/licensePlateService';
import { useScanner } from '../../Stores/useScanner';
import { ScannerActionMap } from '../../Types/scannerAction';
import { captureHorizontallyCentered } from '../../Utils/captureHorizontallyCentered';
import { checkInBookingOverviewDtoToScannerResultData } from '../../Utils/checkInBookingOverviewDtoToScannerResultState';
import { convertCanvasToBase64 } from '../../Utils/convertCanvasToBase64';
import { searchCargoId } from '../../Utils/searchCargoId';
import CameraFrame from '../CameraFrame';

const frame = RegoPlateDimensions;

interface RegoScannerProps {
	videoRef: React.RefObject<HTMLVideoElement>;
	disabled?: boolean;
}

export default function RegoScanner({ videoRef, disabled = true }: RegoScannerProps) {
	const abortControllerRef = useAbortController();
	const { goToCheckIn, goToScannerResult } = useScannerLocation();

	const [detectedTexts, setDetectedTexts] = React.useState<string[]>();
	const [attempts, setAttempts] = React.useState(0);
	const [scanCount, setScanCount] = React.useState(0);

	const dispatch = useScanner(x => x.dispatch);
	const bookings = useScanner(x => x.bookings);

	const capture = () => {
		let canvas: HTMLCanvasElement;
		try {
			canvas = captureHorizontallyCentered(videoRef, frame.width, frame.height, CameraFrameTopOffset);
		} catch (err) {
			console.error('Could not draw image on canvas from video');
			alertToast(REGO_CAPTURE_ERROR, 'error', undefined, { autoClose: 2200 });
			goToCheckIn();
			return null;
		}

		const imageBase64 = convertCanvasToBase64(canvas);
		return imageBase64;
	};

	const { value: isCapturing, on: starCapturing, off: stopCapturing } = useBoolean(false);

	const detectBooking = async (imageBase64: string, signal?: AbortSignal): Promise<void> => {
		try {
			const newDetectedTexts = await readLicensePlate(imageBase64, signal);
			setScanCount(state => state + 1);

			setDetectedTexts(newDetectedTexts);
			const formattedBookings = bookings.map(checkInBookingOverviewDtoToScannerResultData);
			const data = searchCargoId(newDetectedTexts, formattedBookings);
			if (data) {
				goToScannerResult({ data });
				abortControllerRef.current.abort();
			}
		} catch (err) {
			console.error(err);
			if (!isAxiosError(err)) {
				throw new Error('Something went wrong with detectBooking');
			}
			if (isCancel(err)) {
				// handle when user cancelled request quietly
				console.error('User has stopped scanning');
			}
		}
	};

	const executeStep = (signal?: AbortSignal) => {
		const imageBase64 = capture();
		if (!imageBase64) {
			return;
		}

		return new Promise<void>((resolve, reject) => {
			signal?.addEventListener('abort', () => {
				reject(new Error('executeStep aborted'));
			});

			Promise.all([
				new Promise(resolveTimeout => setTimeout(resolveTimeout, SCANNING_THROTTLE_DELAY)),
				detectBooking(imageBase64, signal),
			])
				.then(() => {
					resolve();
				})
				.catch(err => {
					console.error(err);
					reject(err);
				});
		});
	};

	const startCycles = async (signal?: AbortSignal) => {
		for (let i = 0; i < CYCLES_PER_SCANNING_ATTEMPT; i++) {
			if (signal?.aborted) {
				break;
			}
			try {
				await executeStep(signal);
			} catch (err) {
				handleClicks.onStop();
			}
		}
		handleClicks.onStop();
		setAttempts(state => state + 1);
	};

	useEffect(() => {
		if (!isCapturing) {
			return;
		}

		// Setup
		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		startCycles(abortController.signal);

		return () => abortController.abort();
	}, [isCapturing]);

	useEffect(() => {
		return () => {
			if (scanCount > 0) {
				trackRegoScans(scanCount);
			}
		};
	}, []);

	const handleClicks = {
		onClose() {
			goToCheckIn();
		},
		onToggle(e?: React.MouseEvent<HTMLButtonElement>) {
			e?.stopPropagation();
			dispatch({ action: ScannerActionMap.ToggleMode });
		},
		onSearch() {
			goToCheckIn(true);
		},
		onStop() {
			abortControllerRef.current.abort();
			stopCapturing();
		},
		onStart() {
			starCapturing();
		},
	};

	return (
		<>
			<CameraFrame width={frame.width} height={frame.height} topOffset={CameraFrameTopOffset} />
			<button className="check-in-scanner__close-btn" onClick={handleClicks.onClose}>
				<Icon name="cross" classname="check-in-scanner__cross" />
			</button>
			<div className="check-in-scanner__top-anchor">
				<button className="check-in-scanner__switch-btn" onClick={handleClicks.onToggle}>
					Switch to TICKET
				</button>
			</div>
			<h6 className="check-in-scanner__description" style={{ top: `${frame.height + CameraFrameTopOffset}rem` }}>
				Scan rego plate
			</h6>
			<div
				className="check-in-scanner__description"
				style={{ top: `${frame.height + CameraFrameTopOffset + 2}rem` }}
			>
				{detectedTexts !== undefined && (
					<div>
						{detectedTexts.length === 0 && <div>No rego plate found</div>}
						{detectedTexts.length > 0 && (
							<>
								<div>No matches found for:</div>
								{detectedTexts.map(text => (
									<div key={text}>{text}</div>
								))}
							</>
						)}
					</div>
				)}
			</div>
			<div className="check-in-scanner__bottom-anchor">
				<button
					className={classNames('check-in-scanner__capture-btn', {
						'check-in-scanner__capture-btn--start': !isCapturing,
						'check-in-scanner__capture-btn--stop': isCapturing,
					})}
					onClick={isCapturing ? handleClicks.onStop : handleClicks.onStart}
					disabled={disabled}
				>
					{isCapturing && <LoadingEllipses>SCANNING</LoadingEllipses>}
					{!isCapturing && (attempts > 0 ? 'TRY AGAIN' : 'START')}
				</button>
				{attempts > 0 && (
					<Button className="btn--text btn--primary check-in-scanner__search-btn" onClick={handleClicks.onSearch}>
						<Icon name="search" classname="check-in-list__search-icon" />
					</Button>
				)}
			</div>
		</>
	);
}
