import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { FormEventHandler } from 'react';
import { Button, Display } from 'Views/Components/Button/Button';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import alertToast from '../../../Util/ToastifyUtils';
import { changeUserGroups } from 'Services/Api/AccountService';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { IUser } from './UserList';

interface GroupEditModalProps {
	user: IUser;
	onAfterSave: () => void;
	onAfterClose: () => void;
}

function GroupEditModal({ user, onAfterSave, onAfterClose }: GroupEditModalProps) {
	const staffGroups = ['Admin', 'Manager', 'Staff'];
	const alreadyStaff = staffGroups.find(x => user.groups.includes(x)) !== undefined;
	const alreadyInvoicedUser = user.groups.indexOf('InvoicedUser') > -1;

	const model = useLocalStore(() => ({
		admin: user.groups.indexOf('Admin') > -1,
		manager: user.groups.indexOf('Manager') > -1,
		staff: user.groups.indexOf('Staff') > -1,
		invoicedUser: user.groups.indexOf('InvoicedUser') > -1,
	}));

	const onSubmit: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();

		const newRoles = [];
		if (model.admin) {
			newRoles.push('Admin');
		}
		if (model.manager) {
			newRoles.push('Manager');
		}
		if (model.staff) {
			newRoles.push('Staff');
		}

		try {
			await changeUserGroups(user.userName, newRoles);
			alertToast('Updated user permissions', 'success');
		} catch (e) {
			console.error(e);
			alertToast('Failed to update user permissions', 'error');
		}

		onAfterSave();
	};

	return (
		<form onSubmit={onSubmit}>
			<h1>Change User Permissions</h1>
			<Checkbox
				label="Admin"
				model={model}
				modelProperty="admin"
				isDisabled={model.invoicedUser}
			/>
			<Checkbox
				label="Manager"
				model={model}
				modelProperty="manager"
				isDisabled={model.invoicedUser}
			/>
			<Checkbox
				label="Staff"
				model={model}
				modelProperty="staff"
				isDisabled={model.invoicedUser}
			/>

			<ButtonGroup alignment={Alignment.HORIZONTAL}>
				<Button onClick={onAfterClose}>Close</Button>
				<Button type="submit" display={Display.Solid}>Save</Button>
			</ButtonGroup>
		</form>
	);
}

export default observer(GroupEditModal);
