import { AxiosResponse } from 'axios';
import useAsync from 'Hooks/useAsync';
import { store } from 'Models/Store';
import React from 'react';
import { PaymentProviderTransactionDetails, setupPayment } from 'Services/Api/_HumanWritten/PaymentService';
import { isNullOrUndefined } from 'Util/TypeGuards';

export const PAYMENT_FORM_ID = 'payment_form';

export enum PaymentError {
	TIMEOUT,
	ERROR,
	NO_PAYMENT_ERROR,
	NO_ERROR,
}

function usePayment(isEventBooking: boolean, transactionId?: string, redirectUrl?: string) {
	const [error, setError] = React.useState<PaymentError>(PaymentError.NO_ERROR);

	const response = useAsync<PaymentProviderTransactionDetails | undefined>(async () => {
		if (isNullOrUndefined(transactionId)) {
			setError(PaymentError.ERROR);
			return undefined;
		}

		const accessCodePromise = new Promise((resolve, reject) => {
			const postPaymentUrl = `${store.routerHistory.location.pathname}?RedirectUrl=${redirectUrl}`;
			setupPayment(transactionId, postPaymentUrl, isEventBooking).then(details => {
				if (details.status === 201) {
					// 201 means no payment required, so navigate user straight to success page
				}
				const parsedDetails: PaymentProviderTransactionDetails = details.data;
				resolve(parsedDetails);
			}).catch((err: AxiosResponse) => {
				// eslint-disable-next-line dot-notation,no-prototype-builtins
				if (err !== null && err['response'].status === 409) {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(PaymentError.NO_PAYMENT_ERROR);
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(PaymentError.ERROR);
				}
			});
		});

		const fallback = timeoutPromise(30000);

		return Promise.race([accessCodePromise, fallback]).then(details => {
			return details as PaymentProviderTransactionDetails;
		}).catch((e: PaymentError) => {
			setError(error);
			return undefined;
		});
	}, [transactionId]);

	return {
		response,
		error,
	};
}

function timeoutPromise(delay: number) {
	return new Promise((_, reject) => {
		setTimeout(() => {
			// We reject here instead of resolving because this ensures that we don't have to do additional
			// checking in the promise.then to see whether the transaction details are there. It ensures that
			// our one successful scenario is handled in .then and that all potential issues are handled in
			// .catch
			// eslint-disable-next-line prefer-promise-reject-errors
			reject(PaymentError.TIMEOUT);
		}, delay);
	});
}

export default usePayment;
