import { runInAction } from 'mobx';
import { stringIsEmpty } from '../../../Util/TypeGuards';

export type ValidationRule<T, K extends keyof T, V = T[K]> = {
	fieldName: keyof T;
	validation: (value: V) => boolean;
	errorMessage?: string;
};

export function validateField<T>(
	state: T,
	fieldName: keyof T,
	errors: { [K in keyof T]?: string },
	rules: ValidationRule<T, keyof T>[],
): boolean {
	let valid = true;

	const fieldValue = state[fieldName];

	delete errors[fieldName];

	const relevantRules = rules.filter(rule => rule.fieldName === fieldName);

	for (const rule of relevantRules) {
		if (!rule.validation(fieldValue)) {
			runInAction(() => {
				errors[rule.fieldName as keyof T] = stringIsEmpty(rule.errorMessage)
					? 'Required'
					: rule.errorMessage;
			});
			valid = false;
			break;
		}
	}

	return valid;
}

export function hasItemsOrInvalidProps(value: unknown): boolean {
	if (Array.isArray(value)) {
		return value.length > 0;
	}
	if (typeof value === 'object' && value !== null) {
		return Object.keys(value).length > 0;
	}
	return false;
}
