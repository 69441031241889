import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { ScannerResultState } from '../../Types/scannerResultState';
import AlreadyCheckedIn from './AlreadyCheckedIn';
import Confirmation from './Confirmation';

export default function ScannerResult() {
	const { scannerUrl } = useScannerLocation();
	const { state } = useLocation<ScannerResultState>();

	if (!state || !state.data) {
		return <Redirect to={scannerUrl} />;
	}

	const { data } = state;
	const {
		checkedIn,
		id,
		driver,
		cargoId,
		firstTimer,
		tag,
		passengers,
		cargoLengthLabel,
		cargoLengthValue,
		towOnLengthLabel,
		towOnLengthValue,
		addOns,
	} = data;

	if (checkedIn) {
		return (
			<AlreadyCheckedIn
				driver={driver}
				cargoId={cargoId}
				firstTimer={firstTimer}
				tag={tag}
				passengers={passengers}
				cargoLengthLabel={cargoLengthLabel}
				cargoLengthValue={cargoLengthValue}
				towOnLengthLabel={towOnLengthLabel}
				towOnLengthValue={towOnLengthValue}
				addOns={addOns}
			/>
		);
	}

	return (
		<Confirmation
			id={id}
			driver={driver}
			cargoId={cargoId}
			firstTimer={firstTimer}
			tag={tag}
			passengers={passengers}
			cargoLengthLabel={cargoLengthLabel}
			cargoLengthValue={cargoLengthValue}
			towOnLengthLabel={towOnLengthLabel}
			towOnLengthValue={towOnLengthValue}
			addOns={addOns}
		/>
	);
}
