import React, { useEffect, useState } from 'react';
import { ConfirmationToken, StripePaymentElementOptions } from '@stripe/stripe-js';
import { ErrorCard } from '../ErrorCard/ErrorCard';
import If from 'Views/Components/If/If';
import { store } from 'Models/Store';
import { SkipPayment } from '../FerryTripBookingWizard/WizardSteps/Payment/SkipPayment';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { Flex } from 'Views/Components/Flex/Flex';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import useAsync from 'Hooks/useAsync';
import { getPaymentTabTotalAmount } from 'Services/Api/_HumanWritten/TransactionService';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { PAYMENT_FORM_ID } from './usePayment';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import {
	createPaymentIntent,
	PaymentProviderTransactionDetails,
} from 'Services/Api/_HumanWritten/PaymentService';

export interface CheckOutFormProps {
	paymentIntentId: string | null;
	transactionId: string;
	isAlteration: boolean;
	isEventBooking: boolean;
	userId?: string;
	setTabIsLoading: (loading: boolean) => void;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
	disableContinueButton?: boolean;
	tabIsLoading?: boolean;
	redirectUrl: string;
	includeSubmitButtons?: boolean;
	triggerRecalculation: boolean;
	setTriggerRecalculation: (recalculate: boolean) => void;
	setShowSurchargeModal: (showSurchargeModal: boolean) => void;
	showSurchargeModal: boolean;
	setMessage: (message: string | null) => void;
	message: string | null;
}

export function CheckOutForm({
	transactionId,
	paymentIntentId,
	isAlteration,
	isEventBooking,
	userId,
	setTabIsLoading,
	setDisableContinueButton,
	disableContinueButton,
	tabIsLoading,
	redirectUrl,
	includeSubmitButtons = false,
	triggerRecalculation,
	setTriggerRecalculation,
	setShowSurchargeModal,
	showSurchargeModal,
	setMessage,
	message,
}: CheckOutFormProps) {
	const stripe = useStripe();
	const elements = useElements();

	const [loading, setLoading] = useState(true);
	const [totalPrice, setTotalPrice] = useState<number | null>(null);
	const [dynamicSurcharge, setDynamicSurcharge] = useState<number | null>(null);
	const [refetchPrice, setRefetchPrice] = useState<boolean>(false);

	const response = useAsync(async () => getPaymentTabTotalAmount(isEventBooking, transactionId), [refetchPrice]);

	const updateElements = () => {
		setRefetchPrice(!refetchPrice);

		setTriggerRecalculation(!triggerRecalculation);
	};

	const handleStripeNotLoaded = () => {
		setTabIsLoading(false);
		setLoading(false);
	};

	const handleError = (errorMessage: string | null) => {
		setMessage(errorMessage ?? 'An unexpected error occurred.');
		setTabIsLoading(false);
		setLoading(false);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLInputElement>) => {
		e.preventDefault();

		setMessage(null);
		setTabIsLoading(true);
		setLoading(true);
		setShowSurchargeModal(false);

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			handleStripeNotLoaded();
			return;
		}

		const confirmationToken = await createConfirmationToken();
		setTimeout(async () => {
			if (message === null && confirmationToken) {
				const confirmedPaymentMethod = await createNewPaymentIntent(confirmationToken);
				if (isNotNullOrUndefined(confirmedPaymentMethod)) {
					await confirmPayment(confirmationToken, confirmedPaymentMethod.paymentIntentId);
				} else {
					handleStripeNotLoaded();
				}
			} else {
				handleStripeNotLoaded();
			}
		}, 0);
	};

	const createConfirmationToken = async () => {
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			handleStripeNotLoaded();
			return;
		}

		const { error: submitError } = await elements!.submit();
		if (submitError && isNotNullOrUndefined(submitError?.message)) {
			handleError(submitError.message);
			return;
		}

		const { error, confirmationToken } = await stripe.createConfirmationToken({
			elements,
			params: {},
		});

		if (error && (error.type === 'card_error' || error.type === 'validation_error')) {
			handleError(error.message ?? null);
		} else if (error) {
			handleError('An unexpected error occurred.');
		}

		return confirmationToken;
	};

	const createNewPaymentIntent = async (confirmationToken: ConfirmationToken): Promise<PaymentProviderTransactionDetails | null> => {
		try {
			const paymentIntentResponse = await createPaymentIntent(
				transactionId,
				redirectUrl,
				confirmationToken.id,
				isEventBooking);
			return paymentIntentResponse.data as PaymentProviderTransactionDetails;
		} catch (e: any) {
			const errorResponse = e?.response?.data as unknown as {message: string, refresh: boolean};
			if (errorResponse.message.includes('Your selected payment method has updated the surcharge')) {
				if (errorResponse.refresh) {
					updateElements();
				}
				setShowSurchargeModal(true);
			} else {
				handleError(errorResponse.message ?? 'An unexpected error occurred.');
			}
			setRefetchPrice(!refetchPrice);
			return null;
		}
	};

	const confirmPayment = async (confirmationToken: ConfirmationToken, clientSecret: string) => {
		if (!stripe || !elements || !clientSecret || isNotNullOrUndefined(message)) {
			handleStripeNotLoaded();
			return;
		}

		const returnUrl = `${redirectUrl}?AccessCode=${paymentIntentId}`;
		const { error } = await stripe.confirmPayment({
			clientSecret,
			confirmParams: {
				confirmation_token: confirmationToken.id,
				return_url: returnUrl,
			},
		});

		if (error.type === 'card_error' || error.type === 'validation_error') {
			handleError(error.message ?? null);
		} else {
			handleError('An unexpected error occurred.');
		}

		handleStripeNotLoaded();
	};

	useEffect(() => {
		if (response.type === 'data') {
			setTotalPrice(response?.data?.totalPrice ?? 0);
			setDynamicSurcharge(response?.data?.creditCardSurcharge ?? 0);
		}
	}, [response]);

	const paymentElementOptions = {
		layout: 'tabs',
	} as StripePaymentElementOptions;

	return (
		<form
			id={PAYMENT_FORM_ID}
			onSubmit={handleSubmit}
		>
			<h5>Payment details</h5>
			<div className="payment-element__wrap">
				<div className="total-amount__wrap">
					<p className="total-amount__header">
						Total amount
					</p>
					<>
						<If condition={isNotNullOrUndefined(totalPrice)}>
							<p className="total-amount__price">
								{displayAsAud((totalPrice ?? 0) + (dynamicSurcharge ?? 0))}
							</p>
						</If>
						<If condition={isNotNullOrUndefined(dynamicSurcharge)}>
							<p className="credit-card-surcharge__price">
								(includes {displayAsAud(dynamicSurcharge ?? 0)} card fee)
							</p>
						</If>
						<div className={`warning-modal__container ${showSurchargeModal ? '' : 'hidden'}`}>
							<Icon name="info" />
							<p>Your selected payment method has updated the surcharge.</p>
						</div>
					</>
					<div className="seperator" />
				</div>
				<div id="payment-container">
					<PaymentElement
						id="payment-element"
						className={loading ? 'hidden' : ''}
						options={paymentElementOptions}
						onChange={change => {
							if (change.complete) {
								setMessage(null);
								setDisableContinueButton(false);
							} else {
								setMessage(null);
								setDisableContinueButton(true);
							}
						}}
						onFocus={change => {
							if (message !== null) {
								setMessage(null);
							}
						}}
						onBlur={change => {
							if (message !== null) {
								setMessage(null);
							}
						}}
						onReady={() => {
							setLoading(false);
						}}
					/>
					<If condition={loading}>
						<LottieSpinner className="centered" />
					</If>
				</div>
				{/* Show any error or success messages */}
				<ErrorCard
					className={message !== null ? '' : 'hidden'}
					title={message?.includes('surcharge') ? 'Surcharge updated' : 'Transaction could not be processed.'}
					subtitle={`${message}` ?? ''}
				/>
				<If condition={!includeSubmitButtons}>
					<SkipPayment
						transactionId={transactionId}
						bookingForAlteration={isAlteration}
						userId={userId ?? store.userId}
						eventBooking={isEventBooking}
					/>
				</If>
				<If condition={includeSubmitButtons}>
					<Flex className="mt-xs mb-sm gift-payment__buttons" gap="xs">
						<Button
							display={Display.Outline}
							colors={Colors.Black}
							disabled={loading}
							onClick={() => {
								store.routerHistory.replace(redirectUrl ?? '');
							}}
						>
							Cancel
						</Button>
						<Button
							className="width-100"
							display={Display.Solid}
							colors={Colors.Primary}
							type="submit"
							disabled={disableContinueButton || (tabIsLoading ?? false)}
							buttonProps={{
								form: PAYMENT_FORM_ID,
							}}
						>
							{tabIsLoading
								? 'Processing...'
								: 'Pay now'}
						</Button>
					</Flex>
				</If>
			</div>
		</form>
	);
}
