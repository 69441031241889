/**
 * Rectangular shape. Values are in rem.
 */
export const RegoPlateDimensions = {
	width: 20,
	height: 10,
} as const;

/**
 * Square shape. Values are in rem.
 */
export const QrCodeDimensions = {
	width: 20,
	height: 20,
} as const;

/**
 * Distance from top of screen to position the CameraFrame. Value is in rem.
 */
export const CameraFrameTopOffset = 10;

/**
 * The number of cycles when trying to scan rego plates before notifying the user to restart scanning.
 */
export const CYCLES_PER_SCANNING_ATTEMPT = 5;

/**
 * The minimum duration (in milliseconds) between each cycle when trying to scan rego plates.
 */
export const SCANNING_THROTTLE_DELAY = 800;

export const NO_CAMERA_ERROR = 'Camera is not working (e1)';
export const QR_CAPTURE_ERROR = 'Camera is not working (e2)';
export const REGO_CAPTURE_ERROR = 'Camera is not working (e3)';
