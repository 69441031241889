import If from '../../../If/If';
import { isNotNullOrUndefined, stringNotEmpty } from '../../../../../Util/TypeGuards';
import * as React from 'react';

export interface ReadOnlyFieldProps {
	fieldName: string;
	fieldValue: string;
	updatePasswordButton?: React.ReactNode;
}
export function ReadOnlyField({
	fieldName,
	fieldValue,
	updatePasswordButton,
}: ReadOnlyFieldProps) {
	return (
		<div className="readonly-field__container">
			<p className="readonly-field__header">{fieldName}</p>
			<div className="readonly-field__body">
				<p
					className={`readonly-field__value ${fieldName.toLowerCase()}-field`}
					id={`${fieldName.toLowerCase()}-field`}
				>
					{stringNotEmpty(fieldValue) ? fieldValue : '-'}
				</p>
				<If condition={isNotNullOrUndefined(updatePasswordButton)}>
					{updatePasswordButton}
				</If>
			</div>
		</div>
	);
}
