/**
 * Always get back-facing camera first, then fallback to whatever camera is available.
 * @returns The stream from the camera.
 */
export const getUserMedia = async () => {
	try {
		return navigator.mediaDevices.getUserMedia({
			video: {
				facingMode: 'environment',
			},
		});
	} catch (err) {
		// This could still throw error if device has no camera
		return navigator.mediaDevices.getUserMedia({
			video: true,
		});
	}
};
