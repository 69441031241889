import React from 'react';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { BookingEntity } from 'Models/Entities';
import { CheckInListRowDetail } from '../CheckInList/CheckInListRowDetail';
import { compareFnByNumberAsc } from '../../../../../../Util/_HumanWritten/SortUtils';
import { useFilterAddOn } from '../CheckInView';

export interface CheckInListRowPassengersProps {
	booking: BookingEntity | CheckInBookingOverviewDto;
}

export default function AddOnsFilterRowItems({ booking }: CheckInListRowPassengersProps) {
	const { bookedSummary } = booking;

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	if (isNullOrUndefined(bookedSummary)) {
		return <></>;
	}

	return (
		<>
			{bookedSummary.additionalBookingOptions
				.filter(x => addOnsModel.filteredAddOns
					.map(y => y.id)
					.includes(x.option.additionalBookingSectionId))
				.sort(compareFnByNumberAsc(x => x.option.order))
				.map(x => {
					const addOn = bookedSummary.additionalBookingOptions
						.find(a => a.option.id === x.option.id);

					if (isNullOrUndefined(addOn) || (addOn?.quantity ?? 0) === 0) {
						return <React.Fragment key={x.option.id} />;
					}

					const label = addOn?.option?.abbreviation ?? addOn?.option?.name;
					return (
						<CheckInListRowDetail
							key={x.option.id}
							label={label}
							value={addOn?.quantity}
							className={`row-detail row-detail-${label}`}
						/>
					);
				})}
		</>
	);
}
