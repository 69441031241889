import * as React from 'react';
import {
	useEffect, useRef, useState,
} from 'react';
import MapHandler, { ILocationAndRoutes } from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapHandler';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';

export interface IRouteMapProps {
	locationsAndRoutes: ILocationAndRoutes;
	currentRoute?: string;
	isMobile?: boolean;
}

const RouteMap = ({ locationsAndRoutes, currentRoute, isMobile }: IRouteMapProps) => {
	const backgroundCanvas = useRef<HTMLCanvasElement>(null);
	const foregroundCanvas = useRef<HTMLCanvasElement>(null);

	const [mapHandler, setMapHandler] = useState<MapHandler>(
		() => new MapHandler(locationsAndRoutes, backgroundCanvas, foregroundCanvas));

	const [isMinimized, setIsMinimized] = useState(isMobile ?? false);

	const [isLoading, setIsLoading] = useState(true);

	// Dispose the map handler when the component is unmounted
	useEffect(() => () => mapHandler?.dispose(), []);

	useEffect(() => {
		if (mapHandler === undefined || currentRoute === undefined) {
			return;
		}

		if (!mapHandler.isReady()) {
			mapHandler.init();
		}

		setIsLoading(false);

		mapHandler.setRoute(currentRoute);
	}, [currentRoute]);

	const setMinimized = (minimized: boolean) => {
		if (mapHandler === undefined || currentRoute === undefined) {
			return;
		}

		// If the map WAS minimized, but now it's not, then we need to set the route
		if (isMinimized && !minimized) {
			mapHandler.clearForegroundCanvas();

			// Wait for the animation to finish
			setTimeout(() => {
				mapHandler.setRoute(currentRoute);
			}, 300);
		}

		setIsMinimized(minimized);
	};

	if (currentRoute === undefined) {
		return null;
	}

	return (
		<div
			className={classNames(
				'map__container',
				{ 'map__container--minimised': isMinimized && isMobile && !isLoading },
				{ error: mapHandler.isError },
			)}
			style={{ display: isLoading ? 'none' : undefined }}
		>
			<If condition={isMobile}>
				<Button
					className="map__button"
					onClick={() => {
						setMinimized(!isMinimized);
					}}
					icon={{ icon: `${isMinimized ? 'minimise-2' : 'expand-2'}`, iconPos: 'icon-left' }}
				/>
			</If>
			<div id="route-map">
				<canvas
					id="route-map-background"
					ref={backgroundCanvas}
				/>
				<canvas
					id="route-map-display"
					ref={foregroundCanvas}
				/>
			</div>
		</div>
	);
};

export default RouteMap;
