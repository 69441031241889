export enum BoardingStatus {
	CHECKED_IN = 'CHECKED_IN',
	ALREADY_CHECKED_IN = 'ALREADY_CHECKED_IN',
	FUTURE_BOOKING = 'FUTURE_BOOKING',
	PAST_BOOKING = 'PAST_BOOKING',
	DIFFERENT_ROUTE = 'DIFFERENT_ROUTE',
	CANCELLED = 'CANCELLED',
	NOT_FOUND = 'NOT_FOUND',
	WRONG_EVENT = 'WRONG_EVENT',
	NOT_EVENT_BOOKING = 'NOT_EVENT_BOOKING',
	NOT_FERRY_BOOKING = 'NOT_FERRY_BOOKING',
}

export interface BoardingResponse {
	status: BoardingStatus;
	departureDateTime?: Date;
	checkedIn?: boolean;
}

export function isBoardingResponse(object: unknown): object is BoardingResponse {
	// IMPORTANT: Need to check for all properties of BoardingResponse
	const fields = ['status'];
	return fields.every(x => Object.prototype.hasOwnProperty.call(object, x));
}
