import React from 'react';
import {
	AdditionalBookingSectionEntity,
	FerryTripEntity,
	CargoTypeEntity, TowOnTypeEntity,
} from 'Models/Entities';
import { ComboboxOption } from 'Views/Components/Combobox/Combobox';
import useCheckInStore from 'Hooks/useCheckInStore';
import useAsync from 'Hooks/useAsync';
import {
	CheckInBookingOverviewDto,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { BookingFormMode } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/BookingForm/BookingForm';

/**
 * These options are used for the dropdown fields of BookingFormEdit component.
 */
export interface BookingFormEditOptions {
	/**
	 * List of all additional booking section entities based on ferry and route of booking.
	 */
	addOnSections: AdditionalBookingSectionEntity[];
	/**
	 * List of all cargo type entities.
	 */
	allCargoTypes: CargoTypeEntity[];
	/**
	 * Value of option is cargo make
	 */
	cargoMakeOptions: ComboboxOption<string>[];
	/**
	 * Value of option is cargo model
	 */
	cargoModelOptions: ComboboxOption<string>[];
	/**
	 * Value of option is tripId
	 */
	departingTrips: ComboboxOption<string>[];
	/**
	 * Value of option is returnTripId
	 */
	returningTrips: ComboboxOption<string>[];
	/**
	 * List of all tow on type entities.
	 */
	allTowOnTypes: ComboboxOption<string>[];
}

export interface OptionsResponse {
	cargoTypes: CargoTypeEntity[];
	addOnSections: AdditionalBookingSectionEntity[];
	towOnTypes: TowOnTypeEntity[];
}

interface useBookingFormOptionsProps {
	booking: CheckInBookingOverviewDto;
	mode: BookingFormMode;
}

function useBookingFormOptions({
	booking,
	mode,
}: useBookingFormOptionsProps) {
	const isEditMode = mode === BookingFormMode.Edit;
	const checkInStore = useCheckInStore();

	const [options, setOptions] = React.useState<BookingFormEditOptions>(() => {
		const departingTrips = [{
			display: checkInStore.ferryTrip.getDisplayTimeDayDate(),
			value: checkInStore.ferryTripId,
		}];

		const returningTrip = booking.returnBooking?.bookedSummary?.ferryTrip;
		const returningTrips = isEditMode && returningTrip
			? [{
				display: new FerryTripEntity(returningTrip).getDisplayTimeDayDate(),
				value: returningTrip.id,
			}] : [];

		return {
			allCargoTypes: [],
			cargoMakeOptions: [],
			cargoModelOptions: [],
			addOnSections: [],
			allTowOnTypes: [],
			departingTrips: departingTrips,
			returningTrips: returningTrips,
		};
	});

	const optionsResponse = useAsync<OptionsResponse, any>(async () => {
		const result = await Promise.all([
			checkInStore.getAllVehicleTypes(),
			checkInStore.getAllAdditionalBookingSections(),
			checkInStore.getAllTowOnTypes(),
		]);

		const [cargoTypes, additionalBookingSections, towOnTypes] = result;
		return {
			cargoTypes: cargoTypes,
			addOnSections: additionalBookingSections,
			towOnTypes: towOnTypes,
		};
	}, []);

	return {
		options, setOptions, optionsResponse,
	};
}

export default useBookingFormOptions;
