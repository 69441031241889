import * as React from 'react';
import Collection from 'Views/Components/Collection/Collection';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { observer } from 'mobx-react';
import useCheckInStore from 'Hooks/useCheckInStore';
import { GetModalContentForCheckIn } from '../CheckInList/CheckInListRow';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { isNotNullOrUndefined, stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import alertToast from 'Util/ToastifyUtils';
import { useEffect, useState } from 'react';
import {
	useFilterAddOn,
	useSearchTerm,
} from '../CheckInView';
import If from 'Views/Components/If/If';
import { CheckInFilters } from '../CheckInFilters';
import { CheckInSearch } from '../CheckInSearch';
import { ScrollToItem } from 'Validators/Functions/HumanWritten/ScrollToElement';
import { filterFerryBookingsByAddOns } from '../Helpers/FilterFerryBookings';
import {
	addOnsHeader,
	firstLetterLastName,
	transformAddOns,
	transformCheckInStatus,
	transformFullName,
	transformMobileNumber,
} from '../CheckInUtils';
import { CheckInNoAddOns, CheckInSearchEmpty } from '../CheckInTable/CheckInSearchEmpty';
import { BookingEntity } from 'Models/Entities';

function AddOnsFilterTable() {
	const checkInStore = useCheckInStore();
	const { ferryTripId, bookings } = checkInStore;

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const {
		model,
	} = useSearchTerm(x => ({
		model: x.model,
	}));

	const [refresh, setRefresh] = useState<number>(1);

	useEffect(() => {
		ScrollToItem('.collection__list');
	}, []);

	const headers = React.useMemo(() => {
		const result: ICollectionHeaderProps<CheckInBookingOverviewDto>[] = [
			{
				name: 'check-in-status',
				className: 'check-in-status-col',
				displayName: ' ',
				transformItem: transformCheckInStatus,
			},
			{
				name: 'name',
				displayName: 'Name',
				transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformFullName(
					item,
					false,
				),
				sortable: false,
				nullValue: '-',
			},
			{
				name: 'phone',
				displayName: 'Mobile',
				transformItem: transformMobileNumber,
				nullValue: '-',
			},
			{
				name: 'add-ons',
				displayName: addOnsHeader(addOnsModel.filteredAddOns.flatMap(x => x.additionalBookingOptions)),
				transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformAddOns(
					item,
					addOnsModel.filteredAddOns.flatMap(x => x.additionalBookingOptions),
				),
				nullValue: '-',
			},
		];

		return result;
	}, []);

	// The extra header count is for the action column
	const headerCount = React.useMemo(() => headers.length + 1, [headers]);

	const beforeRow = (item: CheckInBookingOverviewDto, index: number, arr: CheckInBookingOverviewDto[]): React.ReactNode => {
		if (arr.length === 0) {
			return null;
		}

		const value = comparer(item);

		if (index === 0 || value !== comparer(arr[index - 1])) {
			return (
				<tr key={`subheader-${item.id}`} className="subheader">
					<td colSpan={headerCount}>{value}</td>
				</tr>
			);
		}

		return null;
	};

	const onCheckIn = async (booking: CheckInBookingOverviewDto) => {
		if (isNotNullOrUndefined(booking)) {
			if (!booking.checkedIn) {
				await confirmModal('Confirm booking', GetModalContentForCheckIn(booking))
					.then(async () => {
						await checkIn(booking);
					})
					.catch(() => {
						// do nothing
					});
			} else {
				await checkIn(booking);
			}
		}
	};
	const checkIn = async (booking: CheckInBookingOverviewDto) => {
		try {
			await checkInStore.checkInBooking(booking.id, !booking.checkedIn);
			if (booking?.checkedIn) {
				PlayCheckInAudio();
			}
		} catch (e) {
			console.error(e);
			alertToast('Check in unsuccessful', 'error');
		}
	};

	// ================================================== Render =================================================== //

	const displayedBookings = filterFerryBookingsByAddOns({
		bookings,
		searchTerm: model.searchTerm,
		addOnsToFilterBy: addOnsModel.filteredAddOns,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(model.searchTerm) && addOnsModel.filteredAddOns.length === 0) {
		return (
			<>
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={false}
					/>
				</If>
			</>
		);
	}

	let comparer = (x: CheckInBookingOverviewDto) => firstLetterLastName(x).toUpperCase();

	return (
		<>
			<If condition={(displayedBookings.length > 0 && stringIsEmpty(model.searchTerm))
				|| stringNotEmpty(model.searchTerm)}
			>
				<CheckInSearch isVehicleCheckIn />
			</If>
			<If condition={displayedBookings.length === 0 && stringNotEmpty(model.searchTerm)}>
				<CheckInSearchEmpty />
			</If>
			<If condition={displayedBookings.length === 0
				&& addOnsModel.filteredAddOns.length > 0
				&& stringIsEmpty(model.searchTerm)}
			>
				<CheckInNoAddOns />
			</If>
			<If condition={displayedBookings.length > 0}>
				<Collection<CheckInBookingOverviewDto>
					className="check-in__table"
					collection={displayedBookings}
					headers={headers}
					beforeRow={beforeRow}
					hidePagination
					perPage={bookings.length}
				/>
			</If>
			<If condition={checkInStore.showFilters}>
				<CheckInFilters
					setRefresh={setRefresh}
					refresh={refresh}
					vehiclesOnly={false}
				/>
			</If>
		</>
	);
}

export default observer(AddOnsFilterTable);
