import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { isNullOrUndefined } from 'Util/TypeGuards';
import useScreenSize from 'Hooks/useScreenSize';
import EventCheckInDetailsView from './EventCheckInDetails/EventCheckInDetailsView';
import EventCheckInTopNav from './EventCheckInTopNav';
import EventCheckInTabs from './EventCheckInTabs/EventCheckInTabs';
import EventCheckInDetailsSidebar from './EventCheckInDetails/EventCheckInDetailsSidebar';
import {
	Route,
	Switch,
} from 'react-router';

export interface EventCheckInViewState {
	/**
	 * True means bookings displayed have already checked in.
	 * False means the opposite (bookings displayed have not been checked in).
	 */
	showCheckedIn: boolean,
}

function EventCheckInView() {
	const state = useLocalStore<EventCheckInViewState>(() => ({
		showCheckedIn: false,
	}));

	const { isIpadMiniLandscape } = useScreenSize();

	if (isIpadMiniLandscape) {
		return (
			<Switch>
				<Route path="/event-check-in/:id/booking/:id">
					{routerProps => {
						const bookingId = routerProps.match?.params.id;
						if (isNullOrUndefined(bookingId)) {
							return null;
						}
						return <EventCheckInDetailsView bookingId={bookingId} />;
					}}
				</Route>
				<Route path="/event-check-in/:id">
					<div id="check-in-body" className="body-content check-in">
						<EventCheckInTopNav showButtons state={state} />
						<EventCheckInTabs state={state} />
					</div>
				</Route>
			</Switch>
		);
	}

	return (
		<div id="check-in-body" className="body-content check-in">
			<Switch>
				<Route path="/event-check-in/:id/booking/:id">
					{routerProps => {
						const bookingId = routerProps.match?.params.id;
						if (isNullOrUndefined(bookingId)) {
							return null;
						}
						return <EventCheckInDetailsSidebar bookingId={bookingId} />;
					}}
				</Route>
			</Switch>
			<EventCheckInTopNav showButtons state={state} />
			<EventCheckInTabs state={state} />
		</div>
	);
}

export default observer(EventCheckInView);
