import { stringNotEmpty } from 'Util/TypeGuards';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';

export function filterEventBookings(props: {
	bookings: EventCheckInBookingOverviewDto[],
	forCheckIn: boolean,
	searchTerm?: string,
}) {
	const {
		bookings,
		forCheckIn,
		searchTerm,
	} = props;

	let bookingsToFilter = bookings
		.filter(x => {
			if (forCheckIn) {
				return !x.checkedIn;
			}
			return x.checkedIn;
		});

	if (stringNotEmpty(searchTerm)) {
		const lowerCaseSearchTerm = searchTerm.toLowerCase();
		bookingsToFilter = bookingsToFilter.filter(x => {
			return x.humanReadableId.toLowerCase().includes(lowerCaseSearchTerm)
				|| x.bookedSummary?.primaryFirstName?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.bookedSummary?.primaryLastName?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.bookedSummary?.primaryEmail?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.bookedSummary?.primaryPhone?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.user?.firstName?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.user?.lastName?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.user?.phone?.toLowerCase()?.includes(lowerCaseSearchTerm)
				|| x.user?.email?.toLowerCase()?.includes(lowerCaseSearchTerm);
		});
	}
	return bookingsToFilter;
}
