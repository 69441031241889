/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsGiftCertificateEntity from 'Models/Security/Acl/VisitorsGiftCertificateEntity';
import UserGiftCertificateEntity from 'Models/Security/Acl/UserGiftCertificateEntity';
import StaffGiftCertificateEntity from 'Models/Security/Acl/StaffGiftCertificateEntity';
import ManagerGiftCertificateEntity from 'Models/Security/Acl/ManagerGiftCertificateEntity';
import AdminGiftCertificateEntity from 'Models/Security/Acl/AdminGiftCertificateEntity';
import InvoicedUserGiftCertificateEntity from 'Models/Security/Acl/InvoicedUserGiftCertificateEntity';
import FixedPriceUserGiftCertificateEntity from 'Models/Security/Acl/FixedPriceUserGiftCertificateEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGiftCertificateEntityAttributes extends IModelAttributes {
	description: string;
	code: string;
	initialValue: number;
	expiryDate: Date;
	legacyId: number;
	disabled: boolean;
	price: number;

	giftCertificateUsages: Array<
		| Models.GiftCertificateUsageEntity
		| Models.IGiftCertificateUsageEntityAttributes
	>;
	userId?: string;
	user?: Models.UserEntity | Models.IUserEntityAttributes;
	transaction?: Models.TransactionEntity |
		Models.ITransactionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GiftCertificateEntity', 'Gift Certificate')
// % protected region % [Customise your entity metadata here] end
export default class GiftCertificateEntity extends Model
	implements IGiftCertificateEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsGiftCertificateEntity(),
		new UserGiftCertificateEntity(),
		new StaffGiftCertificateEntity(),
		new ManagerGiftCertificateEntity(),
		new AdminGiftCertificateEntity(),
		new InvoicedUserGiftCertificateEntity(),
		new FixedPriceUserGiftCertificateEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@Validators.Required()
	@observable
	@attribute<GiftCertificateEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] on begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<GiftCertificateEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Initial Value'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<GiftCertificateEntity, number>()
	@CRUD({
		name: 'Initial Value',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public initialValue: number;
	// % protected region % [Modify props to the crud options here for attribute 'Initial Value'] end

	// % protected region % [Modify props to the crud options here for attribute 'Expiry Date'] off begin
	@Validators.Required()
	@observable
	@attribute<GiftCertificateEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Expiry Date',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public expiryDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Expiry Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<GiftCertificateEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	/**
	 * Whether or not the gift certificate has been disabled by staff
	 */
	@observable
	@attribute<GiftCertificateEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] off begin
	/**
	 * The amount paid for this gift certificate.
	 */
	@Validators.Numeric()
	@observable
	@attribute<GiftCertificateEntity, number>()
	@CRUD({
		name: 'Price',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate Usage'] off begin
		name: 'Gift Certificate Usages',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.GiftCertificateUsageEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'giftCertificateUsages',
			oppositeEntity: () => Models.GiftCertificateUsageEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate Usage'] end
	})
	public giftCertificateUsages: Models.GiftCertificateUsageEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] off begin
		name: 'Transaction',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.TransactionEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'transactions',
			oppositeEntity: () => Models.TransactionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] end
	})
	public transaction?: Models.TransactionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGiftCertificateEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGiftCertificateEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.initialValue !== undefined) {
				this.initialValue = attributes.initialValue;
			}
			if (attributes.expiryDate !== undefined) {
				if (attributes.expiryDate === null) {
					this.expiryDate = attributes.expiryDate;
				} else {
					this.expiryDate = moment(attributes.expiryDate).toDate();
				}
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.giftCertificateUsages !== undefined && Array.isArray(attributes.giftCertificateUsages)) {
				for (const model of attributes.giftCertificateUsages) {
					if (model instanceof Models.GiftCertificateUsageEntity) {
						this.giftCertificateUsages.push(model);
					} else {
						this.giftCertificateUsages.push(new Models.GiftCertificateUsageEntity(model));
					}
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			if (attributes.transaction !== undefined) {
				if (attributes.transaction === null) {
					this.transaction = attributes.transaction;
				} else if (attributes.transaction instanceof Models.TransactionEntity) {
					this.transaction = attributes.transaction;
				} else {
					this.transaction = new Models.TransactionEntity(attributes.transaction);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		giftCertificateUsages {
			${Models.GiftCertificateUsageEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
			${Models.UserEntity.getFiles().map(f => f.name).join('\n')}
		}
		transaction {
			${Models.TransactionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			giftCertificateUsages: {},
			transaction: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'giftCertificateUsages',
							'transaction',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GiftCertificateEntity.prototype, 'created');
CRUD(modifiedAttr)(GiftCertificateEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
