import { useHistory, useLocation } from 'react-router-dom';

import { ScannerResultState } from '../Types/scannerResultState';

export const ScannerRoutePath = {
	Scanner: '/scan',
	ScannerResult: '/scan/result',
} as const;

export const useScannerLocation = () => {
	const history = useHistory();
	const location = useLocation();
	const checkInBaseUrl = location.pathname
		.split('/')
		.filter(x => x !== 'scan' && x !== 'result')
		.join('/');

	const scannerUrl = `${checkInBaseUrl}${ScannerRoutePath.Scanner}`;
	const scannerResultUrl = `${checkInBaseUrl}${ScannerRoutePath.ScannerResult}`;

	/**
	 * @param autoFocus Focus on the search input on arrival to the check in page.
	 */
	const goToCheckIn = (autoFocus = false) => history.replace(checkInBaseUrl, { autoFocus });
	const goToScanner = () => history.replace(scannerUrl);
	const goToScannerResult = (state: ScannerResultState) => history.push(scannerResultUrl, state);

	return {
		scannerUrl,
		goToCheckIn,
		goToScanner,
		goToScannerResult,
	};
};
