import React from 'react';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { IPriceLineItem } from 'Services/Api/_HumanWritten/PricingService/PricingResult';

export interface AdditionalItemLineItemProps {
	label?: string;
	price?: number;
	lineItem?: IPriceLineItem;
	isAlteration: boolean;
}

export function AdditionalItemLineItem({
	label,
	price,
	lineItem,
	isAlteration,
}: AdditionalItemLineItemProps) {
	const labelToUse = label ?? lineItem?.description ?? '';
	const priceToUse = price ?? lineItem?.price ?? 0;

	return (
		<div className="additional-price__container">
			<p className="additional-item__label">{labelToUse}</p>
			<p className="additional-item__price">
				{formatPriceDisplay(priceToUse, isAlteration, true)}
			</p>
		</div>
	);
}
