import React from 'react';
import { Text } from 'Views/Components/Text/Text';
import { DualActionModalContentProps, DualActionModalContent } from 'Views/Components/_HumanWritten/Modal/Base/DualActionModalContent';
import { Colors } from 'Views/Components/Button/Button';
import DeleteFerryTripStaticTextMemo from 'Views/Components/_HumanWritten/Modal/DeleteFerryTripModal/DeleteFerryTripStaticText';
import DeleteFerryTripBookedBookingsText from 'Views/Components/_HumanWritten/Modal/DeleteFerryTripModal/DeleteFerryTripBookedBookingsText';
import { useDeleteFerryTripModal } from 'Hooks/useDeleteFerryTripModal';
import LoadingEllipses from 'Views/Components/_HumanWritten/Lottie/LoadingEllipses/LoadingEllipses';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';

export interface DeleteFerryTripModalProps extends Pick<DualActionModalContentProps, 'onConfirm'> {
	ferryTripId: string;
}

export function DeleteFerryTripModalContents({
	onConfirm,
	ferryTripId,
}: DeleteFerryTripModalProps) {
	const {
		bookingCountResponse,
		onConfirmInner,
		loading,
	} = useDeleteFerryTripModal(ferryTripId, onConfirm);

	return (
		<DualActionModalContent
			title="Delete trip?"
			body={(
				<>
					<DeleteFerryTripStaticTextMemo />
					{bookingCountResponse.type === 'loading' && (
						<Text color="gray">
							<LoadingEllipses>
								Checking for bookings
							</LoadingEllipses>
						</Text>
					)}
					{bookingCountResponse.type === 'data' && (
						<DeleteFerryTripBookedBookingsText count={bookingCountResponse.data?.count} />
					)}
				</>
			)}
			autoClose
			onConfirm={onConfirmInner}
			fixedCancelWidth
			confirmContent={
				loading
					? 'Deleting...'
					: undefined
			}
			confirmBtnProps={{
				disabled: loading,
				colors: loading ? Colors.Disabled : Colors.Alternate,
			}}
			cancelBtnProps={{
				disabled: loading,
				colors: loading ? Colors.Disabled : Colors.Secondary,
			}}
		/>
	);
}

export function showDeleteFerryTripModal(props: DeleteFerryTripModalProps) {
	showModal({
		key: 'check-in-move-confirmation-booking',
		content: <DeleteFerryTripModalContents {...props} />,
		className: 'base-modal-width-lg',
	});
}
