import React, { useState } from 'react';
import { RegisterCustomerForm } from './RegisterCustomerForm';
import { RegisterCustomerSent } from './RegisterCustomerSent';
import { REGISTRATION_PAGE_TITLE } from '../../../../ConstantPageNames';

export function RegisterCustomer() {
	const [verificationSent, setVerificationSent] = useState(false);
	const [email, setEmail] = useState('');
	const [userId, setUserId] = useState('');

	return (
		<div className="register-customer">
			{
				verificationSent
					? <RegisterCustomerSent email={email} userId={userId} />
					: (
						<RegisterCustomerForm
							next={
								(newEmail, newUserId) => {
									setEmail(newEmail);
									setUserId(newUserId);
									setVerificationSent(true);
								}
							}
						/>
					)
			}
		</div>
	);
}
