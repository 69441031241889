/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';

// % protected region % [Add any extra imports here] on begin
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import BookingCardList from '../Components/_HumanWritten/Bookings/BookingCards/BookingCardList';
import { BOOKINGS_CUSTOMER_PAGE_TITLE, CUSTOMER_BOOKINGS_PAGE_TITLE } from '../../ConstantPageNames';
import {
	BookingDetailedView,
} from '../Components/_HumanWritten/Bookings/BookingDetailedView/BookingDetailedView';

interface RouteParams {
	id?: string
}

function BookingPageInner({ match }: RouteComponentProps<RouteParams>) {
	const { id } = match.params;
	const detailedView = !!id;

	return detailedView
		? <BookingDetailedView bookingId={id} />
		: <BookingCardList />;
}
// % protected region % [Add any extra imports here] end

export interface BookingsPageProps extends RouteComponentProps {
	// % protected region % [Add any extra props here] off begin
	// % protected region % [Add any extra props here] end
}

@observer
// % protected region % [Add any customisations to default class definition here] off begin
class BookingsPage extends React.Component<BookingsPageProps> {
// % protected region % [Add any customisations to default class definition here] end

	// % protected region % [Add class properties here] off begin
	// % protected region % [Add class properties here] end

	render() {
		const {
			match,
			location,
			history,
			staticContext,
			// % protected region % [Destructure any additional props here] off begin
			// % protected region % [Destructure any additional props here] end
		} = this.props;

		// % protected region % [Add logic before rendering contents here] on begin
		document.title = BOOKINGS_CUSTOMER_PAGE_TITLE;
		if (window.loginData) {
			const { userName } = window.loginData;
			if (userName && (userName?.startsWith('staff@')
				|| userName?.startsWith('admin@') || userName?.startsWith('manager@'))) {
				document.title = CUSTOMER_BOOKINGS_PAGE_TITLE;
			}
		}
		// % protected region % [Add logic before rendering contents here] end

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'User', 'Staff', 'Manager', 'Admin', 'InvoicedUser', 'FixedPriceUser']}>
				{
				// % protected region % [Alter navigation here] on begin
				}
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{
				// % protected region % [Alter navigation here] end
				}
				<div className="body-content">
					{
					// % protected region % [Add code for 09cc2b4b-bed0-472e-ab7e-cf9474b026e5 here] on begin
					}
					<BookingPageInner {...this.props} />
					{
					// % protected region % [Add code for 09cc2b4b-bed0-472e-ab7e-cf9474b026e5 here] end
					}
				</div>
			</SecuredPage>
		);

		// % protected region % [Override contents here] off begin
		// % protected region % [Override contents here] end

		return contents;
	}
}

// % protected region % [Override export here] off begin
export default BookingsPage;
// % protected region % [Override export here] end
