import * as React from 'react';
import { Redirect } from 'react-router';
import BookingDetailedViewInner from './BookingDetailedViewInner';
import { useBookingDetailedView } from 'Hooks/useBookingDetailedView';
import { LottieSpinner } from '../../Lottie/LottieSpinner';

export type EditType = 'vehicle' | 'passenger' | 'trip-time' | 'add-on' | 'event-date-time' | 'custom-ticket' | 'tickets';

export interface BookingDetailsProps {
	bookingId: string;
}

export const BookingDetailedView = ({ bookingId }: BookingDetailsProps) => {
	const { response } = useBookingDetailedView(bookingId);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	if (!response.data) {
		return <Redirect to="/404" />;
	}

	return <BookingDetailedViewInner booking={response.data} refresh={response.refresh} />;
};
