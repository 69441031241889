import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';

interface BookingFormGroupHeaderConfig {
	model: any;
	modelProperty: string;
	disabled?: boolean;
	onAfterChange?: (value?: boolean) => void;
	showCloseBtn?: boolean;
	onClose?: () => void;
}

interface BookingFormGroupHeaderProps {
	id?: string;
	title: string;
	children?: React.ReactNode;
	config?: BookingFormGroupHeaderConfig;
	className?: string;
}

function BookingFormGroupHeader({
	id,
	title,
	children,
	config,
	className,
}: BookingFormGroupHeaderProps) {
	if (config === undefined) {
		return (
			<div id={id} className={classNames('booking-form__group', className)}>
				<div className="booking-form__subheader">
					{title}
				</div>
				{children}
			</div>
		);
	}

	return (
		<div id={id} className={classNames('booking-form__group', className)}>
			<Checkbox
				className="column-1"
				model={config.model}
				modelProperty={config.modelProperty}
				label={(
					<div className="booking-form__subheader">
						{title}
					</div>
				)}
				onAfterChecked={(_, value) => {
					if (config.onAfterChange) {
						config.onAfterChange(value);
					}
				}}
				isDisabled={config.disabled}
			/>
			{config.showCloseBtn && !config.disabled && (
				<Button
					className={classNames('close-button icon-close icon-right column-2')}
					colors={Colors.None}
					display={Display.Text}
					sizes={Sizes.ExtraLarge}
					onClick={() => {
						if (config.onClose) {
							config.onClose();
						}
					}}
				/>
			)}
			{config.model[config.modelProperty] && children}
		</div>
	);
}

export default observer(BookingFormGroupHeader);
