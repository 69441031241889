import { store } from 'Models/Store';
import { alertModal, confirmModal } from 'Views/Components/Modal/ModalUtils';

export async function ProcessResults(res: string, cancellation: boolean): Promise<boolean> {
	if (res.includes('departed') || res.includes('checked in')) {
		const departed = res.includes('departed');

		if (store.isStaff) {
			const confirmed = await confirmModal(
				`Warning: Booking ${departed ? 'departed' : 'checked-in'}`,
				`${departed
					? `The booking you are trying to ${cancellation ? 'cancel' : 'edit'} has already departed. Do you still wish to continue?.`
					: `The booking you are trying to ${cancellation ? 'cancel' : 'edit'} has already been checked in. Do you still wish to continue?`}`,
				{
					confirmText: 'Continue anyway',
					cancelText: 'Back',
				});
			if (!confirmed) {
				return false;
			}
		} else {
			alertModal(
				'Booking closed for modifications',
				`${departed
					? 'The ferry trip associated to this booking has already departed.'
					: 'This booking has already been checked in.'}`,
				{
					cancelText: 'Refresh booking',
				})
				.finally(() => {
					if (!store.isStaff) {
						window.location.reload();
					}
				});
			return false;
		}
	}

	if (res.includes('cancelled')) {
		alertModal('Booking closed for modifications', 'This booking has already been cancelled.', {
			cancelText: 'Refresh booking',
		})
			.finally(() => {
				window.location.reload();
			});
		return false;
	}
	return true;
}
