import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FEATURE_IMAGE_1_URL, VEHICLE_ICON_DARK_URL } from 'Constants';
import { store } from 'Models/Store';
import {
	BookingWizardData,
	getBookingWizardData,
	saveBookingWizardDataToLocalStorage,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { TripDetails } from '../FerryTripBookingWizard/Sidebar/FerryFilters/TripDetails/TripDetails';
import { RouteEntity } from '../../../../Models/Entities';
import { isNotNullOrUndefined, stringNotEmpty } from '../../../../Util/TypeGuards';
import locationAndRoutesStore from '../../../../Models/LocationAndRoutesStore';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import {
	PassengerTypeInputs,
} from '../FerryTripBookingWizard/Sidebar/FerryFilters/PassengerDetails/PassengerTypeInputs';
import If from '../../If/If';
import { whiteLabelStore } from '../../../../Models/WhiteLabelStore';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Button/Button';
import Icon from '../Icon/Icon';
import useDeviceDetect from '../../../../Hooks/useDeviceDetect';
import RouteMap from '../../RouteMap/RouteMap';
import usePassengerTypes from '../../../../Hooks/usePassengerTypes';
import useLocationAndRoutes from '../../../../Hooks/useLocationAndRoutes';

export interface HomeSearchProps {
	type: string;
}

function HomeSearch({ type }: HomeSearchProps) {
	const [wizardData, setWizardData] = useState<BookingWizardData>(getBookingWizardData(null));
	const [route, setRoute] = React.useState<RouteEntity | null>(null);
	const [locations, setLocations] = useState<any>(null);

	usePassengerTypes();
	useLocationAndRoutes();

	const { isMobile } = useDeviceDetect();

	if (isMobile) {
		store.routerHistory.push('/booking-wizard/search');
	}

	const {
		tabSelected,
	} = wizardData;

	const { vehicleBookingRequiresAdult } = whiteLabelStore.config;

	const onVehicleTab = tabSelected === 'vehicle';
	const onPassengerTab = tabSelected === 'passenger';

	const onUpdateWizardData = (bookingWizardData: BookingWizardData) => {
		saveBookingWizardDataToLocalStorage(bookingWizardData);
		setWizardData(bookingWizardData);
	};

	const updateDataCallback = useCallback((bookingWizardData: BookingWizardData) => {
		onUpdateWizardData(bookingWizardData);
	}, []);

	if (store.loggedIn && !store.isStaff && wizardData.userId === '') {
		const newData = { ...wizardData };
		newData.userId = store.userId ?? '';
		updateDataCallback(newData);
	}

	useEffect(() => {
		if (locationAndRoutesStore.locations !== null
			&& locationAndRoutesStore.locations.length > 0
			&& (locations === null || locations.length < 1)) {
			setLocations(locationAndRoutesStore.locations);
		}
	}, [locations, locationAndRoutesStore.locations]);

	useEffect(() => {
		// We do two different fetches for the route because there were some problems which arose if the selected route
		// didn't have a return route. This was due to multiple state variables being set at once, so this one will set
		// the route whenever the wizard data changes, and the one below will set the route initially based on the wizard
		// data
		if (stringNotEmpty(wizardData.fromLocationId) && stringNotEmpty(wizardData.toLocationId)) {
			const routeEntity = locationAndRoutesStore.getRouteByLocationIds(wizardData.fromLocationId, wizardData.toLocationId);

			if (isNotNullOrUndefined(routeEntity)) {
				if (routeEntity.departureId !== wizardData.fromLocationId
					|| routeEntity.destinationId !== wizardData.toLocationId) {
					const newData = { ...wizardData };

					newData.fromLocationId = routeEntity.departureId;
					newData.toLocationId = routeEntity.destinationId;
					updateDataCallback(newData);
				}

				setRoute(routeEntity);
			}
		}
	}, [wizardData.toLocationId, wizardData.fromLocationId, locationAndRoutesStore.routes, locationAndRoutesStore.locations]);

	const cargoIcon = (
		<img
			alt="cargo-icon"
			className="booking-type__icon vehicle"
			src={VEHICLE_ICON_DARK_URL}
		/>
	);

	const passengerIcon = (
		<Icon name="person" classname="booking-type__icon person" />
	);

	if (route === null) {
		return <LottieSpinner />;
	}

	return (
		<>
			<div className="homepage-background">
				<img className="background-img" src={FEATURE_IMAGE_1_URL} alt="bg-img" />
				<div className="colour-overlay" />
			</div>
			<div className="home-search-page__container">
				<div className="main-content__container">
					<div className="search-form__container">
						<div className="search-form__top-container">
							<div className="search-form__header">
								<h5>
									{type === 'cargo' ? cargoIcon : passengerIcon}
									{type === 'cargo' ? `Taking a ${whiteLabelStore.vehicleLabelLowerCase}` : 'Walk on passenger'}
								</h5>
								<Button
									className="change-booking-type-btn"
									onClick={() => {
										store.routerHistory.push('/home');
									}}
									colors={Colors.Secondary}
									display={Display.Text}
								>
									Change booking type
								</Button>
							</div>
							<TripDetails
								model={{
									tripTypeTabSelected: wizardData.tripType,
									fromLocation: wizardData.fromLocationId,
									toLocation: wizardData.toLocationId,
									startDate: wizardData.departureTrip === false
										? wizardData.associatedTripDateTime ?? wizardData.startDate
										: wizardData.startDate,
									endDate: wizardData.departureTrip === true
										? wizardData.associatedTripDateTime ?? wizardData.endDate
										: wizardData.endDate,
									route: route,
									wizardData: wizardData,
								}}
								locations={locations}
								departureTrip={wizardData.departureTrip}
								typeSelected={wizardData.tripType}
								onTabSelected={newTripType => {
									const newData: BookingWizardData = { ...wizardData };
									newData.tripType = newTripType;
									if (newData.tripType === 'one way') {
										newData.returningTicketId = '';
									}
									updateDataCallback(newData);
								}}
								onDatesChanged={(date, departureDate, updateOtherDate) => {
									if (date !== undefined) {
										const newData: BookingWizardData = { ...wizardData };
										if (departureDate || updateOtherDate) {
											newData.startDate = date;
											newData.ticketSelectionStartDate = date;
										}
										if (!departureDate || updateOtherDate) {
											newData.endDate = date;
											newData.ticketSelectionEndDate = date;
										}
										updateDataCallback(newData);
									}
								}}
								onUpdateRouteDetails={(fromLocation, toLocation, newRoute) => {
									const newData: BookingWizardData = { ...wizardData };
									newData.fromLocationId = fromLocation;
									newData.toLocationId = toLocation;

									// Reset because additional booking options may not be
									// available in the new route/ferry
									newData.departingTripOptions = [];
									newData.returningTripOptions = [];

									updateDataCallback(newData);
									setRoute(newRoute);
								}}
							/>
							<div className="dashed-border" />
						</div>
						<div className="search-form__bottom-container">
							<If condition={onPassengerTab || (vehicleBookingRequiresAdult && onVehicleTab)}>
								<div className="search-form-passengers__container">
									<PassengerTypeInputs
										wizardData={wizardData}
										onUpdateFilters={updateDataCallback}
									/>
								</div>
							</If>
							<Button
								className="search-trips__btn"
								onClick={() => {
									store.routerHistory.push('/booking-wizard/tickets');
								}}
								display={Display.Solid}
								sizes={Sizes.Large}
								colors={Colors.Secondary}
							>
								Search trips
							</Button>
						</div>
					</div>
					<RouteMap currentRoute={route.id} locationsAndRoutes={locationAndRoutesStore} isMobile={isMobile} />
				</div>
			</div>
		</>
	);
}

export default observer(HomeSearch);
