/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
import React from 'react';
import showLightNavBarOnPage from 'Views/Components/Navigation/NavigationUtils';
import { Search } from 'Views/Components/_HumanWritten/Search/Search';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { LOGO_PRIMARY_DARK, LOGO_PRIMARY_LIGHT } from 'Constants';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
// % protected region % [Add any extra imports here] end

// % protected region % [Customise getFrontendNavLinks signature here] on begin
// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/prefer-default-export
export function getFrontendNavLinks(pageProps: RouteComponentProps, isMobile: boolean): ILink[][] {
// % protected region % [Customise getFrontendNavLinks signature here] end

	// % protected region % [Add any logic before displaying page links here] on begin
	const { location } = pageProps;
	const userGroups = store.userGroups.length
		? store.userGroups.map(group => group.name)
		: [];

	let userProfileClassName: string;

	if (userGroups.includes('Admin')) {
		userProfileClassName = 'admin-circle-background';
	} else if (userGroups.includes('Staff') || userGroups.includes('Manager')) {
		userProfileClassName = 'staff-circle-background';
	} else if (userGroups.includes('User')) {
		userProfileClassName = 'customer-circle-background';
	} else {
		userProfileClassName = 'user-circle-background';
	}

	const onChangeEmailRequestPage = location.pathname.endsWith('/change-email-request');
	const onEmailConfirmPage = location.pathname.includes('/register');
	const { hideEvents } = whiteLabelStore.config;

	const staffDropdownMenuItems = [
		{
			label: 'Account',
			path: '/account-details',
			innerTagClass: 'hide-underline',
		},
		{
			label: 'Logout',
			path: '/logout',
			innerTagClass: 'hide-underline',
		}];

	if (isMobile) {
		staffDropdownMenuItems.unshift(
			{
				label: 'Bookings',
				path: '/staff-bookings',
				innerTagClass: 'hide-underline',
			},
			{
				label: 'Reports',
				path: '/reports',
				innerTagClass: 'hide-underline',
			},
		);
		if (!hideEvents) {
			staffDropdownMenuItems.unshift(
				{
					label: 'Events',
					path: '/upcoming-events',
					innerTagClass: 'hide-underline',
				},
			);
		}
		staffDropdownMenuItems.unshift(
			{
				label: 'Schedule',
				path: '/ferry-schedule',
				innerTagClass: 'hide-underline',
			},
		);
	}

	const customerDropdownMenuItems = [
		{
			label: 'Bookings',
			path: '/bookings',
			innerTagClass: 'hide-underline',
		},
		{
			label: 'Account',
			path: '/account-details/account',
			innerTagClass: 'hide-underline',
		},
		{
			label: 'Credits',
			path: '/account-details/credits',
			innerTagClass: 'hide-underline',
		},
		{
			label: 'Logout',
			path: '/logout',
			innerTagClass: 'hide-underline',
		},
	];

	if (isMobile) {
		customerDropdownMenuItems.shift();
		if (!hideEvents) {
			customerDropdownMenuItems.unshift(
				{
					label: 'Events',
					path: '/upcoming-events',
					innerTagClass: 'hide-underline',
				},
			);
		}
		customerDropdownMenuItems.unshift(
			{
				label: 'Schedule',
				path: '/ferry-schedule',
				innerTagClass: 'hide-underline',
			},
		);
		customerDropdownMenuItems.unshift(
			{
				label: 'Bookings',
				path: '/bookings',
				innerTagClass: 'hide-underline',
			},
		);
	}

	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{
				label: (
					<img
						className="nav__logo"
						src={((showLightNavBarOnPage(isMobile)) ? LOGO_PRIMARY_LIGHT : LOGO_PRIMARY_DARK)}
						alt="company-logo"
						title="Home"
					/>
				),
				path: '/',
				innerTagClass: 'hide-underline',
				className: `li__logo ${onChangeEmailRequestPage || onEmailConfirmPage ? 'disabled' : ''}`,
				//
				// Don't allow user to leave VerifyEmailPage or EmailConfirmationPage
				//
				isDisabled: onChangeEmailRequestPage || onEmailConfirmPage,
			},
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{
				label: (<h5>Schedule</h5>),
				path: '/ferry-schedule',
				className: location && location.pathname === '/ferry-schedule'
					? 'current-page-nav-link' : 'page-nav-link',
				innerTagClass: 'hide-underline',
				shouldDisplay: () => (
					!isMobile
					//
					// Don't display on VerifyEmailPage
					//
					&& !onChangeEmailRequestPage
				),
			},
			{
				label: (<h5>Events</h5>),
				path: '/upcoming-events',
				className: location && location.pathname === '/upcoming-events'
					? 'current-page-nav-link' : 'page-nav-link',
				innerTagClass: 'hide-underline',
				shouldDisplay: () => (
					!hideEvents
					&& !isMobile
					//
					// Don't display on VerifyEmailPage
					//
					&& !onChangeEmailRequestPage
				),
			},
			{
				label: (<h5>Bookings</h5>),
				path: '/staff-bookings',
				className: location && location.pathname === '/staff-bookings'
					? 'current-page-nav-link' : 'page-nav-link',
				innerTagClass: 'hide-underline',
				shouldDisplay: () => (
					(store.isStaff || store.isInvoicedUser)
					&& !isMobile
					//
					// Don't display on VerifyEmailPage
					//
					&& !onChangeEmailRequestPage
				),
			},
			{
				label: (<h5>Reports</h5>),
				path: '/reports',
				className: location && location.pathname === '/reports'
					? 'current-page-nav-link' : 'page-nav-link',
				innerTagClass: 'hide-underline',
				shouldDisplay: () => (
					store.isStaff
					&& !isMobile
				),
			},
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] on begin
			{
				label: '',
				path: '/booking-wizard/cart',
				icon: 'cart',
				iconPos: 'icon-right',
				className: `cart-item ${isMobile ? 'mobile' : ''}`,
				innerTagClass: 'hide-underline',
				showRedDot: true,
				shouldDisplay: () => {
					const transactionId = getFerryBookingTransactionIdFromStorage();
					const result = (isNotNullOrUndefined(transactionId)) && (
						(
							store.loggedIn || (
								!store.loggedIn && (
									location.pathname.includes('/booking-wizard')
									|| location.pathname.includes('/ferry-schedule')
								)
							)
						)
						&& (
							!isMobile || (isMobile && !location.pathname.includes('/home'))
						)
						&& !location.pathname.includes('post-payment')
					);
					return result;
				},
			},
			{
				label: (<div className={userProfileClassName}>{store.userInitials ?? 'SA'}</div>),
				path: '/profile',
				className: store.isStaff ? 'profile-icon' : 'profile-icon-right',
				innerTagClass: 'hide-underline',
				shouldDisplay: () => (
					store.loggedIn
					//
					// Hide profile link on verify email page
					//
					&& !onChangeEmailRequestPage
				),
				subLinks: store.isStaff
					? staffDropdownMenuItems
					: customerDropdownMenuItems,
			},
			{
				label: (<div className="login-signup-navbar">Log in / Sign up</div>),
				path: '/login',
				innerTagClass: 'hide-underline',
				className: 'login-signup',
				shouldDisplay: () => (
					!store.loggedIn
					&& !store?.routerHistory?.location?.pathname.toString().includes('login')
					&& !store?.routerHistory?.location?.pathname.toString().includes('register')
				),
			},
			{
				label: (<Search />),
				path: '', // The search component will prevent propagation to this nav component, so the path is irrelevant
				shouldDisplay: () => (
					store.isStaff
					&& !isMobile
					//
					// Don't display on VerifyEmailPage
					//
					&& !onChangeEmailRequestPage
				),
				innerTagClass: 'hide-underline',
			},
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
