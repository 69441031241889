import React from 'react';
import { Button } from '../../Button/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export interface SearchResultOptionProps {
	id: string;
	name: string;
	email: string;
	onClick: () => void;
}

export function SearchResultOption({
	id,
	name,
	email,
	onClick,
}: SearchResultOptionProps) {
	const classes = ['search-nav-bar__result'];

	return (
		<Link
			to={`/search-results/${id}`}
			className={classNames(classes)}
			onClick={() => {
				onClick();
			}}
		>
			<p className="search-nav-bar__result-name">
				{name}
			</p>
			<p className="search-nav-bar__result-email">
				{email}
			</p>
		</Link>
	);
}
