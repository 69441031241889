import * as React from 'react';
import { SingleActionModalContent } from './Base/SingleActionModalContent';
import { store } from 'Models/Store';
import { showModal } from './Base/BaseModalContents';

export function EventCheckInNotAnEventBookingModalContents() {
	const message = React.useMemo(() => {
		return 'This booking is not an event booking.';
	}, []);

	return (
		<SingleActionModalContent
			title="Error"
			body={message}
			onConfirm={() => store.modal.hide()}
			confirmContent="Ok"
		/>
	);
}

export function showEventCheckInNotAnEventBookingModal() {
	showModal({
		key: 'boarding-not-an-event-booking',
		content: <EventCheckInNotAnEventBookingModalContents />,
	});
}
