import * as React from 'react';
import { BookingEntity, FerryTripEntity } from 'Models/Entities';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { gql } from '@apollo/client';
import { IConditionalFetchArgs } from 'Models/Model';
import { store } from 'Models/Store';
import { formatDateTime } from 'Util/_HumanWritten/TimeUtils';
import { PATH_TO_DEPARTURE_DATETIME_OF_BOOKING } from 'Services/CustomGql/BookingEntityType';
import {
	getBookedSummaryAttributes,
	getFerryTripAttributes,
	getTrailerInfoAttributes,
	getVehicleInfoAttributes,
} from 'Services/CustomGql/BookingSummaryType';
import { bookingStatusOptions } from 'Models/Enums';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import {
	transformFullName,
	transformPassengers,
	transformVehicleSpace,
	transformTrailerSpace,
	transformRego,
	transformTrip,
	transformVehicleMakeModel,
} from '../CheckInUtils';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	addPlusToNumber,
	convertToInternationalNumber,
	convertToNationalNumber,
	removePlusFromNumber,
} from 'Validators/Functions/HumanWritten/Phone';

// =================================================== Constants =================================================== //

/**
 * 5 is the number of bookings that will fit the page without scrolling.
 */
export const BOOKINGS_PER_PAGE = 5;

/**
 * 400ms is the duration the request is delayed until there are no actions being triggered
 *
 * This includes updating input value and page number.
 */
export const DEBOUNCE_DELAY = 300;

// ==================================================== Queries ==================================================== //

const fetchBookingsGql = gql`
	query searchBookingCheckIn(
		$args: [[WhereExpressionGraph]]
		$orderBy: [OrderByGraph]
		$skip: Int
		$take: Int
	) {
		countBookingEntitys(conditions: $args) {
			number
		}
		bookingEntitys(conditions: $args, orderBy: $orderBy, skip: $skip, take: $take) {
			id
			humanReadableId
			bookingStatus
			transactionStatus
			user {
				id
				firstName
				lastName
				phone
				userDisplayName {
					displayName
				}
			}
			bookedSummary {
				${getBookedSummaryAttributes()}
				${getFerryTripAttributes()}
				${getVehicleInfoAttributes()}
				${getTrailerInfoAttributes()}
			}
			bookingSummaries {
				recentSummary {
					${getBookedSummaryAttributes()}
					ferryTrip {
						startDate
						startTime
						departureDateTime
						route {
							duration
						}
					}
					cargoInfo {
						tbc
						selectedLengthId
						selectedWeightId
						cargoIdentification
						cargoTypeId
					}
					towOnInfo {
						selectedLengthId
						towOnType {
							id
							label
						}
					}
				}
			}
		}
	}
`;

export interface CheckInSearchResult {
	countBookingEntitys: { number: number };
	bookingEntitys: BookingEntity[];
}

type BookingSearchArgs = Required<
	Pick<IConditionalFetchArgs<BookingEntity>, 'args' | 'orderBy' | 'skip' | 'take'>
>;

interface CheckInSearchOptions {
	take: number;
	skip: number;
	routeId?: string;
	searchTerm?: string;
	afterDepartureDate?: Date;
}

/**
 * Returns count and list of bookings based on variables.
 *
 * Bookings must equal to `routeId`.
 *
 * Bookings must be greater than `departureDateTime` of current ferry trip.
 *
 * `Search term` is used to compare humanReadableId, user's [fullname/phone no], driver's [fullName/phone no/rego].
 */
export async function fetchCheckInSearchResults({
	take,
	skip,
	routeId,
	searchTerm,
	afterDepartureDate,
}: CheckInSearchOptions): Promise<CheckInSearchResult> {
	const conditions: IWhereCondition<BookingEntity>[][] = [
		[{
			// Ensure only fetching BOOKED bookings
			path: 'bookingStatus',
			comparison: 'equal',
			value: bookingStatusOptions.BOOKED,
		}],
	];

	if (routeId) {
		conditions.push([
			{
				path: 'bookingSummaries.recentSummary.ferryTrip.routeId',
				comparison: 'equal',
				value: routeId,
			},
		]);
	}
	if (afterDepartureDate) {
		conditions.push([
			{
				path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
				comparison: 'greaterThan',
				value: formatDateTime(afterDepartureDate),
			},
		]);
	}
	if (searchTerm) {
		const pathComparisons = [
			'humanReadableId',
			'user.fullName',
			'bookingSummaries.recentSummary.driverFullName',
			'bookingSummaries.recentSummary.cargoInfo.cargoIdentification',
		];
		const pathComparisonConditions = pathComparisons.map<IWhereCondition<BookingEntity>>(x => {
			return {
				path: x,
				comparison: 'like',
				case: 'INVARIANT_CULTURE_IGNORE_CASE',
				value: `%${searchTerm}%`,
			};
		});

		const phoneSearchTerms = getPhoneSearchTerms(searchTerm);
		phoneSearchTerms.forEach(x => {
			pathComparisonConditions.push(x);
		});
		conditions.push(pathComparisonConditions);
	}

	try {
		const result = await store.apolloClient.query<CheckInSearchResult, BookingSearchArgs>({
			query: fetchBookingsGql,
			fetchPolicy: 'network-only',
			variables: {
				take: take,
				skip: skip,
				orderBy: [
					{
						path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
						descending: false,
					},
				],
				args: conditions,
			},
		});

		return {
			countBookingEntitys: result.data.countBookingEntitys,
			bookingEntitys: result.data.bookingEntitys.map(x => {
				const booking = new BookingEntity(x);
				// We need departure date time, so we need class function of FerryTripEntity
				if (booking.bookedSummary) {
					const { ferryTrip } = booking.bookedSummary;
					booking.bookedSummary.ferryTrip = new FerryTripEntity(ferryTrip);
				}
				return booking;
			}),
		};
	} catch (e) {
		console.error(e);
		return {
			countBookingEntitys: { number: 0 },
			bookingEntitys: [],
		};
	}
}

export function getCheckInSearchTableColumns() {
	let items: ICollectionHeaderProps<BookingEntity>[] = [
		{
			name: 'humanReadableId',
			displayName: 'Booking ID',
		},
		{
			name: 'name',
			displayName: 'Name',
			transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformFullName(
				item,
				false,
			),
			nullValue: '-',
		},
		{
			name: 'vehicle-type',
			displayName: 'Vehicle type',
			transformItem: transformVehicleMakeModel,
			nullValue: '-',
		},
		{
			name: 'vehicle-rego',
			displayName: whiteLabelStore.regoLabelPascalCase,
			transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformRego(item) ?? '-',
			nullValue: '-',
		},
		{
			displayName: 'Trip',
			name: '',
			nullValue: '-',
			transformItem: transformTrip,
		},
		{
			name: 'vehicle-length',
			className: 'thin-column',
			customDisplayName: <div className={whiteLabelStore.vehicleIconDarkClassName} />,
			displayName: '',
			transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformVehicleSpace(item) ?? '-',
			nullValue: '-',
		},
		{
			name: 'trailer-length',
			className: 'thin-column',
			// Passing an icon required custom css for the asc/desc icon to sit nicely. See check-in.scss
			customDisplayName: <Icon name="chevron-trailer" />,
			displayName: '',
			transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformTrailerSpace(item) ?? '-',
		},
		{
			name: 'passengers',
			displayName: passengerTypeStore.checkInTableHeaders,
			transformItem: transformPassengers,
			nullValue: passengerTypeStore.checkInTableNullRow,
		},
	];

	if (!whiteLabelStore.config.trailersEnabled) {
		items = items.filter(x => x.name !== 'trailer-length');
	}

	return items;
}

export function getPhoneSearchTerms(searchTerm: string) {
	const list = [] as IWhereCondition<BookingEntity>[];

	let numbersOnlyPhonePattern = searchTerm;
	let internationalPhonePattern = searchTerm;

	// Match Australian numbers (e.g. 04 or +61)
	const matchAustralianNationalNumber = new RegExp('^04').test(searchTerm);
	const matchAustralianInternationalNumber = new RegExp('^\\+61').test(searchTerm);

	// Match non-Australian numbers (e.g. +1) or a number that doesn't match any of the above scenarios
	const matchInternationalNumber = new RegExp('^\\+[0-9]').test(searchTerm);
	const matchOtherNumber = new RegExp('^[0-9]').test(searchTerm);

	if (matchAustralianNationalNumber) {
		internationalPhonePattern = convertToInternationalNumber(internationalPhonePattern);
	} else if (matchAustralianInternationalNumber) {
		numbersOnlyPhonePattern = convertToNationalNumber(numbersOnlyPhonePattern);
	} else if (matchInternationalNumber) {
		numbersOnlyPhonePattern = removePlusFromNumber(searchTerm);
	} else if (matchOtherNumber) {
		internationalPhonePattern = addPlusToNumber(searchTerm);
	}

	list.push(
		{
			path: 'user.phone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${internationalPhonePattern}%`,
		},
	);

	list.push(
		{
			path: 'user.phone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${numbersOnlyPhonePattern}%`,
		},
	);

	list.push(
		{
			path: 'bookingSummaries.recentSummary.driverPhone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${internationalPhonePattern}%`,
		},
	);

	list.push(
		{
			path: 'bookingSummaries.recentSummary.driverPhone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${numbersOnlyPhonePattern}%`,
		},
	);
	return list;
}
