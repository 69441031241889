import classNames from 'classnames';
import React, {
	useState,
	useEffect,
	ChangeEvent,
	ClipboardEvent,
	KeyboardEvent,
} from 'react';
import * as uuid from 'uuid';

const focusInput = (index: number) => {
	document.getElementById(`pin-input-${index}`)?.focus();
};

interface PinCodeInputProps {
	className?: string;
	length?: number;
	onComplete: (pinCode: string) => void;
	resetDependencies?: any[];
}

export default function PinCodeInput({
	className,
	length = 4,
	onComplete,
	resetDependencies = [],
}: PinCodeInputProps) {
	// Simply to track each individual input, in corresponding to the index of the values
	const [keys] = useState<string[]>(new Array(length).fill('').map(x => uuid.v4()));

	// Each digit in the pin code has it's own input
	const [values, setValues] = useState<string[]>(new Array(length).fill(''));

	const currentLength = values.filter(x => x !== '').length;

	const focusAccordingly = () => {
		const newFocusIndex = currentLength === length ? currentLength - 1 : currentLength;
		focusInput(newFocusIndex);
	};

	useEffect(() => {
		focusAccordingly();
		if (currentLength === length) {
			onComplete(values.join(''));
		}
	}, [values]);

	useEffect(() => {
		setValues(new Array(length).fill(''));
	}, resetDependencies);

	const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const inputValue = e.target.value;
		if (index === 0 && inputValue.length === length && /^\d+$/.test(inputValue)) {
			// This handles the case where the user is on their mobile, and copies the code, and the copied text
			// appears on their clipboard above the keyboard.
			setValues(prevValues => {
				const newValues = [...prevValues];
				for (let i = 0; i < length; i++) {
					newValues[i] = inputValue.charAt(i);
				}
				return newValues;
			});
		} else if (/^\d$/.test(inputValue)) {
			// Ensure only digits are entered
			setValues(prevValues => {
				const newValues = [...prevValues];
				newValues[index] = inputValue;
				return newValues;
			});
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace') {
			setValues(prevValues => {
				const newValues = [...prevValues];
				if (values[index]) {
					// Clear the current value
					newValues[index] = '';
				} else if (index > 0) {
					// Move focus to the previous input and clear it
					const previousInput = document.getElementById(`pin-input-${index - 1}`) as HTMLInputElement;
					if (previousInput) {
						previousInput.focus();
						newValues[index - 1] = '';
					}
				}
				return newValues;
			});
			e.preventDefault(); // Prevent the default backspace behavior
		}
	};

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		const pasteData = e.clipboardData.getData('Text');
		if (pasteData.length === length && /^\d+$/.test(pasteData)) {
			const newValues = pasteData.split('');
			setValues(newValues);
			onComplete(pasteData);
			focusInput(newValues.length);
		}
		e.preventDefault(); // Prevent default paste behavior
	};

	return (
		<div className={classNames('pin-code', className)}>
			<div className="body">
				{values.map((value, index) => (
					<input
						key={keys[index]}
						id={`pin-input-${index}`}
						title={`pin-input-${index}`}
						type="text"
						inputMode="numeric"
						maxLength={index === 0 ? length : 1}
						value={value}
						onChange={e => handleChange(e, index)}
						onKeyDown={e => handleKeyDown(e, index)}
						onPaste={handlePaste}
						onFocus={focusAccordingly}
						autoComplete="off"
					/>
				))}
			</div>
		</div>
	);
}
