import * as React from 'react';
import { EditType } from '../BookingDetailedView';
import {
	CheckBookingCanBeModified,
	discardAlterations,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { getBookingCutOffEntity } from './GetBookingCutOffEntity';
import { roundToTwoDecimals } from 'Util/_HumanWritten/CurrencyUtils';
import { alertModal, confirmModal } from 'Views/Components/Modal/ModalUtils';
import { confirmEditPopUp } from './ConfirmEditPopUp';
import {
	clearAllFerryBookingWizardDataFromStorage,
} from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { setTransactionPriceInStorage } from 'Util/_HumanWritten/CartPriceSessionStorage';
import { store } from 'Models/Store';
import { redirectEditTo } from './RedirectEditTo';
import { BookingEntity } from 'Models/Entities';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';
import { ProcessResults } from './EditCancelPopUp';

export const onEdit = async (
	booking: BookingEntity,
	editType: EditType,
	bookingIsDepartureOfTwoWayBooking: boolean,
	isIpad: boolean,
) => {
	// We need to make sure there are no inprogress alterations before we continue to the booking wizard
	// or else pricing calculations will be off (as we need to use the most recent `RESERVED` alteration
	// in order to know what price changes have been made.
	await discardAlterations({
		bookingIds: [booking.id],
		systemExpired: true,
	});
	const res = await CheckBookingCanBeModified(booking.id);
	const canContinueWithCancel = await ProcessResults(res, false);
	if (!canContinueWithCancel) {
		return;
	}

	// Logic breakdown
	//
	//     if booking has associated return ticket, confirm changes apply to both or just one
	//     then if cuttoff fee exists, confirm user still wants to continue
	//     finally navigate user to specified booking wizard step
	//
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return;
	}

	// Confirm changes apply to both or just one booking

	let alterReturn = false;
	let returnAlterationFee = 0;

	if (!booking.desynced && !res.includes('desynced')) {
		// When booking is synced, there is an associated booking
		const returnBookingInfo = booking?.returnBooking ?? booking?.returnBookingFor;
		const returnBookingClosed = returnBookingInfo?.bookedSummary?.ferryTrip.closed;

		if (returnBookingInfo && !returnBookingInfo.bookedSummary) {
			console.error('Booked summary missing, this should not happen');
			return;
		}

		if (returnBookingInfo
			&& returnBookingInfo.bookedSummary
			&& returnBookingInfo.bookingStatus === 'BOOKED'
			&& !returnBookingInfo.checkedIn
			&& !returnBookingClosed
			&& !isBeforeNow(returnBookingInfo.bookedSummary.ferryTrip.departureDateTime)
		) {
			const returnBookingCutOffEntity = await getBookingCutOffEntity(returnBookingInfo, 'EDIT');
			if (returnBookingCutOffEntity) {
				returnAlterationFee = roundToTwoDecimals((returnBookingCutOffEntity.percentageFee / 100)
					* returnBookingInfo.bookedSummary.totalCost);
			}

			alterReturn = await confirmModal(
				'We noticed you have a returning trip...',
				(
					<div>
						<h5>Do you wish to make changes to those trip details too?</h5>
					</div>
				),
				{
					cancelText: 'One way',
					confirmText: 'Both trips',
					resolveOnCancel: true,
				},
			);
		}
	}

	// Check current booking has cuttoff fee
	const departureCuttOff = await getBookingCutOffEntity(booking, 'EDIT');
	let departureAlterationFee = 0;
	if (departureCuttOff) {
		departureAlterationFee = roundToTwoDecimals(
			(departureCuttOff.percentageFee / 100) * booking.bookedSummary.totalCost,
		);
	}

	let continueToEdit = true;

	if (editType === 'trip-time' && departureCuttOff) {
		continueToEdit = await confirmEditPopUp(departureCuttOff);
	}

	if (!continueToEdit) {
		return;
	}

	// Clear the local storage and reset total cart prices back to $0
	clearAllFerryBookingWizardDataFromStorage();
	setTransactionPriceInStorage(0);

	if (alterReturn && !bookingIsDepartureOfTwoWayBooking) {
		// If editing both trips using the edit button of the return leg of a booking,
		// switch fees around
		redirectEditTo(
			booking,
			isIpad,
			bookingIsDepartureOfTwoWayBooking,
			editType,
			alterReturn,
			returnAlterationFee,
			departureAlterationFee,
		);
	} else {
		redirectEditTo(
			booking,
			isIpad,
			bookingIsDepartureOfTwoWayBooking,
			editType,
			alterReturn,
			departureAlterationFee,
			returnAlterationFee,
		);
	}
};
