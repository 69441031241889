import * as React from 'react';
import { store } from 'Models/Store';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { showModal } from './Base/BaseModalContents';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';

export type CheckInAddMoveModalContentsProps = Pick<DualActionModalContentProps, 'onCancel' | 'onConfirm'>;

export function CheckInAddMoveModalContents({
	onCancel,
	onConfirm,
}: CheckInAddMoveModalContentsProps) {
	return (
		<DualActionModalContent
			className="add-move-checkin"
			title="Add/Move booking"
			body="Do you want to add a booking or move an existing one?"
			cancelContent={(
				<Button
					buttonProps={{
						id: 'modal-add-new-btn',
					}}
					key="cancel"
					className="cancel"
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					onClick={() => {
						if (onCancel) {
							onCancel();
						}
						store.modal.hide();
					}}
				>
					Add new
				</Button>
			)}
			confirmContent={(
				<Button
					buttonProps={{
						id: 'modal-move-existing-btn',
					}}
					key="confirm"
					className="confirm"
					display={Display.Solid}
					colors={Colors.Alternate}
					sizes={Sizes.Medium}
					onClick={() => {
						if (onConfirm) {
							onConfirm();
						}
						store.modal.hide();
					}}
				>
					Move existing
				</Button>
			)}
			// Below does not matter because cancel and confirm content will replace buttons
			onCancel={onCancel}
			onConfirm={onConfirm}
		/>
	);
}

export function showCheckInAddMoveModal(props: CheckInAddMoveModalContentsProps) {
	showModal({
		key: 'add-move-check-in-booking',
		content: <CheckInAddMoveModalContents {...props} />,
	});
}
