import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import queryString from 'querystring';
import { FEATURE_IMAGE_2_URL } from 'Constants';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import BookingWizardBackground from 'Views/Components/_HumanWritten/BookingWizardBG/BookingWizardBackground';
import { EventBookingWizardWrap } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventBookingWizardWrap';

export interface EventBookingWizardPageProps extends RouteComponentProps {
}

@observer
class EventBookingWizardPage extends React.Component<EventBookingWizardPageProps> {
	render() {
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		const queryParams = location.search.substring(1);
		const params = queryString.parse(queryParams);

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage>
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="booking-wizard-nav-spacer" />
				<div className="body-content">
					<BookingWizardBackground src={FEATURE_IMAGE_2_URL} />
					<EventBookingWizardWrap queryParams={queryParams === '' ? undefined : params} />
				</div>
			</SecuredPage>
		);

		return contents;
	}
}

export interface EventBookingWizardPageParams {}

export default EventBookingWizardPage;
