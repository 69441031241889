import * as React from 'react';
import { store } from 'Models/Store';
import { runInAction } from 'mobx';
import LinkAsButton from 'Views/Components/_HumanWritten/LinkAsButton/LinkAsButton';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';

export interface EventCheckInActionButtonsProps {
	state?: {
		showCheckedIn: boolean;
	};
}

export function EventCheckInActionButtons({
	state,
}: EventCheckInActionButtonsProps) {
	return (
		<div className="check-in__action-btns">
			<Button
				className={`check-in__action-btn__toggle-check-in ${state?.showCheckedIn ? 'checked' : 'unchecked'}`}
				display={Display.Text}
				colors={Colors.Secondary}
				sizes={Sizes.ExtraLarge}
				onClick={() => {
					if (state) {
						runInAction(() => {
							state.showCheckedIn = !state?.showCheckedIn;
						});
					}
				}}
				icon={{ icon: 'check-box', iconPos: 'icon-left' }}
			/>
			<div className="border__wrapper">
				<div className="border" />
			</div>
			<LinkAsButton
				to={`${store.routerHistory.location.pathname}/scan`}
				className="check-in__action-btn__camera"
				display={Display.Solid}
				colors={Colors.Secondary}
				sizes={Sizes.ExtraLarge}
			>
				<Icon name="camera" />
			</LinkAsButton>
		</div>
	);
}
