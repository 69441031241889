import RouteMapPoint from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapPoint';
import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';

const SCALE_ORIGIN = 1200;

export default class RouteMapProjection {
	private imageHeight: number;
	private imageWidth: number;

	private canvas: RouteMapCanvas;

	// Ratio of the canvas to the image
	public scaleX: number;
	private scaleY: number;

	// Ratio of the canvas to the origin (this is used to calculate the size of things on the map)
	public originScaleX: number;
	private originScaleY: number;

	private readonly deviceResolution: number;

	constructor(canvas: RouteMapCanvas, deviceResolution: number) {
		this.canvas = canvas;
		this.deviceResolution = deviceResolution;
	}

	public updateImageSize(imageSize: RouteMapPoint) {
		this.imageHeight = imageSize.getY();
		this.imageWidth = imageSize.getX();

		this.updateScale();
	}

	public updateScale() {
		this.calculateProjection();
	}

	public getScaleX() {
		return this.originScaleX;
	}

	public getScaleY() {
		return this.originScaleY;
	}

	// Get the size scaled by the current scale
	public scaleSize(size: number, minScale: number = 0) {
		if (minScale > 1) {
			return size;
		}

		return size * Math.max(this.getScaleX(), minScale);
	}

	// From mouse coordinates to canvas coordinates
	public getCanvasCoords(clientX: number, clientY: number): RouteMapPoint {
		const canvasRect = this.canvas.getCanvas().getBoundingClientRect();

		return new RouteMapPoint(
			(clientX - canvasRect.left) * (this.canvas.getCanvas().width / canvasRect.width),
			(clientY - canvasRect.top) * (this.canvas.getCanvas().height / canvasRect.height));
	}

	// From image coordinates to canvas coordinates
	public project(x: number, y: number): RouteMapPoint {
		return new RouteMapPoint(x * this.scaleX, y * this.scaleY);
	}

	public projectPoint(point: RouteMapPoint): RouteMapPoint {
		return this.project(point.getX(), point.getY());
	}

	public unproject(x: number, y: number): RouteMapPoint {
		return new RouteMapPoint(x / this.scaleX, y / this.scaleY);
	}

	public unprojectPoint(point: RouteMapPoint): RouteMapPoint {
		return this.unproject(point.getX(), point.getY());
	}

	private calculateProjection() {
		this.scaleX = this.canvas.getCanvas().width / this.imageWidth;
		this.scaleY = this.canvas.getCanvas().height / this.imageHeight;

		this.originScaleX = this.canvas.getCanvas().width / (SCALE_ORIGIN);
		this.originScaleY = this.canvas.getCanvas().height / (SCALE_ORIGIN);
	}
}
