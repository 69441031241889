import * as React from 'react';
import { TripDirectionSelector } from 'Views/Components/_HumanWritten/TripDirectionSelector/TripDirectionSelector';
import moment from 'moment';
import LineSvg from 'images/Line 313.svg';
import LineDisabledSvg from 'images/Line 313-disabled.svg';
import CalendarSvg from 'images/calender.svg';
import CalendarDisabledSvg from 'images/calender-disabled.svg';
import { IRouteEntityAttributes, LocationEntity, RouteEntity } from 'Models/Entities';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { Button } from 'Views/Components/Button/Button';
import { DisplayType } from 'Views/Components/Models/Enums';
import { store } from 'Models/Store';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import { wizardModeOptions } from 'Models/Enums';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { BookingWizardData } from '../../../BookingWizardData';
import { TripTypeButtons } from './TripTypeButtons';
import { DateRangePicker } from './DateRangePicker';

export interface TripDetailsModel {
	tripTypeTabSelected: 'one way' | 'return';
	endDate: Date;
	fromLocation: string;
	toLocation: string;
	startDate: Date;
	route: RouteEntity | IRouteEntityAttributes;
	wizardData: BookingWizardData;
}

export interface TripDetailsProps {
	model: TripDetailsModel;
	onTabSelected: (direction: 'one way' | 'return') => void;
	typeSelected: string;
	onDatesChanged: (startDate: Date, departureDate: boolean, updateOtherDate: boolean) => void;
	onUpdateRouteDetails: (fromLocation: string, toLocation: string, route: RouteEntity) => void;
	departureTrip: boolean | undefined;
	locations?: LocationEntity[];
}

export function TripDetails({
	model, onTabSelected, typeSelected, onDatesChanged, onUpdateRouteDetails, departureTrip, locations,
}: TripDetailsProps) {
	const changeBothDates = isNullOrUndefined(departureTrip);

	return (
		<div className="trip-details__container">
			<TripTypeButtons
				typeSelected={typeSelected}
				onTabSelected={onTabSelected}
				model={model}
			/>
			<TripDirectionSelector
				model={model}
				routeProperty="route"
				fromLocationProperty="fromLocation"
				toLocationProperty="toLocation"
				updateModel={onUpdateRouteDetails}
				disabled={model.wizardData.wizardMode === wizardModeOptions.ALTERATION}
				locations={locations}
				className="custom"
			/>
			<DateRangePicker
				model={model}
				onDatesChanged={onDatesChanged}
				changeBothDates={changeBothDates}
				departureTrip={departureTrip}
			/>
		</div>
	);
}
