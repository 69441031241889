import {
	AdditionalBookingOptionEntity,
	CargoEntity,
	FerryTripEntity,
	TowOnEntity,
} from '../../../Models/Entities';
import { action } from 'mobx';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../Util/TypeGuards';
import { SelectedTicketsDto } from './EventsBookingWizard/EventsBookingWizardData';

export interface BookingSummaryAddon {
	quantity: number;
	option: AdditionalBookingOptionEntity;
}
export interface BookingSummaries {
	recentSummary: BookingSummary;
	previousSummary?: BookingSummary;
}

export interface IBookingSummary {
	// Shared properties (event and ferry booking)
	id: string;
	userId: string;
	userFirstName: string;
	userLastName: string;
	ferryTrip: FerryTripEntity;
	totalCost: number;
	paidBookingPrice: number;
	maxRefund: number;
	nonRefundableAmount: number;

	// Ferry booking only properties
	adultPassengerCount: number;
	childPassengerCount: number;
	infantPassengerCount: number;
	passengerCountD?: number;
	passengerCountE?: number;
	passengerCountF?: number;
	passengerCountG?: number;
	passengerCountH?: number;
	driverPhone: string;
	driverFirstName: string;
	driverLastName: string;
	cargoInfo?: CargoEntity | null;
	towOnInfo?: TowOnEntity | null;
	additionalBookingOptions: BookingSummaryAddon[];
	note?: string;

	// Event booking only properties
	eventId?: string;
	selectedTickets?: SelectedTicketsDto[];
	primaryFirstName?: string;
	primaryLastName?: string;
	primaryPhone?: string;
	primaryEmail?: string;
}
export class BookingSummary implements IBookingSummary {
	constructor(attributes?: Partial<IBookingSummary>) {
		this.assignAttributes(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	private assignAttributes(attributes?: Partial<IBookingSummary>) {
		if (isNullOrUndefined(attributes)) {
			return;
		}

		// Shared properties (event and ferry booking)
		if (attributes.id !== undefined) {
			this.id = attributes.id;
		}
		if (attributes.userId !== undefined) {
			this.userId = attributes.userId;
		}
		if (attributes.userFirstName !== undefined) {
			this.userFirstName = attributes.userFirstName;
		}
		if (attributes.userLastName !== undefined) {
			this.userLastName = attributes.userLastName;
		}
		if (isNotNullOrUndefined(attributes.ferryTrip)) {
			this.ferryTrip = new FerryTripEntity(attributes.ferryTrip);
		}
		if (attributes.totalCost !== undefined) {
			this.totalCost = attributes.totalCost;
		}
		if (attributes.paidBookingPrice !== undefined) {
			this.paidBookingPrice = attributes.paidBookingPrice;
		}
		if (attributes.maxRefund !== undefined) {
			this.maxRefund = attributes.maxRefund;
		}
		if (attributes.nonRefundableAmount !== undefined) {
			this.nonRefundableAmount = attributes.nonRefundableAmount;
		}

		// Ferry booking only properties
		if (attributes.adultPassengerCount !== undefined) {
			this.adultPassengerCount = attributes.adultPassengerCount;
		}
		if (attributes.childPassengerCount !== undefined) {
			this.childPassengerCount = attributes.childPassengerCount;
		}
		if (attributes.infantPassengerCount !== undefined) {
			this.infantPassengerCount = attributes.infantPassengerCount;
		}
		if (isNotNullOrUndefined(attributes.passengerCountD)) {
			this.passengerCountD = attributes.passengerCountD;
		}
		if (isNotNullOrUndefined(attributes.passengerCountE)) {
			this.passengerCountE = attributes.passengerCountE;
		}
		if (isNotNullOrUndefined(attributes.passengerCountF)) {
			this.passengerCountF = attributes.passengerCountF;
		}
		if (isNotNullOrUndefined(attributes.passengerCountG)) {
			this.passengerCountG = attributes.passengerCountG;
		}
		if (isNotNullOrUndefined(attributes.passengerCountH)) {
			this.passengerCountH = attributes.passengerCountH;
		}
		if (attributes.driverPhone !== undefined) {
			this.driverPhone = attributes.driverPhone;
		}
		if (attributes.driverFirstName !== undefined) {
			this.driverFirstName = attributes.driverFirstName;
		}
		if (attributes.driverLastName !== undefined) {
			this.driverLastName = attributes.driverLastName;
		}
		if (isNotNullOrUndefined(attributes.cargoInfo)) {
			this.cargoInfo = new CargoEntity(attributes.cargoInfo);
		}
		if (isNotNullOrUndefined(attributes.towOnInfo)) {
			this.towOnInfo = new TowOnEntity(attributes.towOnInfo);
		}
		if (isNotNullOrUndefined(attributes.additionalBookingOptions)) {
			this.additionalBookingOptions = attributes.additionalBookingOptions;
		}
		if (attributes.note !== undefined) {
			this.note = attributes.note;
		}

		// Event booking only properties
		if (attributes.eventId !== undefined) {
			this.eventId = attributes.eventId;
		}
		if (isNotNullOrUndefined(attributes.selectedTickets)) {
			this.selectedTickets = attributes.selectedTickets;
		}
		if (attributes.primaryFirstName !== undefined) {
			this.primaryFirstName = attributes.primaryFirstName;
		}
		if (attributes.primaryLastName !== undefined) {
			this.primaryLastName = attributes.primaryLastName;
		}
		if (attributes.primaryPhone !== undefined) {
			this.primaryPhone = attributes.primaryPhone;
		}
		if (attributes.primaryEmail !== undefined) {
			this.primaryEmail = attributes.primaryEmail;
		}
	}

	// Shared properties (event and ferry booking)
	id: string;
	userId: string;
	userFirstName: string;
	userLastName: string;
	ferryTrip: FerryTripEntity;
	totalCost: number;
	paidBookingPrice: number;
	maxRefund: number;
	nonRefundableAmount: number;

	// Ferry booking only properties
	adultPassengerCount: number;
	childPassengerCount: number;
	infantPassengerCount: number;
	passengerCountD?: number;
	passengerCountE?: number;
	passengerCountF?: number;
	passengerCountG?: number;
	passengerCountH?: number;
	driverPhone: string;
	driverFirstName: string;
	driverLastName: string;
	cargoInfo?: CargoEntity | null;
	towOnInfo?: TowOnEntity | null;
	additionalBookingOptions: BookingSummaryAddon[];
	note?: string;

	// Event booking only properties
	eventId?: string;
	selectedTickets?: SelectedTicketsDto[];
	primaryFirstName?: string;
	primaryLastName?: string;
	primaryPhone?: string;
	primaryEmail?: string;
}
