import React from 'react';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { observer } from 'mobx-react';
import {
	CartCalculationLineItem,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/CartLineItems/CartCalculationLineItem';
import { IPriceLineItem } from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import { isNullOrUndefined } from 'Util/TypeGuards';

export interface GstLineProps {
	lineItem: IPriceLineItem | null;
}

function GstLine({
	lineItem,
}: GstLineProps) {
	if (isNullOrUndefined(lineItem)) {
		return null;
	}
	return (
		<CartCalculationLineItem
			keyToUse="gst-line-item"
			className="gst-line-item"
			lineItemDisplayName={lineItem.description}
			lineItemPrice={formatPriceDisplay(
				lineItem.price,
				// We don't want to show a sign here. the alteration prop determines whether we
				// show a sign so setting it to false regardless will hide the sign
				false,
				true,
			)}
		/>
	);
}

export default observer(GstLine);
