import React from 'react';
import { FerryTripAvailability } from 'Util/_HumanWritten/CapacityCalculationUtils';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { IStore } from 'Models/Store';
import If from 'Views/Components/If/If';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { RenderAddToWaitListModal } from 'Views/Components/_HumanWritten/Waitlist/AddToWaitListModal';

export const renderSpacesRemaining = (
	isDepartureTicket: boolean,
	trailerCheckboxChecked: boolean,
	ticketsTabTrip: TicketsTabTrip,
	availability: FerryTripAvailability,
	isVehicleBooking: boolean,
	isVehicleAvailability: boolean,
	isTrailerAvailability: boolean,
	isInvalidAlterationTicket: boolean,
	vehicleLengthExceeded: boolean,
	exceedsPassengerAvailable: boolean,
	isPassengerAvailability: boolean,
	trailerSpaceExceeded: boolean,
	isCompletelySoldOut: boolean,
	showWaitlist: boolean,
	wizardData: BookingWizardData,
	disabledFerryTrip: boolean = false,
	store: IStore,
	refresh?: () => void,
) => {
	let content: JSX.Element;
	let soldOutText = '';
	// The two following messages should only show if there is still space on a ferry trip
	// but the user no longer fits due to their selection of passengers / vehicle/trailer length
	if (trailerCheckboxChecked && isVehicleBooking && trailerSpaceExceeded && wizardData.trailerLengthId !== '') {
		soldOutText = `TRAILER ${availability.measurementType.toUpperCase()} EXCEEDS SPACES AVAILABLE`;
	} else if (isVehicleAvailability && isVehicleBooking && vehicleLengthExceeded) {
		soldOutText = `VEHICLE ${availability.measurementType.toUpperCase()} EXCEEDS SPACES AVAILABLE`;
	} else if (isPassengerAvailability && exceedsPassengerAvailable) {
		soldOutText = 'NO SPACES AVAILABLE';
	} else if (isCompletelySoldOut) {
		soldOutText = 'SOLD OUT';
	} else if (isInvalidAlterationTicket) {
		soldOutText = `This time is ${isDepartureTicket
			? 'after'
			: 'before'} your ${isDepartureTicket
			? 'return'
			: 'departure'} date.`;
	}
	if (soldOutText !== '') {
		content = (
			<div className="sold-out-waitlist__container">
				<div className="no-space-available">{soldOutText}</div>
				<If condition={showWaitlist && !isInvalidAlterationTicket && !store.isStaff}>
					{renderWaitListButton(ticketsTabTrip, wizardData, store, refresh)}
				</If>
			</div>
		);
	} else {
		content = (
			<div className="test">
				<div className="test-2">
					<p className="spaces-remaining-label">Space left:</p>
					<div className={`spaces-remaining-amount ${disabledFerryTrip ? 'departed' : ''}`}>
						{availability.passenger === 0 && availability.vehicle < 1
							? <div>SOLD OUT</div>
							: (
								<>
									<div className="passenger-capacity icon-chevron-passenger icon-left">
										{isPassengerAvailability ? availability.passenger : 'X'}
									</div>
									<If condition={availability.totalVehicle > 0}>
										{isVehicleBooking
											&& (
												<div className={`vehicle-capacity ${disabledFerryTrip
													? 'icon-custom-vehicle-disabled'
													: whiteLabelStore.vehicleIconDarkClassName} icon-left`}
												>
													{availability.vehicle > 0 && availability.passenger > 0
														? availability.vehicle : 'X'}
												</div>
											)}
										{(isVehicleAvailability && isPassengerAvailability && trailerCheckboxChecked)
											&& (
												<div className="trailer-capacity icon-chevron-trailer icon-left">
													{!trailerSpaceExceeded
														? <span className="available-trailer icon-tick icon-only" />
														: 'X'}
												</div>
											)}
									</If>
								</>
							)}
					</div>
				</div>
				<If
					condition={!isTrailerAvailability
						&& isVehicleBooking
						&& showWaitlist
						&& !isInvalidAlterationTicket
						&& !store.isStaff
						&& availability.totalTrailers > 0}
				>
					{renderWaitListButton(ticketsTabTrip, wizardData, store, refresh)}
				</If>
			</div>
		);
	}

	return (
		<div className={`trip-card-spaces-remaining-container ${isCompletelySoldOut
			? 'sold-out_add-to-waitlist'
			: ''}`}
		>
			{content}
		</div>
	);
};

export const renderWaitListButton = (
	ticketsTabTrip: TicketsTabTrip,
	wizardData: BookingWizardData,
	store: IStore,
	refresh?: () => void,
) => {
	return (
		<>
			{ticketsTabTrip.isWaitListed
				? <p className="waitlisted-text">Waitlisted</p>
				: (
					<Button
						display={ticketsTabTrip.isWaitListed ? Display.Solid : Display.Outline}
						colors={Colors.Secondary}
						className="add-to-waitlist__button"
						onClick={() => RenderAddToWaitListModal(
							ticketsTabTrip.id,
							ticketsTabTrip.vehicleSpacesTotal > 0,
							wizardData,
							!store.isStaff ? store.userId : undefined,
							!store.isStaff ? store.email : '',
							refresh,
						)}
					>
						Add to waitlist
					</Button>
				)}
		</>
	);
};
