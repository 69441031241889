import If from '../../../If/If';
import * as React from 'react';
import Icon from '../../Icon/Icon';

export interface ReadOnlyFieldProps {
	contentName: string;
	content: React.ReactNode;
	open: boolean;
	setOpen: (value: boolean) => void;
}
export function CollapseExpand({
	contentName,
	content,
	open,
	setOpen,
}: ReadOnlyFieldProps) {
	return (
		<div className="collapse-expand__container">
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				className={`collapse-expand__header ${contentName
					.toLowerCase()
					.replace(' / ', '-')
					.replace(' ', '-')}-field ${open ? 'open' : 'closed'}`}
				onClick={() => setOpen(!open)}
				onMouseDown={() => {}}
				onKeyDown={() => {}}
			>
				<h6>{contentName}</h6>
				<Icon name={open ? 'chevron-up' : 'chevron-down'} classname={open ? 'chevron-up' : 'chevron-down'} />
			</div>
			<If condition={open}>
				<div className="collapse-expand__body">
					{content}
				</div>
			</If>
		</div>
	);
}
