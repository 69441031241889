import { IStore } from 'Models/Store';
import { clearStorageTransactionPrice } from '../CartPriceSessionStorage';
import {
	clearEventBookingTransactionIdFromStorage,
	clearFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	clearBookingWizardData,
	clearOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	clearEventBookingWizardData,
	clearOldEventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';

/**
 * The method to be set as the 'onSuccess' method of the booking wizard.
 * Clears the booking wizard data in the local storage and the cart prices in the store, then redirects to the
 * ferry schedule page.
 * @param store: The application's store
 * @param transactionId
 * @param alteration
 * @param returnTrip
 * @param userId
 * @param stopNavigation
 */
export function onWizardSuccess(
	store: IStore,
	transactionId: string,
	alteration: boolean,
	returnTrip: boolean,
	eventBooking: boolean,
	userId?: string,
	stopNavigation?: boolean,
) {
	/**
	 * Clear the local storage of booking wizard data
	 * This should be fine as all payments should have been successful and all
	 * relevant Bookings should now be in the database.
	 */
	// store.clearBookingPrices();

	if (eventBooking) {
		clearEventBookingWizardData();
		clearOldEventBookingWizardData();
		clearEventBookingTransactionIdFromStorage();
		// clearStorageTransactionPrice();
	} else {
		clearBookingWizardData();
		clearOldBookingWizardData();
		clearFerryBookingTransactionIdFromStorage();
		// clearStorageTransactionPrice();
	}

	if (stopNavigation) {
		return;
	}

	// eslint-disable-next-line max-len
	const baseURL = eventBooking ? '/event-booking-success' : '/booking-success';
	const destination = `${baseURL}?id=${transactionId}&ui=${userId}&alteration=${alteration}&returnTrip=${returnTrip}`;
	store.routerHistory.push(destination);
}

/**
 * Removes the booking wizard data and booking ids stored in local storage.
 */
export function clearAllFerryBookingWizardDataFromStorage() {
	clearBookingWizardData();
	clearFerryBookingTransactionIdFromStorage();
	clearOldBookingWizardData();
}

/**
 * Removes the event booking wizard data and booking ids stored in local storage.
 */
export function clearAllEventBookingWizardDataFromStorage() {
	clearEventBookingWizardData();
	clearEventBookingTransactionIdFromStorage();
	clearOldEventBookingWizardData();
}

export function clearFerryBookingWizard() {
	clearBookingWizardData();
	clearFerryBookingTransactionIdFromStorage();
	clearOldBookingWizardData();
	clearStorageTransactionPrice();
}

export function clearEventBookingWizard() {
	clearEventBookingWizardData();
	clearEventBookingTransactionIdFromStorage();
	clearOldEventBookingWizardData();
	clearStorageTransactionPrice();
}
