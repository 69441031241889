/**
 * This function will calculate the minimum ticket price from a list of ferry trips.
 * @param filteredPriceByFerryTrip: A list of all the prices associated to that date.
 * Note: This is used in the TICKET SELECTION tab in the booking wizard.
 * It shows in the tab header to show the user the minimum ticket price for a day.
 * The minimum price is based on user selections (e.g. passengers).
 */
export const getMinimumFerryTicketPriceFromListOfFerryTrips = (
	filteredPriceByFerryTrip: { [key: string]: number },
) => {
	if (Object.keys(filteredPriceByFerryTrip).length === 0) {
		return 0;
	}

	const lowestPrice = Math.min(...Object.values(filteredPriceByFerryTrip));
	return Math.floor(lowestPrice);
};
