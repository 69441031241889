import React, { useEffect, useState } from 'react';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import useAddOns from 'Hooks/useAddOns';
import { FerryTripEntity } from 'Models/Entities';
import { ParsedUrlQuery } from 'querystring';
import {
	getBookingWizardParameters,
	getCachedBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardHelpers';
import {
	BookingWizardData,
	getBookingWizardData,
	getBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { BookingWizardWrapInner } from './BookingWizardWrapInner';
import locationAndRoutesStore from 'Models/LocationAndRoutesStore';

export interface SelectedTrips {
	departingTrip?: FerryTripEntity;
	returningTrip?: FerryTripEntity;
}

export interface BookingWizardWrapProps {
	queryParams?: ParsedUrlQuery;
}

export function BookingWizardWrap(props: BookingWizardWrapProps) {
	const { queryParams } = props;

	useAddOns();

	const [loading, setLoading] = useState(true);
	const [wizardData, setWizardData] = useState<BookingWizardData>();
	const [locations, setLocations] = useState<any>(null);

	useEffect(() => {
		if (locationAndRoutesStore.locations !== null
			&& locationAndRoutesStore.locations.length > 0
			&& (locations === null || locations.length < 1)) {
			setLocations(locationAndRoutesStore.locations);
		}
	}, [locations, locationAndRoutesStore.locations]);

	useEffect(() => {
		if (getBookingWizardDataCacheToken() != null) {
			getCachedBookingWizardData()
				.then(data => {
					setWizardData(data);
					setLoading(false);
				})
				.catch(e => {
					console.error(e);
					setWizardData(getBookingWizardData(getBookingWizardParameters(queryParams)));
					setLoading(false);
				});
			return;
		}

		setWizardData(getBookingWizardData(getBookingWizardParameters(queryParams)));
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading || wizardData === undefined) {
		return <LottieSpinner />;
	}

	return (
		<BookingWizardWrapInner
			bookingWizardData={wizardData}
			setBookingWizardData={setWizardData}
			locations={locations}
		/>
	);
}
