import React from 'react';
import { isNullOrUndefined } from '../../../Util/TypeGuards';

export function UserDisplayNameTag(displayName?: string | null) {
	if (isNullOrUndefined(displayName)) {
		return <></>;
	}
	return (
		<span className="user-display-name-tag">{displayName}</span>
	);
}

export function UserDisabledTag() {
	return (
		<span className="user-disabled-tag">Deactivated</span>
	);
}
