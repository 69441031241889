import React from 'react';
import classnames from 'classnames';
import { Button, Display, Sizes } from 'Views/Components/Button/Button';

export interface EventContinueButtonProps {
	disabled?: boolean;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	buttonProps?: React.ButtonHTMLAttributes<Element>;
	children?: React.ReactNode;
}

export const EventContinueButton = ({
	disabled,
	onClick,
	buttonProps,
	children,
}: EventContinueButtonProps) => {
	const classNames = [
		'event-booking-wizard-continue-btn',
		'continue-btn',
	];

	return (
		<Button
			disabled={disabled}
			className={classnames(classNames)}
			display={Display.Solid}
			sizes={Sizes.Large}
			icon={{ icon: 'arrow-right', iconPos: 'icon-right' }}
			onClick={onClick}
			buttonProps={buttonProps}
		>
			{children ?? 'Continue'}
		</Button>
	);
};
