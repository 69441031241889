/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsCargoReferenceManyToMany from '../Security/Acl/VisitorsCargoReferenceManyToMany';
import UserCargoReferenceManyToMany from '../Security/Acl/UserCargoReferenceManyToMany';
import StaffCargoReferenceManyToMany from '../Security/Acl/StaffCargoReferenceManyToMany';
import ManagerCargoReferenceManyToMany from '../Security/Acl/ManagerCargoReferenceManyToMany';
import AdminCargoReferenceManyToMany from '../Security/Acl/AdminCargoReferenceManyToMany';
import InvoicedUserCargoReferenceManyToMany from '../Security/Acl/InvoicedUserCargoReferenceManyToMany';
import FixedPriceUserCargoReferenceManyToMany from '../Security/Acl/FixedPriceUserCargoReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISpacingAddOnCargoAttributes extends IModelAttributes {
	spacingAddOnId: string;
	cargoId: string;

	spacingAddOn: Models.SpacingAddOnEntity | Models.ISpacingAddOnEntityAttributes;
	cargo: Models.CargoEntity | Models.ICargoEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('SpacingAddOnCargo')
export default class SpacingAddOnCargo
	extends Model
	implements ISpacingAddOnCargoAttributes {
	public static acls: IAcl[] = [
		new VisitorsCargoReferenceManyToMany(),
		new UserCargoReferenceManyToMany(),
		new StaffCargoReferenceManyToMany(),
		new ManagerCargoReferenceManyToMany(),
		new AdminCargoReferenceManyToMany(),
		new InvoicedUserCargoReferenceManyToMany(),
		new FixedPriceUserCargoReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public spacingAddOnId: string;

	@observable
	@attribute()
	public cargoId: string;

	@observable
	@attribute({ isReference: true })
	public spacingAddOn: Models.SpacingAddOnEntity;

	@observable
	@attribute({ isReference: true })
	public cargo: Models.CargoEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ISpacingAddOnCargoAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.spacingAddOnId) {
				this.spacingAddOnId = attributes.spacingAddOnId;
			}
			if (attributes.cargoId) {
				this.cargoId = attributes.cargoId;
			}

			if (attributes.spacingAddOn) {
				if (attributes.spacingAddOn instanceof Models.SpacingAddOnEntity) {
					this.spacingAddOn = attributes.spacingAddOn;
					this.spacingAddOnId = attributes.spacingAddOn.id;
				} else {
					this.spacingAddOn = new Models.SpacingAddOnEntity(attributes.spacingAddOn);
					this.spacingAddOnId = this.spacingAddOn.id;
				}
			} else if (attributes.spacingAddOnId !== undefined) {
				this.spacingAddOnId = attributes.spacingAddOnId;
			}

			if (attributes.cargo) {
				if (attributes.cargo instanceof Models.CargoEntity) {
					this.cargo = attributes.cargo;
					this.cargoId = attributes.cargo.id;
				} else {
					this.cargo = new Models.CargoEntity(attributes.cargo);
					this.cargoId = this.cargo.id;
				}
			} else if (attributes.cargoId !== undefined) {
				this.cargoId = attributes.cargoId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
