import useAsync from 'Hooks/useAsync';
import { store } from 'Models/Store';
import { getAllAdditionalBookingOptions } from 'Services/Api/_HumanWritten/AdditionalBookingOptionsService';
import { fetchInvoicedUser, fetchUserById } from 'Services/Api/_HumanWritten/UserService';
import alertToast from 'Util/ToastifyUtils';
import { stringNotEmpty } from 'Util/TypeGuards';
import { InvoicedUserCRUDProps } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserCRUD';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import {
	AddOnsPriceEntity,
	UserEntity,
	PriceEntity,
	MeasurementEntity,
} from 'Models/Entities';

export function useInvoicedUserCRUD({ mode, userId }: InvoicedUserCRUDProps) {
	return useAsync(async () => {
		if (mode !== EntityFormMode.CREATE && stringNotEmpty(userId)) {
			return fetchInvoicedUser(userId);
		}

		try {
			const additionalOptions = await getAllAdditionalBookingOptions();

			const additionalOptionPrices = additionalOptions?.map(x => new AddOnsPriceEntity({
				id: x.id,
				price: x.staticPrice,
				additionalBookingOption: x,
			}));
			// Create base user entity
			return new UserEntity({
				prices: store.measurements.map(x => new PriceEntity({
					measurementId: x.id,
					measurement: new MeasurementEntity({
						id: x.id,
						label: x.label,
						value: x.value,
						measurementType: x.measurementType,
					}),
				})).sort((m1, m2) => m1.measurement.value > m2.measurement.value ? 1 : -1),
				addOnsPrices: additionalOptionPrices,
			});
		} catch (e) {
			alertToast('Could not fetch additional options', 'error');
		}
		return new UserEntity();
	}, []);
}

export async function getUser(userId: string) {
	const user = await fetchUserById(userId);
	return new UserEntity(user);
}
