import {
	BookingEntity,
} from 'Models/Entities';
import { getBookedSummaryAttributes } from './BookingSummaryType';
import { FetchBookingForBookingDetailedView } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { FetchEventBookingForBookingDetailedView } from '../Api/_HumanWritten/BookingService/EventsBookingService';

// See serverside\src\Utility\ExpressionUtilities.cs for more info for how the paths are used.

/**
 * The expression path of 'bookingSummaries.recentSummary.ferryTrip.departureDateTime' links to the booking's most
 * recent BOOKED alteration with a ferry trip.
 */
export const PATH_TO_DEPARTURE_DATETIME_OF_BOOKING = 'bookingSummaries.recentSummary.ferryTrip.departureDateTime';

/**
 * The expression path of 'bookingSummaries.recentSummary.ferryTrip.id' links to the booking's most
 * recent BOOKED alteration with a ferry trip.
 */
export const PATH_TO_TRIP_ID_OF_BOOKING = 'bookingSummaries.recentSummary.ferryTrip.id';

export async function getBookingForBookingDetailedView(
	bookingId: string,
	userId: string | null = null,
): Promise<BookingEntity | undefined> {
	try {
		const booking = await FetchBookingForBookingDetailedView(bookingId, userId);
		if (booking == null) {
			return undefined;
		}

		return new BookingEntity(booking);
	} catch (e) {
		console.error('Something went wrong while fetching the booking.');
	}

	return undefined;
}

export async function getEventBookingForBookingDetailedView(
	bookingId: string,
	userId: string | null = null,
): Promise<BookingEntity | undefined> {
	try {
		const booking = await FetchEventBookingForBookingDetailedView(bookingId, userId);
		if (booking == null) {
			return undefined;
		}

		return new BookingEntity(booking);
	} catch (e) {
		console.error('Something went wrong while fetching the event booking.');
	}

	return undefined;
}

function getBookingAttributesForDetailView(userId: string | null = null) {
	// booking summary nested attributes does not include
	//
	//     ferry trip
	//         space capacity
	//         dynamic prices
	//
	return `
		bookingStatus
		isEventBooking
		transactionStatus
		bookedSummary {
			${getBookedSummaryAttributes(true, userId)}
		}
		cancellationFee
		cancellationCutoffHours
		alterationFee
	`;
}
