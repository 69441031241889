import { MeasurementEntity } from 'Models/Entities';
import { action } from 'mobx';
import moment from 'moment/moment';
import { getDayOfTheWeek } from 'Util/_HumanWritten/DayOfWeekUtils';
import { formatAMPM } from 'Util/_HumanWritten/TimeUtils';
import * as Enums from 'Models/Enums';
import { isNullOrUndefined } from 'Util/TypeGuards';
import * as Models from 'Models/Entities';

export interface IFerryTripDto {
	/**
	 * The Id of the ferry trip.
	 * Used for fetching the bookings and the re-fetch of the ferry trip.
	 */
	id: string;
	/**
	 * The Id of the route associated to the ferry trip.
	 * Used for fetching the additional booking options that are associated to the route.     */
	routeId: string;
	/**
	 * The Id of the ferry associated to the ferry trip.
	 * Used for fetching the additional booking options that are associated to the ferry.
	 */
	ferryId: string;
	/**
	 * The long version of the departure location.
	 * Is displayed in the top navbar of the check-in tab on web.
	 */
	departureLongName: string;
	/**
	 * The short version of the departure location.
	 * Is displayed in the top navbar of the check-in tab on mobile (or smaller screens).
	 */
	departureShortName: string;
	/**
	 * The long version of the destination location.
	 * Is displayed in the top navbar of the check-in tab on web.
	 */
	destinationLongName: string;
	/**
	 * The short version of the destination location.
	 * Is displayed in the top navbar of the check-in tab on mobile (or smaller screens).
	 */
	destinationShortName: string;
	/**
	 * The departure date/time of the ferry trip.
	 * This is displayed in the top navbar of the check-in tab on mobile and web.
	 */
	departureDateTime: Date;
	/**
	 * The arrival date/time of the ferry trip.
	 * This is displayed in the top navbar of the check-in tab on mobile and web.
	 */
	arrivalDateTime: Date;
	/**
	 * Whether or not the ferry trip is closed for online bookings (from customers).
	 * This will be used for functionality around closing/opening a ferry trip up to online bookings.
	 */
	closed: boolean;
	/**
	 * The measurement entities associated to a ferry trip.
	 * This will used to populate the vehicle and trailer length and weight dropdown menus.
	 */
	measurements: MeasurementEntity[];
	/**
	 * Whether or not the ferry has any initial vehicle capacity.
	 * This will determine whether or not the vehicle tab will show on the check-in page.
	 */
	hasVehicleCapacity: boolean;
}

export class FerryTripDto implements IFerryTripDto {
	constructor(attributes?: Partial<IFerryTripDto>) {
		this.assignAttributes(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	private assignAttributes(attributes?: Partial<IFerryTripDto>) {
		if (isNullOrUndefined(attributes)) {
			return;
		}
		if (attributes.id !== undefined) {
			this.id = attributes.id;
		}
		if (attributes.departureDateTime !== undefined) {
			if (attributes.departureDateTime === null) {
				this.departureDateTime = attributes.departureDateTime;
			} else {
				this.departureDateTime = moment(attributes.departureDateTime).toDate();
			}
		}
		if (attributes.arrivalDateTime !== undefined) {
			if (attributes.arrivalDateTime === null) {
				this.arrivalDateTime = attributes.arrivalDateTime;
			} else {
				this.arrivalDateTime = moment(attributes.arrivalDateTime).toDate();
			}
		}
		if (attributes.ferryId !== undefined) {
			this.ferryId = attributes.ferryId;
		}
		if (attributes.routeId !== undefined) {
			this.routeId = attributes.routeId;
		}
		if (attributes.closed !== undefined) {
			this.closed = attributes.closed;
		}
		if (attributes.measurements !== undefined && Array.isArray(attributes.measurements)) {
			for (const model of attributes.measurements) {
				if (isNullOrUndefined(this.measurements)) {
					this.measurements = [];
				}
				if (model instanceof Models.MeasurementEntity) {
					this.measurements.push(model);
				} else {
					this.measurements.push(new Models.MeasurementEntity(model));
				}
			}
		}
		if (attributes.departureShortName !== undefined) {
			this.departureShortName = attributes.departureShortName;
		}
		if (attributes.departureLongName !== undefined) {
			this.departureLongName = attributes.departureLongName;
		}
		if (attributes.destinationShortName !== undefined) {
			this.destinationShortName = attributes.destinationShortName;
		}
		if (attributes.destinationLongName !== undefined) {
			this.destinationLongName = attributes.destinationLongName;
		}
		if (attributes.hasVehicleCapacity !== undefined) {
			this.hasVehicleCapacity = attributes.hasVehicleCapacity;
		}
	}

	id: string;
	routeId: string;
	ferryId: string;
	departureLongName: string;
	departureShortName: string;
	destinationLongName: string;
	destinationShortName: string;
	departureDateTime: Date;
	arrivalDateTime: Date;
	closed: boolean;
	measurements: MeasurementEntity[];
	hasVehicleCapacity: boolean;

	public tripTimeFormatted(): string | null {
		return `${this.departTimeFormatted() ?? ''} - ${this.arrivalTimeFormatted() ?? ''}`;
	}

	public departTimeFormatted(): string | null {
		if (!this.departureDateTime) {
			return '';
		}
		return formatAMPM(this.departureDateTime);
	}

	public arrivalTimeFormatted(): string | null {
		if (!this.arrivalDateTime) {
			return null;
		}
		return formatAMPM(this.arrivalDateTime);
	}

	public getDisplayTimeDayDate(): string {
		const momentDateTime = moment(this.departureDateTime);
		const res = `${this.tripTimeFormatted()}, `
			+ `${getDayOfTheWeek(momentDateTime.day(), false)} `
			+ `${momentDateTime.format('DD MMM YYYY')}`;
		return res;
	}

	public getFilteredMeasurements(measurementType?: Enums.measurementType): MeasurementEntity[] {
		if (isNullOrUndefined(measurementType)) {
			return this.measurements;
		}
		return this.measurements?.filter(x => x.measurementType === measurementType);
	}

	public getMinimumMeasurement(measurementType: Enums.measurementType = 'LENGTH'): MeasurementEntity {
		return this.measurements
			.filter(x => x.measurementType === measurementType)
			.sort((m1, m2) => (m1.value > m2.value ? 1 : -1))[0];
	}
}
