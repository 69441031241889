import { useContext } from 'react';
import {
	CheckInStoreContext,
	ICheckInStore,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/context/CheckInContext';

/**
 * This hook should be used to access the check-in context.
 */
export default function useCheckInStore(): ICheckInStore {
	return useContext(CheckInStoreContext);
}
