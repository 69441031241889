import * as React from 'react';
import { StickyBottomBar } from 'Views/Components/_HumanWritten/StickyBottomBar/StickyBottomBar';
import {
	Button,
	Colors,
	Display,
	ICbButtonProps,
	Sizes,
} from 'Views/Components/Button/Button';

export interface CheckInBottomBarBtnProps extends Partial<ICbButtonProps> {
	content: React.ReactNode;
}

export interface CheckInBottomBarProps {
	closeBtnProps: CheckInBottomBarBtnProps;
	confirmBtnProps: CheckInBottomBarBtnProps;
}

export function CheckInBottomBar({
	closeBtnProps,
	confirmBtnProps,
}: CheckInBottomBarProps) {
	return (
		<StickyBottomBar className="check-in-bottom-bar">
			<Button
				key="close"
				className="close"
				display={Display.Solid}
				colors={Colors.Black}
				sizes={Sizes.Medium}
				{...{ ...closeBtnProps, content: undefined }}
			>
				{closeBtnProps.content}
			</Button>
			<Button
				key="confirm"
				className="confirm"
				display={Display.Solid}
				colors={Colors.Primary}
				sizes={Sizes.Medium}
				{...{ ...confirmBtnProps, content: undefined }}
			>
				{confirmBtnProps.content}
			</Button>
		</StickyBottomBar>
	);
}
