import moment from 'moment/moment';
import { FerryTripEntity } from 'Models/Entities';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';

// Checks if the return trip date is after the departure trip date
export const isInvalidReturnTripDate = (wizardData: BookingWizardData) => {
	return moment(wizardData.startDate).toDate() > moment(wizardData.endDate).toDate();
};

// Checks if the start/end date is the same as the selected trip date
export const isSameDate = (
	wizardData: BookingWizardData,
	selectedTrips: SelectedTrips,
	departureTicket: boolean,
) => {
	let sidebarDate = true;
	let selectedTabDate = true;
	if (departureTicket) {
		sidebarDate = moment(selectedTrips.departingTrip?.startDate).format('YYYY-MM-DD')
			=== moment(wizardData.startDate).format('YYYY-MM-DD');
		selectedTabDate = moment(selectedTrips.departingTrip?.startDate).format('YYYY-MM-DD')
			=== moment(wizardData.ticketSelectionStartDate).format('YYYY-MM-DD');
	}
	if (!departureTicket && !!selectedTrips.returningTrip) {
		sidebarDate = moment(selectedTrips.returningTrip.startDate).format('YYYY-MM-DD')
			=== moment(wizardData.endDate).format('YYYY-MM-DD');
		selectedTabDate = moment(selectedTrips.returningTrip.startDate).format('YYYY-MM-DD')
			=== moment(wizardData.ticketSelectionEndDate).format('YYYY-MM-DD');
	}
	return (sidebarDate || selectedTabDate);
};

// Checks if the ferry filter route is the same as the selected ferry ticket route
export const isSameRoute = (
	wizardData: BookingWizardData,
	selectedTrips: SelectedTrips,
	departureTicket: boolean,
) => {
	if (departureTicket) {
		return selectedTrips.departingTrip?.route.departureId === wizardData.fromLocationId
			&& selectedTrips.departingTrip?.route.destinationId === wizardData.toLocationId;
	} if (!departureTicket && !!selectedTrips.returningTrip) {
		return selectedTrips.returningTrip.route.departureId === wizardData.toLocationId
		&& selectedTrips.returningTrip.route.destinationId === wizardData.fromLocationId;
	}
	return false;
};

// If the return trip date is after the departure trip date, unselect the return trip
export const unselectInvalidDateTicket = (
	wizardData: BookingWizardData,
	departureTrip?: FerryTripEntity,
	returningTrip?: FerryTripEntity,
) => {
	if (isInvalidReturnTripDate(wizardData)) {
		wizardData.endDate = wizardData.startDate;
		wizardData.ticketSelectionEndDate = wizardData.endDate;

		// If the new selected departure ferry ticket departure dateTime
		// is after the select destination ticket dateTime, deselect it
		if (!!returningTrip && !!departureTrip
			&& (departureTrip.departureDateTime >= returningTrip.departureDateTime)) {
			wizardData.returningTicketId = '';
		}
	}
};

// Check if a date is before today's date (does not take time into consideration)
export function isDateBeforeToday(dateToCompare: Date) {
	if (isNotNullOrUndefined(dateToCompare)) {
		const currentDateTime = new Date();
		const dateOfToday = new Date(
			currentDateTime.getUTCFullYear(),
			currentDateTime.getUTCMonth(),
			currentDateTime.getUTCDate(),
		);

		const date = new Date(dateToCompare);
		const formattedDateToCompare = new Date(
			date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
		);

		return dateOfToday > formattedDateToCompare;
	}
	return true;
}
