import * as React from 'react';
import { useMemo } from 'react';
import { useLottie } from 'lottie-react';
import defaultSpinner from './LottieFiles/default-ship-verified.json';
import customSpinner from './LottieFiles/custom-ship-verified.json';
import { themeStore } from '../../../../Models/ThemeStore';
import { SetLottieColour } from './LottieColourChanger';

export function LottieShipVerified() {
	const options = useMemo(() => {
		// Set the colour of the spinner depending on the application brand colouring
		const colouredSpinner = SetLottieColour(
			customSpinner,
			themeStore.config.brandColourPrimary,
			themeStore.config.brandColourSecondary,
		);

		return {
			loop: true,
			autoplay: true,
			animationData: colouredSpinner ?? defaultSpinner,
		};
	}, []);

	const { View } = useLottie(options);

	return (
		<div className="">
			{View}
		</div>
	);
}
