import * as React from 'react';
import EventBookingFormView from './EventBookingFormView';
import { DisableContinueState } from '../EventCheckInUtils';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';

export interface EventBookingFormProps {
	booking: EventCheckInBookingOverviewDto;
	/**
	 * Default mode is VIEW
	 */
	checkIn?: () => Promise<boolean>;
	className?: string;
}

/**
 * It was decided it is easier to manage view/edit/add mode using two components.
 *
 * One for VIEW and one for EDIT/ADD. This would produce more readable code.
 */
function EventBookingForm({
	booking,
	checkIn,
	className,
}: EventBookingFormProps) {
	return (
		<EventBookingFormView
			key={booking.id}
			booking={booking}
			checkIn={checkIn}
			className={className}
		/>
	);
}

export default EventBookingForm;
