// eslint-disable-next-line import/prefer-default-export
import { FerryTripEntity } from 'Models/Entities';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { isSameDate } from 'Util/_HumanWritten/DateSelectorUtils';
import { TabSelected } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

// Filters the ferry trips to only have the most recently departed trip for that day
// (along with any trips that haven't departed yet)
export function ferryTripFilterDeparted(
	currentDay: Date,
	ferryTrips?: FerryTripEntity[],
): FerryTripEntity[] {
	if (isNullOrUndefined(ferryTrips)) {
		return [];
	}
	const filteredList = ferryTrips.filter(filteredTrip => {
		return isSameDate(filteredTrip.startDate, currentDay);
	});

	// Filter all the departed ferry trips so we can find the most recently departed trip
	const departedFerryList = filteredList.filter(x => {
		return x.departureDateTime <= new Date();
	});

	// Filter all the ferry trips that have not yet departed
	const filteredFerryList = filteredList.filter(x => {
		return x.departureDateTime > new Date();
	});

	// If the departure ferry trip list has anything in it, find the trip at the end of the list
	if (departedFerryList.length > 0) {
		filteredFerryList.unshift(departedFerryList[departedFerryList.length - 1]);
	}
	return filteredFerryList;
}

export function ferryTripFilterDepartedTicketTab(
	ferryTrips: TicketsTabTrip[],
	currentDay: Date,
	tabSelected: TabSelected,
): TicketsTabTrip[] {
	let initialList = ferryTrips;
	if (tabSelected === 'vehicle') {
		initialList = initialList.filter(x => x.vehicleSpacesTotal > 0);
	}

	const filteredList = initialList.filter(filteredTrip => {
		return isSameDate(filteredTrip.startDate, currentDay);
	});

	// Filter all the departed ferry trips so we can find the most recently departed trip
	const departedFerryList = filteredList.filter(x => {
		return x.startDate <= new Date();
	});

	// Filter all the ferry trips that have not yet departed
	const filteredFerryList = filteredList.filter(x => {
		return x.startDate > new Date();
	});

	// If the departure ferry trip list has anything in it, find the trip at the end of the list
	if (departedFerryList.length > 0) {
		filteredFerryList.unshift(departedFerryList[departedFerryList.length - 1]);
	}
	return filteredFerryList;
}
