import * as React from 'react';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { isNotNullOrUndefined } from 'Util/TypeGuards';

export interface TagProps {
	text: string;
	customStyles?: CSSProperties;
	tagClassName?: string;
}

export function Tag({
	text,
	customStyles,
	tagClassName,
}: TagProps) {
	let className = isNotNullOrUndefined(tagClassName) ? tagClassName : 'tag--primary';
	if (isNotNullOrUndefined(customStyles?.background)) {
		className = '';
	}

	return (
		<span
			className={classNames('tag', className)}
			style={customStyles}
		>
			{text}
		</span>
	);
}
