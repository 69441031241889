import * as React from 'react';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import CheckInDetails, { CheckInDetailsProps } from './CheckInDetails';
import { SideBar } from 'Views/Components/_HumanWritten/SideBar/SideBar';

export interface CheckInDetailsSidebarProps extends CheckInDetailsProps {}

function CheckInDetailsSidebar({ bookingId }: CheckInDetailsSidebarProps) {
	const routes = useCheckInRoutes();

	return (
		<SideBar onClose={() => store.routerHistory.replace(routes.base)}>
			<CheckInDetails bookingId={bookingId} className="pb-xxxl" alwaysOneInputPerRow />
		</SideBar>
	);
}

export default CheckInDetailsSidebar;
