import React from 'react';
import { observer } from 'mobx-react';
import { UserEntity } from 'Models/Entities';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import If from 'Views/Components/If/If';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';
import { isNullOrUndefined } from 'Util/TypeGuards';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';

interface AdditionalOptionsTableProps {
	model: UserEntity;
	isReadOnly?: boolean;
	accountsPage?: boolean;
	setNeedConfirm?: (value: boolean) => void;
	errors?: FormErrors<UserEntity>;
}

function AdditionalOptionsTable({
	model,
	isReadOnly = true,
	accountsPage = false,
	setNeedConfirm,
	errors,
}: AdditionalOptionsTableProps) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableCell textAlign="left">Ticket type</TableCell>
					<If condition={!accountsPage}>
						<TableCell textAlign="left">Space units</TableCell>
					</If>
					<TableCell textAlign="left">Cost</TableCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{model.addOnsPrices.map(addOn => {
					return (
						<TableRow key={addOn.id}>
							<TableCell textAlign="left">{addOn.additionalBookingOption.name}</TableCell>
							<If condition={!accountsPage}>
								<TableCell textAlign="left">1</TableCell>
							</If>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field icon-money icon-left"
									id={`additional-option-price-${addOn.additionalBookingOption.name}`}
									model={addOn}
									modelProperty="price"
									isDisabled={isReadOnly}
									placeholder="0"
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
									}}
									errors={isNullOrUndefined(addOn.price) ? errors?.addOnsPrices : ''}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}

export default observer(AdditionalOptionsTable);
