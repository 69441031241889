import { BookingEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { isBeforeNow } from 'Util/_HumanWritten/GeneralHelpers/DateTimeHelpers';

export function canEditBooking(booking: BookingEntity): boolean {
	if (store.isStaff) {
		return true;
	}

	// If the booking does not have `PAID` status or `AWAITING status and the user is not staff+
	if (((booking.transactionStatus !== 'PAID'
			&& booking.transactionStatus !== 'AWAITING')
		&& !store.isStaff)) {
		return false;
	}

	// If any of the alterations associated to the booking have `INVOICED` transactions
	if (booking.alterations.some(x => x.isInvoiced) && !store.isStaff) {
		return false;
	}

	if (!booking.bookedSummary) {
		throw new Error('booking.bookedSummary is undefined');
	}

	if (isBeforeNow(booking.bookedSummary.ferryTrip.departureDateTime)) {
		return false;
	}

	if (booking.bookedSummary.ferryTrip.closed) {
		return false;
	}

	if (booking.checkedIn) {
		return false;
	}

	return true;
}
