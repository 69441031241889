import axios from 'axios';
import { PRICING_API, SERVER_URL, WIZARD_DATA_API } from '../../../Constants';
import { serialiseDate } from '../../../Util/AttributeUtils';
import { measurementPrice } from '../../CustomGql/FerryTripEntityType';
import { CargoEntity, CargoTypeEntity, TowOnTypeEntity } from '../../../Models/Entities';
import { FerryBookingCreationDto } from './BookingService/FerryTripBookingService';

export interface TicketsTabTrip {
	id: string;
	isWaitListed: boolean,
	isHidden: boolean | null,
	isClosed: boolean | null,
	startDate: Date;
	tripTimeTakenMinutes: number;

	// Ferry trip Capacity
	passengerSpacesAvailable: number;
	passengerSpacesTotal: number;
	vehicleSpacesAvailable: number;
	vehicleSpacesTotal: number;
	trailerSpotsAvailable: number;
	trailerSpotsTotal: number;
	weightAvailable: number;
	totalWeight: number;
}

export interface TicketsTabData {
	departureTickets: TicketsTabTrip[];
	returningTickets: TicketsTabTrip[];
}

export interface CargoTabDto {
	cargoTypes: CargoTypeEntity[],
	towOnTypes: TowOnTypeEntity[],
	cargoMeasurementPrices: { [key: string]: number },
	towOnMeasurementPrices: { [key: string]: number },
	previousCargos?: CargoEntity[],
}

export interface TicketsTabStateData {
	departureTickets: TicketsTabTrip[] | null;
	returningTickets: TicketsTabTrip[] | null;
}

export async function GetTicketsTabData(
	signal: AbortSignal,
	departureLocationId: string,
	destinationLocationId: string,
	dateToFetchNoFormat: Date,
	takeDeparture: boolean,
	oneWayAlteration: boolean,
	bookingBeingEditedId: string | null,
	isStaff: boolean,
	userId?: string,
): Promise<TicketsTabData> {
	const dateToFetch = serialiseDate(dateToFetchNoFormat);
	const { data } = await axios.get<TicketsTabData>(
		`${WIZARD_DATA_API}/tickets-tab`,
		{
			params: {
				departureLocationId,
				destinationLocationId,
				dateToFetch,
				takeDeparture,
				bookingBeingEditedId,
				userId,
				isStaff,
			},
			signal,
		},
	);

	const tripsToReturn = takeDeparture ? data.departureTickets : data.returningTickets;
	tripsToReturn.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	tripsToReturn.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	if (oneWayAlteration) {
		return {
			departureTickets: tripsToReturn,
			returningTickets: [],
		};
	}

	data.departureTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	data.departureTickets = data.departureTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	data.returningTickets.forEach(x => {
		x.startDate = new Date(x.startDate);
	});
	data.returningTickets = data.returningTickets.sort(
		(a, b) => a.startDate > b.startDate ? 1 : -1,
	);

	return data;
}

export async function FetchCargoTabData(
	request: FerryBookingCreationDto,
): Promise<CargoTabDto | null> {
	try {
		const result = await axios.post(
			`${WIZARD_DATA_API}/get-vehicle-tab-data`, request);
		return {
			cargoTypes: result.data.cargoTypes.map((x: CargoTypeEntity) => new CargoTypeEntity(x)),
			towOnTypes: result.data.towOnTypes.map((x: TowOnTypeEntity) => new TowOnTypeEntity(x)),
			cargoMeasurementPrices: result.data.cargoMeasurementPrices,
			towOnMeasurementPrices: result.data.towOnMeasurementPrices,
			previousCargos: result.data.previousCargos.map((x: CargoEntity) => new CargoEntity(x)),
		} as CargoTabDto;
	} catch (e) {
		console.error(e);
		return null;
	}
}
