import * as React from 'react';
import { observer } from 'mobx-react';
import Tabs from 'Views/Components/Tabs/Tabs';
import useScreenSize from 'Hooks/useScreenSize';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { EventCheckInViewState } from '../EventCheckInView';
import { checkedInCustomTicketPassengers, getTotalCustomTicketPassengers } from '../EventCheckInUtils';
import useEventCheckInStore from 'Hooks/useEventCheckInStore';
import EventCheckInList from '../CheckInList/EventCheckInList';
import { EventCheckInTabLabel } from './EventCheckInTabLabel';
import EventCheckInTable from '../EventCheckInTable/EventCheckInTable';

export const EVENT_CHECK_IN_PASSENGER_TAB_ID = 'passenger-tab';

export interface EventCheckInTabInfo {
	// Passenger spaces
	totalPassengers: number;
	checkedInPassengers: number;
}

export interface EventCheckInTabsProps {
	state: EventCheckInViewState;
}

function EventCheckInTabs(props: EventCheckInTabsProps) {
	const { state } = props;
	const {
		showCheckedIn,
	} = state;

	const { isIpadMiniLandscape } = useScreenSize();
	const eventCheckInStore = useEventCheckInStore();
	const { bookings } = eventCheckInStore;

	const passengerTypeResponse = usePassengerTypes();

	const tabInfo = React.useMemo<EventCheckInTabInfo>(() => {
		if (!bookings || bookings.length === 0) {
			return {
				totalPassengers: 0,
				checkedInPassengers: 0,
			};
		}
		return {
			totalPassengers: getTotalCustomTicketPassengers(bookings),
			checkedInPassengers: checkedInCustomTicketPassengers(bookings),
		};
	}, [bookings, passengerTypeResponse.type]);

	const forCheckIn = !showCheckedIn;

	function getPassengerTabLabel() {
		return `${tabInfo.checkedInPassengers}/${tabInfo.totalPassengers}`;
	}

	const tabs = [
		{
			key: 'passenger-tab',
			name: (
				<EventCheckInTabLabel
					id={EVENT_CHECK_IN_PASSENGER_TAB_ID}
					iconName="chevron-passenger"
					content={getPassengerTabLabel()}
				/>
			),
			component: isIpadMiniLandscape
				? <EventCheckInList key="passenger" forCheckIn={forCheckIn} />
				: <EventCheckInTable key="passenger" forCheckIn={forCheckIn} />,
		},
	];

	return (
		<Tabs
			className="check-in-tab check-in-tab--primary"
			currentTab={0}
			tabs={tabs}
		/>
	);
}

export default observer(EventCheckInTabs);
