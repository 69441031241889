import React from 'react';

export interface WhiteBackgroundProps {
	content: React.ReactElement;
	noMargin?: boolean;
}
export const WhiteBackground = ({ content, noMargin = false }: WhiteBackgroundProps) => {
	return (
		<div className={`white-background ${noMargin ? 'no-margin' : ''}`}>
			{content}
		</div>
	);
};
