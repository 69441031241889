import * as React from 'react';
import { observer } from 'mobx-react';
import { EventDetailEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { GetFormattedDate, GetFormattedTime } from 'Views/Components/_HumanWritten/Events/EventCards/EventUtils';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ClockImg from 'images/clock.svg';
import CalendarImg from 'images/calender.svg';
import MapImg from 'images/location-pin.svg';
import { EventDetailSection } from './EventDetailSection';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { WhiteBackground } from 'Views/Components/_HumanWritten/WhiteBackground';
import { useHistory } from 'react-router';
import { stringNotEmpty } from 'Util/TypeGuards';
import If from 'Views/Components/If/If';
import {
	Button,
	Colors,
	Display,
} from 'Views/Components/Button/Button';
import {
	clearEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	clearEventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';

export interface EventDetailedViewInnerProps {
	event: EventDetailEntity;
	showButtons: boolean;
}

function getImages(event: EventDetailEntity) {
	const images = event.supportingImages.map((x, index) => (
		<div className="event-detailed-view__supporting-image-container">
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
			<img
				className="event-detailed-view__supporting-image"
				src={`/api/files/${x.imageId}`}
				key={x.imageId}
				alt=""
			/>
		</div>
	));
	images.unshift(
		<div className="event-detailed-view__supporting-image-container">
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
			<img
				className="event-detailed-view__supporting-image"
				src={`/api/files/${event?.headerImage?.imageId}`}
				key={event?.headerImage?.imageId}
				alt=""
			/>
		</div>,
	);
	return images;
}

function EventDetailedViewInner({
	event,
	showButtons,
}: EventDetailedViewInnerProps) {
	const { isIpad } = useDeviceDetect();
	const history = useHistory();

	const eventHasStarted = !event.ferryTrip?.departureDateTime || (event.ferryTrip.departureDateTime < new Date());

	// Set document title
	document.title = 'Event details';

	let buttonText = 'Book tickets';

	if (event.cancelled) {
		buttonText = 'Event cancelled';
	} else if (event.ferryTrip?.closed || eventHasStarted) {
		buttonText = 'Event closed';
	} else if (event?.remainingCapacity === 0) {
		buttonText = 'Sold out';
	}

	return (
		<WhiteBackground
			content={(
				<div className={`event-detailed-view__container ${store.isStaff ? 'bottom-padding' : ''}`}>
					<If condition={showButtons}>
						<div className="navigation-header">
							<Button
								className="back-navigation hide-underline icon-chevron-left icon-left event-detailed-view__back"
								icon={{ icon: 'arrow-left', iconPos: 'icon-left' }}
								onClick={() => {
									store.routerHistory.goBack();
								}}
							>
								Back
							</Button>
						</div>
					</If>
					<div className="event-detailed-view__header-container">
						<Carousel
							className="event-detailed-view__supporting-image-carousel"
							autoPlay
							infiniteLoop
							showArrows={!isIpad}
							showThumbs={false}
							showIndicators
							showStatus={false}
							interval={3000}
							dynamicHeight={false}
							stopOnHover
						>
							{getImages(event)}
						</Carousel>
						<div className="event-detailed-view__title-container">
							<div className="event-detailed-view__title">
								<h2 className="event-detailed-view__event-name">{event.name}</h2>
								{/* <If condition={store.isManager && !isIpad}> */}
								{/* 	<Button */}
								{/* 		className="event-detailed-view__edit-event" */}
								{/* 		sizes={Sizes.Medium} */}
								{/* 		colors={Colors.Secondary} */}
								{/* 		display={Display.Text} */}
								{/* 		icon={{ icon: 'edit-box', iconPos: 'icon-bottom' }} */}
								{/* 		onClick={() => {}} */}
								{/* 	/> */}
								{/* </If> */}
							</div>
							<If condition={showButtons}>
								<Button
									className="event-detailed-view__title-button"
									display={Display.Solid}
									colors={Colors.Secondary}
									disabled={eventHasStarted || event.ferryTrip?.closed || event.cancelled || (event?.remainingCapacity ?? 0) < 1}
									onClick={() => {
										clearEventBookingWizardData();
										clearEventBookingTransactionIdFromStorage();
										history.push(`/event-booking-wizard?eventId=${event.id}`);
									}}
								>
									{`${buttonText}${event.hidden ? ' (private)' : ''}`}
								</Button>
							</If>
						</div>
					</div>
					<div className="event-detailed-view__body">
						<div className="underline" />
						<EventDetailSection
							title="Event details"
							body={(
								<>
									<p className="event-detailed-view__date">
										<img src={CalendarImg} alt="calendar-img" />
										{GetFormattedDate(event.ferryTrip!.startDate, event.ferryTrip!.endDate)}
									</p>
									<p className="event-detailed-view__time">
										<img src={ClockImg} alt="clock-img" />
										{GetFormattedTime(event.ferryTrip!.startTime, event.ferryTrip!.endTime)}
									</p>
									<If condition={stringNotEmpty(event.googleMapUrl) && stringNotEmpty(event.address)}>
										<p className="event-detailed-view__location">
											<img src={MapImg} alt="map-img" />
											{event.address} (
											<a
												className="hide-underline"
												href={event.googleMapUrl}
											>
												View on Google Maps
											</a>
											)
										</p>
									</If>
								</>
							)}
						/>
						<EventDetailSection
							title="About"
							body={<p dangerouslySetInnerHTML={{ __html: event.description }} />}
						/>
						<EventDetailSection
							title="Pricing"
							width="200px"
							body={(
								<>
									{event.customTicketTypes.map(x => {
										return (
											<div key={x.id} className="event-detailed-view__price">
												<p>{x.pluralName}</p>
												<p><strong>{displayAsAud(x.price)}</strong></p>
											</div>
										);
									})}
								</>
							)}
						/>
						<If condition={store.isStaff && showButtons}>
							<Button
								className="event-detailed-view__check-in-button"
								display={Display.Solid}
								colors={Colors.BookNow}
								onClick={() => {
									history.push(`/event-check-in/${event.id}`);
								}}
							>
								Check-in
							</Button>
						</If>
					</div>
				</div>
			)}
		/>
	);
}

export default observer(EventDetailedViewInner);
