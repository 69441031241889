import * as React from 'react';

export interface CustomSpinnerProps {
	loaderClassname?: string;
	containerClassname?: string;
}

export default function CustomSpinner({
	loaderClassname,
	containerClassname,
}: CustomSpinnerProps) {
	return (
		<div className={`spinner-container ${containerClassname}`}>
			<div className={`simple-loader ${loaderClassname}`} />
		</div>
	);
}
