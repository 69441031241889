import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { GiftCertificateUsageEntity } from 'Models/Entities';
import useAsync from 'Hooks/useAsync';
import { fetchGiftCertificateUsagesByTransactionId } from 'Util/_HumanWritten/FetchGiftCertificates';
import { getGiftCertificateRedeemText } from 'Services/Api/_HumanWritten/ConfigGeneralService';
import { SteeringWheelSpinner } from 'Views/Components/_HumanWritten/Lottie/SteeringWheelSpinner';
import { FEATURE_IMAGE_5_URL } from 'Constants';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { downloadInvoice } from 'Services/Api/_HumanWritten/InvoiceService';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import If from 'Views/Components/If/If';
import moment from 'moment/moment';
import * as queryString from 'querystring';
import { BOOKING_SUCCESS_PAGE_TITLE } from 'ConstantPageNames';
import { FetchBookingsForCart } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import TripBookingSummaryInner from '../FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryInner';
import { isNullOrUndefined } from 'Util/TypeGuards';

export interface BookingSuccessProps {
	routerProps: RouteComponentProps;
}

export function BookingSuccessOuterWrapper({ routerProps }: BookingSuccessProps) {
	document.title = BOOKING_SUCCESS_PAGE_TITLE;

	// Parse the query params from the page route
	const queryParams = routerProps.location.search.substring(1);
	const params = queryString.parse(queryParams);
	const transactionId = params.id?.toString() ?? '';
	const userId = params.ui?.toString() ?? '';

	const [giftCertificateUsages,
		setGiftCertificateUsages] = useState<GiftCertificateUsageEntity[]>([]);

	const [redeemText, setRedeemText] = useState(null);

	const response = useAsync(async () => {
		return FetchBookingsForCart(
			transactionId,
			userId,
		);
	},
	[transactionId]);

	useEffect(() => {
		if (transactionId !== '') {
			fetchGiftCertificateUsagesByTransactionId(transactionId ?? '')
				.then(usages => {
					// eslint-disable-next-line no-debugger
					setGiftCertificateUsages(usages.data);
					getGiftCertificateRedeemText().then(x => {
						setRedeemText(x.data);
					});
				})
				.catch(_ => {
					setGiftCertificateUsages([]);
				});
		}
	}, [transactionId]);

	if (response.type === 'loading') {
		return <SteeringWheelSpinner />;
	}

	const { data: bookingList } = response;

	const zeroUsageGiftCertificates = giftCertificateUsages.filter(x => x.usedValue === 0)[0];

	if (isNullOrUndefined(bookingList) || bookingList.length === 0) {
		return <div>No bookings found</div>;
	}

	return (
		<div className="body-content booking-success">
			<img className="background-img" src={FEATURE_IMAGE_5_URL} alt="bg-img" />
			<NavigationWrapper className="booking-success__nav" {...routerProps} />
			<div className="booking-success__content">
				<div className="content__header">
					<h2>Paradise awaits</h2>
					<h5>Your trip is successfully booked.</h5>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						buttonProps={{ id: 'download-tax-invoice' }}
						onClick={() => {
							downloadInvoice(transactionId, false);
						}}
					>
						<Icon name="download" />
						Download tax invoice
					</Button>
				</div>
				<div className="content__body">
					<p className="body__digital-ferry-ticket">
						<strong>We are paperless. Save a tree, and please don&apos;t print.</strong>
					</p>
					<p className="body__qr-code-info">Please have your QR code ready on your phone at check-in.</p>
					<If condition={zeroUsageGiftCertificates !== undefined}>
						<div className="gift-voucher-section">
							<h2>{' Your account has been credited.'}</h2>
							<div className="credit-voucher">
								<h3 className="gift-voucher-voucher-number">
									Ferry voucher #{zeroUsageGiftCertificates?.giftCertificate?.code}
								</h3>
								<h2 className="gift-voucher-credited-amount">
									${zeroUsageGiftCertificates?.giftCertificate?.initialValue?.toFixed(2)}
								</h2>
								<p className="gift-voucher-expiry-date">
									Expires: {moment(zeroUsageGiftCertificates?.giftCertificate?.expiryDate)
										.format('DD MMM YYYY')}
								</p>
							</div>
							<div
								className="redeem-text-section"
								dangerouslySetInnerHTML={{ __html: redeemText ?? '<></>' }}
							/>
						</div>
					</If>
					<h2 className="body__title">
						Booking summary:
					</h2>
					<div className="trip-summary-container">
						{/* Parent classname is required to inherit styles for trip card */}
						<TripBookingSummaryInner
							bookings={bookingList}
							refresh={() => {}}
							tripSummaryLocation="booking-success"
							removePreProcessedBooking={() => {}}
							transactionId={transactionId}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
