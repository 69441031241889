/* eslint-disable dot-notation */
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import {
	clearEventBookingWizardData, clearOldEventBookingWizardData,
	EventBookingWizardData,
	getOldEventBookingWizardData, saveOldEventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { NoSpacesModal } from 'Views/Components/_HumanWritten/EventsBookingWizard/Modals/NoSpacesModal';
import { showRestartWizardModal } from 'Views/Components/_HumanWritten/Modal/RestartWizardModal/RestartWizardModal';
import alertToast from 'Util/ToastifyUtils';
import { alertModal } from 'Views/Components/Modal/ModalUtils';
import { Colors } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	clearFerryBookingTransactionIdFromStorage,
	setEventBookingTransactionIdInStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import {
	createEventBooking,
	dataToEventBookingDto,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import {
	isSameEventBookingWizardData,
} from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';
import { useHistory } from 'react-router';

export enum ReservationErrorType {
	LOADING = 'Loading',
	OTHER = 'Other',
	NONE = 'None',
	NO_SPACES = 'NoSpaces',
	DISABLED = 'Disabled',
	CLOSED = 'Closed',
}

export interface ReservationErrorInformation {
	reservationError: ReservationErrorType;
}

export interface EventsReservationTabProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (newData: EventBookingWizardData) => void;
	navigateToNextStep: () => void;
	navigateToPreviousStep: () => void;
	navigateToTickets: () => void;
	onCompleteBookingAttempt: (attemptFailed: boolean) => void;
}

export function EventsReservationTab({
	wizardData,
	onUpdateWizardData,
	navigateToNextStep,
	navigateToPreviousStep,
	navigateToTickets,
	onCompleteBookingAttempt,
}: EventsReservationTabProps) {
	const [shownToast, setShownToast] = useState(false);
	const [errors, setErrors] = useState<ReservationErrorInformation>({
		reservationError: ReservationErrorType.LOADING,
	});
	const history = useHistory();

	const createReservations = async (
		data: EventBookingWizardData,
	): Promise<ReservationErrorInformation> => {
		if (!data) {
			return {
				reservationError: ReservationErrorType.OTHER,
			};
		}

		if (stringNotEmpty(data.userId)) {
			// This endpoint can be used for creating an event booking or editing one that is already in the cart
			const response = await createEventBooking(dataToEventBookingDto(data));

			switch (response.status) {
				// Status will be 200 if the booking was successfully created
				case 200:
					if (response.data !== null) {
						if (response.data !== '') {
							// The endpoint will return the same transaction id when adding a second booking, so we can
							// safely just set the transaction id in session storage
							setEventBookingTransactionIdInStorage(response.data['transactionId']);
							const newData = { ...data };

							newData.bookingToEditId = response.data['bookingId'];
							saveOldEventBookingWizardData(newData);
							onUpdateWizardData(newData);
						}
						return {
							reservationError: ReservationErrorType.NONE,
						};
					}

					// This should never be reached but the failsafe is important. This will be reached if we get a
					// 200 but there is no data in the response. The method we hit will never do this and so we should
					// always hit the return within the response.data !== '' check
					return {
						reservationError: ReservationErrorType.OTHER,
					};
				// Status will be 400 (BAD_REQUEST) if the input was malformed in any way. This shouldn't happen
				// when booking through the wizard but the endpoint can return this so we need to account for
				// this possibility
				case 400:
					return {
						reservationError: ReservationErrorType.OTHER,
					};
				// Status will be 408 (REQUEST_TIMEOUT) if we were unable to acquire the redis lock to perform
				// the space reservation checks. In the endpoint our attempts to get the lock would have `time`d
				// out, and completed without performing the checks or creating the booking
				case 408:
					return {
						reservationError: ReservationErrorType.OTHER,
					};
				// Status will be 409 (CONFLICT) in two scenarios. These are when the space reservation checks
				// fail. We differentiate these based on the error
				// message which is returned because there wasn't a different error code which made sense to use
				// for either of these scenarios
				case 409:
					// The create booking endpoint will always return this data structure for 409 errors
					// eslint-disable-next-line no-case-declarations
					const errorResponse = response.data as unknown as {error: string};

					if (errorResponse.error === ReservationErrorType.NO_SPACES) {
						return {
							reservationError: ReservationErrorType.NO_SPACES,
						};
					}
					if (errorResponse.error === ReservationErrorType.CLOSED) {
						return {
							reservationError: ReservationErrorType.CLOSED,
						};
					}
					if (errorResponse.error === ReservationErrorType.DISABLED) {
						return {
							reservationError: ReservationErrorType.DISABLED,
						};
					}
					if (errorResponse.error === ReservationErrorType.OTHER) {
						return {
							reservationError: ReservationErrorType.OTHER,
						};
					}
			}
			// If we get here, we are dealing with a duplicate. this scenario will be hit by going back and then
			// moving forward through the wizard again. We need the user to be able to progress in this scenario
			// so we can just have the component progress to the next step without any additional handling
			return {
				reservationError: ReservationErrorType.NONE,
			};
		}

		// We shouldn't ever get here because the status code cases will sort everything out for us. We do need
		// to have a fallback for the compiler to not get cranky, and if we get here then we didn't get a 200,
		// which means that the entity wasn't created/updated.
		return {
			reservationError: ReservationErrorType.OTHER,
		};
		// Only data is a dependency as adding setReservationErrors, setReservationErrorsAndGoToNextStep caused
		// multiple versions of the same booking to be made and reserved.
	};

	useEffect(() => {
		const oldWizardData = getOldEventBookingWizardData();

		createReservations(wizardData).then(result => {
			const failed = result.reservationError !== ReservationErrorType.NONE;
			onCompleteBookingAttempt(failed);

			setErrors(result);
		});
	}, []);

	if (errors?.reservationError === ReservationErrorType.NONE) {
		navigateToNextStep();
	}

	if (!shownToast && errors?.reservationError === ReservationErrorType.DISABLED) {
		showRestartWizardModal();
		return <></>;
	}

	if (
		!shownToast
		&& errors?.reservationError === ReservationErrorType.OTHER
	) {
		alertToast('Could not create event booking. Please contact staff.', 'error', '', {
			autoClose: 3000,
		});
		setShownToast(true);
		navigateToPreviousStep();
	}

	const click = async () => {
		clearEventBookingWizardData();
		clearOldEventBookingWizardData();
		clearFerryBookingTransactionIdFromStorage();
		history.push('/upcoming-events');
	};

	if (errors.reservationError === ReservationErrorType.CLOSED) {
		alertModal(
			'Event closed',
			'The event you are trying to book is no longer open to online bookings.',
			{
				cancelText: 'Ok',
				buttonColour: Colors.Black,
			},
		).then(async () => {
			await click();
		}).catch(async () => {
			await click();
		});
	}
	return (
		<>
			<NoSpacesModal
				showModal={errors?.reservationError === ReservationErrorType.NO_SPACES}
				onClickCross={click}
				onClickSelect={click}
			/>
			<If condition={errors.reservationError === ReservationErrorType.LOADING}>
				<LottieSpinner />
			</If>
		</>

	);
}
