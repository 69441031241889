import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { AccountDetailsWrap } from '../Components/_HumanWritten/AccountDetails/AccountDetailsWrap';
import { ACCOUNT_STAFF_VIEW_PAGE_TITLE } from '../../ConstantPageNames';

export interface StaffVisibleAccountDetailsPageProps extends RouteComponentProps {
}

@observer
class StaffVisibleAccountDetailsPage extends React.Component<StaffVisibleAccountDetailsPageProps> {
	render() {
		document.title = ACCOUNT_STAFF_VIEW_PAGE_TITLE;
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		// eslint-disable-next-line prefer-const
		let contents = (
			// eslint-disable-next-line max-len
			<SecuredPage groups={['Super Administrators', 'Staff', 'Manager', 'Admin']}>
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="body-content">
					<AccountDetailsWrap staffView tab="account" />
				</div>
			</SecuredPage>
		);
		return contents;
	}
}

// % protected region % [Override export here] off begin
export default StaffVisibleAccountDetailsPage;
// % protected region % [Override export here] end
