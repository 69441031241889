import * as React from 'react';
import { Colors, Display } from 'Views/Components/Button/Button';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { Text } from 'Views/Components/Text/Text';
import LinkAsButton from '../../LinkAsButton/LinkAsButton';

function EventCheckInTopNavMobile() {
	const routes = useCheckInRoutes(true);

	return (
		<div className="check-in__top-nav">
			<LinkAsButton
				to={routes.base}
				display={Display.Text}
				colors={Colors.Black}
			>
				<Text color="gray">
					<Icon name="chevron-left" />
				</Text>
				<Text color="gray">
					Check-in list
				</Text>
			</LinkAsButton>
		</div>
	);
}

export default EventCheckInTopNavMobile;
