import { MobileTripCalendar } from './Components/MobileTripCalendar';
import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import WebTripCalendar from 'Views/Components/_HumanWritten/FerrySchedule/Components/WebTripCalendar';
import { CustomDateSelector } from 'Views/Components/DatePicker/CustomDateSelector';
import { FerryTripEntity, LocationEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import {
	Button,
	Colors,
	Display, Sizes,
} from 'Views/Components/Button/Button';
import { BulkBookingBottomBar } from 'Views/Components/_HumanWritten/FerrySchedule/BulkBookings/BulkBookingBottomBar';
import useStore from 'Hooks/useStore';
import { useHistory } from 'react-router';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { toggleTrip } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleHelpers';
import { FerryScheduleModel } from 'Views/Components/_HumanWritten/FerrySchedule/FerryScheduleModel';
import { OnBulkBookTrips } from 'Views/Components/_HumanWritten/FerrySchedule/BulkBookings/OnBulkBookTrips';
import { ScheduleFilters } from './ScheduleFilters/ScheduleFilters';
import { action } from 'mobx';
import { checkLeftDate, getNewDate } from 'Util/_HumanWritten/DateSelectorUtils';
import useLocationAndRoutes from 'Hooks/useLocationAndRoutes';

export interface FerryScheduleProps {
	model: FerryScheduleModel;
	loading: boolean;
	refresh: () => void;
	ferryTrips: FerryTripEntity[] | undefined;
	locations: LocationEntity[] | undefined;
	tripToEdit: React.MutableRefObject<FerryTripEntity | null>;
	bulkBookingTrips: string[] | null,
	setBulkBookingTrips: (trips: string[] | null) => void;
}

function FerryScheduleInnerWrap({
	model,
	loading,
	refresh,
	ferryTrips,
	locations,
	tripToEdit,
	bulkBookingTrips,
	setBulkBookingTrips,
} :FerryScheduleProps) {
	const store = useStore();
	const history = useHistory();
	const { isIpad } = useDeviceDetect();

	useLocationAndRoutes();

	const changeDateByWeek = action((increase: boolean, isStaff: boolean) => {
		model.date = getNewDate(model.date as Date, increase, 7, isStaff);
	});

	const isStaff = store.isStaff || store.isManager || store.isAdmin;

	return (
		<>
			<div className="calendar-view__container">
				<If condition={isIpad}>
					<div className="navigation-header ferry-schedule-page-header">
						<Link
							to="/home"
							className="back-navigation hide-underline icon-chevron-left icon-left"
						>
							Back
						</Link>
						<CustomDateSelector
							model={model}
							dateProperty="date"
						/>
					</div>
				</If>
				<ScheduleFilters
					model={model}
					locations={locations}
					bulkBookingTrips={bulkBookingTrips}
					setBulkBookingTrips={setBulkBookingTrips}
					isIpad={isIpad}
				/>
				{isIpad
					? (
						<MobileTripCalendar
							model={model}
							ferryTrips={loading ? undefined : ferryTrips}
							selectedRoute={model.route}
							tripToEdit={tripToEdit ?? null}
						/>
					) : (
						<div className="ferry-schedule__container">
							<Button
								className="ferry-schedule__left-button"
								display={Display.Text}
								colors={Colors.None}
								sizes={Sizes.Large}
								icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
								onClick={() => changeDateByWeek(false, store.isStaff)}
								disabled={isStaff ? false : (checkLeftDate(model.date, 1))}
							/>
							<WebTripCalendar
								model={model}
								ferryTrips={loading ? undefined : ferryTrips}
								selectedDate={new Date().toString()}
								selectedRoute={model.route}
								refetch={refresh}
								tripToEdit={tripToEdit ?? null}
								bulkBookingTrips={bulkBookingTrips}
								onClickTrip={tripId => {
									toggleTrip(
										bulkBookingTrips,
										tripId,
										setBulkBookingTrips,
									);
								}}
							/>
							<Button
								className="ferry-schedule__right-button"
								display={Display.Text}
								colors={Colors.None}
								sizes={Sizes.Large}
								icon={{ icon: 'chevron-right', iconPos: 'icon-right' }}
								onClick={() => changeDateByWeek(true, store.isStaff)}
							/>
						</div>
					)}
			</div>
			<If condition={bulkBookingTrips !== null}>
				<BulkBookingBottomBar
					tripCount={bulkBookingTrips?.length ?? 0}
					onCancel={() => {
						setBulkBookingTrips(null);
					}}
					onBookTrips={() => {
						if (bulkBookingTrips !== null && bulkBookingTrips.length > 0) {
							OnBulkBookTrips(bulkBookingTrips, model.fromLocation, model.toLocation);
							history.push('/booking-wizard');
						}
					}}
				/>
			</If>
		</>
	);
}

export default observer(FerryScheduleInnerWrap);
