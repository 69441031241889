import React from 'react';
import { EventBookingWizardData } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { EventCartSummary } from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/Cart/EventCartSummary';
import { EventDetailEntity } from 'Models/Entities';

export interface EventCartTabProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (newData: EventBookingWizardData) => void;
	/**
	 * This function's only purpose is to re-render the whole component...
	 */
	setRefresh: (refresh: boolean) => void;
	refresh: boolean;
	eventDetails: EventDetailEntity;
}

export function EventCartTab({
	wizardData,
	onUpdateWizardData,
	setRefresh,
	refresh,
	eventDetails,
}: EventCartTabProps) {
	return (
		<EventCartSummary
			wizardData={wizardData}
			onUpdateWizardData={onUpdateWizardData}
			eventDetails={eventDetails}
			setRefresh={setRefresh}
			refresh={refresh}
		/>
	);
}
