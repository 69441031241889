import {
	bookingType,
} from '../../../Models/Enums';
import axios from 'axios';
import { SERVER_URL } from '../../../Constants';

export interface AddToWaitlistDto {
	ferryTripId: string,
	bookingType: bookingType,
	email: string,
	userId?: string,
}

export interface RemoveFromWaitlistDto {
	departureName: string,
	destinationName: string,
	departureDateTime: Date,
}

/**
 * Adds a user/email address to a waitlist for the selected ferry trip
 * @param addToWaitlistDto: contains ferryTripId, bookingType (e.g. vehicle/passenger only), email address and userId
 */
export async function addToWaitList(addToWaitlistDto: AddToWaitlistDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/WaitlistItemEntity/add-to-waitlist`,
		addToWaitlistDto,
	);
}

/**
 * Removes a user/email address from a waitlist
 * @param waitlistItemId: the waitlist item that will be set to `REMOVED`
 */
export async function removeFromWaitlist(waitlistItemId: string) {
	return axios.post(
		`${SERVER_URL}/api/entity/WaitlistItemEntity/remove-from-waitlist?waitlistItemId=${waitlistItemId}`,
	);
}

/**
 * Gets all the (non-removed) items on a ferry trip waitlits
 * @param ferryTripId: the waitlist item that will be set to `REMOVED`
 */
export async function getAllWaitlistItems(ferryTripId: string) {
	return axios.post(
		`${SERVER_URL}/api/entity/WaitlistItemEntity/get-all-waitlist-items?ferryTripId=${ferryTripId}`,
	);
}
