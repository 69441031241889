import gql from 'graphql-tag';
import useAsync from 'Hooks/useAsync';
import { TransactionEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { isNullOrUndefined } from 'Util/TypeGuards';

/**
 * Returns true if the transaction is associated with bookings that only have one alteration with RESERVED status.
 */
export async function transactionHasOnlyNewBookings(transactionId: string | null): Promise<boolean> {
	if (transactionId === null) {
		return false;
	}

	const response = await store.apolloClient.query<{ transactionEntity: TransactionEntity }, any>({
		query: gql`
			query transactionHasInvoicedBookings($transactionId: ID) {
				# ${new Date()}
				transactionEntity(id: $transactionId) {
					hasOnlyNewBookings
				}
			}
		`,
		fetchPolicy: 'network-only',
		variables: { transactionId },
	});

	const { hasOnlyNewBookings } = response?.data?.transactionEntity;

	if (response.error || response.errors || isNullOrUndefined(hasOnlyNewBookings)) {
		console.error('Something went wrong while checking if transaction only has new bookings.');
		return false;
	}

	return hasOnlyNewBookings;
}

/**
 * Hook for transactionHasOnlyNewBookings.
 */
export function useTransactionHasOnlyNewBookings(transactionId: string | null) {
	return useAsync<boolean>(() => transactionHasOnlyNewBookings(transactionId), []);
}

/**
 * Returns true if the transaction has an alteration that is related to an invoiced booking.
 * Invoiced bookings have alterations with transaction status as INVOICED or PENDING_INVOICED.
 */
export async function transactionCanInvoiceBookings(transactionId: string | null): Promise<boolean> {
	if (transactionId === null) {
		return false;
	}

	const response = await store.apolloClient.query<{ transactionEntity: TransactionEntity }, any>({
		query: gql`
			query transactionHasInvoicedBookings($transactionId: ID) {
				# ${new Date()}
				transactionEntity(id: $transactionId) {
					canInvoiceBookings
				}
			}
		`,
		fetchPolicy: 'network-only',
		variables: { transactionId },
	});

	const { canInvoiceBookings } = response?.data?.transactionEntity;

	if (response.error || response.errors || isNullOrUndefined(canInvoiceBookings)) {
		console.error('Something went wrong while checking if transaction can invoice bookings.');
		return false;
	}

	return canInvoiceBookings;
}
