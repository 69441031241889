import React from 'react';
import { StaffBookingsFilters } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsComponent';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { BookingOrderByOption } from 'Util/_HumanWritten/Bookings/FetchBookingsUtils';

export interface StaffBookingsHeaderButtonProps {
	text: string;
	orderByField?: BookingOrderByOption
	filters: StaffBookingsFilters;
	setFilters: (newFilters: StaffBookingsFilters) => void;
}

export function StaffBookingsHeaderButton({
	text,
	orderByField,
	filters,
	setFilters,
}: StaffBookingsHeaderButtonProps) {
	let icon = 'order';

	if (filters.orderBy === orderByField) {
		icon = filters.orderByDescending ? 'arrow-down' : 'arrow-up';
	}
	return (
		<div className="staff-bookings__header-order-btn-wrap">
			{text}
			<If condition={!!orderByField}>
				<Button
					className="staff-bookings__header-order-btn"
					icon={{ icon: icon, iconPos: 'icon-right' }}
					onClick={() => {
						const newFilters = { ...filters };

						// Default will never be used because the if component blocks us from rendering in scenarios
						// where we would use it
						newFilters.orderBy = orderByField ?? BookingOrderByOption.TRIP_DATE;
						if (newFilters.orderBy === orderByField) {
							newFilters.orderByDescending = filters.orderBy === orderByField
								? !filters.orderByDescending
								: true;
						}

						setFilters(newFilters);
					}}
				/>
			</If>

		</div>
	);
}
