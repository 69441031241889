export const REGISTRATION_PAGE_TITLE = 'Register account';
export const REGISTRATION_CONFIRM_PAGE_TITLE = 'Good to go!';
export const LOGIN_PAGE_TITLE = 'Login';
export const HOME_PAGE_TITLE = 'Home';
export const HOME_SEARCH_PAGE_TITLE = 'Search';
export const ACCOUNT_CUSTOMER_PAGE_TITLE = 'Account';
export const UPDATE_PASSWORD_PAGE_TITLE = 'Update password';
export const ACTIVATE_NEW_PASSWORD_PAGE_TITLE = 'Activate new password';
export const FORGOT_PASSWORD_PAGE_TITLE = 'Forgot password';

export const BOOKING_DETAILED_VIEW_PAGE_CUSTOMER_TITLE = 'Booking details';
export const BOOKINGS_CUSTOMER_PAGE_TITLE = 'Bookings';

export const EVENT_BOOKING_DETAILED_VIEW_PAGE_CUSTOMER_TITLE = 'Event booking details';
export const BOOKING_SUCCESS_PAGE_TITLE = 'Booking confirmation';
export const BOOKING_CANCEL_SUCCESS_PAGE_TITLE = 'Booking cancelled';
export const INVOICES_PAGE_TITLE = 'Invoices';
export const REMOVE_FROM_WAITLIST_PAGE_TITLE = 'Remove from waitlist';

// ADMIN
export const ADMIN_PAGE_TITLE = 'Admin';
export const FERRY_SCHEDULE_PAGE_TITLE = 'Schedule';

// STAFF
export const REPORTS_PAGE_TITLE = 'Reports';
export const SEARCH_RESULTS_PAGE_TITLE = 'Search results';
export const BOOKING_DETAILED_VIEW_PAGE_STAFF_TITLE = 'Customer booking details';
export const ACCOUNT_STAFF_VIEW_PAGE_TITLE = 'Customer account details';
export const CUSTOMER_BOOKINGS_PAGE_TITLE = 'Customer bookings';
export const CUSTOMER_INVOICES_PAGE_TITLE = 'Customer invoices';

// CHECK-IN
export const CHECK_IN_VIEW_BOOKING_PAGE_TITLE = 'Ferry check-in - view booking';
export const CHECK_IN_ADD_BOOKING_PAGE_TITLE = 'Ferry check-in - add booking';
export const CHECK_IN_REVIEW_BOOKING_PAGE_TITLE = 'Ferry check-in - review booking';
export const CHECK_IN_SELECT_CUSTOMER_PAGE_TITLE = 'Ferry check-in - select customer';
export const CHECK_IN_MOVE_BOOKING_PAGE_TITLE = 'Ferry check-in - move booking';
export const CHECK_IN_SCAN_QR_PAGE_TITLE = 'Ferry check-in - scan QR';
export const CHECK_IN_PAGE_TITLE = 'Ferry check-in';

// EVENTS

export const EVENTS_LIST_VIEW_PAGE_TITLE = 'Upcoming events';
