const LAST_PAYMENT_ATTEMPT_KEY = 'last-payment-attempt-datetime';

/**
 * Returns stored date time of last valid attempt of user pressing the 'Pay now' button.
 */
export function getLastPaymentAttempt(): Date | null {
	const isoString = sessionStorage.getItem(LAST_PAYMENT_ATTEMPT_KEY);
	if (isoString) {
		// Convert the ISO string to a Date object
		return new Date(isoString);
	}
	return null;
}

export function setLastPaymentAttempt(now = new Date()) {
	// Convert the datetime to ISO string format
	const isoString = now.toISOString();

	// Set the ISO string as the value of a key in local storage
	sessionStorage.setItem(LAST_PAYMENT_ATTEMPT_KEY, isoString);
}
