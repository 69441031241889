import * as React from 'react';
import {
	EventDetailEntity,
} from '../../../../../Models/Entities';
import EventSideBar, { EventSideBarProps } from './EventSideBar';
import { observer, useLocalStore } from 'mobx-react';
import { store } from '../../../../../Models/Store';
import { confirmModal } from '../../../Modal/ModalUtils';
import { useEffect } from 'react';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../../../../Util/TypeGuards';
import { LottieSpinner } from '../../Lottie/LottieSpinner';
import { reaction, runInAction } from 'mobx';
import * as Models from '../../../../../Models/Entities';

export interface EventSideBarWrapperProps {
	event: EventDetailEntity;
	refetch: () => void;
}

function EventSideBarWrapper({ event, refetch }: EventSideBarWrapperProps) {
	const state = useLocalStore((): EventSideBarProps => ({
		eventDetails: event ?? null,
		customTicketTypes: event?.customTicketTypes ?? [],
		headerImage: event?.headerImage ?? null,
		supportingImages: event?.supportingImages ?? [],
	}));

	reaction(
		() => event,
		newEvent => {
			state.eventDetails = newEvent ?? null;
			state.customTicketTypes = newEvent?.customTicketTypes ?? [];
			state.headerImage = newEvent?.headerImage ?? null;
			state.supportingImages = newEvent?.supportingImages ?? [];
		},
	);

	if (isNullOrUndefined(state.eventDetails)) {
		return <LottieSpinner />;
	}

	return (
		<EventSideBar
			state={state}
			originalEvent={event}
			hidePanel={(confirm: boolean, needRefetch: boolean) => {
				if (!confirm) {
					store.routerHistory.push('/upcoming-events');
					if (needRefetch) {
						refetch();
					}
					return;
				}

				confirmModal(
					'Discard event changes?',
					// eslint-disable-next-line max-len
					'You have unsaved event details that will be lost if you leave the page. Are you sure you want to leave?',
					{
						confirmText: 'Discard changes',
					},
				)
					.then(() => {
						store.routerHistory.push('/upcoming-events');
					})
					.catch(() => {});
			}}
		/>
	);
}

export default observer(EventSideBarWrapper);
