import React from 'react';
import { useParams } from 'react-router';
import alertToast from '../../../../Util/ToastifyUtils';
import { isNullOrUndefined } from '../../../../Util/TypeGuards';
import useStore from 'Hooks/useStore';
import useAsync from 'Hooks/useAsync';
import AccountDetailsInnerWrap from './AccountDetailsInnerWrap';
import { getUser } from '../../../../Hooks/useInvoicedUserCRUD';
import { LottieSpinner } from '../Lottie/LottieSpinner';

export interface AccountDetailsWrapProps {
	tab: string;
	staffView?: boolean;
}

export function AccountDetailsWrap({
	tab,
	staffView,
}: AccountDetailsWrapProps) {
	let { id } = useParams<{ id: string }>();
	const store = useStore();

	if (isNullOrUndefined(id)) {
		id = store.userId as string;
	}
	const response = useAsync(async () => getUser(id), []);

	if (response?.type === 'error') {
		alertToast('Could not fetch user.', 'error');
	}
	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response.type === 'data' && isNullOrUndefined(response.data)) {
		alertToast('Could not fetch user', 'error');
		return <LottieSpinner />;
	}

	return (
		<AccountDetailsInnerWrap
			user={response.data!}
			staffView={staffView}
			initialTab={tab}
			refetchUser={response.refresh}
		/>
	);
}
