import React from 'react';
import queryString from 'querystring';
import { useLocation } from 'react-router-dom';
import { PostPayment } from './PostPayment';

export interface PostPaymentTabWrapProps {
	onSuccess: (transactionId: string) => void;
	onPaymentFailed: (errorCode: string) => void;
}

export function PostPaymentTab({
	onSuccess,
	onPaymentFailed,
}: PostPaymentTabWrapProps) {
	const queryParams = useLocation().search.substring(1);
	const params = queryString.parse(queryParams);
	// eslint-disable-next-line camelcase
	const { AccessCode, payment_intent } = params;

	if (typeof AccessCode !== 'string') {
		return <></>;
	}

	return (
		<PostPayment
			onSuccess={onSuccess}
			onPaymentFailed={onPaymentFailed}
			paymentIntentId={payment_intent.toString()}
		/>
	);
}
