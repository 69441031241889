/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type bookingStatus =
	// % protected region % [Override bookingStatus keys here] off begin
	'BOOKED' |
	'CANCELLED' |
	'RESERVED' |
	'EXPIRED_RESERVATION' |
	'UNPAID' |
	'EXPIRED_UNPAID';
	// % protected region % [Override bookingStatus keys here] end

export const bookingStatusOptions: { [key in bookingStatus]: string } = {
	// % protected region % [Override bookingStatus display fields here] off begin
	BOOKED: 'Booked',
	CANCELLED: 'Cancelled',
	RESERVED: 'Reserved',
	EXPIRED_RESERVATION: 'Expired Reservation',
	UNPAID: 'Unpaid',
	EXPIRED_UNPAID: 'Expired Unpaid',
	// % protected region % [Override bookingStatus display fields here] end
};

export type bookingType =
	// % protected region % [Override bookingType keys here] off begin
	'PASSENGER_ONLY' |
	'VEHICLE' |
	'VEHICLE_AND_PASSENGER' |
	'TRAILER' |
	'EVENT_BOOKING';
	// % protected region % [Override bookingType keys here] end

export const bookingTypeOptions: { [key in bookingType]: string } = {
	// % protected region % [Override bookingType display fields here] off begin
	PASSENGER_ONLY: 'Passenger only',
	VEHICLE: 'Vehicle',
	VEHICLE_AND_PASSENGER: 'Vehicle and Passenger',
	TRAILER: 'Trailer',
	EVENT_BOOKING: 'Event Booking',
	// % protected region % [Override bookingType display fields here] end
};

export type communicationType =
	// % protected region % [Override communicationType keys here] off begin
	'SMS' |
	'EMAIL' |
	'INFO_CHANGE' |
	'BOOKING' |
	'NOTE';
	// % protected region % [Override communicationType keys here] end

export const communicationTypeOptions: { [key in communicationType]: string } = {
	// % protected region % [Override communicationType display fields here] off begin
	SMS: 'SMS',
	EMAIL: 'Email',
	INFO_CHANGE: 'Info Change',
	BOOKING: 'Booking',
	NOTE: 'Note',
	// % protected region % [Override communicationType display fields here] end
};

export type cutoffType =
	// % protected region % [Override cutoffType keys here] off begin
	'EDIT' |
	'CANCEL';
	// % protected region % [Override cutoffType keys here] end

export const cutoffTypeOptions: { [key in cutoffType]: string } = {
	// % protected region % [Override cutoffType display fields here] off begin
	EDIT: 'Edit',
	CANCEL: 'Cancel',
	// % protected region % [Override cutoffType display fields here] end
};

export type defaultBookingType =
	// % protected region % [Override defaultBookingType keys here] off begin
	'PASSENGER' |
	'CARGO';
	// % protected region % [Override defaultBookingType keys here] end

export const defaultBookingTypeOptions: { [key in defaultBookingType]: string } = {
	// % protected region % [Override defaultBookingType display fields here] off begin
	PASSENGER: 'Passenger',
	CARGO: 'Cargo',
	// % protected region % [Override defaultBookingType display fields here] end
};

export type discountType =
	// % protected region % [Override discountType keys here] off begin
	'GLOBAL' |
	'PASSENGER' |
	'VEHICLE' |
	'TRAILER' |
	'ADD_ON';
	// % protected region % [Override discountType keys here] end

export const discountTypeOptions: { [key in discountType]: string } = {
	// % protected region % [Override discountType display fields here] off begin
	GLOBAL: 'Global',
	PASSENGER: 'Passenger',
	VEHICLE: 'Vehicle',
	TRAILER: 'Trailer',
	ADD_ON: 'Add on',
	// % protected region % [Override discountType display fields here] end
};

export type feeType =
	// % protected region % [Override feeType keys here] off begin
	'PHONE' |
	'CANCELLATION' |
	'ALTERATION' |
	'STAFF_OVERRIDDEN' |
	'OTHER' |
	'CREDIT_CARD_SURCHARGE';
	// % protected region % [Override feeType keys here] end

export const feeTypeOptions: { [key in feeType]: string } = {
	// % protected region % [Override feeType display fields here] off begin
	PHONE: 'Phone',
	CANCELLATION: 'Cancellation',
	ALTERATION: 'Alteration',
	STAFF_OVERRIDDEN: 'Staff Overridden',
	OTHER: 'Other',
	CREDIT_CARD_SURCHARGE: 'Credit Card Surcharge',
	// % protected region % [Override feeType display fields here] end
};

export type fontColour =
	// % protected region % [Override fontColour keys here] off begin
	'DARK' |
	'LIGHT';
	// % protected region % [Override fontColour keys here] end

export const fontColourOptions: { [key in fontColour]: string } = {
	// % protected region % [Override fontColour display fields here] off begin
	DARK: 'Dark',
	LIGHT: 'Light',
	// % protected region % [Override fontColour display fields here] end
};

export type locationType =
	// % protected region % [Override locationType keys here] off begin
	'NONE' |
	'BOOKING_WIZARD' |
	'CHECK_IN';
	// % protected region % [Override locationType keys here] end

export const locationTypeOptions: { [key in locationType]: string } = {
	// % protected region % [Override locationType display fields here] off begin
	NONE: 'None',
	BOOKING_WIZARD: 'Booking Wizard',
	CHECK_IN: 'Check In',
	// % protected region % [Override locationType display fields here] end
};

export type measurementType =
	// % protected region % [Override measurementType keys here] off begin
	'WEIGHT' |
	'LENGTH' |
	'HEIGHT';
	// % protected region % [Override measurementType keys here] end

export const measurementTypeOptions: { [key in measurementType]: string } = {
	// % protected region % [Override measurementType display fields here] off begin
	WEIGHT: 'Weight',
	LENGTH: 'Length',
	HEIGHT: 'Height',
	// % protected region % [Override measurementType display fields here] end
};

export type noteType =
	// % protected region % [Override noteType keys here] off begin
	'STAFF' |
	'ALTERATION' |
	'CANCELLATION' |
	'COMMUNICATION';
	// % protected region % [Override noteType keys here] end

export const noteTypeOptions: { [key in noteType]: string } = {
	// % protected region % [Override noteType display fields here] off begin
	STAFF: 'Staff',
	ALTERATION: 'Alteration',
	CANCELLATION: 'Cancellation',
	COMMUNICATION: 'Communication',
	// % protected region % [Override noteType display fields here] end
};

export type smsDeliveryStatus =
	// % protected region % [Override smsDeliveryStatus keys here] off begin
	'DELIVERED' |
	'UNDELIVERED' |
	'EXPIRED' |
	'REJECTED' |
	'DELETED';
	// % protected region % [Override smsDeliveryStatus keys here] end

export const smsDeliveryStatusOptions: { [key in smsDeliveryStatus]: string } = {
	// % protected region % [Override smsDeliveryStatus display fields here] off begin
	DELIVERED: 'Delivered',
	UNDELIVERED: 'Undelivered',
	EXPIRED: 'Expired',
	REJECTED: 'Rejected',
	DELETED: 'Deleted',
	// % protected region % [Override smsDeliveryStatus display fields here] end
};

export type systemOfMeasurementType =
	// % protected region % [Override systemOfMeasurementType keys here] off begin
	'METRIC' |
	'IMPERIAL';
	// % protected region % [Override systemOfMeasurementType keys here] end

export const systemOfMeasurementTypeOptions: { [key in systemOfMeasurementType]: string } = {
	// % protected region % [Override systemOfMeasurementType display fields here] off begin
	METRIC: 'Metric',
	IMPERIAL: 'Imperial',
	// % protected region % [Override systemOfMeasurementType display fields here] end
};

export type timeFormat =
	// % protected region % [Override timeFormat keys here] off begin
	'TWENTY_FOUR_HOUR_TIME' |
	'TWELVE_HOUR_TIME';
	// % protected region % [Override timeFormat keys here] end

export const timeFormatOptions: { [key in timeFormat]: string } = {
	// % protected region % [Override timeFormat display fields here] off begin
	TWENTY_FOUR_HOUR_TIME: 'Twenty Four Hour Time',
	TWELVE_HOUR_TIME: 'Twelve Hour Time',
	// % protected region % [Override timeFormat display fields here] end
};

export type transactionStatus =
	// % protected region % [Override transactionStatus keys here] off begin
	'IN_PROGRESS' |
	'COMPLETED' |
	'FAILED' |
	'ABANDONED' |
	'INVOICED' |
	'PENDING_INVOICE' |
	'EFTPOS' |
	'CASH' |
	'PENDING_PAYMENT' |
	'EXPIRED';
	// % protected region % [Override transactionStatus keys here] end

export const transactionStatusOptions: { [key in transactionStatus]: string } = {
	// % protected region % [Override transactionStatus display fields here] off begin
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
	FAILED: 'Failed',
	ABANDONED: 'Abandoned',
	INVOICED: 'Invoiced',
	PENDING_INVOICE: 'Pending Invoice',
	EFTPOS: 'Eftpos',
	CASH: 'Cash',
	PENDING_PAYMENT: 'Pending Payment',
	EXPIRED: 'Expired',
	// % protected region % [Override transactionStatus display fields here] end
};

// % protected region % [Add any extra enums here] on begin
export type tripDirectionTypes =
	'DEPARTURE' |
	'RETURN';

export type wizardModes =
	'CREATE' |
	'ALTERATION';

export const wizardModeOptions: { [key in wizardModes]: wizardModes } = {
	CREATE: 'CREATE',
	ALTERATION: 'ALTERATION',
};

export type bookingTransactionStatus =
	'PAID' |
	'CANCELLED' |
	'INVOICED' |
	'AWAITING';

export type PassengerTypeKey = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H';

export function allPassengerTypeKeys(): PassengerTypeKey[] {
	return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
}

export type cacheType =
	'ADD_ONS' |
	'CAPACITY' |
	'CARGO_TYPES' |
	'CONFIG_GENERAL' |
	'CONFIG_THEME' |
	'FERRIES' |
	'FERRY_TRIPS' |
	'LOCATIONS' |
	'MEASUREMENTS' |
	'PASSENGER_TYPES' |
	'TOW_ON_TYPES';

export const cacheTypeOptions: { [key in cacheType]: string } = {
	FERRY_TRIPS: 'Ferry trips',
	CAPACITY: 'Capacity',
	ADD_ONS: 'Add-ons',
	LOCATIONS: 'Locations',
	PASSENGER_TYPES: 'Passenger types',
	MEASUREMENTS: 'Measurements',
	CARGO_TYPES: 'Cargo types',
	TOW_ON_TYPES: 'Tow on types',
	FERRIES: 'Ferries',
	CONFIG_THEME: 'Config theme',
	CONFIG_GENERAL: 'Config general',
};
// % protected region % [Add any extra enums here] end
