import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import classNames from 'classnames';
import useCheckInStore from 'Hooks/useCheckInStore';
import useScreenSize from 'Hooks/useScreenSize';
import { TextField } from 'Views/Components/TextBox/TextBox';
import CheckInSearchTable from '../CheckInTable/CheckInSearchTable';
import { CommonPaginationOptions } from 'Views/Components/Pagination/Pagination';
import {
	BOOKINGS_PER_PAGE,
	DEBOUNCE_DELAY,
	fetchCheckInSearchResults,
} from '../CheckInTable/CheckInSearchTableUtils';
import CheckInSearchList from '../CheckInList/CheckInSearchList';

type CheckInSearchPagination = Required<Pick<CommonPaginationOptions, 'pageNo' | 'totalRecords'>>;

export interface CheckInMoveState extends CheckInSearchPagination {
	searchTerm?: string;
	isLoading: boolean;
}

function CheckInMoveContent() {
	const checkInStore = useCheckInStore();
	const { isIpadMiniPortrait } = useScreenSize();
	const state = useLocalStore<CheckInMoveState>(() => ({
		// Initialise searchTerm as undefined to represent the no attempt to search has been made
		// Therefore, display CheckInStart message
		searchTerm: undefined,
		pageNo: 0,
		totalRecords: 0,
		isLoading: false,
	}));

	const fetchResults = React.useCallback(
		AwesomeDebouncePromise((value: string, pageNo: number) => {
			return fetchCheckInSearchResults({
				take: BOOKINGS_PER_PAGE,
				skip: pageNo * BOOKINGS_PER_PAGE,
				searchTerm: value,
				routeId: checkInStore.ferryTrip.routeId,
				afterDepartureDate: checkInStore.ferryTrip.departureDateTime,
			});
		}, DEBOUNCE_DELAY),
		[],
	);

	return (
		<>
			<div className={classNames('check-in__top-nav', { 'bottom-bar top-bar': isIpadMiniPortrait })}>
				<TextField
					model={state}
					modelProperty="searchTerm"
					className="check-in__search"
					placeholder="Search"
					inputProps={{
						id: 'search-input',
						onKeyUp: e => {
							// Hide mobile keyboard after pressing 'Enter'
							if (e.key === 'Enter') {
								const target = e.target as any;
								target.blur();
							}
						},
					}}
				/>
			</div>
			{
				isIpadMiniPortrait
					? <CheckInSearchList state={state} fetch={fetchResults} />
					: <CheckInSearchTable state={state} fetch={fetchResults} />
			}
		</>
	);
}

export default observer(CheckInMoveContent);
