import React, { useEffect, useState } from 'react';
import { UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { UserDisabledTag, UserDisplayNameTag } from 'Views/Components/_HumanWritten/UserDisplayNameTag';
import AccountDetailsVouchers from 'Views/Components/_HumanWritten/AccountDetails/VoucherSection/GiftVouchersWrapper';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import { reaction, runInAction } from 'mobx';
import { changeEmailRequest, changeEmailRequestByStaff, me } from 'Services/Api/AccountService';
import alertToast from 'Util/ToastifyUtils';
import { saveUser, updateUserType } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/AccountFieldsUtils';
import { isEmailValid, isPhoneValid, validateForm } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/AccountFieldsValidation';
import { observer, useLocalStore } from 'mobx-react';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import If from 'Views/Components/If/If';
import DetailFields from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/DetailFields';
import FixedPricingFields from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/FixedPricingFields';
import handleNavigationAway from 'Views/Components/_HumanWritten/AccountDetails/HandleNavigationAway';
import { useAccountNavigationAway } from 'Hooks/useAccountNavigationAway';
import { useHistory } from 'react-router';
import { UserInvoiceListComponent } from 'Views/Components/_HumanWritten/UserInvoiceList/UserInvoiceListComponent';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import { UserNotesModal } from 'Views/Components/_HumanWritten/AccountDetails/UserNotes/UserNotesModal';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { ACCOUNT_CUSTOMER_PAGE_TITLE, ACCOUNT_STAFF_VIEW_PAGE_TITLE } from 'ConstantPageNames';
import { WhiteBackground } from 'Views/Components/_HumanWritten/WhiteBackground';
import {
	Button,
	Display,
	Colors,
	Sizes,
} from 'Views/Components/Button/Button';
import Tabs from 'Views/Components/Tabs/Tabs';

export interface AccountDetailsProps {
	user: UserEntity;
	staffView?: boolean;
	refetchUser: () => void;
	initialTab: string;
}

const TAB_INDEX = ['account', 'credits'];

function AccountDetailsInnerWrap({
	user,
	staffView,
	refetchUser,
	initialTab,
}: AccountDetailsProps) {
	// Set document title
	document.title = ACCOUNT_CUSTOMER_PAGE_TITLE;
	if (window?.location?.href?.includes('staff-account-details')) {
		document.title = ACCOUNT_STAFF_VIEW_PAGE_TITLE;
	}

	const history = useHistory();

	const getTabFromTabName = (name: string): number => {
		const index = TAB_INDEX.indexOf(name);

		return index !== -1 ? index : 0;
	};

	// This state tracks only email and phone
	const specialState = useLocalStore(() => ({ email: user?.email, phone: user?.phone }));

	// We want to separate user from state changes
	const [copiedUser] = React.useState(new UserEntity(user));
	const [mode, setMode] = useState<EntityFormMode>(EntityFormMode.VIEW);
	const [saving, setSaving] = useState<boolean>(false);
	const [currentTab, setCurrentTab] = useState(getTabFromTabName(initialTab));
	const [errors, setErrors] = useState<FormErrors<UserEntity>>({});

	const setTab = (tab: number | string) => {
		const tabIndex = typeof tab === 'string' ? getTabFromTabName(tab) : tab;
		setCurrentTab(tabIndex);

		if (!staffView) {
			history.replace(`/account-details/${TAB_INDEX[tabIndex]}`);
		}
	};

	useEffect(() => {
		setTab(initialTab);
	}, [initialTab]);

	const [changesMade, setChangesMade] = useState(false);
	const setNeedConfirm = () => {
		setChangesMade(true);
	};

	useEffect(() => {
		// If any changes are made on any of these fields it will trigger the modal to confirm closing the side-bar
		const disposals = [
			reaction(() => copiedUser.firstName, setNeedConfirm),
			reaction(() => copiedUser.lastName, setNeedConfirm),
			reaction(() => specialState.email, setNeedConfirm),
			reaction(() => specialState.phone, setNeedConfirm),
			reaction(() => copiedUser.postcode, setNeedConfirm),
			reaction(() => copiedUser.address, setNeedConfirm),
			reaction(() => copiedUser.userDisplayNameId, setNeedConfirm),
		];

		// Clean up all of the reactions after one has been triggered
		return () => {
			disposals.map(disposal => disposal());
		};
	}, [copiedUser]);

	useAccountNavigationAway({
		needConfirm: changesMade,
		confirmationFunction: refetchUser,
	});

	const handleSave = async () => {
		setSaving(true);
		const emailHasChanged = copiedUser.email !== specialState.email;

		const newErrors = validateForm(copiedUser, specialState);
		setErrors(newErrors);

		if (Object.keys(newErrors).length === 0) {
			const res = await Promise.all([
				isPhoneValid(copiedUser.phone, specialState?.phone),
				isEmailValid(copiedUser.email, specialState?.email),
			]);

			if (res.includes(false)) {
				setSaving(false);
				return;
			}

			try {
				// Ensure phone is updated before save
				if (copiedUser.phone !== specialState.phone) {
					runInAction(() => {
						copiedUser.phone = specialState.phone;
					});
				}
				if (emailHasChanged) {
					confirmModal(
						'Change email address?',
						<>
							<h5>From: <strong className="brand-colour">{copiedUser.email}</strong></h5>
							<h5>To: <strong className="brand-colour">{specialState.email}</strong></h5>
							<h5>Are you sure you want to proceed with these changes?</h5>
						</>,
					).then(async () => {
						await save(emailHasChanged);
					});
				} else {
					await save(emailHasChanged);
				}
			} catch (e) {
				alertToast('Could not save information', 'error');
			}
		}
		if (Object.keys(newErrors).length > 0) {
			if (Object.keys(newErrors).includes('prices')
				|| Object.keys(newErrors).includes('adultPrice')
				|| Object.keys(newErrors).includes('addOnsPrices')) {
				setCurrentTab(2);
				alertToast('All prices are required on the `Pricing` tab', 'error');
			} else {
				setCurrentTab(0);
			}
			ScrollToError('end');
		}
		setSaving(false);
	};

	const save = async (emailHasChanged: boolean) => {
		await saveUser(copiedUser, user, store.isManager);
		setChangesMade(false);

		if (staffView) {
			if (emailHasChanged) {
				await changeEmailRequestByStaff(user.email, specialState.email)
					.catch(() => {
						alertToast('Change email request failed', 'error', 'Error');
					});
			}

			refetchUser();
			try {
				await updateUserType(copiedUser, user);
			} catch {
				alertToast('Failed to update user type', 'error');
			}
			alertToast('User\'s details have been updated!', 'success', 'Details saved');
			setSaving(false);
			return;
		}

		// Account edited by account owner
		const userResult = await me();
		store.setLoggedInUser(userResult);
		alertToast('Your details have been updated!', 'success', 'Details saved!');
		if (emailHasChanged) {
			await changeEmailRequest(specialState.email);
			store.routerHistory.replace('/change-email-request');
		}
		refetchUser();
	};

	const tabs = [
		{
			name: 'Account',
			key: 'account-details',
			component: <DetailFields
				user={copiedUser}
				mode={mode}
				setMode={m => setMode(m)}
				staffView={staffView}
				specialState={specialState}
				errors={errors}
				setErrors={setErrors}
			/>,
		},
		{
			name: 'Credits',
			key: 'vouchers-list',
			component: <AccountDetailsVouchers
				user={copiedUser}
			/>,
		},
	];
	if (staffView) {
		tabs.push({
			name: 'Pricing',
			key: 'pricing-details',
			component: <FixedPricingFields
				user={copiedUser}
				originalUser={user}
				mode={mode}
				setMode={m => setMode(m)}
				setNeedConfirm={setNeedConfirm}
				errors={errors}
			/>,
		});
	}
	if (staffView || !store.isStaff) {
		tabs.push({
			name: 'Invoices',
			key: 'invoices-list',
			component: <UserInvoiceListComponent userId={copiedUser.id} />,
		});
	}

	return (
		<WhiteBackground
			content={(
				<div className="account-page">
					<div className="navigation-header account-details">
						<Button
							className="back-navigation hide-underline icon-chevron-left icon-left booking-detailed-view__back-button"
							icon={{ icon: 'arrow-left', iconPos: 'icon-left' }}
							onClick={() => {
								store.routerHistory.goBack();
							}}
						>
							Back
						</Button>
					</div>
					<div className="user-profile-container">
						<h3 className="account-page-details__header">
							{staffView ? user?.getFullName(true) : 'Account'}
							{staffView && UserDisplayNameTag(user?.userDisplayName?.displayName)}
							{staffView && copiedUser.disabled && UserDisabledTag()}
						</h3>
						<Tabs
							tabs={tabs}
							currentTab={currentTab}
							onTabClicked={tabIndex => setCurrentTab(tabIndex)}
						/>
						<If condition={mode !== EntityFormMode.VIEW}>
							<ButtonGroup
								alignment={Alignment.HORIZONTAL}
								className="update-profile-buttons bottom-navigation-btn-group"
							>
								<Button
									type="button"
									className="update-profile-cancel cancel-btn"
									display={Display.Solid}
									sizes={Sizes.Large}
									colors={Colors.Secondary}
									buttonProps={{ id: 'update_profile_cancel' }}
									onClick={async () => {
										await handleNavigationAway(changesMade, refetchUser);
									}}
								>
									Cancel
								</Button>
								<Button
									type="button"
									className="update-profile-save save-btn"
									display={Display.Solid}
									sizes={Sizes.Large}
									colors={Colors.Primary}
									buttonProps={{ id: 'update_profile_save' }}
									icon={{ icon: 'arrow-right', iconPos: 'icon-right' }}
									onClick={handleSave}
									disabled={saving}
								>
									{saving ? 'Saving...' : 'Save'}
								</Button>
							</ButtonGroup>
						</If>
					</div>
					<If condition={store.isStaff && staffView && mode !== 'edit'}>
						<UserNotesModal userId={user.id} />
					</If>
				</div>
			)}
		/>
	);
}

export default observer(AccountDetailsInnerWrap);
