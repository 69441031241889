// Messages that can be received from the server
export enum CheckInResponseType {
	SubscribedToCheckIn,
	BookingCheckedIn,
	BookingCheckedOut,
	BookingAdded,
	BookingUpdated,
	BookingRemoved,
	OnlineBookingStatusChanged,
}
