import React from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { Text } from 'Views/Components/Text/Text';
import { invoicePayment } from 'Services/Api/_HumanWritten/PaymentService';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { onWizardSuccess } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { store } from 'Models/Store';
import { useTransactionHasOnlyNewBookings } from 'Services/CustomGql/TransactionEntityType';
import { TripType } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface SkipPaymentProps {
	transactionId: string | null;
	bookingForAlteration?: boolean;
	userId?: string,
	tripType?: TripType;
	eventBooking: boolean;
}

export function SkipPayment({
	transactionId,
	bookingForAlteration = false,
	tripType,
	userId,
	eventBooking,
}: SkipPaymentProps) {
	const canSkipPayment = useTransactionHasOnlyNewBookings(transactionId);

	if (
		(!store.isManager && !store.isInvoicedUser)
		|| isNullOrUndefined(transactionId)
		|| canSkipPayment.type !== 'data'
		|| canSkipPayment.data !== true
	) {
		return null;
	}

	return (
		<div className="skip-payment">
			<Text color="gray" className="mb-sm">
				<strong>Need to skip payment?</strong> Payment can be completed at a later date.
			</Text>
			<Button
				className="pr-md pl-md"
				display={Display.Outline}
				colors={Colors.Black}
				onClick={async () => {
					try {
						await invoicePayment(transactionId);
						onWizardSuccess(store, transactionId, bookingForAlteration, tripType === 'return', eventBooking, userId);
					} catch (e) {
						console.error('Error with skipping payment', e);
					}
				}}
			>
				Skip payment
			</Button>
		</div>
	);
}
