import * as React from 'react';
import useAsync from 'Hooks/useAsync';
import { BookingEntity } from 'Models/Entities';
import { PATH_TO_TRIP_ID_OF_BOOKING } from 'Services/CustomGql/BookingEntityType';
import { disableFerryTrip } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';

export function useDeleteFerryTripModal(ferryTripId: string, onConfirm?: () => void) {
	const bookingCountResponse = useAsync(() => getBookingCountByFerryTripId(ferryTripId), []);
	const [loading, setLoading] = React.useState(false);

	const onConfirmInner = async () => {
		try {
			setLoading(true);
			// Returns null if error occurs
			const res = await disableFerryTrip(ferryTripId);
			if (res === null) {
				throw new Error();
			}
		} catch {
			// This cannot happen if they are trying to delete a past trip
			alertToast('Failed to delete ferry trip', 'error');
			store.modal.hide();
			return;
		}

		setLoading(false);

		if (onConfirm) {
			onConfirm();
		}

		store.modal.hide();
	};

	return {
		bookingCountResponse,
		onConfirmInner,
		loading,
	};
}

function getBookingCountByFerryTripId(ferryTripId: string) {
	return BookingEntity.count(
		{
			args: [[{ path: PATH_TO_TRIP_ID_OF_BOOKING, value: ferryTripId, comparison: 'equal' }]],
		},
	);
}
