import * as React from 'react';
import { LottieShipVerified } from '../Lottie/LottieShipVerified';
import { Text } from 'Views/Components/Text/Text';
import { store } from 'Models/Store';

export interface RedirectLoaderProps {
	to: string;
	title: string;
	subtitle: string;
	duration?: number;
}

export function RedirectLoader(props: RedirectLoaderProps) {
	const {
		to,
		title,
		subtitle,
		duration = 5000,
	} = props;

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			store.routerHistory.replace(to);
		}, duration);

		return () => {
			clearTimeout(timeout);
		};
	}, [to]);

	return (
		<div className="body-content">
			<div className="redirect-loader">
				<div className="redirect-loader__body">
					<LottieShipVerified />
					<div className="redirect-loader__text">
						<Text weight="700" size="xl" className="mb-sm">
							{title}
						</Text>
						<Text weight="500" size="sm" color="gray">
							{subtitle}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
}
