import * as React from 'react';
import { isNotNullOrUndefined, stringIsEmpty } from '../../../../Util/TypeGuards';
import { GetRgbColourString, HexToRgb } from '../../Helpers/ColoursHelper';

export function SetLottieColour(lottieJson: unknown, primaryHex: string, secondaryHex?: string) {
	try {
		let lottieJsonString = JSON.stringify(lottieJson);
		const primaryColour = HexToRgb(primaryHex);

		lottieJsonString = ReplaceColoursInLottieJson(
			lottieJsonString,
			'"PRIMARY_COLOUR"',
			GetRgbColourString(primaryColour),
		);

		if (isNotNullOrUndefined(secondaryHex)) {
			const secondaryColour = HexToRgb(secondaryHex);

			lottieJsonString = ReplaceColoursInLottieJson(
				lottieJsonString,
				'"SECONDARY_COLOUR"',
				GetRgbColourString(secondaryColour),
			);
		}

		if (!stringIsEmpty(lottieJsonString)) {
			return JSON.parse(lottieJsonString);
		}
		return null;
	} catch {
		return null;
	}
}

export function ReplaceColoursInLottieJson(lottieJsonString: string, valueToReplace: string, replacementValue: string) {
	return lottieJsonString.replaceAll(valueToReplace, replacementValue);
}
