import { RefObject } from 'react';

export default class RouteMapCanvas {
	private canvasRef: RefObject<HTMLCanvasElement>;

	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D;

	private eventListeners: { [key: string]: any } = {};

	private needsRerender: boolean = false;

	constructor(canvasRef: RefObject<HTMLCanvasElement>) {
		this.canvasRef = canvasRef;
	}

	public init() {
		const canvas = this.canvasRef.current;
		if (canvas === null) {
			throw new Error('Canvas is not initialized');
		}

		this.canvas = canvas;
		const context = canvas.getContext('2d');

		if (context === null) {
			throw new Error('Could not get canvas context');
		}

		this.context = context;
	}

	public getContext() {
		return this.context;
	}

	public getCanvas() {
		return this.canvas;
	}

	public clear() {
		if (!this.isReady()) {
			return;
		}
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
	}

	public setDimensions(width: number, height: number) {
		this.canvas.width = width;
		this.canvas.height = height;
	}

	public setStyleHeight(height: number) {
		this.canvas.style.height = `${height}px`;
	}

	public isReady() {
		return this.context !== undefined;
	}

	public addEventListener<K extends keyof HTMLElementEventMap>(
		event: K,
		callback: (ev: HTMLElementEventMap[K]) => any,
	) {
		if (this.eventListeners[event] !== undefined) {
			return;
		}

		this.canvas.addEventListener(event, callback);

		this.eventListeners[event] = callback;
	}

	public removeEventListener<K extends keyof HTMLElementEventMap>(event: K) {
		if (this.eventListeners[event] === undefined) {
			return;
		}

		this.canvas.removeEventListener(event, this.eventListeners[event]);
		this.eventListeners[event] = undefined;
	}

	public getNeedsRerender() {
		return this.needsRerender;
	}

	public setNeedsRerender(needsRerender: boolean) {
		this.needsRerender = needsRerender;
	}
}
