import * as React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { BookingDetailSection } from './SharedSections/BookingDetailSection';

function checkInUrl(bookingId: string) {
	// TESTING PURPOSES
	// Uncomment return value below and replace url base
	// return `http://192.168.12.40:5023/qr-code/booking/${bookingId}`;

	return `${window.location.origin}/qr-code/booking/${bookingId}`;
}

export interface BookingDetailsQrCodeProps {
	bookingId: string;
	isEventBooking: boolean;
}

function BookingDetailsQrCode({ bookingId, isEventBooking }: BookingDetailsQrCodeProps) {
	const url = React.useMemo(() => {
		return checkInUrl(bookingId);
	}, [bookingId]);

	const sectionContent = (
		<>
			<p>{isEventBooking ? 'Use this QR code on the day' : 'Use this QR code at check-in'}</p>
			<div className="qr-outer">
				<div className="qr-inner">
					{/* Inline style is only way to set qr code size */}
					<QRCodeSVG value={url} style={{ height: '10rem', width: '10rem' }} />
				</div>
			</div>
		</>
	);

	return (
		<BookingDetailSection
			sectionName="Your ticket"
			sectionContent={sectionContent}
			sectionClassName="qr-code"
			icon={{
				name: 'issue',
				classname: 'ticket',
			}}
			modificationRules={{
				canEdit: false,
				editType: 'passenger',
				onEdit: () => {},
				isInvoiced: false,
			}}
		/>
	);
}

export default BookingDetailsQrCode;
