/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsFeeEntity from 'Models/Security/Acl/VisitorsFeeEntity';
import UserFeeEntity from 'Models/Security/Acl/UserFeeEntity';
import StaffFeeEntity from 'Models/Security/Acl/StaffFeeEntity';
import ManagerFeeEntity from 'Models/Security/Acl/ManagerFeeEntity';
import AdminFeeEntity from 'Models/Security/Acl/AdminFeeEntity';
import InvoicedUserFeeEntity from 'Models/Security/Acl/InvoicedUserFeeEntity';
import FixedPriceUserFeeEntity from 'Models/Security/Acl/FixedPriceUserFeeEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFeeEntityAttributes extends IModelAttributes {
	amount: number;
	noInterventionAmount: number;
	feeType: Enums.feeType;

	alterationId?: string;
	alteration?: Models.AlterationEntity | Models.IAlterationEntityAttributes;
	feeOptionId?: string;
	feeOption?: Models.FeeOptionEntity | Models.IFeeOptionEntityAttributes;
	transactionId?: string;
	transaction?: Models.TransactionEntity | Models.ITransactionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FeeEntity', 'Fee')
// % protected region % [Customise your entity metadata here] end
export default class FeeEntity extends Model
	implements IFeeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFeeEntity(),
		new UserFeeEntity(),
		new StaffFeeEntity(),
		new ManagerFeeEntity(),
		new AdminFeeEntity(),
		new InvoicedUserFeeEntity(),
		new FixedPriceUserFeeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Amount'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FeeEntity, number>()
	@CRUD({
		name: 'Amount',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public amount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Amount'] end

	// % protected region % [Modify props to the crud options here for attribute 'No Intervention Amount'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FeeEntity, number>()
	@CRUD({
		name: 'No Intervention Amount',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public noInterventionAmount: number;
	// % protected region % [Modify props to the crud options here for attribute 'No Intervention Amount'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fee Type'] off begin
	@Validators.Required()
	@observable
	@attribute<FeeEntity, Enums.feeType>()
	@CRUD({
		name: 'Fee Type',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.feeTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.feeTypeOptions),
		displayFunction: (attr: Enums.feeType) => Enums.feeTypeOptions[attr],
	})
	public feeType: Enums.feeType;
	// % protected region % [Modify props to the crud options here for attribute 'Fee Type'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alteration',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.AlterationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public alteration: Models.AlterationEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Fee Option'] off begin
		name: 'Fee Option',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.FeeOptionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Fee Option'] end
	})
	public feeOptionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public feeOption: Models.FeeOptionEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] off begin
		name: 'Transaction',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.TransactionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] end
	})
	public transactionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public transaction: Models.TransactionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFeeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFeeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.amount !== undefined) {
				this.amount = attributes.amount;
			}
			if (attributes.noInterventionAmount !== undefined) {
				this.noInterventionAmount = attributes.noInterventionAmount;
			}
			if (attributes.feeType !== undefined) {
				this.feeType = attributes.feeType;
			}
			if (attributes.alterationId !== undefined) {
				this.alterationId = attributes.alterationId;
			}
			if (attributes.alteration !== undefined) {
				if (attributes.alteration === null) {
					this.alteration = attributes.alteration;
				} else if (attributes.alteration instanceof Models.AlterationEntity) {
					this.alteration = attributes.alteration;
					this.alterationId = attributes.alteration.id;
				} else {
					this.alteration = new Models.AlterationEntity(attributes.alteration);
					this.alterationId = this.alteration.id;
				}
			}
			if (attributes.feeOptionId !== undefined) {
				this.feeOptionId = attributes.feeOptionId;
			}
			if (attributes.feeOption !== undefined) {
				if (attributes.feeOption === null) {
					this.feeOption = attributes.feeOption;
				} else if (attributes.feeOption instanceof Models.FeeOptionEntity) {
					this.feeOption = attributes.feeOption;
					this.feeOptionId = attributes.feeOption.id;
				} else {
					this.feeOption = new Models.FeeOptionEntity(attributes.feeOption);
					this.feeOptionId = this.feeOption.id;
				}
			}
			if (attributes.transactionId !== undefined) {
				this.transactionId = attributes.transactionId;
			}
			if (attributes.transaction !== undefined) {
				if (attributes.transaction === null) {
					this.transaction = attributes.transaction;
				} else if (attributes.transaction instanceof Models.TransactionEntity) {
					this.transaction = attributes.transaction;
					this.transactionId = attributes.transaction.id;
				} else {
					this.transaction = new Models.TransactionEntity(attributes.transaction);
					this.transactionId = this.transaction.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		alteration {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
		feeOption {
			${Models.FeeOptionEntity.getAttributes().join('\n')}
		}
		transaction {
			${Models.TransactionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FeeEntity.prototype, 'created');
CRUD(modifiedAttr)(FeeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
