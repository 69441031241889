import * as React from 'react';
import classNames from 'classnames';
import { useMemo } from 'react';
import defaultSpinner from './LottieFiles/default-spinner.json';
import customSpinner from './LottieFiles/custom-spinner.json';
import { useLottie } from 'lottie-react';
import { themeStore } from '../../../../Models/ThemeStore';
import { SetLottieColour } from './LottieColourChanger';
import useStore from 'Hooks/useStore';

interface LottieSpinnerProps {
	className?: string;
}

export function LottieSpinner({ className }: LottieSpinnerProps) {
	const store = useStore();
	const options = useMemo(() => {
		let colouredSpinner: unknown = '';
		if (store?.spinnerJSON !== '') {
			colouredSpinner = store?.spinnerJSON;
		} else {
			// Set the colour of the spinner depending on the application brand colouring
			colouredSpinner = SetLottieColour(
				customSpinner,
				themeStore?.config?.brandColourPrimary,
			);
			store.setSpinnerJSON(colouredSpinner);
		}

		return {
			loop: true,
			autoplay: true,
			animationData: colouredSpinner ?? defaultSpinner,
		};
	}, []);

	const { View } = useLottie(options);
	return (
		<div className={classNames('lottie-loading-spinner', className)}>
			{View}
		</div>
	);
}
