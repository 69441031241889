import { Symbols } from 'Symbols';
import { initValidators, IModelAttributeValidationError, ErrorType } from '../../Util';
import { Model } from 'Models/Model';
import parsePhoneNumber from 'libphonenumber-js';
import { DEFAULT_COUNTRY_CODE } from '../../../Constants';
import { isNullOrUndefined } from '../../../Util/TypeGuards';

export default function validate() {
	return (target: Record<string | symbol, any>, key: string) => {
		initValidators(target, key);
		target[Symbols.validatorMap][key].push('Phone');
		target[Symbols.validator].push(
			(model: Model): Promise<IModelAttributeValidationError | null> => new Promise(resolve => {
				if (model[key] === null || model[key] === undefined) {
					resolve(null);
				} else {
					resolve(parsePhoneNumber(model[key], DEFAULT_COUNTRY_CODE)?.isValid()
						? null
						: {
							errorType: ErrorType.INVALID,
							errorMessage: 'Enter a valid phone number.',
							attributeName: key,
							target: model,
						});
				}
			}),
		);
	};
}

export function IsValidPhone(phone?: string | null): boolean {
	if (isNullOrUndefined(phone)) {
		return false;
	}
	return parsePhoneNumber(phone, DEFAULT_COUNTRY_CODE)?.isValid() ?? false;
}

export function convertToNationalNumber(number: string) {
	return number.replace(new RegExp('^\\+61'), '0');
}

export function convertToInternationalNumber(number: string) {
	return number.replace(new RegExp('^04'), '+614');
}
export function removePlusFromNumber(number: string) {
	return number.replace(new RegExp('^\\+'), '');
}

export function addPlusToNumber(number: string) {
	return `+${number}`;
}
