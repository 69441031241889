import { getMeasurementLabelFromId } from './MeasurementUtils';
import { getTruncatedPrice } from './PriceUtils';

/**
 * This function will format pricing in the booking wizard flow and check-in flow.
 * @param price: The price that is being formatted.
 * @param isAlteration: Whether the price is being altered for an alteration or not.
 * @param includeDecimals: Whether decimals will be shown in final formatted price.
 * @param showSign: Whether the +/- sign will be shown or not.
 * Note: If it is an alteration, it will usually always show the sign (as it will show
 * whether there was a price increase/decrease). However, some situations are not alterations
 * and will require the sign to be shown still. Or some situations in an alteration may not
 * require the sign to be shown (e.g. GST and surcharge).
 */
export const formatPriceDisplay = (
	price: number,
	isAlteration: boolean,
	includeDecimals: boolean = true,
	showSign: boolean = false,
) => {
	let sign = '';

	// The decision we have made regarding prices is that we always round the value down. This is because we don't want
	// to deal with any additional cents which may arise from rounding up multiple times. We cannot use the rounding or
	// toFixed function to do that as they don't have the necessary overloads. This method will ensure that any partial
	// cents that we have are removed before they are displayed
	const truncatedPrice = getTruncatedPrice(price, includeDecimals);

	if (isAlteration || showSign) {
		if (truncatedPrice > 0) {
			sign = '+';
		} else if (truncatedPrice < 0) {
			sign = '-';
		} else if (truncatedPrice === 0) {
			sign = '';
		}
	}

	return `${sign}$${Math.abs(truncatedPrice).toFixed(includeDecimals ? 2 : 0)}`;
};

/**
 * Formats the display for towOn drop down menu in vehicle tab
 * @param option: The length type option being formatted.
 * @param cost: The cost of the length type option.
 * @param isAlteration: Whether it is an alteration or not (to determine if +/- should show is pricing)
 * @returns IF ALTERATION `Up to 5.2m (+$25.00)`
 * @returns IF NOT ALTERATION `Up to 5.2m ($25.00)`
 */
// Formats the display for trailer drop down menu in vehicle tab
export const formatTrailerPrice = (optionId: string, cost: number, isAlteration: boolean) => {
	return `${getMeasurementLabelFromId(optionId)} (${formatPriceDisplay(cost, isAlteration, true, true)})`;
};
