import { BookingCutoffEntity, BookingEntity } from 'Models/Entities';
import { cutoffType } from 'Models/Enums';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { getBookingCutOff } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';

export async function getBookingCutOffEntity(booking: BookingEntity, cutOffType: cutoffType) {
	const isVehicleBooking = isNotNullOrUndefined(booking.bookedSummary?.cargoInfo);

	const bookingCutoffRequest = await getBookingCutOff(
		booking,
		cutOffType,
		isVehicleBooking ? 'VEHICLE' : 'PASSENGER_ONLY',
	);

	return bookingCutoffRequest.data !== '' ? bookingCutoffRequest.data as BookingCutoffEntity : null;
}
