import {
	AlterationEntity, BookingEntity, FerryTripEntity, UserEntity,
} from '../Models/Entities';
import { HasCondition, IWhereCondition } from '../Views/Components/ModelCollection/ModelQuery';

export enum UserType {
	CUSTOMER = 'Customer',
	STAFF = 'Staff',
	MANAGER = 'Manager',
	ADMIN = 'Admin',
}

/**
 * Fetches a list of users based on the supplied arguments
 *
 * @param userType The type of user to fetch entities for. All user types will be considered if this is null
 * @param args The where conditions used to filter entities
 * @param take the number of entities to take
 * @param skip the number of entities which should be skipped before we start choosing entities to be returned
 * @param expandString
 */
export async function fetchUsers(
	userType?: UserType,
	args?: IWhereCondition<UserEntity>[][],
	take?: number,
	skip?: number,
	expandString?: string,
): Promise<{ data: UserEntity[], count: number }> {
	const hasConditions: IWhereCondition<UserEntity>[][] = [];

	if (!!userType) {
		hasConditions.push([]);

		if (userType !== UserType.CUSTOMER) {
			hasConditions[0].push({
				path: 'Role.Name',
				comparison: 'equal',
				value: 'User',
			});
		}
		if (userType !== UserType.STAFF) {
			hasConditions[0].push({
				path: 'Role.Name',
				comparison: 'equal',
				value: 'Staff',
			});
		}
		if (userType !== UserType.MANAGER) {
			hasConditions[0].push({
				path: 'Role.Name',
				comparison: 'equal',
				value: 'Manager',
			});
		}
		if (userType !== UserType.ADMIN) {
			hasConditions[0].push({
				path: 'Role.Name',
				comparison: 'equal',
				value: 'Admin',
			});
		}
	}

	const has: HasCondition<UserEntity>[][] | undefined = hasConditions.length > 0 ? [[{
		path: 'userGroups' as any,
		negate: true,
		conditions: hasConditions,
	}]] : undefined;

	return UserEntity.fetchAndCount<UserEntity>(
		{
			args: args,
			orderBy: [
				{
					path: 'modified',
					descending: true,
				},
				{
					path: 'id',
				},
			],
			take: take,
			skip: skip,
			has: has,
		},
		expandString,
		!!expandString ? 'list' : 'default',
	);
}
