import { RefObject } from 'react';

/**
 * Draw an image on a canvas taken with the given dimensions, starting from the top-center of the video.
 */
export const captureHorizontallyCentered = (
	videoRef: RefObject<HTMLVideoElement>,
	width: number,
	height: number,
	topOffset?: number,
) => {
	if (!videoRef.current) {
		throw new Error('VideoRef does not exist');
	}

	const canvas = document.createElement('canvas');

	// The video dimensions are different from the visible content seen on the scanning page
	const scale = videoRef.current.clientHeight / videoRef.current.videoHeight;

	// DANGER: rem value maybe different depending on user's browser settings
	const rem = 16 / scale;
	canvas.width = width * rem;
	canvas.height = height * rem;

	// Find the starting points in the video
	const sx = (videoRef.current.videoWidth - canvas.width) / 2;
	const sy = (topOffset ?? 0) * rem;

	const ctx = canvas.getContext('2d');
	ctx?.drawImage(videoRef.current, sx, sy, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);

	return canvas;
};
