import * as React from 'react';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { whiteLabelStore } from '../../../../../../../Models/WhiteLabelStore';

export function AdditionalNotesInfoSection({
	booking,
	canEdit,
	onEdit,
	isInvoiced,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const sectionContent = (
		<p className="booking-additional-notes">
			{booking.bookedSummary.note ?? '-'}
		</p>
	);

	return (
		<BookingDetailSection
			sectionName={
				booking.hasVehicle()
					? whiteLabelStore.cargoBookingNoteLabel
					: whiteLabelStore.passengerBookingNoteLabel
			}
			sectionContent={sectionContent}
			sectionClassName="notes"
			icon={{
				name: 'note',
				classname: 'note',
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: booking.hasVehicle() ? 'vehicle' : 'add-on',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
