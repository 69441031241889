import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { UserEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import If from 'Views/Components/If/If';
import { upperCaseFirst } from 'Util/StringUtils';
import { addInvoicedUser } from 'Services/Api/_HumanWritten/UserService';
import { FormErrors, validateInvoicedUserForm } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';
import PassengerTypePriceInputs from 'Views/Components/_HumanWritten/InvoicedUserForm/PassengerTypePriceInputs';
import MeasurementPriceInputs from 'Views/Components/_HumanWritten/InvoicedUserForm/MeasurementPriceInputs';
import AdditionalOptionPriceInputs from 'Views/Components/_HumanWritten/InvoicedUserForm/AdditionalOptionsPriceInputs';
import { useInvoicedUserCRUD } from 'Hooks/useInvoicedUserCRUD';

export interface InvoicedUserCRUDProps {
	userId?: string;
	mode: EntityFormMode;
}

export function InvoicedUserCRUD({ match }: RouteComponentProps<InvoicedUserCRUDProps>) {
	const { userId, mode } = match.params;
	const [errors, setErrors] = React.useState<FormErrors<UserEntity>>({});
	const [processingRequest, setProcessingRequest] = React.useState<boolean>(false);

	const response = useInvoicedUserCRUD(match.params);

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response?.type === 'error') {
		alertToast('Could not fetch invoiced user', 'error');
		return <div>Something went wrong!?!?</div>;
	}

	const invoicedUser = response.data;

	const onSubmit = async () => {
		setProcessingRequest(true);
		const newErrors: FormErrors<UserEntity> = validateInvoicedUserForm(response.data);
		const modelErrors = await response.data.validate();

		if (Object.keys(modelErrors).some(x => x.includes('phone')) && newErrors.phone === undefined) {
			newErrors.phone = 'An account with this phone number already exists';
		}

		setErrors(newErrors);

		if (Object.keys(newErrors).length !== 0) {
			setProcessingRequest(false);
			return;
		}

		if (mode === EntityFormMode.CREATE) {
			const responseCode = await addInvoicedUser(response.data);
			switch (responseCode.status) {
				case 200:
					alertToast('Successfully created invoiced user', 'success');
					store.routerHistory.push('/admin/user');
					break;
				// If we get a 409 it means the email address is already being used by a user
				case 409:
					alertToast(responseCode?.data?.message, 'error');
					setProcessingRequest(false);
					break;
				case 400:
					alertToast('Could not add/update invoiced user entity.', 'error');
					setProcessingRequest(false);
					break;
				case 401:
					alertToast('You do not have permissions to add a invoiced user.', 'error');
					setProcessingRequest(false);
					break;
			}
		} else if (mode === EntityFormMode.EDIT) {
			const newEditErrors: FormErrors<UserEntity> = await response.data.validate();

			if (Object.keys(newEditErrors).length === 0) {
				try {
					await response.data.save({ prices: {}, addOnsPrices: {} });
					setProcessingRequest(false);
					alertToast('Successfully updated invoiced user', 'success');
					store.routerHistory.push(`/admin/invoiced-user/view/${userId}`);
				} catch {
					setProcessingRequest(false);
				}
			}
		}
	};

	const clearError = (key: string) => {
		setErrors(state => {
			const newErrors = { ...state };
			newErrors[key] = undefined;
			return newErrors;
		});
	};

	const measurementPrices = invoicedUser?.prices.sort((a, b) => a.measurement.value - b.measurement.value);

	return (
		<div className="custom-crud-component">
			<section className="invoiced-user-section">
				<div className="custom-crud__header">
					<h2>{upperCaseFirst(mode.toLowerCase())} Invoiced User</h2>
					<If condition={mode === EntityFormMode.VIEW}>
						<Button
							className="edit"
							display={Display.Outline}
							onClick={() => store.routerHistory.push(`/admin/invoiced-user/edit/${userId}`)}
						>
							Edit
						</Button>
					</If>
				</div>
				<div className="custom-crud__body">
					<div className="custom-crud__body base-user-entity-section">
						<TextField
							id="first-name"
							model={invoicedUser}
							modelProperty="firstName"
							label="First name"
							isRequired
							placeholder="Enter first name"
							errors={errors.firstName}
							isReadOnly={mode === EntityFormMode.VIEW}
							onAfterChange={() => clearError('firstName')}
						/>
						<TextField
							id="last-name"
							model={invoicedUser}
							modelProperty="lastName"
							label="Last name"
							isRequired
							placeholder="Enter last name"
							errors={errors.lastName}
							isReadOnly={mode === EntityFormMode.VIEW}
							onAfterChange={() => clearError('lastName')}
						/>
						<TextField
							id="email"
							model={invoicedUser}
							modelProperty="email"
							label="Email"
							isRequired
							placeholder="Enter email"
							errors={errors.email}
							isReadOnly={mode !== EntityFormMode.CREATE}
							onAfterChange={() => clearError('email')}
						/>
						<TextField
							id="phone"
							model={invoicedUser}
							modelProperty="phone"
							label="Mobile"
							isRequired
							placeholder="Enter mobile"
							errors={errors.phone}
							isReadOnly={mode === EntityFormMode.VIEW}
							onAfterChange={() => clearError('phone')}
						/>
						<TextField
							id="postcode"
							model={invoicedUser}
							modelProperty="postcode"
							label="Postcode"
							isRequired
							placeholder="Enter postcode"
							errors={errors.postcode}
							isReadOnly={mode === EntityFormMode.VIEW}
							onAfterChange={() => clearError('postcode')}
						/>
					</div>
					<h4>Passenger pricing</h4>
					<div className="custom-crud__body passenger-pricing-section">
						<PassengerTypePriceInputs
							mode={mode}
							invoicedUser={invoicedUser}
						/>
					</div>
					<If condition={(invoicedUser?.prices?.length ?? 0) > 0}>
						<h4>
							{whiteLabelStore?.vehicleLabelPascalCase}{whiteLabelStore?.config?.trailersEnabled
								? ' / Trailer'
								: ''} pricing
						</h4>
						<p className="form-errors">{errors.prices}</p>
						<div className="custom-crud__body vehicle-pricing-section">
							<MeasurementPriceInputs
								mode={mode}
								invoicedUser={invoicedUser}
							/>
						</div>
					</If>
					<If condition={(invoicedUser?.addOnsPrices?.length ?? 0) > 0}>
						<h4>Additional options pricing</h4>
						<p className="form-errors">{errors.addOnsPrices}</p>
						<div className="custom-crud__body add-ons-pricing-section">
							<AdditionalOptionPriceInputs
								mode={mode}
								invoicedUser={invoicedUser}
							/>
						</div>
					</If>
				</div>
				<Button
					className="cancel-button"
					colors={Colors.Black}
					display={Display.Outline}
					sizes={Sizes.ExtraLarge}
					onClick={() => store.routerHistory.push('/admin/user')}
				>
					{mode === EntityFormMode.VIEW ? 'Back' : 'Cancel'}
				</Button>
				<If condition={mode !== EntityFormMode.VIEW}>
					<Button
						className="create-button"
						colors={Colors.Black}
						display={Display.Solid}
						sizes={Sizes.ExtraLarge}
						onClick={onSubmit}
						disabled={processingRequest}
					>
						{/* eslint-disable-next-line no-nested-ternary */}
						{processingRequest ? 'Loading...' : (mode === EntityFormMode.CREATE ? 'Create' : 'Update')}
					</Button>
				</If>
			</section>
		</div>
	);
}
