import { hasItemsOrInvalidProps, ValidationRule } from '../../../../../Validators/Functions/HumanWritten/ValidateForm';
import { EventDetailEntity, FerryTripEntity } from '../../../../../Models/Entities';
import {
	isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty, stringNotEmpty,
} from '../../../../../Util/TypeGuards';
import { EventSideBarProps } from './EventSideBar';

export const eventDetailsValidationRules: ValidationRule<EventDetailEntity, keyof EventDetailEntity>[] = [
	{
		fieldName: 'name',
		validation: value => !!value && stringNotEmpty(value.toString().trim()),
	},
	{
		fieldName: 'address',
		validation: value => !!value && stringNotEmpty(value.toString().trim()),
	},
	{
		fieldName: 'googleMapUrl',
		validation: value => !!value && stringNotEmpty(value.toString().trim()),
	},
	{
		fieldName: 'description',
		validation: value => !!value && stringNotEmpty(value.toString().trim()),
	},
	{
		fieldName: 'termsAndConditions',
		validation: value => !!value && stringNotEmpty(value.toString().trim()),
	},
	{
		fieldName: 'totalCapacity',
		validation: value => isNotNullOrUndefined(value) && !Number.isNaN(value),
	},
	{
		fieldName: 'totalCapacity',
		validation: value => {
			return typeof value === 'number' && value > 0;
		},
		errorMessage: 'Must be more than 0',
	},
	{
		fieldName: 'eventLabelId',
		validation: value => !!value,
	},
];

export const eventSidebarPropsValidationRules: ValidationRule<EventSideBarProps, keyof EventSideBarProps>[] = [
	{
		fieldName: 'headerImage',
		validation: value => isNotNullOrUndefined(value),
	},
	{
		fieldName: 'supportingImages', // aka supportingImages
		validation: value => hasItemsOrInvalidProps(value),
	},
	{
		fieldName: 'customTicketTypes',
		validation: value => hasItemsOrInvalidProps(value) && !(value as any[]).some(
			x => typeof x === 'object' && x !== null && (
				stringIsEmpty(x.singularName)
				|| stringIsEmpty(x.pluralName)
				|| isNullOrUndefined(x.quantity)
				|| isNullOrUndefined(x.maxCapacity)
				|| stringIsEmpty(x.description)
			),
		),
		errorMessage: 'Required fields',
	},
];
