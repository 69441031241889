import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import Tabs, { ITabConfig } from 'Views/Components/Tabs/Tabs';
import { CheckInTabLabel } from './CheckInTabLabel';
import useCheckInStore from 'Hooks/useCheckInStore';
import CheckInTable from '../CheckInTable/CheckInTable';
import CheckInList from '../CheckInList/CheckInList';
import useScreenSize from 'Hooks/useScreenSize';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import classNames from 'classnames';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { themeStore } from 'Models/ThemeStore';
import AddOnsFilterList from '../AddOnsFilter/AddOnsFilterList';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import AddOnsFilterTable from '../AddOnsFilter/AddOnsFilterTable';
import {
	checkedInCargoItemsCount,
	checkedInPassengers,
	checkedInVehicleBookings,
	checkedInVehicleWeight,
	getTotalPassengers,
	totalCargoItemsCount,
	totalVehicleBookings,
	totalVehicleWeight,
} from '../CheckInUtils';
import {
	CheckInCountType,
	CheckInViewState,
	useFilterAddOn,
	useSortSettings,
} from '../CheckInView';

export const CHECK_IN_PASSENGER_TAB_ID = 'passenger-tab';
export const CHECK_IN_VEHICLE_TAB_ID = 'vehicle-tab';
export const CHECK_IN_ADD_ONS_TAB_ID = 'add-ons-tab';

export interface CheckInTabInfo {
	// Passenger spaces
	totalPassengers: number;
	checkedInPassengers: number;

	// Vehicle spaces
	totalVehicles: number;
	totalCargoItems: number;
	checkedInVehicles: number;
	checkedInCargoItems: number;
	checkedInWeight: number;
	totalWeight: number;
}

export interface CheckInTabsProps {
	state: CheckInViewState;
}

function CheckInTabs(props: CheckInTabsProps) {
	const { state } = props;
	const {
		showCheckedIn,
		currentTab,
		tripHasCargoSpaces,
	} = state;

	const {
		countType,
	} = useSortSettings(x => ({
		countType: x.countType,
	}));

	const {
		addOnsModel,
		setFilteredAddOns,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
		setFilteredAddOns: x.setFilteredAddOns,
	}));

	const { isIpadMiniLandscape } = useScreenSize();
	const checkInStore = useCheckInStore();
	const { bookings } = checkInStore;

	const passengerTypeResponse = usePassengerTypes();

	const tabInfo = React.useMemo<CheckInTabInfo>(() => {
		// NOTE: Values should change when new bookings are made.
		if (!bookings || bookings.length === 0) {
			return {
				// Passenger spaces
				totalPassengers: 0,
				checkedInPassengers: 0,

				// Vehicle spaces
				totalVehicles: 0,
				totalCargoItems: 0,
				checkedInVehicles: 0,
				checkedInCargoItems: 0,
				checkedInWeight: 0,
				totalWeight: 0,
			};
		}
		return {
			// Passenger counts
			totalPassengers: getTotalPassengers(bookings),
			checkedInPassengers: checkedInPassengers(bookings),

			// Vehicle counts
			totalVehicles: totalVehicleBookings(bookings),
			totalCargoItems: totalCargoItemsCount(bookings),
			checkedInVehicles: checkedInVehicleBookings(bookings),
			checkedInCargoItems: checkedInCargoItemsCount(bookings),
			checkedInWeight: checkedInVehicleWeight(bookings),
			totalWeight: totalVehicleWeight(bookings),
		};
	}, [bookings, passengerTypeResponse.type]);

	const forCheckIn = !showCheckedIn;

	function getPassengerTabLabel() {
		return `${tabInfo.checkedInPassengers}/${tabInfo.totalPassengers}`;
	}

	function getVehicleTabLabel() {
		switch (countType) {
			case CheckInCountType.ItemCount:
				return `${tabInfo.checkedInCargoItems}/${tabInfo.totalCargoItems}`;
			case CheckInCountType.WeightCount:
				return `${tabInfo.checkedInWeight}/${tabInfo.totalWeight}`;
			default:
				return `${tabInfo.checkedInVehicles}/${tabInfo.totalVehicles}`;
		}
	}

	let tabs: ITabConfig[] = [];

	if (addOnsModel.filteredAddOns.length > 0) {
		tabs = [
			{
				key: CHECK_IN_ADD_ONS_TAB_ID,
				className: 'add-ons-tab',
				name: (
					<div id="add-ons-tab__label" className="add-ons-tab__label">
						<span>
							{addOnsModel.filteredAddOns.map(x => x.name).join(' | ')}
						</span>
						<Button
							className="clear-add-ons__btn"
							onClick={() => setFilteredAddOns([])}
							icon={{ icon: 'close', iconPos: 'icon-left' }}
							display={Display.Text}
							colors={Colors.None}
						/>
						{/* <span>{addOnsModel.filteredAddOns.map(x => { */}
						{/* 	return (<p>{x.name}</p>); */}
						{/* })} */}
						{/* </span> */}
					</div>
				),
				component: isIpadMiniLandscape
					? <AddOnsFilterList key="add-ons" />
					: <AddOnsFilterTable key="add-ons" />,
			},
		];

		runInAction(() => {
			state.currentTab = 0;
		});
	} else {
		tabs = [
			{
				key: CHECK_IN_PASSENGER_TAB_ID,
				name: (
					<CheckInTabLabel
						id={CHECK_IN_PASSENGER_TAB_ID}
						iconName="chevron-passenger"
						content={getPassengerTabLabel()}
					/>
				),
				component: isIpadMiniLandscape
					? <CheckInList key="passenger" forCheckIn={forCheckIn} />
					: <CheckInTable key="passenger" forCheckIn={forCheckIn} isVehicle={false} />,
			},
		];

		if (tripHasCargoSpaces) {
			tabs.push({
				key: CHECK_IN_VEHICLE_TAB_ID,
				name: (
					<div
						id={CHECK_IN_VEHICLE_TAB_ID}
						className={classNames(
							state.currentTab === 1 && themeStore.textColourTypeOnSecondary === 'DARK'
								? whiteLabelStore.vehicleIconLightClassName
								: whiteLabelStore.vehicleIconDarkClassName,
							'icon-left',
							'check-in__tab-label',
						)}
					>
						{`${getVehicleTabLabel()}`}
					</div>
				),
				component: isIpadMiniLandscape
					? <CheckInList key="vehicle" forCheckIn={forCheckIn} vehiclesOnly />
					: <CheckInTable key="vehicle" forCheckIn={forCheckIn} isVehicle />,
			});
		}
	}

	return (
		<Tabs
			className="check-in-tab check-in-tab--primary"
			currentTab={state.currentTab}
			onTabClicked={tabIndex => {
				runInAction(() => {
					state.currentTab = tabIndex as (0 | 1);
				});
			}}
			tabs={tabs}
		/>
	);
}

export default observer(CheckInTabs);
