import React from 'react';
import { observer } from 'mobx-react';
import { NotesEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { Button } from 'Views/Components/Button/Button';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';
import { Toggle } from '../../../Toggle/Toggle';
import { removeNoteFromFerryTrip } from 'Services/Api/_HumanWritten/NotesService';
import { TextArea } from '../../../../TextArea/TextArea';

interface NotesTableProps {
	notes: NotesEntity[];
	setNotes: (note: NotesEntity[]) => void;
	disabled: boolean;
}

function NotesTable({
	notes,
	setNotes,
	disabled,
}: NotesTableProps) {
	return (
		<>
			<Table>
				<TableHeader>
					<TableRow>
						<TableCell textAlign="left">Note</TableCell>
						<TableCell textAlign="right">Customer note</TableCell>
						<TableCell textAlign="right" />
					</TableRow>
				</TableHeader>
				<If condition={notes.length > 0}>
					<TableBody>
						{notes
							.map(x => {
								return (
									<TableRow key={x.id}>
										<If condition={disabled}>
											<TableCell textAlign="left">{x.content}</TableCell>
										</If>
										<If condition={!disabled}>
											<TableCell textAlign="left">
												<TextArea
													className="note-content-input-field"
													id={`note-content__${x.id}`}
													model={x}
													modelProperty="content"
													isDisabled={disabled}
													placeholder="Enter note content"
													isRequired
													onAfterChange={() => {}}
													errors={undefined}
												/>
											</TableCell>
										</If>
										<TableCell textAlign="right">
											<Toggle
												className={`ferry-trip-notes-toggle ${x.showToCustomers ? 'on' : 'off'} ${disabled ? 'disabled' : ''}`}
												id="ferry-trip-notes-toggle"
												model={x}
												modelProperty="showToCustomers"
												labelVisible
												labelOn=""
												labelOff=""
												disabled={disabled}
											/>
										</TableCell>
										<TableCell textAlign="right">
											<Button
												className={`"clear-line-item__btn ${disabled ? 'hidden' : ''}`}
												icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
												onClick={async () => {
													confirmModal(
														'Remove note?',
														// eslint-disable-next-line max-len
														<p>Are you sure you want to remove the note from this ferry trip?</p>,
													).then(async () => {
														await removeNoteFromFerryTrip(x.id);
														setNotes(notes.filter(y => y.id !== x.id));
													}).catch(() => {});
												}}
											/>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</If>
			</Table>
			<If condition={notes.length === 0}>
				<p className="empty-notes__message">No notes to show</p>
			</If>
		</>
	);
}

export default observer(NotesTable);
