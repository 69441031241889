import {
	BookingFormModeMutation,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/BookingForm/BookingFormEdit/BookingFormEdit';

export interface CustomLocationState {}

/**
 * The location state to expect when routing from RegisterCustomer.
 */
export interface RegisterCustomerLocationState extends CustomLocationState {
	/**
	 * userId is from the user entity id created from RegisterCustomer component.
	 */
	userId?: string;
}

export function isRegisterCustomerLocationState(object: unknown): object is RegisterCustomerLocationState {
	// IMPORTANT: Need to check for all properties of RegisterCustomerLocationState
	return Object.prototype.hasOwnProperty.call(object, 'userId');
}

export interface CustomerSelectionLocationState extends CustomLocationState {
	/**
	 * The url to navigate to after new customer is registered from RegisterCustomer component.
	 */
	backUrl?: string;
}

export function isCustomerSelectionLocationState(object: unknown): object is CustomerSelectionLocationState {
	// IMPORTANT: Need to check for all properties of CustomerSelectionLocationState
	return Object.prototype.hasOwnProperty.call(object, 'backUrl');
}

export interface CheckInLocationState extends CustomLocationState {
	/**
	 * The mode in progress to complete the booking form.
	 */
	mode?: BookingFormModeMutation;
	/**
	 * True means return booking will also be altered, false otherwise.
	 */
	applyToReturn?: string;
}

export function isCheckInLocationState(object: CustomLocationState): object is CheckInLocationState {
	// IMPORTANT: Need to check for all properties of isCheckInAddLocationState
	const fields = ['mode', 'applyToReturn'];
	return fields.every(x => Object.prototype.hasOwnProperty.call(object, x));
}

export interface QrCodeRedirectLocationState extends CustomLocationState {
	/**
	 * bookingId is the booking entity that was checked-in using the QrCodeRedirect component.
	 */
	bookingId?: string;
}

export function isQrCodeRedirectLocationState(object: CustomLocationState): object is QrCodeRedirectLocationState {
	// IMPORTANT: Need to check for all properties of QrCodeRedirectLocationState
	return Object.prototype.hasOwnProperty.call(object, 'bookingId');
}
