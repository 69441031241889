import React from 'react';
import { observer } from 'mobx-react';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import Password from 'Views/Components/Password/Password';
import { UpdatePasswordLocationState } from 'Views/Pages/UpdatePasswordPage';
import UpdatePasswordTitle from './UpdatePasswordTitle';
import { useUpdatePassword } from './useUpdatePassword';

interface UpdatePasswordProps {
	userInfo?: UpdatePasswordLocationState;
}

function UpdatePassword({ userInfo }: UpdatePasswordProps) {
	const {
		state,
		onUpdatePasswordClicked,
		onAfterChange,
	} = useUpdatePassword(userInfo);

	return (
		<div className="reset-password-body-content">
			<div className="reset-password-page">
				<form className="reset-password-form" onSubmit={onUpdatePasswordClicked}>
					<UpdatePasswordTitle name={userInfo?.name} />
					<p>Passwords must contain:</p>
					<ul>
						<li>12 characters</li>
					</ul>
					<Password
						id="new_password"
						className="new-password"
						model={state}
						modelProperty="password"
						label="New password"
						placeholder="New password"
						isRequired
						errors={state.errors.password}
						onAfterChange={() => onAfterChange('password')}
					/>
					<Password
						id="confirm_password"
						className="confirm-password"
						model={state}
						modelProperty="confirmPassword"
						label="Confirm password"
						placeholder="Confirm password"
						isRequired
						errors={state.errors.confirmPassword}
						onAfterChange={() => onAfterChange('confirmPassword')}
					/>
					<ButtonGroup
						alignment={Alignment.HORIZONTAL}
						className="confirm-pwd-buttons bottom-navigation-btn-group"
					>
						<Button
							className="cancel-register cancel-btn"
							display={Display.Solid}
							colors={Colors.Secondary}
							sizes={Sizes.Large}
							buttonProps={{ id: 'cancel_register' }}
							onClick={() => window.history.back()}
						>
							Cancel
						</Button>
						<Button
							disabled={state.loading}
							type="submit"
							className="confirm-reset-password confirm-btn"
							display={Display.Solid}
							sizes={Sizes.Large}
							buttonProps={{ id: 'confirm_reset_password' }}
							icon={
								state.loading
									? undefined
									: { icon: 'arrow-right', iconPos: 'icon-right' }
							}
						>
							{state.loading ? 'Updating...' : 'Confirm'}
						</Button>
					</ButtonGroup>
				</form>
			</div>
		</div>
	);
}

export default observer(UpdatePassword);
