import * as React from 'react';
import CheckInTopNav from '../CheckInTopNav';
import CheckInReviewContent from './CheckInReviewContent';

function CheckInReview() {
	return (
		<div id="check-in-body" className="body-content check-in review">
			<CheckInTopNav />
			<CheckInReviewContent />
		</div>
	);
}

export default CheckInReview;
