import * as React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import * as uuid from 'uuid';
import classNames from 'classnames';

interface IToggleProps<T> {
	model: T;
	modelProperty: string;
	id?: string;
	name?: string;
	className?: string;
	labelOn ?: string;
	labelOff?: string;
	labelVisible?: boolean;
	onChecked?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
	onAfterChecked?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void);
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	disabled?: boolean;
}

@observer
export class Toggle<T> extends React.Component<IToggleProps<T>, any> {
	public static defaultProps = {};
	private uuid = uuid.v4();

	public render() {
		const {
			id,
			name,
			className,
			labelOn,
			labelOff,
			labelVisible,
			model,
			modelProperty,
			inputProps,
			disabled,
		} = this.props;
		const idString = id || this.uuid.toString();
		const fieldId = `${idString}-field`;

		// need to do === false here, because if label visible was undefined, that would return false, whereas here we
		// want that to return true
		const ariaLabel = !(labelVisible === false) ? `off: ${labelOff}, on: ${labelOn}` : undefined;
		const classes = classNames('input-group input-group__toggle', className, disabled ? 'disabled' : '');

		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label id={idString} className={classes}>
				<input
					type="checkbox"
					id={fieldId}
					name={name}
					className="__toggle"
					onChange={this.onChecked}
					checked={model[modelProperty] || false}
					aria-label={ariaLabel}
					{...inputProps}
					disabled={disabled}
				/>
				<span className="input__toggle" />
				<span className="labels" data-on={labelOn ?? 'On'} data-off={labelOff ?? 'Off'} />
			</label>
		);
	}

	@action
	public onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (this.props.onChecked) {
			this.props.onChecked(event, event.target.checked);
		}

		this.props.model[this.props.modelProperty] = event.target.checked;

		this.props.onAfterChecked?.(event, event.target.checked);
	}
}
