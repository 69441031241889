import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import moment from 'moment';
import CopySvg from 'images/copy.svg';
import GiftSvg from 'images/gift.svg';
import If from 'Views/Components/If/If';
import useStore from 'Hooks/useStore';
import { RenderGiftCertificateDetailsModal } from '../../Modal/GiftCertificateDetailsModal';
import { GiftVoucherDto } from './GiftVouchersUtils';

export interface GiftVoucherCardProps {
	giftVoucher: GiftVoucherDto;
	refresh: () => void,
}

export const GiftVoucherCard = ({ giftVoucher, refresh }: GiftVoucherCardProps) => {
	const store = useStore();
	const { isStaff } = store;
	const onClickCode = (e: React.MouseEvent<HTMLElement>) => {
		navigator?.clipboard?.writeText(giftVoucher.code).then(() => {
			alertToast('Code copied!', 'success');
		});
		e.preventDefault();
		e.stopPropagation();
	};

	const onClickStaff = () => {
		RenderGiftCertificateDetailsModal(giftVoucher, refresh);
	};

	const isExpired = giftVoucher.status === 'EXPIRED' || giftVoucher.status === 'USEDEXPIRED';
	const isUsed = giftVoucher.status === 'USED';

	let statusText = '';
	let statusClassname = '';

	if (isExpired) {
		statusText = 'Expired';
		statusClassname = 'invalid';
	} else if (isUsed) {
		statusText = 'Used';
		statusClassname = 'invalid';
	} else {
		statusText = `Expires ${moment(giftVoucher.expiryDate).format('DD MMM YYYY')}`;
		statusClassname = 'valid';
	}

	return (
		<div
			onClick={() => {
				if (isStaff) {
					onClickStaff();
				}
			}}
			aria-hidden="true"
			className={`gift-voucher__container ${statusClassname}`}
		>
			<div className={`gift-voucher__body ${statusClassname}`}>
				<p className="gift-voucher-code__label">Code</p>
				<div className="gift-voucher-code__body">
					<h4
						aria-hidden="true"
						className="gift-voucher-code__amount"
						onClick={e => onClickCode(e)}
					>
						{giftVoucher.code.toUpperCase()}
						<img
							className="copy-icon"
							src={CopySvg}
							alt="copy-icon"
						/>
					</h4>
				</div>
				<div className="bottom-container">
					<p className={`gift-voucher-expiry ${statusClassname}`}>
						{`${statusText}`}
					</p>
					<div className="value-remaining__container">
						<If condition={!isExpired && !isUsed}>
							<img
								className="gift-icon"
								src={GiftSvg}
								alt="gift-icon"
							/>
						</If>
						<h4 className="gift-voucher-value-remaining">{`$${giftVoucher.remainingValue.toFixed(2)}`}</h4>
					</div>
				</div>
			</div>
		</div>
	);
};
