import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { FormatPhone, upperCaseFirst } from 'Util/StringUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { getMeasurementLabelFromId } from 'Util/_HumanWritten/MeasurementUtils';
import { VEHICLE_ICON_DARK_URL } from 'Constants';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { CargoTypeEntity } from '../../../../../../../Models/Entities';

export function VehicleInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	const {
		cargoInfo,
		towOnInfo,
		driverFirstName,
		driverLastName,
		driverPhone,
	} = booking.bookedSummary;

	const formatPhone = FormatPhone(driverPhone);
	const addRego = (cargoInfo?.cargoIdentification.toUpperCase() === 'N/A');

	const driverInfo = `${upperCaseFirst(driverFirstName)} ${upperCaseFirst(driverLastName)} (${formatPhone})`;

	const sectionContent = (
		<>
			<p className="vehicle-rego">
				<If condition={addRego}>
					<span>{whiteLabelStore.regoLabelPascalCase} </span>
				</If>
				{cargoInfo?.cargoIdentification.toUpperCase()}
			</p>
			<p className="vehicle-make-model">
				{cargoInfo?.cargoType.getFormattedVehicleMakeModel(
					whiteLabelStore.config.vehicleLengthEnabled
						? cargoInfo?.selectedLengthId
						: undefined,
				)}
			</p>
			{towOnInfo
				&& (
					<p className="trailer-info">
						{towOnInfo.towOnType.label} (
						{getMeasurementLabelFromId(towOnInfo.selectedLengthId)})
					</p>
				)}
			<p className="driver-info">
				{whiteLabelStore.driverLabelPascalCase}: {driverInfo}
			</p>
		</>
	);

	return (
		<BookingDetailSection
			sectionName={`${whiteLabelStore.vehicleLabelPascalCase} details`}
			sectionContent={sectionContent}
			sectionClassName="vehicle-details"
			icon={{
				overrideIcon: (
					<img
						alt="cargo-icon"
						className="booking-detail-section__icon vehicle"
						src={VEHICLE_ICON_DARK_URL}
					/>
				),
			}}
			modificationRules={{
				canEdit: canEdit,
				editType: 'vehicle',
				onEdit: onEdit,
				isInvoiced: isInvoiced,
			}}
		/>
	);
}
