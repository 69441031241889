import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import { BOOKINGS_CUSTOMER_PAGE_TITLE, CUSTOMER_BOOKINGS_PAGE_TITLE } from '../../ConstantPageNames';
import { EventDetailedView } from '../Components/_HumanWritten/Events/EventDetailedView/EventDetailedView';

interface RouteParams {
	id?: string
}

function EventsPageInner({ match }: RouteComponentProps<RouteParams>) {
	const { id } = match.params;

	return <EventDetailedView eventId={id} showButtons />;
}

export interface EventsPageProps extends RouteComponentProps {
}

@observer
class EventsPage extends React.Component<EventsPageProps> {
	render() {
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		document.title = BOOKINGS_CUSTOMER_PAGE_TITLE;
		if (window.loginData) {
			const { userName } = window.loginData;
			if (userName && (userName?.startsWith('staff@')
				|| userName?.startsWith('admin@') || userName?.startsWith('manager@'))) {
				document.title = CUSTOMER_BOOKINGS_PAGE_TITLE;
			}
		}

		return (
			<>
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div className="body-content">
					<EventsPageInner {...this.props} />
				</div>
			</>
		);
	}
}

export default EventsPage;
