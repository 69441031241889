import React from 'react';

export default function Errors({ errors: innerErrors }: { errors?: string | string[] }) {
	if (!innerErrors) {
		return null;
	}

	if (typeof innerErrors === 'string') {
		return (
			<div className="input-group__error-text">
				<p>{innerErrors}</p>
			</div>
		);
	}

	return (
		<div className="input-group__error-text">
			{innerErrors.map((error, idx) => {
				const key = `${error}${idx}`;
				return <p key={key}>{error}</p>;
			})}
		</div>
	);
}
