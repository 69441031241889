import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'Views/Components/Flex/Flex';
import If from 'Views/Components/If/If';
import { Text } from 'Views/Components/Text/Text';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import { EventCheckInOrUndoButton } from '../EventCheckInButtons/EventCheckInOrUndoButton';
import EventCheckInListRowPassengers from './EventCheckInListRowPassengers';
import { eventCheckInStore } from '../EventContext/EventCheckInContext';
import { FormatPhone } from '../../../../../../Util/StringUtils';

export interface CheckInListRowProps {
	booking: EventCheckInBookingOverviewDto;
	forCheckIn?: boolean;
	onClick?: (booking: EventCheckInBookingOverviewDto) => void;
}

export function GetModalContentForEventCheckIn(booking?: EventCheckInBookingOverviewDto) {
	if (isNullOrUndefined(booking)) {
		return '';
	}
	const message: string[] = [];

	// Passengers
	booking.bookedSummary.selectedTickets?.forEach(ticket => {
		const customTicketType = eventCheckInStore.eventDetails.customTicketTypes.find(x => x.id === ticket.customTicketTypeId);
		if (ticket.quantity > 0) {
			message.push(`${ticket.quantity} ${ticket.quantity > 1 ? customTicketType?.pluralName : customTicketType?.singularName}`);
		}
	});

	return message.join(', ');
}

export function EventCheckInListRow(props: CheckInListRowProps) {
	const {
		booking,
		forCheckIn = false,
		onClick,
	} = props;

	const onCheckIn = (e: any) => {
		e.stopPropagation();
		if (!booking.checkedIn) {
			confirmModal(
				'Confirm booking?',
				GetModalContentForEventCheckIn(booking),
				{},
			).then(() => {
				eventCheckInStore.checkInBooking(booking.id, forCheckIn);
			}).catch(() => {});
		} else {
			eventCheckInStore.checkInBooking(booking.id, forCheckIn);
		}
	};

	const onCardClick = (e: any) => {
		if (onClick) {
			onClick(booking);
		}
	};

	return (
		<Flex className={classNames('check-in-list__row', { 'checked-in': !forCheckIn })} onClick={onCardClick}>
			<Flex direction="col" flex={1} gap="xxxs" className="p-sm">
				<Flex gap="xxs">
					<Text weight="600" size="sm" wordBreak="break-all">
						<span className="mr-xxs fullname">
							{booking.getFullName()}
						</span>
						<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
							<span className="badge badge--primary text-word-break-keep-all">
								{booking?.user?.userDisplayName?.displayName}
							</span>
						</If>
					</Text>
				</Flex>
				<If condition={isNotNullOrUndefined(booking?.bookedSummary?.primaryPhone)}>
					<Text size="sm" color="gray">
						{FormatPhone(booking.bookedSummary.primaryPhone!)}
					</Text>
				</If>
				<Flex gap="sm">
					<EventCheckInListRowPassengers booking={booking} />
				</Flex>
			</Flex>
			<Flex direction="col" justify="center" className="mr-sm">
				<EventCheckInOrUndoButton
					forCheckIn={forCheckIn}
					iconOnly
					className={forCheckIn ? 'check-in-list__row__btn-green' : 'check-in-list__row__btn-grey'}
					onClick={onCheckIn}
				/>
			</Flex>
		</Flex>
	);
}
