import * as React from 'react';
import { useEffect } from 'react';
import { NotesEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { getAllFerryTripNotes } from '../../../../../Services/Api/_HumanWritten/NotesService';
import NotesTable from './CustomTables/NotesTable';
import { INotesEntityAttributes } from 'Models/Entities/NotesEntity';

export interface FerryTripNotesTabProps {
	ferryTripId: string;
	isDisabled: boolean;
	existingNotes: NotesEntity[] | null;
	setExistingNotes: (note: NotesEntity[]) => void;
	refresh: boolean;
}

export const FerryTripNotesTab = ({
	ferryTripId,
	isDisabled,
	existingNotes,
	setExistingNotes,
	refresh,
}: FerryTripNotesTabProps) => {
	useEffect(() => {
		const fetchNotes = async () => {
			const result = await getAllFerryTripNotes(ferryTripId);
			setExistingNotes(result.data.map((y: Partial<INotesEntityAttributes> | undefined) => new NotesEntity(y)));
		};
		fetchNotes();
	}, [ferryTripId, refresh]);

	const handleAddNote = () => {
		const newNotes = existingNotes ?? [];
		newNotes.push(new NotesEntity({
			ferryTripId: ferryTripId,
			content: '',
			showToCustomers: false,
		}));
		setExistingNotes([...newNotes]);
	};

	return (
		<div className="custom-sidebar__tab-content notes-tab">
			<If condition={existingNotes !== null}>
				<NotesTable
					notes={existingNotes as NotesEntity[]}
					setNotes={setExistingNotes}
					disabled={isDisabled}
				/>
				<If condition={!isDisabled}>
					<Button
						display={Display.Outline}
						colors={Colors.Black}
						onClick={async () => {
							handleAddNote();
						}}
					>
						Add note
					</Button>
				</If>
			</If>
			<If condition={existingNotes === null}>
				<LottieSpinner />
			</If>
		</div>
	);
};
