import React from 'react';

interface LoadingEllipsesProps {
	children: React.ReactNode;
}

export default function LoadingEllipses({ children }: LoadingEllipsesProps) {
	return (
		<div className="loading-ellipses">
			{children}
		</div>
	);
}
