import React, { ReactNode } from 'react';
import { StaffBookingsFilters } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsComponent';
import { BookingRender } from 'Views/Components/_HumanWritten/StaffBookings/BookingRender';
import Pagination from 'Views/Components/Pagination/Pagination';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { StaffBookingsHeaderButton } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsHeaderButton';
import { BookingOrderByOption } from 'Util/_HumanWritten/Bookings/FetchBookingsUtils';
import { BookingListSummaryDto } from '../../../../Util/_HumanWritten/Bookings/FetchBookingsWithFilters';
import { BookingEntity } from '../../../../Models/Entities';

export interface StaffBookingsCollectionProps {
	bookings: BookingEntity[] | null;
	totalBookingCount: number;
	perPage: number;
	filters: StaffBookingsFilters;
	setFilters: (newFilters: StaffBookingsFilters) => void;
	className?: string;
}

export function StaffBookingsCollection({
	bookings,
	totalBookingCount,
	perPage,
	filters,
	setFilters,
	className,
}: StaffBookingsCollectionProps) {
	const bookingRenders: ReactNode[] = [];

	if (bookings !== null) {
		bookings
			.forEach(booking => {
				bookingRenders.push(
					<BookingRender
						key={booking.id}
						booking={booking}
					/>,
				);
			});
	}

	return (
		<>
			<div className={className}>
				<table>
					<thead>
						<tr>
							<th>
								<StaffBookingsHeaderButton
									text="Name"
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th className="email-column__header">
								<StaffBookingsHeaderButton
									text="Email"
									orderByField={BookingOrderByOption.EMAIL}
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Booking ID"
									orderByField={BookingOrderByOption.BOOKING_ID}
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Type"
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Direction"
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Trip date"
									orderByField={BookingOrderByOption.TRIP_DATE}
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Created on"
									orderByField={BookingOrderByOption.CREATED_DATE}
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Amount ($)"
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							<th>
								<StaffBookingsHeaderButton
									text="Status"
									filters={filters}
									setFilters={setFilters}
								/>
							</th>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<th />
						</tr>
					</thead>
					<tbody>
						{bookingRenders}
					</tbody>
				</table>
				<If condition={bookings === null}>
					<LottieSpinner />
				</If>
				<If condition={bookings !== null && bookingRenders.length === 0}>
					<p className="staff-bookings__no-bookings">
						No bookings
					</p>
				</If>
				<If condition={bookingRenders.length > 0}>
					<div className="collection-component">
						<section className="staff-bookings__pagination collection__pagination">
							<section className="collection__load">
								<Pagination
									perPage={perPage}
									pageNo={filters.pageNo}
									totalRecords={totalBookingCount}
									onPageChange={pageNo => {
										const newFilters: StaffBookingsFilters = { ...filters };
										newFilters.pageNo = pageNo;
										setFilters(newFilters);
									}}
									showGoToPageBox={false}
								/>
							</section>
						</section>
					</div>
				</If>
			</div>
		</>
	);
}
