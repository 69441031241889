import { getDynamicPricingAttributes } from '../Bookings/FetchBookingsUtils';
import {
	FerryEntity,
	FerryTripEntity,
	LocationEntity,
	MeasurementEntity,
	PriceEntity,
	RouteEntity,
} from 'Models/Entities';
import { stringIsEmpty } from '../../TypeGuards';

export function getFerryTripExpandString(bookingToEditId: string | null = null, userId: string | null = null) {
	return `
		${FerryTripEntity.getAttributes().join('\n')}
		departureDateTime
		ferry {
			${FerryEntity.getAttributes().join('\n')}
		}
		route {
			${RouteEntity.getAttributes().join('\n')}
			departure {
				${LocationEntity.getAttributes().join('\n')}
			}
			destination {
				${LocationEntity.getAttributes().join('\n')}
			}
		}
		prices {
			${PriceEntity.getAttributes().join('\n')}
			measurement {
				${MeasurementEntity.getAttributes().join('\n')}
			}
		}
		waitlistItems {
			userId
		}
		${getSpaceCapacityAttributes(bookingToEditId)}
		${getDynamicPricingAttributes(userId)}
	`;
}

export function getSpaceCapacityAttributes(bookingToEditId: string | null = null) {
	const bookingToEdit = stringIsEmpty(bookingToEditId) ? null : `"${bookingToEditId}"`;
	return `
		passengerSpaceCapacity(bookingId: ${bookingToEdit}) {
			used
			remaining
			overbookingAllowanceUsed
			overbookingAllowanceRemaining
		}
		vehicleSpaceCapacity(bookingId: ${bookingToEdit}) {
			used
			remaining
			overbookingAllowanceUsed
			overbookingAllowanceRemaining
			trailerUsed
			trailerRemaining
		}
		weightSpaceCapacity(bookingId: ${bookingToEdit}) {
			used
			remaining
		}
	`;
}
