export const trackRegoScans = (numberOfScans: number) => {
	// @ts-ignore
	const { gtag } = window;
	if (gtag) {
		gtag('event', 'rego_scan', {
			event_category: 'engagement',
			event_label: 'Rego scans',
			value: numberOfScans,
		});
	}
};
