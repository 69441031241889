import * as React from 'react';
import { observer } from 'mobx-react';
import { FEATURE_IMAGE_1_URL, VEHICLE_ICON_DARK_URL } from 'Constants';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { clearFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	clearBookingWizardData,
	clearOldBookingWizardData,
	getBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import If from '../../If/If';
import Icon from '../Icon/Icon';
import useDeviceDetect from 'Hooks/useDeviceDetect';

export interface HomeProps {
	contactUs: boolean;
}

function Home({ contactUs }: HomeProps) {
	const { isMobile } = useDeviceDetect();

	const onScheduleClick = () => {
		clearBookingWizardData();
		store.routerHistory.push('/ferry-schedule');
	};

	const onEventsCatalogueClick = () => {
		store.routerHistory.push('/upcoming-events');
	};

	const onSearch = (type: 'passenger' | 'vehicle') => {
		const wizardData = getBookingWizardData({
			type: type,
		});
		if (wizardData.wizardMode === 'ALTERATION') {
			clearBookingWizardData();
			clearOldBookingWizardData();
			clearFerryBookingTransactionIdFromStorage();
		}
	};

	const onVehicleClick = () => {
		onSearch('vehicle');

		if (isMobile) {
			store.routerHistory.push('/booking-wizard?type=vehicle');
		} else {
			store.routerHistory.push('/home/search?type=cargo');
		}
	};

	const onPassengerClick = () => {
		onSearch('passenger');

		if (isMobile) {
			store.routerHistory.push('/booking-wizard?type=passenger');
		} else {
			store.routerHistory.push('/home/search?type=passenger');
		}
	};

	// Show intercom if the user has entered the application from a contact us link,
	// an assumption is that they have just navigated to the page.
	if (contactUs && !!(window as any).Intercom) {
		(window as any).Intercom('show');
	}

	const showEventsCatalogue = !whiteLabelStore.config.hideEvents;

	const cargoIcon = (
		<img
			alt="cargo-icon"
			className="primary-booking-type__icon vehicle"
			src={VEHICLE_ICON_DARK_URL}
		/>
	);

	const passengerIcon = (
		<Icon name="person" classname="secondary-booking-type__icon person" />
	);

	return (
		<>
			<div className="homepage-background">
				<img className="background-img" src={FEATURE_IMAGE_1_URL} alt="bg-img" />
				<div className="colour-overlay" />
			</div>
			<div className="home-page-container">
				<div className="begin-booking__container glass">
					<p className="white">BEGIN BOOKING</p>
					<h4 className="white">Select your ferry booking type</h4>
					<div className="booking-type-buttons__container">
						<Button
							className="primary-booking-type__button hide-underline`"
							onClick={whiteLabelStore.defaultBookingType === 'vehicle' ? onVehicleClick : onPassengerClick}
						>
							<h5>
								{whiteLabelStore.defaultBookingType === 'vehicle' ? cargoIcon : passengerIcon}
								{whiteLabelStore.defaultBookingType === 'vehicle' ? whiteLabelStore.vehicleLabelPascalCase : whiteLabelStore.passengerLabelPascalCase}
							</h5>
						</Button>
						<Button
							className="secondary-booking-type__button hide-underline"
							onClick={whiteLabelStore.defaultBookingType === 'vehicle' ? onPassengerClick : onVehicleClick}
						>
							<h5>
								{whiteLabelStore.defaultBookingType === 'vehicle' ? passengerIcon : cargoIcon}
								{whiteLabelStore.defaultBookingType === 'vehicle' ? whiteLabelStore.passengerLabelPascalCase : whiteLabelStore.vehicleLabelPascalCase}
							</h5>
						</Button>
					</div>
				</div>
				<div className="see-schedule__container glass">
					<p className="white">NOT READY TO BOOK YET?</p>
					<h4 className="white">Check schedule</h4>
					<Button
						className="check-ferry-schedule__button icon-arrow-right icon-right hide-underline"
						onClick={onScheduleClick}
						display={Display.Solid}
						sizes={Sizes.Large}
						colors={Colors.Primary}
					>
						Check ferry schedule
					</Button>
					<If condition={showEventsCatalogue}>
						<Button
							className="events-catalogue__button icon-arrow-right icon-right hide-underline"
							onClick={onEventsCatalogueClick}
							display={Display.Solid}
							sizes={Sizes.Large}
							colors={Colors.Primary}
						>
							Events catalogue
						</Button>
					</If>
				</div>
			</div>
		</>
	);
}

export default observer(Home);
