import React, { useEffect } from 'react';
import TripBookingSummaryCard, { tripSummaryLocationType } from './TripBookingSummaryCard';
import { BookingEntity } from 'Models/Entities';
import TripBookingSummaryTotal from './TripBookingSummaryTotal';
import classNames from 'classnames';
import { feeType } from 'Models/Enums';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { SelectedTrips } from '../../BookingWizardWrap';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { useHistory } from 'react-router';
import useFetchBookingPriceBreakdown from 'Hooks/useFetchBookingPrice';
import {
	useBookingWizardPricingCacheStore,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardPricingCacheStore';
import {
	ferryBookingToWizardData,
} from 'Util/_HumanWritten/BookingWizard/FerryBookingToWizardDataUtils';
import {
	removeBookingFromCart,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import {
	BookingWizardCartFields,
	BookingWizardData,
	clearBookingWizardData,
	getDefaultWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface TripBookingSummaryCardsInnerProps {
	bookings: BookingEntity[];
	wizardData?: BookingWizardData;
	onClearGiftCertificate?: (id: string) => void;
	onClearFee?: (feeId: string) => void;
	preProcessedCartFields?: BookingWizardCartFields[];
	removePreProcessedBooking: (tripId: string) => void;
	onRemoveManagerDiscount?: () => void,
	onRemoveFee?: (feeType: feeType, departureDiscountAmount?: number, returnDiscountAmount?: number) => void;
	/**
	 * The location that the trip summary is being rendered
	 */
	tripSummaryLocation: tripSummaryLocationType;
	/**
	 * Looks like Check-In is the only component that uses this prop...
	 */
	alterReturn?: boolean;
	selectedTrips?: SelectedTrips;
	setSelectedTrips?: (trips: SelectedTrips) => void;
	onUpdateWizardData?: (data: BookingWizardData) => void;
	refresh?: () => void;
	refetch?: boolean;
	setRefetch?: (refetch: boolean) => void;
	transactionId?: string;
	includeCreditCardFee?: boolean;
}

function TripBookingSummaryInner({
	bookings,
	wizardData,
	removePreProcessedBooking,
	preProcessedCartFields,
	onRemoveManagerDiscount,
	onRemoveFee,
	tripSummaryLocation,
	alterReturn,
	selectedTrips,
	setSelectedTrips,
	onClearGiftCertificate,
	refresh,
	refetch,
	setRefetch,
	onUpdateWizardData,
	transactionId,
	onClearFee,
	includeCreditCardFee = true,
}: TripBookingSummaryCardsInnerProps) {
	const afterPayment = tripSummaryLocation === 'booking-success';
	const cartView = tripSummaryLocation === 'cart' || tripSummaryLocation === 'check-in';

	const transactionInfo = useFetchBookingPriceBreakdown(
		tripSummaryLocation,
		[
			wizardData?.cargoTypeId,
			wizardData?.vehicleLengthId,
			wizardData?.vehicleWeightId,
			wizardData?.trailerTypeId,
			wizardData?.trailerLengthId,
			// Force re-render when array content changes
			JSON.stringify(wizardData?.departingTripOptions),
			JSON.stringify(wizardData?.returningTripOptions),
			refetch,
			refetch],
		transactionId,
		wizardData,
		includeCreditCardFee);

	const isAlteration = (wizardData?.wizardMode === 'ALTERATION' || transactionInfo?.isAlteration) ?? false;

	const history = useHistory();

	const {
		setBookingPrice,
		removeBookingPrice,
	} = useBookingWizardPricingCacheStore();

	let bookingsToDisplay: BookingWizardCartFields[];
	if (preProcessedCartFields !== undefined) {
		bookingsToDisplay = preProcessedCartFields;
	} else {
		// If it is cartView only show the bookings that are stored in the local storage
		// Else if it is not cartView (sideBarSummary) then show the already existing bookings and append the current
		// in progress booking to the list of bookings to render in the side bar
		bookingsToDisplay = cartView || afterPayment
			? ferryBookingToWizardData(
				bookings,
				undefined,
				undefined,
				isAlteration,
				tripSummaryLocation === 'check-in'
					? alterReturn
					: (
						(
							isNotNullOrUndefined(wizardData)
							&& (wizardData?.departureTrip === undefined
								|| wizardData.returningTicketId !== '')
						)
						|| (isAlteration && isNullOrUndefined(wizardData) && bookings[0].returnBooking !== null)
						|| !isAlteration
					),
			)
			: ferryBookingToWizardData(
				bookings,
				wizardData,
				selectedTrips,
				isAlteration,
				(
					isNotNullOrUndefined(wizardData)
					&& (
						wizardData?.departureTrip === undefined
						|| wizardData.returningTicketId !== ''
					)
				)
				|| !isAlteration
				|| alterReturn,
			);
	}

	const redirectToNextPage = () => {
		if (bookings?.length === 1) {
			handleSingleBookingInCart();
		} else if (!cartView) {
			store.routerHistory.push('/booking-wizard/cart');
		}
	};

	const clearBookingToEdit = () => {
		if (isNotNullOrUndefined(wizardData) && onUpdateWizardData) {
			const newData: BookingWizardData = {
				...wizardData,
				departureTicketId: '',
				returningTicketId: '',
				bookingToEdit: '',
			};

			onUpdateWizardData(newData);
		}
	};

	const handleSingleBookingInCart = () => {
		if (wizardData?.bulkBookingTripIds) {
			clearBookingWizardData();
			// store.clearBookingPrices();
			history.push('/ferry-schedule');
		} else if (wizardData) {
			clearBookingToEdit();
			store.routerHistory.push('/booking-wizard/tickets');
		}
	};

	const onRemoveBooking = async (bookingId: string): Promise<boolean> => {
		// store.removeBookingPrice(bookingId);
		removeBookingPrice(bookingId);

		if (bookingId === '') {
			// This is the current booking that only exists in the wizard
			if (onUpdateWizardData) {
				onUpdateWizardData(getDefaultWizardData());
			}
			return true;
		}

		// Remove a reserved booking
		const result = await removeBookingFromCart(bookingId);
		if (result.status !== 200) {
			alertToast(
				'There was an error removing this booking.',
				'error',
				'Failed to remove booking',
			);
			return false;
		}

		alertToast(
			'The booking has been removed from your cart.',
			'success',
			'Booking removed',
		);
		// If the user deletes the most recent booking, the booking wizard data
		// will be replaced with the next booking in the list of bookings
		// The deleted booking is still in the list, so we add another index
		// to avoid pre-filling wizard data with the deleted booking
		if (wizardData?.bookingToEdit === bookingId && bookings.length > 1 && onUpdateWizardData) {
			const bookingsToFilter = bookings.filter(booking => booking.id !== bookingId);
			const convertedBookings = ferryBookingToWizardData(bookingsToFilter);

			if (setSelectedTrips) {
				setSelectedTrips({
					departingTrip: undefined,
					returningTrip: undefined,
				});
			}
			onUpdateWizardData({ ...convertedBookings[convertedBookings.length - 1].wizardData });
		}
		return true;
	};

	useEffect(() => {
		if (isNotNullOrUndefined(transactionInfo)) {
			// We want to store the price of each booking so that we don't have to recalculate when the user is
			// adding another booking (note: we show the old booking in the sidebar with the total price).
			for (const x of transactionInfo?.linkedBookings) {
				if (x.departureBooking.bookingId) {
					let total = x.departureBooking.totalPrice;
					if (x.returnBooking?.bookingId) {
						total += x.returnBooking.totalPrice;
					}
					setBookingPrice(x.departureBooking.bookingId, total);
				}
			}
		}
	}, [transactionInfo]);

	const listOfBookings = bookingsToDisplay?.map((booking, count) => {
		const index = count;

		if (isNullOrUndefined(booking)) {
			return null;
		}
		return (
			<TripBookingSummaryCard
				key={index}
				count={index}
				booking={bookings.find(x => x.id === booking.wizardData.bookingToEdit) ?? bookings[0]}
				cartFields={booking}
				wizardData={wizardData}
				bookingInfo={
					// eslint-disable-next-line no-nested-ternary
					tripSummaryLocation === 'sidebar'
						? (isNotNullOrUndefined(wizardData?.bulkBookingTripIds)
							? transactionInfo?.linkedBookings.find(x => x.departureBooking.ferryTripId === booking.wizardData.departureTicketId)
							: transactionInfo?.linkedBookings[0])
						: transactionInfo?.linkedBookings
							.find(x => x.departureBooking.bookingId === booking.wizardData.bookingToEdit)
				}
				isAlteration={isAlteration ?? false}
				tripSummaryLocation={tripSummaryLocation}
				selectedTrips={selectedTrips}
				setSelectedTrips={setSelectedTrips}
				removePreProcessedBooking={removePreProcessedBooking}
				preProcessedCartFields={preProcessedCartFields}
				afterPayment={afterPayment}
				cartView={cartView}
				refresh={refresh}
				onRemoveBooking={onRemoveBooking}
				onUpdateWizardData={onUpdateWizardData}
				redirectToNextPage={redirectToNextPage}
			/>
		);
	});

	if (listOfBookings?.length === 0) {
		return (
			<div className="no-bookings-to-show">
				<h6>Nothing in the cart</h6>
			</div>
		);
	}

	const isCartView = cartView || afterPayment;
	const clazz = classNames('trip-booking-summary-card-container', {
		'cart-view': isCartView,
		'sidebar-view': !isCartView,
	});

	return (
		<div className={clazz}>
			{listOfBookings}
			<TripBookingSummaryTotal
				transactionPricingResults={transactionInfo ?? undefined}
				afterPayment={afterPayment}
				cartView={cartView}
				wizardData={wizardData}
				onClearGiftCertificate={onClearGiftCertificate}
				onRemoveManagerDiscount={onRemoveManagerDiscount}
				onRemoveFee={onRemoveFee}
				onClearFee={onClearFee}
				tripSummaryLocation={tripSummaryLocation}
				refetch={refetch}
				setRefetch={setRefetch}
				bookingIds={bookings.map(x => x.id)}
			/>
		</div>
	);
}

export default TripBookingSummaryInner;
