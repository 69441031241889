import { FetchGivenFerryTrips } from 'Util/_HumanWritten/FerryTrips/FetchGivenTrips';
import { FerryTripEntity } from 'Models/Entities';
import { BookingWizardPageParams } from 'Views/Pages/BookingWizardPage';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import { getStoredEventWizardData, getStoredWizardData } from 'Services/Api/_HumanWritten/BookingWizardCacheService';
import { SelectedTrips } from './BookingWizardWrap';
import { ParsedUrlQuery } from 'querystring';
import {
	BookingWizardData,
	getBookingWizardData,
	getBookingWizardDataCacheToken,
	removeBookingWizardDataCacheToken,
	saveBookingWizardDataToLocalStorage,
} from './BookingWizardData';
import {
	EventBookingWizardData,
	getEventBookingWizardData,
	getEventBookingWizardDataCacheToken,
	removeEventBookingWizardDataCacheToken,
	saveEventBookingWizardDataToLocalStorage,
} from '../EventsBookingWizard/EventsBookingWizardData';

export function fetchTripsForWizard(
	departingTripId: string,
	returningTripId: string,
	selectedTrips: SelectedTrips | null,
	setSelectedTrips: (trips: SelectedTrips) => void,
	userId: string | null = null,
) {
	const idsToFetch: string[] = [];
	const updatedSelectedTrips: SelectedTrips = {};

	/*
		Cases to cover here:
			* Id doesn't exist, trip does exist
				set trip to undefined
			* Id doesn't exist, trip doesn't exist
				set trip to undefined
			* Id does exist, trip doesn't exist
				fetch the trip associated with the id
			* Id does exist, trip does exist and is the same trip
				preserve the existing status
			* Id does exist, trip does exist and is a different trip
				fetch the trip associated with the id
	 */
	if (departingTripId === '') {
		updatedSelectedTrips.departingTrip = undefined;
	}
	if (departingTripId !== '') {
		if (departingTripId !== (selectedTrips?.departingTrip?.id ?? null)) {
			idsToFetch.push(departingTripId);
		} else {
			idsToFetch.push(departingTripId);
		}
	}

	if (returningTripId === '') {
		updatedSelectedTrips.returningTrip = undefined;
	}
	if (returningTripId !== '') {
		if (returningTripId !== (selectedTrips?.returningTrip?.id ?? null)) {
			idsToFetch.push(returningTripId);
		} else {
			idsToFetch.push(returningTripId);
		}
	}

	if (idsToFetch.length > 0) {
		FetchGivenFerryTrips(idsToFetch, null, userId).then(fetchedTrips => {
			if (idsToFetch.includes(departingTripId)) {
				updatedSelectedTrips.departingTrip = fetchedTrips.find(trip => trip.id === departingTripId);
			}

			if (idsToFetch.includes(returningTripId)) {
				updatedSelectedTrips.returningTrip = fetchedTrips.find(trip => trip.id === returningTripId);
			}

			setSelectedTrips(updatedSelectedTrips);
		});
	} else if (selectedTrips !== updatedSelectedTrips) {
		setSelectedTrips(updatedSelectedTrips);
	}
}

export function fetchBulkBookingTrips(
	tripIds: string[],
	setFerryTrips: (trips: FerryTripEntity[]) => void,
) {
	FetchGivenFerryTrips(tripIds).then(x => {
		setFerryTrips(x);
	});
}

export function getBookingWizardParameters(parsedParams?: ParsedUrlQuery): BookingWizardPageParams | null {
	if (!parsedParams) {
		return null;
	}

	if (getBookingWizardDataCacheToken() !== null) {
		return null;
	}

	const {
		date,
		from,
		to,
		route,
		type,
		ferryTripId,
		fromLocationName,
		toLocationName,
		bookingId,
		AccessCode,
		ErrorCode,
		redirectedFromEmail,
	} = parsedParams;

	if (isNotNullOrUndefined(AccessCode) || isNotNullOrUndefined(ErrorCode)) {
		// AccessCode is a query parameter which is supplied by stripe when we complete a payment. Stripe will navigate us to
		// a page of our choosing and give us the access code for us to verify the bookings. If AccessCode is supplied then
		// previously we would be navigated to the tickets page when we got that parameter, because there were parameters,
		// and the parameters did not fulfil the validation of earlier steps
		return null;
	}

	if (redirectedFromEmail === 'true') {
		store.setRedirectedFromWaitlistEmail(true);
	}

	let departureDate: Date | undefined;
	if (date) {
		departureDate = new Date(Date.parse(date as string));
	}
	return {
		date: departureDate,
		fromLocation: from as string,
		toLocation: to as string,
		routeId: route as string,
		type: type && parsedParams.type === 'passenger'
			? 'passenger'
			: 'vehicle',
		ferryTripId: ferryTripId as string,
		fromLocationName: fromLocationName as string,
		toLocationName: toLocationName as string,
		bookingId: bookingId as string,
	} as BookingWizardPageParams;
}

export async function getCachedBookingWizardData(): Promise<BookingWizardData> {
	const token = getBookingWizardDataCacheToken();
	if (token != null) {
		const wizardData = await getStoredWizardData(token);
		saveBookingWizardDataToLocalStorage(wizardData);
		removeBookingWizardDataCacheToken();
	}
	return getBookingWizardData(null);
}

export async function getCachedEventBookingWizardData(): Promise<EventBookingWizardData | null> {
	const token = getEventBookingWizardDataCacheToken();
	if (token != null) {
		const wizardData = await getStoredEventWizardData(token);
		saveEventBookingWizardDataToLocalStorage(wizardData);
		removeEventBookingWizardDataCacheToken();
	}
	return getEventBookingWizardData(undefined);
}
