/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import React from 'react';
import classNames from 'classnames';
// % protected region % [Add extra page imports here] on begin
import { Button } from 'Views/Components/Button/Button';
// % protected region % [Add extra page imports here] end

// % protected region % [Modify CardProps here] on begin
interface CardProps {
	rounded?: boolean;
	className?: string;
	icon?: string | React.ReactNode;
	iconOnly?: boolean;
	href?: string;
	children: React.ReactNode;
	makeButton?: boolean;
	onClick?: () => void;
	disabled?: boolean;
}
// % protected region % [Modify CardProps here] end

// % protected region % [Add extra implementation here] off begin
// % protected region % [Add extra implementation here] end

// % protected region % [Modify Card component here] on begin
export default function Card(props: CardProps) {
	const {
		children,
		className,
		href,
		iconOnly,
		icon,
		rounded,
		makeButton,
		onClick,
		disabled,
	} = props;

	let iconNode: React.ReactNode = null;
	if (icon) {
		if (typeof icon === 'string') {
			iconNode = <div className={classNames('icon', iconOnly ? 'icon-only' : '', icon)} />;
		} else {
			iconNode = <div className={classNames('icon', iconOnly ? 'icon-only' : '')}>{icon}</div>;
		}
	}

	if (makeButton) {
		return (
			<Button
				className={classNames('card', 'button', rounded ? 'card--rounded' : '', className)}
				onClick={onClick}
				disabled={disabled === true}
			>
				<div className="card--content">
					{iconNode}
					{children}
				</div>
			</Button>
		);
	}

	return (
		<a
			href={href}
			target="_blank"
			rel="noreferrer"
			className={classNames('card', rounded ? 'card--rounded' : '', className)}
		>
			<div className="card--content">
				{iconNode}
				{children}
			</div>
		</a>
	);
}
// % protected region % [Modify Card component here] end
