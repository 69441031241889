import React from 'react';
import If from '../../If/If';
import { isNotNullOrUndefined, stringIsEmpty } from '../../../../Util/TypeGuards';
import { Button } from 'Views/Components/Button/Button';

export interface AccountDetailsTabHeaderProps {
	headerText: string;
	buttonText?: string;
	onClick?: () => void;
}

export function AccountDetailsTabHeader({ headerText, buttonText, onClick }: AccountDetailsTabHeaderProps) {
	return (
		<div className="account-details-tab-header">
			<h4>{headerText}</h4>
			<If condition={!stringIsEmpty(buttonText) && isNotNullOrUndefined(onClick)}>
				<Button
					buttonProps={{
						id: 'edit-button',
					}}
					onClick={onClick}
				>
					{buttonText}
				</Button>
			</If>
		</div>
	);
}
