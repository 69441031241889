import useStore from 'Hooks/useStore';
import { observer } from 'mobx-react';
import { UserEntity } from 'Models/Entities';
import React, { useMemo, useRef, useState } from 'react';
import { registerForCustomer } from 'Services/Api/_HumanWritten/RegisterService';
import { isRequired } from 'Util/EntityUtils';
import alertToast from 'Util/ToastifyUtils';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { getAttributeComponent } from 'Views/Components/CRUD/Attributes/AttributeFactory';
import { EntityFormMode } from 'Views/Components/Helpers/Common';

export interface RegisterCustomerFormProps {
	/**
	 * This function will trigger the next step in RegisterCustomer component.
	 */
	next: (email: string, userId: string) => void;
}

function RegisterCustomerFormContent({ next }: RegisterCustomerFormProps) {
	const store = useStore();
	const [userEntity] = useState(new UserEntity());
	const [loading, setLoading] = useState(false);

	/**
	 * We want validation errors to appear only after the first submit.
	 */
	const firstSubmit = useRef(false);

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// Allow validation errors to appear
		firstSubmit.current = true;

		// Fields must be valid before sending creating user entity
		const errors = await userEntity.validate();
		if (Object.keys(errors).length > 0) {
			return;
		}

		// Create user entity
		try {
			setLoading(true);
			const response = await registerForCustomer(userEntity);
			next(userEntity.email, response.data);
		} catch (error) {
			setLoading(false);
			alertToast('Failed to create new user', 'error');
		}
	};

	/**
	 * Create the field components. This includes:
	 *
	 *     email
	 *     firstName
	 *     lastName
	 *     phone
	 *     postcode
	 *
	 */
	const renderAttributes = useMemo(() => {
		// Only trigger validation when:
		//   - submit button has been clicked at least once
		//   - the field has an error
		//   - the field value is not empty
		const onAfterChange = (attributeName: string) => {
			if (
				firstSubmit.current
				&& userEntity.getErrorsForAttribute(attributeName)
				&& userEntity[attributeName] !== undefined
			) {
				userEntity.validate();
			}
		};

		// Generate field components
		return userEntity.getAttributeCRUDOptions()
			.filter(attributeOption => {
				// We only want the required fields of UserEntity
				return isRequired(userEntity, attributeOption.attributeName);
			})
			.map(attributeOption => getAttributeComponent(
				attributeOption,
				userEntity,
				userEntity.getErrorsForAttribute(attributeOption.attributeName),
				EntityFormMode.CREATE,
				isRequired(
					userEntity,
					attributeOption.attributeName,
				),
				onAfterChange,
			));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userEntity, userEntity.validationErrors, firstSubmit.current]);
	// We disable lint because we need to watch for changes in [validationErrors, firstSubmit] values
	// where as lint only recommends: [userEntity]

	return (
		<form className="register-customer__body" onSubmit={onSubmit}>
			<h1>Register a new customer</h1>
			<div className="form-fields">
				{renderAttributes}
			</div>
			<div className="actions">
				<Button
					display={Display.Solid}
					sizes={Sizes.Medium}
					colors={Colors.Secondary}
					buttonProps={{ id: 'back_btn' }}
					className="actions--back"
					onClick={() => store.routerHistory.goBack()}
				>
					Back
				</Button>
				<Button
					type="submit"
					className="submit-register actions--continue"
					display={Display.Solid}
					sizes={Sizes.Medium}
					colors={Colors.Primary}
					buttonProps={{ id: 'continue_btn' }}
					disabled={loading}
				>
					{loading ? 'Loading...' : 'Continue'}
				</Button>
			</div>
		</form>
	);
}

// Observer is required as we need to see the changes in getAttributeComponent, specifically to display error labels
export const RegisterCustomerForm = observer(RegisterCustomerFormContent);
