import * as React from 'react';
import classNames from 'classnames';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { FerryTripImporterModalProps } from './FerryTripImporterModal';

export type FerryTripImporterModalContentsProps = Omit<FerryTripImporterModalProps, 'showModal'>

export function FerryTripImporterModalContents({
	importResponse,
	onContinue,
	onReset,
	onRequestClose,
}: FerryTripImporterModalContentsProps) {
	if (importResponse === undefined || !importResponse.hasErrors()) {
		return null;
	}

	const hasContinueBtn = onContinue !== undefined;
	const headerTitle = () => {
		let header = `${hasContinueBtn ? 'Found issues with' : 'Error uploading'} `;
		header += `${importResponse.data.failCount} of ${importResponse.data.tripCount} trips`;
		return header;
	};

	return (
		<>
			<div className="ferry-trip-import-modal__header">
				<h2>{headerTitle()}</h2>
				<p>
					<strong>There was a problem loading some trips.</strong>&nbsp;
					Re-upload a file or continue with adding the trips.
				</p>
			</div>
			<div className="ferry-trip-import-modal__body">
				<div className="scrollable">
					<table>
						<thead>
							<tr>
								<th>Line no.</th>
								<th>Date &#38; time</th>
								<th>Reason</th>
							</tr>
						</thead>
						<tbody>
							{
								importResponse.errors.map(x => (
									<tr key={`${x.line}${x.dateAndTime}${x.reason}`}>
										<td>{x.line}</td>
										<td>{x.dateAndTime}</td>
										<td>{x.reason}</td>
									</tr>
								))
							}
						</tbody>
					</table>
				</div>
			</div>
			<div className="ferry-trip-import-modal__footer">
				<Button
					display={Display.Solid}
					colors={Colors.Black}
					onClick={onRequestClose}
				>
					Cancel
				</Button>
				<Button
					className={classNames({ 'upload--primary': onContinue === undefined })}
					display={Display.Solid}
					colors={
						onContinue === undefined
							? Colors.Alternate
							: Colors.Black
					}
					onClick={onReset}
					buttonProps={{ id: 're-upload' }}
				>
					Upload new CSV
				</Button>
				<If condition={onContinue !== undefined}>
					<Button
						disabled={importResponse.data.successCount === 0}
						display={Display.Solid}
						colors={Colors.Alternate}
						onClick={onContinue}
						buttonProps={{ id: 'continue-btn' }}
					>
						Continue with {importResponse.data.successCount} trips
					</Button>
				</If>
			</div>
		</>
	);
}
