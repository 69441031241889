import * as React from 'react';
import { Text } from 'Views/Components/Text/Text';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';

interface CheckInListRowSubheaderProps {
	item: CheckInBookingOverviewDto;
	index: number;
	arr: CheckInBookingOverviewDto[];
	comparer: (x: CheckInBookingOverviewDto) => string;
}

export function CheckInListRowSubheader({
	item,
	index,
	arr,
	comparer,
}: CheckInListRowSubheaderProps) {
	if (arr.length === 0) {
		return null;
	}

	const value = comparer(item);

	if (index === 0 || value !== comparer(arr[index - 1])) {
		return (
			<Text className="check-in-list__row-subheader pt-xxs pb-xxs pl-md pr-md bottom-bar">{value}</Text>
		);
	}

	return null;
}
