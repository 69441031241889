import { LocationEntity, RouteEntity } from './Entities';
import { fetchAllRoutes } from '../Services/Api/_HumanWritten/RouteService';
import { FetchLocations } from '../Services/Api/_HumanWritten/LocationService';
import { whiteLabelStore } from './WhiteLabelStore';
import { stringNotEmpty } from '../Util/TypeGuards';
import { getFerryScheduleSettingsFromStorage } from '../Services/SessionStorage/LocationsSessionStorage';
import { observable, runInAction } from 'mobx';

interface ILocationAndRoutesStore {
	locations: LocationEntity[];
	routes: RouteEntity[];
	retrieveAsync(refresh?: boolean): Promise<void>;

	getRouteByLocationIds(fromLocationId: string, toLocationId: string): RouteEntity | null;
	getDefaultRoute(): RouteEntity | null;
	getAnyRoute(): RouteEntity | null;
	getSessionStorageRoute(): RouteEntity | null;
}

export class LocationAndRoutesStore implements ILocationAndRoutesStore {
	/**
	 * These are locations in the database.
	 */
	@observable
	private _locations: LocationEntity[] = [];

	/**
	 * These are the routes in the database.
	 */
	@observable
	private _routes: RouteEntity[] = [];

	/**
	 * True means the passenger types has been fetched, false otherwise.
	 */
	private _fetched: boolean = false;

	public async retrieveAsync(refresh = false) {
		if (refresh) {
			this._fetched = false;
		}
		if (this._fetched) {
			return;
		}

		// Fetch locations and routes
		const [routes, locations] = await Promise.all([
			fetchAllRoutes(),
			FetchLocations(),
		]);

		runInAction(() => {
			this._routes = routes;
			this._locations = locations;
			this._fetched = true;
		});
	}

	public get locations() {
		return this._locations;
	}

	public get routes() {
		return this._routes;
	}

	public getRouteByLocationIds(fromLocationId: string, toLocationId: string): RouteEntity | null {
		if (stringNotEmpty(fromLocationId) && stringNotEmpty(toLocationId)) {
			return this._routes.find(x => x.departureId === fromLocationId
				&& x.destinationId === toLocationId) ?? null;
		}

		return null;
	}

	public getDefaultRoute(): RouteEntity | null {
		const { defaultRouteId } = whiteLabelStore;

		if (defaultRouteId != null) {
			return this._routes.find(x => x.id === defaultRouteId) ?? null;
		}
		return null;
	}

	public getAnyRoute(): RouteEntity | null {
		return this._routes[0] ?? null;
	}

	public getSessionStorageRoute(): RouteEntity | null {
		if (getFerryScheduleSettingsFromStorage() !== null) {
			return this._routes.find(x => x.id === getFerryScheduleSettingsFromStorage()) ?? null;
		}
		return null;
	}
}

const locationAndRoutesStore: ILocationAndRoutesStore = new LocationAndRoutesStore();
export default locationAndRoutesStore;
