/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsTowOnTypeEntity from 'Models/Security/Acl/VisitorsTowOnTypeEntity';
import UserTowOnTypeEntity from 'Models/Security/Acl/UserTowOnTypeEntity';
import StaffTowOnTypeEntity from 'Models/Security/Acl/StaffTowOnTypeEntity';
import ManagerTowOnTypeEntity from 'Models/Security/Acl/ManagerTowOnTypeEntity';
import AdminTowOnTypeEntity from 'Models/Security/Acl/AdminTowOnTypeEntity';
import InvoicedUserTowOnTypeEntity from 'Models/Security/Acl/InvoicedUserTowOnTypeEntity';
import FixedPriceUserTowOnTypeEntity from 'Models/Security/Acl/FixedPriceUserTowOnTypeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITowOnTypeEntityAttributes extends IModelAttributes {
	label: string;
	disabled: boolean;
	staffOnly: boolean;

	minimumHeightId?: string;
	minimumHeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	minimumLengthId?: string;
	minimumLength?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	minimumWeightId?: string;
	minimumWeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	towOns: Array<
		| Models.TowOnEntity
		| Models.ITowOnEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TowOnTypeEntity', 'Tow On Type')
// % protected region % [Customise your entity metadata here] end
export default class TowOnTypeEntity extends Model
	implements ITowOnTypeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsTowOnTypeEntity(),
		new UserTowOnTypeEntity(),
		new StaffTowOnTypeEntity(),
		new ManagerTowOnTypeEntity(),
		new AdminTowOnTypeEntity(),
		new InvoicedUserTowOnTypeEntity(),
		new FixedPriceUserTowOnTypeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Label'] off begin
	/**
	 * The label that will display around the application for this tow on type.
	 */
	@Validators.Required()
	@observable
	@attribute<TowOnTypeEntity, string>()
	@CRUD({
		name: 'Label',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public label: string;
	// % protected region % [Modify props to the crud options here for attribute 'Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	/**
	 * Whether or not this tow on option is disabled from the frontend of the application.
	 */
	@observable
	@attribute<TowOnTypeEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Staff Only'] off begin
	/**
	 * Whether or not this tow on option is for only staff to book.
	 */
	@observable
	@attribute<TowOnTypeEntity, boolean>()
	@CRUD({
		name: 'Staff Only',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public staffOnly: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Staff Only'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Height'] off begin
		name: 'Minimum Height',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Height'] end
	})
	public minimumHeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumHeight: Models.MeasurementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Length'] off begin
		name: 'Minimum Length',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Length'] end
	})
	public minimumLengthId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumLength: Models.MeasurementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Minimum Weight'] off begin
		name: 'Minimum Weight',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Minimum Weight'] end
	})
	public minimumWeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public minimumWeight: Models.MeasurementEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On'] off begin
		name: 'Tow Ons',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.TowOnEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOns',
			oppositeEntity: () => Models.TowOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On'] end
	})
	public towOns: Models.TowOnEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITowOnTypeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITowOnTypeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.label !== undefined) {
				this.label = attributes.label;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.staffOnly !== undefined) {
				this.staffOnly = attributes.staffOnly;
			}
			if (attributes.minimumHeightId !== undefined) {
				this.minimumHeightId = attributes.minimumHeightId;
			}
			if (attributes.minimumHeight !== undefined) {
				if (attributes.minimumHeight === null) {
					this.minimumHeight = attributes.minimumHeight;
				} else if (attributes.minimumHeight instanceof Models.MeasurementEntity) {
					this.minimumHeight = attributes.minimumHeight;
					this.minimumHeightId = attributes.minimumHeight.id;
				} else {
					this.minimumHeight = new Models.MeasurementEntity(attributes.minimumHeight);
					this.minimumHeightId = this.minimumHeight.id;
				}
			}
			if (attributes.minimumLengthId !== undefined) {
				this.minimumLengthId = attributes.minimumLengthId;
			}
			if (attributes.minimumLength !== undefined) {
				if (attributes.minimumLength === null) {
					this.minimumLength = attributes.minimumLength;
				} else if (attributes.minimumLength instanceof Models.MeasurementEntity) {
					this.minimumLength = attributes.minimumLength;
					this.minimumLengthId = attributes.minimumLength.id;
				} else {
					this.minimumLength = new Models.MeasurementEntity(attributes.minimumLength);
					this.minimumLengthId = this.minimumLength.id;
				}
			}
			if (attributes.minimumWeightId !== undefined) {
				this.minimumWeightId = attributes.minimumWeightId;
			}
			if (attributes.minimumWeight !== undefined) {
				if (attributes.minimumWeight === null) {
					this.minimumWeight = attributes.minimumWeight;
				} else if (attributes.minimumWeight instanceof Models.MeasurementEntity) {
					this.minimumWeight = attributes.minimumWeight;
					this.minimumWeightId = attributes.minimumWeight.id;
				} else {
					this.minimumWeight = new Models.MeasurementEntity(attributes.minimumWeight);
					this.minimumWeightId = this.minimumWeight.id;
				}
			}
			if (attributes.towOns !== undefined && Array.isArray(attributes.towOns)) {
				for (const model of attributes.towOns) {
					if (model instanceof Models.TowOnEntity) {
						this.towOns.push(model);
					} else {
						this.towOns.push(new Models.TowOnEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		minimumHeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		minimumLength {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		minimumWeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		towOns {
			${Models.TowOnEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			towOns: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'towOns',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TowOnTypeEntity.prototype, 'created');
CRUD(modifiedAttr)(TowOnTypeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
