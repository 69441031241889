/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsTowOnEntity from 'Models/Security/Acl/VisitorsTowOnEntity';
import UserTowOnEntity from 'Models/Security/Acl/UserTowOnEntity';
import StaffTowOnEntity from 'Models/Security/Acl/StaffTowOnEntity';
import ManagerTowOnEntity from 'Models/Security/Acl/ManagerTowOnEntity';
import AdminTowOnEntity from 'Models/Security/Acl/AdminTowOnEntity';
import InvoicedUserTowOnEntity from 'Models/Security/Acl/InvoicedUserTowOnEntity';
import FixedPriceUserTowOnEntity from 'Models/Security/Acl/FixedPriceUserTowOnEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITowOnEntityAttributes extends IModelAttributes {
	legacyId: number;

	towOnDetails: Array<
		| Models.TowOnDetailEntity
		| Models.ITowOnDetailEntityAttributes
	>;
	selectedHeightId?: string;
	selectedHeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	selectedLengthId: string;
	selectedLength: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	selectedWeightId?: string;
	selectedWeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	towOnTypeId?: string;
	towOnType?: Models.TowOnTypeEntity | Models.ITowOnTypeEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TowOnEntity', 'Tow On')
// % protected region % [Customise your entity metadata here] end
export default class TowOnEntity extends Model
	implements ITowOnEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsTowOnEntity(),
		new UserTowOnEntity(),
		new StaffTowOnEntity(),
		new ManagerTowOnEntity(),
		new AdminTowOnEntity(),
		new InvoicedUserTowOnEntity(),
		new FixedPriceUserTowOnEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<TowOnEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Detail'] off begin
		name: 'Tow On Details',
		displayType: 'reference-multicombobox',
		order: 20,
		referenceTypeFunc: () => Models.TowOnDetailEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnDetails',
			oppositeEntity: () => Models.TowOnDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Detail'] end
	})
	public towOnDetails: Models.TowOnDetailEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Height'] off begin
		name: 'Selected Height',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Height'] end
	})
	public selectedHeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedHeight: Models.MeasurementEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Length'] off begin
		name: 'Selected Length',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Length'] end
	})
	public selectedLengthId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedLength: Models.MeasurementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Weight'] off begin
		name: 'Selected Weight',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Weight'] end
	})
	public selectedWeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedWeight: Models.MeasurementEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type'] off begin
		name: 'Tow On Type',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.TowOnTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type'] end
	})
	public towOnTypeId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public towOnType: Models.TowOnTypeEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITowOnEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITowOnEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.towOnDetails !== undefined && Array.isArray(attributes.towOnDetails)) {
				for (const model of attributes.towOnDetails) {
					if (model instanceof Models.TowOnDetailEntity) {
						this.towOnDetails.push(model);
					} else {
						this.towOnDetails.push(new Models.TowOnDetailEntity(model));
					}
				}
			}
			if (attributes.selectedHeightId !== undefined) {
				this.selectedHeightId = attributes.selectedHeightId;
			}
			if (attributes.selectedHeight !== undefined) {
				if (attributes.selectedHeight === null) {
					this.selectedHeight = attributes.selectedHeight;
				} else if (attributes.selectedHeight instanceof Models.MeasurementEntity) {
					this.selectedHeight = attributes.selectedHeight;
					this.selectedHeightId = attributes.selectedHeight.id;
				} else {
					this.selectedHeight = new Models.MeasurementEntity(attributes.selectedHeight);
					this.selectedHeightId = this.selectedHeight.id;
				}
			}
			if (attributes.selectedLengthId !== undefined) {
				this.selectedLengthId = attributes.selectedLengthId;
			}
			if (attributes.selectedLength !== undefined) {
				if (attributes.selectedLength === null) {
					this.selectedLength = attributes.selectedLength;
				} else if (attributes.selectedLength instanceof Models.MeasurementEntity) {
					this.selectedLength = attributes.selectedLength;
					this.selectedLengthId = attributes.selectedLength.id;
				} else {
					this.selectedLength = new Models.MeasurementEntity(attributes.selectedLength);
					this.selectedLengthId = this.selectedLength.id;
				}
			}
			if (attributes.selectedWeightId !== undefined) {
				this.selectedWeightId = attributes.selectedWeightId;
			}
			if (attributes.selectedWeight !== undefined) {
				if (attributes.selectedWeight === null) {
					this.selectedWeight = attributes.selectedWeight;
				} else if (attributes.selectedWeight instanceof Models.MeasurementEntity) {
					this.selectedWeight = attributes.selectedWeight;
					this.selectedWeightId = attributes.selectedWeight.id;
				} else {
					this.selectedWeight = new Models.MeasurementEntity(attributes.selectedWeight);
					this.selectedWeightId = this.selectedWeight.id;
				}
			}
			if (attributes.towOnTypeId !== undefined) {
				this.towOnTypeId = attributes.towOnTypeId;
			}
			if (attributes.towOnType !== undefined) {
				if (attributes.towOnType === null) {
					this.towOnType = attributes.towOnType;
				} else if (attributes.towOnType instanceof Models.TowOnTypeEntity) {
					this.towOnType = attributes.towOnType;
					this.towOnTypeId = attributes.towOnType.id;
				} else {
					this.towOnType = new Models.TowOnTypeEntity(attributes.towOnType);
					this.towOnTypeId = this.towOnType.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		towOnDetails {
			${Models.TowOnDetailEntity.getAttributes().join('\n')}
		}
		selectedHeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		selectedLength {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		selectedWeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		towOnType {
			${Models.TowOnTypeEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			towOnDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public errors: { [ key in 'selectedLengthId' | 'towOnTypeId']?: string } = {};
	@action
	public validateField = async (field: string) => {
		switch (field) {
			case 'selectedLengthId': {
				if (!this.selectedLengthId) {
					this.errors.selectedLengthId = 'Required field';
				} else {
					delete this.errors.selectedLengthId;
				}
				break;
			}
			case 'towOnTypeId': {
				if (!this.towOnTypeId) {
					this.errors.towOnTypeId = 'Required field';
				} else {
					delete this.errors.towOnTypeId;
				}
				break;
			}
		}
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TowOnEntity.prototype, 'created');
CRUD(modifiedAttr)(TowOnEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
