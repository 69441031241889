/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsAdditionalBookingOptionEntity from 'Models/Security/Acl/VisitorsAdditionalBookingOptionEntity';
import UserAdditionalBookingOptionEntity from 'Models/Security/Acl/UserAdditionalBookingOptionEntity';
import StaffAdditionalBookingOptionEntity from 'Models/Security/Acl/StaffAdditionalBookingOptionEntity';
import ManagerAdditionalBookingOptionEntity from 'Models/Security/Acl/ManagerAdditionalBookingOptionEntity';
import AdminAdditionalBookingOptionEntity from 'Models/Security/Acl/AdminAdditionalBookingOptionEntity';
import InvoicedUserAdditionalBookingOptionEntity from 'Models/Security/Acl/InvoicedUserAdditionalBookingOptionEntity';
import FixedPriceUserAdditionalBookingOptionEntity from 'Models/Security/Acl/FixedPriceUserAdditionalBookingOptionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IAdditionalBookingOptionEntityAttributes extends IModelAttributes {
	name: string;
	abbreviation: string;
	information: string;
	order: number;
	staticPrice: number;
	removable: boolean;
	excludeGST: boolean;
	canExportReport: boolean;
	showInCheckIn: boolean;
	removalTitle: string;
	removalInformation: string;
	isSingular: boolean;
	disabled: boolean;
	isvehicleonly: boolean;
	colour: string;
	emailTemplate: string;
	iconId: string;
	icon: Blob;

	addOnsPrices: Array<
		| Models.AddOnsPriceEntity
		| Models.IAddOnsPriceEntityAttributes
	>;
	additionalBookingSectionId: string;
	additionalBookingSection: Models.AdditionalBookingSectionEntity | Models.IAdditionalBookingSectionEntityAttributes;
	tickets: Array<
		| Models.TicketEntity
		| Models.ITicketEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AdditionalBookingOptionEntity', 'Additional Booking Option')
// % protected region % [Customise your entity metadata here] end
export default class AdditionalBookingOptionEntity extends Model
	implements IAdditionalBookingOptionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsAdditionalBookingOptionEntity(),
		new UserAdditionalBookingOptionEntity(),
		new StaffAdditionalBookingOptionEntity(),
		new ManagerAdditionalBookingOptionEntity(),
		new AdminAdditionalBookingOptionEntity(),
		new InvoicedUserAdditionalBookingOptionEntity(),
		new FixedPriceUserAdditionalBookingOptionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Add on name',
		description: 'The name of the add on that will appear around the front-end of the application.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] off begin
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Abbreviation',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abbreviation: string;
	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] end

	// % protected region % [Modify props to the crud options here for attribute 'Information'] on begin
	@Validators.Required()
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Information about add on',
		description: 'Information about the add on, for customers to see when making a booking.',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public information: string;
	// % protected region % [Modify props to the crud options here for attribute 'Information'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	@Validators.Integer()
	@observable
	@attribute<AdditionalBookingOptionEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Static Price'] on begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<AdditionalBookingOptionEntity, number>()
	@CRUD({
		name: 'Price of add on',
		description: 'The price of the add on that will be charged to customers when they add it to their booking.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public staticPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Static Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Removable'] on begin
	/**
	 * Indicates whether this option can be removed when altering a booking.
	 */
	@Validators.Required()
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Allow add-on to be removed',
		description: 'If checked, the add on can be removed from a booking during an alteration. Otherwise it is '
			+ 'not refundable unless they contact the staff in the office.',
		displayType: 'checkbox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public removable: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Removable'] end

	// % protected region % [Modify props to the crud options here for attribute 'Exclude GST'] on begin
	/**
	 * When true, this item will not be included in GST calculations.
	 */
	@Validators.Required()
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Add on excludes GST',
		description: 'If checked, the add on does not contain any GST and therefore all reports will not '
			+ 'calculate GST for this item.',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public excludeGST: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Exclude GST'] end

	// % protected region % [Modify props to the crud options here for attribute 'Can Export Report'] on begin
	/**
	 * Whether this additional booking option can be exported or not.
	 */
	@Validators.Required()
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Can export report',
		description: 'If checked, there will be a report option on the reports tab for staff to export a list of '
			+ 'all purchases during a specified period of time.',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public canExportReport: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Can Export Report'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show In Check In'] off begin
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Show In Check In',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showInCheckIn: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Show In Check In'] end

	// % protected region % [Modify props to the crud options here for attribute 'Removal Title'] on begin
	/**
	 * This is the message displayed when altering an additional booking option on a booking.
	 */
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Removal Title',
		displayType: 'hidden',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public removalTitle: string;
	// % protected region % [Modify props to the crud options here for attribute 'Removal Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Removal Information'] on begin
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Removal Information',
		displayType: 'hidden',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public removalInformation: string;
	// % protected region % [Modify props to the crud options here for attribute 'Removal Information'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Singular'] on begin
	/**
	 * This is set to true if only a single item can be added to a booking
	 */
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Is singular',
		description: 'If checked, a user will only be able to add one of these to each booking. This is useful for a '
			+ 'vehicle permit on an island, as you only need one per vehicle.',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isSingular: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Singular'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'hidden',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'IsVehicleOnly'] on begin
	/**
	 * True when add on is only related to vehicle bookings
	 */
	@observable
	@attribute<AdditionalBookingOptionEntity, boolean>()
	@CRUD({
		name: 'Is vehicle only',
		description: 'If checked, this add on will only appear for vehicle (or equivalent cargo) bookings. '
			+ 'If unchecked, this item will only appear for passenger bookings.',
		displayType: 'checkbox',
		order: 120,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isvehicleonly: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'IsVehicleOnly'] end

	// % protected region % [Modify props to the crud options here for attribute 'Colour'] on begin
	/**
	 * Hex value
	 */
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Colour',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public colour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Email template'] on begin
	@observable
	@attribute<AdditionalBookingOptionEntity, string>()
	@CRUD({
		name: 'Email template',
		description: 'This will be the text that will appear in the booking confirmation email, to let the user know '
			+ 'they have purchased an add on. It is a good place to add any important info the user needs to know '
			+ 'about the add on.',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public emailTemplate: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email template'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] on begin
	/**
	 * The icon that will show for this additional booking option.
	 */
	@observable
	@attribute<AdditionalBookingOptionEntity, string>({ file: 'icon' })
	@CRUD({
		name: 'Icon',
		displayType: 'hidden',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'icon',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="The icon that will show for this additional booking option." style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public iconId: string;

	@observable
	public icon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] on begin
		name: 'Add Ons Prices',
		displayType: 'hidden',
		order: 150,
		referenceTypeFunc: () => Models.AddOnsPriceEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'addOnsPrices',
			oppositeEntity: () => Models.AddOnsPriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] end
	})
	public addOnsPrices: Models.AddOnsPriceEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Section'] on begin
		name: 'Additional booking section',
		description: 'The additional booking section that this add on will appear in.',
		displayType: 'reference-combobox',
		order: 160,
		referenceTypeFunc: () => Models.AdditionalBookingSectionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Section'] end
	})
	public additionalBookingSectionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public additionalBookingSection: Models.AdditionalBookingSectionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] off begin
		name: 'Tickets',
		displayType: 'reference-multicombobox',
		order: 200,
		referenceTypeFunc: () => Models.TicketEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tickets',
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.TicketEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAdditionalBookingOptionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAdditionalBookingOptionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.abbreviation !== undefined) {
				this.abbreviation = attributes.abbreviation;
			}
			if (attributes.information !== undefined) {
				this.information = attributes.information;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.staticPrice !== undefined) {
				this.staticPrice = attributes.staticPrice;
			}
			if (attributes.removable !== undefined) {
				this.removable = attributes.removable;
			}
			if (attributes.excludeGST !== undefined) {
				this.excludeGST = attributes.excludeGST;
			}
			if (attributes.canExportReport !== undefined) {
				this.canExportReport = attributes.canExportReport;
			}
			if (attributes.showInCheckIn !== undefined) {
				this.showInCheckIn = attributes.showInCheckIn;
			}
			if (attributes.removalTitle !== undefined) {
				this.removalTitle = attributes.removalTitle;
			}
			if (attributes.removalInformation !== undefined) {
				this.removalInformation = attributes.removalInformation;
			}
			if (attributes.isSingular !== undefined) {
				this.isSingular = attributes.isSingular;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.isvehicleonly !== undefined) {
				this.isvehicleonly = attributes.isvehicleonly;
			}
			if (attributes.colour !== undefined) {
				this.colour = attributes.colour;
			}
			if (attributes.emailTemplate !== undefined) {
				this.emailTemplate = attributes.emailTemplate;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.iconId !== undefined) {
				this.iconId = attributes.iconId;
			}
			if (attributes.addOnsPrices !== undefined && Array.isArray(attributes.addOnsPrices)) {
				for (const model of attributes.addOnsPrices) {
					if (model instanceof Models.AddOnsPriceEntity) {
						this.addOnsPrices.push(model);
					} else {
						this.addOnsPrices.push(new Models.AddOnsPriceEntity(model));
					}
				}
			}
			if (attributes.additionalBookingSectionId !== undefined) {
				this.additionalBookingSectionId = attributes.additionalBookingSectionId;
			}
			if (attributes.additionalBookingSection !== undefined) {
				if (attributes.additionalBookingSection === null) {
					this.additionalBookingSection = attributes.additionalBookingSection;
				} else if (attributes.additionalBookingSection instanceof Models.AdditionalBookingSectionEntity) {
					this.additionalBookingSection = attributes.additionalBookingSection;
					this.additionalBookingSectionId = attributes.additionalBookingSection.id;
				} else {
					this.additionalBookingSection = new Models.AdditionalBookingSectionEntity(attributes.additionalBookingSection);
					this.additionalBookingSectionId = this.additionalBookingSection.id;
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.TicketEntity) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.TicketEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		additionalBookingSection {
			${Models.AdditionalBookingSectionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AdditionalBookingOptionEntity.prototype, 'created');
CRUD(modifiedAttr)(AdditionalBookingOptionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
