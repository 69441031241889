import { SERVER_URL } from 'Constants';
import axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import alertToast from 'Util/ToastifyUtils';
import { CheckInDto } from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';

export const checkInBaseUrl = `${SERVER_URL}/api/check-in`;

/**
 * Creates new alteration for booking simply to update the associated ferry trip.
 * @param bookingId The ID of the booking to be updated.
 * @param ferryTripId The ID of the ferry trip to update booking.
 */
export async function checkInMove(bookingId: string, ferryTripId: string) {
	try {
		const res = axios.post(
			buildUrl(`${checkInBaseUrl}/move`, { bookingId, ferryTripId }),
		);
		return res;
	} catch (e: any) {
		alertToast(e.toString(), 'error');
		return null;
	}
}

/**
 * Returns the routing path based on bookingId and user group.
 */
export async function checkInQrCode(bookingId: string) {
	return axios.get(
		buildUrl(`${checkInBaseUrl}`, { bookingId }),
	);
}

export async function checkInBoarding(ferryTripId: string, bookingId: string) {
	return axios.get(
		buildUrl(`${checkInBaseUrl}/boarding`, { ferryTripId, bookingId }),
	);
}

/**
 * Returns whether the booking check in status has successfully been changed or not
 * @param checkInDto: contains bookingId and checkedIn bool
 */
export async function updateCheckedInStatus(checkInDto: CheckInDto) {
	return axios.post(
		`${checkInBaseUrl}/update-checkin-status`,
		checkInDto,
	);
}

/**
 * Gets the check-in data required to show check-in initial page load.
 * @param ferryTripId: the id of the ferry trip to fetch the data for
 */
export async function getCheckInData(ferryTripId: string) {
	try {
		const response = axios.get(
			buildUrl(`${checkInBaseUrl}/get-check-in-data`, { ferryTripId }),
		);
		return response;
	} catch (e: any) {
		alertToast(e.toString(), 'error');
		return null;
	}
}

/**
 * Gets the check-in data required to show check-in initial page load.
 * @param ferryTripId: the id of the ferry trip to fetch the data for
 */
export async function getEventCheckInData(eventId: string) {
	try {
		const response = axios.get(
			buildUrl(`${checkInBaseUrl}/get-event-check-in-data`, { eventId }),
		);
		return response;
	} catch (e: any) {
		alertToast(e.toString(), 'error');
		return null;
	}
}

/**
 * Gets a booking for check-in for the view booking page/sidebar.
 * @param bookingId: the id of the booking to fetch the data for
 */
export async function getBooking(bookingId: string) {
	const response = axios.get(
		buildUrl(`${checkInBaseUrl}/get-booking-by-id`, { bookingId }),
	);
	return response;
}

/**
 * Gets an eventbooking for check-in for the view booking page/sidebar.
 * @param bookingId: the id of the booking to fetch the data for
 */
export async function getEventBooking(bookingId: string) {
	const response = axios.get(
		buildUrl(`${checkInBaseUrl}/get-event-booking-by-id`, { bookingId }),
	);
	return response;
}
