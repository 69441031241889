import * as React from 'react';
import moment from 'moment/moment';

export function GetFormattedDate(startDate: Date, endDate: Date) {
	const startDateString = moment(startDate).format('dddd DD MMM, YYYY');
	const endDateString = moment(endDate).format('dddd DD MMM, YYYY');

	if (startDateString !== endDateString) {
		return `${startDateString} - ${endDateString}`;
	}
	return startDateString;
}

export function GetFormattedTime(startTime: Date, endTime: Date) {
	return `${moment(startTime).format('h:mma')} - ${moment(endTime).format('h:mma')}`;
}

export function GetFormattedDateTime(startDate: Date, endDate: Date, startTime: Date, endTime: Date) {
	const startDateString = moment(startDate).format('dddd DD MMM, YYYY');
	const endDateString = moment(endDate).format('dddd DD MMM, YYYY');

	if (startDateString !== endDateString) {
		// eslint-disable-next-line max-len
		return `${startDateString}, ${moment(startTime).format('h:mma')} - ${endDateString}, ${moment(endTime).format('h:mma')}`;
	}
	return `${startDateString}, ${moment(startTime).format('h:mma')} - ${moment(endTime).format('h:mma')}`;
}
