import React from 'react';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import {
	EventBookingWizardData,
	getCustomTicketInfoFromWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { EventDetailEntity } from 'Models/Entities';

export interface EventBreakdownDescriptionProps {
	wizardData: EventBookingWizardData;
	eventDetails: EventDetailEntity;
}

export function EventBreakdownDescription({ wizardData, eventDetails }: EventBreakdownDescriptionProps) {
	usePassengerTypes();

	const description = getCustomTicketInfoFromWizardData(wizardData, eventDetails);

	return <>{description}</>;
}
