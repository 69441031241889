/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsRouteEntity from 'Models/Security/Acl/VisitorsRouteEntity';
import UserRouteEntity from 'Models/Security/Acl/UserRouteEntity';
import StaffRouteEntity from 'Models/Security/Acl/StaffRouteEntity';
import ManagerRouteEntity from 'Models/Security/Acl/ManagerRouteEntity';
import AdminRouteEntity from 'Models/Security/Acl/AdminRouteEntity';
import InvoicedUserRouteEntity from 'Models/Security/Acl/InvoicedUserRouteEntity';
import FixedPriceUserRouteEntity from 'Models/Security/Acl/FixedPriceUserRouteEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { stringNotEmpty } from '../../Util/TypeGuards';
// % protected region % [Add any further imports here] end

export interface IRouteEntityAttributes extends IModelAttributes {
	name: string;
	duration: number;
	disabled: boolean;
	closeCheckInMinutesBeforeDeparture: number;
	mapId: string;
	map: Blob;
	mapCanvasCoordinates: string;

	additionalBookingSectionss: Array<
		| Models.RoutesAdditionalBookingSections
		| Models.IRoutesAdditionalBookingSectionsAttributes
	>;
	configGeneral?: Models.ConfigGeneralEntity |
		Models.IConfigGeneralEntityAttributes;
	departureId: string;
	departure: Models.LocationEntity | Models.ILocationEntityAttributes;
	destinationId: string;
	destination: Models.LocationEntity | Models.ILocationEntityAttributes;
	feeOptions: Array<
		| Models.RouteFeeOption
		| Models.IRouteFeeOptionAttributes
	>;
	ferryTrips: Array<
		| Models.FerryTripEntity
		| Models.IFerryTripEntityAttributes
	>;
	bookingCutoffs: Array<
		| Models.BookingCutoffRoute
		| Models.IBookingCutoffRouteAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RouteEntity', 'Route')
// % protected region % [Customise your entity metadata here] end
export default class RouteEntity extends Model
	implements IRouteEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsRouteEntity(),
		new UserRouteEntity(),
		new StaffRouteEntity(),
		new ManagerRouteEntity(),
		new AdminRouteEntity(),
		new InvoicedUserRouteEntity(),
		new FixedPriceUserRouteEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<RouteEntity, string>()
	@CRUD({
		name: 'Route name',
		description: 'This name will not be seen on the front-end of the application by customers. '
			+ 'Recommend the route name to contain the route location names.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Duration'] on begin
	/**
	 * Duration of trip in minutes
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<RouteEntity, number>()
	@CRUD({
		name: 'Route duration (minutes)',
		description: 'The duration of the route in minutes. Must be a whole number.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public duration: number;
	// % protected region % [Modify props to the crud options here for attribute 'Duration'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	@observable
	@attribute<RouteEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'hidden',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Close Check In Minutes Before Departure'] on begin
	/**
	 * How many minutes before the departure time on this route will users no longer be able to make bookings on the route.
	 */
	@Validators.Numeric()
	@observable
	@attribute<RouteEntity, number>()
	@CRUD({
		name: 'Close check in minutes before departure',
		description: 'How many minutes before the trip departs do you want this route to close ferry trips?',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public closeCheckInMinutesBeforeDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Close Check In Minutes Before Departure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map'] off begin
	@observable
	@attribute<RouteEntity, string>({ file: 'map' })
	@CRUD({
		name: 'Map',
		displayType: 'file',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'map',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Route" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public mapId: string;

	@observable
	public map: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Map'] end

	// % protected region % [Modify props to the crud options here for attribute 'Map Canvas Coordinates'] off begin
	@observable
	@attribute<RouteEntity, string>()
	@CRUD({
		name: 'Map Canvas Coordinates',
		description: 'The coordinates of the map canvas.',
		displayType: 'textarea',
		order: 60,
		searchable: true,
		headerColumn: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public mapCanvasCoordinates: string;
	// % protected region % [Modify props to the crud options here for attribute 'Map Canvas Coordinates'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Sections'] on begin
		name: 'Additional booking sections',
		description: 'A list of all the additional booking option sections associated to this route.',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RoutesAdditionalBookingSections,
		optionEqualFunc: makeJoinEqualsFunc('additionalBookingSectionsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'routeEntity',
			oppositeEntityName: 'additionalBookingSectionEntity',
			relationName: 'routes',
			relationOppositeName: 'additionalBookingSections',
			entity: () => Models.RouteEntity,
			joinEntity: () => Models.RoutesAdditionalBookingSections,
			oppositeEntity: () => Models.AdditionalBookingSectionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Additional Booking Sections'] end
	})
	public additionalBookingSectionss: Models.RoutesAdditionalBookingSections[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Config General'] on begin
		name: 'Config General',
		displayType: 'hidden',
		order: 80,
		referenceTypeFunc: () => Models.ConfigGeneralEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'configGenerals',
			oppositeEntity: () => Models.ConfigGeneralEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Config General'] end
	})
	public configGeneral?: Models.ConfigGeneralEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Departure'] on begin
		name: 'Departure location',
		description: 'The departure location for this route.',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.LocationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Departure'] end
	})
	public departureId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public departure: Models.LocationEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Destination'] on begin
		name: 'Destination location',
		description: 'The destination location for this route.',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.LocationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Destination'] end
	})
	public destinationId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public destination: Models.LocationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Fee Option'] on begin
		name: 'Fee Option',
		displayType: 'hidden',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RouteFeeOption,
		optionEqualFunc: makeJoinEqualsFunc('feeOptionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'routeEntity',
			oppositeEntityName: 'feeOptionEntity',
			relationName: 'route',
			relationOppositeName: 'feeOption',
			entity: () => Models.RouteEntity,
			joinEntity: () => Models.RouteFeeOption,
			oppositeEntity: () => Models.FeeOptionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Fee Option'] end
	})
	public feeOptions: Models.RouteFeeOption[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] on begin
		name: 'Ferry Trips',
		displayType: 'hidden',
		order: 70,
		referenceTypeFunc: () => Models.FerryTripEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ferryTrips',
			oppositeEntity: () => Models.FerryTripEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] end
	})
	public ferryTrips: Models.FerryTripEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Booking Cutoff'] on begin
		name: 'Booking cutoff rules',
		description: 'A list of all the booking cutoff rules associated to this route.',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BookingCutoffRoute,
		optionEqualFunc: makeJoinEqualsFunc('bookingCutoffId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'routeEntity',
			oppositeEntityName: 'bookingCutoffEntity',
			relationName: 'route',
			relationOppositeName: 'bookingCutoff',
			entity: () => Models.RouteEntity,
			joinEntity: () => Models.BookingCutoffRoute,
			oppositeEntity: () => Models.BookingCutoffEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Booking Cutoff'] end
	})
	public bookingCutoffs: Models.BookingCutoffRoute[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRouteEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRouteEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.duration !== undefined) {
				this.duration = attributes.duration;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.closeCheckInMinutesBeforeDeparture !== undefined) {
				this.closeCheckInMinutesBeforeDeparture = attributes.closeCheckInMinutesBeforeDeparture;
			}
			if (attributes.map !== undefined) {
				this.map = attributes.map;
			}
			if (attributes.mapId !== undefined) {
				this.mapId = attributes.mapId;
			}
			if (attributes.mapCanvasCoordinates !== undefined) {
				this.mapCanvasCoordinates = attributes.mapCanvasCoordinates;
			}
			if (attributes.additionalBookingSectionss !== undefined && Array.isArray(attributes.additionalBookingSectionss)) {
				for (const model of attributes.additionalBookingSectionss) {
					if (model instanceof Models.RoutesAdditionalBookingSections) {
						this.additionalBookingSectionss.push(model);
					} else {
						this.additionalBookingSectionss.push(new Models.RoutesAdditionalBookingSections(model));
					}
				}
			}
			if (attributes.configGeneral !== undefined) {
				if (attributes.configGeneral === null) {
					this.configGeneral = attributes.configGeneral;
				} else if (attributes.configGeneral instanceof Models.ConfigGeneralEntity) {
					this.configGeneral = attributes.configGeneral;
				} else {
					this.configGeneral = new Models.ConfigGeneralEntity(attributes.configGeneral);
				}
			}
			if (attributes.departureId !== undefined) {
				this.departureId = attributes.departureId;
			}
			if (attributes.departure !== undefined) {
				if (attributes.departure === null) {
					this.departure = attributes.departure;
				} else if (attributes.departure instanceof Models.LocationEntity) {
					this.departure = attributes.departure;
					this.departureId = attributes.departure.id;
				} else {
					this.departure = new Models.LocationEntity(attributes.departure);
					this.departureId = this.departure.id;
				}
			}
			if (attributes.destinationId !== undefined) {
				this.destinationId = attributes.destinationId;
			}
			if (attributes.destination !== undefined) {
				if (attributes.destination === null) {
					this.destination = attributes.destination;
				} else if (attributes.destination instanceof Models.LocationEntity) {
					this.destination = attributes.destination;
					this.destinationId = attributes.destination.id;
				} else {
					this.destination = new Models.LocationEntity(attributes.destination);
					this.destinationId = this.destination.id;
				}
			}
			if (attributes.feeOptions !== undefined && Array.isArray(attributes.feeOptions)) {
				for (const model of attributes.feeOptions) {
					if (model instanceof Models.RouteFeeOption) {
						this.feeOptions.push(model);
					} else {
						this.feeOptions.push(new Models.RouteFeeOption(model));
					}
				}
			}
			if (attributes.ferryTrips !== undefined && Array.isArray(attributes.ferryTrips)) {
				for (const model of attributes.ferryTrips) {
					if (model instanceof Models.FerryTripEntity) {
						this.ferryTrips.push(model);
					} else {
						this.ferryTrips.push(new Models.FerryTripEntity(model));
					}
				}
			}
			if (attributes.bookingCutoffs !== undefined && Array.isArray(attributes.bookingCutoffs)) {
				for (const model of attributes.bookingCutoffs) {
					if (model instanceof Models.BookingCutoffRoute) {
						this.bookingCutoffs.push(model);
					} else {
						this.bookingCutoffs.push(new Models.BookingCutoffRoute(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (stringNotEmpty(attributes.mapCanvasCoordinates)) {
				this.mapCanvasCoordinates = attributes.mapCanvasCoordinates;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		additionalBookingSectionss {
			${Models.RoutesAdditionalBookingSections.getAttributes().join('\n')}
			additionalBookingSections {
				${Models.AdditionalBookingSectionEntity.getAttributes().join('\n')}
			}
		}
		bookingCutoffs {
			${Models.BookingCutoffRoute.getAttributes().join('\n')}
			bookingCutoff {
				${Models.BookingCutoffEntity.getAttributes().join('\n')}
			}
		}
		departure {
			${Models.LocationEntity.getAttributes().join('\n')}
		}
		destination {
			${Models.LocationEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RouteEntity.prototype, 'created');
CRUD(modifiedAttr)(RouteEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
