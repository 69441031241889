import React from 'react';
import { UserEntity } from 'Models/Entities';
import { Button } from 'Views/Components/Button/Button';
import { StaffBookingsComponent } from 'Views/Components/_HumanWritten/StaffBookings/StaffBookingsComponent';
import { UserDisabledTag, UserDisplayNameTag } from 'Views/Components/_HumanWritten/UserDisplayNameTag';

export interface SearchResultsProps {
	user: UserEntity | null;
	onClickView: () => void;
}

export function SearchResults({
	user,
	onClickView,
}: SearchResultsProps) {
	if (user === null) {
		return <></>;
	}

	return (
		<div className="search-results__wrap">
			<h3>
				{user.getFullName()}
			</h3>
			<h4>
				Account
			</h4>
			<div className="staff-bookings search-results">
				<div className="staff-bookings__table">
					<table>
						<thead>
							<tr>
								<th>
									<div className="staff-bookings__header-order-btn-wrap">
										Name
									</div>
								</th>
								<th>
									<div className="staff-bookings__header-order-btn-wrap">
										Mobile
									</div>
								</th>
								<th>
									<div className="staff-bookings__header-order-btn-wrap">
										Email
									</div>
								</th>
								<th>
									<div className="staff-bookings__header-order-btn-wrap">
										Postcode
									</div>
								</th>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<th />
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{/* eslint-disable-next-line max-len */}
									{user.getFullName()} {UserDisplayNameTag(user?.userDisplayName?.displayName)} {user.disabled && UserDisabledTag()}
								</td>
								<td>
									{user.phone}
								</td>
								<td>
									{user.email}
								</td>
								<td>
									{user.postcode}
								</td>
								<td className="search-results__view-btn">
									<Button
										onClick={onClickView}
										className="link-view-booking link-rm-txt-dec hide-underline"
									>
										View
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<StaffBookingsComponent userId={user.id} onResultsPage />
		</div>
	);
}
