import { runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

enum ScreenSize {
	IpadLandscape = 'IpadLandscape',
	IpadPotrait = 'IpadPotrait',
	Mobile = 'Mobile'
}

export default function useScreenSize() {
	const state = useLocalStore<{
		isIpadMiniPortrait: boolean;
		isIpadMiniLandscape: boolean;
		isMobile: boolean;
	}>(() => ({
		isIpadMiniPortrait: checkScreenSize(ScreenSize.IpadPotrait, window.innerWidth),
		isIpadMiniLandscape: checkScreenSize(ScreenSize.IpadLandscape, window.innerWidth),
		isMobile: checkScreenSize(ScreenSize.Mobile, window.innerWidth),
	}));

	useEffect(() => {
		const resize = () => {
			runInAction(() => {
				state.isIpadMiniPortrait = checkScreenSize(ScreenSize.IpadPotrait, window.innerWidth);
				state.isIpadMiniLandscape = checkScreenSize(ScreenSize.IpadLandscape, window.innerWidth);
				state.isMobile = checkScreenSize(ScreenSize.Mobile, window.innerWidth);
			});
		};

		window.addEventListener('resize', resize);
		window.addEventListener('orientationchange', resize);
		window.addEventListener('load', resize);
		window.addEventListener('reload', resize);

		return () => {
			window.removeEventListener('resize', resize);
			window.removeEventListener('orientationchange', resize);
			window.removeEventListener('load', resize);
			window.removeEventListener('reload', resize);
		};
	}, []);

	return state;
}

function checkScreenSize(screenSize: ScreenSize, width: number) {
	switch (screenSize) {
		case ScreenSize.IpadLandscape:
			return width <= 1023;
		case ScreenSize.IpadPotrait:
			return width <= 820;
		case ScreenSize.Mobile:
			return width <= 480;
		default:
	}
	return true;
}
