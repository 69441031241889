import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import * as React from 'react';
import { stringNotEmpty } from 'Util/TypeGuards';
import { useSearchTerm } from '../../FerryCheckIn/CheckInView';
import { TextField } from 'Views/Components/TextBox/TextBox';

export function EventCheckInSearch() {
	const {
		model,
		setSearchTerm,
	} = useSearchTerm(x => ({
		model: x.model,
		setSearchTerm: x.setSearchTerm,
	}));

	return (
		<div className="check-in-list__search">
			<Icon name="search" classname="check-in-list__search-icon" />
			<TextField
				model={model}
				modelProperty="searchTerm"
				className="check-in-list__search-input"
				placeholder="Search by name, phone or email"
				clickToClear={stringNotEmpty(model.searchTerm)}
				onClickToClear={e => {
					setSearchTerm(e.currentTarget.value);
				}}
				autoFocus={stringNotEmpty(model.searchTerm)}
				onAfterChange={e => {
					setSearchTerm(e.currentTarget.value);
				}}
			/>
		</div>
	);
}
