import React, { useEffect, useState } from 'react';
import { observable, runInAction } from 'mobx';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import useStore from 'Hooks/useStore';
import useAsync from 'Hooks/useAsync';
import { fetchUserDisplayTypes } from 'Services/Api/_HumanWritten/UserService';
import If from 'Views/Components/If/If';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { store } from 'Models/Store';
import { Toggle } from 'Views/Components/_HumanWritten/Toggle/Toggle';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { AccountDetailsTabHeader } from 'Views/Components/_HumanWritten/AccountDetails/AccountDetailsTabHeader';
import { CollapseExpand } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/CollapseExpand';
import { ReadOnlyField } from 'Views/Components/_HumanWritten/AccountDetails/AccountFields/ReadOnlyField';
import { Button } from 'Views/Components/Button/Button';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import PassengerTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/PassengerTable';
import { vehicleTrailerTitle } from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripPricingTab';
import MeasurementsTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/MeasurementsTable';
import AdditionalOptionsTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/AdditionalOptionsTable';
import {
	getAllAdditionalBookingOptions,
} from 'Services/Api/_HumanWritten/AdditionalBookingOptionsService';
import {
	AddOnsPriceEntity,
	DefaultPricingEntity,
	MeasurementEntity,
	PriceEntity,
	UserDisplayNameEntity,
	UserEntity,
} from 'Models/Entities';

export interface FixedPricingFieldsProps {
	user?: UserEntity;
	originalUser?: UserEntity;
	mode: EntityFormMode;
	setMode: (mode: EntityFormMode) => void;
	setNeedConfirm: (value: boolean) => void;
	errors: FormErrors<UserEntity>;
}
function assignDefaultPricing(user: UserEntity, defaultPricing: DefaultPricingEntity) {
	user.adultPrice = defaultPricing.adultPrice;
	user.childPrice = defaultPricing.childPrice;
	user.infantPrice = defaultPricing.infantPrice;
	user.priceD = defaultPricing.passengerDPrice;
	user.priceE = defaultPricing.passengerEPrice;
	user.priceF = defaultPricing.passengerFPrice;
	user.priceG = defaultPricing.passengerGPrice;
	user.priceH = defaultPricing.passengerHPrice;
	user.prices = defaultPricing.prices.map(x => {
		return new PriceEntity({
			amount: x.amount,
			measurement: x.measurement,
			measurementId: x.measurementId,
		});
	});
	user.addOnsPrices = defaultPricing.addOnsPrices.map(x => {
		return new AddOnsPriceEntity({
			price: x.price,
			additionalBookingOption: x.additionalBookingOption,
			additionalBookingOptionId: x.additionalBookingOptionId,
		});
	});
}

function clearPricingFromUser(user: UserEntity) {
	user.prices = [];
	user.addOnsPrices = [];
	user.userDisplayNameId = undefined;
}
function FixedPricingFields({
	user,
	originalUser,
	mode,
	setMode,
	setNeedConfirm,
	errors,
}: FixedPricingFieldsProps) {
	const { isManager } = useStore();

	const [checkboxState, setCheckboxState] = useState<boolean>(isNotNullOrUndefined(user?.userDisplayNameId));
	const [passengerPricingOpen, setPassengerPricingOpen] = useState<boolean>(false);
	const [measurementPricingOpen, setMeasurementPricingOpen] = useState<boolean>(false);
	const [addOnsPricingOpen, setAddOnsPricingOpen] = useState<boolean>(false);

	const [userDisplayName, setUserDisplayName] = useState<UserDisplayNameEntity | null | undefined>(null);
	const userModel = observable({
		isSpecialUser: checkboxState,
	});

	useEffect(() => {
		if (Object.keys(errors).includes('prices')) {
			setMeasurementPricingOpen(true);
		}
		if (Object.keys(errors).includes('adultPrice')) {
			setPassengerPricingOpen(true);
		}
		if (Object.keys(errors).includes('addOnsPrices')) {
			setAddOnsPricingOpen(true);
		}
	}, [errors]);

	useEffect(() => {
		if (isNotNullOrUndefined(user) && checkboxState && originalUser?.userDisplayNameId === null) {
			getAllAdditionalBookingOptions().then(options => {
				const additionalOptionPrices = options?.map(x => new AddOnsPriceEntity({
					price: x.staticPrice,
					additionalBookingOption: x,
				}));
				// Create base user entity
				runInAction(() => {
					if (user.prices === null || user.prices.length === 0) {
						user.prices = store.measurements.map(x => new PriceEntity({
							measurementId: x.id,
							measurement: new MeasurementEntity({
								id: x.id,
								label: x.label,
								value: x.value,
								measurementType: x.measurementType,
							}),
						})).sort((m1, m2) => m1.measurement.value > m2.measurement.value ? 1 : -1);
					}

					if (user.addOnsPrices === null || user.addOnsPrices.length === 0) {
						user.addOnsPrices = additionalOptionPrices;
					}
				});
			});
		}
		if (isNotNullOrUndefined(user) && !checkboxState && originalUser?.userDisplayNameId !== null) {
			runInAction(() => {
				user.userDisplayNameId = undefined;
			});
		}
	}, [checkboxState]);

	const response = useAsync(() => {
		return fetchUserDisplayTypes();
	}, [user]);

	useEffect(() => {
		if (isNotNullOrUndefined(user?.userDisplayNameId)) {
			setUserDisplayName(response.data?.find(x => x.id === user?.userDisplayNameId));
		}
	}, [response?.type]);

	if (response.type === 'error' || isNullOrUndefined(user)) {
		return <></>;
	}

	const displayTypes = response.data;

	return (
		<div className="account-details-user-type-container">
			<AccountDetailsTabHeader
				headerText="Pricing"
				buttonText={mode === EntityFormMode.VIEW && isManager ? 'Edit' : ''}
				onClick={() => setMode(EntityFormMode.EDIT)}
			/>
			{mode === EntityFormMode.VIEW
				? (
					<ReadOnlyField
						fieldName="Pricing"
						fieldValue={isNotNullOrUndefined(user?.userDisplayNameId)
							? `Personalised - ${user?.userDisplayName?.displayName}`
							: 'Standard'}
					/>
				)
				: (
					<Toggle
						className="account-details-pricing-toggle"
						id="account-details-pricing-toggle"
						model={userModel}
						modelProperty="isSpecialUser"
						labelVisible
						labelOn="Personalised pricing"
						labelOff="Standard pricing"
						disabled={!isManager}
						onChecked={(event, checked) => {
							runInAction(() => {
								setCheckboxState(checked);
							});
							if (!checked && isNotNullOrUndefined(user)) {
								runInAction(() => {
									clearPricingFromUser(user);
									setUserDisplayName(null);
								});
							}
						}}
					/>
				)}
			<If condition={userModel.isSpecialUser}>
				<If condition={mode !== EntityFormMode.VIEW}>
					<Combobox
						className="user-type__combobox"
						model={user}
						modelProperty="userDisplayNameId"
						label="User type"
						placeholder="Select user type"
						isDisabled={!isManager || mode === EntityFormMode.VIEW}
						options={displayTypes?.map(x => {
							return { display: x.displayName, value: x.id };
						}) ?? []}
						searchable
						inputProps={{
							upward: false,
						}}
						onAfterChange={(event, option) => {
							const value = displayTypes?.find(x => x.id === option.value);
							if (isNotNullOrUndefined(user) && isNotNullOrUndefined(value)) {
								runInAction(() => {
									setUserDisplayName(value);
									user.userDisplayNameId = value?.id;
									user.userDisplayName = value;
									// eslint-disable-next-line max-len
									if (isNotNullOrUndefined(value.defaultPricing) && (!user.prices.some(x => isNotNullOrUndefined(x.amount)
										|| !user.addOnsPrices.some(y => isNotNullOrUndefined(y.price))))) {
										assignDefaultPricing(user, value.defaultPricing);
									}
								});
							}
						}}
					/>
				</If>
				<If condition={isNotNullOrUndefined(user.userDisplayNameId)}>
					{mode === EntityFormMode.VIEW
						? (
							<>
								<If condition={isNotNullOrUndefined(user.address)}>
									<ReadOnlyField
										fieldName="Address"
										fieldValue={user.address}
									/>
								</If>
								<br />
							</>
						)
						: (
							<>
								<p className="user-type__description">{userDisplayName?.description}</p>
								<p className="address-field__label">Address</p>
								<div className="address-field__input input-group">
									<Button
										className="location-map"
										icon={{ icon: 'location-map', iconPos: 'icon-right' }}
									/>
									<TextField
										className="address-field"
										model={user}
										modelProperty="address"
										label="Address"
										labelVisible={false}
										isReadOnly={!isManager}
									/>
								</div>
							</>
						)}
					<CollapseExpand
						contentName="Passenger"
						content={(
							<PassengerTable
								model={user}
								isReadOnly={!isManager || mode === EntityFormMode.VIEW}
								accountsPage
								setNeedConfirm={setNeedConfirm}
								errors={errors}
							/>
						)}
						open={passengerPricingOpen}
						setOpen={(value: boolean) => setPassengerPricingOpen(value)}
					/>
					<CollapseExpand
						contentName={vehicleTrailerTitle()}
						content={(
							<MeasurementsTable
								model={user}
								isReadOnly={!isManager || mode === EntityFormMode.VIEW}
								accountsPage
								setNeedConfirm={setNeedConfirm}
								errors={errors}
							/>
						)}
						open={measurementPricingOpen}
						setOpen={(value: boolean) => setMeasurementPricingOpen(value)}
					/>
					<CollapseExpand
						contentName="Additional options"
						content={(
							<AdditionalOptionsTable
								model={user}
								isReadOnly={!isManager || mode === EntityFormMode.VIEW}
								accountsPage
								setNeedConfirm={setNeedConfirm}
								errors={errors}
							/>
						)}
						open={addOnsPricingOpen}
						setOpen={(value: boolean) => setAddOnsPricingOpen(value)}
					/>
				</If>
			</If>
		</div>
	);
}

export default FixedPricingFields;
