import gql from 'graphql-tag';
import { FerryTripEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { measurementType } from 'Models/Enums';

/**
 * Returns true if the transaction is associated with bookings that only have one alteration with RESERVED status.
 */
export interface measurementPrice {
	measurementId: string,
	measurementType: measurementType,
	measurementSpace: number,
	amount: number,
}

export async function getFerryTripAlterationssCount(ferryTripId?: string): Promise<number | null> {
	if (isNullOrUndefined(ferryTripId)) {
		return null;
	}

	const response = await store.apolloClient.query<{ ferryTripEntity: FerryTripEntity }, any>({
		query: gql`
			query getFerryTripAlterationssCount($ferryTripId: ID) {
				# ${new Date()}
				ferryTripEntity(id: $ferryTripId) {
					alterationssCount
				}
			}
		`,
		fetchPolicy: 'network-only',
		variables: { ferryTripId },
	});

	const { alterationssCount } = response?.data?.ferryTripEntity;

	if (response.error || response.errors || isNullOrUndefined(alterationssCount)) {
		console.error('Something went wrong while checking if transaction only has new bookings.');
		return null;
	}

	return alterationssCount;
}
