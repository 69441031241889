import * as React from 'react';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';
import { store } from 'Models/Store';
import { showModal } from './Base/BaseModalContents';
import { formatShortTime, formatShortDate } from 'Util/_HumanWritten/TimeUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';

export interface CheckInPastTripModalContentsProps extends Pick<DualActionModalContentProps, 'onConfirm'> {
	departureDateTime?: Date;
	checkedIn?: boolean;
	autoClose?: boolean;
}

export function CheckInPastTripModalContents({
	onConfirm,
	departureDateTime,
	checkedIn,
	autoClose = true,
}: CheckInPastTripModalContentsProps) {
	const body = isNotNullOrUndefined(departureDateTime) && isNotNullOrUndefined(checkedIn)
		? `This booking was for ${formatShortTime(departureDateTime)} ${formatShortDate(departureDateTime)} and has
		 ${checkedIn ? '' : 'not '}been checked in. Please confirm check-in?`
		: 'This booking belongs to an earlier trip. Please confirm check-in?';

	return (
		<DualActionModalContent
			title="Past booking"
			body={body}
			onConfirm={onConfirm}
			onCancel={() => store.modal.hide()}
			fixedCancelWidth
			confirmContent="Move to current"
			autoClose={autoClose}
		/>
	);
}

export function showCheckInPastTripModal(props: CheckInPastTripModalContentsProps) {
	showModal({
		key: 'boarding-past-booking',
		content: <CheckInPastTripModalContents {...props} />,
	});
}
