import {
	getEventBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	SelectedTicketsDto,
	EventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { stringNotEmpty } from 'Util/TypeGuards';
import axios from 'axios';
import { EVENT_BOOKING_URL, FERRY_BOOKING_URL } from 'Constants';
import { History } from 'history';
import useAsync from 'Hooks/useAsync';
import { BookingEntity, FeeEntity, GiftCertificateUsageEntity } from 'Models/Entities';
import { buildUrl } from 'Util/FetchUtils';
import { feeType, locationType } from '../../../../Models/Enums';
import { BookingCreationAdditionalOption } from './FerryTripBookingService';

export interface EventBookingCreationDto {
	bookingId?: string,
	userId: string,
	eventId?: string,
	primaryFirstName?: string,
	primaryLastName?: string,
	primaryPhone?: string,
	primaryEmail?: string,
	selectedTickets?: SelectedTicketsDto[],
	existingTransactionId?: string,
	location: locationType;
}

export interface EventBookingPricesDto {
	transactionId: string;
	subtotalPrice: number;
	gstPrice: number;
	surchargePrice: number;
	giftCertificateUsages: GiftCertificateUsageEntity[];
	totalPrice: number;
	serviceFees: FeeEntity[];
}

export function dataToEventBookingDto(data: EventBookingWizardData): EventBookingCreationDto {
	return {
		bookingId: stringNotEmpty(data.bookingToEditId) ? data.bookingToEditId : undefined,
		eventId: data.eventId,
		userId: data.userId ?? '',
		firstName: data.firstName,
		lastName: data.lastName,
		phone: data.phone,
		email: data.email,
		selectedTickets: data.selectedTickets,
		existingTransactionId: getEventBookingTransactionIdFromStorage() ?? null,
		location: 'BOOKING_WIZARD',
	} as EventBookingCreationDto;
}

// Create a new booking end-point
export async function createEventBooking(bookingInformation: EventBookingCreationDto) {
	return axios.post(
		`${EVENT_BOOKING_URL}/new-booking`,
		bookingInformation,
		{
			validateStatus: () => true,
		},
	);
}

export async function GetPrices(transactionId: string): Promise<EventBookingPricesDto> {
	const response = await axios.get(
		buildUrl(`${EVENT_BOOKING_URL}/calculate-price`, { transactionId }),
	);
	return response.data as EventBookingPricesDto;
}

/**
 * Returns true if user can navigate straight to the cart. Otherwise, return false.
 *
 * At the moment this is only valid when a user has an existing reserved booking in local storage.
 */
export function canNavigateToEventsCartStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getEventBookingTransactionIdFromStorage();

	return existingTransaction !== null && (
		pathname === '/event-booking-wizard/cart' || pathname === '/event-booking-wizard/t-and-cs'
	);
}

/**
 * Returns true when user can navigate to the payment step. Otherwise, return false.
 *
 * This will allow a user to have an incomplete booking in the wizard and navigate to the payment step if there is at
 * least one booking in the cart.
 */
export function canNavigateToEventsPaymentStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getEventBookingTransactionIdFromStorage();

	return existingTransaction !== null && pathname === '/event-booking-wizard/payment';
}

/**
 * Returns true when user can navigate to the post payment step. Otherwise, return false.
 *
 * This will allow a user to have an incomplete booking in the wizard and navigate to the post payment step if there is
 * at least one booking in the cart.
 */
export function canNavigateToEventsPostPaymentStep(history: History): boolean {
	const { pathname } = history.location;
	const existingTransaction = getEventBookingTransactionIdFromStorage();

	return existingTransaction !== null && pathname === '/event-booking-wizard/post-payment';
}

export function FetchEventBookingsForCartAsync(
	transactionId: string | null,
	userId?: string | null,
) {
	return useAsync(() => FetchEventBookingsForCart(transactionId), []);
}

/**
 * Fetches event bookings to be displayed on the cart tab of the booking wizard.
 *
 * @param transactionId
 */
export async function FetchEventBookingsForCart(
	transactionId: string | null,
): Promise<BookingEntity | null> {
	if (transactionId === null || transactionId === '') {
		return null;
	}
	try {
		const result = await axios.get(
			buildUrl(`${EVENT_BOOKING_URL}/fetch-bookings-for-cart`, { transactionId }),
		);
		return new BookingEntity(result.data);
	} catch (e) {
		console.error(e);
		return null;
	}
}

export async function FetchEventBookingForBookingDetailedView(bookingId: string, userId: string | null): Promise<BookingEntity> {
	const results = await axios.get(
		buildUrl(`${FERRY_BOOKING_URL}/fetch-booked-event-booking-by-id`, {
			bookingId,
			userId: userId ?? '',
		}),
	);
	return new BookingEntity(results.data[0]);
}

// Remove a towOn removal fee from alteration
export async function removeFee(transactionId: string, feeToRemove: feeType) {
	return axios.post(
		`${EVENT_BOOKING_URL}/remove-fee/${transactionId}?feeType=${feeToRemove}`,
	).then(({ data }) => data);
}

// Adds a fee to a transaction
export async function addFeeToTransaction(transactionId: string, feeOptionId: string) {
	return axios.post(
		`${EVENT_BOOKING_URL}/add-fee-to-transaction/${transactionId}?feeOptionId=${feeOptionId}`,
	).then(({ data }) => data);
}

export async function removeFeeFromTransaction(transactionId: string, feeOptionId: string) {
	return axios.post(
		`${EVENT_BOOKING_URL}/remove-fee-from-transaction/${transactionId}?feeId=${feeOptionId}`,
	).then(({ data }) => data);
}
