import * as React from 'react';
import { EVENT_IMAGE } from 'Constants';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { isNullOrUndefined } from 'Util/TypeGuards';
import alertToast from 'Util/ToastifyUtils';
import { EventDetailEntity } from 'Models/Entities';
import { GetFormattedDateTime } from './EventUtils';
import { useHistory } from 'react-router';
import If from '../../../If/If';
import { Link } from 'react-router-dom';
import { store } from '../../../../../Models/Store';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../../Button/Button';

export interface EventCardProps {
	event: EventDetailEntity;
	index: number;
	eventToEdit: React.MutableRefObject<EventDetailEntity | null>;
}

function htmlToPlainText(html: string): string {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	return doc.body.textContent || '';
}

export function EventCard(props: EventCardProps) {
	const {
		event,
		index,
		eventToEdit,
	} = props;
	return (
		<div className="event-card__container">
			<EventCardDetails
				event={event}
				index={index}
				eventToEdit={eventToEdit}
			/>
		</div>
	);
}

function EventCardDetails({ event, index, eventToEdit }: EventCardProps) {
	const { isMobile, isIpad } = useDeviceDetect();
	const history = useHistory();

	if (isNullOrUndefined(event) || isNullOrUndefined(event.ferryTrip)) {
		alertToast(
			'Event is missing details',
			'error',
			undefined,
			{ autoClose: 3000 },
		);
		return <></>;
	}

	return (
		<div className="event-details__container">
			<Link
				to={`/event-details/${event.id}`}
				className="event-details__link hide-underline"
				id={event.id}
				tabIndex={index}
			>
				<div className="event-details__image">
					<img className="event-detail__header-img" src={EVENT_IMAGE + event.headerImage?.id} alt="header-img" />
					<p className="event-details__label">
						<img
							className="event-icon"
							src={`/api/files/${event.eventLabel?.iconId}`}
							alt="event-icon"
							style={{
								filter: 'invert(1) brightness(1000%)',
								WebkitFilter: 'invert(1) brightness(1000%)',
							}}
						/>
						{`${event.eventLabel?.label} ${event.hidden ? '(private)' : ''}`}
					</p>
					<p className="event-capacity__label">
						<div className="issue-icon white-icon" />
						<If condition={(event?.remainingCapacity ?? 0) === 0 && !event.closed}>
							Sold out
						</If>
						<If condition={(event?.remainingCapacity ?? 0) > 0 && !event.closed}>
							Tickets left: {event?.remainingCapacity ?? 0}/{event?.totalCapacity ?? 0}
						</If>
						<If condition={event.closed}>
							Closed
						</If>
					</p>
				</div>
				<div className="event-details__body" key={event.id}>
					<div className="event-details__header">
						<h5 className="event-details__name">{event.name}</h5>
					</div>
					<p className="event-details__date-time">{GetFormattedDateTime(
						event.ferryTrip.startDate,
						event.ferryTrip.endDate,
						event.ferryTrip.startTime,
						event.ferryTrip.endTime)}
					</p>
					{/* eslint-disable-next-line react/no-danger */}
					<p className="event-details__description">{htmlToPlainText(event.description)}</p>
					<p className="event-details__see-more">See more</p>
				</div>
			</Link>
			<If condition={store.isManager && !isIpad}>
				<Button
					className="event-details__edit-event"
					sizes={Sizes.Medium}
					colors={Colors.Secondary}
					display={Display.Text}
					icon={{ icon: 'edit-box', iconPos: 'icon-bottom' }}
					onClick={e => {
						e.stopPropagation();
						eventToEdit.current = event;
						history.push(`/upcoming-events/edit/${event.id}`);
					}}
				/>
			</If>
		</div>
	);
}
