import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { store } from 'Models/Store';

export const useAutoFocus = (inputId: string) => {
	const { state } = useLocation<{ autoFocus: boolean }>();

	useEffect(() => {
		if (state?.autoFocus) {
			const input = document.getElementById(inputId);
			(input as HTMLInputElement)?.focus();
		}

		// Reset state so that it doesn't refocus
		store.routerHistory.location.state = undefined;
	}, []);
};
