import { getBaseSummaryAttributes } from 'Models/Entities/BookingEntity';
import { getFerryTripExpandString } from 'Util/_HumanWritten/FerryTrips/FetchTripsUtils';
import { getDynamicPricingAttributes } from 'Util/_HumanWritten/Bookings/FetchBookingsUtils';
import { stringIsEmpty } from 'Util/TypeGuards';
import {
	CargoEntity,
	TowOnEntity,
	FerryTripEntity,
	RouteEntity,
	LocationEntity,
	CargoTypeEntity,
} from 'Models/Entities';

export function getBookedSummaryAttributes(includeReferences = false, userId: string | null = null) {
	let expands = getBaseSummaryAttributes();

	if (includeReferences) {
		expands += `
			${getAdditionalBookingOptionsAttributes(userId)}
			${getVehicleInfoAttributes()}
			${getTrailerInfoAttributes()}
			${getFerryTripAttributes(userId)}
		`;
	}

	return expands;
}

export function getBookingSummariesAttributes(userId: string | null = null) {
	return `
		recentSummary {
			${getBaseSummaryAttributes()}
			ferryTrip {
				${getFerryTripExpandString(null, userId)}
			}
			${getVehicleInfoAttributes()}
			${getTrailerInfoAttributes()}
			${getAdditionalBookingOptionsAttributes(userId)}
		}
		previousSummary {
			${getBaseSummaryAttributes()}
			ferryTrip {
				${getFerryTripExpandString(null, userId)}
			}
			${getVehicleInfoAttributes()}
			${getTrailerInfoAttributes()}
			${getAdditionalBookingOptionsAttributes(userId)}
		}
	`;
}

export function getAdditionalBookingOptionsAttributes(userId: string | null = null) {
	const userIdToUse = stringIsEmpty(userId) ? null : `"${userId}"`;
	return `
		additionalBookingOptions {
			quantity
			option {
				id
				name
				information
				staticPrice (userId: ${userIdToUse})
				removable
				excludeGST
				canExportReport
				removalTitle
				removalInformation
				isSingular
				disabled
				isvehicleonly
			}
		}
	`;
}

export function getVehicleInfoAttributes() {
	return `
		cargoInfo {
			${CargoEntity.getAttributes().join('\n')}
			selectedLength {
				id
				value
				label
			}
			selectedWeight {
				id
				value
				label
			}
			cargoType {
				${CargoTypeEntity.getAttributes().join('\n')}
			}
		}
	`;
}

export function getTrailerInfoAttributes() {
	return `
		towOnInfo {
			${TowOnEntity.getAttributes().join('\n')}
			towOnType {
				id
				label		
			}
			selectedLength {
				id
				value
				label
			}
		}
	`;
}

export function getFerryTripAttributes(userId: string | null = null) {
	return `
		ferryTrip {
			${FerryTripEntity.getAttributes().join('\n')}
			departureDateTime
			route {
				${RouteEntity.getAttributes().join('\n')}
				departure {
					${LocationEntity.getAttributes().join('\n')}
				}
				destination {
					${LocationEntity.getAttributes().join('\n')}
				}
			}
			prices {
				measurementId
				ferryTripId
				amount
			}
			${getDynamicPricingAttributes(userId)}
		}
	`;
}
