import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import * as React from 'react';

import useBookingFormOptions, { BookingFormEditOptions } from 'Hooks/useBookingFormOptions';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import useCheckInStore from 'Hooks/useCheckInStore';
import { IBookingFormGroupHeaders, useGroupHeaders } from 'Hooks/useGroupHeaders';
import { store } from 'Models/Store';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { bookingCanFit } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import { route } from 'Util/_HumanWritten/RouterUtils';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import { checkIfInvalidName } from 'Util/StringUtils';
import alertToast from 'Util/ToastifyUtils';
import { isNotNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { deduceWarning, showOverbookingModal } from 'Views/Components/_HumanWritten/Modal/OverbookingAllowanceModal';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import If from 'Views/Components/If/If';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { CheckInBookingOverviewDto } from '../../CheckInEntities/CheckInBookingOverviewDto';
import { DisableContinueState } from '../../CheckInUtils';
import { BookingFormState, IBookingFormState } from '../../context/BookingFormState';
import { BookingFormMode } from '../BookingForm';
import BookingFormGroupHeader from '../BookingFormGroupHeader';
import BookingFormEditAddOns from './BookingFormEditAddOns';
import BookingFormEditPassengers from './BookingFormEditPassengers';
import BookingFormEditTrailer from './BookingFormEditTrailer';
import BookingFormEditVehicle from './BookingFormEditVehicle';
import { AdditionalOption } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	BOOKING_FORM_ID,
	bookingFormHasChanged,
	groupHeaderHasChanged,
	passengerCount,
	transformBookingToBookingFormState,
	vehicleTrailerLength,
} from '../BookingFormUtils';
import {
	showCheckInDiscardChangesModal,
} from 'Views/Components/_HumanWritten/Modal/CheckInDiscardChangesModalContents';

export type BookingFormModeMutation = BookingFormMode.Add | BookingFormMode.Edit;

/**
 * Shared prop interface for all BookingFormEditSections
 */
export interface BookingFormEditSectionProps {
	mode: BookingFormModeMutation;
	groupHeaders: IBookingFormGroupHeaders;
	errors: { [key: string]: string | undefined };
	options: BookingFormEditOptions;
	setOptions: React.Dispatch<React.SetStateAction<BookingFormEditOptions>>;
	resetContinue: (errorKey?: string | string[]) => void;
	oneInputPerRow?: boolean;
}

export interface BookingFormEditProps {
	booking: CheckInBookingOverviewDto;
	mode: BookingFormModeMutation;
	className?: string;
	/**
	 * True if changes to the booking is to be applied to the return trip as well. False otherwise.
	 */
	applyToReturn?: boolean;
	disableState?: DisableContinueState;
	/**
	 * When true, all inputs will occupy one row. Otherwise some will be next to each other e.g.
	 * first and last name.
	 */
	oneInputPerRow?: boolean;
}

function BookingFormEdit({
	booking,
	mode,
	className,
	disableState,
	applyToReturn = false,
	oneInputPerRow = false,
}: BookingFormEditProps) {
	const isEditMode = mode === BookingFormMode.Edit;
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();
	const errors = useLocalStore<{ [key: string]: string | undefined }>(() => ({}));

	React.useEffect(() => {
		checkInStore.setFormState(booking);
	}, []);

	const initialState = React.useMemo<IBookingFormState>(
		() => {
			const initial = new BookingFormState(transformBookingToBookingFormState(booking));

			if (mode === BookingFormMode.Add) {
				initial.reset();
			}

			return initial;
		},
		[booking],
	);
	const { formState } = checkInStore;

	const [initialGroupHeaders, groupHeaders] = useGroupHeaders(booking);
	const isVehicleBooking = groupHeaders.vehicles.show;

	const { options, setOptions, optionsResponse } = useBookingFormOptions({
		booking: booking,
		mode: mode,
	});

	/**
	 * This useEffect should only run twice.
	 * The first is when optionsResponse has been initialised.
	 * The second is when optionsResponse data has been updated.
	 */
	React.useEffect(() => {
		if (!optionsResponse.data) {
			return;
		}
		const { cargoTypes, addOnSections, towOnTypes } = optionsResponse.data;

		// These add-on options already exists in the form state
		const existingDepartingOptionIds = formState.departingTripOptions.map(x => x.optionId);
		const existingReturningOptionIds = formState.returningTripOptions?.map(x => x.optionId) ?? [];

		/**
		 * List of additional booking option IDs to be added to booking form state.
		 *
		 * We need to do this so that we can track each add-on independently, even the ones that were not initially
		 * part of the booking.
		 */
		const departingAddOns = addOnSections.reduce<string[]>((prev, section) => {
			return section.additionalBookingOptions
				.map(x => x.id)
				.filter(id => !existingDepartingOptionIds.includes(id))
				.concat(prev);
		}, []);

		const returningAddOns = addOnSections.reduce<string[]>((prev, section) => {
			return section.additionalBookingOptions
				.filter(abo => abo?.additionalBookingSection?.routess
					.some(r => r.routes?.id === booking.returnBooking?.bookedSummary?.ferryTrip?.routeId))
				.map(x => x.id)
				.filter(id => !existingReturningOptionIds.includes(id))
				.concat(prev);
		}, []);

		runInAction(() => {
			// Update both state and initial state as this is still considered to be the start of the process
			// We also construct a new array to trigger a re-render
			formState.departingTripOptions = [
				...formState.departingTripOptions,
				...departingAddOns.map(x => ({ amount: 0, optionId: x } as AdditionalOption)),
			];
			initialState.departingTripOptions = [
				// Important we create new instance of list and of each element, so we do not reference addOns of
				// state and initial state.
				...formState.departingTripOptions.map(x => ({ amount: x.amount, optionId: x.optionId } as AdditionalOption)),
			];
			if (applyToReturn) {
				formState.returningTripOptions = [
					...formState.returningTripOptions ?? [],
					...returningAddOns.map(x => ({ amount: 0, optionId: x } as AdditionalOption)),
				];
				initialState.returningTripOptions = [
					// Important we create new instance of list and of each element, so we do not reference addOns of
					// state and initial state.
					...formState.returningTripOptions.map(x => ({ amount: x.amount, optionId: x.optionId } as AdditionalOption)),
				];
			}
		});

		const uniqueVehicleMakes = Array.from(
			new Set(cargoTypes.map(x => x.cargoMake)).values(),
		);

		setOptions(currentOptions => {
			return {
				...currentOptions,
				allCargoTypes: cargoTypes,
				cargoMakeOptions: uniqueVehicleMakes.map(x => ({ value: x, display: x })),
				cargoModelOptions: cargoTypes
					.filter(x => x.cargoMake === (formState.cargoMake ?? initialState.cargoMake))
					.map(x => ({ display: x.cargoModel, value: x.cargoModel })),
				addOnSections: [...(addOnSections || [])]?.sort(compareFnByNumberAsc(x => x?.order)),
				allTowOnTypes: towOnTypes.map(x => ({ value: x.id, display: x.label })),
			};
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsResponse.data]);

	React.useEffect(() => {
		//
		// The purpose of this useEffect is to notify the staff that the ferry trip they are boarding has departed
		// (based on the trip's scheduled departure time).
		//
		// When applicable, a popup will appear when the user tries to access the QR scanner. When confirmed, the popup
		// will not re-appear for the lifetime of the component (until they leave the check-in page).
		//
		const unblock = store.routerHistory.block((nextLocation, action) => {
			const { pathname } = nextLocation;

			if (pathname === routes.base) {
				if (bookingFormHasChanged(initialState, formState)) {
					showCheckInDiscardChangesModal({
						onConfirm: () => {
							unblock();
							checkInStore.setFormState();
							route(nextLocation, action);
						},
					});

					// Block route change, discard modal will handle it
					return false;
				}

				unblock();
				route(nextLocation, action);
			}

			return undefined;
		});

		// Subscribe to blocker
		store.routerHistory.listen(unblock);

		// Clean up
		return () => {
			unblock();
		};

		// Restart blocker everytime location changes (because unblock is called every time user changes the page)
	}, [store.routerHistory.location, formState]);

	if (optionsResponse.type === 'loading') {
		return <LottieSpinner />;
	}

	/**
	 * It counts the current total number of passengers, and checks if the new passenger count will fit in the
	 * booking's ferry trip.
	 *
	 * If new passenger count will NOT fit in the ferry trip, we will show the `overbooking pop-up`.
	 *
	 * We do the same for the return booking, if `applyToReturn` is true.
	 */
	const onSubmitEdit = async () => {
		const { vehicles, trailers, addOns } = groupHeaders;
		const newLength = vehicleTrailerLength(formState);
		const newPassengerCount = passengerCount(formState);

		const checkCurrent = bookingCanFit(booking.id, newLength, newPassengerCount);

		const checks = applyToReturn && booking.returnBooking?.id
			? [checkCurrent, bookingCanFit(booking.returnBooking.id, newLength, newPassengerCount)]
			: [checkCurrent];

		const results = await Promise.all(checks);

		let canFitCurrent = true;
		let canFitReturn = true;

		const [currentTrip, returnTrip] = results;
		canFitCurrent = !currentTrip?.isOverbooking ?? false;
		if (applyToReturn) {
			canFitReturn = !returnTrip?.isOverbooking ?? false;
		}

		if (canFitCurrent && canFitReturn) {
			await checkInStore.reviewBooking(
				mode,
				formState,
				vehicles.show,
				trailers.show,
				addOns.show,
				applyToReturn,
			);
		} else {
			// Show overbooking pop up
			const warningMode = deduceWarning(canFitCurrent, canFitReturn);
			showOverbookingModal({
				mode: warningMode,
				currentTrip: currentTrip ?? undefined,
				returnTrip: returnTrip ?? undefined,
				onConfirm: async () => {
					await checkInStore.reviewBooking(
						mode,
						formState,
						vehicles.show,
						trailers.show,
						addOns.show,
						applyToReturn,
					);
				},
			});
		}
	};

	const resetContinue = (errorKey?: string | string[]) => {
		if (errorKey) {
			runInAction(() => {
				if (typeof errorKey === 'string') {
					delete errors[errorKey];
				} else {
					for (const key of errorKey) {
						delete errors[key];
					}
				}
			});
		}
		if (disableState?.continue) {
			runInAction(() => {
				disableState.continue = false;
			});
		}
	};

	/**
	 * Returns true if form is valid. False otherwise.
	 */
	const validate = () => {
		// Order of if statements are important
		let isValid = true;

		if (isVehicleBooking) {
			const requiredFields = [
				'driverPhone',
				'driverFirstName',
				'driverLastName',
				'cargoIdentification',
				'cargoMake',
				'cargoModel',
				'vehicleLengthId',
				'vehicleWeightId',
			];
			for (const field of requiredFields) {
				if (stringIsEmpty(formState[field])) {
					runInAction(() => {
						errors[field] = 'Required field';
					});
					isValid = false;
				}
			}

			if (formState.driverPhone && !IsValidPhone(formState.driverPhone)) {
				runInAction(() => {
					errors.driverPhone = 'Enter a valid phone number.';
				});
				isValid = false;
			}

			if (formState.driverFirstName && checkIfInvalidName(formState.driverFirstName)) {
				runInAction(() => {
					errors.driverFirstName = 'No spaces. A-Z and \'-\' only.';
				});
				isValid = false;
			}

			if (formState.driverLastName && checkIfInvalidName(formState.driverLastName)) {
				runInAction(() => {
					errors.driverLastName = 'No spaces. A-Z and \'-\' only.';
				});
				isValid = false;
			}
		}

		if (isValid && !(
			bookingFormHasChanged(initialState, formState)
			|| groupHeaderHasChanged(initialGroupHeaders, groupHeaders)
		)) {
			alertToast('No changes made', 'error', undefined, { autoClose: 1500 });
			isValid = false;
		}

		return isValid;
	};

	/**
	 * `onSubmit` is triggered by a button parent element, referenced by having button form prop set to
	 * BOOKING_FORM_ID.
	 */
	const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!validate()) {
			if (disableState !== undefined) {
				runInAction(() => {
					disableState.continue = true;
				});
			}
			ScrollToError();
			return;
		}

		if (mode === BookingFormMode.Edit) {
			onSubmitEdit();
		} else {
			// Add booking
			const { vehicles, trailers, addOns } = groupHeaders;
			await checkInStore.reviewBooking(mode, formState, vehicles.show, trailers.show, addOns.show);
		}
	};

	const showAddOns = () => {
		const result = options.addOnSections.some(addOnSection => {
			const addOns = addOnSection.additionalBookingOptions
				.filter(x => {
					if (x.name === 'Vehicle access permit' || (x.isvehicleonly && !groupHeaders.vehicles.show)) {
						// Hide 'Vehicle access permit' from check-in flow
						// Hide vehicle only add-ons when vehicle group is unchecked
						return false;
					}
					return true;
				})
				.sort(compareFnByNumberAsc(x => x?.order));
			return addOns.length > 0;
		});
		if (result) {
			// Open add ons section when there is at least one amount selected
			runInAction(() => {
				groupHeaders.addOns.show = formState.departingTripOptions.some(x => x.amount > 0);
			});
		}
		return result;
	};

	return (
		<>
			<form
				id={BOOKING_FORM_ID}
				className={classNames('booking-form booking-form--edit', className)}
				onSubmit={onSubmit}
			>
				<If condition={isEditMode}>
					<div className="booking-form__title">
						<div className="text">
							Edit booking
						</div>
					</div>
				</If>
				<TextField
					className={classNames({ 'column-1': !oneInputPerRow })}
					model={
						isVehicleBooking
							? formState
							: formState.user
					}
					modelProperty={
						isVehicleBooking
							? 'driverFirstName'
							: 'firstName'
					}
					label="First name"
					name="driverFirstName"
					placeholder="Enter first name"
					onAfterChange={() => resetContinue('driverFirstName')}
					isDisabled={!isVehicleBooking}
					errors={
						isVehicleBooking
							? errors.driverFirstName
							: undefined
					}
				/>
				<TextField
					className={classNames({ 'column-2': !oneInputPerRow })}
					model={
						isVehicleBooking
							? formState
							: formState.user
					}
					modelProperty={
						isVehicleBooking
							? 'driverLastName'
							: 'lastName'
					}
					label="Last name"
					name="driverLastName"
					placeholder="Enter last name"
					onAfterChange={() => resetContinue('driverLastName')}
					isDisabled={!isVehicleBooking}
					errors={
						isVehicleBooking
							? errors.driverLastName
							: undefined
					}
				/>
				<TextField
					className={classNames({ 'column-1': !oneInputPerRow })}
					model={
						isVehicleBooking
							? formState
							: formState.user
					}
					modelProperty={
						isVehicleBooking
							? 'driverPhone'
							: 'phone'
					}
					label="Phone"
					name="driverPhone"
					placeholder="Enter mobile number"
					onAfterChange={() => resetContinue('driverPhone')}
					isDisabled={!isVehicleBooking}
					errors={
						isVehicleBooking
							? errors.driverPhone
							: undefined
					}
				/>
				<TextField
					className={classNames({ 'column-2': !oneInputPerRow })}
					model={formState}
					modelProperty="email"
					label="Email"
					name="email"
					placeholder="Enter email"
					isDisabled
				/>
				<Combobox
					model={formState}
					modelProperty="departureTicketId"
					label="Departing trip"
					placeholder="Select trip"
					options={options.departingTrips}
					searchable
					isDisabled
				/>
				<If condition={isNotNullOrUndefined(booking.returnBooking)}>
					<Combobox
						model={formState}
						modelProperty="returningTicketId"
						label="Return trip"
						placeholder="Select trip"
						options={options.returningTrips}
						searchable
						isDisabled={isEditMode}
					/>
				</If>

				<If condition={whiteLabelStore.bookingNoteEnabled}>
					<TextField
						model={formState}
						modelProperty="note"
						label={isVehicleBooking
							? whiteLabelStore.cargoBookingNoteLabel
							: whiteLabelStore.passengerBookingNoteLabel}
						placeholder={isVehicleBooking
							? whiteLabelStore.cargoBookingNotePlaceholder
							: whiteLabelStore.passengerBookingNotePlaceholder}
						name="additional-details"
					/>
				</If>

				<BookingFormGroupHeader
					title="Passengers"
					config={{
						model: groupHeaders.passengers,
						modelProperty: 'show',
					}}
				>
					<div>
						<BookingFormEditPassengers resetContinue={resetContinue} />
					</div>
				</BookingFormGroupHeader>

				<BookingFormEditVehicle
					mode={mode}
					groupHeaders={groupHeaders}
					errors={errors}
					options={options}
					setOptions={setOptions}
					resetContinue={resetContinue}
					oneInputPerRow={oneInputPerRow}
				/>

				<BookingFormEditTrailer
					mode={mode}
					groupHeaders={groupHeaders}
					errors={errors}
					options={options}
					setOptions={setOptions}
					resetContinue={resetContinue}
					oneInputPerRow={oneInputPerRow}
				/>

				<If condition={showAddOns()}>
					<BookingFormEditAddOns
						groupHeaders={groupHeaders}
						options={options}
						resetContinue={resetContinue}
						// Unused props below
						errors={errors}
						mode={mode}
						setOptions={setOptions}
					/>
				</If>
			</form>
		</>
	);
}

export default observer(BookingFormEdit);
