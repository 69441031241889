export function ScrollToElement(scrollToElement: NodeListOf<Element>, scrollTo: ScrollLogicalPosition = 'start') {
	// We need to do this so that it executes after the the page has finished loading
	// otherwise it will execute before the page has loaded and not work as intended.
	setTimeout(() => {
		if (scrollToElement.length !== 0) {
			scrollToElement[0].scrollIntoView({
				behavior: 'smooth',
				block: scrollTo,
				inline: 'nearest',
			});
		}
	}, 0);
}

export function ScrollToItem(className: string, scrollTo: ScrollLogicalPosition = 'start') {
	// We need to do this so that it executes after the setErrors is completed
	// otherwise it will execute before the errors are set and not work as intended.
	setTimeout(() => {
		const myElement = document.querySelectorAll(className);
		ScrollToElement(myElement, scrollTo);
	}, 0);
}
