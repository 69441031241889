import React from 'react';
import If from 'Views/Components/If/If';
import {
	BookingWizardTab,
	SidebarStatus,
	VisibilityStatus,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import {
	FerryBookingTimerWrap,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/Components/FerryBookingTimerWrap';
import {
	FerryProgressBarStyledSection,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/ProgressBar/FerryProgressBarStyledSection';

export interface FerryBookingWizardProgressBarProps {
	tabs: BookingWizardTab[];
	progressBarClass: string;
	onClickTab: (tab: BookingWizardTab) => void,
	currentTabIndex: number;
	isIpad: boolean;
	onTimerExpiry: () => void;
	ticketsPageUrl: string;
	isMobile: boolean;
	disabled: boolean;
	forEventBooking: boolean;
}

export function FerryBookingWizardProgressBar({
	onTimerExpiry,
	progressBarClass,
	onClickTab,
	tabs,
	currentTabIndex,
	isIpad,
	isMobile,
	ticketsPageUrl,
	disabled,
	forEventBooking,
}: FerryBookingWizardProgressBarProps) {
	const completedTabs = tabs
		.slice(0, currentTabIndex + 1)
		.filter(tab => tab.visibility === VisibilityStatus.VISIBLE);
	const incompleteTabs = tabs
		.slice(currentTabIndex + 1)
		.filter(tab => tab.visibility === VisibilityStatus.VISIBLE);
	const sidebarExists: boolean = tabs[currentTabIndex].sidebarStatus !== SidebarStatus.NO_SIDEBAR;

	return (
		<div className="booking-wizard-progress-bar-container">
			<ol className={progressBarClass}>
				<FerryProgressBarStyledSection
					completedSection
					isLastValidStep={completedTabs.length === tabs.length}
					onClickTab={onClickTab}
					sidebarExists={sidebarExists}
					tabs={completedTabs}
					isMobile={isMobile}
					disabled={disabled}
				/>
				<FerryProgressBarStyledSection
					completedSection={false}
					isLastValidStep={false}
					// This styled section is for tabs which we haven't reached yet. As a result, the buttons will be
					// disabled, so we don't need to do anything when the tabs are clicked
					sidebarExists={sidebarExists}
					tabs={incompleteTabs}
					isMobile={isMobile}
					disabled={disabled}
				/>
			</ol>
			<If condition={isIpad}>
				{/* We can default the pageWithSidebar prop to false here because its irrelevant for mobile renders */}
				<FerryBookingTimerWrap
					pageWithSidebar={false}
					onTimerExpiry={onTimerExpiry}
					isIpad={isIpad}
					ticketsPageUrl={ticketsPageUrl}
					forEventBooking={forEventBooking}
				/>
			</If>
		</div>
	);
}
