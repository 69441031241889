import * as React from 'react';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ICbButtonProps } from 'Views/Components/Button/Button';

export interface LinkAsButtonProps
	extends Pick<ICbButtonProps, 'icon' | 'quickTypes' | 'display' | 'colors' | 'sizes' | 'widths' | 'className'> {
	to: LocationDescriptor<any>;
	replace?: boolean;
	children: React.ReactNode;
	onClick?: () => void;
}

export default function LinkAsButton(props: LinkAsButtonProps) {
	const {
		to,
		replace,
		children,
		icon: iconProp,
		quickTypes: quickTypesProp,
		display: displayProp,
		colors: colorsProp,
		sizes: sizesProp,
		widths: widthsProp,
		className: classNameProp,
		onClick,
	} = props;

	const icon = iconProp ? `btn--icon icon-${iconProp.icon} ${iconProp.iconPos}` : '';
	const quickTypes = quickTypesProp ?? '';
	const display = displayProp ?? '';
	const colors = colorsProp ?? '';
	const sizes = sizesProp ?? '';
	const widths = widthsProp ?? '';
	const className = classNameProp ?? '';

	const clazz = ['btn', icon, quickTypes, display, colors, sizes, widths, className];

	return (
		<Link
			to={to ?? ''}
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			className={classNames(clazz)}
			replace={replace}
		>
			{children}
		</Link>
	);
}
