import * as React from 'react';
import sound from 'assets/sounds/sound.wav';

export function PlayCheckInAudio() {
	PlayAudio(sound);
}
export function PlayAudio(soundToPlay: string) {
	const htmlAudio: HTMLAudioElement = new Audio(soundToPlay);
	htmlAudio.play().then(() => {
		// The audio was played, yay
	}).catch(e => {
		console.log('Failed to play the audio. Error:', e);
	});
}
