import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import {
	setLastPaymentAttempt,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Payment/PaymentTabUtils';
import StripePaymentTab from './StripePaymentTab';

export interface PaymentTabWrapProps {
	paymentCompletedUrl: string;
	timeoutExpiryUrl: string;
	nameToPrefill: string;
	onCompletePayment: () => void;
	onPageLoaded: () => void;
	bulkBooking: boolean;
	returnTrip: boolean;
	userId?: string;
	isAlteration: boolean;
	bookingToEditId?: string;
	eventBooking: boolean;
	setTabIsLoading: (loading: boolean) => void;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
	disableContinueButton: boolean;
}

export function PaymentTabWrap({
	paymentCompletedUrl,
	timeoutExpiryUrl,
	nameToPrefill,
	onCompletePayment,
	onPageLoaded,
	bulkBooking,
	returnTrip,
	userId,
	isAlteration,
	bookingToEditId,
	eventBooking,
	setTabIsLoading,
	setDisableContinueButton,
	disableContinueButton,
}: PaymentTabWrapProps) {
	const queryParams = useLocation().search.substring(1);
	const params = queryString.parse(queryParams);
	const { ErrorCode } = params;

	// We may not have a code if we are just navigating to the page normally, so we need to ensure that we are able to
	// render the payment tab without having received an error code
	let code;
	if (typeof ErrorCode === 'string' && ErrorCode !== '') {
		code = ErrorCode;

		// We need to remove the error code from the url because if a user accidentally refreshes the page, they will
		// have the error message shown again.
		//
		// The other ripple effect is that it make our redirect logic to AwaitingPaymentPage not work, because it only
		// works when 'errorCode' is null
		const [pathWithoutSearchParam] = window.location.href.split('?');
		window.history.replaceState({}, 'Payment', pathWithoutSearchParam);
	} else {
		code = null;
	}

	const handleOnCompletePayment = () => {
		// We want to record the date time of the user's last valid attempt of paying
		//
		// Because if they accidentally refresh the page, we would can tell if they have done it accidentally
		// if the time difference is withing a few seconds from future now
		setLastPaymentAttempt();
		onCompletePayment();
	};

	return (
		<StripePaymentTab
			paymentCompletedUrl={paymentCompletedUrl}
			timeoutExpiryUrl={timeoutExpiryUrl}
			nameToPrefill={nameToPrefill}
			errorCode={code}
			onCompletePayment={handleOnCompletePayment}
			onPageLoaded={onPageLoaded}
			bulkBooking={bulkBooking}
			returnTrip={returnTrip}
			userId={userId}
			isAlteration={isAlteration}
			bookingToEditId={bookingToEditId}
			eventBooking={eventBooking}
			setTabIsLoading={setTabIsLoading}
			setDisableContinueButton={setDisableContinueButton}
			disableContinueButton={disableContinueButton}
		/>
	);
}
