import * as React from 'react';
import { useMemo } from 'react';
import { useLottie } from 'lottie-react';
import defaultSpinner from './LottieFiles/default-loading-bar.json';
import customSpinner from './LottieFiles/custom-loading-bar.json';
import { SetLottieColour } from './LottieColourChanger';
import { themeStore } from '../../../../Models/ThemeStore';

export function LottieLoadingBar() {
	const options = useMemo(() => {
		// Set the colour of the spinner depending on the application brand colouring
		const colouredSpinner = SetLottieColour(
			customSpinner,
			themeStore?.config?.brandColourPrimary,
		);

		return {
			loop: true,
			autoplay: true,
			animationData: colouredSpinner ?? defaultSpinner,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		};
	}, []);

	const { View } = useLottie(options);
	return (
		<div className="lottie-loading-bar">
			{View}
		</div>
	);
}
