import * as React from 'react';
import { runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import useAsync from 'Hooks/useAsync';
import { formatShortDate, formatShortTime } from 'Util/_HumanWritten/TimeUtils';
import {
	getTripNewDepartureEmailBody, getTripNewDepartureEmailSubjectName,
} from 'Services/Api/_HumanWritten/EmailTemplateEntityService';
import { FerryTripEntity } from 'Models/Entities';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { SendFerryTripCommunications } from 'Services/Api/_HumanWritten/CommunicationService';
import alertToast from 'Util/ToastifyUtils';

export interface TripNewDepartureEmailPreviewState {
	type: 'loading' | 'error' | 'data';
	header: string;
	body: string;
	sendSms: boolean,
	sendEmail: boolean,
}

export interface TripNewDepartureEmailPreviewErrors {
	header?: string;
	body?: string;
	communicationType?: string,
}

export function useTripNewDepartureEmailPreview(ferryTripId: string, newDeparture: Date) {
	const request = useAsync<string | null>(() => getTripNewDepartureEmailBody(ferryTripId), [ferryTripId]);
	const subjectNameRequest = useAsync<string | null>(() => getTripNewDepartureEmailSubjectName(), []);
	const state = useLocalStore<TripNewDepartureEmailPreviewState>(() => ({
		type: request.type,
		header: `${formatShortDate(newDeparture)} ${formatShortTime(newDeparture, true)}`,
		body: '',
		sendEmail: true,
		sendSms: false,
	}));
	const errors = useLocalStore<TripNewDepartureEmailPreviewErrors>(() => ({}));

	React.useEffect(() => {
		runInAction(() => {
			if (request.type === 'loading' || subjectNameRequest.type === 'loading') {
				state.type = 'loading';
				return;
			}
			if (request.type === 'error' || subjectNameRequest.type === 'error') {
				state.type = 'error';
				return;
			}

			const subjectHeader = subjectNameRequest.data?.trim();

			state.type = 'data';
			state.body = request.data?.replace('$InsertNewTime', formatShortTime(newDeparture)) ?? '';
			state.header = subjectHeader && subjectHeader !== ''
				? subjectHeader
				: 'Your ferry time has changed';
		});
	}, [request.type, request.data, subjectNameRequest.type, subjectNameRequest.data]);

	return { state, errors };
}

export async function saveFerryTripAndSendEmails(
	ferryTrip: FerryTripEntity,
	header: string,
	body: string,
	sendSms: boolean,
	sendEmail: boolean,
) {
	await ferryTrip.save();
	const response = await SendFerryTripCommunications({
		ferryTripId: ferryTrip.id,
		sendEmail: sendEmail,
		sendSms: sendSms,
		bodyContent: body,
		sendToLoggedInUser: false,
		subjectHeader: header,
	});
	if (isNotNullOrUndefined(response.SMS_SUCCESS)) {
		alertToast(response.SMS_SUCCESS, 'success');
	}
	if (isNotNullOrUndefined(response.SMS_ERROR)) {
		alertToast(response.SMS_ERROR, 'error');
	}
	if (isNotNullOrUndefined(response.EMAIL_SUCCESS)) {
		alertToast(response.EMAIL_SUCCESS, 'success');
	}
	if (isNotNullOrUndefined(response.EMAIL_ERROR)) {
		alertToast(response.EMAIL_ERROR, 'error');
	}
}
