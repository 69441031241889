import Modal from 'Views/Components/Modal/Modal';
import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import * as uuid from 'uuid';

export interface BaseSpaceModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void;
	textToDisplay: string[];
	label?: string;
	buttonText?: string;
}

export function BaseSpaceModal(props: BaseSpaceModalProps) {
	const { showModal, label } = props;
	if (!showModal) {
		return <></>;
	}

	return (
		<Modal
			isOpen
			label={label ?? 'You no longer fit on this ferry'}
		>
			<BaseSpaceModalContents {...props} />
		</Modal>
	);
}

export function BaseSpaceModalContents({
	onClickSelect,
	onClickCross,
	textToDisplay,
	label,
	buttonText,
}: BaseSpaceModalProps) {
	return (
		<>
			<div className="space-modal__wrap">
				<div className="space-modal__header">
					<h5 className="space-modal__title">{label}</h5>
					<Button
						className="space-modal__cross-btn"
						icon={{ icon: 'cross', iconPos: 'icon-top' }}
						onClick={onClickCross}
					/>
				</div>
				<div className="space-modal--body">
					{textToDisplay.map(text => {
						return (
							<p className="space-modal__body-text" key={uuid.v4()}>
								{text}
							</p>
						);
					})}
				</div>
			</div>
			<Button className="space-modal__button" onClick={onClickSelect}>
				<div className="space-modal__button-contents">
					<p>{buttonText ?? 'Select a new ticket'}</p>
					<span className="btn--icon icon-arrow-right icon-right" />
				</div>
			</Button>
		</>
	);
}
