import React from 'react';
import classNames from 'classnames';
import { BookingSummary } from '../../../BookingSummaryDto';
import { eventCheckInStore } from '../EventContext/EventCheckInContext';
import { isNullOrUndefined } from 'Util/TypeGuards';

export interface EventBookingFormViewPassengersProps {
	summary?: BookingSummary;
}

export default function EventBookingFormViewTickets({ summary }: EventBookingFormViewPassengersProps) {
	if (isNullOrUndefined(summary)) {
		return <></>;
	}

	return (
		<>
			{eventCheckInStore.eventDetails.customTicketTypes.map(x => {
				const value = summary.selectedTickets
					?.find(y => y.customTicketTypeId === x.id)?.quantity ?? 0;

				const label = value > 1 ? x.pluralName : x.singularName;

				return (
					<div key={x.id} className={classNames({ gray: value < 1 })}>
						{value}x {label}
					</div>
				);
			})}
		</>
	);
}
