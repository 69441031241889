import classNames from 'classnames';
import useScreenSize from 'Hooks/useScreenSize';
import { observer } from 'mobx-react';
import * as React from 'react';
import CheckInTopNavMobile from '../CheckInTopNavMobile';
import CheckInDetails, { CheckInDetailsProps } from './CheckInDetails';

export interface CheckInDetailsViewProps extends CheckInDetailsProps {}

function CheckInDetailsView({ bookingId }: CheckInDetailsViewProps) {
	const { isMobile } = useScreenSize();

	return (
		<div id="check-in-body" className="body-content check-in">
			<CheckInTopNavMobile />
			<div className="check-in__body">
				<CheckInDetails
					bookingId={bookingId}
					className={classNames('pt-xs pb-xxxl', { 'p-xs': isMobile })}
				/>
			</div>
		</div>
	);
}

export default observer(CheckInDetailsView);
