import * as React from 'react';
import { FEATURE_IMAGE_3_URL } from 'Constants';
import { RouteComponentProps } from 'react-router';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { Text } from 'Views/Components/Text/Text';

function ChangeEmailRequestPage(props: RouteComponentProps) {
	const {
		match,
		location,
		history,
		staticContext,
	} = props;

	return (
		<>
			<NavigationWrapper
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className="body-content">
				<div className="login-container">
					<div className="feature-image">
						<img
							className="bg-img"
							alt="bg-img"
							src={FEATURE_IMAGE_3_URL}
						/>
					</div>
					<div className="login">
						<div className="change-email-request">
							<Text size="lg" weight="700" className="mb-md">
								Check your inbox
							</Text>
							<Text size="md" weight="500">
								We&apos;ve sent an email to your inbox to verify your account. Once verified you can
								pick up where you left off.
							</Text>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ChangeEmailRequestPage;
