import * as React from 'react';
import classNames from 'classnames';
import { BaseModalContent } from './BaseModalContents';
import useStore from 'Hooks/useStore';
import {
	Button,
	Colors,
	Display,
	ICbButtonProps,
	Sizes,
} from 'Views/Components/Button/Button';
import Icon from '../../Icon/Icon';
import { SingleActionModalContentProps } from './SingleActionModalContent';

export interface DualActionModalContentProps extends SingleActionModalContentProps {
	/**
	 * If content is string, it will be wrapped with default button component (Default: 'Confirm' {right-arrow-icon})
	 */
	confirmContent?: React.ReactNode;
	/**
	 * If content is string, it will be wrapped with default button component (Default: 'Close')
	 */
	cancelContent?: React.ReactNode;
	onCancel?: () => void;
	/**
	 * When true, cancel button will have a fixed width of 100px. Otherwise, it would typically have flex: 1.
	 */
	fixedCancelWidth?: boolean;
	/**
	 * Sets the confirm button to red instead of blue
	 */
	warningColour?: boolean;
	/**
	 * When using default confirm button, use this to customize it.
	 */
	confirmBtnProps?: Partial<ICbButtonProps>
	/**
	 * When using default cancel button, use this to customize it.
	 */
	cancelBtnProps?: Partial<ICbButtonProps>
}

export function DualActionModalContent({
	title,
	body,
	className,
	confirmContent,
	cancelContent,
	onCancel,
	onConfirm,
	fixedCancelWidth = false,
	autoClose = true,
	warningColour = false,
	confirmBtnProps,
	cancelBtnProps,
}: DualActionModalContentProps) {
	const store = useStore();

	const defaultCancelButton = () => {
		return (
			<Button
				key="cancel"
				display={Display.Solid}
				colors={Colors.Secondary}
				sizes={Sizes.Medium}
				className={classNames('cancel', { 'fixed-width': fixedCancelWidth })}
				onClick={() => {
					if (onCancel !== undefined) {
						onCancel();
					}
					if (autoClose) {
						store.modal.hide();
					}
				}}
				{...cancelBtnProps}
			>
				{cancelContent ?? 'Close'}
			</Button>
		);
	};

	const defaultConfirmButton = () => {
		return (
			<Button
				key="confirm"
				display={Display.Solid}
				colors={warningColour ? Colors.Red : Colors.Alternate}
				sizes={Sizes.Medium}
				className="confirm"
				onClick={() => {
					if (onConfirm !== undefined) {
						onConfirm();
					}
					if (autoClose) {
						store.modal.hide();
					}
				}}
				{...confirmBtnProps}
			>
				{confirmContent ?? 'Confirm'}
				<Icon name="arrow-right" />
			</Button>
		);
	};

	const footer = (
		<>
			{
				cancelContent === undefined || typeof cancelContent === 'string'
					? defaultCancelButton()
					: cancelContent
			}
			{
				confirmContent === undefined || typeof confirmContent === 'string'
					? defaultConfirmButton()
					: confirmContent
			}
		</>
	);

	return (
		<BaseModalContent
			title={title}
			body={body}
			className={className}
			footer={footer}
		/>
	);
}
