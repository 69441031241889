import React from 'react';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { BookingEntity } from '../../../../../../Models/Entities';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import { EventCheckInListRowDetail } from './EventCheckInListRowDetail';
import { eventCheckInStore } from '../EventContext/EventCheckInContext';

export interface EventCheckInListRowPassengersProps {
	booking: BookingEntity | EventCheckInBookingOverviewDto;
}

export default function EventCheckInListRowPassengers({ booking }: EventCheckInListRowPassengersProps) {
	const { bookedSummary } = booking;

	if (isNullOrUndefined(bookedSummary)) {
		return <></>;
	}

	return (
		<>
			{eventCheckInStore.eventDetails.customTicketTypes.map(x => {
				const value = bookedSummary.selectedTickets?.find(y => y.customTicketTypeId === x.id)?.quantity ?? 0;

				if (value === 0) {
					return <React.Fragment key={x.id} />;
				}

				const label = x.singularName[0];

				return (
					<EventCheckInListRowDetail
						key={x.id}
						label={label}
						value={value}
						className={`row-detail-${x.singularName}`}
					/>
				);
			})}
		</>
	);
}
