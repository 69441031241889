import React, { useEffect, useState } from 'react';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import classNames from 'classnames';
import useStore from 'Hooks/useStore';
import If from 'Views/Components/If/If';
import moment from 'moment';
import { unselectInvalidDateTicket } from 'Util/_HumanWritten/TicketSelectionValidationUtils';
import { Button } from 'Views/Components/Button/Button';
import { BookingEntity } from 'Models/Entities';
import { isNotNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { renderSpacesRemaining, renderWaitListButton } from 'Util/_HumanWritten/SpaceRemainingUtils';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import CustomSpinner from 'Views/Components/Spinner/CustomSpinner';
import {
	getCSSClassForTicketsTabTripCardStatus,
	getTripTimeFromTicketTabTrip,
} from 'Util/_HumanWritten/TicketsTabTripUtils';
import {
	calculateAvailabilityFromTicketTabTrip,
	calculateSpacesExceeded,
	calculateTotalPassengers,
} from 'Util/_HumanWritten/CapacityCalculationUtils';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface FilteredTripCardProps {
	className: string;
	ticketsTabTrip: TicketsTabTrip;
	index: number;
	wizardData: BookingWizardData;
	departure: boolean;
	departureTripTime: Date | null;
	saveChanges: (newData: BookingWizardData) => void;
	isStaff: boolean;
	locationInApp: tripSummaryLocationType;
	refreshTrips?: () => void;
	priceByFerryTrip: { [key: string]: number };
	loading: boolean;
}

// For the ticket selection to work on the frontend, we need to account for any previously selected vehicle lengths
// trailer lengths and passenger count while in EDIT mode for a booking that has already been added to the cart.
// Therefore we will get the old counts/lengths from the oldWizardData and add it to the available space for the
// selected ferry. This allows for the user to be able to select the ticket still and see an accurate number of spaces
// available in including the already RESERVED booking they are editing.
export function FilteredTripCard({
	className,
	ticketsTabTrip,
	index,
	wizardData,
	departure,
	departureTripTime,
	saveChanges,
	isStaff,
	refreshTrips,
	priceByFerryTrip,
	loading,
}: FilteredTripCardProps) {
	usePassengerTypes();
	const store = useStore();
	const { isIpad } = useDeviceDetect();
	const isSelected = className === 'selected-trip-card-tile';
	const oldWizardData = getOldFerryBookingWizardData();
	const availability = calculateAvailabilityFromTicketTabTrip(ticketsTabTrip);

	const [isValid, setIsValid] = useState(true);
	const isVehicleAvailability = availability.vehicle > 0;
	const isPassengerAvailability = availability.passenger > 0;
	const isTrailerAvailability = availability.trailer > 0;
	const isVehicleBooking = wizardData.tabSelected === 'vehicle';
	const isCompletelySoldOut = (!isPassengerAvailability && (!isVehicleBooking
			|| (whiteLabelStore.minAdultsForVehicle > 0 && isVehicleBooking)))
		|| (!isVehicleAvailability && isVehicleBooking);
	const tripHasDeparted = ticketsTabTrip.startDate < new Date();
	const tripIsClosed = ticketsTabTrip.isClosed;
	const disabledFerryTrip = (tripHasDeparted || tripIsClosed) ?? false;

	const { vehicleLengthExceeded, trailerSpaceExceeded } = calculateSpacesExceeded(
		// NEED to use the availability returned from the server
		// as the `availability` variable finds the floor of the remaining spaces
		// which causes the vehicle/trailer spaces exceeded to be true in the following scenario:
		// There is 1.1 spaces available on the server, `availability` rounds this to 1 space, the user tres
		// to book a vehicle that takes up 1.1 space, which is more than the rounded space of 1.
		ticketsTabTrip.vehicleSpacesAvailable,
		ticketsTabTrip.trailerSpotsAvailable,
		availability.measurementType,
		wizardData,
		oldWizardData,
	);

	const exceedsPassengerAvailable = (calculateTotalPassengers(wizardData))
		> availability.passenger;

	const isInvalidAlterationTicket = wizardData.wizardMode === 'ALTERATION'
		&& (stringNotEmpty(wizardData.returningTicketId))
		&& ((wizardData.departureTrip
			&& wizardData.associatedTripDateTime
			&& moment(wizardData.associatedTripDateTime).toDate() <= ticketsTabTrip.startDate)
		|| (!wizardData.departureTrip
			&& wizardData.associatedTripDateTime
			&& moment(wizardData.associatedTripDateTime).toDate() >= ticketsTabTrip.startDate));

	const availabilityCss = React.useMemo(() => (
		getCSSClassForTicketsTabTripCardStatus(ticketsTabTrip, availability, wizardData.tabSelected === 'vehicle')
	),
	[
		availability,
		availability.passenger,
		availability.vehicle,
		ticketsTabTrip,
		ticketsTabTrip.startDate,
		wizardData.tabSelected,
	]);

	const isSelectable: boolean = React.useMemo(() => {
		setIsValid(true);
		if (wizardData.departureTicketId === ticketsTabTrip.id || wizardData.returningTicketId === ticketsTabTrip.id) {
			return true;
		}
		if (isInvalidAlterationTicket) {
			return false;
		}
		// Even though staff and managers have their trips that they are allowed to select, we need to ensure that they
		// don't select a returning trip that leaves before the departing trip, as we don't want the wizard to allow
		// this for anyone
		if (!departure && wizardData.departureTicketId !== ''
			&& ticketsTabTrip.startDate <= (departureTripTime ?? wizardData.ticketSelectionStartDate)) {
			setIsValid(false);
			return false;
		}
		// Staff can select any departed trip regardless of space capacity or departed status
		if ((isStaff && disabledFerryTrip) || store.isManager) {
			return true;
		}
		if (disabledFerryTrip && !isStaff) {
			return false;
		}
		if (availabilityCss === 'no-availability' && !store.isManager) {
			return false;
		}
		if (wizardData.tabSelected === 'vehicle' && (vehicleLengthExceeded || exceedsPassengerAvailable)) {
			return false;
		}
		if (wizardData.tabSelected === 'passenger' && exceedsPassengerAvailable) {
			return false;
		}
		return !isInvalidAlterationTicket;
	}, [
		availabilityCss,
		departure,
		departureTripTime,
		exceedsPassengerAvailable,
		ticketsTabTrip.startDate,
		saveChanges,
		tripIsClosed,
		tripHasDeparted,
		vehicleLengthExceeded,
		wizardData.tabSelected,
		wizardData.departureTicketId,
		wizardData.startDate,
	]);

	const selectTicket = (selectedFerryTrip: TicketsTabTrip) => {
		const newData = { ...wizardData };
		// Only staff members can execute logic for any ferry trips
		// Otherwise, only execute logic when ferry trip is selectable
		if (!isSelectable && !store.isStaff) {
			return;
		}
		// Conditions:
		// - for departing ferry trips
		//   - if id is empty, then assign new ticket
		//   - if id is NOT empty, make id empty
		// - for returning ferry trips
		//   - if id is empty, then assign new ticket
		//   - if id is NOT empty, make id empty
		if (newData.departureTicketId === ''
			&& (departure || (newData.wizardMode === 'ALTERATION' && newData.departureTrip !== false))) {
			newData.departureTicketId = selectedFerryTrip.id;
			// Set the departure date to the selected ferry ticket date and set the destination date if it is before
			// the selected tickets departure date
			unselectInvalidDateTicket(newData);
		} else if (newData.departureTicketId === ''
			&& (newData.wizardMode === 'ALTERATION' && newData.departureTrip === false)) {
			// This is used for when the user passes the return ticket of a two-way trip into the wizard
			// Because this scenrio will not be able to enter the above IF statement
			newData.departureTicketId = selectedFerryTrip.id;

			// Set the departure date to the selected ferry ticket date
			newData.endDate = selectedFerryTrip.startDate;
		} else if (
			(newData.departureTicketId !== '' && newData.departureTicketId !== undefined)
			&& (departure || (newData.wizardMode === 'ALTERATION' && newData.departureTrip === false))
		) {
			newData.ticketSelectionStartDate = newData.startDate;
			newData.departureTicketId = '';
		} else if (!departure && newData.returningTicketId === '') {
			newData.returningTicketId = selectedFerryTrip.id;
		} else if (!departure && newData.returningTicketId !== ''
			&& newData.returningTicketId !== undefined) {
			newData.ticketSelectionEndDate = newData.endDate;
			newData.returningTicketId = '';
		}
		saveChanges(newData);
	};

	useEffect(() => {
		const newData = { ...wizardData };
		if (newData.tabSelected === 'vehicle'
			&& vehicleLengthExceeded
		) {
			// Deselect any ferry ticket that the vehicle (and trailer) length exceeds
			if (ticketsTabTrip.id === newData.departureTicketId) {
				newData.departureTicketId = '';
			}
			if (ticketsTabTrip.id === newData.returningTicketId) {
				newData.returningTicketId = '';
			}

			saveChanges(newData);
		}
	}, [
		ticketsTabTrip.id,
		saveChanges,
		vehicleLengthExceeded,
		wizardData.departureTicketId,
		wizardData.returningTicketId,
		wizardData.tabSelected,
	]);

	if ((isIpad) && isSelected) {
		return (
			<>
				<div
					className={`selected-trip-card-tile ${disabledFerryTrip ? 'departed' : ''}`}
					id={ticketsTabTrip.id}
					onClick={() => selectTicket(ticketsTabTrip)}
					onKeyPress={() => selectTicket(ticketsTabTrip)}
					role="button"
					tabIndex={index}
				>
					<div className="selected-trip-card-tile__border--left" />
					<div className="selected-trip-card-tile__contents">
						<h5 className="trip-card-departure-time">
							{getTripTimeFromTicketTabTrip(ticketsTabTrip)}
						</h5>
						<div className="trip-card-price__container">
							<CustomSpinner loaderClassname={`mini ${loading ? '' : 'hide'}`} />
							<p className="trip-card-price">
								{formatPriceDisplay(
									priceByFerryTrip[ticketsTabTrip.id] ?? 0,
									wizardData.wizardMode === 'ALTERATION')}
							</p>
						</div>
						<If condition={!disabledFerryTrip || store.isStaff}>
							{renderSpacesRemaining(
								departure,
								wizardData.trailerCheckboxSelected,
								ticketsTabTrip,
								availability,
								isVehicleBooking,
								isVehicleAvailability,
								isTrailerAvailability,
								(isInvalidAlterationTicket || !isValid) ?? false,
								vehicleLengthExceeded,
								exceedsPassengerAvailable,
								isPassengerAvailability,
								trailerSpaceExceeded,
								isCompletelySoldOut,
								false,
								wizardData,
								disabledFerryTrip,
								store,
								refreshTrips,
							)}
						</If>
						<If condition={disabledFerryTrip && !store.isStaff}>
							<div className="disabled-ferry-container">
								<p className="disabled-ferry-message">{tripHasDeparted ? 'Departed' : 'Closed'}</p>
							</div>
						</If>
						<div
							className={`fake-radio-button checked icon-tick icon-only ${isSelectable
								? 'show'
								: 'hide'}`}
						/>
					</div>
				</div>
				<If condition={isSelected}>
					<div className={`${wizardData.departureTrip
						? 'departure-ticket'
						: 'return-ticket'} change-selected-ticket`}
					>
						<Button
							onClick={() => {
								selectTicket(ticketsTabTrip);
							}}
						>
							Change {departure ? 'departing' : 'returning'} trip time
						</Button>
					</div>
				</If>
			</>
		);
	}

	return (
		<>
			<div
				className={
					classNames(
						className,
						availabilityCss,
						isSelectable ? 'selectable' : '',
					)
				}
				id={ticketsTabTrip.id}
				onClick={() => {
					if (isSelectable) {
						selectTicket(ticketsTabTrip);
					}
				}}
				onKeyPress={() => {
					if (isSelectable) {
						selectTicket(ticketsTabTrip);
					}
				}}
				role="button"
				tabIndex={index}
			>
				<h3 className="trip-card-departure-time">{getTripTimeFromTicketTabTrip(ticketsTabTrip)}</h3>
				{(!disabledFerryTrip || store.isStaff) && (
					<>
						{renderSpacesRemaining(
							departure,
							wizardData.trailerCheckboxSelected,
							ticketsTabTrip,
							availability,
							isVehicleBooking,
							isVehicleAvailability,
							isTrailerAvailability,
							(isInvalidAlterationTicket || !isValid) ?? false,
							vehicleLengthExceeded,
							exceedsPassengerAvailable,
							isPassengerAvailability,
							trailerSpaceExceeded,
							isCompletelySoldOut,
							!isIpad && !isStaff,
							wizardData,
							disabledFerryTrip,
							store,
							refreshTrips,
						)}
					</>
				)}
				{disabledFerryTrip && !store.isStaff && (
					<div className="disabled-ferry-container">
						<p className="disabled-ferry-message">{tripHasDeparted ? 'Departed' : 'Closed'}</p>
					</div>
				)}
				<div className="trip-card-price__container">
					<CustomSpinner loaderClassname={`mini ${loading ? '' : 'hide'}`} />
					<p className="trip-card-price">
						{formatPriceDisplay(
							priceByFerryTrip[ticketsTabTrip.id] ?? 0,
							wizardData.wizardMode === 'ALTERATION')}
					</p>
				</div>
				<div className="selection-container">
					{(isCompletelySoldOut || (!isTrailerAvailability && isVehicleBooking)) && isIpad && !isStaff
						&& renderWaitListButton(ticketsTabTrip, wizardData, store, refreshTrips)}
					<div
						className={`fake-radio-button ${className === 'selected-trip-card-tile'
							? 'checked icon-tick icon-only' : 'unchecked'} ${isSelectable ? 'show'
							: 'hide'}`}
					/>
				</div>
			</div>
			<If condition={isSelected}>
				<div className={`${departure ? 'departure-ticket' : 'return-ticket'} change-selected-ticket`}>
					<Button
						onClick={() => {
							selectTicket(ticketsTabTrip);
						}}
					>
						Change {departure ? 'departing' : 'returning'} trip time
					</Button>
				</div>
			</If>
		</>
	);
}
