import React, { useEffect } from 'react';
import {
	Route,
	Router,
	Switch,
	useHistory,
	useRouteMatch,
} from 'react-router-dom';

import useCheckInStore from 'Hooks/useCheckInStore';
import { useScanner } from '../Stores/useScanner';
import { ScannerActionMap } from '../Types/scannerAction';
import Scanner from './Scanner/Scanner';
import ScannerResult from './ScannerResult/ScannerResult';

export default function ScannerRoute() {
	const history = useHistory();
	const { path } = useRouteMatch();

	const dispatch = useScanner(x => x.dispatch);
	const checkInContext = useCheckInStore();

	useEffect(() => {
		// Load bookings to mag the cargo id scanning to.
		const { bookings } = checkInContext;
		dispatch({ action: ScannerActionMap.StartScanner, bookings });
	}, []);

	return (
		<Router history={history}>
			<div className="check-in-scanner">
				<div className="check-in-scanner__container">
					<Switch>
						<Route path={`${path}/result`}>
							<ScannerResult />
						</Route>
						<Route path={path}>
							<Scanner />
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	);
}
