/**
 * Since this compareFn handles undefined values, it priorities items that do have a value.
 */
export const compareFnByNumberAsc = <T>(selectAttribute: (item: T) => number | undefined | null) => {
	return (a: T, b: T) => {
		const valueA = selectAttribute(a);
		const valueB = selectAttribute(b);

		if (valueA === valueB) {
			return 0;
		}

		if (typeof valueA !== 'number') {
			// this will arrange item A at the end of the list
			return 1;
		}

		if (typeof valueB !== 'number') {
			// this will arrange item B at the end of the list
			return -1;
		}

		return valueA > valueB ? 1 : -1;
	};
};
