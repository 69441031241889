/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/VisitorsAdditionalBookingSectionsReferenceManyToMany';
import UserAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/UserAdditionalBookingSectionsReferenceManyToMany';
import StaffAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/StaffAdditionalBookingSectionsReferenceManyToMany';
import ManagerAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/ManagerAdditionalBookingSectionsReferenceManyToMany';
import AdminAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/AdminAdditionalBookingSectionsReferenceManyToMany';
import InvoicedUserAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/InvoicedUserAdditionalBookingSectionsReferenceManyToMany';
import FixedPriceUserAdditionalBookingSectionsReferenceManyToMany from '../Security/Acl/FixedPriceUserAdditionalBookingSectionsReferenceManyToMany';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
// % protected region % [Add any further imports here] end

export interface IRoutesAdditionalBookingSectionsAttributes extends IModelAttributes {
	routesId: string;
	additionalBookingSectionsId: string;

	routes: Models.RouteEntity | Models.IRouteEntityAttributes;
	additionalBookingSections: Models.AdditionalBookingSectionEntity | Models.IAdditionalBookingSectionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('RoutesAdditionalBookingSections')
export default class RoutesAdditionalBookingSections
	extends Model
	implements IRoutesAdditionalBookingSectionsAttributes {
	public static acls: IAcl[] = [
		new VisitorsAdditionalBookingSectionsReferenceManyToMany(),
		new UserAdditionalBookingSectionsReferenceManyToMany(),
		new StaffAdditionalBookingSectionsReferenceManyToMany(),
		new ManagerAdditionalBookingSectionsReferenceManyToMany(),
		new AdminAdditionalBookingSectionsReferenceManyToMany(),
		new InvoicedUserAdditionalBookingSectionsReferenceManyToMany(),
		new FixedPriceUserAdditionalBookingSectionsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public routesId: string;

	@observable
	@attribute()
	public additionalBookingSectionsId: string;

	@observable
	@attribute({ isReference: true })
	public routes: Models.RouteEntity;

	@observable
	@attribute({ isReference: true })
	public additionalBookingSections: Models.AdditionalBookingSectionEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IRoutesAdditionalBookingSectionsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.routesId) {
				this.routesId = attributes.routesId;
			}
			if (attributes.additionalBookingSectionsId) {
				this.additionalBookingSectionsId = attributes.additionalBookingSectionsId;
			}

			if (attributes.routes) {
				if (attributes.routes instanceof Models.RouteEntity) {
					this.routes = attributes.routes;
					this.routesId = attributes.routes.id;
				} else {
					this.routes = new Models.RouteEntity(attributes.routes);
					this.routesId = this.routes.id;
				}
			} else if (attributes.routesId !== undefined) {
				this.routesId = attributes.routesId;
			}

			if (attributes.additionalBookingSections) {
				if (attributes.additionalBookingSections instanceof Models.AdditionalBookingSectionEntity) {
					this.additionalBookingSections = attributes.additionalBookingSections;
					this.additionalBookingSectionsId = attributes.additionalBookingSections.id;
				} else {
					this.additionalBookingSections = new Models.AdditionalBookingSectionEntity(attributes.additionalBookingSections);
					this.additionalBookingSectionsId = this.additionalBookingSections.id;
				}
			} else if (attributes.additionalBookingSectionsId !== undefined) {
				this.additionalBookingSectionsId = attributes.additionalBookingSectionsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
