/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsFeeOptionEntity from 'Models/Security/Acl/VisitorsFeeOptionEntity';
import UserFeeOptionEntity from 'Models/Security/Acl/UserFeeOptionEntity';
import StaffFeeOptionEntity from 'Models/Security/Acl/StaffFeeOptionEntity';
import ManagerFeeOptionEntity from 'Models/Security/Acl/ManagerFeeOptionEntity';
import AdminFeeOptionEntity from 'Models/Security/Acl/AdminFeeOptionEntity';
import InvoicedUserFeeOptionEntity from 'Models/Security/Acl/InvoicedUserFeeOptionEntity';
import FixedPriceUserFeeOptionEntity from 'Models/Security/Acl/FixedPriceUserFeeOptionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { store } from '../Store';
// % protected region % [Add any further imports here] end

export interface IFeeOptionEntityAttributes extends IModelAttributes {
	key: string;
	name: string;
	description: string;
	feeAmount: number;
	fixedAmountFee: boolean;
	automaticallyApply: boolean;
	checkInFee: boolean;
	bookingWizardFee: boolean;
	newBookingFee: boolean;
	alterBookingFee: boolean;
	removable: boolean;
	applyForStaff: boolean;
	applyForRegularCustomer: boolean;
	applyForFixedPriceCustomers: boolean;
	disabled: boolean;

	routes: Array<
		| Models.RouteFeeOption
		| Models.IRouteFeeOptionAttributes
	>;
	fees: Array<
		| Models.FeeEntity
		| Models.IFeeEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('FeeOptionEntity', 'Fee Option')
// % protected region % [Customise your entity metadata here] end
export default class FeeOptionEntity extends Model
	implements IFeeOptionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFeeOptionEntity(),
		new UserFeeOptionEntity(),
		new StaffFeeOptionEntity(),
		new ManagerFeeOptionEntity(),
		new AdminFeeOptionEntity(),
		new InvoicedUserFeeOptionEntity(),
		new FixedPriceUserFeeOptionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Key'] on begin
	/**
	 * The key for the fee.
	 */
	@observable
	@attribute<FeeOptionEntity, string>()
	@CRUD({
		name: 'Key',
		displayType: store.isSuper ? 'textfield' : 'hidden',
		order: 10,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		isReadonly: !store.isSuper,
	})
	public key: string;
	// % protected region % [Modify props to the crud options here for attribute 'Key'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	/**
	 * The name of the fee that will display on the front-end of the application.
	 */
	@Validators.Required()
	@observable
	@attribute<FeeOptionEntity, string>()
	@CRUD({
		name: 'Fee name',
		description: 'The name of this fee can be seen by users on the frontend of the application.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * A description of when this fee should be applied.
	 */
	@Validators.Required()
	@observable
	@attribute<FeeOptionEntity, string>()
	@CRUD({
		name: 'Description',
		description: 'The description can help staff understand what situation the fee would be used in.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fee Amount'] on begin
	/**
	 * The amount that the fee will add on to the booking. Note: Depending on whether it is percentage or a whole dollar value.
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<FeeOptionEntity, number>()
	@CRUD({
		name: 'Fee amount',
		description: 'The fee amount can either be a percentage (e.g. 20 = 20%) or a whole dollar value. '
			+ 'To make it a whole dollar value you must check `Make this a fixed amount fee` below.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public feeAmount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Fee Amount'] end

	// % protected region % [Modify props to the crud options here for attribute 'Fixed Amount Fee'] on begin
	/**
	 * Whether or not this is a fixed dollar value fee. If it is, then the  fee amount will be an exact dollar value added to the booking. Otherwise it will be a percentage of the total booking cost.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Set as fixed amount fee',
		description: 'If this is checked, the value you have entered in `Fee Amount` will be a whole dollar value, '
			+ 'rather than a percentage of the transaction cost.',
		displayType: 'checkbox',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public fixedAmountFee: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Fixed Amount Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'Automatically Apply'] on begin
	/**
	 * Whether or not the fee will automatically apply in the situations it applies to.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Automatically apply fee',
		description: 'Whether or not to automatically apply the fee (if it fits the scenario).',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public automaticallyApply: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Automatically Apply'] end

	// % protected region % [Modify props to the crud options here for attribute 'Check In Fee'] on begin
	/**
	 * Whether or not to show this fee during check-in.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Show fee in check-in',
		description: 'If this is checked, the fee will display as an option for staff to choose during check-in.',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public checkInFee: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Check In Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'Booking Wizard Fee'] on begin
	/**
	 * Whether or not to show this fee during the booking wizard.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Show fee in the booking wizard',
		description: 'If this is checked, the fee will display as an option for staff to choose during '
			+ 'the booking wizard.',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public bookingWizardFee: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Wizard Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'New Booking Fee'] on begin
	/**
	 * Whether or not this fee shows for new bookings or not.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Show fee during new booking creation',
		description: 'If this is checked, the fee will display as an option for staff to choose during the '
			+ 'creation of new bookings.',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public newBookingFee: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'New Booking Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'Alter Booking Fee'] on begin
	/**
	 * Whether or not this fee appears during an alteration of a booking.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Show fee during booking alteration',
		description: 'If this is checked, the fee will display as an option for staff to choose during an alteration '
			+ 'of an existing booking.',
		displayType: 'checkbox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public alterBookingFee: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Alter Booking Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'Removable'] on begin
	/**
	 * Whether or not the fee in removable (if it is automatically applied) by staff.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Removable',
		description: 'Whether or not this fee is removable (will only apply to automatically applied fees).',
		displayType: 'checkbox',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public removable: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Removable'] end

	// % protected region % [Modify props to the crud options here for attribute 'Apply For Staff'] on begin
	/**
	 * Whether or not to automatically apply the fee for staff making bookings on behalf of a customer.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Apply for staff',
		description: 'Whether or not this fee will automatically apply for staff.',
		displayType: 'checkbox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public applyForStaff: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Apply For Staff'] end

	// % protected region % [Modify props to the crud options here for attribute 'Apply For Regular Customer'] on begin
	/**
	 * Whether or not to automatically apply for regular customers when they are in the same situation that applies to this fee.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Apply for regular customer',
		description: 'Whether or not this fee will automatically apply for regular customer bookings '
			+ '(fixed price customers not included).',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public applyForRegularCustomer: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Apply For Regular Customer'] end

	// % protected region % [Modify props to the crud options here for attribute 'Apply For Fixed Price Customers'] on begin
	/**
	 * Whether or not to automatically apply for fixed price customers when in the situation that aligns with this fee option.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Apply for fixed price customers',
		description: 'Whether or not to apply this fee automatically for fixed price users.',
		displayType: 'checkbox',
		order: 140,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public applyForFixedPriceCustomers: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Apply For Fixed Price Customers'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	/**
	 * Whether or not this fee is disabled and hidden from the UI as an option.
	 */
	@observable
	@attribute<FeeOptionEntity, boolean>()
	@CRUD({
		name: 'Disable this fee',
		description: 'If this is checked, it will disable this fee from automatically being applied on the frontend '
			+ '(only applies to system automated fees).',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Route'] on begin
		name: 'Route',
		displayType: 'hidden',
		order: 130,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RouteFeeOption,
		optionEqualFunc: makeJoinEqualsFunc('routeId'),
		isReadonly: true,
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'feeOptionEntity',
			oppositeEntityName: 'routeEntity',
			relationName: 'feeOption',
			relationOppositeName: 'route',
			entity: () => Models.FeeOptionEntity,
			joinEntity: () => Models.RouteFeeOption,
			oppositeEntity: () => Models.RouteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Route'] end
	})
	public routes: Models.RouteFeeOption[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Fee'] on begin
		name: 'Fees',
		displayType: 'hidden',
		order: 120,
		referenceTypeFunc: () => Models.FeeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'fees',
			oppositeEntity: () => Models.FeeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Fee'] end
	})
	public fees: Models.FeeEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IFeeOptionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFeeOptionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.key !== undefined) {
				this.key = attributes.key;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.feeAmount !== undefined) {
				this.feeAmount = attributes.feeAmount;
			}
			if (attributes.fixedAmountFee !== undefined) {
				this.fixedAmountFee = attributes.fixedAmountFee;
			}
			if (attributes.automaticallyApply !== undefined) {
				this.automaticallyApply = attributes.automaticallyApply;
			}
			if (attributes.checkInFee !== undefined) {
				this.checkInFee = attributes.checkInFee;
			}
			if (attributes.bookingWizardFee !== undefined) {
				this.bookingWizardFee = attributes.bookingWizardFee;
			}
			if (attributes.newBookingFee !== undefined) {
				this.newBookingFee = attributes.newBookingFee;
			}
			if (attributes.alterBookingFee !== undefined) {
				this.alterBookingFee = attributes.alterBookingFee;
			}
			if (attributes.removable !== undefined) {
				this.removable = attributes.removable;
			}
			if (attributes.applyForStaff !== undefined) {
				this.applyForStaff = attributes.applyForStaff;
			}
			if (attributes.applyForRegularCustomer !== undefined) {
				this.applyForRegularCustomer = attributes.applyForRegularCustomer;
			}
			if (attributes.applyForFixedPriceCustomers !== undefined) {
				this.applyForFixedPriceCustomers = attributes.applyForFixedPriceCustomers;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.routes !== undefined && Array.isArray(attributes.routes)) {
				for (const model of attributes.routes) {
					if (model instanceof Models.RouteFeeOption) {
						this.routes.push(model);
					} else {
						this.routes.push(new Models.RouteFeeOption(model));
					}
				}
			}
			if (attributes.fees !== undefined && Array.isArray(attributes.fees)) {
				for (const model of attributes.fees) {
					if (model instanceof Models.FeeEntity) {
						this.fees.push(model);
					} else {
						this.fees.push(new Models.FeeEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = '';
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			routes: {},
			fees: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'routes',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FeeOptionEntity.prototype, 'created');
CRUD(modifiedAttr)(FeeOptionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
