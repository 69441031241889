import * as React from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'Views/Components/Flex/Flex';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { AllCheckedInEvent } from '../EventCheckInEmpty/AllCheckedInEvent';
import { NoneCheckedInEvent } from '../EventCheckInEmpty/NoneCheckedInEvent';
import { firstLetterLastName } from '../EventCheckInUtils';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import { EventCheckInListRowSubheader } from './EventCheckInListRowSubheader';
import { EventCheckInListRow } from './EventCheckInListRow';
import useEventCheckInStore from 'Hooks/useEventCheckInStore';
import { EventCheckInOpenClose } from './EventCheckInOpenClose';
import If from 'Views/Components/If/If';
import { filterEventBookings } from '../Helpers/FilterEventBookings';
import { useSearchTerm } from '../../FerryCheckIn/CheckInView';
import { stringIsEmpty } from 'Util/TypeGuards';
import { EventCheckInSearch } from '../EventCheckInSearch/EventCheckInSearch';
import { EventCheckInSearchEmpty } from '../EventCheckInSearch/EventCheckInSearchEmpty';

export interface EventCheckInListProps {
	forCheckIn?: boolean;
}

function EventCheckInList(props: EventCheckInListProps) {
	const {
		forCheckIn = false,
	} = props;

	const eventCheckInStore = useEventCheckInStore();
	const { bookings } = eventCheckInStore;
	const routes = useCheckInRoutes(true);

	const {
		model,
	} = useSearchTerm(x => ({
		model: x.model,
	}));

	const displayedBookings = filterEventBookings({
		bookings,
		forCheckIn,
		searchTerm: model.searchTerm,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(model.searchTerm)) {
		return (
			<>
				{
					forCheckIn
						? (
							<div className="all-checked-in__container">
								<AllCheckedInEvent />
								<EventCheckInOpenClose
									ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
									eventId={eventCheckInStore.eventId}
									checkInClosed={eventCheckInStore.eventDetails.closed}
									tripDeparted={eventCheckInStore.eventDetails.startDateTime < new Date()}
								/>
							</div>
						)
						: (
							<div className="none-checked-in__container">
								<NoneCheckedInEvent />
								<EventCheckInOpenClose
									ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
									eventId={eventCheckInStore.eventId}
									checkInClosed={eventCheckInStore.eventDetails.closed}
									tripDeparted={eventCheckInStore.eventDetails.startDateTime < new Date()}
								/>
							</div>
						)
				}
			</>
		);
	}

	const comparer = (x: EventCheckInBookingOverviewDto) => firstLetterLastName(x).toUpperCase();

	return (
		<>
			<Flex direction="col" className="check-in-list" flex={1}>
				<EventCheckInSearch />
				<If condition={displayedBookings.length === 0}>
					<EventCheckInSearchEmpty />
				</If>
				<If condition={displayedBookings.length > 0}>
					{
						displayedBookings.map((item, index, arr) => {
							return (
								<React.Fragment key={item.id}>
									<EventCheckInListRowSubheader
										item={item}
										index={index}
										arr={arr}
										comparer={comparer}
									/>
									<EventCheckInListRow
										key={item.id}
										booking={item}
										forCheckIn={forCheckIn}
										onClick={() => {
											store.routerHistory.push(`${routes.base}/booking/${item.id}`);
										}}
									/>
								</React.Fragment>
							);
						})
					}
					<EventCheckInOpenClose
						ferryTripId={eventCheckInStore.eventDetails.ferryTripId}
						eventId={eventCheckInStore.eventId}
						checkInClosed={eventCheckInStore.eventDetails.closed}
						tripDeparted={eventCheckInStore.eventDetails.startDateTime < new Date()}
					/>
				</If>
			</Flex>
		</>
	);
}

export default observer(EventCheckInList);
