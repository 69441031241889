import * as React from 'react';
import { FerryTripEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';
import { Badge, BadgeColor } from '../CheckInTable/Badge';
import { isNotNullOrUndefined, isNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import BookingFormGroupHeader from './BookingFormGroupHeader';
import { observer } from 'mobx-react';
import { transformMobileNumber } from '../CheckInUtils';
import { CheckInOrUndoButton } from '../CheckInButtons/CheckInOrUndoButton';
import classNames from 'classnames';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import BookingFormViewPassengers from './BookingFormViewPassengers';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { Tag } from '../CheckInList/Tag';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';

interface BookingFormFieldProps {
	label: string;
	value: string;
	customValue?: React.ReactNode;
}

function BookingFormField({
	label,
	value,
	customValue,
}: BookingFormFieldProps) {
	return (
		<div className="booking-form__field">
			<div className="booking-form__field--label">
				{label}
			</div>
			<If condition={isNotNullOrUndefined(customValue)}>
				{customValue}
			</If>
			<If condition={isNullOrUndefined(customValue)}>
				<div className="booking-form__field--value">
					{value}
				</div>
			</If>
		</div>
	);
}

export interface BookingFormProps {
	booking: CheckInBookingOverviewDto;
	checkIn?: () => void;
	className?: string;
}

/**
 * It was decided it is easier to manage view/edit/add mode using two components.
 *
 * One for VIEW and one for EDIT/ADD. This would produce more readable code.
 */
function BookingFormView({
	booking,
	checkIn,
	className,
}: BookingFormProps) {
	if (isNullOrUndefined(booking.bookedSummary)) {
		alertToast('Could not find booking', 'error');
		return <></>;
	}
	const {
		firstTimeOnRoute,
		bookedSummary: {
			additionalBookingOptions,
			ferryTrip: departingTrip,
		},
		returnBooking,
		transactionStatus,
	} = booking;

	return (
		<div className={classNames('booking-form', className)}>
			<div className="booking-form__title">
				<div className="text">
					{booking.getFullName()}
				</div>
				<If condition={isNotNullOrUndefined(checkIn)}>
					<CheckInOrUndoButton
						iconOnly
						id={booking.id}
						className={`check-in-btn ${!booking.checkedIn ? 'green-btn' : 'grey-btn'}`}
						forCheckIn={!booking.checkedIn}
						onClick={checkIn}
					/>
				</If>
			</div>
			<If condition={firstTimeOnRoute
				|| additionalBookingOptions.length > 0
				|| transactionStatus === 'AWAITING'
				|| transactionStatus === 'INVOICED'}
			>
				<div className="booking-form__badges">
					<If condition={isNotNullOrUndefined(transactionStatus)
						&& (transactionStatus === 'AWAITING'
							|| transactionStatus === 'INVOICED')}
					>
						<Badge
							text={transactionStatus ?? ''}
							color={transactionStatus === 'AWAITING' ? BadgeColor.Awaiting : BadgeColor.Invoiced}
						/>
					</If>
					<If condition={isNotNullOrUndefined(transactionStatus)
						&& transactionStatus === 'CANCELLED'}
					>
						<Badge
							text={transactionStatus ?? 'CANCELLED'}
							color={BadgeColor.Cancelled}
						/>
					</If>
					<If condition={firstTimeOnRoute}>
						<Badge text="NEW" />
					</If>
					<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
						<Badge text={booking?.user?.userDisplayName?.displayName} />
					</If>
					{additionalBookingOptions.filter(x => x.quantity > 0).map(
						x => x.option !== undefined && (
							<Tag
								key={x.option.id}
								text={stringNotEmpty(x.option.abbreviation) ? x.option.abbreviation : x.option.name}
								customStyles={{ background: x?.option?.colour }}
							/>
						),
					)}
				</div>
			</If>
			<BookingFormGroupHeader title="Details">
				<BookingFormField
					label="Phone"
					value={transformMobileNumber(booking)}
					customValue={
						(
							<a href={`tel:${transformMobileNumber(booking)}`} className="booking-form__field--value hide-underline">
								{transformMobileNumber(booking)}
							</a>
						)
					}
				/>
				<BookingFormField
					label="Departing trip"
					value={new FerryTripEntity(departingTrip).getShortDisplayName()}
				/>
				<BookingFormField
					label="Returning trip"
					value={
						returnBooking
							? new FerryTripEntity(returnBooking.bookedSummary?.ferryTrip).getShortDisplayName()
							: '-'
					}
				/>
				{(whiteLabelStore.bookingNoteEnabled) && (
					<BookingFormField
						label={booking.bookedSummary?.cargoInfo
							? whiteLabelStore.cargoBookingNoteLabel
							: whiteLabelStore.passengerBookingNoteLabel}
						value={
							booking.bookedSummary?.note && stringNotEmpty(booking.bookedSummary?.note)
								? booking.bookedSummary.note
								: '-'
						}
					/>
				)}
			</BookingFormGroupHeader>
			<BookingFormGroupHeader title="Passengers" id="passengers-group">
				<div>
					<BookingFormViewPassengers summary={booking.bookedSummary} />
				</div>
			</BookingFormGroupHeader>
			<BookingFormGroupHeader title={whiteLabelStore.vehicleLabelPascalCase} id="vehicle-group">
				<If condition={!booking.bookedSummary?.cargoInfo}>
					-
				</If>
				<If condition={isNotNullOrUndefined(booking.bookedSummary?.cargoInfo)}>
					<BookingFormField
						label={whiteLabelStore.vehicleMakeLabelPascalCase}
						value={booking.getDisplayVehicleMake()}
					/>
					<BookingFormField
						label={whiteLabelStore.vehicleModelLabelPascalCase}
						value={booking.getDisplayVehicleModel()}
					/>
					<If condition={whiteLabelStore.config.vehicleLengthEnabled}>
						<BookingFormField
							label="Length"
							value={booking.getDisplayVehicleLength()}
						/>
						<BookingFormField
							label="Weight"
							value={booking.getDisplayVehicleWeight()}
						/>
					</If>
					<BookingFormField
						label={whiteLabelStore.regoLabelPascalCase}
						value={booking.getDisplayVehicleRego()}
					/>
				</If>
			</BookingFormGroupHeader>
			{whiteLabelStore.config.trailersEnabled && (
				<BookingFormGroupHeader title="Trailer" id="trailer-group">
					<If condition={!booking.bookedSummary?.towOnInfo}>
						-
					</If>
					<If condition={isNotNullOrUndefined(booking.bookedSummary?.towOnInfo)}>
						<BookingFormField
							label="Type"
							value={booking.getDisplayTrailerType()}
						/>
						<BookingFormField
							label="Length"
							value={booking.getDisplayTrailerLength()}
						/>
					</If>
				</BookingFormGroupHeader>
			)}
			<BookingFormGroupHeader title="Add ons" id="addons-group">
				<If condition={additionalBookingOptions.length === 0}>
					-
				</If>
				<If condition={additionalBookingOptions.length > 0}>
					<div>
						{
							additionalBookingOptions.filter(x => x.quantity > 0).map(x => (
								<div key={x.option.id}>
									{`${x.quantity}x ${x.option.name}`}
								</div>
							))
						}
					</div>
				</If>
			</BookingFormGroupHeader>
			<div className="booking-action-buttons bottom-border">
				<Button
					type="button"
					sizes={Sizes.Medium}
					colors={Colors.Secondary}
					display={Display.Text}
					icon={{ icon: 'look' }}
					onClick={() => store.routerHistory.push(`/bookings/${booking.id}`)}
				>
					View booking
				</Button>
				{/* TODO #977 */}
				{/* <BookingActionButtons */}
				{/* 	booking={booking} */}
				{/* 	canEdit */}
				{/* 	customStatus={booking.transactionStatus} */}
				{/* 	refresh={() => {}} */}
				{/* /> */}
			</div>
		</div>
	);
}

export default observer(BookingFormView);
