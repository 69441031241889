import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import alertToast from 'Util/ToastifyUtils';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { removeGiftCertificateFromTransaction } from 'Services/Api/_HumanWritten/GiftCertificateService';
import { isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { useState } from 'react';
import If from 'Views/Components/If/If';
import StyledRadioButton, { paymentMethodType } from 'Views/Components/RadioButton/StyledRadioButton';
import { useCheckInReview } from 'Hooks/useCheckInReview';
import { BookingFormMode } from '../BookingForm/BookingForm';
import { CheckInBottomBar } from '../CheckInBottomBar';
import TripBookingSummaryInner from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryInner';
import {
	FerryPromoGiftCard,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/FerryPromoGiftCard';

function CheckInReviewContent() {
	const {
		isIpadMiniPortrait,
		state,
		mode,
		formState,
		applyToReturn,
		onConfirm,
		onBack,
		refetch,
		setRefetch,
		onClearFee,
	} = useCheckInReview();

	const [paymentMethod, setPaymentMethod] = useState('Eftpos');
	if (
		state.loading || isNullOrUndefined(mode) || isNullOrUndefined(formState)
		//
		// Wait for transaction to be created
		//
		|| stringIsEmpty(formState.existingTransactionId)
		//
		// Wait for bookings, which is used to show in cart
		//
		|| isNullOrUndefined(state.bookings)
		|| !state.bookings.length
		//
		// Wait for bookingToEdit if reviewing edit mode
		//
		|| (mode === BookingFormMode.Edit && isNullOrUndefined(state.bookingToEdit))
	) {
		return (
			<div className="check-in__body">
				<LottieSpinner />
			</div>
		);
	}

	if (state.error) {
		alertToast(
			'Something went wrong',
			'error',
			undefined,
			{
				autoClose: 2500,
			},
		);
		return <></>;
	}

	const isInvoiced = state.bookingToEdit?.alterations.some(x => x.transaction.status === 'PENDING_INVOICE')
		|| (state.bookings[0]?.user?.userDisplayName?.invoicedUser ?? false);
	return (
		<>
			<div className={classNames('check-in__body', { 'pl-sm pr-sm': isIpadMiniPortrait })}>
				<h4 className="check-in__body__title">
					Review booking
				</h4>
				<FerryPromoGiftCard
					className="set-width"
					transactionId={formState.existingTransactionId}
					updateGiftCertUsages={async usages => {
						runInAction(() => {
							formState.giftCertificateUsages = usages;
							setRefetch(!refetch);
						});
					}}
				/>
				<TripBookingSummaryInner
					tripSummaryLocation="check-in"
					bookings={state.bookings}
					refetch={refetch}
					setRefetch={setRefetch}
					onClearFee={(feeId: string) => onClearFee(feeId, formState.existingTransactionId)}
					onClearGiftCertificate={id => {
						const transaction = formState.existingTransactionId ?? '';
						removeGiftCertificateFromTransaction(transaction, id, false)
							.then(_ => {
								alertToast('Removed gift certificate', 'success');
								setRefetch(!refetch);
							})
							.catch(_ => {
								alertToast('Could not remove gift certificate', 'error');
							});
					}}
					alterReturn={applyToReturn}
					removePreProcessedBooking={() => {}}
					includeCreditCardFee={paymentMethod === 'Eftpos'}
				/>
				<If condition={!isInvoiced}>
					<StyledRadioButton
						radioButtonOneLabel="Cash"
						radioButtonTwoLabel="Eftpos"
						paymentMethod={paymentMethod as paymentMethodType}
						setPaymentMethod={(paymentMethodSelected: paymentMethodType) => {
							setPaymentMethod(paymentMethodSelected);
							setRefetch(!refetch);
						}}
					/>
				</If>
				<If condition={isInvoiced}>
					<div className="invoiced-button-container">
						<button className="invoiced-button">Invoiced</button>
					</div>
				</If>
			</div>
			<CheckInBottomBar
				closeBtnProps={{
					content: 'Back',
					onClick: onBack,
				}}
				confirmBtnProps={{
					content: (
						<>
							Confirm & check in
							<Icon name="arrow-right" />
						</>
					),
					onClick: () => onConfirm(paymentMethod as paymentMethodType),
				}}
			/>
		</>
	);
}

export default observer(CheckInReviewContent);
