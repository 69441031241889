import * as React from 'react';
import { Redirect } from 'react-router';
import { useEventDetailedView } from 'Hooks/useEventDetailedView';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { EventDetailEntity, FerryTripEntity } from 'Models/Entities';
import EventDetailedViewInner from 'Views/Components/_HumanWritten/Events/EventDetailedView/EventDetailedViewInner';

export interface SectionProps {
	event: FerryTripEntity;
}

export interface EventDetailsProps {
	eventId?: string;
	showButtons: boolean;
}

export const EventDetailedView = ({ eventId, showButtons }: EventDetailsProps) => {
	const { response } = useEventDetailedView(eventId!);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	if (!response.data) {
		return <Redirect to="/404" />;
	}

	return <EventDetailedViewInner event={new EventDetailEntity(response.data)} showButtons={showButtons} />;
};
