/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsDiscountReferenceManyToMany from '../Security/Acl/VisitorsDiscountReferenceManyToMany';
import UserDiscountReferenceManyToMany from '../Security/Acl/UserDiscountReferenceManyToMany';
import StaffDiscountReferenceManyToMany from '../Security/Acl/StaffDiscountReferenceManyToMany';
import ManagerDiscountReferenceManyToMany from '../Security/Acl/ManagerDiscountReferenceManyToMany';
import AdminDiscountReferenceManyToMany from '../Security/Acl/AdminDiscountReferenceManyToMany';
import InvoicedUserDiscountReferenceManyToMany from '../Security/Acl/InvoicedUserDiscountReferenceManyToMany';
import FixedPriceUserDiscountReferenceManyToMany from '../Security/Acl/FixedPriceUserDiscountReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITransactionDiscountAttributes extends IModelAttributes {
	transactionId: string;
	discountId: string;

	transaction: Models.TransactionEntity | Models.ITransactionEntityAttributes;
	discount: Models.DiscountEntity | Models.IDiscountEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TransactionDiscount')
export default class TransactionDiscount
	extends Model
	implements ITransactionDiscountAttributes {
	public static acls: IAcl[] = [
		new VisitorsDiscountReferenceManyToMany(),
		new UserDiscountReferenceManyToMany(),
		new StaffDiscountReferenceManyToMany(),
		new ManagerDiscountReferenceManyToMany(),
		new AdminDiscountReferenceManyToMany(),
		new InvoicedUserDiscountReferenceManyToMany(),
		new FixedPriceUserDiscountReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public transactionId: string;

	@observable
	@attribute()
	public discountId: string;

	@observable
	@attribute({ isReference: true })
	public transaction: Models.TransactionEntity;

	@observable
	@attribute({ isReference: true })
	public discount: Models.DiscountEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITransactionDiscountAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.transactionId) {
				this.transactionId = attributes.transactionId;
			}
			if (attributes.discountId) {
				this.discountId = attributes.discountId;
			}

			if (attributes.transaction) {
				if (attributes.transaction instanceof Models.TransactionEntity) {
					this.transaction = attributes.transaction;
					this.transactionId = attributes.transaction.id;
				} else {
					this.transaction = new Models.TransactionEntity(attributes.transaction);
					this.transactionId = this.transaction.id;
				}
			} else if (attributes.transactionId !== undefined) {
				this.transactionId = attributes.transactionId;
			}

			if (attributes.discount) {
				if (attributes.discount instanceof Models.DiscountEntity) {
					this.discount = attributes.discount;
					this.discountId = attributes.discount.id;
				} else {
					this.discount = new Models.DiscountEntity(attributes.discount);
					this.discountId = this.discount.id;
				}
			} else if (attributes.discountId !== undefined) {
				this.discountId = attributes.discountId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
