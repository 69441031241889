import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';
import RouteMapProjection from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapProjection';
import RouteMapPoint from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapPoint';
import {
	RouteMapRenderableType,
} from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapRenderableObject';
import RouteMapHandler from '../RouteMapHandler';
import alertToast from 'Util/ToastifyUtils';
import { themeStore } from 'Models/ThemeStore';
import { RouteMapText } from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapText';

const CIRCLE_SIZE = 60;
const CIRCLE_PADDING = 10;
const CIRCLE_BORDER_WIDTH = 10;

const ICON_SIZE = CIRCLE_SIZE - CIRCLE_PADDING;

const MIN_ICON_SCALE = undefined;

const CIRCLE_BACKGROUND_COLOR = themeStore?.textColourOnPrimary;
const CIRCLE_BORDER_COLOR = themeStore?.config?.brandColourPrimary;
const CIRCLE_FONT_COLOR = themeStore?.config?.brandColourPrimary;

const HOVER_CIRCLE_BACKGROUND_COLOR = themeStore?.config?.brandColourPrimary;
const HOVER_CIRCLE_BORDER_COLOR = themeStore?.config?.brandColourPrimary;
const HOVER_CIRCLE_FONT_COLOR = themeStore?.textColourOnPrimary;

const SELECTED_CIRCLE_BACKGROUND_COLOR = themeStore?.config?.brandColourPrimary2;
const SELECTED_CIRCLE_BORDER_COLOR = themeStore?.config?.brandColourPrimary2;
const SELECTED_CIRCLE_FONT_COLOR = themeStore?.textColourOnPrimary;

const ADDRESS_BACKGROUND_COLOUR = themeStore?.config?.brandColourPrimary2;
const ADDRESS_FONT_COLOUR = themeStore?.textColourOnPrimary;

export default abstract class RouteMapIcon {
	protected readonly mapHandler: RouteMapHandler;
	protected readonly foregroundCanvas: RouteMapCanvas;

	protected readonly projectionHandler: RouteMapProjection;

	protected location: RouteMapPoint;
	protected projectedLocation: RouteMapPoint;

	protected isMouseOver: boolean;
	protected isSelected: boolean;

	protected locationName: string;
	protected locationAddress: string;

	protected locationNameText: RouteMapText;
	protected locationAddressText: RouteMapText;

	protected firstRender: boolean = true;

	constructor(mapHandler: RouteMapHandler) {
		this.mapHandler = mapHandler;
		this.foregroundCanvas = mapHandler.getForegroundCanvas();
		this.projectionHandler = mapHandler.getProjection();

		this.initText();
	}

	private initText() {
		this.locationNameText = new RouteMapText(this.mapHandler, this.locationName);
		this.locationNameText.setFontStyles('Be Vietnam Pro', 40, 'black');
		this.locationNameText.setBorder('white', 10);

		this.locationAddressText = new RouteMapText(this.mapHandler, this.locationAddress);
		this.locationAddressText.setFontStyles('Be Vietnam Pro', 40, ADDRESS_FONT_COLOUR);
		this.locationAddressText.setBackground(ADDRESS_BACKGROUND_COLOUR, 20, 20);
	}

	public updateScale(): void {
		this.projectedLocation = this.projectionHandler.projectPoint(this.location);

		const nameLocation = new RouteMapPoint(
			this.projectedLocation.getX() + this.getCircleSize(),
			this.projectedLocation.getY() + this.getCircleSize());
		this.locationNameText.setText(this.locationName);
		this.locationNameText.setLocation(nameLocation);
		this.locationNameText.updateScale();

		const addressLocation = new RouteMapPoint(
			this.projectedLocation.getX(),
			this.projectedLocation.getY() + this.getCircleSize() + this.scaleValue(40));
		this.locationAddressText.setText(this.locationAddress);
		this.locationAddressText.setLocation(addressLocation);
		this.locationAddressText.updateScale();
	}

	public isAtLocation(x: number, y: number): boolean {
		if (!this.projectedLocation) {
			return false;
		}

		const addressOver = this.isSelected && this.locationAddressText.isAtPoint(new RouteMapPoint(x, y));

		const circleSize = this.getCircleSize() + CIRCLE_BORDER_WIDTH;
		return addressOver
			|| this.distance(x, y, this.projectedLocation.getX(), this.projectedLocation.getY()) < circleSize;
	}

	public mouseOver(): void {
		if (this.isMouseOver) {
			return;
		}

		this.isMouseOver = true;
		this.mapHandler.requestRender();
	}

	public mouseOut(): void {
		if (!this.isMouseOver) {
			return;
		}

		this.isMouseOver = false;
		this.mapHandler.requestRender();
	}

	public click(point: RouteMapPoint): void {
		if (this.isSelected) {
			if (this.locationAddressText.isAtPoint(point)) {
				this.copyAddressToClipboard();
				return;
			}

			this.blur();
			return;
		}

		this.isSelected = true;
		this.mapHandler.requestRender();
	}

	public blur(): void {
		// Only blur if the icon was selected
		if (!this.isSelected) {
			return;
		}
		this.isSelected = false;
		this.isMouseOver = false;
		this.mapHandler.requestRender();
	}

	private copyAddressToClipboard() {
		const element = document.createElement('input');
		element.value = this.locationAddress;

		element.select();
		element.setSelectionRange(0, 99999);

		navigator?.clipboard?.writeText(element.value).then(() => {
			alertToast('Copied to clipboard');
		}).catch(e => {
			console.error('Unable to copy to clipboard', e);
		});
	}

	protected renderIcon(icon: number): boolean {
		const ctx = this.foregroundCanvas.getContext();
		ctx.save();

		const iconSize = this.getIconSize();

		ctx.font = `${iconSize}px lightning-icons`;
		ctx.fillStyle = this.getCircleFontColor();

		const result = this.renderText(String.fromCodePoint(icon), 'lightning-icons', this.projectedLocation);

		ctx.restore();

		return result;
	}

	protected getIconSize() {
		return this.scaleValue(ICON_SIZE, MIN_ICON_SCALE);
	}

	protected getTextMeasurements(text: string): TextMetrics {
		const ctx = this.foregroundCanvas.getContext();
		return ctx.measureText(text);
	}

	protected renderText(text: string, font: string, location: RouteMapPoint): boolean {
		if (this.firstRender && !this.isFontLoaded(font, text)) {
			return true;
		}
		this.firstRender = false;

		const metrics = this.getTextMeasurements(text);
		const x = location.getX() - metrics.width / 2;
		const y = location.getY() + metrics.actualBoundingBoxAscent / 2;

		this.foregroundCanvas.getContext().fillText(text, x, y);
		return false;
	}

	protected renderCircle(): boolean {
		const location = this.projectedLocation;
		const ctx = this.foregroundCanvas.getContext();

		ctx.save();

		ctx.fillStyle = this.getCircleBorderColor();
		ctx.lineWidth = 10;

		const circleSize = this.getCircleSize();

		ctx.beginPath();
		ctx.arc(
			location.getX(),
			location.getY(),
			circleSize + this.scaleValue(CIRCLE_BORDER_WIDTH),
			0,
			2 * Math.PI);
		ctx.fill();

		ctx.fillStyle = this.getCircleBackgroundColor();
		ctx.beginPath();
		ctx.arc(location.getX(), location.getY(), circleSize, 0, 2 * Math.PI);
		ctx.fill();

		ctx.restore();

		return false;
	}

	protected renderLocation(): boolean {
		this.locationNameText?.render();
		return false;
	}

	protected renderAddress(): boolean {
		this.locationAddressText?.render();

		return false;
	}

	/**
	 * Checks if the icon font has loaded by measuring the size of the text with two different fonts. If the sizes are not
	 * the same, the font has been loaded.
	 */
	protected isFontLoaded(fontName: string, text: string): boolean {
		const ctx = this.foregroundCanvas.getContext();

		ctx.save();

		ctx.font = `${this.getIconSize()}px ${fontName}`;
		const firstMeasure = ctx.measureText(text);

		ctx.font = `${this.getIconSize()}px serif`;
		const secondMeasure = ctx.measureText(text);

		ctx.restore();

		return Math.abs(firstMeasure.width - secondMeasure.width) >= this.scaleValue(2);
	}

	private distance(x1: number, y1: number, x2: number, y2: number): number {
		return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
	}

	getType(): RouteMapRenderableType {
		return 'icon';
	}

	private getCircleBorderColor() {
		if (this.isSelected) {
			return SELECTED_CIRCLE_BORDER_COLOR;
		}

		if (this.isMouseOver) {
			return HOVER_CIRCLE_BORDER_COLOR;
		}

		return CIRCLE_BORDER_COLOR;
	}

	private getCircleBackgroundColor() {
		if (this.isSelected) {
			return SELECTED_CIRCLE_BACKGROUND_COLOR;
		}

		if (this.isMouseOver) {
			return HOVER_CIRCLE_BACKGROUND_COLOR;
		}

		return CIRCLE_BACKGROUND_COLOR;
	}

	private getCircleFontColor() {
		if (this.isSelected) {
			return SELECTED_CIRCLE_FONT_COLOR;
		}

		if (this.isMouseOver) {
			return HOVER_CIRCLE_FONT_COLOR;
		}

		return CIRCLE_FONT_COLOR;
	}

	private getCircleSize() {
		return this.scaleValue(CIRCLE_SIZE, MIN_ICON_SCALE);
	}

	private scaleValue(value: number, minScale?: number) {
		return this.projectionHandler.scaleSize(value, minScale);
	}
}
