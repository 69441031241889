import moment from 'moment';
import { TicketsTabTrip } from 'Services/Api/_HumanWritten/BookingWizardDataService';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { FerryTripEntity } from 'Models/Entities';
import { FerryTripAvailability } from './CapacityCalculationUtils';

export type AvailabilityCSSClass =
	'departed'
	| 'closed'
	| 'no-availability'
	| 'high-availability'
	| 'low-availability'
	| 'hidden';

export function getTripTimeFromTicketTabTrip(ferryTrip: TicketsTabTrip): string {
	const arrivalTime = findEndTripTime(ferryTrip.tripTimeTakenMinutes, ferryTrip.startDate);
	return (`${formatTime(ferryTrip.startDate)} - ${formatTime(arrivalTime)}`);
}

export function formatTime(date: Date): string {
	const militaryTime = whiteLabelStore.config.timeFormat === 'TWENTY_FOUR_HOUR_TIME';
	return militaryTime ? moment(date).format('HH:mm') : moment(date).format('h:mma');
}

export function findEndTripTime(numOfMinutes : number, departureDateTime: Date): Date {
	return moment(departureDateTime).add(numOfMinutes, 'minutes').toDate();
}

export function getTripTimeFromFerryTrip(ferryTrip: FerryTripEntity): string {
	if (!ferryTrip.departureDateTime) {
		return '';
	}

	return formatTime(ferryTrip.departureDateTime);
}

export function getCSSClassForTicketsTabTripCardStatus(
	ferryTrip: TicketsTabTrip,
	availability: FerryTripAvailability,
	vehicleTrip: boolean,
): AvailabilityCSSClass {
	// calculates the average percentage of availability of vehicle and passenger of the ferry trip
	let availabilityPercent;

	if (vehicleTrip) {
		availabilityPercent = ((availability.vehicle / availability.totalVehicle) * 100);
	} else {
		availabilityPercent = ((availability.passenger / ferryTrip.passengerSpacesTotal) * 100);
	}
	if (ferryTrip.startDate < new Date()) {
		return 'departed';
	}
	if (ferryTrip.isClosed) {
		return 'closed';
	}
	if (ferryTrip.isHidden) {
		return 'hidden';
	}
	if (availabilityPercent === 0
		|| (!vehicleTrip && availability.passenger === 0)
		|| (vehicleTrip && availability.vehicle < 1)) {
		return 'no-availability';
	}
	if (availabilityPercent >= 50) {
		return 'high-availability';
	}

	return 'low-availability';
}

export function getCSSClassForFerryScheduleTripCardStatus(
	ferryTrip: FerryTripEntity,
	availability: FerryTripAvailability,
): AvailabilityCSSClass {
	// calculates the average percentage of availability of vehicle and passenger of the ferry trip
	const useVehicleAvailability = whiteLabelStore.defaultBookingType === 'vehicle' && availability.totalVehicle > 0;

	let availabilityPercent = (
		(availability.vehicle / availability.totalVehicle) * 100
	);

	if (!useVehicleAvailability) {
		availabilityPercent = (
			(availability.passenger / availability.totalPassengers) * 100
		);
	}

	if (!ferryTrip.departureDateTime || (ferryTrip.departureDateTime < new Date())) {
		return 'departed';
	}

	if (ferryTrip.closed) {
		return 'closed';
	}

	if (ferryTrip.hidden) {
		return 'hidden';
	}
	if (availabilityPercent <= 0 || availability.passenger === 0
		|| (useVehicleAvailability && availability.vehicle < 1)) {
		return 'no-availability';
	}
	if (availabilityPercent >= 50) {
		return 'high-availability';
	}

	return 'low-availability';
}
