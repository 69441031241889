import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import UpdatePassword from 'Views/Components/_HumanWritten/UpdatePassword/UpdatePassword';

export interface UpdatePasswordLocationState {
	email: string;
	name: string;
}

function UpdatePasswordPage(props: RouteComponentProps) {
	const {
		match,
		location,
		history,
		staticContext,
	} = props;

	return (
		<>
			<NavigationWrapper
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			{!location.state && <UpdatePassword />}
			{location.state && (
				<SecuredPage groups={['Super Administrators', 'Admin', 'Manager', 'Staff']}>
					<UpdatePassword userInfo={location.state as UpdatePasswordLocationState | undefined} />
				</SecuredPage>
			)}
		</>
	);
}

export default UpdatePasswordPage;
