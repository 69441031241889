import { BookingEntity } from 'Models/Entities';
import { PATH_TO_DEPARTURE_DATETIME_OF_BOOKING } from 'Services/CustomGql/BookingEntityType';
import moment from 'moment';
import { bookingStatusOptions } from 'Models/Enums';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { getBookingExpandString } from './FetchBookingsUtils';
import { isNullOrUndefined } from '../../TypeGuards';

export interface Bookings {
	totalBookingCount: number,
	bookings: BookingEntity[];
}

export async function FetchUserBookings(options: {
	customerId: string,
	skip: number,
	upcoming?: boolean,
	expandString?: string,
	totalBookingCount?: number
}): Promise<Bookings> {
	const {
		customerId,
		skip,
		upcoming,
		expandString,
		totalBookingCount,
	} = options;

	const conditionArgs: IWhereCondition<BookingEntity>[][] = [[{
		path: 'userId',
		comparison: 'equal',
		value: customerId,
	}]];

	// Need to order by departure DateTime, then created date (in case there are multiple
	// with the same departure DateTime).
	// Legacy Id is a fallback because migrated booking can all have the same created DateTime
	// and we don't want the fetch to keep returning differently ordered bookings each time.
	const orderByArgs = [
		{ path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING, descending: !upcoming },
		{ path: 'created', descending: true },
		{ path: 'id', descending: true },
	];

	// Considered a past booking if it has a CANCELLED status or has departed (and is either BOOKED or CANCELLED status)
	if (!upcoming) {
		conditionArgs.push(
			[
				{
					path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
					comparison: 'lessThanOrEqual',
					value: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
				},
				{
					path: 'bookingStatus',
					comparison: 'equal',
					value: bookingStatusOptions.CANCELLED,
				},
			],
		);
	}
	// Considered an upcoming booking if it has BOOKED status and hasn't departed
	if (upcoming) {
		conditionArgs.push(
			[{
				path: PATH_TO_DEPARTURE_DATETIME_OF_BOOKING,
				comparison: 'greaterThan',
				value: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
			}],
		);
		conditionArgs.push(
			[{
				path: 'bookingStatus',
				comparison: 'equal',
				value: bookingStatusOptions.BOOKED,
			}],
		);
	}
	try {
		const results = await BookingEntity.fetchAndCount<BookingEntity>(
			{
				args: conditionArgs as IWhereCondition<BookingEntity>[][],
				orderBy: orderByArgs,
				skip: skip,
				take: 5,
			},
			expandString ?? getBookingExpandString('user-bookings'),
			'list',
		);

		let count = totalBookingCount;

		if (isNullOrUndefined(count)) {
			const fetchAndCount = await BookingEntity.fetchAndCount<BookingEntity>(
				{
					args: conditionArgs as IWhereCondition<BookingEntity>[][],
					orderBy: orderByArgs,
				},
				'',
				'list',
			);
			count = fetchAndCount.count;
		}

		return {
			totalBookingCount: count,
			bookings: results.data,
		} as Bookings;
	} catch (e) {
		// console.error(e);
		return {
			totalBookingCount: 0,
			bookings: [],
		} as Bookings;
	}
}
