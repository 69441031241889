import React, { useEffect, useState } from 'react';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { UserInvoicesCollection } from './UserInvoiceListCollection';
import {
	fetchUserInvoices,
	UserInvoicesCountDto,
} from '../../../../Services/Api/_HumanWritten/InvoiceService';
import { getLoggedInUser } from '../../../../__tests__/Factory/MockUseStore';

export interface UserInvoiceListProps {
	userId?: string;
}

export function UserInvoiceListComponent({
	userId,
}: UserInvoiceListProps) {
	const perPage = 10;
	const [invoices, setInvoices] = useState<UserInvoicesCountDto | null>(null);
	const [pageNum, setPageNum] = useState<number>(0);

	useEffect(() => {
		setInvoices(null);
		if (isNotNullOrUndefined(userId) || isNotNullOrUndefined(getLoggedInUser()?.id)) {
			fetchUserInvoices(userId as string, pageNum * perPage, perPage).then(x => {
				setInvoices(x.data as UserInvoicesCountDto);
			});
		}
	}, [pageNum, userId]);

	return (
		<div className="user-invoice-list__container">
			<UserInvoicesCollection
				invoices={invoices?.invoices ?? null}
				totalInvoiceCount={invoices?.count ?? 0}
				perPage={perPage}
				pageNum={pageNum}
				setPageNum={setPageNum}
			/>
		</div>
	);
}
