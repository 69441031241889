import useAsync from 'Hooks/useAsync';
import { getPaymentTabTotalAmount } from 'Services/Api/_HumanWritten/TransactionService';

export function usePaymentAmount(
	isEventBooking: boolean,
	transactionId?: string,
	confirmationTokenId?: string,
	triggerRecalculation?: boolean,
) {
	return useAsync(async () => getPaymentTabTotalAmount(isEventBooking, transactionId), [confirmationTokenId, triggerRecalculation]);
}
