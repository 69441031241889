import * as React from 'react';
import { useEffect, useState } from 'react';
import { WaitlistItemEntity } from 'Models/Entities';
import { getAllWaitlistItems } from 'Services/Api/_HumanWritten/WaitlistService';
import WaitlistTable from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/CustomTables/WaitlistTable';
import If from 'Views/Components/If/If';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { RenderAddToWaitListModal } from 'Views/Components/_HumanWritten/Waitlist/AddToWaitListModal';

export interface WaitlistTabProps {
	ferryTripId: string;
	hasInitialCargo: boolean;
}

export const WaitlistTabComponent = ({
	ferryTripId,
	hasInitialCargo = true,
}: WaitlistTabProps) => {
	const [waitlistItems, setWaitlistItems] = useState<WaitlistItemEntity[] | null>(null);
	const [refresh, setRefresh] = useState<boolean>(false);

	useEffect(() => {
		getAllWaitlistItems(ferryTripId).then(x => {
			setWaitlistItems(x.data as WaitlistItemEntity[]);
		});
	}, [refresh]);

	return (
		<div className="custom-sidebar__tab-content waitlist-tab">
			<div className="header__container">
				<h6>{`View waitlist (${waitlistItems?.length ?? '?'} items)`}</h6>
				<Button
					display={Display.Outline}
					colors={Colors.Black}
					onClick={async () => {
						await RenderAddToWaitListModal(
							ferryTripId,
							hasInitialCargo,
							undefined,
							undefined,
							undefined,
							() => setRefresh(!refresh),
							true,
						);
					}}
				>
					Add to waitlist
				</Button>
			</div>
			<If condition={waitlistItems !== null}>
				<WaitlistTable
					waitlistItems={waitlistItems as WaitlistItemEntity[]}
					setWaitlitsItems={setWaitlistItems}
				/>
			</If>
			<If condition={waitlistItems === null}>
				<LottieSpinner />
			</If>
		</div>
	);
};
