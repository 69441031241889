import * as React from 'react';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';
import { showModal } from './Base/BaseModalContents';
import { BookingSummaryAddon } from '../BookingSummaryDto';
import { CheckInBookingOverviewDto } from '../CheckIn/FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { GetModalContentForCheckIn } from '../CheckIn/FerryCheckIn/CheckInList/CheckInListRow';
import { EventCheckInBookingOverviewDto } from '../CheckIn/EventCheckIn/EventCheckInEntities/EventCheckInOverviewDto';
import { GetModalContentForEventCheckIn } from '../CheckIn/EventCheckIn/CheckInList/EventCheckInListRow';

export interface EventCheckInScanConfirmModalContentsProps extends Pick<DualActionModalContentProps, 'onConfirm'> {
	booking?: EventCheckInBookingOverviewDto;
}

export function EventCheckInScanConfirmModalContents({
	onConfirm,
	booking,
}: EventCheckInScanConfirmModalContentsProps) {
	const title = 'Confirm booking?';

	const body = (
		<div>
			{GetModalContentForEventCheckIn(booking)}
		</div>
	);

	return (
		<DualActionModalContent
			title={title}
			body={body}
			onConfirm={onConfirm}
			fixedCancelWidth
			autoClose
		/>
	);
}

export function showEventCheckInScanConfirm(props: EventCheckInScanConfirmModalContentsProps) {
	showModal({
		key: 'check-in-scan-confirm-booking',
		content: <EventCheckInScanConfirmModalContents {...props} />,
	});
}
