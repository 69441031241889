import * as React from 'react';
import Icon from '../Icon/Icon';

export type informationBoxStatus = 'error' | 'success' | 'warning' | 'information';
export interface InformationBoxProps {
	header: string;
	body: string;
	status: informationBoxStatus;
}

export function InformationBox({
	header,
	body,
	status,
}: InformationBoxProps) {
	return (
		<div className={`information-box__container ${status}_colour`}>
			<div>
				<Icon name="info" classname="info_icon" />
			</div>
			<div>
				<p><strong>{header}</strong></p>
				<p>{body}</p>
			</div>
		</div>
	);
}
