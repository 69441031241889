/* eslint-disable react/react-in-jsx-scope */
import { store } from 'Models/Store';
import {
	AlterationExpirationDto,
	discardAlterations,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';
import { clearFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import {
	clearBookingWizardData,
	clearOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { clearStorageTransactionPrice } from 'Util/_HumanWritten/CartPriceSessionStorage';

export default async function handleNavigation(bookingId: string) {
	return confirmModal(
		'Cancel changes?',
		// eslint-disable-next-line react/react-in-jsx-scope
		<div className="edit-booking-modal">
			<h5>This will discard any changes you have made.</h5>
		</div>,
		{
			cancelText: 'Back',
			confirmText: 'Yes, cancel',
		},
	).then(async () => {
		await discardAlterations({ bookingIds: [bookingId], systemExpired: false } as AlterationExpirationDto);
		clearFerryBookingTransactionIdFromStorage();
		clearBookingWizardData();
		clearOldBookingWizardData();
		clearStorageTransactionPrice();
		return true;
	}).catch(() => {
		return false;
	});
}
