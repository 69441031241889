import * as React from 'react';
import classNames from 'classnames';
import { Flex } from 'Views/Components/Flex/Flex';
import If from 'Views/Components/If/If';
import { Text } from 'Views/Components/Text/Text';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { transformMobileNumber } from '../CheckInUtils';
import AddOnsFilterRowItems from './AddOnsFilterRowItems';
import { Tag } from '../CheckInList/Tag';

export interface AddOnsFilterListRowProps {
	booking: CheckInBookingOverviewDto;
}

export function AddOnsFilterListRow(props: AddOnsFilterListRowProps) {
	const {
		booking,
	} = props;

	return (
		<Flex className={classNames('check-in-list__row')}>
			<Flex direction="col" flex={1} gap="xxxs" className="p-sm">
				<Flex gap="xxs">
					<Text
						weight="600"
						color="high-emphasis"
						size="sm"
						wordBreak="break-all"
					>
						<span className="mr-xxs fullname">
							{booking.getFullName()}
						</span>
						<If condition={booking.firstTimeOnRoute}>
							<Tag
								text="NEW"
								tagClassName="tag--new"
							/>
						</If>
						<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
							<Tag
								text={booking?.user?.userDisplayName?.displayName}
								customStyles={{ background: booking?.user?.userDisplayName?.tagColour }}
							/>
						</If>
					</Text>
				</Flex>
				<Text size="sm" color="gray">
					<a
						href={`tel:${transformMobileNumber(booking)}`}
						className="mobile hide-underline"
					>
						{transformMobileNumber(booking)}
					</a>
				</Text>
				<Flex flexWrap="wrap" className="margin-right-sm">
					<AddOnsFilterRowItems booking={booking} />
				</Flex>
			</Flex>
		</Flex>
	);
}
