import * as React from 'react';
import { observer } from 'mobx-react';
import { Flex } from 'Views/Components/Flex/Flex';
import { Text } from 'Views/Components/Text/Text';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { runInAction } from 'mobx';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { DisplayType } from 'Views/Components/Models/Enums';
import { FerryTripEntity } from 'Models/Entities';
import { useEffect, useState } from 'react';
import If from 'Views/Components/If/If';
import { stringIsEmpty } from 'Util/TypeGuards';
import { CommunicationTabContactsCount } from 'Models/Entities/FerryTripEntity';
import { getTripContactCount } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import {
	TripNewDepartureEmailPreviewErrors,
	TripNewDepartureEmailPreviewState,
} from 'Hooks/useTripNewDepartureEmailPreview';

export interface TripNewDepartureEmailPreviewProps {
	state: TripNewDepartureEmailPreviewState;
	errors: TripNewDepartureEmailPreviewErrors;
	ferryTrip: FerryTripEntity;
}

function TripNewDepartureEmailPreview({ ferryTrip, state, errors }: TripNewDepartureEmailPreviewProps) {
	const { type } = state;
	const [smsCount, setSmsCount] = useState<number>(1);
	const [tripContactsCount, setTripContactsCount] = useState<CommunicationTabContactsCount>({
		mobileNumberCount: 0,
		emailAddressesCount: 0,
	});

	useEffect(() => {
		getTripContactCount(ferryTrip.id).then(x => {
			setTripContactsCount(x as CommunicationTabContactsCount);
		});
	}, []);

	useEffect(() => {
		setSmsCount(Math.max(Math.ceil(state.body.length / 153), 1));
	}, [state.body]);

	if (type === 'loading') {
		return (
			<Flex className="trip-new-departure-email-placeholder">
				<LottieSpinner />
			</Flex>
		);
	}

	if (type === 'error') {
		return <Text>Error</Text>;
	}

	return (
		<Flex className="new-trip-departure-modal-content" direction="col">
			<Text>
				Customise the message that will go out to customers about the trip updates.
			</Text>
			<p className="input-heading">
				Subject header <span className="red">*</span>
			</p>
			<TextField
				model={state}
				modelProperty="header"
				label="Subject header"
				labelVisible={false}
				className="m-none"
				errors={errors.header}
				onAfterChange={() => {
					runInAction(() => {
						delete errors.header;
					});
				}}
			/>
			<p className="input-heading">
				Body <span className="red">*</span>
			</p>
			<TextArea
				model={state}
				modelProperty="body"
				labelVisible={false}
				label="Body"
				textAreaProps={{ rows: 5 }}
				className="m-none"
				errors={errors.body}
				onAfterChange={() => {
					runInAction(() => {
						delete errors.body;
					});
				}}
			/>
			<p className={`sms-count ${state.sendSms ? 'show' : 'hide'}`}>{smsCount} credit(s)</p>
			<p className="input-heading communication-method">
				Select communication method  <span className="red">*</span>
			</p>
			<div className="checkbox-container">
				<Checkbox
					id="send-email-input"
					className="send-email-input"
					model={state}
					modelProperty="sendEmail"
					displayType={DisplayType.BLOCK}
					label={`Send Email (${tripContactsCount?.emailAddressesCount ?? '?'})`}
					isDisabled={false}
					onAfterChecked={(event, checked) => {
						if (checked || state.sendSms) {
							runInAction(() => {
								delete errors.communicationType;
							});
						}
					}}
				/>
				<Checkbox
					id="send-sms-input"
					className="send-sms-input"
					model={state}
					modelProperty="sendSms"
					displayType={DisplayType.BLOCK}
					label={`Send SMS (${tripContactsCount?.mobileNumberCount ?? '?'})`}
					isDisabled={false}
					onAfterChecked={(event, checked) => {
						if (checked || state.sendSms) {
							runInAction(() => {
								delete errors.communicationType;
							});
						}
					}}
				/>
			</div>
			<If condition={!stringIsEmpty(errors.communicationType)}>
				<p className="red">{errors.communicationType}</p>
			</If>
		</Flex>
	);
}

export default observer(TripNewDepartureEmailPreview);
