import gql from 'graphql-tag';
import { UserEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { getLikeArgument } from 'Util/FetchUtils';
import alertToast from 'Util/ToastifyUtils';
import { HasCondition, IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import {
	addPlusToNumber, convertToInternationalNumber,
	convertToNationalNumber,
	removePlusFromNumber,
} from 'Validators/Functions/HumanWritten/Phone';

interface QueryResult {
	userEntitys: UserEntity[];
}
interface QueryProps {
	has: HasCondition<UserEntity>[][];
	conditions: IWhereCondition<UserEntity>[][];
	take: number;
}
const query = gql`
	query customerByNameEmailPhone($has: [[HasConditionType]], $conditions: [[WhereExpressionGraph]], $take: Int) {
		userEntitys(has: $has, conditions: $conditions, take: $take) {
			id
			firstName
			lastName
			phone
			email
			disabled
			latestVehicles {
				id
				cargoIdentification
			}
			userDisplayName {
				displayName
			}
		}
	}
`;

/**
 * Returns user entities (specifically only customers) where the searchTerm matches full name, email or phone.
 * @param searchTerm The input value to compare with user full name, email or phone.
 * @param id Optional user id to be included in the results.
 */
export async function searchCustomersByNameEmailPhone(searchTerm: string, id?: string, take = 10) {
	// Fetches only customer users
	const has: HasCondition<UserEntity> = {
		path: 'userGroups' as any,
		negate: true,
		conditions: [[
			{ path: 'Role.Name', comparison: 'equal', value: 'Admin' },
			{ path: 'Role.Name', comparison: 'equal', value: 'Staff' },
			{ path: 'Role.Name', comparison: 'equal', value: 'Manager' },
		]],
	};

	const compareFields = ['fullName', 'email'];
	const condition: IWhereCondition<UserEntity>[] = compareFields
		.map(x => getLikeArgument<UserEntity>(x, searchTerm));
	const phoneSearchTerms = getPhoneSearchTerms(searchTerm);
	phoneSearchTerms.forEach(x => {
		condition.push(x);
	});

	const requests = [
		store.apolloClient.query<QueryResult, QueryProps>({
			query: query,
			variables: {
				has: [[has]],
				conditions: [condition],
				take: id ? take - 1 : take,
			},
			fetchPolicy: 'network-only',
		}),
	];

	if (id) {
		requests.push(
			store.apolloClient.query<QueryResult, QueryProps>({
				query: query,
				variables: {
					conditions: [[{ path: 'id', comparison: 'equal', value: id }]],
					has: [[has]],
					take: 1,
				},
				fetchPolicy: 'network-only',
			}),
		);
	}

	const [response, idResponse] = await Promise.all(requests);

	if (
		response.error || response.errors
		|| (idResponse && (idResponse.error || idResponse.errors))
	) {
		alertToast('Something went wrong while fetching customers', 'error');
		return [];
	}

	const returnValue = idResponse
		? idResponse.data.userEntitys
			.concat(response.data.userEntitys)
			.filter((v, i, a) => {
				// Exclude duplicates
				return a.findIndex(x => x.id === v.id) === i;
			})
		: response.data.userEntitys;

	return returnValue;
}

export function getPhoneSearchTerms(searchTerm: string) {
	const list = [] as IWhereCondition<UserEntity>[];

	let numbersOnlyPhonePattern = searchTerm;
	let internationalPhonePattern = searchTerm;

	// Match Australian numbers (e.g. 04 or +61)
	const matchAustralianNationalNumber = new RegExp('^04').test(searchTerm);
	const matchAustralianInternationalNumber = new RegExp('^\\+61').test(searchTerm);

	// Match non-Australian numbers (e.g. +1) or a number that doesn't match any of the above scenarios
	const matchInternationalNumber = new RegExp('^\\+[0-9]').test(searchTerm);
	const matchOtherNumber = new RegExp('^[0-9]').test(searchTerm);

	if (matchAustralianNationalNumber) {
		internationalPhonePattern = convertToInternationalNumber(internationalPhonePattern);
	} else if (matchAustralianInternationalNumber) {
		numbersOnlyPhonePattern = convertToNationalNumber(numbersOnlyPhonePattern);
	} else if (matchInternationalNumber) {
		numbersOnlyPhonePattern = removePlusFromNumber(searchTerm);
	} else if (matchOtherNumber) {
		internationalPhonePattern = addPlusToNumber(searchTerm);
	}

	list.push(
		{
			path: 'phone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${internationalPhonePattern}%`,
		},
	);

	list.push(
		{
			path: 'phone',
			comparison: 'like',
			case: 'INVARIANT_CULTURE_IGNORE_CASE',
			value: `%${numbersOnlyPhonePattern}%`,
		},
	);
	return list;
}
