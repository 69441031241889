import { FerryTripImportResponse } from 'Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';
import * as React from 'react';
import { useState } from 'react';
import { Divider } from 'semantic-ui-react';
import { delayPromiseAll } from 'Util/_HumanWritten/DelayUtils';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { CloseIconButton } from 'Views/Components/_HumanWritten/CloseIconButton/CloseIconButton';
import { LottieLoadingBar } from 'Views/Components/_HumanWritten/Lottie/LottieLoadingBar';
import { FerryTripImporterModal } from 'Views/Components/_HumanWritten/Modal/FerryTripImporterModal';
import {
	downloadSampleFerryTripCsv,
	validateFerryTripCsv,
} from 'Services/Api/_HumanWritten/FerryTripEntityService';

export interface FerryTripImportSideBarContentsProps{
	file?: File;
	setFile: (file?: File) => void;
	importResponse?: FerryTripImportResponse;
	setImportResponse: (res?: FerryTripImportResponse) => void;
	onClose?: () => void;
	onContinue?: () => void;
}

/**
 * ID ref for hidden input element, used for selecting csv file.
 */
const INPUT_ID = 'ferry-trip-input-csv';

export function FerryTripImportSideBarContents({
	file,
	setFile,
	importResponse,
	setImportResponse,
	onClose,
	onContinue,
}: FerryTripImportSideBarContentsProps) {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	/**
	 * Prompt the file explorer for user to select the csv file.
	 *
	 * Usually, clicking the input element will trigger the prompt, but styling an input element to look like a button
	 * is more work. So we hide the input element and use a button to handle it instead.
	 */
	const openFileExplorer = (): void => {
		const inputCsv = document.getElementById(INPUT_ID);
		inputCsv?.click();
	};

	const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		e.preventDefault();
		if (!e.currentTarget.files) {
			return;
		}

		if (e.target.files) {
			setFile(e.target.files[0]);
		}

		// Reset html input file
		e.target.value = '';
	};

	const onImport = () => {
		if (file === undefined) {
			return;
		}

		setLoading(true);

		delayPromiseAll<FerryTripImportResponse>(1000, validateFerryTripCsv(file))
			.then(result => {
				const validatedResponse: FerryTripImportResponse = result[0];
				setImportResponse(validatedResponse);
				if (validatedResponse.hasErrors()) {
					setLoading(false);
					setShowModal(true);
				} else if (onContinue !== undefined) {
					onContinue();
				}
			})
			.catch(err => {
				if (err.response?.status === 401) {
					alertToast(
						<>You are <strong>not authorized</strong></>,
						'error',
					);
				} else {
					alertToast(
						<>Something went wrong while processing <strong>{file.name}</strong></>,
						'error',
					);
				}
				setLoading(false);
			});
	};

	const onModalClose = () => {
		setImportResponse(undefined);
	};

	const onModalReset = () => {
		setFile(undefined);
		onModalClose();
	};

	const onModalContinue = () => {
		if (onContinue)	{
			onContinue();
		}
	};

	return (
		<>
			<FerryTripImporterModal
				showModal={showModal}
				importResponse={importResponse}
				onRequestClose={onModalClose}
				onReset={onModalReset}
				onContinue={onModalContinue}
			/>

			<div className="ferry-trip-import-side-bar__header">
				<div className="header">
					<p className="header-1">
						Import trips
					</p>
					<p className="header-2">
						Import trips via CSV upload
					</p>
				</div>
				<CloseIconButton onClick={onClose} />
			</div>

			<Divider className="ferry-trip-import-side-bar__divider" />

			<div className="ferry-trip-import-side-bar__body">
				<If condition={!loading}>
					<div className="ferry-trip-import-side-bar__item">
						<input
							id={INPUT_ID}
							type="file"
							accept=".csv"
							onChange={onFileChange}
						/>
						<p className="item__label title">
							File upload
						</p>
						<div className="item__row">
							<Button
								display={Display.Solid}
								colors={Colors.Alternate}
								onClick={openFileExplorer}
							>
								Choose file
							</Button>
							<p id="file-name">{file?.name ?? 'No file chosen'}</p>
							<If condition={file !== undefined}>
								<CloseIconButton onClick={() => setFile(undefined)} />
							</If>
						</div>
					</div>

					<div className="ferry-trip-import-side-bar__item">
						<p className="item__label title">
							File upload preview
						</p>
						<p className="item__label">
							Download a sample file here. Your file must be in this format.
						</p>
						<Button
							display={Display.Solid}
							colors={Colors.Black}
							onClick={downloadSampleFerryTripCsv}
							buttonProps={{ id: 'download-sample' }}
						>
							Download sample
						</Button>
					</div>
				</If>
			</div>

			<div className="ferry-trip-import-side-bar__footer">
				<If condition={loading}>
					<div className="ferry-trip-import-side-bar__loading">
						<div className="header-2">
							Importing trips...
						</div>
						<LottieLoadingBar />
					</div>
				</If>
				<div className="ferry-trip-import-side-bar__actions">
					<Button
						display={Display.Outline}
						colors={Colors.Black}
						onClick={onClose}
					>
						Cancel
					</Button>
					<If condition={!loading}>
						<Button
							display={Display.Solid}
							colors={Colors.Black}
							disabled={file === undefined}
							onClick={onImport}
							buttonProps={{ id: 'import' }}
						>
							Import
						</Button>
					</If>
				</div>
			</div>
		</>
	);
}
