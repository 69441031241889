/**
 * Typically used for sending image data to server.
 */
export const convertCanvasToBase64 = (canvas: HTMLCanvasElement): string => {
	try {
		return canvas.toDataURL('image/png').split(',')[1];
	} catch (err) {
		console.error(err);
		throw new Error('Something went wrong with convertCanvasToBase64');
	}
};
