import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { BookingEntity, EventDetailEntity } from 'Models/Entities';
import { EVENT_IMAGE, FEATURE_IMAGE_5_URL } from 'Constants';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { downloadInvoice } from 'Services/Api/_HumanWritten/InvoiceService';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import * as queryString from 'querystring';
import EventBookingSummaryCard from '../EventsBookingWizard/WizardSteps/Cart/EventBookingSummaryCard';
import useStore from 'Hooks/useStore';
import {
	EventBookingPricesDto,
	FetchEventBookingsForCartAsync,
	GetPrices,
} from 'Services/Api/_HumanWritten/BookingService/EventsBookingService';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { setTransactionPriceInStorage } from 'Util/_HumanWritten/CartPriceSessionStorage';
import { LottieSpinner } from '../Lottie/LottieSpinner';
import { GetEventDetails } from 'Services/Api/_HumanWritten/EventDetailEntityService';

export interface BookingSuccessProps {
	routerProps: RouteComponentProps;
}

export function EventBookingSuccessOuterWrapper({ routerProps }: BookingSuccessProps) {
	const [booking, setBooking] = useState<BookingEntity | null>(null);
	const [prices, setPrices] = useState<EventBookingPricesDto | null>(null);
	const [eventDetails, setEventDetails] = useState<EventDetailEntity | null>(null);

	const queryParams = routerProps.location.search.substring(1);
	const params = queryString.parse(queryParams);
	const transactionId = params.id?.toString() ?? '';

	const response = FetchEventBookingsForCartAsync(transactionId);

	const getPricesForCart = () => {
		if (isNotNullOrUndefined(transactionId) && booking !== null) {
			GetPrices(transactionId).then(x => {
				setPrices(x);
				setTransactionPriceInStorage(x.totalPrice);
			});
		}
	};

	// Get the prices for the cart on the initial load, only if prices are null
	useEffect(() => {
		if (prices === null) {
			getPricesForCart();
		}
	}, [booking]);

	useEffect(() => {
		if (isNotNullOrUndefined(booking)) {
			const eventId = booking?.alterations[0]?.ferryTrip?.eventDetailId;
			if (isNotNullOrUndefined(eventId)) {
				GetEventDetails(eventId).then(x => {
					setEventDetails(new EventDetailEntity(x));
				});
			}
		}
	}, [booking]);

	useEffect(() => {
		if (response.type === 'data' && response.data !== null) {
			setBooking(response.data);
		}
	}, [setBooking, response.data, response.type]);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	if (isNullOrUndefined(booking) || isNullOrUndefined(eventDetails)) {
		return <></>;
	}

	return (
		<div className="body-content booking-success">
			<img className="background-img" src={EVENT_IMAGE + eventDetails.headerImage?.id} alt="bg-img" />
			<NavigationWrapper className="booking-success__nav nav--light" {...routerProps} />
			<div className="booking-success__content">
				<div className="content__header">
					<h2>Booking confirmed!</h2>
					<h5>Your event is booked</h5>
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						buttonProps={{ id: 'download-tax-invoice' }}
						onClick={() => {
							downloadInvoice(transactionId, true);
						}}
					>
						<Icon name="download" />
						Download tax invoice
					</Button>
				</div>
				<div className="content__body">
					<p className="body__digital-ferry-ticket"><strong>All event tickets are digital.</strong></p>
					<p className="body__qr-code-info">Show us the QR code on the day of the event.</p>
					<h2 className="body__title">
						Booking summary:
					</h2>
					<div className="trip-summary-container">
						{/* Parent classname is required to inherit styles for trip card */}
						<EventBookingSummaryCard
							eventDetails={eventDetails}
							transactionId={transactionId ?? undefined}
							onUpdateWizardData={() => {}}
							booking={booking}
							refresh={() => {}}
							onClearGiftCertificate={() => {}}
							tripSummaryLocation="booking-success"
							prices={prices}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
