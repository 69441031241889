import * as React from 'react';
import { BaseSpaceModal } from './BaseSpaceModal';

export interface VehicleModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void
}

export function VehicleModal({
	showModal,
	onClickSelect,
	onClickCross,
}: VehicleModalProps) {
	return (
		<BaseSpaceModal
			showModal={showModal}
			onClickCross={onClickCross}
			onClickSelect={onClickSelect}
			textToDisplay={[
				'Your vehicle length exceeds the space available on the ferry.',
			]}
		/>
	);
}
