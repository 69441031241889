import { TransactionEntity } from '../../Models/Entities';

export async function fetchTransactionById(transactionId: string) {
	return TransactionEntity.fetchAndCount<TransactionEntity>(
		{
			id: transactionId,
		},
		'secondsUntilExpiry',
	);
}
