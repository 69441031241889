/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsPassengerTypeEntity from 'Models/Security/Acl/VisitorsPassengerTypeEntity';
import UserPassengerTypeEntity from 'Models/Security/Acl/UserPassengerTypeEntity';
import StaffPassengerTypeEntity from 'Models/Security/Acl/StaffPassengerTypeEntity';
import ManagerPassengerTypeEntity from 'Models/Security/Acl/ManagerPassengerTypeEntity';
import AdminPassengerTypeEntity from 'Models/Security/Acl/AdminPassengerTypeEntity';
import InvoicedUserPassengerTypeEntity from 'Models/Security/Acl/InvoicedUserPassengerTypeEntity';
import FixedPriceUserPassengerTypeEntity from 'Models/Security/Acl/FixedPriceUserPassengerTypeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
/* eslint-disable max-len */
import { PassengerTypeKey } from 'Models/Enums';
import { store } from '../Store';
// % protected region % [Add any further imports here] end

export interface IPassengerTypeEntityAttributes extends IModelAttributes {
	order: number;
	singularName: string;
	pluralName: string;
	quantity: number;
	abbreviation: string;
	description: string;
	tooltipInfo: string;
	tooltipEnabled: boolean;
	disabled: boolean;
	key: string;

	passengerDetails: Array<
		| Models.PassengerDetailEntity
		| Models.IPassengerDetailEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PassengerTypeEntity', 'Passenger Type')
// % protected region % [Customise your entity metadata here] end
export default class PassengerTypeEntity extends Model
	implements IPassengerTypeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsPassengerTypeEntity(),
		new UserPassengerTypeEntity(),
		new StaffPassengerTypeEntity(),
		new ManagerPassengerTypeEntity(),
		new AdminPassengerTypeEntity(),
		new InvoicedUserPassengerTypeEntity(),
		new FixedPriceUserPassengerTypeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Order'] on begin
	/**
	 * This determines the order in which it appears in a list of passenger types.
	 */
	@Validators.Integer()
	@observable
	@attribute<PassengerTypeEntity, number>()
	@CRUD({
		name: 'Order',
		description: 'The order in which the passenger type will appear on the frontend of the application. E.g. 1 '
			+ 'means it will be at the top of the list of all passenger types.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Singular Name'] on begin
	/**
	 * The passenger name used when referenced as a singular item.
	 */
	@Validators.Required()
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Singular name',
		description: 'The singular name for the passenger type. If there is only 1 selected this wording will be '
			+ 'used. E.g. Adult.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public singularName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Singular Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Plural Name'] on begin
	/**
	 * The passenger name used when referenced as a multiple items. Fallback should use the singular name.
	 */
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Plural name',
		description: 'The plural name for the passenger type. If there is more than 1 selected this wording will be '
			+ 'used. E.g. Adults.',
		displayType: 'textfield',
		order: 30,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public pluralName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Plural Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] on begin
	/**
	 * The number of passengers per passenger type.
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<PassengerTypeEntity, number>()
	@CRUD({
		name: 'Number of spaces taken',
		description: 'The number of passenger spaces this passenger type will take. E.g. a family passenger type '
			+ 'could take 4 passenger spaces.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public quantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] on begin
	/**
	 * The short-hand naming convention for this passenger type.
	 */
	@Validators.Required()
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Abbreviation',
		description: 'The short term to refer to this passenger on screens with limited space. E.g. `A` could be short for Adults.',
		displayType: 'textfield',
		order: 50,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public abbreviation: string;
	// % protected region % [Modify props to the crud options here for attribute 'Abbreviation'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * This appears as the second line in the counter when adding/editing a booking.
	 */
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Description',
		description: 'A description of the passenger type to help users understand the difference between them all. '
			+ 'E.g. you could mention the age range that this passenger type applies to.',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		headerColumn: true,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Info'] on begin
	/**
	 * The text that appears in the tooltip.
	 */
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Tooltip info',
		description: 'The information that will appear in the tooltip for this passenger type. This is a good area '
			+ 'to add more information about the passenger type. E.g. `A student must present their student Id on the '
			+ 'day of travel.`',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public tooltipInfo: string;
	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Info'] end

	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Enabled'] on begin
	/**
	 * True displays the tooltip for the passenger type. False hides it.
	 */
	@observable
	@attribute<PassengerTypeEntity, boolean>()
	@CRUD({
		name: 'Tooltip enabled',
		description: 'If checked, a tooltip will appear next to the passenger type with the information specified '
			+ 'in `Tooltip info`. Otherwise the tooltip will not show.',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public tooltipEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Tooltip Enabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] on begin
	/**
	 * True will hide this passenger type throughout the application.
	 */
	@observable
	@attribute<PassengerTypeEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		description: 'If checked, this passenger type will not appear on the frontend of the application.',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
		headerColumn: true,
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Key'] on begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<PassengerTypeEntity, string>()
	@CRUD({
		name: 'Key',
		displayType: store.isSuper ? 'textfield' : 'hidden',
		order: 100,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
		isReadonly: true,
	})
	public key: string;
	// % protected region % [Modify props to the crud options here for attribute 'Key'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] off begin
		name: 'Passenger Details',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.PassengerDetailEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'passengerDetails',
			oppositeEntity: () => Models.PassengerDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Passenger Detail'] end
	})
	public passengerDetails: Models.PassengerDetailEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	/**
	 * This is used to determine if the `disabled` attribute has changed.
	 */
	public readonly disabledSnapshot: boolean;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPassengerTypeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] on begin
		if (attributes?.disabled !== undefined) {
			// Save the original value of disabled attribute
			this.disabledSnapshot = attributes?.disabled;
		}
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPassengerTypeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.singularName !== undefined) {
				this.singularName = attributes.singularName;
			}
			if (attributes.pluralName !== undefined) {
				this.pluralName = attributes.pluralName;
			}
			if (attributes.quantity !== undefined) {
				this.quantity = attributes.quantity;
			}
			if (attributes.abbreviation !== undefined) {
				this.abbreviation = attributes.abbreviation;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.tooltipInfo !== undefined) {
				this.tooltipInfo = attributes.tooltipInfo;
			}
			if (attributes.tooltipEnabled !== undefined) {
				this.tooltipEnabled = attributes.tooltipEnabled;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.key !== undefined) {
				this.key = attributes.key;
			}
			if (attributes.passengerDetails !== undefined && Array.isArray(attributes.passengerDetails)) {
				for (const model of attributes.passengerDetails) {
					if (model instanceof Models.PassengerDetailEntity) {
						this.passengerDetails.push(model);
					} else {
						this.passengerDetails.push(new Models.PassengerDetailEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = '';
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			passengerDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public get passengerTypeKey(): PassengerTypeKey {
		switch (this.key) {
			case 'A':
				return 'A';
			case 'B':
				return 'B';
			case 'C':
				return 'C';
			case 'D':
				return 'D';
			case 'E':
				return 'E';
			case 'F':
				return 'F';
			case 'G':
				return 'G';
			default:
				return 'H';
		}
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PassengerTypeEntity.prototype, 'created');
CRUD(modifiedAttr)(PassengerTypeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
