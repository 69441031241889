import React from 'react';

interface UpdatePasswordTitleProps {
	name?: string;
}

function UpdatePasswordTitle({ name }: UpdatePasswordTitleProps) {
	if (name) {
		return (
			<h3>Update {`${name}'s`} password</h3>
		);
	}
	return (
		<h3>Update password</h3>
	);
}

export default UpdatePasswordTitle;
