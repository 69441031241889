import { useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { updatePassword, updatePasswordByStaff } from 'Services/Api/AccountService';
import alertToast from 'Util/ToastifyUtils';
import { UpdatePasswordLocationState } from 'Views/Pages/UpdatePasswordPage';

interface IUpdatePasswordStateErrors {
	password?: string;
	confirmPassword?: string;
	[attr: string]: string | undefined;
}

interface IUpdatePasswordState {
	loading: boolean;
	password: string;
	confirmPassword: string;
	errors: IUpdatePasswordStateErrors;
}

export function useUpdatePassword(userInfo?: UpdatePasswordLocationState) {
	const state = useLocalStore<IUpdatePasswordState>(() => ({
		loading: false,
		password: '',
		confirmPassword: '',
		errors: {},
	}));

	const onUpdatePasswordClicked = action(async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (state.loading) {
			return;
		}

		const errors: IUpdatePasswordStateErrors = {};

		if (!state.password) {
			errors.password = 'Required';
		} else if (state.password.length < 12) {
			errors.password = 'Must be at least 12 characters';
		} else if (!state.confirmPassword) {
			errors.confirmPassword = 'Required';
		} else if (state.confirmPassword !== state.password) {
			errors.confirmPassword = 'Does not match';
		}

		state.errors = errors;

		if (Object.keys(state.errors).length === 0) {
			try {
				runInAction(() => {
					state.loading = true;
				});
				if (userInfo) {
					// When userInfo is provided, it means it is
					// a staff user editing a customer's password
					await updatePasswordByStaff({
						newPassword: state.password,
						username: userInfo.email,
					});
				} else {
					await updatePassword(state.password);
				}
				alertToast('Successfully updated password', 'success');
				runInAction(() => {
					state.loading = false;
				});
				window.history.back();
			} catch {
				alertToast(
					'Failed to update password',
					'error',
				);
				runInAction(() => {
					state.loading = false;
				});
			}
		}
	});

	const onAfterChange = action((key: keyof IUpdatePasswordStateErrors) => {
		state.errors[key] = '';
	});

	return {
		state,
		onUpdatePasswordClicked,
		onAfterChange,
	};
}
