import RouteMapHandler from '../RouteMapHandler';
import RouteMapPoint from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapPoint';
import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';
import RouteMapProjection from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapProjection';

const TEXT_EDGE_PADDING = 20;
const TEXT_LINE_HEIGHT = 5; // This will be added to the height of each line

const TEXT_OVERHANG_BEFORE_BREAK = 50;

const MIN_TEXT_SCALE = undefined;

export class RouteMapText {
	private readonly canvas: RouteMapCanvas;
	private readonly projectionHandler: RouteMapProjection;

	private text: string;
	private location: RouteMapPoint;

	private fontStyles: [string, number, string] = ['serif', 10, 'white'];
	private backgroundStyles?: [string, number, number] = undefined;
	private borderStyles?: [string, number] = undefined;

	// The values that are used for the rendering of the text
	private projectedLocation: RouteMapPoint;
	private lineHeight: number;
	private textLines: string[] = [];

	private firstRender: boolean = true;

	constructor(mapHandler: RouteMapHandler, text: string) {
		this.projectionHandler = mapHandler.getProjection();
		this.canvas = mapHandler.getForegroundCanvas();
		this.text = text;
	}

	public isAtPoint(point: RouteMapPoint) {
		if (!this.projectedLocation) {
			return false;
		}

		if (this.backgroundStyles !== undefined) {
			const paddingX = this.projectionHandler.scaleSize(this.backgroundStyles[1]);
			const paddingY = this.projectionHandler.scaleSize(this.backgroundStyles[2]);

			return this.projectedLocation.getX() - paddingX <= point.getX()
				&& this.projectedLocation.getX() + this.getWidth() + paddingX >= point.getX()
				&& this.projectedLocation.getY() - paddingY <= point.getY()
				&& this.projectedLocation.getY() + this.getHeight() + paddingY >= point.getY();
		}

		return this.projectedLocation.getX() + this.getWidth() >= point.getX()
			&& this.projectedLocation.getX() <= point.getX()
			&& this.projectedLocation.getY() + this.getHeight() >= point.getY()
			&& this.projectedLocation.getY() <= point.getY();
	}

	public setText(text: string) {
		this.text = text;

		this.updateScale();
	}

	public setLocation(location: RouteMapPoint) {
		this.location = location;

		this.updateScale();
	}

	public updateScale() {
		if (this.location === undefined) {
			return;
		}

		this.projectedLocation = this.location.copy();

		const canvas = this.canvas.getCanvas();
		const widthOverHang = canvas.width - TEXT_EDGE_PADDING;

		this.lineHeight = this.getTextMeasurements(this.text).actualBoundingBoxAscent + TEXT_LINE_HEIGHT;

		this.textLines = [];
		let tempLine = '';
		let totalOverHang = 0;
		this.text.split(' ').forEach(word => {
			const measurements = this.getTextMeasurements(`${tempLine}${word}`);
			const overHangAmount = (this.projectedLocation.getX() + measurements.width) - widthOverHang;

			if (overHangAmount > 0
				&& overHangAmount < TEXT_OVERHANG_BEFORE_BREAK
				&& this.projectedLocation.getX() - overHangAmount > 0) {
				// Add the text to the current line, but push the projected location over by the overhang amount
				tempLine += `${word} `;
				totalOverHang = Math.max(totalOverHang, overHangAmount);
			} else if (overHangAmount > 0) {
				// Create a new line because the text is too long
				this.textLines.push(tempLine);
				tempLine = `${word} `;
			} else {
				// Add the text to the current line because it fits
				tempLine += `${word} `;
			}
		});

		// Push the final line onto the array
		if (tempLine !== '') {
			this.textLines.push(tempLine);
		}
		this.projectedLocation.setX(this.projectedLocation.getX() - totalOverHang);

		// Adjust y position if text goes beyond canvas bottom
		const totalHeight = this.textLines.length * this.lineHeight;
		if (this.projectedLocation.getY() + totalHeight > canvas.height) {
			this.projectedLocation.setY(canvas.height - totalHeight);
		}
	}

	public setBackground(backgroundColour: string, backgroundPaddingX: number, backgroundPaddingY: number) {
		this.backgroundStyles = [backgroundColour, backgroundPaddingX, backgroundPaddingY];
	}

	public setBorder(borderColour: string, borderWidth: number) {
		this.borderStyles = [borderColour, borderWidth];
	}

	public setFontStyles(fontFamily: string, fontSize: number, fontColour: string) {
		this.fontStyles = [fontFamily, fontSize, fontColour];

		this.updateScale();
	}

	public getHeight() {
		return this.textLines.length * this.lineHeight;
	}

	public getWidth() {
		return this.textLines
			.reduce((width, line) => Math.max(width, this.getTextMeasurements(line).width), 0);
	}

	public render() {
		if (!this.projectedLocation) {
			return;
		}

		// To fix the issue when sometimes the font has not been loaded properly when the first scaling was done
		if (this.firstRender) {
			this.updateScale();
			this.firstRender = false;
		}

		if (this.backgroundStyles !== undefined) {
			this.drawBackground();
		}

		if (this.borderStyles !== undefined) {
			this.drawBorder();
		}

		this.drawText();
	}

	private getTextMeasurements(text: string): TextMetrics {
		const ctx = this.canvas.getContext();

		ctx.save();

		this.enableFontStyles(ctx);
		const measurements = ctx.measureText(text);

		ctx.restore();

		return measurements;
	}

	private enableFontStyles(ctx: CanvasRenderingContext2D) {
		const [fontFace, fontSize, fontColour] = this.fontStyles;

		ctx.fillStyle = fontColour;
		ctx.font = `${this.projectionHandler.scaleSize(fontSize, MIN_TEXT_SCALE)}px ${fontFace}`;
	}

	private drawText() {
		const ctx = this.canvas.getContext();

		ctx.save();

		this.enableFontStyles(ctx);
		this.textLines.forEach((line, index) => {
			ctx.fillText(line,
				this.projectedLocation.getX(),
				this.projectedLocation.getY() + this.lineHeight * (index + 1));
		});

		ctx.restore();
	}

	private drawBackground() {
		if (this.backgroundStyles === undefined) {
			return;
		}

		const ctx = this.canvas.getContext();

		const [backgroundColour, backgroundPaddingX, backgroundPaddingY] = this.backgroundStyles;

		const paddingX = this.projectionHandler.scaleSize(backgroundPaddingX);
		const paddingY = this.projectionHandler.scaleSize(backgroundPaddingY);

		ctx.fillStyle = backgroundColour;
		ctx.fillRect(
			this.projectedLocation.getX() - paddingX,
			this.projectedLocation.getY() - paddingY,
			this.getWidth() + paddingX * 2,
			this.getHeight() + paddingY * 2);
	}

	private drawBorder() {
		if (this.borderStyles === undefined) {
			return;
		}
		const ctx = this.canvas.getContext();
		const [borderColor, borderWidth] = this.borderStyles;

		ctx.save();

		this.enableFontStyles(ctx);
		ctx.strokeStyle = borderColor;
		ctx.lineWidth = this.projectionHandler.scaleSize(borderWidth);

		this.textLines.forEach((line, index) => {
			ctx.strokeText(line,
				this.projectedLocation.getX(),
				this.projectedLocation.getY() + this.lineHeight * (index + 1));
		});

		ctx.restore();
	}
}
