import classNames from 'classnames';
import * as React from 'react';
import { QrReader } from 'react-qr-reader';
import { LottieSpinner } from '../Lottie/LottieSpinner';

export interface QrCodeScannerProps {
	onSuccess: (value: string) => void;
	value?: string;
	setValue: (value: string | undefined) => void;
	onError?: (value: any) => void;
	children?: React.ReactChild | React.ReactChildren;
	className?: string;
}

export function QrCodeScanner(props: QrCodeScannerProps) {
	const {
		onSuccess,
		onError,
		value,
		setValue,
		children,
		className,
	} = props;

	if (value) {
		return <>{children}</>;
	}

	const squareContraints = {
		aspectRatio: 1,
		facingMode: 'environment',
	};

	return (
		<QrReader
			className={classNames('reader', className)}
			constraints={squareContraints}
			onResult={(result: any, error: any) => {
				if (result?.text) {
					setValue(result.text);
					onSuccess(result.text);
					setValue(undefined);
				}
				if (!!error && onError) {
					onError(error);
				}
			}}
			// scanDelay={100}
		/>
	);
}
