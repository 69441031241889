/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsBookingCutoffEntity from 'Models/Security/Acl/VisitorsBookingCutoffEntity';
import UserBookingCutoffEntity from 'Models/Security/Acl/UserBookingCutoffEntity';
import StaffBookingCutoffEntity from 'Models/Security/Acl/StaffBookingCutoffEntity';
import ManagerBookingCutoffEntity from 'Models/Security/Acl/ManagerBookingCutoffEntity';
import AdminBookingCutoffEntity from 'Models/Security/Acl/AdminBookingCutoffEntity';
import InvoicedUserBookingCutoffEntity from 'Models/Security/Acl/InvoicedUserBookingCutoffEntity';
import FixedPriceUserBookingCutoffEntity from 'Models/Security/Acl/FixedPriceUserBookingCutoffEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBookingCutoffEntityAttributes extends IModelAttributes {
	hoursBeforeDeparture: number;
	percentageFee: number;
	bookingType: Enums.bookingType;
	cutoffType: Enums.cutoffType;
	moveToEarlierTime: boolean;
	moveToLaterTime: boolean;

	popupId?: string;
	popup?: Models.PopupEntity | Models.IPopupEntityAttributes;
	routes: Array<
		| Models.BookingCutoffRoute
		| Models.IBookingCutoffRouteAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('BookingCutoffEntity', 'Booking Cutoff')
// % protected region % [Customise your entity metadata here] end
export default class BookingCutoffEntity extends Model
	implements IBookingCutoffEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsBookingCutoffEntity(),
		new UserBookingCutoffEntity(),
		new StaffBookingCutoffEntity(),
		new ManagerBookingCutoffEntity(),
		new AdminBookingCutoffEntity(),
		new InvoicedUserBookingCutoffEntity(),
		new FixedPriceUserBookingCutoffEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Hours Before Departure '] on begin
	/**
	 * The number of hours before a ferry trip departs (do not apply pricing before this point)
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<BookingCutoffEntity, number>()
	@CRUD({
		name: 'Time before departure (hours)',
		description: 'The hours before departure that this booking cutoff will apply. E.g. if the time before '
			+ 'departure is 12hrs, any booking that falls within the 12hrs will have this cutoff applied '
			+ '(depending on whether it is a cancellation or alteration).',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public hoursBeforeDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Hours Before Departure '] end

	// % protected region % [Modify props to the crud options here for attribute 'Percentage Fee'] on begin
	/**
	 * The percentage value to calculate the fee based on the booking cost
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<BookingCutoffEntity, number>()
	@CRUD({
		name: 'Fee percentage',
		description: 'The percentage of the total booking cost that will be charged as a fee to the user if they '
			+ 'fall within this booking cutoff. E.g. 20 = 20%.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public percentageFee: number;
	// % protected region % [Modify props to the crud options here for attribute 'Percentage Fee'] end

	// % protected region % [Modify props to the crud options here for attribute 'Booking Type'] on begin
	/**
	 * The type of booking that the cut off entity applies to
	 */
	@Validators.Required()
	@observable
	@attribute<BookingCutoffEntity, Enums.bookingType>()
	@CRUD({
		name: 'Booking type',
		description: 'The type of booking that the user is editing or cancelling in order for this fee to apply to '
			+ 'their booking.',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.bookingTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.bookingTypeOptions),
		displayFunction: (attr: Enums.bookingType) => Enums.bookingTypeOptions[attr],
	})
	public bookingType: Enums.bookingType;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cutoff Type'] on begin
	/**
	 * The type of action the fee is applicable to
	 */
	@Validators.Required()
	@observable
	@attribute<BookingCutoffEntity, Enums.cutoffType>()
	@CRUD({
		name: 'Cutoff type',
		description: 'The cutoff type that this will apply to. E.g. if a user is altering their booking and they meet '
			+ 'the other criteria, they will be charged this fee.',
		displayType: 'enum-combobox',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.cutoffTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.cutoffTypeOptions),
		displayFunction: (attr: Enums.cutoffType) => Enums.cutoffTypeOptions[attr],
	})
	public cutoffType: Enums.cutoffType;
	// % protected region % [Modify props to the crud options here for attribute 'Cutoff Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Move To Earlier Time'] off begin
	@observable
	@attribute<BookingCutoffEntity, boolean>()
	@CRUD({
		name: 'Move To Earlier Time',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public moveToEarlierTime: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Move To Earlier Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Move To Later Time'] off begin
	@observable
	@attribute<BookingCutoffEntity, boolean>()
	@CRUD({
		name: 'Move To Later Time',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public moveToLaterTime: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Move To Later Time'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Popup'] on begin
		name: 'Popup',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.PopupEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'popups',
			oppositeEntity: () => Models.PopupEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Popup'] end
	})
	public popupId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public popup: Models.PopupEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Route'] on begin
		name: 'Route',
		description: 'The route that this fee applies to. It will only apply this fee to bookings that have a ferry '
			+ 'trip on the route. If no route is specified, it will apply to all routes.',
		displayType: 'reference-multicombobox',
		order: 60,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BookingCutoffRoute,
		optionEqualFunc: makeJoinEqualsFunc('routeId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'bookingCutoffEntity',
			oppositeEntityName: 'routeEntity',
			relationName: 'bookingCutoff',
			relationOppositeName: 'route',
			entity: () => Models.BookingCutoffEntity,
			joinEntity: () => Models.BookingCutoffRoute,
			oppositeEntity: () => Models.RouteEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Route'] end
	})
	public routes: Models.BookingCutoffRoute[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IBookingCutoffEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IBookingCutoffEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.hoursBeforeDeparture !== undefined) {
				this.hoursBeforeDeparture = attributes.hoursBeforeDeparture;
			}
			if (attributes.percentageFee !== undefined) {
				this.percentageFee = attributes.percentageFee;
			}
			if (attributes.bookingType !== undefined) {
				this.bookingType = attributes.bookingType;
			}
			if (attributes.cutoffType !== undefined) {
				this.cutoffType = attributes.cutoffType;
			}
			if (attributes.moveToEarlierTime !== undefined) {
				this.moveToEarlierTime = attributes.moveToEarlierTime;
			}
			if (attributes.moveToLaterTime !== undefined) {
				this.moveToLaterTime = attributes.moveToLaterTime;
			}
			if (attributes.popupId !== undefined) {
				this.popupId = attributes.popupId;
			}
			if (attributes.popup !== undefined) {
				if (attributes.popup === null) {
					this.popup = attributes.popup;
				} else if (attributes.popup instanceof Models.PopupEntity) {
					this.popup = attributes.popup;
					this.popupId = attributes.popup.id;
				} else {
					this.popup = new Models.PopupEntity(attributes.popup);
					this.popupId = this.popup.id;
				}
			}
			if (attributes.routes !== undefined && Array.isArray(attributes.routes)) {
				for (const model of attributes.routes) {
					if (model instanceof Models.BookingCutoffRoute) {
						this.routes.push(model);
					} else {
						this.routes.push(new Models.BookingCutoffRoute(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		routes {
			${Models.BookingCutoffRoute.getAttributes().join('\n')}
			route {
				${Models.RouteEntity.getAttributes().join('\n')}
				${Models.RouteEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		popup {
			${Models.PopupEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			routes: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'popup',
							'routes',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(BookingCutoffEntity.prototype, 'created');
CRUD(modifiedAttr)(BookingCutoffEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
