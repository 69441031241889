import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { stringNotEmpty } from 'Util/TypeGuards';
import { AdditionalBookingSectionEntity } from '../../../../../../Models/Entities';

export function filterFerryBookings(props: {
	bookings: CheckInBookingOverviewDto[],
	vehiclesOnly: boolean,
	forCheckIn: boolean,
	searchTerm?: string,
}) {
	const {
		bookings,
		vehiclesOnly,
		forCheckIn,
		searchTerm,
	} = props;

	let bookingsToFilter = bookings
		.filter(x => {
			if (!x.bookedSummary) {
				throw new Error('Booked summary missing');
			}
			if (vehiclesOnly) {
				return x.bookedSummary.cargoInfo || x.bookedSummary.towOnInfo;
			}
			return !(x.bookedSummary.cargoInfo || x.bookedSummary.towOnInfo);
		})
		.filter(x => {
			if (forCheckIn) {
				return !x.checkedIn;
			}
			return x.checkedIn;
		});

	if (stringNotEmpty(searchTerm)) {
		bookingsToFilter = findSearchTermMatch(searchTerm, bookings);
	}

	return bookingsToFilter;
}

export function filterFerryBookingsByAddOns(props: {
	bookings: CheckInBookingOverviewDto[],
	searchTerm?: string,
	addOnsToFilterBy?: AdditionalBookingSectionEntity[],
}) {
	const {
		bookings,
		searchTerm,
		addOnsToFilterBy,
	} = props;

	const addOnSectionIds = addOnsToFilterBy?.map(x => x.id);
	let bookingsToFilter = bookings;

	if (stringNotEmpty(searchTerm)) {
		bookingsToFilter = findSearchTermMatch(searchTerm, bookings);
	}
	if ((addOnsToFilterBy?.length ?? 0) > 0) {
		bookingsToFilter = bookingsToFilter.filter(x => {
			return x.bookedSummary.additionalBookingOptions
				.some(y => addOnSectionIds?.includes(y.option.additionalBookingSectionId));
		});
	}
	return bookingsToFilter;
}

const findSearchTermMatch = (searchTerm: string, bookings: CheckInBookingOverviewDto[]) => {
	const lowerCaseSearchTerm = searchTerm.toLowerCase();

	return bookings.filter(x => {
		return x.humanReadableId?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.bookedSummary?.driverFirstName?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.bookedSummary?.driverLastName?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.bookedSummary?.cargoInfo?.cargoIdentification?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.user?.firstName?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.user?.lastName?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.user?.phone?.toLowerCase()?.includes(lowerCaseSearchTerm)
			|| x.user?.email?.toLowerCase()?.includes(lowerCaseSearchTerm);
	});
};
