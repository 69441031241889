import * as React from 'react';
import {
	FerryTripEntity,
	IUserEntityAttributes,
	UserEntity,
} from 'Models/Entities';
import { BookingFormMode } from '../BookingForm/BookingForm';
import { observer, useLocalStore } from 'mobx-react';
import { CheckInBottomBar } from '../CheckInBottomBar';
import useStore from 'Hooks/useStore';
import { BOOKING_FORM_ID } from '../BookingForm/BookingFormUtils';
import useCheckInStore from 'Hooks/useCheckInStore';
import { DisableContinueState } from '../CheckInUtils';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { Colors } from 'Views/Components/Button/Button';
import useScreenSize from 'Hooks/useScreenSize';
import { Text } from 'Views/Components/Text/Text';
import If from 'Views/Components/If/If';
import classNames from 'classnames';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import BookingFormEdit from '../BookingForm/BookingFormEdit/BookingFormEdit';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export interface CheckInAddFormProps {}

function CheckInAddForm() {
	const checkInStore = useCheckInStore();
	const store = useStore();
	const routes = useCheckInRoutes();
	const state = useLocalStore<DisableContinueState>(() => ({
		continue: false,
	}));
	const { isIpadMiniPortrait, isMobile } = useScreenSize();

	// Ensure userId is provided
	if (!checkInStore.formState.userId) {
		store.routerHistory.replace(routes.selectCustomer);
	}

	// It is assumed the previous route was the /select-customer page
	// In that page, the details of the selected customer is stored in the formState
	// We want to create a copy of the details here to assign to the blank booking
	// To track user details vs driver details
	const userDetails = React.useMemo<Partial<IUserEntityAttributes>>(() => {
		return {
			id: checkInStore.formState.userId,
			firstName: checkInStore.formState.driverFirstName,
			lastName: checkInStore.formState.driverLastName,
			phone: checkInStore.formState.driverPhone,
			email: checkInStore.formState.email,
		};
	}, [checkInStore.formState]);

	const blankBooking = CheckInBookingOverviewDto.createBlank();
	blankBooking.user = new UserEntity(userDetails);
	blankBooking.bookedSummary!.ferryTrip = new FerryTripEntity({
		id: checkInStore.ferryTrip.id,
		routeId: checkInStore.ferryTrip.routeId,
		ferryId: checkInStore.ferryTrip.ferryId,
		departureDateTime: checkInStore.ferryTrip.departureDateTime,
		arrivalDateTime: checkInStore.ferryTrip.arrivalDateTime,
	});

	return (
		<>
			<div className="check-in__body">
				<If condition={!isIpadMiniPortrait}>
					<h4 className="check-in__body__title">
						Add booking
					</h4>
				</If>
				<div className="check-in__body__card">
					<If condition={isIpadMiniPortrait}>
						<Text
							size="md"
							weight="700"
							className={classNames('pt-sm', { 'pl-xs': isMobile, 'pl-mdlg': !isMobile })}
						>
							Add booking
						</Text>
					</If>
					<BookingFormEdit
						booking={blankBooking}
						mode={BookingFormMode.Add}
						disableState={state}
						className={classNames('pb-xxxl', { 'p-xs pt-mdlg': isMobile })}
						oneInputPerRow={isIpadMiniPortrait}
					/>
				</div>
			</div>
			<CheckInBottomBar
				closeBtnProps={{
					content: 'Back',
					onClick: () => store.routerHistory.replace(routes.selectCustomer),
				}}
				confirmBtnProps={{
					content: (
						<>
							Next
							<Icon name="arrow-right" />
						</>
					),
					type: 'submit',
					buttonProps: {
						// This will trigger onSubmit of form with id BOOKING_FORM_ID, unless default is prevented
						form: BOOKING_FORM_ID,
					},
					disabled: state.continue,
					colors: state.continue ? Colors.Disabled : Colors.Primary,
				}}
			/>
		</>
	);
}

export default observer(CheckInAddForm);
