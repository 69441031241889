/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import {
	Button,
	Display,
	Colors,
	Sizes,
} from '../Components/Button/Button';
import { action, observable, runInAction } from 'mobx';
import { store } from 'Models/Store';
import axios from 'axios';
import * as queryString from 'querystring';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import If from 'Views/Components/If/If';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import * as Models from '../../Models/Entities';
import _ from 'lodash';
import { Model } from 'Models/Model';
import { isRequired } from 'Util/EntityUtils';
import { getAttributeComponent } from 'Views/Components/CRUD/Attributes/AttributeFactory';
import alertToast from '../../Util/ToastifyUtils';
import { SERVER_URL } from 'Constants';
import { getErrorMessages } from 'Util/GraphQLUtils';
import Spinner from 'Views/Components/Spinner/Spinner';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { IUserEntity, UserFields } from 'Models/UserEntity';
// % protected region % [Add any extra imports or interfaces here] on begin
import { PropertyType } from 'Validators/Util';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import { Link } from 'react-router-dom';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { FEATURE_IMAGE_3_URL } from 'Constants';
import { checkIfInvalidName } from 'Util/StringUtils';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import {
	getBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
// % protected region % [Add any extra imports or interfaces here] end

interface IRegistrationState {
	errors: {
		selectedRegisterType?: string,
		[attr: string]: string | undefined,
	};
	selectedRegisterType: string,
	modelToRegister: Model & IUserEntity | null,
	emailSending: boolean,
	emailSent: boolean,
	// % protected region % [Add extra registration state properties here] off begin
	// % protected region % [Add extra registration state properties here] end
}

const getDefaultRegistrationState = (): IRegistrationState => ({
	selectedRegisterType: '',
	modelToRegister: null,
	errors: {},
	emailSending: false,
	emailSent: false,
	// % protected region % [Instantiate extra registration state properties here] off begin
	// % protected region % [Instantiate extra registration state properties here] end
});

// % protected region % [Customise class signature and class properties] on begin
export type registrationTypeType = 'standard' | 'during-booking';

@observer
export default class RegistrationPage extends React.Component<RouteComponentProps> {
	// % protected region % [Customise class signature and class properties] end
	@observable
	private registrationState: IRegistrationState = getDefaultRegistrationState();

	@observable
	private displayMode: 'select-type' | 'register' = 'register';

	private registerableEntities: {value: string, display: string}[] = [
		{ value: 'UserEntity', display: 'User' },
	];

	// % protected region % [Add extra constructor logic here] on begin
	@observable
	private wizardDataToken: string = '';

	@observable
	private registrationType: registrationTypeType = this.props.location.search.includes('booking-wizard')
		? 'during-booking'
		: 'standard';

	constructor(props: RouteComponentProps, context: any) {
		super(props, context);

		if (this.registerableEntities.length > 1) {
			this.displayMode = 'select-type';
		} else {
			this.displayMode = 'register';
			if (this.registerableEntities.length === 1) {
				this.registrationState.selectedRegisterType = this.registerableEntities[0].display;
				this.registrationState.modelToRegister = this.userEntityFactory(this.registerableEntities[0].value);
			}
		}
		this.autofillEmail();
	}

	// % protected region % [Add extra constructor logic here] end

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		document.title = this.registrationState.emailSent ? 'Verify email' : 'Register';
		const entityAttrs = this.getRegisterEntityAttributes();
		const registerNode = (
			<div className="body-content">
				<div className="register-container">
					<div className="feature-image">
						<img
							className="bg-img"
							alt="bg-img"
							src={FEATURE_IMAGE_3_URL}
						/>
					</div>
					<div className="navigation-container">
						<Link to="/login" className="icon-chevron-left icon-left back-navigation">
							Login
						</Link>
					</div>
					<form className="register" onSubmit={this.onSubmitRegisterClicked}>
						<h3>Register as a new user</h3>
						{entityAttrs}
						<ButtonGroup alignment={Alignment.HORIZONTAL} className="register-buttons btn-group">
							<Button
								type="submit"
								className="submit-register btn--primary"
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'submit_register' }}
							>
								Continue
							</Button>
						</ButtonGroup>
					</form>
				</div>
			</div>
		);

		const emailUser = this.registrationState.modelToRegister
			? this.registrationState.modelToRegister.email
			: 'the user';

		const emailSentMessageNode = (registrationType: registrationTypeType) => {
			return (
				<div className="body-content">
					<div className="register-container">
						<div className="feature-image">
							<img
								className="bg-img"
								alt="bg-img"
								src={FEATURE_IMAGE_3_URL}
							/>
						</div>
						<div className="registration registration-success">
							<h3>Check your inbox</h3>
							<h4>
								{/* eslint-disable-next-line max-len */}
								We&apos;ve sent an email to your inbox to verify your account. Once verified you can pick up where you left off.
							</h4>
						</div>
					</div>
				</div>
			);
		};

		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		contents = (
			<>
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{this.registrationState.emailSending && <LottieSpinner />}
				<If condition={!this.registrationState.emailSent}>
					<If condition={this.displayMode === 'register'}>
						{registerNode}
					</If>
				</If>
				<If condition={this.registrationState.emailSent}>
					{emailSentMessageNode(this.registrationType)}
				</If>
			</>
		);
		// % protected region % [Override contents here] end

		return contents;
	}

	private getRegisterEntityAttributes = () => {
		// % protected region % [Override getRegisterEntityAttributes here] on begin
		if (!this.registrationState.modelToRegister) {
			return null;
		}
		const attributeOptions = this.registrationState.modelToRegister.getAttributeCRUDOptions();
		const model = this.registrationState.modelToRegister;
		return attributeOptions
			.filter(attributeOption => {
				return isRequired(model, attributeOption.attributeName)
					|| (UserFields as string[]).indexOf(attributeOption.attributeName) >= 0;
			})
			.sort((a, b) => {
				if (a.order !== undefined && b.order !== undefined) {
					return a.order - b.order;
				}
				return -1;
			})
			.map(attributeOption => getAttributeComponent(
				attributeOption,
				model,
				model.getErrorsForAttribute(attributeOption.attributeName),
				EntityFormMode.CREATE,
				isRequired(
					model,
					attributeOption.attributeName,
				) || ['password', '_confirmPassword'].includes(attributeOption.attributeName),
				() => (model && model.hasValidationError)
					? runInAction(() => {
						model.validationErrors = {};
					})
					: undefined,
				() => ((model as Models.UserEntity).phone)
					? runInAction(() => {
						model.validationErrors = {};
					})
					: undefined,
			));
		// % protected region % [Override getRegisterEntityAttributes here] end
	};

	@action
	private onSubmitRegisterClicked = async (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onSubmitRegisterClicked here] on begin
		event.preventDefault();

		this.registrationState.errors = {};
		if (this.registrationState.modelToRegister) {
			await this.validate(this.registrationState.modelToRegister);
			if (this.registrationState.modelToRegister.hasValidationError) {
				ScrollToError('end');
				return;
			}
			this.submitRegister();
		}
		// % protected region % [Override onSubmitRegisterClicked here] end
	};

	@action
	private submitRegister = () => {
		// % protected region % [Override submitRegister here] on begin
		if (this.registrationState.modelToRegister) {
			const wizardDataToken = getBookingWizardDataCacheToken();
			const userType = this.registrationState.modelToRegister.getModelName();
			const data = {
				registrationModel: this.registrationState.modelToRegister.toJSON({ password: {} }),
				wizardDataToken: wizardDataToken,
			};
			let modifiedErrorMessage: string | null = null;
			this.registrationState.emailSending = true;

			axios.post(`${SERVER_URL}/api/register/${_.kebabCase(userType)}`, data)
				.then(() => {
					this.onRegistrationEmailSentSuccess();
				})
				.catch(response => {
					runInAction(() => {
						this.registrationState.emailSending = false;
					});
					const errorMessages = getErrorMessages(response).map((error: any) => (error.message));
					if (errorMessages[0].includes('Phone') || errorMessages[0].includes('Username')) {
						modifiedErrorMessage = 'An account with this phone number or email already exists';
					}
					alertToast((modifiedErrorMessage ?? errorMessages), 'error', 'Registration was not complete');
				});
		}
		// % protected region % [Override submitRegister here] end
	};

	@action
	private onCancelRegisterClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onCancelRegisterClicked here] off begin
		event.preventDefault();
		this.resetRegistration();
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/login?${!!redirect ? `redirect=${redirect}` : ''}`);
		// % protected region % [Override onCancelRegisterClicked here] end
	};

	@action
	private onTypeConfirmed = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onTypeConfirmed here] off begin
		event.preventDefault();

		this.registrationState.errors = {};
		if (this.registerableEntities.length > 1 && !this.registrationState.selectedRegisterType) {
			this.registrationState.errors.selectedRegisterType = 'You need to choose the registration type';
		}
		if (Object.keys(this.registrationState.errors).length === 0) {
			let entityToRegister = null;
			if (this.registerableEntities.length > 1 && !!this.registrationState.selectedRegisterType) {
				entityToRegister = this.registrationState.selectedRegisterType;
			} else if (this.registerableEntities.length === 1) {
				entityToRegister = this.registerableEntities[0].value;
			}
			this.displayMode = 'register';
			this.registrationState.modelToRegister = this.userEntityFactory(entityToRegister);
		}
		// % protected region % [Override onTypeConfirmed here] end
	};

	private userEntityFactory = (entityToRegister: string | null): Model & IUserEntity | null => {
		// % protected region % [Override userEntityFactory here] off begin
		switch (entityToRegister) {
			case 'UserEntity':
				return new Models.UserEntity();
			default:
				return null;
		}
		// % protected region % [Override userEntityFactory here] end
	};

	@action
	private onChangeUserType = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		// % protected region % [Override onChangeUserType here] off begin
		e.preventDefault();
		this.resetRegistration();
		this.displayMode = 'select-type';
		// % protected region % [Override onChangeUserType here] end
	};

	private resetRegistration = () => {
		// % protected region % [Override resetRegistration here] off begin
		this.registrationState = getDefaultRegistrationState();
		// % protected region % [Override resetRegistration here] end
	};

	@action
	private onRegistrationEmailSentSuccess = () => {
		// % protected region % [Override onRegistrationEmailSentSuccess here] on begin
		this.registrationState.emailSending = false;
		this.registrationState.emailSent = true;
		// % protected region % [Override onRegistrationEmailSentSuccess here] end
	};

	private onLoginClick = () => {
		// % protected region % [Override onLoginClick here] off begin
		const { redirect } = queryString.parse(this.props.location.search.substring(1));
		store.routerHistory.push(`/login?redirect=${redirect}`);
		// % protected region % [Override onLoginClick here] end
	};

	// % protected region % [Add class methods here] on begin
	@action
	private autofillEmail() {
		if (this.registrationState.modelToRegister) {
			this.registrationState.modelToRegister.email = store.tempEmail;
		}
	}

	@action
	private validate = async (model: Model & IUserEntity) => {
		await model.validate().then(action(() => {
			if (!model._confirmPassword) {
				// eslint-disable-next-line dot-notation
				model.validationErrors['_confirmPassword'] = {
					type: PropertyType.OWN,
					errors: {
						required: [
							'This field is required',
						],
					},
				};
			}

			if (!model.password) {
				// eslint-disable-next-line dot-notation
				model.validationErrors['password'] = {
					type: PropertyType.OWN,
					errors: {
						required: [
							'This field is required',
						],
					},
				};
			}
			const phone = (model as Models.UserEntity)?.phone ?? undefined;
			if (!IsValidPhone(phone)) {
				// eslint-disable-next-line dot-notation
				model.validationErrors['phone'] = {
					type: PropertyType.OWN,
					errors: {
						required: [
							'Invalid phone number',
						],
					},
				};
			}
			const firstName = (model as Models.UserEntity)?.firstName ?? undefined;
			if (isNotNullOrUndefined(firstName) && checkIfInvalidName(firstName)) {
				// eslint-disable-next-line dot-notation
				model.validationErrors['firstName'] = {
					type: PropertyType.OWN,
					errors: {
						required: [
							'No spaces. A-Z and \'-\' only.',
						],
					},
				};
			}
			const lastName = (model as Models.UserEntity)?.lastName ?? undefined;
			if (isNotNullOrUndefined(lastName) && checkIfInvalidName(lastName)) {
				// eslint-disable-next-line dot-notation
				model.validationErrors['lastName'] = {
					type: PropertyType.OWN,
					errors: {
						required: [
							'No spaces. A-Z and \'-\' only.',
						],
					},
				};
			}
		}));
	}
	// % protected region % [Add class methods here] end
}

// % protected region % [Add extra features here] off begin
// % protected region % [Add extra features here] end
