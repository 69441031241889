import If from '../../../../../If/If';
import TrailerCheckbox from './TrailerCheckbox/TrailerCheckbox';
import React from 'react';
import { UpdateWizardData } from './FerryFilterPassengers';
import { BookingWizardData } from '../../../BookingWizardData';
import { stringNotEmpty } from '../../../../../../../Util/TypeGuards';

export interface FerryFilterSubheadingProps {
	wizardData: BookingWizardData;
	onUpdateFilters: UpdateWizardData;
	onPassengerTab: boolean;
	onVehicleTab: boolean;
	passengerButtonSubheading: string;
	vehicleButtonSubheading: string;
	vehicleBookingRequiresAdult: boolean;
	trailersEnabled: boolean;
	trailerCheckboxSelected: boolean;
}

export function FerryFilterSubheading({
	wizardData,
	onUpdateFilters,
	onPassengerTab,
	onVehicleTab,
	passengerButtonSubheading,
	vehicleButtonSubheading,
	trailersEnabled,
	trailerCheckboxSelected,
}: FerryFilterSubheadingProps) {
	return (
		<>
			{onPassengerTab && stringNotEmpty(passengerButtonSubheading) && passengerButtonSubheading !== 'NO_LABEL' && (
				<div className="category-title-container">
					<p>{passengerButtonSubheading}</p>
				</div>
			)}

			{onVehicleTab && stringNotEmpty(vehicleButtonSubheading) && vehicleButtonSubheading !== 'NO_LABEL' && (
				<>
					<div className="category-title-container">
						<p>{vehicleButtonSubheading}</p>
					</div>
					<If condition={trailersEnabled}>
						<TrailerCheckbox
							hasTrailer={trailerCheckboxSelected}
							setHasTrailer={() => handleTrailerCheckbox(wizardData, onUpdateFilters)}
						/>
					</If>
				</>
			)}
		</>
	);
}

function handleTrailerCheckbox(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData) {
	const x: BookingWizardData = { ...wizardData };
	x.trailerCheckboxSelected = !x.trailerCheckboxSelected;
	onUpdateFilters(x);
}
