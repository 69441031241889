import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import useEventCheckInStore from 'Hooks/useEventCheckInStore';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import alertToast from 'Util/ToastifyUtils';
import { store } from 'Models/Store';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { runInAction } from 'mobx';
import { BookingFormMode } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/BookingForm/BookingForm';
import useAsync from 'Hooks/useAsync';
import { getEventBooking } from 'Services/Api/_HumanWritten/CheckInService';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import { DisableContinueState } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInUtils';
import EventBookingForm from '../EventBookingForm/EventBookingForm';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { GetModalContentForEventCheckIn } from '../CheckInList/EventCheckInListRow';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import {
	BOOKING_FORM_ID,
	CLOSE_BOOKING_FORM_ID,
} from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/BookingForm/BookingFormUtils';

export interface EventCheckInDetailsProps {
	bookingId: string;
	className?: string;
}

function EventCheckInDetails({
	bookingId,
	className,
}: EventCheckInDetailsProps) {
	const eventCheckInStore = useEventCheckInStore();
	const routes = useCheckInRoutes(true);
	const { state, responseType } = useEventCheckInDetails(bookingId);

	if (responseType === 'error') {
		alertToast('Could not find booking', 'error');
		store.routerHistory.replace(routes.base);
	}

	if (responseType === 'loading' || state.booking === undefined) {
		return (
			<LottieSpinner />
		);
	}

	const onClose = () => {
		store.routerHistory.replace(routes.base);
	};
	const CheckInConfirmation = async () => {
		if (isNotNullOrUndefined(state.booking)) {
			if (!state.booking.checkedIn) {
				return confirmModal('Confirm booking', GetModalContentForEventCheckIn(state.booking))
					.then(async () => {
						await executeCheckIn();
						return true;
					})
					.catch(() => {
						return false;
					});
			}
			await executeCheckIn();
			return true;
		}
		return false;
	};

	const executeCheckIn = async () => {
		if (isNotNullOrUndefined(state.booking)) {
			try {
				// This will update the booking in the backend and alert toast
				await eventCheckInStore.checkInBooking(state.booking!.id, !state.booking!.checkedIn);

				// Update the checkedIn status of the local state so that btn can switch between undo and check in
				runInAction(() => {
					if (state.booking) {
						state.booking.checkedIn = !state.booking?.checkedIn;
					}
					if (state.booking?.checkedIn) {
						PlayCheckInAudio();
					}
				});
			} catch (e) {
				console.error(e);
				alertToast('Check in unsuccessful', 'error');
			}
		}
	};

	return (
		<div className="check-in__sidebar">
			<div className="check-in__sidebar__body">
				<EventBookingForm
					className={className}
					booking={state.booking}
					checkIn={CheckInConfirmation}
				/>
			</div>
			<div className="check-in__sidebar__footer">
				<Button
					display={Display.Solid}
					colors={Colors.Secondary}
					sizes={Sizes.Medium}
					key="cancel"
					type="submit"
					buttonProps={{
						// This will trigger onSubmit of form with id CLOSE_BOOKING_FORM_ID
						form: CLOSE_BOOKING_FORM_ID,
					}}
					onClick={onClose}
				>
					Close
				</Button>
				<Button
					display={Display.Solid}
					colors={
						state.continue
							? Colors.Disabled
							: Colors.Primary
					}
					sizes={Sizes.Medium}
					key="confirm"
					type="submit"
					buttonProps={{
						// This will trigger onSubmit of form with id BOOKING_FORM_ID, unless default is prevented
						form: BOOKING_FORM_ID,
					}}
					onClick={() => store.routerHistory.push(`/bookings/${state.booking!.id}`)}
				>
					<If condition={state.mode === BookingFormMode.View}>
						View booking <Icon name="look" />
					</If>
				</Button>
			</div>
		</div>
	);
}

export default observer(EventCheckInDetails);

export interface EventCheckInDetailsState extends DisableContinueState {
	booking?: EventCheckInBookingOverviewDto;
	mode: BookingFormMode;
}

function useEventCheckInDetails(id: string) {
	const state = useLocalStore<EventCheckInDetailsState>(() => ({
		booking: undefined,
		mode: BookingFormMode.View,
		continue: false,
	}));

	const response = useAsync(() => getEventBooking(id), [id]);

	React.useEffect(() => {
		if (response.data) {
			const { data } = response.data;

			runInAction(() => {
				// Important to create new instance of booking entity to have access to class methods
				state.booking = new EventCheckInBookingOverviewDto(data);
			});
		}
	}, [response.data]);

	return {
		state,
		responseType: response.type,
	};
}
