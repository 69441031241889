import * as React from 'react';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import alertToast from 'Util/ToastifyUtils';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import If from 'Views/Components/If/If';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { DisplayType } from 'Views/Components/Models/Enums';
import { DateRangePicker } from 'Views/Components/DateRangePicker/DateRangePicker';
import CalendarSvg from 'images/calender.svg';
import moment from 'moment';
import InputWrapper, { InputType, LabelPositions } from 'Views/Components/Inputs/InputWrapper';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import { TimePicker } from 'Views/Components/TimePicker/TimePicker';
import {
	downloadAdditionalOptionReport,
	downloadCashReport,
	downloadCustomerReport,
	downloadEftposReport,
	downloadGiftCertificateCsv,
	downloadInvoiceReport,
	downloadOccupancyReport,
	downloadOperationReport,
	downloadRevenueReportCsv,
	downloadTakingsBreakdownReportCsv,
} from 'Services/Api/_HumanWritten/ReportService';
import { ReportType } from 'Views/Components/_HumanWritten/Reports/Reports';

export type dateFilterType = 'created' | 'departure';

export interface dateTimeSelectionModel {
	selectionType: dateFilterType,
	startDate: Date,
	endDate?: Date,
	startTime?: Date,
	endTime?: Date,
}

export interface ReportsDateSelectionModalProps {
	dateTimeSelectionModel: dateTimeSelectionModel;
	selectedReportType?: ReportType;
}

export const ReportsDateSelectionModalContent = observer(({
	dateTimeSelectionModel,
	selectedReportType,
}: ReportsDateSelectionModalProps) => {
	const radioButtons = [
		{
			value: 'departure',
			label: selectedReportType === 'gift-certificates' ? 'Date used' : 'Departure date',
			key: 'departure-date',
		},
		{
			value: 'created',
			label: 'Date created',
			key: 'date-created',
		},
	];

	const noRadioBtns: ReportType[] = [
		'revenue',
		'takings-breakdown',
		'occupancy',
		'customer',
		'operation',
		'cash',
		'eftpos',
	];

	const showOptions = selectedReportType && !noRadioBtns.includes(selectedReportType);

	const dateLabel = () => {
		switch (selectedReportType) {
			case 'revenue':
				return 'Realisation date';
			case 'occupancy':
			case 'operation':
				return 'Departure date';
			case 'customer':
			case 'takings-breakdown':
				return 'Created date';
			case 'cash':
			case 'eftpos':
				return 'Date range';
			default:
				return undefined;
		}
	};

	return (
		<div className="reports-date-selection-modal__content">
			{showOptions && (
				<div>
					{radioButtons.map(option => (
						<InputWrapper
							inputType={InputType.RADIO}
							label={{ text: option.label, position: LabelPositions.AFTER }}
							className="date-range-type__radio"
							key={option.key}
						>
							<input
								type="radio"
								value={option.value}
								checked={dateTimeSelectionModel.selectionType === option.value}
								onChange={action(event => {
									dateTimeSelectionModel.selectionType = event.target.value as dateFilterType;
								})}
							/>
						</InputWrapper>
					))}
				</div>
			)}
			<DateRangePicker
				model={dateTimeSelectionModel}
				modelProperty="range"
				className="reports-modal__date-range-picker"
				displayType={DisplayType.BLOCK}
				// enableTime
				flatpickrOptions={{
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
				}}
				flatpickrProps={{
					value: [dateTimeSelectionModel.startDate, dateTimeSelectionModel.endDate
						?? dateTimeSelectionModel.startDate],
					onChange: dates => {
						const [startDate, endDate] = dates;
						runInAction(() => {
							if (startDate !== undefined && endDate !== undefined) {
								dateTimeSelectionModel.startDate = startDate;
								dateTimeSelectionModel.endDate = endDate;
							}
						});
					},
					render: (props, ref) => {
						return (
							<div className="reports-date-selector__container" ref={ref}>
								<CustomDateRangeFilter
									model={dateTimeSelectionModel}
									selectedReportType={selectedReportType}
									label={dateLabel()}
								/>
							</div>
						);
					},
				}}
			/>
		</div>
	);
});

export const RenderReportsDateSelectionModal = async (
	state: { loading: boolean },
	selectedReportType: ReportType,
	reportId?: string,
) => {
	const initialDate = moment(new Date()).minutes(0).seconds(0)
		.toDate();
	const reportsdateTimeSelectionModel = observable({
		selectionType: (selectedReportType === 'additional-option' ? 'created' : 'departure') as dateFilterType,
		startDate: initialDate,
		endDate: initialDate,
		startTime: moment(initialDate).hours(6).toDate(),
		endTime: initialDate,
	});

	const modalTitle = `Export ${GetReportReadableName(selectedReportType)} report`;
	const cancelButtonText: string = 'Close';
	const confirmButtonText: string = 'Generate report';

	let modalContent: JSX.Element;
	const ObservableModalContent = observer(() => {
		return selectedReportType === 'cash' || selectedReportType === 'eftpos'
			? (
				<ReportsTimeSelectionModalContent
					dateTimeSelectionModel={reportsdateTimeSelectionModel}
					selectedReportType={selectedReportType}
				/>
			)
			: (
				<ReportsDateSelectionModalContent
					dateTimeSelectionModel={reportsdateTimeSelectionModel}
					selectedReportType={selectedReportType}
				/>
			);
	});
	// eslint-disable-next-line prefer-const
	modalContent = <ObservableModalContent />;
	const confirmed = await modalWithCustomInput(
		modalTitle,
		modalContent,
		{
			cancelText: cancelButtonText,
			confirmText: confirmButtonText,
			actionClassName: 'reports-date-selection-modal',
			resolveOnCancel: true,
			onConfirm: action(() => {
				return true;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	try {
		runInAction(() => {
			state.loading = true;
		});
		if (selectedReportType === 'occupancy') {
			downloadOccupancyReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
			);
		}
		if (selectedReportType === 'invoice') {
			downloadInvoiceReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
				reportsdateTimeSelectionModel.selectionType === 'created',
			);
		}
		if (selectedReportType === 'revenue') {
			downloadRevenueReportCsv(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
			);
		}
		if (selectedReportType === 'takings-breakdown') {
			downloadTakingsBreakdownReportCsv(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
			);
		}
		if (selectedReportType === 'gift-certificates') {
			downloadGiftCertificateCsv(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
				reportsdateTimeSelectionModel.selectionType === 'created',
			);
		}
		if (selectedReportType === 'additional-option' && isNotNullOrUndefined(reportId)) {
			await downloadAdditionalOptionReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
				reportsdateTimeSelectionModel.selectionType === 'created',
				reportId,
			);
		}
		if (selectedReportType === 'customer') {
			downloadCustomerReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
			);
		}
		if (selectedReportType === 'cash') {
			downloadCashReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.startTime,
				reportsdateTimeSelectionModel.endTime,
			);
		}
		if (selectedReportType === 'operation') {
			downloadOperationReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.endDate,
			);
		}
		if (selectedReportType === 'eftpos') {
			downloadEftposReport(
				reportsdateTimeSelectionModel.startDate,
				reportsdateTimeSelectionModel.startTime,
				reportsdateTimeSelectionModel.endTime,
			);
		}
	} catch (e) {
		runInAction(() => {
			state.loading = false;
		});
		alertToast('Unable to export report', 'error');
	}
};

export const ReportsTimeSelectionModalContent = observer(({
	dateTimeSelectionModel,
	selectedReportType,
}: ReportsDateSelectionModalProps) => {
	return (
		<div className="reports-date-selection-modal__content">
			{/* eslint-disable-next-line max-len */}
			<p>{`Provide the date and time range during which the ${selectedReportType} was expected to have been collected.`}</p>
			<DatePicker
				model={dateTimeSelectionModel}
				modelProperty="startDate"
				name="customDateSelector"
				className="date-picker__general calendar-date-selector"
				displayType={DisplayType.BLOCK}
				label="Date"
				labelVisible
				flatpickrOptions={{
					position: 'below left',
					monthSelectorType: 'static',
					allowInput: false,
					dateFormat: 'd-m-Y',
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
			/>
			<div className="time-range-picker">
				<TimePicker
					model={dateTimeSelectionModel}
					// time24hr
					modelProperty="startTime"
					label="From time"
					flatpickrOptions={{
						dateFormat: 'h:i K',
					}}
				/>
				<TimePicker
					model={dateTimeSelectionModel}
					// time24hr
					modelProperty="endTime"
					label="To time"
					flatpickrOptions={{
						dateFormat: 'h:i K',
					}}
				/>
			</div>
		</div>
	);
});

interface ICustomDateRangeFilterProps {
	model: dateTimeSelectionModel;
	selectedReportType?: ReportType;
	label?: string;
}

function CustomDateRangeFilter({ model, selectedReportType, label }: ICustomDateRangeFilterProps) {
	return (
		<>
			<If condition={selectedReportType === 'gift-certificates'}>
				<p className="reports-date-selector__label">
					{model.selectionType === 'created' ? 'Created on' : 'Used on'}
				</p>
			</If>
			<If condition={selectedReportType !== 'gift-certificates'}>
				<p className="reports-date-selector__label">
					<If condition={label === undefined}>
						{model.selectionType === 'created' ? 'Created on' : 'Departed on'}
					</If>
					<If condition={label !== undefined}>
						{label}
					</If>
				</p>
			</If>
			<div className="reports-date-selector__input">
				<span>
					{`${moment(model.startDate)
						.format('DD/MM/YYYY')
						.toUpperCase()} - ${moment(model.endDate).format('DD/MM/YYYY').toUpperCase()}`}
				</span>
				<img
					className="date-calendar-icon"
					src={CalendarSvg}
					alt="depart-calendar-icon"
				/>
			</div>
		</>
	);
}

function GetReportReadableName(reportType: ReportType) {
	switch (reportType) {
		case 'takings-breakdown':
			return 'transaction breakdown';
		case 'additional-option':
			return 'add on';
		case 'gift-certificates':
			return 'gift certificate';
		default:
			return reportType;
	}
}
