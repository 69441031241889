import classNames from 'classnames';
import * as React from 'react';

type SpaceSize = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'mdlg' | 'lg' | 'xl' | 'xxl' | 'xxxl';
type FlexDirection = 'row' | 'col';
type AlignItems = 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';
type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

export interface FlexProps {
	direction?: FlexDirection;
	gap?: SpaceSize;
	rowGap?: 'none';
	colGap?: 'none';
	flex?: 1;
	flexWrap?: FlexWrap;
	align?: AlignItems;
	justify?: JustifyContent;
	className?: string;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	children?: React.ReactNode;
}

export function Flex(props: FlexProps) {
	const {
		direction: directionProp = 'row',
		gap: gapProp,
		rowGap: rowGapProp,
		colGap: colGapProp,
		flex: flexProp,
		flexWrap: flexWrapProp,
		align: alignProp,
		justify: justifyProp,
		className,
		onClick,
		children,
	} = props;

	const direction = `flex-${directionProp}`;
	const gap = gapProp
		? `gap-${gapProp}`
		: '';
	const flex = flexProp
		? `flex-${flexProp}`
		: '';
	const flexWrap = flexWrapProp
		? `flex-wrap-${flexWrapProp}`
		: '';
	const align = alignProp
		? `flex-align-${alignProp}`
		: '';
	const justify = justifyProp
		? `flex-justify-${justifyProp}`
		: '';
	const rowGap = rowGapProp
		? `flex-row-gap-${rowGapProp}`
		: '';
	const colGap = colGapProp
		? `flex-col-gap-${colGapProp}`
		: '';

	const clazz = ['flex', direction, gap, rowGap, colGap, flex, flexWrap, align, justify, className];

	if (onClick) {
		const onInternalClick = (e: any) => {
			if (onClick) {
				onClick(e);
			}
		};

		return (
			<div role="presentation" className={classNames(clazz)} onClick={e => onInternalClick(e)}>
				{children}
			</div>
		);
	}

	return (
		<div className={classNames(clazz)}>
			{children}
		</div>
	);
}
