import If from '../../../If/If';
import { CustomDateSelector } from '../../../DatePicker/CustomDateSelector';
import { TripDirectionSelector } from '../../TripDirectionSelector/TripDirectionSelector';
import { whiteLabelStore } from '../../../../../Models/WhiteLabelStore';
import { Button, Colors, Display } from '../../../Button/Button';
import * as React from 'react';
import { FerryScheduleModel } from '../FerryScheduleModel';
import { LocationEntity } from '../../../../../Models/Entities';
import { FerryTripImporterModal } from '../../Modal/FerryTripImporterModal';
import { FerryTripImportSideBar } from '../../SideBar/FerryTripImportSideBar/FerryTripImportSideBar';
import { useState } from 'react';
import { FerryTripImportResponse } from '../../../../../Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';
import alertToast from '../../../../../Util/ToastifyUtils';
import useDeviceDetect from '../../../../../Hooks/useDeviceDetect';
import useStore from '../../../../../Hooks/useStore';

export interface ScheduleFiltersProps {
	model: FerryScheduleModel;
	locations: LocationEntity[] | undefined;
	bulkBookingTrips: string[] | null;
	setBulkBookingTrips: (trips: string[] | null) => void;
	isIpad: boolean;
}

export function ScheduleFilters({
	model,
	locations,
	bulkBookingTrips,
	setBulkBookingTrips,
	isIpad,
}: ScheduleFiltersProps) {
	const store = useStore();
	const buttons: React.ReactNode[] = [];
	const { ferryScheduleFilterSize } = useDeviceDetect();

	const [showMenu, setShowMenu] = React.useState(false);
	const [showSideBar, setShowSideBar] = useState(false);
	const [importResponse, setImportResponse] = useState<FerryTripImportResponse | undefined>();

	const onSideBarClose = (isSuccess: boolean, response?: FerryTripImportResponse) => {
		setShowSideBar(false);
		if (response) {
			setImportResponse(response);
		}
		if (isSuccess) {
			alertToast('Successfully imported ferry trips', 'success');
		}
	};
	if (bulkBookingTrips === null) {
		if (store.isManager) {
			buttons.push(
				<div className="ferry-schedule-menu__button">
					<Button
						display={Display.Solid}
						colors={Colors.Black}
						onClick={() => setShowSideBar(true)}
					>
						Import
					</Button>
				</div>,
			);
		}

		if ((store.isStaff || store.isInvoicedUser) && !whiteLabelStore.config.hideBulkBookingButton) {
			buttons.push(
				<div className="ferry-schedule-menu__button">
					<Button
						display={Display.Outline}
						colors={Colors.Black}
						onClick={() => {
							if (bulkBookingTrips == null) {
								setBulkBookingTrips([]);
							}
						}}
					>
						Bulk book trips
					</Button>
				</div>,
			);
		}
	}

	return (
		<>
			<div className="calendar-view-container__header">
				<div className="left">
					<TripDirectionSelector
						model={model}
						fromLocationProperty="fromLocation"
						toLocationProperty="toLocation"
						routeProperty="route"
						disabled={bulkBookingTrips !== null}
						locations={locations}
					/>
					<If condition={!isIpad}>
						<div className="border__wrapper">
							<div className="border" />
						</div>
						<CustomDateSelector
							model={model}
							dateProperty="date"
						/>
					</If>
				</div>
				<div className="right">
					<If condition={!isIpad}>
						<div className="ferry-schedule-menu__button-container">
							<If condition={buttons.length > 0}>
								<If condition={buttons.length <= 2 && !ferryScheduleFilterSize}>
									{buttons}
								</If>
								<If condition={buttons.length > 2 || ferryScheduleFilterSize}>
									<If condition={showMenu}>
										<div className="menu__dropdown">
											{buttons}
										</div>
									</If>
									<div className="ferry-schedule-menu__button">
										<Button
											className="menu__button"
											display={Display.Text}
											colors={Colors.None}
											icon={{ icon: 'menu', iconPos: 'icon-right' }}
											onClick={() => setShowMenu(!showMenu)}
										/>
									</div>
								</If>
							</If>
						</div>
					</If>
				</div>
			</div>
			<If condition={store.isManager}>
				<FerryTripImporterModal
					showModal={importResponse !== undefined && importResponse.hasErrors()}
					importResponse={importResponse}
					onRequestClose={() => setImportResponse(undefined)}
					onReset={() => {
						setImportResponse(undefined);
						setShowSideBar(true);
					}}
				/>
				<If condition={showSideBar}>
					<FerryTripImportSideBar onClose={onSideBarClose} />
				</If>
			</If>
		</>
	);
}
