/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDiscountEntity from 'Models/Security/Acl/VisitorsDiscountEntity';
import UserDiscountEntity from 'Models/Security/Acl/UserDiscountEntity';
import StaffDiscountEntity from 'Models/Security/Acl/StaffDiscountEntity';
import ManagerDiscountEntity from 'Models/Security/Acl/ManagerDiscountEntity';
import AdminDiscountEntity from 'Models/Security/Acl/AdminDiscountEntity';
import InvoicedUserDiscountEntity from 'Models/Security/Acl/InvoicedUserDiscountEntity';
import FixedPriceUserDiscountEntity from 'Models/Security/Acl/FixedPriceUserDiscountEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDiscountEntityAttributes extends IModelAttributes {
	name: string;
	code: string;
	bookingWindowStartDate: Date;
	bookingWindowEndDate: Date;
	tripWindowStartDate: Date;
	tripWindowEndDate: Date;
	active: boolean;
	discountType: Enums.discountType;
	discountScaling: number;
	legacyId: number;

	alterations: Array<
		| Models.DiscountAlteration
		| Models.IDiscountAlterationAttributes
	>;
	transactions: Array<
		| Models.TransactionDiscount
		| Models.ITransactionDiscountAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DiscountEntity', 'Discount')
// % protected region % [Customise your entity metadata here] end
export default class DiscountEntity extends Model
	implements IDiscountEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDiscountEntity(),
		new UserDiscountEntity(),
		new StaffDiscountEntity(),
		new ManagerDiscountEntity(),
		new AdminDiscountEntity(),
		new InvoicedUserDiscountEntity(),
		new FixedPriceUserDiscountEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<DiscountEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	@Validators.Required()
	@Validators.Unique()
	@observable
	@attribute<DiscountEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Booking Window Start Date'] off begin
	@Validators.Required()
	@observable
	@attribute<DiscountEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Booking Window Start Date',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public bookingWindowStartDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Window Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Booking Window End Date'] off begin
	@Validators.Required()
	@observable
	@attribute<DiscountEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Booking Window End Date',
		displayType: 'datepicker',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public bookingWindowEndDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Window End Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trip Window Start Date'] off begin
	@observable
	@attribute<DiscountEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Trip Window Start Date',
		displayType: 'datepicker',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public tripWindowStartDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Trip Window Start Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Trip Window End Date'] off begin
	@observable
	@attribute<DiscountEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Trip Window End Date',
		displayType: 'datepicker',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public tripWindowEndDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Trip Window End Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Active'] off begin
	@observable
	@attribute<DiscountEntity, boolean>()
	@CRUD({
		name: 'Active',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public active: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'Discount Type'] off begin
	@observable
	@attribute<DiscountEntity, Enums.discountType>()
	@CRUD({
		name: 'Discount Type',
		displayType: 'enum-combobox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.discountTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.discountTypeOptions),
		displayFunction: (attr: Enums.discountType) => Enums.discountTypeOptions[attr],
	})
	public discountType: Enums.discountType;
	// % protected region % [Modify props to the crud options here for attribute 'Discount Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Discount Scaling'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DiscountEntity, number>()
	@CRUD({
		name: 'Discount Scaling',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public discountScaling: number;
	// % protected region % [Modify props to the crud options here for attribute 'Discount Scaling'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<DiscountEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alteration',
		displayType: 'reference-multicombobox',
		order: 110,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.DiscountAlteration,
		optionEqualFunc: makeJoinEqualsFunc('alterationId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'discountEntity',
			oppositeEntityName: 'alterationEntity',
			relationName: 'discount',
			relationOppositeName: 'alteration',
			entity: () => Models.DiscountEntity,
			joinEntity: () => Models.DiscountAlteration,
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterations: Models.DiscountAlteration[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] off begin
		name: 'Transaction',
		displayType: 'reference-multicombobox',
		order: 120,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TransactionDiscount,
		optionEqualFunc: makeJoinEqualsFunc('transactionId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'discountEntity',
			oppositeEntityName: 'transactionEntity',
			relationName: 'discount',
			relationOppositeName: 'transaction',
			entity: () => Models.DiscountEntity,
			joinEntity: () => Models.TransactionDiscount,
			oppositeEntity: () => Models.TransactionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] end
	})
	public transactions: Models.TransactionDiscount[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDiscountEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDiscountEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.bookingWindowStartDate !== undefined) {
				if (attributes.bookingWindowStartDate === null) {
					this.bookingWindowStartDate = attributes.bookingWindowStartDate;
				} else {
					this.bookingWindowStartDate = moment(attributes.bookingWindowStartDate).toDate();
				}
			}
			if (attributes.bookingWindowEndDate !== undefined) {
				if (attributes.bookingWindowEndDate === null) {
					this.bookingWindowEndDate = attributes.bookingWindowEndDate;
				} else {
					this.bookingWindowEndDate = moment(attributes.bookingWindowEndDate).toDate();
				}
			}
			if (attributes.tripWindowStartDate !== undefined) {
				if (attributes.tripWindowStartDate === null) {
					this.tripWindowStartDate = attributes.tripWindowStartDate;
				} else {
					this.tripWindowStartDate = moment(attributes.tripWindowStartDate).toDate();
				}
			}
			if (attributes.tripWindowEndDate !== undefined) {
				if (attributes.tripWindowEndDate === null) {
					this.tripWindowEndDate = attributes.tripWindowEndDate;
				} else {
					this.tripWindowEndDate = moment(attributes.tripWindowEndDate).toDate();
				}
			}
			if (attributes.active !== undefined) {
				this.active = attributes.active;
			}
			if (attributes.discountType !== undefined) {
				this.discountType = attributes.discountType;
			}
			if (attributes.discountScaling !== undefined) {
				this.discountScaling = attributes.discountScaling;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.alterations !== undefined && Array.isArray(attributes.alterations)) {
				for (const model of attributes.alterations) {
					if (model instanceof Models.DiscountAlteration) {
						this.alterations.push(model);
					} else {
						this.alterations.push(new Models.DiscountAlteration(model));
					}
				}
			}
			if (attributes.transactions !== undefined && Array.isArray(attributes.transactions)) {
				for (const model of attributes.transactions) {
					if (model instanceof Models.TransactionDiscount) {
						this.transactions.push(model);
					} else {
						this.transactions.push(new Models.TransactionDiscount(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		alterations {
			${Models.DiscountAlteration.getAttributes().join('\n')}
			alteration {
				${Models.AlterationEntity.getAttributes().join('\n')}
			}
		}
		transactions {
			${Models.TransactionDiscount.getAttributes().join('\n')}
			transaction {
				${Models.TransactionEntity.getAttributes().join('\n')}
			}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			alterations: {},
			transactions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'alterations',
							'transactions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DiscountEntity.prototype, 'created');
CRUD(modifiedAttr)(DiscountEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
