/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDynamicPriceEntity from 'Models/Security/Acl/VisitorsDynamicPriceEntity';
import UserDynamicPriceEntity from 'Models/Security/Acl/UserDynamicPriceEntity';
import StaffDynamicPriceEntity from 'Models/Security/Acl/StaffDynamicPriceEntity';
import ManagerDynamicPriceEntity from 'Models/Security/Acl/ManagerDynamicPriceEntity';
import AdminDynamicPriceEntity from 'Models/Security/Acl/AdminDynamicPriceEntity';
import InvoicedUserDynamicPriceEntity from 'Models/Security/Acl/InvoicedUserDynamicPriceEntity';
import FixedPriceUserDynamicPriceEntity from 'Models/Security/Acl/FixedPriceUserDynamicPriceEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDynamicPriceEntityAttributes extends IModelAttributes {
	bookingType: Enums.bookingType;
	priceScaling: number;
	minimumThreshold: number;
	hoursBeforeDeparture: number;
	legacyId: number;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DynamicPriceEntity', 'Dynamic Price')
// % protected region % [Customise your entity metadata here] end
export default class DynamicPriceEntity extends Model
	implements IDynamicPriceEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDynamicPriceEntity(),
		new UserDynamicPriceEntity(),
		new StaffDynamicPriceEntity(),
		new ManagerDynamicPriceEntity(),
		new AdminDynamicPriceEntity(),
		new InvoicedUserDynamicPriceEntity(),
		new FixedPriceUserDynamicPriceEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Booking Type'] on begin
	/**
	 * More specific booking type takes precedence
	 */
	@Validators.Required()
	@observable
	@attribute<DynamicPriceEntity, Enums.bookingType>()
	@CRUD({
		name: 'Booking type',
		description: 'The type of booking that this dynamic pricing will apply to. E.g. if it is a passenger booking '
			+ 'and it falls within the minimum threshold of space, it will apply the dynamic pricing to the price.',
		displayType: 'enum-combobox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.bookingTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.bookingTypeOptions),
		displayFunction: (attr: Enums.bookingType) => Enums.bookingTypeOptions[attr],
	})
	public bookingType: Enums.bookingType;
	// % protected region % [Modify props to the crud options here for attribute 'Booking Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price Scaling'] on begin
	/**
	 * The percentage to increase the price
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<DynamicPriceEntity, number>()
	@CRUD({
		name: 'Price scaling percentage',
		description: 'The percentage that the price will scale if a booking meets the other requirements. '
			+ 'E.g. 20 = 20%.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public priceScaling: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price Scaling'] end

	// % protected region % [Modify props to the crud options here for attribute 'Minimum Threshold'] on begin
	/**
	 * The percentage of occupancy available before price scaling is applied. If null, then ignore condition.
	 */
	@Validators.Numeric()
	@observable
	@attribute<DynamicPriceEntity, number>()
	@CRUD({
		name: 'Minimum space threshold',
		description: 'The minimum space available for the specified booking type, in order for this dynamic pricing '
			+ 'to apply. Note: if it is left empty, it will use time before departure below.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public minimumThreshold: number;
	// % protected region % [Modify props to the crud options here for attribute 'Minimum Threshold'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hours Before Departure '] on begin
	/**
	 * The number of hours before a ferry trip departs (do not apply pricing before this point). If null, then ignore condition.
	 */
	@Validators.Integer()
	@observable
	@attribute<DynamicPriceEntity, number>()
	@CRUD({
		name: 'Time before departure (hours)',
		description: 'The number of hours before the ferry trip departs that this dynamic pricing will automatically '
			+ 'apply. Note: if this is left empty, it will use the minimum space threshold to determine if the dynamic '
			+ 'pricing should apply or not.',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public hoursBeforeDeparture: number;
	// % protected region % [Modify props to the crud options here for attribute 'Hours Before Departure '] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] on begin
	@Validators.Integer()
	@observable
	@attribute<DynamicPriceEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'hidden',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDynamicPriceEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDynamicPriceEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.bookingType !== undefined) {
				this.bookingType = attributes.bookingType;
			}
			if (attributes.priceScaling !== undefined) {
				this.priceScaling = attributes.priceScaling;
			}
			if (attributes.minimumThreshold !== undefined) {
				this.minimumThreshold = attributes.minimumThreshold;
			}
			if (attributes.hoursBeforeDeparture !== undefined) {
				this.hoursBeforeDeparture = attributes.hoursBeforeDeparture;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DynamicPriceEntity.prototype, 'created');
CRUD(modifiedAttr)(DynamicPriceEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
