import * as React from 'react';
import { Colors, Display } from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { Text } from 'Views/Components/Text/Text';
import alertToast from 'Util/ToastifyUtils';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import { showCheckInAlreadyModal } from '../../Modal/CheckInAlreadyModalContents';
import {
	checkInBoarding,
	getEventBooking,
} from 'Services/Api/_HumanWritten/CheckInService';
import { BoardingStatus, isBoardingResponse } from 'Models/_HumanWritten/BoardingResponse';
import { showCheckInCancelledBookingModal } from '../../Modal/CheckInCancelledBookingModalContents';
import { showCheckInBookingNotFoundModal } from '../../Modal/CheckInBookingNotFoundModalContents';
import LinkAsButton from '../../LinkAsButton/LinkAsButton';
import Icon from '../../Icon/Icon';
import { QrCodeScanner } from '../../QrCodeScanner/QrCodeScanner';
import { LottieSpinner } from '../../Lottie/LottieSpinner';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { EventCheckInBookingOverviewDto } from './EventCheckInEntities/EventCheckInOverviewDto';
import { CHECK_IN_ALERT_CONFIG } from './EventCheckInUtils';
import { showEventCheckInScanConfirm } from '../../Modal/EventCheckInScanConfirm';
import { showEventCheckInWrongEventModal } from '../../Modal/EventCheckInWrongEventModalContents';
import useEventCheckInStore from '../../../../../Hooks/useEventCheckInStore';
import { showEventCheckInNotAnEventBookingModal } from '../../Modal/EventCheckInNotAnEventBookingModalContents';

export default EventCheckInScanner;

function EventCheckInScanner() {
	const eventCheckInStore = useEventCheckInStore();
	const routes = useCheckInRoutes(true);
	usePassengerTypes();
	const [scanResult, setScanResult] = React.useState<string>();

	const confirmPassengers = async (booking: EventCheckInBookingOverviewDto | null, next: () => Promise<void>) => {
		if (isNullOrUndefined(booking)) {
			// With no info on booking due to failed fetch, what do we do?
			return showEventCheckInScanConfirm({
				onConfirm: async () => {
					await next();
				},
			});
		}

		return showEventCheckInScanConfirm({
			onConfirm: async () => {
				await next();
			},
			booking: booking,
		});
	};

	/**
	 * Scenarios listed below:
	 *
	 *     Booking belongs to current event
	 *     Booking already checked-in
	 *     Booking belongs to another event
	 *     Booking is cancelled
	 *     Booking is not found
	 */
	const process = async (bookingId: string) => {
		if (stringIsEmpty(bookingId)) {
			console.error('Error in CheckInScanner');
			return;
		}

		const booking = eventCheckInStore.bookings.find(x => x.id === bookingId);

		if (isNotNullOrUndefined(booking)) {
			// Booking already checked-in
			if (booking.checkedIn) {
				return showCheckInAlreadyModal();
			}

			// Booking belongs to current trip
			const onConfirm = async () => {
				await eventCheckInStore.checkInBooking(bookingId, true, {
					triggerAlert: false,
				});
				eventCustomAlert(booking);
			};
			return confirmPassengers(booking, onConfirm);
		}

		// Check id using server
		const boardingResponse = await checkInBoarding(eventCheckInStore.eventDetails.ferryTripId, bookingId);

		if (isBoardingResponse(boardingResponse.data)) {
			const { status, departureDateTime, checkedIn } = boardingResponse.data;
			switch (status) {
				case BoardingStatus.ALREADY_CHECKED_IN:
					return showCheckInAlreadyModal();
				case BoardingStatus.CANCELLED:
					return showCheckInCancelledBookingModal();
				case BoardingStatus.NOT_FOUND:
					return showCheckInBookingNotFoundModal();
				case BoardingStatus.WRONG_EVENT:
					return showEventCheckInWrongEventModal();
				case BoardingStatus.NOT_EVENT_BOOKING:
					return showEventCheckInNotAnEventBookingModal();
				default:
			}

			// At this point, the scenarios remaining is for a valid booking
			const response = await getEventBooking(bookingId);
			const fetchedBooking = new EventCheckInBookingOverviewDto(response.data);

			if (isNullOrUndefined(fetchedBooking)) {
				return alertToast('Booking not found', 'error', undefined, CHECK_IN_ALERT_CONFIG);
			}

			switch (status) {
				case BoardingStatus.CHECKED_IN:
					return confirmPassengers(
						fetchedBooking,
						async () => {
							try {
								await eventCheckInStore.checkInBooking(bookingId, true, {
									triggerAlert: false,
								});
								eventCustomAlert(fetchedBooking);
							} catch (e) {
								alertToast('Something went wrong with check-in process', 'error', 'Error');
							}
						},
					);
			}
		}

		throw new Error('Reach end of process function');
	};

	const onScanSuccess = async (value: string) => {
		try {
			await process(getIdFromUrl(value) ?? '');
			setScanResult(undefined);
		} catch (e) {
			console.error('Error while processing in CheckInScanner', e);
			store.modal.hide();
			setScanResult(undefined);
		}
	};

	return (
		<div id="check-in-body" className="body-content check-in">
			<div className="check-in__top-nav">
				<div className="check-in__top-nav__back flex">
					<LinkAsButton to={routes.base} display={Display.Text} colors={Colors.Black}>
						<Icon name="chevron-left" />
						Back
					</LinkAsButton>
				</div>
			</div>
			<div className="check-in__body">
				<QrCodeScanner
					value={scanResult}
					setValue={setScanResult}
					onSuccess={onScanSuccess}
				>
					<LottieSpinner />
				</QrCodeScanner>
				<Text color="gray" className="mt-md">
					Scan QR code to check in
				</Text>
			</div>
		</div>
	);
}

function getIdFromUrl(url: string) {
	try {
		decodeURI(url);
	} catch (e) {
		console.error(e);
		return;
	}

	const sections = url.split('/');
	const id = sections[sections.length - 1];

	return id;
}

function eventCustomAlert(booking: EventCheckInBookingOverviewDto) {
	alertToast(
		<span>
			<strong>{`${booking.bookedSummary.primaryFirstName ?? booking.bookedSummary.userFirstName}`}</strong> is checked in
		</span>,
		'success',
		undefined,
		CHECK_IN_ALERT_CONFIG,
	);
}
