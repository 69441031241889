import * as React from 'react';
import CheckInTopNav from './CheckInTopNav';
import { observer, useLocalStore } from 'mobx-react';
import { isNullOrUndefined } from 'Util/TypeGuards';
import useScreenSize from 'Hooks/useScreenSize';
import CheckInDetailsSidebar from './CheckInDetails/CheckInDetailsSidebar';
import CheckInDetailsView from './CheckInDetails/CheckInDetailsView';
import useCheckInStore from 'Hooks/useCheckInStore';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { CheckInSorter } from './CheckInUtils';
import CheckInTabs from './CheckInTabs/CheckInTabs';
import { AdditionalBookingSectionEntity } from 'Models/Entities';
import {
	Route,
	Switch,
} from 'react-router';

export enum CheckInCountType {
	ItemCount = 'ItemCount',
	SpaceCount = 'SpaceCount',
	WeightCount = 'WeightCount',
}

export interface CheckInViewState {
	/**
	 * True means bookings displayed have already checked in.
	 * False means the opposite (bookings displayed have not been checked in).
	 */
	showCheckedIn: boolean,
	currentTab: 0 | 1;
	tripHasCargoSpaces: boolean,
}

interface UseFilterSettingsProps {
	countType: CheckInCountType;
	cargoSorting: CheckInSorter;
	cargoDescending: boolean;
	passengerSorting: CheckInSorter;
	passengerDescending: boolean;
	setCountType(type: CheckInCountType): void;
	setCargoSorting(type: CheckInSorter): void;
	setPassengerSorting(type: CheckInSorter): void;
	setCargoDescending(descending: boolean): void;
	setPassengerDescending(descending: boolean): void;
	toggleCargoDescending: () => void;
	togglePassengerDescending: () => void;
}

export const useSortSettings = create<UseFilterSettingsProps, [['zustand/persist', unknown]]>(
	persist(
		(set, get) => ({
			countType: CheckInCountType.SpaceCount,
			cargoSorting: CheckInSorter.Fullname,
			cargoDescending: false,
			passengerSorting: CheckInSorter.Fullname,
			passengerDescending: false,
			setCountType(type: CheckInCountType) {
				set(() => ({ countType: type }));
			},
			setCargoSorting(type: CheckInSorter) {
				set(() => ({ cargoSorting: type }));
			},
			setCargoDescending(cargoDescending: boolean) {
				set(() => ({ cargoDescending }));
			},
			setPassengerSorting(type: CheckInSorter) {
				set(() => ({ passengerSorting: type }));
			},
			setPassengerDescending(passengerDescending: boolean) {
				set(() => ({ passengerDescending }));
			},
			toggleCargoDescending: () => {
				set(state => ({ cargoDescending: !state.cargoDescending }));
			},
			togglePassengerDescending: () => {
				set(state => ({ passengerDescending: !state.passengerDescending }));
			},
		}),
		{
			name: 'check-in-sorter-store',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

interface UseSearchTermProps {
	model: SearchTermModel;
	setSearchTerm(term: string): void;
}

interface SearchTermModel {
	searchTerm: string;
}

export const useSearchTerm = create<UseSearchTermProps, [['zustand/persist', unknown]]>(set => ({
	model: {
		searchTerm: '',
	},
	setSearchTerm: (term: string) => set((state: any) => ({ model: { ...state.model, searchTerm: term } })),
}));

interface UseFilterAddOnProps {
	model: FilteredAddOnsModel;
	setFilteredAddOns(addOns: AdditionalBookingSectionEntity[]): void;
}

interface FilteredAddOnsModel {
	filteredAddOns: AdditionalBookingSectionEntity [];
}

export const useFilterAddOn = create<UseFilterAddOnProps, [['zustand/persist', unknown]]>(set => ({
	model: {
		filteredAddOns: [],
	},
	setFilteredAddOns: (filteredAddOns: AdditionalBookingSectionEntity []) => set((state: any) => ({ model: { ...state.model, filteredAddOns: filteredAddOns } })),
}));

function CheckInView() {
	const { ferryTrip } = useCheckInStore();

	const tripHasInitialCargoSpace = ferryTrip?.hasVehicleCapacity;

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const state = useLocalStore<CheckInViewState>(() => ({
		showCheckedIn: false,
		currentTab: (whiteLabelStore.config.defaultBookingType === 'CARGO' && tripHasInitialCargoSpace) ? 1 : 0,
		tripHasCargoSpaces: tripHasInitialCargoSpace,
	}));

	const { isIpadMiniLandscape } = useScreenSize();

	if (isIpadMiniLandscape) {
		return (
			<Switch>
				<Route path="/check-in/:id/booking/:id">
					{routerProps => {
						const bookingId = routerProps.match?.params.id;
						if (isNullOrUndefined(bookingId)) {
							return null;
						}
						return <CheckInDetailsView bookingId={bookingId} />;
					}}
				</Route>
				<Route path="/check-in/:id">
					<div id="check-in-body" className="body-content check-in">
						<CheckInTopNav showButtons state={state} />
						<CheckInTabs state={state} />
					</div>
				</Route>
			</Switch>
		);
	}

	return (
		<div id="check-in-body" className="body-content check-in">
			<Switch>
				<Route path="/check-in/:id/booking/:id">
					{routerProps => {
						const bookingId = routerProps.match?.params.id;
						if (isNullOrUndefined(bookingId)) {
							return null;
						}
						return <CheckInDetailsSidebar bookingId={bookingId} />;
					}}
				</Route>
			</Switch>
			<CheckInTopNav showButtons state={state} />
			<CheckInTabs state={state} />
		</div>
	);
}

export default observer(CheckInView);
