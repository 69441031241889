import BookingEntity from 'Models/Entities/BookingEntity';
import { convertToWizardData } from 'Util/_HumanWritten/BookingWizard/SummaryToWizardUtils';
import { store } from 'Models/Store';
import { BookingSummary } from 'Views/Components/_HumanWritten/BookingSummaryDto';
import {
	saveBookingWizardDataToLocalStorage,
	saveOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export const redirectEditTo = (
	booking: BookingEntity,
	isIpad: boolean,
	bookingIsDepartureOfTwoWayBooking: boolean,
	editType: string,
	alterReturn: boolean,
	departingChangeFee: number = 0,
	returningChangeFee: number = 0,
) => {
	const associatedBooking = booking.returnBooking ?? booking.returnBookingFor;
	const associatedBookingSummary: BookingSummary | undefined = associatedBooking?.bookedSummary;

	// When editing both ways, we want 'summary' to be the departure booking
	// hence, we want the 'returnSummary' to be the returning booking.
	//
	// We want to clarify this because of the scenario when the user clicks edit both ways from
	// the return leg of a two way booking.
	const summary = !alterReturn || bookingIsDepartureOfTwoWayBooking
		? booking.bookedSummary
		: associatedBookingSummary;

	if (!summary) {
		throw new Error('Missing booked summary');
	}

	// BookingToEdit (aka BookingId) needs to be the departure leg of a two-way trip
	// (even if the user enters the edit mode through the return booking).
	const bookingToEdit = !alterReturn || bookingIsDepartureOfTwoWayBooking
		? booking.id
		: associatedBooking.id;

	const associatedTripDateTime = alterReturn
		? undefined
		: associatedBookingSummary?.ferryTrip.departureDateTime;

	const departureTrip = alterReturn
		? undefined
		: bookingIsDepartureOfTwoWayBooking;

	let returnSummary: BookingSummary | undefined;
	if (alterReturn) {
		returnSummary = bookingIsDepartureOfTwoWayBooking
			? associatedBookingSummary
			: booking.bookedSummary;
	}

	const departingCancellationFee = (bookingIsDepartureOfTwoWayBooking || !alterReturn)
		? booking.cancellationFee
		: booking.returnBookingFor.cancellationFee;

	const returningCancellationFee = (bookingIsDepartureOfTwoWayBooking || !alterReturn)
		? booking.returnBooking?.cancellationFee
		: booking.cancellationFee;

	const departingCancellationCutoffHours = (bookingIsDepartureOfTwoWayBooking || !alterReturn)
		? booking.cancellationCutoffHours
		: booking.returnBookingFor.cancellationCutoffHours;

	const wizardData = convertToWizardData(
		booking.userId,
		summary,
		{
			wizardMode: 'ALTERATION',
			bookingToEdit,
			departureTrip,
			associatedTripDateTime,
			departingCancellationFee,
			returningCancellationFee,
			departingCancellationCutoffHours,
		},
		returnSummary,
	);

	saveBookingWizardDataToLocalStorage(wizardData);
	saveOldBookingWizardData(wizardData);

	let urlPath = '';
	switch (editType) {
		case 'vehicle': {
			urlPath = 'vehicle';
			break;
		}
		case 'add-on': {
			urlPath = 'add-ons';
			break;
		}
		case 'passenger': {
			urlPath = isIpad ? 'search' : 'tickets';
			break;
		}
		case 'trip-time': {
			urlPath = 'tickets';
			break;
		}
	}
	store.routerHistory.push(`/booking-wizard/${urlPath}`);
};
