import React from 'react';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { useEffect, useState } from 'react';
import { BookingEntity } from 'Models/Entities';
import { Button } from 'Views/Components/Button/Button';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { feeType, wizardModeOptions } from 'Models/Enums';
import { removeGiftCertificateFromTransaction } from 'Services/Api/_HumanWritten/GiftCertificateService';
import alertToast from 'Util/ToastifyUtils';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Link } from 'react-router-dom';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { FerryPromoGiftCard } from './FerryPromoGiftCard';
import TripBookingSummaryInner from './TripBookingSummaryInner';
import {
	tripSummaryLocationType,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBookingSummaryCard';
import {
	getFerryBookingTransactionIdFromStorage,
} from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	addManagerDiscount, removeManagerDiscount,
} from 'Services/Api/_HumanWritten/ManagerBookingDiscountService';
import {
	BookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	FetchBookingsForCartAsync,
	removeFee,
	removeFeeFromTransaction,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';

export interface CartSummaryProps {
	wizardData: BookingWizardData;
	selectedTrips: SelectedTrips;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	setSelectedTrips: (trips: SelectedTrips) => void;
	onAddNewBooking: (data: BookingWizardData) => void;
	setRefresh: (refresh: boolean) => void;
	refresh: boolean;
	tripSummaryLocation: tripSummaryLocationType;
	setTabIsLoading?: (isLoading: boolean) => void;
}

export function CartSummary({
	wizardData,
	selectedTrips,
	onUpdateWizardData,
	setSelectedTrips,
	onAddNewBooking,
	setTabIsLoading,
}: CartSummaryProps) {
	const [bookingList, setBookingList] = useState<BookingEntity[]>([]);
	const [refetch, setRefetch] = useState(true);

	const transactionId = getFerryBookingTransactionIdFromStorage();

	const response = FetchBookingsForCartAsync(
		transactionId,
		wizardData.userId,
	);

	const onClearGiftCertificate = (code: string) => {
		removeGiftCertificateFromTransaction(transactionId ?? '', code, false)
			.then(_ => {
				alertToast('Removed gift certificate', 'success');
				setRefetch(!refetch);
			})
			.catch(_ => {
				alertToast('Could not remove gift certificate', 'error');
			});
	};

	const onClearFee = (feeId: string) => {
		removeFeeFromTransaction(transactionId ?? '', feeId)
			.then(_ => {
				setRefetch(!refetch);
			})
			.catch(_ => {
				alertToast('Could not remove fee', 'error');
			});
	};

	const onRemoveManagerDiscount = async () => {
		if (isNotNullOrUndefined(transactionId)) {
			try {
				await removeManagerDiscount({
					transactionId: transactionId,
				});
				setRefetch(!refetch);
			} catch {
				alertToast('Could not remove manager discount from transaction', 'error');
			}
		}
	};

	const onRemoveFee = async (
		feeToRemove: feeType,
		departingDiscountAmount?: number,
		returningDiscountAmount?: number,
	) => {
		if (isNotNullOrUndefined(transactionId)) {
			try {
				const newData = { ...wizardData };
				if (feeToRemove === 'OTHER') {
					try {
						await addManagerDiscount({
							transactionId,
							departingDiscountAmount,
							returningDiscountAmount,
						});
						setRefetch(!refetch);
					} catch {
						alertToast('Could not add manager discount to transaction', 'error');
					}
				} else {
					await removeFee(transactionId, feeToRemove);
				}
				onUpdateWizardData(newData);
				setRefetch(!refetch);
			} catch {
				alertToast('Could not remove trailer removal fee from alteration', 'error');
			}
		}
	};

	useEffect(() => {
		if (response.type === 'data') {
			setBookingList(response.data);
			if (setTabIsLoading) {
				setTabIsLoading(false);
			}
		}
	}, [setBookingList, response.data, response.type]);

	if (response.type === 'loading' || response.type === 'error') {
		return <LottieSpinner />;
	}

	let stylingToUse = 'booking-wizard__tab-header cart-summary-heading';
	if (wizardData.wizardMode === wizardModeOptions.ALTERATION) {
		stylingToUse = 'booking-wizard__tab-header cart-summary-heading-alteration';
	}

	return (
		<div className="cart-summary-container">
			<h2 className={stylingToUse}>Review your cart
				<If condition={wizardData.wizardMode === wizardModeOptions.ALTERATION}>
					<Link to={`/bookings/${wizardData?.bookingToEdit ?? ''}`}>
						<Icon name="cross" classname="icon" />
					</Link>
				</If>
			</h2>
			<div className="pre-cart-items">
				<div className={`add-another-booking-section ${
					wizardData.wizardMode === wizardModeOptions.ALTERATION
					|| wizardData.bulkBookingTripIds !== undefined
						? 'hide'
						: ''
				}`}
				>
					<Button
						className="add-another-booking-button icon-add icon-right hide-underline"
						onClick={() => {
							onAddNewBooking(wizardData);
						}}
					>
						Add another booking
					</Button>
				</div>
				<FerryPromoGiftCard
					transactionId={transactionId ?? ''}
					onAddGiftCertificate={() => {
						setRefetch(!refetch);
					}}
				/>
			</div>
			<TripBookingSummaryInner
				tripSummaryLocation="cart"
				wizardData={wizardData}
				onUpdateWizardData={onUpdateWizardData}
				selectedTrips={selectedTrips}
				setSelectedTrips={setSelectedTrips}
				bookings={bookingList}
				refresh={response.refresh}
				refetch={refetch}
				setRefetch={setRefetch}
				onClearFee={feeId => onClearFee(feeId)}
				onClearGiftCertificate={code => onClearGiftCertificate(code)}
				onRemoveManagerDiscount={async () => { onRemoveManagerDiscount(); }}
				onRemoveFee={async (
					fee,
					departureDiscountAmount,
					returnDiscountAmount,
				) => { onRemoveFee(fee, departureDiscountAmount, returnDiscountAmount); }}
				removePreProcessedBooking={() => {}}
			/>
		</div>
	);
}
