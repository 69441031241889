import * as React from 'react';
import { useState } from 'react';
import {
	FerryTripCsvError,
	FerryTripImportResponse,
	IFerryTripImportResponse,
} from 'Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';
import { saveFerryTripCsv } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

function isFerryTripCsvErrorEqual(a: FerryTripCsvError, b: FerryTripCsvError): boolean {
	return a.dateAndTime === b.dateAndTime
		&& a.line === b.line
		&& a.reason === b.reason;
}

function compareFerryTripImportResponse(
	original: FerryTripImportResponse,
	latest: FerryTripImportResponse,
): FerryTripImportResponse | undefined {
	const { errors: orignialErrors } = original;

	const newErrors: FerryTripCsvError[] = latest.errors
		.filter(newError => orignialErrors.find(x => isFerryTripCsvErrorEqual(x, newError)) === null);

	if (newErrors.length === 0) {
		return undefined;
	}

	// Update fail count, trip count, and errors.
	const newImportResponse: IFerryTripImportResponse = {
		data: {
			...latest.data,
			failCount: newErrors.length,
			tripCount: latest.data.tripCount - orignialErrors.length + newErrors.length,
		},
		errors: newErrors,
	};

	return new FerryTripImportResponse(newImportResponse);
}

export interface FerryTripImportSideBarConfirmationProps {
	file?: File;
	importResponse: FerryTripImportResponse;
	onCancel: () => void;
	onConfirm: (importResponse?: FerryTripImportResponse) => void;
}

export function FerryTripImportSideBarConfirmation({
	file,
	importResponse,
	onCancel,
	onConfirm,
}: FerryTripImportSideBarConfirmationProps) {
	const [loading, setLoading] = useState<boolean>(false);

	const onConfirmInternal = async () => {
		if (file) {
			setLoading(true);
			saveFerryTripCsv(file)
				.then(res => {
					const finalResponse = compareFerryTripImportResponse(importResponse, res);
					onConfirm(finalResponse);
				})
				.catch(err => {
					if (err.response?.status === 401) {
						alertToast(
							<>You are <strong>not authorized</strong></>,
							'error',
						);
					} else {
						alertToast(
							<>Something went wrong while processing <strong>{file.name}</strong></>,
							'error',
						);
					}
				});
		}
	};

	return (
		<>
			<div className="ferry-trip-import-side-bar__body body__confirmation">
				<p className="title">
					Confirm {importResponse.data.successCount} new trips?
				</p>
				<p>
					We were able to load <strong>{importResponse.data.successCount}</strong> from the file.
					Do you want to add them to the schedule and make them available for booking?
				</p>
				<div className="ferry-trip-import-side-bar__actions">
					<Button
						display={Display.Outline}
						colors={Colors.Black}
						onClick={onCancel}
						buttonProps={{ id: 'cancel' }}
					>
						Cancel
					</Button>
					<Button
						disabled={loading}
						display={Display.Solid}
						colors={Colors.Black}
						onClick={onConfirmInternal}
						buttonProps={{ id: 'confirm' }}
					>
						{ loading ? 'Saving...' : 'Confirm' }
					</Button>
				</div>
			</div>
		</>
	);
}
