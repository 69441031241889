import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RegisterCustomer } from 'Views/Components/_HumanWritten/RegisterCustomer/RegisterCustomer';
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import { FEATURE_IMAGE_3_URL } from '../../Constants';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { REGISTRATION_PAGE_TITLE } from '../../ConstantPageNames';

export default function RegisterCustomerPage(props: RouteComponentProps) {
	document.title = REGISTRATION_PAGE_TITLE;

	const {
		match,
		location,
		history,
		staticContext,
	} = props;

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<NavigationWrapper
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>
			<div className="body-content">
				<div className="register-container">
					<div className="feature-image">
						<img
							className="bg-img"
							alt="bg-img"
							src={FEATURE_IMAGE_3_URL}
						/>
					</div>
					<RegisterCustomer />
				</div>
			</div>
		</SecuredPage>
	);
}
