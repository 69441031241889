import { measurementDetails, store } from '../../Models/Store';
import { isNullOrUndefined } from '../TypeGuards';
import { PriceEntity } from '../../Models/Entities';
import { measurementPrice } from '../../Services/CustomGql/FerryTripEntityType';
import { measurementType } from 'Models/Enums';

/**
 * This function will truncate the prices so that they can align with the server pricing better.
 * It will help to avoid rounding errors.
 * @param price: The price to truncate.
 * @param includeDecimals: Whether to include decimals or not.
 */
export function getTruncatedPrice(price: number, includeDecimals: boolean) {
	let truncatedPrice = Math.round(price * 100) / 100;
	if (!includeDecimals) {
		truncatedPrice = Math.floor(truncatedPrice);
	}

	return truncatedPrice;
}

export const getMinimumPriceFromFerryTripPrices = (
	prices: PriceEntity[],
	measurementTypeToFilter: measurementType = 'LENGTH',
) => {
	if (isNullOrUndefined(prices) || prices.length === 0) {
		return null;
	}
	return prices
		.filter(x => x.measurement.measurementType === measurementTypeToFilter)
		.reduce((previous, current) => {
			return current.amount < previous.amount ? current : previous;
		});
};

export const getMinimumPriceFromFerryTripDynamicPrices = (
	dynamicPrices: measurementPrice[],
	measurementTypeToFilter: measurementType = 'LENGTH',
) => {
	if (isNullOrUndefined(dynamicPrices) || dynamicPrices.length === 0) {
		return null;
	}
	return dynamicPrices
		.filter(x => x.measurementType === measurementTypeToFilter)
		.reduce((previous, current) => {
			return current.amount < previous.amount ? current : previous;
		});
};
