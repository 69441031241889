import React from 'react';
import { BookingPricingResult } from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';

export interface TripBreakdownDescriptionProps {
	bookingInfo?: BookingPricingResult;
}

export function TripBreakdownDescription({ bookingInfo }: TripBreakdownDescriptionProps) {
	if (isNullOrUndefined(bookingInfo)) {
		return <></>;
	}

	const description = evaluateDescription(bookingInfo);

	return <>{description}</>;
}

function evaluateDescription(bookingInfo: BookingPricingResult): string {
	const description: string[] = [];

	// Vehicle detail
	const vehicle = bookingInfo.priceLineItems.find(x => x.key === 'BaseCargo');
	if (isNotNullOrUndefined(vehicle)) {
		description.push(vehicle.description);
	}

	// Passenger detail
	const passengerPriceLineItem = bookingInfo.priceLineItems.find(x => x.key === 'Passenger');

	if (isNotNullOrUndefined(passengerPriceLineItem)) {
		description.push(passengerPriceLineItem.description);
	}

	// Examples:
	// "1 vehicle, 1 adult, ..."
	// "2 adults, 2 children, ..."
	return description.join(', ').trim();
}
