import { useContext } from 'react';
import {
	EventCheckInStoreContext,
	IEventCheckInStore,
} from '../Views/Components/_HumanWritten/CheckIn/EventCheckIn/EventContext/EventCheckInContext';

/**
 * This hook should be used to access the event check-in context.
 */
export default function useEventCheckInStore(): IEventCheckInStore {
	return useContext(EventCheckInStoreContext);
}
