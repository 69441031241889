/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsGiftCertificateUsageEntity from 'Models/Security/Acl/VisitorsGiftCertificateUsageEntity';
import UserGiftCertificateUsageEntity from 'Models/Security/Acl/UserGiftCertificateUsageEntity';
import StaffGiftCertificateUsageEntity from 'Models/Security/Acl/StaffGiftCertificateUsageEntity';
import ManagerGiftCertificateUsageEntity from 'Models/Security/Acl/ManagerGiftCertificateUsageEntity';
import AdminGiftCertificateUsageEntity from 'Models/Security/Acl/AdminGiftCertificateUsageEntity';
import InvoicedUserGiftCertificateUsageEntity from 'Models/Security/Acl/InvoicedUserGiftCertificateUsageEntity';
import FixedPriceUserGiftCertificateUsageEntity from 'Models/Security/Acl/FixedPriceUserGiftCertificateUsageEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IGiftCertificateUsageEntityAttributes extends IModelAttributes {
	usedValue: number;

	giftCertificateId?: string;
	giftCertificate?: Models.GiftCertificateEntity | Models.IGiftCertificateEntityAttributes;
	transactionId?: string;
	transaction?: Models.TransactionEntity | Models.ITransactionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('GiftCertificateUsageEntity', 'Gift Certificate Usage')
// % protected region % [Customise your entity metadata here] end
export default class GiftCertificateUsageEntity extends Model
	implements IGiftCertificateUsageEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsGiftCertificateUsageEntity(),
		new UserGiftCertificateUsageEntity(),
		new StaffGiftCertificateUsageEntity(),
		new ManagerGiftCertificateUsageEntity(),
		new AdminGiftCertificateUsageEntity(),
		new InvoicedUserGiftCertificateUsageEntity(),
		new FixedPriceUserGiftCertificateUsageEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Used Value'] off begin
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<GiftCertificateUsageEntity, number>()
	@CRUD({
		name: 'Used Value',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public usedValue: number;
	// % protected region % [Modify props to the crud options here for attribute 'Used Value'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] off begin
		name: 'Gift Certificate',
		displayType: 'reference-combobox',
		order: 20,
		referenceTypeFunc: () => Models.GiftCertificateEntity,
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] end
	})
	public giftCertificateId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public giftCertificate: Models.GiftCertificateEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] off begin
		name: 'Transaction',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.TransactionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Transaction'] end
	})
	public transactionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public transaction: Models.TransactionEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IGiftCertificateUsageEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IGiftCertificateUsageEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.usedValue !== undefined) {
				this.usedValue = attributes.usedValue;
			}
			if (attributes.giftCertificateId !== undefined) {
				this.giftCertificateId = attributes.giftCertificateId;
			}
			if (attributes.giftCertificate !== undefined) {
				if (attributes.giftCertificate === null) {
					this.giftCertificate = attributes.giftCertificate;
				} else if (attributes.giftCertificate instanceof Models.GiftCertificateEntity) {
					this.giftCertificate = attributes.giftCertificate;
					this.giftCertificateId = attributes.giftCertificate.id;
				} else {
					this.giftCertificate = new Models.GiftCertificateEntity(attributes.giftCertificate);
					this.giftCertificateId = this.giftCertificate.id;
				}
			}
			if (attributes.transactionId !== undefined) {
				this.transactionId = attributes.transactionId;
			}
			if (attributes.transaction !== undefined) {
				if (attributes.transaction === null) {
					this.transaction = attributes.transaction;
				} else if (attributes.transaction instanceof Models.TransactionEntity) {
					this.transaction = attributes.transaction;
					this.transactionId = attributes.transaction.id;
				} else {
					this.transaction = new Models.TransactionEntity(attributes.transaction);
					this.transactionId = this.transaction.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		giftCertificate {
			${Models.GiftCertificateEntity.getAttributes().join('\n')}
		}
		transaction {
			${Models.TransactionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(GiftCertificateUsageEntity.prototype, 'created');
CRUD(modifiedAttr)(GiftCertificateUsageEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
