import { SERVER_URL } from '../../../Constants';
import axios from 'axios';
import { buildUrl } from '../../../Util/FetchUtils';

export function downloadInvoice(transactionId: string, isEventBooking: boolean) {
	const timezone = window.Intl.DateTimeFormat().resolvedOptions().timeZone;
	window.open(`${SERVER_URL}/api/invoice/${transactionId}?isEventBooking=${isEventBooking}&timezone=${timezone}`, '_blank')?.focus();
}
export function fetchUserInvoices(userId: string, skip: number, take: number) {
	return axios.get(
		buildUrl(`${SERVER_URL}/api/invoice/get-all-invoices`,
			{ userId, skip: skip.toString(), take: take.toString() }),
	);
}

export interface UserInvoicesCountDto {
	invoices: UserInvoicesDto[],
	count: number,
}
export interface UserInvoicesDto {
	invoiceId: string,
	humanReadableId: string,
	createdDate: Date,
	invoiceAmount: number,
}
