import * as React from 'react';
import { BookingCutoffEntity } from 'Models/Entities';
import { getFormattedHoursDays } from 'Util/StringUtils';
import { confirmModal } from '../../../../Modal/ModalUtils';

export async function confirmEditPopUp(bookingCutoffEntity: BookingCutoffEntity) {
	return confirmModal(
		'Are you sure?',
		(
			<div className="edit-booking-modal">
				<h5>
					{`You can change your trip time up to ${
						getFormattedHoursDays(bookingCutoffEntity.hoursBeforeDeparture)
					} before your original departure.`}
				</h5>
				<h5>
					{`Note: Editing the date of your trip will incur a fee of ${
						bookingCutoffEntity.percentageFee}% of your original booking cost.`}
				</h5>
			</div>
		),
		{
			cancelText: 'Cancel',
			confirmText: 'Continue',
			resolveOnCancel: true,
		},
	);
}
