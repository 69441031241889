import * as React from 'react';
import { BookingEntity } from 'Models/Entities';
import { addCustomStaffBookingNote, noteInfoDto } from 'Services/Api/_HumanWritten/NotesService';
import { runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { useState } from 'react';
import useDeviceDetect from 'Hooks/useDeviceDetect';
import { useBookingNotesModal } from 'Hooks/useBookingNotesModal';
import { store } from 'Models/Store';
import alertToast from 'Util/ToastifyUtils';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { upperCaseFirst } from 'Util/StringUtils';
import moment from 'moment';
import { formatTime } from 'Util/_HumanWritten/TicketsTabTripUtils';
import { TextField } from 'Views/Components/TextBox/TextBox';

export interface BookingNotesModalProps {
	booking: BookingEntity;
}

export interface NoteProps {
	notes: noteInfoDto[];
}

interface BookingNoteInputProps {
	input: string;
	userId: string;
}

export function BookingNotesModal({
	booking,
}: BookingNotesModalProps) {
	const response = useBookingNotesModal(booking.id);
	const { isMobile } = useDeviceDetect();
	const [showNotes, setShowNotes] = useState<boolean>(!isMobile);
	const customNotesInput = useLocalStore<BookingNoteInputProps>(() => ({
		input: '',
		userId: store.userId ?? '',
	}));

	const createCustomStaffNote = async () => {
		if (booking != null && customNotesInput.input !== '') {
			const result = await addCustomStaffBookingNote({
				noteContent: customNotesInput.input,
				bookingId: booking.id,
			});

			if (result === false) {
				alertToast('Could not add new note', 'error');
			} else {
				// Reset the input back to empty string
				runInAction(() => {
					customNotesInput.input = '';
					response.refresh();
				});
			}
		}
	};

	if (!response || response.type === 'error') {
		alertToast('Failed to fetch notes for booking.', 'error');
		return <></>;
	}

	const notes = response.data as noteInfoDto[] ?? [];

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		createCustomStaffNote();
	};

	return (
		<div className="notes-modal__container">
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				key="notes-header"
				onKeyPress={() => {}}
				className="notes-modal__header"
				onClick={() => {
					runInAction(() => {
						setShowNotes(!showNotes);
					});
				}}
			>
				<div className="left-container">
					<h6 className="title">Activity</h6>
					<h6 className="note-count">{notes?.length ?? 0}</h6>
				</div>
				<Button
					className={`show-hide-notes-panel-button ${
						showNotes ? 'icon-chevron-down' : 'icon-chevron-up'
					} icon-only`}
					colors={Colors.Secondary}
					display={Display.Text}
					onClick={() => {
						runInAction(() => {
							setShowNotes(!showNotes);
						});
					}}
				/>
			</div>
			<If condition={showNotes}>
				<div className="past-notes__container">
					{renderNotes(notes, `${booking.user.firstName} ${booking.user.lastName}`)}
				</div>
				<div className="new-note__container">
					<form onSubmit={onSubmit}>
						<TextField
							id="new_note-field"
							model={customNotesInput}
							modelProperty="input"
							inputProps={{
								autoComplete: 'off',
							}}
							placeholder="Write a note..."
						/>
					</form>
					<Button
						className="add-note-button"
						colors={Colors.Secondary}
						display={Display.Text}
						onClick={createCustomStaffNote}
					>
						Add note
					</Button>
				</div>
			</If>
		</div>
	);
}

function renderNotes(notes: noteInfoDto[], bookingUsername: string) {
	const listOfNotes: JSX.Element[] = [];
	if (isNullOrUndefined(notes)) {
		return <></>;
	}
	const uniqueDates = notes.filter(
		(noteInfo, index, arr) => arr
			.findIndex(t => new Date(t.creationDateTime)
				.toDateString() === new Date(noteInfo.creationDateTime)
				.toDateString()) === index,
	);

	uniqueDates.forEach(x => {
		notes.forEach(noteInfo => {
			let backgroundNote = false;
			if ((noteInfo.content.includes('Started alteration')
					|| noteInfo.content.includes('check')
					|| noteInfo.content.includes('invoiced')
					|| noteInfo.content.includes('awaiting'))
				&& noteInfo.noteType !== 'STAFF') {
				backgroundNote = true;
			}
			if (new Date(noteInfo.creationDateTime).toDateString() === new Date(x.creationDateTime).toDateString()) {
				if (backgroundNote) {
					listOfNotes.push(
						<div className="note__container" key={noteInfo.noteId}>
							<div className="note__header background_note">
								{`${noteInfo.userName} ${noteInfo.content.toLowerCase()} at ${
									formatTime(noteInfo.creationDateTime)
								}`}
							</div>
						</div>,
					);
				} else {
					listOfNotes.push(
						<div className="note__container" key={noteInfo.noteId}>
							<div className="note__header">
								<div className="note-author">
									<If condition={noteInfo.locationType === 'CHECK_IN'}>
										<div className="issue-icon grey-icon" />
									</If>
									<div>{noteInfo.noteType === 'COMMUNICATION' && bookingUsername === noteInfo.userName
										? 'System'
										: noteInfo.userName}
									</div>
								</div>
								<p className="note-created-time">{formatTime(noteInfo.creationDateTime)}</p>
							</div>
							<div className={`note__content ${backgroundNote ? 'expire-reservation-note' : ''}`}>
								{convertContentToParagraphs(noteInfo.content)}
							</div>
							<If condition={!backgroundNote}>
								<div className={`note__footer ${noteInfo.noteType.toLowerCase()}`}>
									{upperCaseFirst(noteInfo.noteType.toLowerCase())}
								</div>
							</If>
						</div>,
					);
				}
			}
		});
		// Add the date header for the above notes (sorry it's backwards)
		listOfNotes.push(
			<div className="date-line" key={new Date(x.creationDateTime).toDateString()}>
				<p className="date-header">{moment(x.creationDateTime).format('DD MMM YYYY').toUpperCase()}</p>
			</div>,
		);
	});

	return listOfNotes;
}

const convertContentToParagraphs = (content: string) => {
	const listOfStrings = content.split('\n');
	const listOfParagraphs: JSX.Element[] = [];
	let count = 0;
	listOfStrings.forEach(paragraph => {
		if (paragraph !== '') {
			count++;
			listOfParagraphs.push(<p key={count}>{paragraph}</p>);
		}
	});
	return listOfParagraphs;
};
