import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export function AllCheckedIn() {
	return (
		<div className="check-in__empty">
			<Icon name="check-box" />
			<h4>All checked in!</h4>
			<p className="check-in__empty__subtitle">There is no one left to check in.</p>
		</div>
	);
}
