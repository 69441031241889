/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Add extra imports here] on begin
import { isNotNullOrUndefined, isNullOrUndefined } from './TypeGuards';
import { formatNumber, ParsedNumber, parseNumber } from 'libphonenumber-js';

export function shortURL(url: string) {
	return url.replace(/((http:\/\/)?(https:\/\/)?(www\.)?)/g, '')
		.replace(/(\/)*(?!.+)/g, '');
}
// % protected region % [Add extra imports here] end

export function lowerCaseFirst(str: string) {
	return str[0].toLowerCase() + str.slice(1);
}

export function upperCaseFirst(str: string) {
	if (str.length === 0) {
		return '';
	}
	return str[0].toUpperCase() + str.slice(1);
}

export function camelCaseIntoWords(name: string) {
	return name.replace(/([A-Z])/g, ' $1')
		.replace(/^./, str => str.toUpperCase());
}

export function camelCase(str: string) {
	const words = str.split(' ');
	const allLowerCase = words.map(w => w.toLowerCase());
	return allLowerCase[0] + allLowerCase.slice(1).map(upperCaseFirst).join('');
}

export function lowerCaseNoSpaces(str: string) {
	return str.toLowerCase().replace(/ /g, '');
}

export function noSpaces(str: string) {
	return str.replace(/ /g, '');
}

/**
 * Formats a number of bytes as a string
 * Implementation taken from https://stackoverflow.com/a/18650828/8426638
 * @param bytes The byte amount to format
 * @param decimals The number of decimals allowed
 */
export function formatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	// eslint-disable-next-line prefer-template
	return parseFloat((bytes / (k ** i)).toFixed(dm)) + ' ' + sizes[i];
}

// % protected region % [Add extra StringUtil functions here] on begin
/**
 * Determines full name with fallback options.
 *
 * - If last name is null, then return first name.
 * - If first name is null, then return last name.
 * - If showLastNameFirst is true, then format return as 'lastName, firstName'
 * - Otherwise, returns full name as 'firstName lastName'
 */
export function getFullName(firstName?: string, lastName?: string, showLastNameFirst: boolean = false): string | null {
	if (isNullOrUndefined(lastName) || lastName === '') {
		return isNotNullOrUndefined(firstName) ? upperCaseFirst(firstName) : null;
	}
	if (isNullOrUndefined(firstName) || firstName === '') {
		return upperCaseFirst(lastName);
	}
	if (showLastNameFirst) {
		return `${upperCaseFirst(lastName)}, ${upperCaseFirst(firstName)}`;
	}
	return `${upperCaseFirst(firstName)} ${upperCaseFirst(lastName)}`;
}

/**
 * Converts the hours to days format (if less than 72hrs)
 *
 * - If less than 72hrs it will appear as ## hours
 * - If more than 72hrs it will appears as ## days
 */
export function getFormattedHoursDays(hours: number): string {
	if (hours <= 72) {
		return `${hours} hour${hours > 1 ? 's' : ''}`;
	}
	const days = hours / 24;
	return `${days.toFixed()} day${days > 1 ? 's' : ''}`;
}

/**
 * Checks that there isn't any white space in the middle of the name
 * or any special characters (only allowing `-` hyphens)
 *
 * Primarily used for driver first and last name as we only
 * want the user to be able to enter one name in each field.
 */
export function checkIfInvalidName(input: string): boolean {
	const validRegex = new RegExp('[^A-Za-z-]');
	return validRegex.test(input);
}

/**
 * Formats a phone number based on it's country code.
 */
export function FormatPhone(phone: string): string {
	if (isNullOrUndefined(phone)) {
		return '';
	}
	const parsedNumber = parseNumber(phone);

	// If it is not an Australian number, it needs to be formatted in international formatting (with the country code)
	if (Object.keys(parsedNumber).length > 0 && (parsedNumber as ParsedNumber)?.country !== 'AU') {
		return formatNumber(parsedNumber as ParsedNumber, 'INTERNATIONAL');
	}

	// If it is an Australian number, it will be formatted without the country code
	return formatNumber(phone, 'AU', 'NATIONAL');
}
// % protected region % [Add extra StringUtil functions here] end
