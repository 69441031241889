import { observer } from 'mobx-react';
import * as React from 'react';
import SecuredPage from '../Components/Security/SecuredPage';
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import { RouteComponentProps } from 'react-router-dom';
import { SearchResultsWrap } from '../Components/_HumanWritten/SearchResults/SearchResultsWrap';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { SEARCH_RESULTS_PAGE_TITLE } from '../../ConstantPageNames';

export interface SearchResultsPageProps extends RouteComponentProps {}

interface SearchParams {
	id: string
}

@observer
class SearchResultsPage extends React.Component<SearchResultsPageProps> {
	render() {
		const {
			match,
			location,
			history,
			staticContext,
		} = this.props;

		document.title = SEARCH_RESULTS_PAGE_TITLE;

		return (
			<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
				{
				}
				<NavigationWrapper
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				{
				}
				<div className="body-content">
					<SearchResultsWrap />
				</div>
			</SecuredPage>
		);
	}
}

export default SearchResultsPage;
