import React from 'react';
import { stringIsEmpty } from 'Util/TypeGuards';
import { InformationBox, informationBoxStatus } from '../../../InformationBox/InformationBox';

export type StateOfBooking = 'checked-in' | 'invoiced' | 'closed' | 'unpaid' | 'expired' | 'standard'

export function GetInformationBox(stateOfBooking: StateOfBooking, date?: Date) {
	if (stateOfBooking === 'standard') {
		return <></>;
	}

	let header = 'Booking is closed for modifications';
	let body = '';
	let status: informationBoxStatus = 'information';

	switch (stateOfBooking) {
		case 'checked-in':
			body = 'This booking can no longer be modified online, as the booking has already been '
				+ 'checked-in to the ferry. If you think there is an error, please reach out to our staff for '
				+ 'assistance.';
			break;
		case 'invoiced':
			body = 'This booking can no longer be modified online as it has already been invoiced. '
				+ 'If you think there is an error, please reach out to our staff for assistance.';
			break;
		case 'closed':
			body = 'This booking can no longer be modified online as the ferry trip has closed for bookings. '
				+ 'If you\'d like to modify your booking, please reach out to our staff for assistance.';
			break;
		case 'expired':
			header = 'Cancellation notice: unpaid booking';
			body = 'This booking has been automatically cancelled due to non-payment within the reserved time period.';
			status = 'warning';
			break;
		// TODO
		case 'unpaid':
			header = `Booking reserved until ${date}`;
			body = 'This booking has been automatically cancelled due to non-payment within the reserved time period.';
			status = 'information';
			break;
	}

	if (stringIsEmpty(header) || stringIsEmpty(body) || stringIsEmpty(status)) {
		return <></>;
	}

	return (
		<InformationBox
			header={header}
			body={body}
			status={status}
		/>
	);
}
