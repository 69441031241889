import { BookingEntity } from '../../../Models/Entities';

export function getTotalPassengerCountFromBooking(booking: BookingEntity) {
	const adults = booking.bookedSummary?.adultPassengerCount ?? 0;
	const children = booking.bookedSummary?.childPassengerCount ?? 0;
	const infants = booking.bookedSummary?.infantPassengerCount ?? 0;
	const passengerD = booking.bookedSummary?.passengerCountD ?? 0;
	const passengerE = booking.bookedSummary?.passengerCountE ?? 0;
	const passengerF = booking.bookedSummary?.passengerCountF ?? 0;
	const passengerG = booking.bookedSummary?.passengerCountG ?? 0;
	const passengerH = booking.bookedSummary?.passengerCountH ?? 0;

	return adults + children + infants + passengerD + passengerE + passengerF + passengerG + passengerH;
}
