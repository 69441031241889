import classNames from 'classnames';
import * as React from 'react';

export interface StickyBottomBarProps {
	className?: string;
	children: React.ReactNode;
}
export function StickyBottomBar({ className, children }: StickyBottomBarProps) {
	return (
		<div className={classNames('sticky-bottom-bar', className)}>
			{children}
		</div>
	);
}
