import * as React from 'react';
import { FerryTripImportResponse } from 'Models/_HumanWritten/FerryTripImport/FerryTripImportResponse';
import Modal from 'Views/Components/Modal/Modal';
import { FerryTripImporterModalContents } from './FerryTripImporterModalContents';

export interface FerryTripImporterModalProps {
	showModal: boolean;
	importResponse?: FerryTripImportResponse;
	onContinue?: () => void;
	onReset?: () => void;
	onRequestClose: () => void;
}

export function FerryTripImporterModal({
	showModal,
	importResponse,
	onContinue,
	onReset,
	onRequestClose,
}: FerryTripImporterModalProps) {
	if (!showModal || importResponse === undefined || !importResponse.hasErrors()) {
		return null;
	}

	return (
		<Modal
			isOpen
			label="ferry-trip-import-side-bar-preview"
			className="ferry-trip-import-modal"
			onRequestClose={onRequestClose}
		>
			<FerryTripImporterModalContents
				importResponse={importResponse}
				onContinue={onContinue}
				onReset={onReset}
				onRequestClose={onRequestClose}
			/>
		</Modal>
	);
}
