import { store } from 'Models/Store';

const showLightNavBarOnPage = (isMobile: boolean) => {
	const pathname = store?.routerHistory?.location?.pathname.toString();

	if (pathname && (pathname.includes('home')
		|| pathname.includes('booking-success')
		|| pathname.includes('booking-cancelled-success'))) {
		return true;
	}

	if (isMobile) {
		return false;
	}

	return !!(pathname && (
		pathname.includes('booking-wizard')
		|| pathname.includes('gift-payment')
	));
};

export default showLightNavBarOnPage;
