import React from 'react';
import CheckInTopNav from '../CheckInTopNav';
import { Route, Switch } from 'react-router';
import CheckInAddForm from './CheckInAddForm';
import CheckInAddSelectUser from './CheckInAddSelectUser';
import useCheckInStore from 'Hooks/useCheckInStore';
import { runInAction } from 'mobx';

function CheckInAdd() {
	const pathTemplate = '/check-in/:id/add';
	const checkInStore = useCheckInStore();

	if (!checkInStore.usingAddFlow) {
		// Reset form state every time we enter Add flow
		checkInStore.setFormState();
	}

	React.useEffect(() => {
		if (!checkInStore.usingAddFlow) {
			runInAction(() => {
				//
				// This will be set to false in CheckInPage watcher when the route is outside of Add flow
				//
				checkInStore.usingAddFlow = true;
			});
		}
	}, [checkInStore.usingAddFlow]);

	return (
		<div id="check-in-body" className="body-content check-in">
			<CheckInTopNav />
			<Switch>
				<Route path={`${pathTemplate}/select-customer`}>
					<CheckInAddSelectUser />
				</Route>
				<Route path={`${pathTemplate}`}>
					<CheckInAddForm />
				</Route>
			</Switch>
		</div>
	);
}

export default CheckInAdd;
