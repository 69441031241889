import * as React from 'react';
import { observer } from 'mobx-react';
import { DateTimePicker } from 'Views/Components/DateTimePicker/DateTimePicker';
import { store } from 'Models/Store';
import { FerryTripEntity } from 'Models/Entities';

export interface TripDetailsStartDateTimeSelectorProps {
	ferryTrip: FerryTripEntity;
	isDisabled: boolean;
}

/**
 * There is a react bug related to DateTimePicker component. It does not re-render the correct state when the
 * isDisabled property changes.
 *
 * As a compromise, we have two different instances of DateTimePicker. One with disabled on and disabled off.
 */
function TripDetailsStartDateTimeSelector({ ferryTrip, isDisabled }: TripDetailsStartDateTimeSelectorProps) {
	if (isDisabled) {
		return (
			<DateTimePicker
				key="tart-date-time-selector-disabled"
				className="start-date-time-selector"
				id="start-date-time-selector"
				model={ferryTrip}
				modelProperty="departureDateTime"
				label="Start trip"
				placeholder="Trip start date"
				isDisabled
				humanFriendly
				altFormat="d M Y H:i"
				flatpickrOptions={{
					minDate: store.isStaff ? '' : 'today',
					position: 'auto left',
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
			/>
		);
	}

	return (
		<DateTimePicker
			key="tart-date-time-selector"
			className="start-date-time-selector"
			id="start-date-time-selector"
			model={ferryTrip}
			modelProperty="departureDateTime"
			label="Start trip"
			isRequired
			placeholder="Trip start date"
			humanFriendly
			altFormat="d M Y H:i"
			flatpickrOptions={{
				minDate: store.isStaff ? '' : 'today',
				position: 'auto left',
				monthSelectorType: 'static',
				allowInput: false,
				locale: {
					firstDayOfWeek: 1,
					weekdays: {
						shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
						longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
					},
				},
				disableMobile: true,
			}}
		/>
	);
}

export default observer(TripDetailsStartDateTimeSelector);
