/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminEventLabelEntity from 'Models/Security/Acl/AdminEventLabelEntity';
import ManagerEventLabelEntity from 'Models/Security/Acl/ManagerEventLabelEntity';
import StaffEventLabelEntity from 'Models/Security/Acl/StaffEventLabelEntity';
import UserEventLabelEntity from 'Models/Security/Acl/UserEventLabelEntity';
import InvoicedUserEventLabelEntity from 'Models/Security/Acl/InvoicedUserEventLabelEntity';
import FixedPriceUserEventLabelEntity from 'Models/Security/Acl/FixedPriceUserEventLabelEntity';
import VisitorsEventLabelEntity from 'Models/Security/Acl/VisitorsEventLabelEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEventLabelEntityAttributes extends IModelAttributes {
	label: string;
	iconId: string;
	icon: Blob;
	colour: string;
	hidden: boolean;

	eventDetails: Array<
		| Models.EventDetailEntity
		| Models.IEventDetailEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EventLabelEntity', 'Event Label')
// % protected region % [Customise your entity metadata here] end
export default class EventLabelEntity extends Model
	implements IEventLabelEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminEventLabelEntity(),
		new ManagerEventLabelEntity(),
		new StaffEventLabelEntity(),
		new UserEventLabelEntity(),
		new InvoicedUserEventLabelEntity(),
		new FixedPriceUserEventLabelEntity(),
		new VisitorsEventLabelEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Label'] off begin
	/**
	 * The label that will appear with this event.
	 */
	@Validators.Required()
	@observable
	@attribute<EventLabelEntity, string>()
	@CRUD({
		name: 'Label',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public label: string;
	// % protected region % [Modify props to the crud options here for attribute 'Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Icon'] on begin
	/**
	 * The icon that will display for this event type.
	 */
	@observable
	@attribute<EventLabelEntity, string>({ file: 'icon' })
	@CRUD({
		name: 'Icon',
		displayType: 'file',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'icon',
		displayFunction: attr => attr
			// eslint-disable-next-line max-len
			? (
				<img
					src={`${SERVER_URL}/api/files/${attr}`}
					alt="The icon that will display for this event type."
					style={{
						filter: 'invert(1) grayscale(1) brightness(0) saturate(100%)',
						maxWidth: '300px',
						WebkitFilter: 'invert(1) grayscale(1) brightness(0) saturate(100%)',
					}}
				/>
			)
			: 'No File Attached',
	})
	public iconId: string;

	@observable
	public icon: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Icon'] end

	// % protected region % [Modify props to the crud options here for attribute 'Colour'] off begin
	/**
	 * The colour that this event will use.
	 */
	@Validators.Required()
	@observable
	@attribute<EventLabelEntity, string>()
	@CRUD({
		name: 'Colour',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public colour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] on begin
	/**
	 * Whether or not this event label will cause the event to be private.
	 */
	@observable
	@attribute<EventLabelEntity, boolean>()
	@CRUD({
		name: 'Hidden',
		displayType: 'hidden',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hidden: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] on begin
		name: 'Event Details',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.EventDetailEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'eventDetails',
			oppositeEntity: () => Models.EventDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] end
	})
	public eventDetails: Models.EventDetailEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEventLabelEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEventLabelEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.label !== undefined) {
				this.label = attributes.label;
			}
			if (attributes.icon !== undefined) {
				this.icon = attributes.icon;
			}
			if (attributes.iconId !== undefined) {
				this.iconId = attributes.iconId;
			}
			if (attributes.colour !== undefined) {
				this.colour = attributes.colour;
			}
			if (attributes.hidden !== undefined) {
				this.hidden = attributes.hidden;
			}
			if (attributes.eventDetails !== undefined && Array.isArray(attributes.eventDetails)) {
				for (const model of attributes.eventDetails) {
					if (model instanceof Models.EventDetailEntity) {
						this.eventDetails.push(model);
					} else {
						this.eventDetails.push(new Models.EventDetailEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = '';
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			eventDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'eventDetails',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EventLabelEntity.prototype, 'created');
CRUD(modifiedAttr)(EventLabelEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
