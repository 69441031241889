import React from 'react';
import { observer } from 'mobx-react';
import { DisplayType } from 'Views/Components/Models/Enums';
import moment from 'moment';
import { DatePicker } from './DatePicker';
import type { StringKeysOfT } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import CalendarSvg from '../../../images/calender.svg';

export interface CustomDateSelectorProps<T> {
	model: Record<StringKeysOfT<T, Date>, Date>
	dateProperty: StringKeysOfT<T, Date>;
}

// eslint-disable-next-line prefer-arrow-callback,func-names
export const CustomDateSelector = observer(function<T> ({ model, dateProperty }: CustomDateSelectorProps<T>) {
	return (
		<div className="custom-date-selector__inner">
			<DatePicker
				model={model}
				modelProperty={dateProperty}
				name="customDateSelector"
				className="date-picker__general calendar-date-selector"
				displayType={DisplayType.INLINE}
				labelVisible
				flatpickrOptions={{
					minDate: store.isStaff ? '' : 'today',
					position: 'below left',
					monthSelectorType: 'static',
					allowInput: false,
					locale: {
						firstDayOfWeek: 1,
						weekdays: {
							shorthand: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
							longhand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
						},
					},
					disableMobile: true,
				}}
				flatpickrProps={{
					value: model[dateProperty],
					render: (props, ref) => {
						return (
							<button
								className="unstyled-button trip-details-calendar-row"
								ref={ref as any}
							>
								<CustomDateRangeFilter
									model={model}
									dateProperty={dateProperty}
								/>
							</button>
						);
					},
				}}
			/>
		</div>
	);
});

// eslint-disable-next-line prefer-arrow-callback,func-names
const CustomDateRangeFilter = observer(function<T> ({ model, dateProperty }: CustomDateSelectorProps<T>) {
	const endDate = moment(model[dateProperty]).add(6, 'days').toDate();
	let dateString = '';

	if (endDate.getMonth() !== model[dateProperty].getMonth()) {
		dateString = `${moment(model[dateProperty]).format('DD MMM')} - ${moment(endDate).format('DD MMM YYYY')}`;
	} else {
		dateString = `${moment(model[dateProperty]).format('DD')} - ${moment(endDate).format('DD MMM YYYY')}`;
	}

	return (
		<div className="custom-date-selector__container">
			<p className="date-range-input__label">
				Schedule from
			</p>

			<div className="date-range-input__input">
				<img
					className="date-calendar-icon"
					src={CalendarSvg}
					alt="calendar-icon"
				/>
				<p>{dateString}</p>
			</div>
		</div>
	);
});
