import React, { Component, ErrorInfo, ReactNode } from 'react';
import { SomethingWentWrong } from './Components/ErrorHandling/SomethingWentWrong';
import { isNotNullOrUndefined } from '../Util/TypeGuards';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
	error: Error | null,
}

class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
		};
	}

	public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error: error };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		this.state = { hasError: true, error: error };
	}

	public render() {
		const { hasError, error } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<>
					<SomethingWentWrong error={error} />
				</>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
