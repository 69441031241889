import * as React from 'react';
import alertToast from 'Util/ToastifyUtils';
import { GreenCircleTickIcon } from 'Views/Components/_HumanWritten/Icon/GreenTickIcon';
import { isNotNullOrUndefined, stringNotEmpty } from 'Util/TypeGuards';
import { formatDateDayMonth, formatShortTime } from 'Util/_HumanWritten/TimeUtils';
import { ToastOptions } from 'react-toastify';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import If from 'Views/Components/If/If';
import { FormatPhone } from 'Util/StringUtils';
import { getCheckInData } from 'Services/Api/_HumanWritten/CheckInService';
import { CheckInBookingOverviewDto, ICheckInBookingOverviewDtoDto } from './CheckInEntities/CheckInBookingOverviewDto';
import { FerryTripDto } from './CheckInEntities/FerryTripDto';
import { AdditionalBookingOptionEntity, BookingEntity } from 'Models/Entities';
import {
	getMeasurementValueFromId,
	getMinimumMeasurementFromStore,
} from 'Util/_HumanWritten/MeasurementUtils';
import { Tag } from './CheckInList/Tag';

// ==================================================== Models ===================================================== //

export interface DisableContinueState {
	continue: boolean;
}

export enum CheckInSorter {
	Default = 'Default',
	Fullname = 'Fullname',
	FullnameDesc = 'FullnameDesc',
	Rego = 'Rego',
	Trailer = 'Trailer',
	TrailerDesc = 'TrailerDesc',
	Vehicle = 'Vehicle',
	VehicleDesc = 'VehicleHighest',
	VehicleType = 'VehicleType',
	VehicleTypeDesc = 'VehicleTypeDesc',
}

export const getCheckInTableHeader = (x: CheckInSorter): CheckInSorter => {
	switch (x) {
		case CheckInSorter.Default:
		case CheckInSorter.Fullname:
		case CheckInSorter.FullnameDesc:
			return CheckInSorter.Fullname;
		case CheckInSorter.Rego:
			return CheckInSorter.Rego;
		case CheckInSorter.VehicleType:
		case CheckInSorter.VehicleTypeDesc:
			return CheckInSorter.VehicleType;
		case CheckInSorter.Trailer:
		case CheckInSorter.TrailerDesc:
			return CheckInSorter.Trailer;
		case CheckInSorter.Vehicle:
		case CheckInSorter.VehicleDesc:
			return CheckInSorter.Vehicle;
		default:
			return CheckInSorter.Fullname;
	}
};

// ================================================== GQL Fetches ================================================== //

interface FetchDataResult {
	ferryTripDto: FerryTripDto;
	bookingSummaries: CheckInBookingOverviewDto[];
}

/**
 * Returns ferry trip and booked bookings based on ferry trip id.
 */
export async function fetchCheckInData(ferryTripId?: string): Promise<FetchDataResult | null> {
	if (!ferryTripId) {
		return new Promise(resolve => resolve(null));
	}

	const response = await getCheckInData(ferryTripId);

	// Process entities as a class to access class functions.
	const ferryTrip = new FerryTripDto(response?.data.ferryTrip);
	const bookings = response?.data?.checkInBookingOverviews
		?.filter((x: any) => isNotNullOrUndefined(x))
		?.map((x: Partial<ICheckInBookingOverviewDtoDto> | undefined) => new CheckInBookingOverviewDto(x));
	const filteredbookings = filterBookedBookings(bookings);

	return {
		ferryTripDto: ferryTrip,
		bookingSummaries: filteredbookings,
	};
}

/**
 * Process has three steps in order:
 * - Filters bookings by BOOKED
 * - Instantiate class for each
 * - Order list by full name
 * @param bookings This list of bookings may not have instantiated classes.
 */
function filterBookedBookings(bookings: CheckInBookingOverviewDto[]): CheckInBookingOverviewDto[] {
	const bookedBookings = bookings; // [
	// 	...bookings
	// 		.filter(x => x.bookingStatus === 'BOOKED')
	// 		.map(x => new BookingEntity(x)),
	// ];
	bookedBookings.sort(newSortByFullName());

	return bookedBookings;
}
// =================================================== Reducers ==================================================== //

export function getTotalPassengers(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.bookedSummary) {
			throw new Error('Booked summary missing');
		}
		return currentValue + passengerTypeStore.getTotalFromBookingSummary(booking.bookedSummary);
	}, 0);
}

export function checkedInPassengers(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.checkedIn) {
			return currentValue;
		}
		if (!booking.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		return currentValue + passengerTypeStore.getTotalFromBookingSummary(booking.bookedSummary);
	}, 0);
}
export function totalVehicleBookings(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		return round(currentValue + vehicleBookingSpace(booking), 1);
	}, 0);
}

export function totalCargoItemsCount(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		const vehicleItems = vehicleBookingItems(booking);
		return currentValue + vehicleItems;
	}, 0);
}

export function checkedInVehicleBookings(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.checkedIn) {
			return currentValue;
		}
		return round(currentValue + vehicleBookingSpace(booking), 1);
	}, 0);
}

export function checkedInCargoItemsCount(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.checkedIn) {
			return currentValue;
		}
		if (!booking.bookedSummary) {
			throw new Error('Booked summary missing');
		}

		const vehicleItems = vehicleBookingItems(booking);
		return currentValue + vehicleItems;
	}, 0);
}

export function totalVehicleWeight(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue: number, booking: BookingEntity | CheckInBookingOverviewDto) => {
		return round(currentValue + vehicleBookingWeight(booking), 1);
	}, 0);
}

export function checkedInVehicleWeight(bookings: CheckInBookingOverviewDto[]): number {
	return bookings.reduce((currentValue, booking) => {
		if (!booking.checkedIn) {
			return currentValue;
		}
		return round(currentValue + vehicleBookingWeight(booking), 1);
	}, 0);
}
function vehicleBookingSpace(booking: CheckInBookingOverviewDto): number {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	let value = 0;
	const { cargoInfo, towOnInfo } = booking.bookedSummary;

	if (cargoInfo && cargoInfo.selectedLengthId) {
		value += getMeasurementValueFromId(cargoInfo.selectedLengthId);
	}
	if (towOnInfo && towOnInfo.selectedLengthId) {
		value += getMeasurementValueFromId(towOnInfo.selectedLengthId);
	}

	return value;
}

function vehicleBookingItems(booking: CheckInBookingOverviewDto): number {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	let value = 0;
	const { cargoInfo, towOnInfo } = booking.bookedSummary;

	if (cargoInfo) {
		value++;
	}
	if (towOnInfo && towOnInfo.selectedLengthId) {
		value++;
	}

	return value;
}

function vehicleBookingWeight(booking: BookingEntity | CheckInBookingOverviewDto): number {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	let value = 0;
	const { cargoInfo, towOnInfo } = booking.bookedSummary;

	if (cargoInfo && cargoInfo.selectedWeightId) {
		value += getMeasurementValueFromId(cargoInfo.selectedWeightId);
	}
	if (towOnInfo) {
		value += getMinimumMeasurementFromStore('WEIGHT')?.value ?? 0;
	}

	return value;
}

function round(value: number, precision: number) {
	const multiplier = 10 ** precision;
	return Math.round(value * multiplier) / multiplier;
}

// ========================================== Table cell transformations =========================================== //

export function transformCheckInStatus(booking: BookingEntity | CheckInBookingOverviewDto) {
	if (booking.checkedIn) {
		return <GreenCircleTickIcon />;
	}
	return null;
}

/**
 * If booking has a vehicle, driver name is used.
 *
 * Otherwise user account name is used.
 */
export function transformFullName(booking: BookingEntity | CheckInBookingOverviewDto, includeAddOnTags: boolean = true) {
	const name = booking.getFullName(true);

	if (!name) {
		return null;
	}

	return (
		<div className="fullname-cell">
			{name}
			<If condition={booking.firstTimeOnRoute}>
				<Tag
					text="NEW"
					tagClassName="tag--new"
				/>
			</If>
			<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
				<Tag
					text={booking?.user?.userDisplayName?.displayName}
					customStyles={{ background: booking?.user?.userDisplayName?.tagColour }}
				/>
			</If>
			<If condition={includeAddOnTags && isAnyAddOnsToShow(booking)}>
				{
					getAddOnsToShow(booking)
						?.map(addOn => {
							return (
								<Tag
									key={addOn.option.id}
									text={stringNotEmpty(addOn.option.abbreviation)
										? addOn.option.abbreviation
										: addOn.option.name}
									customStyles={{ background: addOn?.option?.colour }}
								/>
							);
						})
				}
			</If>
		</div>
	);
}

export function transformMobileNumber(booking: BookingEntity | CheckInBookingOverviewDto) {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	const { bookedSummary } = booking;
	const isVehicleBooking = isNotNullOrUndefined(bookedSummary.cargoInfo);

	let phone;

	if (isVehicleBooking) {
		phone = bookedSummary.driverPhone;
	} else {
		phone = booking.user.phone;
	}

	if (phone === '' || phone === null) {
		return '-';
	}

	return FormatPhone(phone);
}

export function transformVehicleMakeModel(booking: BookingEntity | CheckInBookingOverviewDto) {
	if (!booking.bookedSummary) {
		throw new Error('Booked summary missing');
	}

	const { bookedSummary } = booking;
	const isVehicleBooking = isNotNullOrUndefined(bookedSummary.cargoInfo);

	let vehicleMakeModel = null;

	if (isVehicleBooking) {
		const make = bookedSummary?.cargoInfo?.cargoType.cargoMake;
		const model = bookedSummary?.cargoInfo?.cargoType.cargoModel;
		vehicleMakeModel = `${make} ${model}`;
	}

	if (vehicleMakeModel === '' || vehicleMakeModel === null) {
		return '-';
	}

	return vehicleMakeModel;
}

export function transformRego(booking: BookingEntity | CheckInBookingOverviewDto) {
	if (!booking || !booking.bookedSummary) {
		return null;
	}
	const { cargoInfo } = booking.bookedSummary;
	if (!cargoInfo) {
		return null;
	}
	return cargoInfo.cargoIdentification;
}

export function transformVehicleSpace(booking: BookingEntity | CheckInBookingOverviewDto, showWeight: boolean = false) {
	if (!booking || !booking.bookedSummary) return null;
	const { cargoInfo } = booking.bookedSummary;
	if (!cargoInfo) {
		return null;
	}
	return showWeight
		? getMeasurementValueFromId(cargoInfo.selectedWeightId)
		: getMeasurementValueFromId(cargoInfo.selectedLengthId);
}

export function transformTrailerSpace(booking: CheckInBookingOverviewDto | BookingEntity, showWeight: boolean = false) {
	if (!booking || !booking.bookedSummary) {
		return null;
	}
	const { towOnInfo } = booking.bookedSummary;
	if (!towOnInfo) {
		return null;
	}
	return showWeight
		? getMinimumMeasurementFromStore('WEIGHT')?.value
		: getMeasurementValueFromId(towOnInfo.selectedLengthId);
}

export function transformPassengers(booking: BookingEntity | CheckInBookingOverviewDto) {
	return passengerTypeStore.getCheckInTableRow(booking.bookedSummary);
}

/**
 * Returns date time as "02 Feb, 13:45"
 */
export function transformTrip(booking: CheckInBookingOverviewDto | BookingEntity) {
	if (!booking.bookedSummary || !booking.bookedSummary.ferryTrip) {
		throw new Error('Booked summary ferry trip missing');
	}

	const { departureDateTime } = booking.bookedSummary.ferryTrip;
	const date = new Date(departureDateTime);

	return `${formatDateDayMonth(date)}, ${formatShortTime(date, true)}`;
}

/**
 * Returns first letter of booking's assigned name (vehicle booking uses driver last name,
 * passenger booking uses user's last name).
 */
export function firstLetterLastName(booking: BookingEntity | CheckInBookingOverviewDto) {
	const bookingName = booking.getFullName(true) ?? '';
	return bookingName.charAt(0);
}

// ================================================ Sort functions ================================================= //

export type BookingSorter = (a: CheckInBookingOverviewDto, b: CheckInBookingOverviewDto) => number;
export function newSortByFullName(descending: boolean = false): BookingSorter {
	return (a: CheckInBookingOverviewDto, b: CheckInBookingOverviewDto) => {
		// Convert to upper case so that it sorts based on letters, not characters
		// e.g. Kate and karlo appears in the same sub-section, otherwise there will be two groups,
		// one for lowercase, another for uppercase
		const fullnameA = a.getFullName()?.toUpperCase() ?? '';
		const fullnameB = b.getFullName()?.toUpperCase() ?? '';
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByFullName(descending: boolean = false): BookingSorter {
	return (a: BookingEntity | CheckInBookingOverviewDto, b: BookingEntity | CheckInBookingOverviewDto) => {
		// Convert to upper case so that it sorts based on letters, not characters
		// e.g. Kate and karlo appears in the same sub-section, otherwise there will be two groups,
		// one for lowercase, another for uppercase
		const fullnameA = (a?.getFullName(true) ?? '').toUpperCase();
		const fullnameB = (b?.getFullName(true) ?? '').toUpperCase();
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByVehicleType(descending: boolean = false): BookingSorter {
	return (a: BookingEntity | CheckInBookingOverviewDto, b: BookingEntity | CheckInBookingOverviewDto) => {
		// Convert to upper case so that it sorts based on letters, not characters
		// e.g. Toyota and toyota appears in the same sub-section, otherwise there will be two groups,
		// one for lowercase, another for uppercase
		const vehicleA = (`${a?.getDisplayVehicleMake()} ${a?.getDisplayVehicleModel()}` ?? '').toUpperCase();
		const vehicleB = (`${b?.getDisplayVehicleMake()} ${b?.getDisplayVehicleModel()}` ?? '').toUpperCase();
		if (vehicleA > vehicleB) return descending ? -1 : 1;
		if (vehicleA < vehicleB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByRego(descending: boolean = false): BookingSorter {
	return (a: BookingEntity | CheckInBookingOverviewDto, b: BookingEntity | CheckInBookingOverviewDto) => {
		const fullnameA = (transformRego(a) ?? '').toUpperCase();
		const fullnameB = (transformRego(b) ?? '').toUpperCase();
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByTrailer(descending: boolean = false): BookingSorter {
	return (a: CheckInBookingOverviewDto, b: CheckInBookingOverviewDto) => {
		const fullnameA = transformTrailerSpace(a) ?? '';
		const fullnameB = transformTrailerSpace(b) ?? '';
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

export function sortByCar(descending: boolean = false): BookingSorter {
	return (a: CheckInBookingOverviewDto, b: CheckInBookingOverviewDto) => {
		const fullnameA = transformVehicleSpace(a) ?? '';
		const fullnameB = transformVehicleSpace(b) ?? '';
		if (fullnameA > fullnameB) return descending ? -1 : 1;
		if (fullnameA < fullnameB) return descending ? 1 : -1;
		return 0;
	};
}

// ================================================ Other functions ================================================ //

export const CHECK_IN_ALERT_CONFIG: ToastOptions = {
	autoClose: 2200,
	pauseOnFocusLoss: false,
	pauseOnHover: false,
	position: 'top-left',
};

/**
 * Display notification for two actions.
 *
 * If checkedIn is true, message says "John Smith checked in".
 *
 * Otherwise, message says "John Smith undo check-in".
 *
 * Notification will last 2 seconds and appears top-left of screen.
 */
export function checkInAlert(booking: BookingEntity | CheckInBookingOverviewDto, checkedIn: boolean) {
	alertToast(
		// Uses driver name, otherwise user name.
		booking.getFullName(true),
		checkedIn ? 'success' : undefined,
		checkedIn ? 'Checked in' : 'Undo check-in',
		CHECK_IN_ALERT_CONFIG,
	);
	if (checkedIn) {
		PlayCheckInAudio();
	}
}

export function isAnyAddOnsToShow(booking: BookingEntity | CheckInBookingOverviewDto) {
	return booking.bookedSummary?.additionalBookingOptions?.some(x => x.option?.showInCheckIn ?? false);
}
export function getAddOnsToShow(booking: BookingEntity | CheckInBookingOverviewDto) {
	return booking.bookedSummary?.additionalBookingOptions?.filter(x => x.option?.showInCheckIn ?? false);
}

export function transformAddOns(booking: BookingEntity | CheckInBookingOverviewDto, addOns: AdditionalBookingOptionEntity[]) {
	if (!booking.bookedSummary) {
		return null;
	}
	const values: string[] = [];
	const addOnsOnBooking = booking.bookedSummary.additionalBookingOptions;

	for (const addOn of addOns) {
		const value = addOnsOnBooking.find(x => x.option.id === addOn.id)?.quantity ?? 0;
		if (value && value !== 0) {
			values.push(value.toString());
		} else {
			// Use '-' as placeholder when value is undefined or 0
			values.push('-');
		}
	}
	return values.join(' | ');
}

export function addOnsHeader(addOns: AdditionalBookingOptionEntity[]) {
	const values: string[] = [];
	for (const addOn of addOns) {
		const value = stringNotEmpty(addOn.abbreviation) ? addOn.abbreviation : addOn.name;
		values.push(value);
	}
	return values.join(' | ');
}
