import * as React from 'react';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import EventCheckInDetails, { EventCheckInDetailsProps } from './EventCheckInDetails';
import { SideBar } from '../../../SideBar/SideBar';

export interface EventCheckInDetailsSidebarProps extends EventCheckInDetailsProps {}

function EventCheckInDetailsSidebar({ bookingId }: EventCheckInDetailsSidebarProps) {
	const routes = useCheckInRoutes(true);

	return (
		<SideBar onClose={() => store.routerHistory.replace(routes.base)}>
			<EventCheckInDetails bookingId={bookingId} className="pb-xxxl" />
		</SideBar>
	);
}

export default EventCheckInDetailsSidebar;
