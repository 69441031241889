import classNames from 'classnames';
import useScreenSize from 'Hooks/useScreenSize';
import { observer } from 'mobx-react';
import * as React from 'react';
import EventCheckInDetails, { EventCheckInDetailsProps } from './EventCheckInDetails';
import EventCheckInTopNavMobile from '../EventCheckInTopNavMobile';

export interface EventCheckInDetailsViewProps extends EventCheckInDetailsProps {}

function EventCheckInDetailsView({ bookingId }: EventCheckInDetailsViewProps) {
	const { isMobile } = useScreenSize();

	return (
		<div id="check-in-body" className="body-content check-in">
			<EventCheckInTopNavMobile />
			<div className="check-in__body">
				<EventCheckInDetails
					bookingId={bookingId}
					className={classNames('pt-xs pb-xxxl', { 'p-xs': isMobile })}
				/>
			</div>
		</div>
	);
}

export default observer(EventCheckInDetailsView);
