import React from 'react';
import { store } from 'Models/Store';
import { showModal } from 'Views/Components/_HumanWritten/Modal/Base/BaseModalContents';
import { SingleActionModalContent } from 'Views/Components/_HumanWritten/Modal/Base/SingleActionModalContent';
import { Text } from 'Views/Components/Text/Text';
import { clearFerryBookingWizard } from '../../../../../Util/_HumanWritten/BookingWizard/BookingWizardUtils';

export function RestartWizardModalContents() {
	return (
		<SingleActionModalContent
			title="Error"
			body={(
				<>
					<Text>
						There has been an error with the reservation process.
					</Text>
					<Text>
						Please restart your booking. Sorry for the inconvenience.
					</Text>
				</>
			)}
			confirmContent="Restart"
			onConfirm={onConfirm}
		/>
	);
}

const RestartWizardModalContentsMemo = React.memo(RestartWizardModalContents);

export function showRestartWizardModal() {
	showModal({
		key: 'restart-booking-wizard',
		content: <RestartWizardModalContentsMemo />,
		modalProps: {
			onRequestClose: onConfirm,
		},
	});
}

function onConfirm() {
	clearFerryBookingWizard();
	store.modal.hide();
	store.routerHistory.push('/home');
}
