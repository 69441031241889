import * as React from 'react';
import { BookingDetailSection, SectionProps } from '../../SharedSections/BookingDetailSection';
import { BookingSummaryAddon } from '../../../../BookingSummaryDto';
import { EditType } from '../../BookingDetailedView';

export function AdditionalBookingOptionsInfoSection({
	booking, canEdit, onEdit, isInvoiced,
}: SectionProps) {
	if (!booking.bookedSummary) {
		console.error('Booked summary missing, this should not happen');
		return <></>;
	}

	// @ts-ignore
	const showEditBtn = canEdit
		&& !isInvoiced
		&& booking.bookedSummary.additionalBookingOptions.some(x => x.option.removable);

	const hasVehicle = !!booking.bookedSummary.cargoInfo;
	const editType: EditType = hasVehicle ? 'vehicle' : 'add-on';

	if (booking.bookedSummary.additionalBookingOptions?.length !== undefined) {
		const sectionContent = (
			<p className="additional-booking-items">
				{booking.bookedSummary.additionalBookingOptions?.filter((x: BookingSummaryAddon) => x.quantity > 0)
					.map((additionalItem: BookingSummaryAddon) => {
						return (
							<p
								className="additional-option-line-item"
								key={additionalItem.option?.id}
							>
								{additionalItem.quantity}x {additionalItem.option?.name}
							</p>
						);
					})}
			</p>
		);

		return (
			<BookingDetailSection
				sectionName="Add ons"
				sectionContent={sectionContent}
				sectionClassName="additional-booking-items"
				icon={{
					name: 'cards',
					classname: 'cards',
				}}
				modificationRules={{
					canEdit: showEditBtn,
					editType: editType,
					onEdit: onEdit,
					isInvoiced: isInvoiced,
				}}
			/>
		);
	}
	return <></>;
}
