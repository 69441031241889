import * as React from 'react';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { runInAction } from 'mobx';
import useCheckInStore from 'Hooks/useCheckInStore';
import { showCheckInAddMoveModal } from 'Views/Components/_HumanWritten/Modal/CheckInAddMoveModalContents';
import LinkAsButton from 'Views/Components/_HumanWritten/LinkAsButton/LinkAsButton';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import { useFilterAddOn } from '../CheckInView';
import If from 'Views/Components/If/If';

export interface CheckInActionButtonsProps {
	state?: {
		showCheckedIn: boolean;
	};
}

export function CheckInActionButtons({
	state,
}: CheckInActionButtonsProps) {
	const checkInStore = useCheckInStore();
	const routes = useCheckInRoutes();
	const onAddBooking = () => {
		showCheckInAddMoveModal({
			onCancel: () => {
				store.routerHistory.push(routes.selectCustomer);
			},
			onConfirm: () => {
				store.routerHistory.push(routes.move);
			},
		});
	};

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const onClickSort = () => {
		checkInStore.setShowFilters(!checkInStore.showFilters);
	};

	return (
		<div className="check-in__action-btns">
			<Button
				className="check-in__action-btn__filter-btn"
				display={Display.Text}
				colors={Colors.Secondary}
				sizes={Sizes.Medium}
				onClick={onClickSort}
			>
				Sort
			</Button>
			<If condition={addOnsModel.filteredAddOns.length === 0}>
				<Button
					buttonProps={{ id: 'checked-in-toggle-button' }}
					className={`check-in__action-btn__toggle-check-in ${state?.showCheckedIn ? 'checked' : 'unchecked'}`}
					display={Display.Text}
					colors={Colors.Secondary}
					sizes={Sizes.ExtraLarge}
					onClick={() => {
						if (state) {
							runInAction(() => {
								state.showCheckedIn = !state?.showCheckedIn;
							});
						}
					}}
					icon={{ icon: 'check-box', iconPos: 'icon-left' }}
				/>
				<div className="border__wrapper">
					<div className="border" />
				</div>
				<Button
					className="check-in__action-btn__add-booking"
					display={Display.Text}
					colors={Colors.Secondary}
					sizes={Sizes.ExtraLarge}
					onClick={onAddBooking}
					icon={{ icon: 'add', iconPos: 'icon-left' }}
				/>
				<LinkAsButton
					to={routes.scan}
					className="check-in__action-btn__camera"
					display={Display.Solid}
					colors={Colors.Secondary}
					sizes={Sizes.ExtraLarge}
				>
					<Icon name="camera" />
				</LinkAsButton>
			</If>
		</div>
	);
}
