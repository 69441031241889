/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsCargoEntity from 'Models/Security/Acl/VisitorsCargoEntity';
import UserCargoEntity from 'Models/Security/Acl/UserCargoEntity';
import StaffCargoEntity from 'Models/Security/Acl/StaffCargoEntity';
import ManagerCargoEntity from 'Models/Security/Acl/ManagerCargoEntity';
import AdminCargoEntity from 'Models/Security/Acl/AdminCargoEntity';
import InvoicedUserCargoEntity from 'Models/Security/Acl/InvoicedUserCargoEntity';
import FixedPriceUserCargoEntity from 'Models/Security/Acl/FixedPriceUserCargoEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
type errorKeys = 'driverFirstName'
	| 'driverLastName'
	| 'driverPhone'
	| 'cargoIdentification'
	| 'selectedLengthTypeId'
	| 'hiredVehicle'
	| 'disabled'
	| 'cargoTypeId'
	| 'cargoMake'
	| 'cargoModel';
// % protected region % [Add any further imports here] end

export interface ICargoEntityAttributes extends IModelAttributes {
	cargoIdentification: string;
	tbc: boolean;
	disabled: boolean;
	legacyId: number;

	cargoDetails: Array<
		| Models.CargoDetailEntity
		| Models.ICargoDetailEntityAttributes
	>;
	cargoTypeId: string;
	cargoType: Models.CargoTypeEntity | Models.ICargoTypeEntityAttributes;
	spacingAddOns: Array<
		| Models.SpacingAddOnCargo
		| Models.ISpacingAddOnCargoAttributes
	>;
	userId: string;
	user: Models.UserEntity | Models.IUserEntityAttributes;
	selectedHeightId?: string;
	selectedHeight?: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	selectedLengthId: string;
	selectedLength: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	selectedWeightId: string;
	selectedWeight: Models.MeasurementEntity | Models.IMeasurementEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CargoEntity', 'Cargo')
// % protected region % [Customise your entity metadata here] end
export default class CargoEntity extends Model
	implements ICargoEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsCargoEntity(),
		new UserCargoEntity(),
		new StaffCargoEntity(),
		new ManagerCargoEntity(),
		new AdminCargoEntity(),
		new InvoicedUserCargoEntity(),
		new FixedPriceUserCargoEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Cargo Identification'] off begin
	/**
	 * The identification for the cargo item. E.g. rego.
	 */
	@Validators.Required()
	@observable
	@attribute<CargoEntity, string>()
	@CRUD({
		name: 'Cargo Identification',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cargoIdentification: string;
	// % protected region % [Modify props to the crud options here for attribute 'Cargo Identification'] end

	// % protected region % [Modify props to the crud options here for attribute 'TBC'] off begin
	/**
	 * If the cargo identification is to be confirmed later.
	 */
	@observable
	@attribute<CargoEntity, boolean>()
	@CRUD({
		name: 'TBC',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public tbc: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TBC'] end

	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] off begin
	@observable
	@attribute<CargoEntity, boolean>()
	@CRUD({
		name: 'Disabled',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public disabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Disabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<CargoEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Detail'] off begin
		name: 'Cargo Details',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.CargoDetailEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargoDetails',
			oppositeEntity: () => Models.CargoDetailEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo Detail'] end
	})
	public cargoDetails: Models.CargoDetailEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Type'] off begin
		name: 'Cargo Type',
		displayType: 'reference-combobox',
		order: 60,
		referenceTypeFunc: () => Models.CargoTypeEntity,
		// % protected region % [Modify props to the crud options here for reference 'Cargo Type'] end
	})
	public cargoTypeId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public cargoType: Models.CargoTypeEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Spacing Add On'] off begin
		name: 'Spacing Add On',
		displayType: 'reference-multicombobox',
		order: 70,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.SpacingAddOnCargo,
		optionEqualFunc: makeJoinEqualsFunc('spacingAddOnId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'cargoEntity',
			oppositeEntityName: 'spacingAddOnEntity',
			relationName: 'cargo',
			relationOppositeName: 'spacingAddOn',
			entity: () => Models.CargoEntity,
			joinEntity: () => Models.SpacingAddOnCargo,
			oppositeEntity: () => Models.SpacingAddOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Spacing Add On'] end
	})
	public spacingAddOns: Models.SpacingAddOnCargo[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] off begin
		name: 'User',
		displayType: 'reference-combobox',
		order: 80,
		referenceTypeFunc: () => Models.UserEntity,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public userId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public user: Models.UserEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Height'] off begin
		name: 'Selected Height',
		displayType: 'reference-combobox',
		order: 90,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Height'] end
	})
	public selectedHeightId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedHeight: Models.MeasurementEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Length'] off begin
		name: 'Selected Length',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Length'] end
	})
	public selectedLengthId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedLength: Models.MeasurementEntity;

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Weight'] off begin
		name: 'Selected Weight',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.MeasurementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Selected Weight'] end
	})
	public selectedWeightId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public selectedWeight: Models.MeasurementEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public errors: { [K in errorKeys]?: string } = {};

	@action
	public validateField = async (field: string): Promise<string | undefined> => {
		switch (field) {
			case 'cargoIdentification': {
				if (!this.cargoIdentification) {
					this.errors.cargoIdentification = 'Required field';
				} else {
					delete this.errors.cargoIdentification;
				}
				break;
			}
			case 'cargoTypeId': {
				if (!this.cargoTypeId) {
					this.errors.cargoTypeId = 'Required field';
				} else {
					delete this.errors.cargoTypeId;
				}
				break;
			}
			case 'cargoMake': {
				if (!this.cargoTypeId) {
					this.errors.cargoMake = 'Required field';
				} else {
					delete this.errors.cargoMake;
				}
				break;
			}
			case 'cargoModel': {
				if (!this.cargoTypeId) {
					this.errors.cargoModel = 'Required field';
				} else {
					delete this.errors.cargoModel;
				}
				break;
			}
		}

		return this.errors[field];
	}
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICargoEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICargoEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.cargoIdentification !== undefined) {
				this.cargoIdentification = attributes.cargoIdentification;
			}
			if (attributes.tbc !== undefined) {
				this.tbc = attributes.tbc;
			}
			if (attributes.disabled !== undefined) {
				this.disabled = attributes.disabled;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.cargoDetails !== undefined && Array.isArray(attributes.cargoDetails)) {
				for (const model of attributes.cargoDetails) {
					if (model instanceof Models.CargoDetailEntity) {
						this.cargoDetails.push(model);
					} else {
						this.cargoDetails.push(new Models.CargoDetailEntity(model));
					}
				}
			}
			if (attributes.cargoTypeId !== undefined) {
				this.cargoTypeId = attributes.cargoTypeId;
			}
			if (attributes.cargoType !== undefined) {
				if (attributes.cargoType === null) {
					this.cargoType = attributes.cargoType;
				} else if (attributes.cargoType instanceof Models.CargoTypeEntity) {
					this.cargoType = attributes.cargoType;
					this.cargoTypeId = attributes.cargoType.id;
				} else {
					this.cargoType = new Models.CargoTypeEntity(attributes.cargoType);
					this.cargoTypeId = this.cargoType.id;
				}
			}
			if (attributes.spacingAddOns !== undefined && Array.isArray(attributes.spacingAddOns)) {
				for (const model of attributes.spacingAddOns) {
					if (model instanceof Models.SpacingAddOnCargo) {
						this.spacingAddOns.push(model);
					} else {
						this.spacingAddOns.push(new Models.SpacingAddOnCargo(model));
					}
				}
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
			if (attributes.selectedHeightId !== undefined) {
				this.selectedHeightId = attributes.selectedHeightId;
			}
			if (attributes.selectedHeight !== undefined) {
				if (attributes.selectedHeight === null) {
					this.selectedHeight = attributes.selectedHeight;
				} else if (attributes.selectedHeight instanceof Models.MeasurementEntity) {
					this.selectedHeight = attributes.selectedHeight;
					this.selectedHeightId = attributes.selectedHeight.id;
				} else {
					this.selectedHeight = new Models.MeasurementEntity(attributes.selectedHeight);
					this.selectedHeightId = this.selectedHeight.id;
				}
			}
			if (attributes.selectedLengthId !== undefined) {
				this.selectedLengthId = attributes.selectedLengthId;
			}
			if (attributes.selectedLength !== undefined) {
				if (attributes.selectedLength === null) {
					this.selectedLength = attributes.selectedLength;
				} else if (attributes.selectedLength instanceof Models.MeasurementEntity) {
					this.selectedLength = attributes.selectedLength;
					this.selectedLengthId = attributes.selectedLength.id;
				} else {
					this.selectedLength = new Models.MeasurementEntity(attributes.selectedLength);
					this.selectedLengthId = this.selectedLength.id;
				}
			}
			if (attributes.selectedWeightId !== undefined) {
				this.selectedWeightId = attributes.selectedWeightId;
			}
			if (attributes.selectedWeight !== undefined) {
				if (attributes.selectedWeight === null) {
					this.selectedWeight = attributes.selectedWeight;
				} else if (attributes.selectedWeight instanceof Models.MeasurementEntity) {
					this.selectedWeight = attributes.selectedWeight;
					this.selectedWeightId = attributes.selectedWeight.id;
				} else {
					this.selectedWeight = new Models.MeasurementEntity(attributes.selectedWeight);
					this.selectedWeightId = this.selectedWeight.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		spacingAddOns {
			${Models.SpacingAddOnCargo.getAttributes().join('\n')}
			spacingAddOn {
				${Models.SpacingAddOnEntity.getAttributes().join('\n')}
			}
		}
		cargoDetails {
			${Models.CargoDetailEntity.getAttributes().join('\n')}
		}
		cargoType {
			${Models.CargoTypeEntity.getAttributes().join('\n')}
		}
		user {
			${Models.UserEntity.getAttributes().join('\n')}
			${Models.UserEntity.getFiles().map(f => f.name).join('\n')}
		}
		selectedHeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		selectedLength {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
		selectedWeight {
			${Models.MeasurementEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			spacingAddOns: {},
			cargoDetails: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'spacingAddOns',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CargoEntity.prototype, 'created');
CRUD(modifiedAttr)(CargoEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
