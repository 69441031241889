/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
	Button,
	Display,
	Sizes,
	Colors,
} from '../Components/Button/Button';
import { action, observable } from 'mobx';
import { TextField } from '../Components/TextBox/TextBox';
import { store } from 'Models/Store';
import { ButtonGroup, Alignment } from 'Views/Components/Button/ButtonGroup';
import { isEmail } from 'Validators/Functions/Email';
import alert from '../../Util/ToastifyUtils';
import { getErrorMessages } from 'Util/GraphQLUtils';
import { resetPasswordRequest } from 'Services/Api/AccountService';
// % protected region % [Add any extra imports here] on begin
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { parse as parseQueryString } from 'querystring';
import { FEATURE_IMAGE_2_URL } from 'Constants';
import If from 'Views/Components/If/If';
import { runInAction } from 'mobx';
import { ACTIVATE_NEW_PASSWORD_PAGE_TITLE, FORGOT_PASSWORD_PAGE_TITLE } from '../../ConstantPageNames';
// % protected region % [Add any extra imports here] end

interface IResetRequestState {
	username: string;
	errors: { [attr: string]: string };
	// % protected region % [Add extra reset password request state properties here] on begin
	resetEmailSent: boolean
	loading: boolean;
	// % protected region % [Add extra reset password request state properties here] end
}

const defaultResetRequestState: IResetRequestState = {
	username: '',
	errors: {},
	// % protected region % [Instantiate extra reset password request state properties here] on begin
	resetEmailSent: false,
	loading: false,
	// % protected region % [Instantiate extra reset password request state properties here] end
};

@observer
export default class ResetPasswordRequestPage extends React.Component<RouteComponentProps> {
	@observable
	private ResetRequestState: IResetRequestState = defaultResetRequestState;

	public render() {
		let contents = null;

		if (store.loggedIn) {
			// % protected region % [Override redirect here] off begin
			return <Redirect to="/" />;
			// % protected region % [Override redirect here] end
		}

		// % protected region % [Override contents here] on begin
		if (this.forcedReset) {
			document.title = ACTIVATE_NEW_PASSWORD_PAGE_TITLE;
		} else {
			document.title = FORGOT_PASSWORD_PAGE_TITLE;
		}

		contents = (
			<div className="body-content">
				<div className="reset-password-request-container">
					<div className="feature-image">
						<img
							className="bg-img"
							alt="bg-img"
							src={FEATURE_IMAGE_2_URL}
						/>
					</div>
					<div className="reset-password-column">
						<div className="navigation-container">
							<Link
								to="/login"
								className={classNames('icon-chevron-left icon-left back-navigation \'show\'')}
							>
								Login
							</Link>
						</div>
						<form className="loginForm" onSubmit={e => this.onResetPasswordClicked(e)}>
							<If condition={!this.forcedReset}>
								<h3>Forgot password?</h3>
								<p className="reset-password-paragraph">
									{/* eslint-disable-next-line max-len */}
									It happens! We&apos;ll send a link to the email below to reset your password and you can be on your way to paradise again.
								</p>
							</If>
							<If condition={this.forcedReset}>
								<h3>We have upgraded our system!</h3>
								<p className="reset-password-paragraph">
									{/* eslint-disable-next-line max-len */}
									Your details and bookings will still be available, we just had to reset your password for security reasons. Follow the steps to re-activate your account.
								</p>
							</If>
							<TextField
								id="username"
								className="username"
								model={this.ResetRequestState}
								modelProperty="username"
								label="Email address"
								inputProps={{ autoComplete: 'username' }}
								placeholder="Email address"
								isRequired
								errors={this.ResetRequestState.errors.username}
								onAfterChange={(() => {
									runInAction(() => {
										this.ResetRequestState.resetEmailSent = false;
									});
								})}
							/>
							<ButtonGroup alignment={Alignment.HORIZONTAL} className="reset-pwd-buttons">
								<div className={
									this.ResetRequestState.resetEmailSent
										? 'email-sent-message-visible'
										: 'email-sent-message-hidden'
								}
								>
									<span>Email sent!</span> Check your inbox.
								</div>
								<Button
									className="request-pwd btn--primary"
									type="submit"
									display={Display.Solid}
									sizes={Sizes.Medium}
									buttonProps={{ id: 'reset_password' }}
									disabled={this.ResetRequestState.username.length <= 0
										|| this.ResetRequestState.loading}
								>
									{this.forcedReset
										// eslint-disable-next-line no-nested-ternary
										? `${this.ResetRequestState.loading
											? 'Sending...'
											: (this.ResetRequestState.resetEmailSent
												? 'Resend activation email'
												: 'Send activation email')}`
										// eslint-disable-next-line no-nested-ternary
										: `${this.ResetRequestState.loading
											? 'Sending...'
											: (this.ResetRequestState.resetEmailSent
												? 'Resend reset email'
												: 'Send reset email')}`}
								</Button>
							</ButtonGroup>
						</form>
					</div>
				</div>
			</div>
		);
		// % protected region % [Override contents here] end
		return contents;
	}

	@action
	private onResetPasswordClicked = (event: React.FormEvent<HTMLFormElement>) => {
		// % protected region % [Override onResetPasswordClicked here] on begin
		event.preventDefault();

		this.ResetRequestState.errors = {};

		if (!this.ResetRequestState.username) {
			this.ResetRequestState.errors.username = 'This field is required';
		} else if (!isEmail(this.ResetRequestState.username)) {
			this.ResetRequestState.errors.username = 'Invalid email address';
		}

		if (Object.keys(this.ResetRequestState.errors).length === 0) {
			runInAction(() => {
				this.ResetRequestState.loading = true;
			});

			resetPasswordRequest(this.ResetRequestState.username).then(() => {
				this.onResetPasswordSent();
			}).catch(response => {
				const errorMessages = getErrorMessages(response).map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Could not send request</h6>
						{errorMessages}
					</div>,
					'error',
				);
			}).finally(() => {
				runInAction(() => {
					this.ResetRequestState.loading = false;
				});
			});
		}
		// % protected region % [Override onResetPasswordClicked here] end
	};

	@action
	private onCancelResetClicked: React.MouseEventHandler = () => {
		// % protected region % [Override onCancelResetClicked here] off begin
		store.routerHistory.push('/login');
		// % protected region % [Override onCancelResetClicked here] end
	};

	@action
	private onResetPasswordSent = () => {
		// % protected region % [Override onResetPasswordSent here] on begin
		this.ResetRequestState.resetEmailSent = true;
		// % protected region % [Override onResetPasswordSent here] end
	};

	// % protected region % [Add class methods here] on begin
	componentDidMount() {
		const { location } = this.props;

		const params = parseQueryString(location.search.substring(1));

		// eslint-disable-next-line dot-notation
		if (typeof params['email'] === 'string') {
			// eslint-disable-next-line dot-notation
			this.setEmail(params['email']);
		}

		if (typeof params.forcedReset === 'string' && params.forcedReset === 'true') {
			runInAction(() => { this.forcedReset = true; });
		}
	}

	@observable
	private forcedReset: boolean = false;

	@action
	private setEmail(email: string) {
		this.ResetRequestState.username = email;
	}
	// % protected region % [Add class methods here] end
}
