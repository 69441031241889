import { CheckInBookingOverviewDto } from '../../FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';

export const ScannerActionMap = {
	StartScanner: 'start-scanner-with-bookings',
	ToggleMode: 'toggle-mode',
} as const;

type StartScannerEvent = { action: typeof ScannerActionMap.StartScanner; bookings: CheckInBookingOverviewDto[] };
type ToggleModeEvent = { action: typeof ScannerActionMap.ToggleMode };

export type ScannerActionEvent = StartScannerEvent | ToggleModeEvent;
