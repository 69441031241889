const resetReservationEventId = 'reset-reservation-timer';

/**
 * Dispatches an event for resetting the reservation timer
 */
export function dispatchResetReservationEvent() {
	const event = new CustomEvent(resetReservationEventId);
	document.dispatchEvent(event);
}

/**
 * Listens to the reset reservation event and runs the given function whenever it is received
 *
 * @param listenerFunc the function to run when receiving an event
 */
export function onReceiveResetReservationEvent(listenerFunc: () => void) {
	document.addEventListener(resetReservationEventId, listenerFunc);
}

export function removeResetReservationListener(listenerFunc: () => void) {
	document.removeEventListener(resetReservationEventId, listenerFunc);
}
