import axios from 'axios';
import { SERVER_URL } from 'Constants';

export interface BookingPriceOverrideDto {
	transactionId: string,
	departingDiscountAmount?: number,
	returningDiscountAmount?: number,
}

// Add/update a staff discount on a transaction
export async function addManagerDiscount(bookingInfo: BookingPriceOverrideDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/ManagerBookingDiscountEntity/add-manager-discount`,
		bookingInfo,
	).then(({ data }) => data);
}

// Remove a staff discount from a transaction
export async function removeManagerDiscount(bookingInfo: BookingPriceOverrideDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/ManagerBookingDiscountEntity/remove-manager-discount`,
		bookingInfo,
	).then(({ data }) => data);
}
