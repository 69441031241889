import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import useCheckInStore from 'Hooks/useCheckInStore';
import { Flex } from 'Views/Components/Flex/Flex';
import { CheckInFilters } from '../CheckInFilters';
import If from 'Views/Components/If/If';
import { useFilterAddOn, useSearchTerm } from '../CheckInView';
import { stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import { CheckInSearch } from '../CheckInSearch';
import { CheckInNoAddOns, CheckInSearchEmpty } from '../CheckInTable/CheckInSearchEmpty';
import { AddOnsFilterListRowSubheader } from './AddOnsFilterListRowSubheader';
import { AddOnsFilterListRow } from './AddOnsFilterListRow';
import { filterFerryBookingsByAddOns } from '../Helpers/FilterFerryBookings';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { firstLetterLastName } from '../CheckInUtils';

function AddOnsFilterList() {
	const {
		model,
	} = useSearchTerm(x => ({
		model: x.model,
	}));

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const [refresh, setRefresh] = useState<number>(1);

	const checkInStore = useCheckInStore();
	const { bookings } = checkInStore;

	const displayedBookings = filterFerryBookingsByAddOns({
		bookings,
		searchTerm: model.searchTerm,
		addOnsToFilterBy: addOnsModel.filteredAddOns,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(model.searchTerm) && addOnsModel.filteredAddOns.length === 0) {
		return (
			<>
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={false}
					/>
				</If>
			</>
		);
	}

	const comparer = (x: CheckInBookingOverviewDto) => firstLetterLastName(x).toUpperCase();

	return (
		<>
			<Flex direction="col" className="check-in-list" flex={1}>
				<If condition={(displayedBookings.length > 0 && stringIsEmpty(model.searchTerm))
					|| stringNotEmpty(model.searchTerm)}
				>
					<CheckInSearch isVehicleCheckIn={false} />
				</If>
				<If condition={displayedBookings.length === 0 && stringNotEmpty(model.searchTerm)}>
					<CheckInSearchEmpty />
				</If>
				<If condition={displayedBookings.length === 0
					&& addOnsModel.filteredAddOns.length > 0
					&& stringIsEmpty(model.searchTerm)}
				>
					<CheckInNoAddOns />
				</If>
				<If condition={displayedBookings.length > 0}>
					{
						displayedBookings.map((item, index, arr) => {
							return (
								<React.Fragment key={item.id}>
									<AddOnsFilterListRowSubheader
										item={item}
										index={index}
										arr={arr}
										comparer={comparer}
									/>
									<AddOnsFilterListRow
										key={item.id}
										booking={item}
									/>
								</React.Fragment>
							);
						})
					}
				</If>
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={false}
					/>
				</If>
			</Flex>
		</>
	);
}

export default observer(AddOnsFilterList);
