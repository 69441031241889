/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminEventDetailEntity from 'Models/Security/Acl/AdminEventDetailEntity';
import ManagerEventDetailEntity from 'Models/Security/Acl/ManagerEventDetailEntity';
import StaffEventDetailEntity from 'Models/Security/Acl/StaffEventDetailEntity';
import UserEventDetailEntity from 'Models/Security/Acl/UserEventDetailEntity';
import InvoicedUserEventDetailEntity from 'Models/Security/Acl/InvoicedUserEventDetailEntity';
import FixedPriceUserEventDetailEntity from 'Models/Security/Acl/FixedPriceUserEventDetailEntity';
import VisitorsEventDetailEntity from 'Models/Security/Acl/VisitorsEventDetailEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
import { isNotNullOrUndefined } from '../../Util/TypeGuards';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IEventDetailEntityAttributes extends IModelAttributes {
	name: string;
	description: string;
	googleMapUrl: string;
	address: string;
	totalCapacity: number;
	cancelled: boolean;
	hidden: boolean;
	closed: boolean;
	termsAndConditions: string;

	customTicketTypes: Array<
		| Models.CustomTicketTypeEntity
		| Models.ICustomTicketTypeEntityAttributes
	>;
	eventLabelId?: string;
	eventLabel?: Models.EventLabelEntity | Models.IEventLabelEntityAttributes;
	ferryTrip?: Models.FerryTripEntity |
		Models.IFerryTripEntityAttributes;
	headerImage?: Models.UploadedImageEntity |
		Models.IUploadedImageEntityAttributes;
	supportingImages: Array<
		| Models.UploadedImageEntity
		| Models.IUploadedImageEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	remainingCapacity?: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('EventDetailEntity', 'Event Detail')
// % protected region % [Customise your entity metadata here] end
export default class EventDetailEntity extends Model
	implements IEventDetailEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminEventDetailEntity(),
		new ManagerEventDetailEntity(),
		new StaffEventDetailEntity(),
		new UserEventDetailEntity(),
		new InvoicedUserEventDetailEntity(),
		new FixedPriceUserEventDetailEntity(),
		new VisitorsEventDetailEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * The name of the event.
	 */
	@Validators.Required()
	@observable
	@attribute<EventDetailEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * A description of the event.
	 */
	@Validators.Required()
	@observable
	@attribute<EventDetailEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textarea',
		order: 80,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Google Map Url'] on begin
	/**
	 * The URL for the google map.
	 */
	@observable
	@attribute<EventDetailEntity, string>()
	@CRUD({
		name: 'Google map URL',
		description: 'The URL for the location on google maps.',
		displayType: 'textfield',
		order: 30,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public googleMapUrl: string;
	// % protected region % [Modify props to the crud options here for attribute 'Google Map Url'] end

	// % protected region % [Modify props to the crud options here for attribute 'Address'] on begin
	@observable
	@attribute<EventDetailEntity, string>()
	@CRUD({
		name: 'Address',
		description: 'The address of the starting location for the event.',
		displayType: 'textfield',
		order: 40,
		headerColumn: false,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public address: string;
	// % protected region % [Modify props to the crud options here for attribute 'Address'] end

	// % protected region % [Modify props to the crud options here for attribute 'Total Capacity'] on begin
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<EventDetailEntity, number>()
	@CRUD({
		name: 'Total capacity',
		description: 'The total capacity of the event.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public totalCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Total Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Cancelled'] off begin
	/**
	 * Whether or not an event has been cancelled.
	 */
	@observable
	@attribute<EventDetailEntity, boolean>()
	@CRUD({
		name: 'Cancelled',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public cancelled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Cancelled'] end

	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] on begin
	/**
	 * Whether or not an event is hidden from regular customers.
	 */
	@observable
	@attribute<EventDetailEntity, boolean>()
	@CRUD({
		name: 'Hidden',
		description: 'Whether or not an event is hidden from regular customers.',
		displayType: 'checkbox',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public hidden: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Hidden'] end

	// % protected region % [Modify props to the crud options here for attribute 'Closed'] on begin
	/**
	 * Whether or not the event is closed for ticket sales.
	 */
	@observable
	@attribute<EventDetailEntity, boolean>()
	@CRUD({
		name: 'Closed',
		description: 'Whether or not the event is closed for ticket sales.',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		headerColumn: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public closed: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Closed'] end

	// % protected region % [Modify props to the crud options here for attribute 'Terms And Conditions'] on begin
	/**
	 * The terms and conditions for the event.
	 */
	@Validators.Required()
	@observable
	@attribute<EventDetailEntity, string>()
	@CRUD({
		name: 'Terms and conditions',
		displayType: 'textarea',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public termsAndConditions: string;
	// % protected region % [Modify props to the crud options here for attribute 'Terms And Conditions'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Custom Ticket Type'] on begin
		name: 'Custom ticket types',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.CustomTicketTypeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customTicketTypes',
			oppositeEntity: () => Models.CustomTicketTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Custom Ticket Type'] end
	})
	public customTicketTypes: Models.CustomTicketTypeEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Event Label'] on begin
		name: 'Event label',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.EventLabelEntity,
		// % protected region % [Modify props to the crud options here for reference 'Event Label'] end
	})
	public eventLabelId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public eventLabel: Models.EventLabelEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] on begin
		name: 'Trip information',
		description: 'The trip information for the event (e.g. start and end dates, times).',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.FerryTripEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'ferryTrips',
			oppositeEntity: () => Models.FerryTripEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ferry Trip'] end
	})
	public ferryTrip?: Models.FerryTripEntity;

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Header Image'] off begin
		name: 'Header Image',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.UploadedImageEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'headerImages',
			oppositeEntity: () => Models.UploadedImageEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Header Image'] end
	})
	public headerImage?: Models.UploadedImageEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Supporting Image'] on begin
		name: 'Supporting images',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.UploadedImageEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'supportingImages',
			oppositeEntity: () => Models.UploadedImageEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Supporting Image'] end
	})
	public supportingImages: Models.UploadedImageEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public remainingCapacity?: number;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IEventDetailEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IEventDetailEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.googleMapUrl !== undefined) {
				this.googleMapUrl = attributes.googleMapUrl;
			}
			if (attributes.address !== undefined) {
				this.address = attributes.address;
			}
			if (attributes.totalCapacity !== undefined) {
				this.totalCapacity = attributes.totalCapacity;
			}
			if (attributes.cancelled !== undefined) {
				this.cancelled = attributes.cancelled;
			}
			if (attributes.hidden !== undefined) {
				this.hidden = attributes.hidden;
			}
			if (attributes.closed !== undefined) {
				this.closed = attributes.closed;
			}
			if (attributes.termsAndConditions !== undefined) {
				this.termsAndConditions = attributes.termsAndConditions;
			}
			if (attributes.customTicketTypes !== undefined && Array.isArray(attributes.customTicketTypes)) {
				for (const model of attributes.customTicketTypes) {
					if (model instanceof Models.CustomTicketTypeEntity) {
						this.customTicketTypes.push(model);
					} else {
						this.customTicketTypes.push(new Models.CustomTicketTypeEntity(model));
					}
				}
			}
			if (attributes.eventLabelId !== undefined) {
				this.eventLabelId = attributes.eventLabelId;
			}
			if (isNotNullOrUndefined(attributes.remainingCapacity)) {
				this.remainingCapacity = attributes.remainingCapacity;
			}
			if (attributes.eventLabel !== undefined) {
				if (attributes.eventLabel === null) {
					this.eventLabel = attributes.eventLabel;
				} else if (attributes.eventLabel instanceof Models.EventLabelEntity) {
					this.eventLabel = attributes.eventLabel;
					this.eventLabelId = attributes.eventLabel.id;
				} else {
					this.eventLabel = new Models.EventLabelEntity(attributes.eventLabel);
					this.eventLabelId = this.eventLabel.id;
				}
			}
			if (attributes.ferryTrip !== undefined) {
				if (attributes.ferryTrip === null) {
					this.ferryTrip = attributes.ferryTrip;
				} else if (attributes.ferryTrip instanceof Models.FerryTripEntity) {
					this.ferryTrip = attributes.ferryTrip;
				} else {
					this.ferryTrip = new Models.FerryTripEntity(attributes.ferryTrip);
				}
			}
			if (attributes.headerImage !== undefined) {
				if (attributes.headerImage === null) {
					this.headerImage = attributes.headerImage;
				} else if (attributes.headerImage instanceof Models.UploadedImageEntity) {
					this.headerImage = attributes.headerImage;
				} else {
					this.headerImage = new Models.UploadedImageEntity(attributes.headerImage);
				}
			}
			if (attributes.supportingImages !== undefined && Array.isArray(attributes.supportingImages)) {
				for (const model of attributes.supportingImages) {
					if (model instanceof Models.UploadedImageEntity) {
						this.supportingImages.push(model);
					} else {
						this.supportingImages.push(new Models.UploadedImageEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		customTicketTypes {
			${Models.CustomTicketTypeEntity.getAttributes().join('\n')}
		}
		eventLabel {
			${Models.EventLabelEntity.getAttributes().join('\n')}
			${Models.EventLabelEntity.getFiles().map(f => f.name).join('\n')}
		}
		ferryTrip {
			${Models.FerryTripEntity.getAttributes().join('\n')}
			${Models.FerryTripEntity.getFiles().map(f => f.name).join('\n')}
		}
		headerImage {
			${Models.UploadedImageEntity.getAttributes().join('\n')}
			${Models.UploadedImageEntity.getFiles().map(f => f.name).join('\n')}
		}
		supportingImages {
			${Models.UploadedImageEntity.getAttributes().join('\n')}
			${Models.UploadedImageEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			customTicketTypes: {},
			ferryTrip: {},
			headerImage: {},
			supportingImages: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'customTicketTypes',
							'ferryTrip',
							'headerImage',
							'supportingImages',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(EventDetailEntity.prototype, 'created');
CRUD(modifiedAttr)(EventDetailEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
