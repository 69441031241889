import { IConfigThemeEntityAttributes } from './Entities';
import { stringNotEmpty } from '../Util/TypeGuards';

export class ThemeStore {
	config: IConfigThemeEntityAttributes;

	lightTextColour = 'white';
	darkTextColour = '#010c20'; // Same as $color-high-emphasis

	constructor(config: IConfigThemeEntityAttributes) { this.config = config; }

	public get textColourOnPrimary() {
		return this.config?.textOnPrimaryColour === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get textColourTypeOnPrimary() {
		return this.config?.textOnPrimaryColour ?? 'LIGHT';
	}

	public get textColourOnSecondary() {
		return this.config?.textOnSecondaryColour === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get textColourTypeOnSecondary() {
		return this.config?.textOnSecondaryColour ?? 'DARK';
	}

	public get paidTagColour() {
		return stringNotEmpty(this.config?.paidTagColour)
			? this.config?.paidTagColour
			: '#C9E879';
	}

	public get textOnPaidTag() {
		return this.config?.textOnPaidTag === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get cancelledTagColour() {
		return stringNotEmpty(this.config?.cancelledTagColour)
			? this.config?.cancelledTagColour
			: '#ff6d76';
	}

	public get textOnCancelledTag() {
		return this.config?.textOnCancelledTag === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get invoicedTagColour() {
		return stringNotEmpty(this.config?.invoicedTagColour)
			? this.config?.invoicedTagColour
			: '#0180b5';
	}

	public get textOnInvoicedTag() {
		return this.config?.textOnInvoicedTag === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get awaitingTagColour() {
		return stringNotEmpty(this.config?.awaitingTagColour)
			? this.config?.awaitingTagColour
			: '#ffe074';
	}

	public get textOnAwaitingTag() {
		return this.config?.textOnAwaitingTag === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get newTagColour() {
		return stringNotEmpty(this.config?.newTagColour)
			? this.config?.newTagColour
			: '#0180b5';
	}

	public get textOnNewTag() {
		return this.config?.textOnNewTag === 'LIGHT' ? this.lightTextColour : this.darkTextColour;
	}

	public get getDefaultFerryTripIcon() {
		return this.config.defaultFerryTripNoteIconId;
	}
}

export const themeStore = new ThemeStore(window.themeData);
