import React from 'react';
import { bookingTransactionStatus } from 'Models/Enums';
import classNames from 'classnames';

export enum TransactionStatusRenderOption {
	PAID = 'PAID',
	CANCELLED = 'CANCELLED',
	AWAITING = 'AWAITING',
	INVOICED = 'INVOICED',
}

export interface TransactionStatusRenderProps {
	status?: bookingTransactionStatus;
}

export function TransactionStatusRender({
	status,
}: TransactionStatusRenderProps) {
	let text = '';
	const renderClass = `booking-status ${status?.toLowerCase()}-status`;

	switch (status) {
		case TransactionStatusRenderOption.CANCELLED:
			text = 'Cancelled';
			break;
		case TransactionStatusRenderOption.AWAITING:
			text = 'Awaiting';
			break;
		case TransactionStatusRenderOption.INVOICED:
			text = 'Invoiced';
			break;
		default:
			text = 'Paid';
	}

	return (
		<div className={classNames('staff-bookings__status', renderClass)}>
			<p>
				{text}
			</p>
		</div>
	);
}
