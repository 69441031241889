import { clearFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import { SelectedTrips } from './BookingWizardWrap';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import locationAndRoutesStore from 'Models/LocationAndRoutesStore';
import { BookingWizardPageParams } from 'Views/Pages/BookingWizardPage';
import {
	wizardModeOptions,
	wizardModes,
} from 'Models/Enums';

export const WIZARD_DATA_LOCAL_STORAGE_KEY = 'ferry-booking-wizard-data';
const OLD_WIZARD_DATA_LOCAL_STORAGE_KEY = 'old-ferry-booking-wizard-data';
const WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY = 'cached-ferry-booking-wizard-data-token';

export interface AdditionalOption {
	optionId: string;
	amount: number;
	optionPrice: number;
	optionName: string;
	gstExempt: boolean;
}

/* eslint-disable @typescript-eslint/ban-types */
export type WizardErrors<T> = {
	[K in keyof T]?: string;
};
/* eslint-enable @typescript-eslint/ban-types */

export type TripType = 'one way' | 'return';
export type TabSelected = 'vehicle' | 'passenger';

export interface BaseBookingCreationData {
	bookingToEdit?: string;
	/**
	 * True means changes apply to one-way using the departure of booking.
	 * False means changes apply to one-way using the return of booking.
	 * Undefined means changes apply to both ways of booking.
	 */
	departureTrip?: boolean;

	/**
	 * Used in ferry filter.
	 */
	adultsCount: number;
	/**
	 * Used in ferry filter.
	 */
	childrenCount: number;
	/**
	 * Used in ferry filter.
	 */
	infantCount: number;

	passengerCountD?: number;
	passengerCountE?: number;
	passengerCountF?: number;
	passengerCountG?: number;
	passengerCountH?: number;

	// Ticket Ids
	departureTicketId: string;
	returningTicketId?: string;

	// Id for user the booking is assigned to
	userId: string;

	// Additional details
	note?: string;

	// Driver information
	driverFirstName?: string;
	driverLastName?: string;
	driverPhone?: string;

	// Vehicle fields
	cargoIdentification?: string;
	hiredVehicle?: boolean;
	cargoTypeId?: string;
	cargoMake?: string;
	cargoModel?: string;
	// Store the selected length Id for vehicle/trailer
	vehicleLengthId?: string;
	vehicleWeightId?: string;
	trailerLengthId?: string;
	trailerTypeId?: string;

	// Additional options
	departingTripOptions: AdditionalOption[];
	returningTripOptions: AdditionalOption[];
}

export interface BookingWizardData extends BaseBookingCreationData {
	wizardMode: wizardModes;
	acceptedTsAndCs: boolean;
	/**
	 * Undefined means wizard is editing two-way booking.
	 * If departureTrip is true, date is the departure time of the return booking.
	 * If departureTrip is false, date is the departure time of the departure booking.
	 */
	associatedTripDateTime?: Date;
	/**
	 * Used in ferry filter.
	 *
	 * The start date that defines the date range on the wizard sidebar filter.
	 */
	startDate: Date;
	/**
	 * Used in ferry filter.
	 *
	 * The end date that defines the date range on the wizard sidebar filter.
	 */
	endDate: Date;

	/**
	 * Used in ferry filter.
	 */
	trailerCheckboxSelected: boolean;
	/**
	 * Used in ferry filter.
	 */
	tabSelected: TabSelected;
	/**
	 * Used in ferry filter.
	 */
	tripType: TripType;

	// Location ids. These are used to determine the route
	fromLocationId: string;
	toLocationId: string;

	// Ticket selection dates. These are the dates shown on the departing and returning tabs on the ticket selection
	// page.  These are NOT the dates shown on the sidebar
	ticketSelectionStartDate: Date;
	ticketSelectionEndDate: Date;

	// Bulk booking ids. If this array is supplied then we ignore departure ticket id and returning ticket id
	bulkBookingTripIds?: string[];
	bulkBookingBookingIds?: string[];

	// Fees
	departingCancellationFee?: number;
	returningCancellationFee?: number;
	departingCancellationCutoffHours?: number;
}

export interface BookingWizardCartFields {
	wizardData: BookingWizardData;
	selectedTrips: SelectedTrips;
}

export function getDefaultWizardData(): BookingWizardData {
	const defaultRoute = locationAndRoutesStore.getDefaultRoute() ?? locationAndRoutesStore.getAnyRoute();

	return {
		acceptedTsAndCs: false,
		bookingToEdit: '',
		wizardMode: wizardModeOptions.CREATE,
		departureTrip: undefined,
		associatedTripDateTime: undefined,
		startDate: new Date(),
		endDate: new Date(),
		adultsCount: whiteLabelStore.minAdultsForVehicle,
		childrenCount: 0,
		infantCount: 0,
		passengerCountD: 0,
		passengerCountE: 0,
		passengerCountF: 0,
		passengerCountG: 0,
		passengerCountH: 0,
		trailerCheckboxSelected: false,
		tabSelected: whiteLabelStore.defaultBookingType,
		tripType: whiteLabelStore.defaultBookingType === 'vehicle'
			? whiteLabelStore.GetVehicleDefaultTripType
			: whiteLabelStore.GetPassengerDefaultTripType,
		fromLocationId: defaultRoute?.departureId ?? '',
		toLocationId: defaultRoute?.destinationId ?? '',
		ticketSelectionStartDate: new Date(),
		ticketSelectionEndDate: new Date(),
		departureTicketId: '',
		returningTicketId: '',
		userId: '',
		driverFirstName: '',
		driverLastName: '',
		driverPhone: '',
		cargoIdentification: '',
		hiredVehicle: false,
		cargoTypeId: '',
		cargoMake: '',
		cargoModel: '',
		vehicleLengthId: '',
		vehicleWeightId: '',
		trailerLengthId: '',
		trailerTypeId: 'NO_TRAILER',
		departingTripOptions: [],
		returningTripOptions: [],
		departingCancellationFee: 0,
		returningCancellationFee: 0,
		departingCancellationCutoffHours: 0,
	};
}

export function saveBookingWizardDataToLocalStorage(data: BookingWizardData) {
	sessionStorage.setItem(WIZARD_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
}

export function getBookingWizardData(params: BookingWizardPageParams | null): BookingWizardData {
	const fetchedData = sessionStorage.getItem(WIZARD_DATA_LOCAL_STORAGE_KEY);

	const oldWizardData = getOldFerryBookingWizardData();

	// TODO: Check whether the params need to be applied regardless of whether the data already existed
	if (fetchedData === null || params !== null) {
		const newData = getDefaultWizardData();
		// This will override any fields which were supplied in the search params.
		// By having the fallback value be the original value of the property we are able to ensure that we
		// aren't setting potentially conflicting default values
		if (!!params) {
			newData.tabSelected = params.type ?? newData.tabSelected;
			newData.tripType = ((params.type ?? newData.tabSelected) === 'vehicle'
				? whiteLabelStore.GetVehicleDefaultTripType
				: whiteLabelStore.GetPassengerDefaultTripType);
			newData.startDate = params.date ?? newData.startDate;
			newData.ticketSelectionStartDate = params.date ?? newData.startDate;
			newData.endDate = params.date ?? newData.startDate;
			newData.ticketSelectionEndDate = params.date ?? newData.startDate;
			newData.fromLocationId = params.fromLocation ?? newData.fromLocationId;
			newData.toLocationId = params.toLocation ?? newData.toLocationId;
			newData.departureTicketId = params.ferryTripId ?? newData.departureTicketId;
			newData.bookingToEdit = params.bookingId ?? '';
			// Set the user Id from the old booking wizard data if there is any
			newData.userId = oldWizardData?.userId ?? '';
			if (params.bookingId) {
				newData.wizardMode = wizardModeOptions.ALTERATION;
				newData.tripType = 'one way';
			}
			clearOldBookingWizardData();

			// We expect this section of the code to be executed during the transition from ferry schedule
			// page to booking wizard. So, we want to ensure transaction ID is cleared, only if the previous
			// booking wizard was being used for alteration.
			if (oldWizardData !== null && oldWizardData.wizardMode === 'ALTERATION') {
				clearFerryBookingTransactionIdFromStorage();
			}
		}

		saveBookingWizardDataToLocalStorage(newData);
		return newData;
	}

	return JSON.parse(fetchedData);
}

export function clearBookingWizardData() {
	sessionStorage.removeItem(WIZARD_DATA_LOCAL_STORAGE_KEY);
}

export function saveOldBookingWizardData(data: BookingWizardData) {
	sessionStorage.setItem(OLD_WIZARD_DATA_LOCAL_STORAGE_KEY, JSON.stringify(data));
}

export function clearOldBookingWizardData() {
	sessionStorage.removeItem(OLD_WIZARD_DATA_LOCAL_STORAGE_KEY);
}

export function getOldFerryBookingWizardData(): BookingWizardData | null {
	const fetchedData = sessionStorage.getItem(OLD_WIZARD_DATA_LOCAL_STORAGE_KEY);

	return fetchedData === null ? null : JSON.parse(fetchedData);
}

/*
* Save the token for the booking wizard data retrieval from the cache.
*/
export function saveBookingWizardDataCacheToken(token: string) {
	localStorage.setItem(WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY, JSON.stringify(token));
}

/*
* Get the token from local storage used to fetch the booking wizard data from the cache.
*/
export function getBookingWizardDataCacheToken(): string | null {
	const fetchedData = localStorage.getItem(WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY);

	return fetchedData === null ? null : JSON.parse(fetchedData);
}

/*
* Remove the token from local storage once it has been used.
*/
export function removeBookingWizardDataCacheToken() {
	localStorage.removeItem(WIZARD_DATA_CACHE_TOKEN_LOCAL_STORAGE_KEY);
}
