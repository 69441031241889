import classNames from 'classnames';
import React from 'react';
import { themeStore } from '../../../../Models/ThemeStore';
import { isNotNullOrUndefined } from '../../../../Util/TypeGuards';

export interface CustomTooltipProps {
	children?: React.ReactNode;
	content?: string;
	classNameIcon?: string;
	classNameBox?: string;
	toolTipPosition?: ToolTipPosition;
	id?: string;
	imageId?: string;
}
export type ToolTipPosition = | 'top-right' | 'top-left' | 'bottom-left' | 'bottom-right';
export function CustomTooltip({
	children,
	classNameIcon = 'icon-information',
	classNameBox,
	toolTipPosition = 'top-right',
	id,
	content,
	imageId,
}: CustomTooltipProps) {
	const defaultIconId = themeStore.getDefaultFerryTripIcon;

	const handlePointerDown = (e: any) => {
		e.stopPropagation();
		// e.preventDefault();
	};

	if (isNotNullOrUndefined(imageId)) {
		return (
			<span
				id={id}
				className={classNames('custom-tooltip icon-right')}
				onPointerDown={handlePointerDown}
				onClick={handlePointerDown}
				role="button"
				tabIndex={0}
				onKeyDown={handlePointerDown}
				onTouchStart={handlePointerDown}
			>
				<img
					className="custom-tooltip"
					src={`/api/files/${defaultIconId}`}
					alt="custom-tooltip-icon"
				/>
				<span className={classNames('tooltiptext', classNameBox, toolTipPosition)}>
					{children ?? content}
				</span>
			</span>
		);
	}
	return (
		<span
			id={id}
			className={classNames('custom-tooltip icon-right', classNameIcon)}
			onPointerDown={handlePointerDown}
		>
			<span className={classNames('tooltiptext', classNameBox, toolTipPosition)}>
				{children ?? content}
			</span>
		</span>
	);
}
