import * as React from 'react';
import { useMemo } from 'react';
import { useLottie } from 'lottie-react';
import defaultSpinner from './LottieFiles/default-waitlist-removed.json';
import customSpinner from './LottieFiles/custom-waitlist-removed.json';
import { SetLottieColour } from './LottieColourChanger';
import { themeStore } from '../../../../Models/ThemeStore';

export function LottieWaitlistRemoved() {
	const options = useMemo(() => {
		// Set the colour of the spinner depending on the application brand colouring
		const colouredSpinner = SetLottieColour(
			customSpinner,
			themeStore.config.brandColourPrimary,
		);

		return {
			loop: true,
			autoplay: true,
			animationData: colouredSpinner ?? defaultSpinner,
		};
	}, []);

	const { View } = useLottie(options);

	return (
		<div className="lottie-waitlist-removed">
			{View}
		</div>
	);
}
