import Axios from 'axios';
import { buildUrl } from 'Util/FetchUtils';
import { BOOKING_ENTITY_URL, ROUTE_ENTITY_URL, SERVER_URL } from '../../../Constants';
import { paymentMethodType } from '../../../Views/Components/RadioButton/StyledRadioButton';
import { transactionStatus } from '../../../Models/Enums';
import axios from 'axios';
import { IRouteEntityAttributes, RouteEntity } from '../../../Models/Entities';

const PAYMENT_API_URL = `${SERVER_URL}/api/Payment`;

export interface PaymentProviderTransactionDetails {
	formActionUrl: string;
	paymentIntentId: string;
}

export interface DynamicSurchargeDto {
	hasChanged: boolean,
	dynamicSurcharge: number,
}

export async function setupPayment(
	transactionId: string,
	redirectUrl: string,
	isEventBooking: boolean) {
	const res = Axios.post(
		`${PAYMENT_API_URL}/setup-payment`,
		{
			transactionId,
			redirectUrl,
			isEventBooking,
		},
	);
	return res;
}

export async function createPaymentIntent(
	transactionId: string,
	redirectUrl: string,
	confirmationTokenId: string,
	isEventBooking: boolean) {
	const res = await Axios.post(
		`${PAYMENT_API_URL}/create-payment-intent`,
		{
			transactionId,
			redirectUrl,
			confirmationTokenId,
			isEventBooking,
		},
	);
	return res;
}

export async function calculateDynamicSurcharge(
	transactionId: string,
	confirmationTokenId: string,
	isEventBooking: boolean) {
	const res = await Axios.post(
		`${PAYMENT_API_URL}/calculate-dynamic-surcharge`,
		{
			transactionId,
			confirmationTokenId,
			isEventBooking,
		},
	);
	return res.data as DynamicSurchargeDto;
}

export async function completePaymentBulkBooking(transactionId: string) {
	return Axios.post(buildUrl(`${PAYMENT_API_URL}/complete-payment-bulk-booking`, { transactionId }));
}

export async function completePayment(paymentIntentId: string) {
	return Axios.post(buildUrl(`${PAYMENT_API_URL}/complete-payment`, {
		paymentIntentId,
	}));
}

/**
 * Set transaction to PENDING_INVOICE, set all related alterations to complete.
 * @param transactionId The transaction to be invoiced.
 */
export async function invoicePayment(transactionId: string) {
	const queryParams: any = { transactionId };
	return Axios.post(buildUrl(`${PAYMENT_API_URL}/invoice-payment`, queryParams));
}

/**
 * Set transaction to complete, set all related alterations to complete.
 * @param transactionId The transaction to be completed without payment.
 * @param bookingId If bookingId is provided, update checkedIn status to true.
 */
export async function completeTransactionNoPayment(
	transactionId: string,
	paymentMethod: paymentMethodType,
	bookingId?: string,
) {
	const transactionStatusToSet = paymentMethod.toUpperCase() as transactionStatus;
	const queryParams: any = { transactionId, transactionStatus: transactionStatusToSet };
	if (bookingId) {
		queryParams.bookingId = bookingId;
	}
	return Axios.post(buildUrl(`${PAYMENT_API_URL}/complete-payment-check-in`, queryParams));
}

// TODO fix to work with Stripe
/**
 * Check that the booking hasn't already had payment taken for
 * it before continuing through to making a new alteration on the booking.
 * @param transactionId The transaction to check if payment has already been taken for.
 */
export async function checkPaymentNotTaken(transactionId: string) {
	return Axios.post(
		buildUrl(`${PAYMENT_API_URL}/check-no-payment-taken`, { transactionId }),
	);
}

export async function fetchPublishableKey(): Promise<string> {
	const results = await axios.get(`${PAYMENT_API_URL}/get-payment-publishable-key`);

	return results.data;
}

export async function fetchAccountId(): Promise<string> {
	const results = await axios.get(`${PAYMENT_API_URL}/get-payment-account-id`);

	return results.data;
}

export async function fetchCurrency(): Promise<string> {
	const results = await axios.get(`${PAYMENT_API_URL}/get-currency`);

	return results.data;
}
