import { SERVER_URL } from 'Constants';
import RouteMapRenderableObject, { RouteMapRenderableType }
	from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapRenderableObject';
import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';
import { RouteEntity } from 'Models/Entities';
import { ILocationAndRoutes } from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapHandler';
import RouteMapHandler from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapHandler';
import { stringIsEmpty } from 'Util/TypeGuards';
import { clamp } from 'lodash';

export default class RouteMapBackground implements RouteMapRenderableObject {
	private readonly canvas: RouteMapCanvas;
	private readonly mapHandler: RouteMapHandler;

	private needRerender: boolean = false;
	private imageLoadError: boolean = false;
	private imageId: string;
	private previousImageId: string;
	private imageCache: Map<string, HTMLImageElement> = new Map();

	private animationStartTime?: number = undefined;

	constructor(mapHandler: RouteMapHandler) {
		this.mapHandler = mapHandler;
		this.canvas = mapHandler.getBackgroundCanvas();
	}

	getType(): RouteMapRenderableType {
		return 'background';
	}

	updateScale(): void {
		// This is already handled by drawing to the size of the canvas
	}

	public setImageId(imageId: string) {
		this.previousImageId = this.imageId;
		this.imageId = imageId;
		this.animationStartTime = undefined;

		this.loadImage(this.imageId)
			.then(() => {
				this.needRerender = true;
				this.mapHandler.clearError();
			}).catch(() => {
				this.mapHandler.setError();
			});
	}

	public update(route: RouteEntity, locationsAndRoutes: ILocationAndRoutes) {
		this.setImageId(route.mapId);
	}

	public render(timestamp: number): boolean {
		if (!this.needRerender && !this.canvas.getNeedsRerender()) {
			return this.needRerender;
		}
		this.needRerender = false;

		this.clear();
		this.needRerender = this.renderImage(timestamp);

		return this.needRerender;
	}

	public clear() {
		this.canvas.getContext().clearRect(0, 0, this.canvasWidth, this.canvasHeight);
	}

	private renderImage(timestamp: number): boolean {
		const ctx = this.canvas.getContext();
		const image = this.imageCache.get(this.imageId);
		const previousImage = this.imageCache.get(this.previousImageId);

		if (image === undefined || !image.complete || this.imageLoadError) {
			return false;
		}

		ctx.save();

		if (this.animationStartTime === undefined) {
			this.animationStartTime = timestamp;
		}

		const animationProgress = clamp((timestamp - this.animationStartTime) / 100, 1);

		ctx.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight);

		if (previousImage !== undefined && animationProgress < 1) {
			ctx.globalAlpha = (1 - animationProgress);
			ctx.drawImage(previousImage, 0, 0, this.canvasWidth, this.canvasHeight);
		}

		ctx.restore();

		return animationProgress < 1;
	}

	private renderLoading() {
		const ctx = this.mapHandler.getForegroundCanvas().getContext();
		ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
		ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
	}

	private loadImage(imageId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			this.imageLoadError = false;
			this.renderLoading();

			if (this.imageCache.has(imageId)) {
				/* If the cache already has the image, we don't need to load it again. */
				resolve();
				return;
			}

			if (stringIsEmpty(imageId)) {
				this.imageLoadError = true;
				reject();
				return;
			}

			const image = new Image();
			image.src = `${SERVER_URL}/api/files/${imageId}`;

			image.onload = () => {
				this.imageCache.set(imageId, image);
				resolve();
			};

			image.onerror = () => {
				this.imageLoadError = true;
				reject();
			};
		});
	}

	private get canvasHeight() {
		return this.canvas.getCanvas().height;
	}

	private get canvasWidth() {
		return this.canvas.getCanvas().width;
	}
}
