import React from 'react';
import useStore from 'Hooks/useStore';

interface IBookingWizardBackground {
	src:string;
}
export default function BookingWizardBackground(props: IBookingWizardBackground) {
	const { src } = props;
	const store = useStore();
	return (
		<div className="booking-wizard-page-background">
			<img
				className={store.isAdmin ? 'admin-background-img' : 'background-img '}
				src={src}
				alt="bg-img"
			/>
		</div>
	);
}
