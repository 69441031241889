import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { buildUrl } from 'Util/FetchUtils';

const baseUrl = `${SERVER_URL}/api/entity/PassengerTypeEntity`;

/**
 * Set the prices for the passenger type for all ferry trips and invoiced users.
 */
export async function savePassengerTypeDefaultPrices(id: string, ferryTripPrice: number, fixedPriceUserPrice: number) {
	return axios.post(
		buildUrl(`${baseUrl}/set-default-prices`, {
			id,
			ferryTripPrice: ferryTripPrice.toString(),
			fixedPriceUserPrice: fixedPriceUserPrice.toString(),
		}),
	);
}
