import * as React from 'react';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { CheckInSorter } from './CheckInUtils';
import { Text } from 'Views/Components/Text/Text';
import { getCheckInCountTypeOptions, getCheckInSortOptions } from './CheckInList/CheckInList';
import { reaction } from 'mobx';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import classNames from 'classnames';
import { DisplayType } from 'Views/Components/Models/Enums';
import { useEffect, useRef } from 'react';
import If from 'Views/Components/If/If';
import { useLocalStore } from 'mobx-react';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { CheckInCountType, useFilterAddOn, useSortSettings } from './CheckInView';
import useCheckInStore from 'Hooks/useCheckInStore';
import { MultiCombobox } from 'Views/Components/Combobox/MultiCombobox';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import { AdditionalBookingSectionEntity } from 'Models/Entities';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';

const passengerOnlySorters = [
	CheckInSorter.Fullname,
	CheckInSorter.FullnameDesc,
];

export interface CheckInFiltersProps {
	vehiclesOnly?: boolean;
	setRefresh: (value: number) => void;
	refresh: number;
}

export function CheckInFilters({
	vehiclesOnly = false,
	setRefresh,
	refresh,
}: CheckInFiltersProps) {
	// Add a local state so that the bookings aren't sorted in the background of the modal
	// and so that we can choose when to apply the changes.
	const checkInStore = useCheckInStore();

	let addOnSections: AdditionalBookingSectionEntity[] = [];
	const addOnsResponse = useAsync(() => checkInStore.getAllAdditionalBookingSections(), []);

	if (addOnsResponse.type === 'error') {
		alertToast('Could not fetch add ons', 'error');
	}

	if (addOnsResponse.type === 'data') {
		addOnSections = addOnsResponse.data;
	}

	const {
		countType,
		cargoSorting,
		cargoDescending,
		passengerSorting,
		passengerDescending,
		setCountType,
		setCargoSorting,
		setPassengerSorting,
		setCargoDescending,
		setPassengerDescending,
	} = useSortSettings(x => ({
		countType: x.countType,
		setCountType: x.setCountType,
		cargoSorting: x.cargoSorting,
		cargoDescending: x.cargoDescending,
		passengerSorting: x.passengerSorting,
		passengerDescending: x.passengerDescending,
		setCargoSorting: x.setCargoSorting,
		setPassengerSorting: x.setPassengerSorting,
		setCargoDescending: x.setCargoDescending,
		setPassengerDescending: x.setPassengerDescending,
	}));

	const {
		model,
		setFilteredAddOns,
	} = useFilterAddOn(x => ({
		model: x.model,
		setFilteredAddOns: x.setFilteredAddOns,
	}));

	const localState = useLocalStore(() => ({
		countType: countType,
		sorter: vehiclesOnly ? cargoSorting : passengerSorting,
		descending: vehiclesOnly ? cargoDescending : passengerDescending,
		filteredAddOns: [...model.filteredAddOns.map(x => x.id)],
	}));

	const needConfirm = useRef(false);

	useEffect(() => {
		const setNeedConfirm = () => {
			needConfirm.current = true;
		};
		// If any changes are made on any of these fields it will trigger the modal to confirm closing the side-bar
		const disposals = [
			reaction(() => localState.countType, setNeedConfirm),
			reaction(() => localState.sorter, setNeedConfirm),
		];

		// Clean up all of the reactions after one has been triggered
		return () => {
			disposals.map(disposal => disposal());
		};
	}, [localState]);

	const countOptions = getCheckInCountTypeOptions()
		.map(x => {
			return {
				display: <Text>{x.text}</Text>,
				value: x.value,
			};
		});

	const sortOptions = getCheckInSortOptions(
		countType === CheckInCountType.WeightCount,
	)
		.filter(x => {
			if (!vehiclesOnly) {
				return passengerOnlySorters.includes(x.value);
			}
			return true;
		})
		.map(x => {
			return {
				display: <Text>{x.text}</Text>,
				value: x.value,
			};
		});

	const onHidePanel = () => {
		if (!needConfirm.current) {
			checkInStore.setShowFilters(false);
		} else {
			confirmModal(
				'Discard sorting changes?',
				// eslint-disable-next-line max-len
				'Your sorting preferences have not been applied. Are you sure you want to leave?',
				{
					confirmText: 'Discard changes',
				},
			)
				.then(() => {
					checkInStore.setShowFilters(false);
				})
				.catch(() => {});
		}
	};

	const onClickApply = () => {
		if (isNotNullOrUndefined(localState?.countType)) {
			setCountType(localState?.countType as CheckInCountType);
		}

		const descenders = [
			CheckInSorter.FullnameDesc,
			CheckInSorter.VehicleDesc,
			CheckInSorter.TrailerDesc,
			CheckInSorter.VehicleTypeDesc,
		];

		if (vehiclesOnly) {
			setCargoSorting(localState.sorter as CheckInSorter);
			setCargoDescending(descenders.includes(localState.sorter));
		} else {
			setPassengerSorting(localState.sorter as CheckInSorter);
			setPassengerDescending(descenders.includes(localState.sorter));
		}
		setFilteredAddOns(addOnSections.filter(x => localState.filteredAddOns.includes(x.id)));
		checkInStore.setShowFilters(false);
		setRefresh(refresh + 1);
	};

	return (
		<div className="sidebar custom-sidebar__container filter__sidebar">
			<div
				className="sidebar-click-interceptor"
				role="button"
				onClick={() => onHidePanel()}
				onKeyDown={() => onHidePanel()}
				tabIndex={0}
				style={{ height: '100%' }}
				aria-label="Close modal"
			/>
			<div className={classNames('sidebar-info')}>
				<div className="filter-sidebar__container">
					<Button
						className="back-navigation hide-underline"
						icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
						onClick={() => onHidePanel()}
					>
						Back
					</Button>
					<h4>Sort</h4>
					<div className="filters__container">
						<Combobox
							label="Sort by"
							displayType={DisplayType.INLINE}
							model={localState}
							modelProperty="sorter"
							options={sortOptions}
							placeholder="Sort by"
							className="sort-by-btn"
							searchable={false}
							isDisabled={localState.filteredAddOns.length > 0}
						/>
						<If condition={vehiclesOnly}>
							<Combobox
								label="Capacity metric"
								displayType={DisplayType.INLINE}
								model={localState}
								modelProperty="countType"
								options={countOptions}
								placeholder="Show count by"
								className="show-count-by-btn"
								searchable={false}
								isDisabled={localState.filteredAddOns.length > 0}
							/>
						</If>
						<If condition={isNotNullOrUndefined(addOnSections) && addOnSections.length > 0}>
							<div className="filter-add-ons__container">
								<p className="filter-add-ons__label">Filter add ons</p>
								<MultiCombobox
									label="Filter add ons"
									labelVisible={false}
									displayType={DisplayType.BLOCK}
									model={localState}
									modelProperty="filteredAddOns"
									options={addOnSections?.map(x => {
										return {
											display: <Text>{x.name}</Text>,
											value: x.id,
										};
									}) ?? []}
									placeholder=""
									className="add-ons-sort-by-btn"
									searchable={false}
								/>
							</div>
						</If>
					</div>
				</div>
				<Button
					className="apply__button"
					display={Display.Solid}
					colors={Colors.Secondary}
					sizes={Sizes.Medium}
					onClick={() => onClickApply()}
				>
					Apply
				</Button>
			</div>
		</div>
	);
}
