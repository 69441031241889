import * as React from 'react';
import { showModal } from './Base/BaseModalContents';
import { SingleActionModalContentProps, SingleActionModalContent } from './Base/SingleActionModalContent';
import { OverbookingSpaceResult } from '../../../../Services/Api/_HumanWritten/FerryTripEntityService';

export enum OverbookingWarning {
	/**
	 * `General` will not display the specific booking that will cause overbooking.
	 */
	General,
	/**
	 * `Current` relates to the current booking being added/edited.
	 */
	Current,
	/**
	 * `Return` relates to the return booking being added/edited.
	 */
	Return,
	/**
	 * `Both` relates to the current AND return booking being added/edited.
	 */
	Both
}

export interface OverbookingAllowanceModalProps extends Pick<SingleActionModalContentProps, 'onConfirm'> {
	mode?: OverbookingWarning;
	currentTrip?: OverbookingSpaceResult;
	returnTrip?: OverbookingSpaceResult;
}

export function OverbookingAllowanceModal({
	onConfirm,
	mode = OverbookingWarning.General,
	currentTrip,
	returnTrip,
}: OverbookingAllowanceModalProps) {
	const warning = warningMessage(mode, currentTrip, returnTrip);

	const body = (
		<>
			<div>{warning}</div>
		</>
	);

	return (
		<SingleActionModalContent
			title="Overbooking allowance"
			body={body}
			onConfirm={onConfirm}
			confirmContent="Ok"
		/>
	);
}

export function showOverbookingModal(props: OverbookingAllowanceModalProps) {
	const warning = warningMessage(props.mode ?? OverbookingWarning.General, props.currentTrip, props.returnTrip);

	if (warning === null || warning.props.children === undefined) {
		if (props.onConfirm) {
			props.onConfirm();
		}
		return;
	}

	showModal({
		key: 'overbooking-pop-up',
		content: <OverbookingAllowanceModal {...props} />,
	});
}

export function deduceWarning(canFitCurrent: boolean, canFitReturn: boolean) {
	let warningMode = OverbookingWarning.General;

	if (!canFitCurrent && !canFitReturn) {
		warningMode = OverbookingWarning.Both;
	} else if (!canFitCurrent) {
		warningMode = OverbookingWarning.Current;
	} else if (!canFitReturn) {
		warningMode = OverbookingWarning.Return;
	}

	return warningMode;
}

const getOverbookingString = (type: string, trip?: OverbookingSpaceResult) => {
	const cargoSpaceOverbooking = parseFloat((trip?.cargoSpaceOverbookingAmount ?? 0).toFixed(2));
	const passengerSpaceOverbooking = parseFloat((trip?.passengerSpaceOverbookingAmount ?? 0).toFixed(2));

	if (cargoSpaceOverbooking > 0 && passengerSpaceOverbooking > 0) {
		return <>The <strong>{type} booking</strong> will exceed capacity by <strong>{cargoSpaceOverbooking}</strong> cargo spaces and <strong>{passengerSpaceOverbooking}</strong> passenger spaces.</>;
	}
	if (cargoSpaceOverbooking > 0) {
		return <>The <strong>{type} booking</strong> will exceed capacity by <strong>{cargoSpaceOverbooking}</strong> cargo spaces.</>;
	}
	if (passengerSpaceOverbooking > 0) {
		return <>The <strong>{type} booking</strong> will exceed capacity by <strong>{passengerSpaceOverbooking}</strong> passenger spaces.</>;
	}

	return <></>;
};
function warningMessage(
	mode: OverbookingWarning,
	currentTrip?: OverbookingSpaceResult,
	returnTrip?: OverbookingSpaceResult,
) {
	const currentTripString = getOverbookingString('current', currentTrip);
	const returnTripString = getOverbookingString('return', returnTrip);

	switch (mode) {
		case OverbookingWarning.Current:
			return currentTripString;
		case OverbookingWarning.Return:
			return returnTripString;
		case OverbookingWarning.Both:
			return (
				<>
					<>{currentTripString}</>
					<br />
					<>{returnTripString}</>
				</>
			);
		default:
			return null;
	}
}
