import React, { ReactNode, useEffect, useState } from 'react';
import { fetchStoreSingular } from 'Util/_HumanWritten/FetchStoreSingular';
import Auth from 'Views/Components/Auth/Auth';
import useStore from 'Hooks/useStore';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { useLocation } from 'react-router-dom';
import { setStoredEventWizardData } from 'Services/Api/_HumanWritten/BookingWizardCacheService';
import {
	EventBookingWizardData,
	saveEventBookingWizardDataCacheToken,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { stringIsEmpty } from '../../../../../Util/TypeGuards';

export interface EventAuthStepProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (newData: EventBookingWizardData) => void;
	children: ReactNode;
}

export function EventAuthStep({ wizardData, onUpdateWizardData, children }: EventAuthStepProps) {
	const store = useStore();
	const location = useLocation();

	const [loading, setLoading] = useState(!store.loggedIn);
	const [redisId, setRedisId] = useState<string | null>(null);

	useEffect(() => {
		if (!store.loggedIn) {
			setStoredEventWizardData(wizardData).then(response => {
				setRedisId(response);
				saveEventBookingWizardDataCacheToken(response);
				setLoading(false);
			});
		}
	}, []);

	if (loading) {
		return <LottieSpinner />;
	}

	return (
		<Auth
			onAfterSuccess={() => {
				// If that is the value for userId, then it hasn't been set previously, which means that the user
				// wasn't logged in before they successfully authenticated
				if (wizardData.userId === '') {
					const store2 = fetchStoreSingular();
					if (!store2.isAdmin && !store.isStaff && !store2.isManager) {
						const newData = { ...wizardData };
						// default value is only needed because of the typing. If auth was successful then the
						// userId will be set in the store
						newData.userId = store.userId ?? '';

						newData.firstName = (stringIsEmpty(wizardData.firstName) ? store.firstName : wizardData.firstName) ?? '';
						newData.lastName = (stringIsEmpty(wizardData.lastName) ? store.lastName : wizardData.lastName) ?? '';
						newData.phone = (stringIsEmpty(wizardData.phone) ? store.phone : wizardData.phone) ?? '';
						newData.email = (stringIsEmpty(wizardData.email) ? store.email : wizardData.email) ?? '';

						onUpdateWizardData(newData);
					}
				}
			}}
			redirectLocation={`${location.pathname}${redisId != null ? encodeURIComponent(`?id=${redisId}`) : ''}`}
		>
			{children}
		</Auth>
	);
}
