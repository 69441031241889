import axios from 'axios';
import { SERVER_URL } from '../../../Constants';
import { MeasurementEntity } from '../../../Models/Entities';

export interface NewMeasurementDto {
	measurement: MeasurementEntity,
	ferryTripDefaultPrice: number,
	fixedPriceUserDefaultPrice: number,
}

export async function newMeasurement(measurementInfo: NewMeasurementDto) {
	return axios.post(
		`${SERVER_URL}/api/entity/MeasurementEntity/add-new-measurement`,
		measurementInfo,
	);
}
