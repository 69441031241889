import React from 'react';
import { observer } from 'mobx-react';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { UserEntity } from 'Models/Entities';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import usePassengerTypes from 'Hooks/usePassengerTypes';

interface InvoicedUserPricesProps {
	invoicedUser: UserEntity;
	mode: EntityFormMode;
	isReadOnly?: boolean;
}

function PassengerTypePriceInputs({
	mode,
	invoicedUser,
	isReadOnly = false,
}: InvoicedUserPricesProps) {
	usePassengerTypes();

	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const id = `passenger-${x.key}`;
				const modelProperty = passengerTypeStore.getPriceProperty(
					x.passengerTypeKey,
					invoicedUser,
				);
				const singularName = passengerTypeStore.getSingularName(x.passengerTypeKey);
				const placeholder = `Enter ${singularName.toLowerCase()} price`;
				const disabled = !passengerTypeStore.getDisabled(x.passengerTypeKey);
				return (
					<TextField
						key={id}
						id={id}
						model={invoicedUser}
						modelProperty={modelProperty}
						label={singularName}
						isRequired={disabled}
						placeholder={placeholder}
						isReadOnly={mode === EntityFormMode.VIEW || isReadOnly}
						inputProps={{ type: 'number' }}
					/>
				);
			})}
		</>
	);
}

export default observer(PassengerTypePriceInputs);
