/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsTransactionEntity from 'Models/Security/Acl/VisitorsTransactionEntity';
import UserTransactionEntity from 'Models/Security/Acl/UserTransactionEntity';
import StaffTransactionEntity from 'Models/Security/Acl/StaffTransactionEntity';
import ManagerTransactionEntity from 'Models/Security/Acl/ManagerTransactionEntity';
import AdminTransactionEntity from 'Models/Security/Acl/AdminTransactionEntity';
import InvoicedUserTransactionEntity from 'Models/Security/Acl/InvoicedUserTransactionEntity';
import FixedPriceUserTransactionEntity from 'Models/Security/Acl/FixedPriceUserTransactionEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { isNotNullOrUndefined } from 'Util/TypeGuards';
// % protected region % [Add any further imports here] end

export interface ITransactionEntityAttributes extends IModelAttributes {
	expirationTime: Date;
	status: Enums.transactionStatus;
	accessCode: string;
	invoiceNumber: string;
	formActionURL: string;
	invoiced: boolean;
	legacyId: number;
	humanReadableId: string;

	alterations: Array<
		| Models.AlterationEntity
		| Models.IAlterationEntityAttributes
	>;
	discounts: Array<
		| Models.TransactionDiscount
		| Models.ITransactionDiscountAttributes
	>;
	feess: Array<
		| Models.FeeEntity
		| Models.IFeeEntityAttributes
	>;
	giftCertificateUsages: Array<
		| Models.GiftCertificateUsageEntity
		| Models.IGiftCertificateUsageEntityAttributes
	>;
	giftCertificateId?: string;
	giftCertificate?: Models.GiftCertificateEntity | Models.IGiftCertificateEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	/**
	 * True when transaction holds bookings with only one RESERVED alteration each. False otherwise.
	 */
	hasOnlyNewBookings?: boolean;
	/**
	 * True when transaction holds bookings that have been previously invoiced (when booking has an associated
	 * INVOICED or PENDING_INVOICE transaction). False otherwise.
	 */
	canInvoiceBookings?: boolean;
	secondsUntilExpiry?: number;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('TransactionEntity', 'Transaction')
// % protected region % [Customise your entity metadata here] end
export default class TransactionEntity extends Model
	implements ITransactionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsTransactionEntity(),
		new UserTransactionEntity(),
		new StaffTransactionEntity(),
		new ManagerTransactionEntity(),
		new AdminTransactionEntity(),
		new InvoicedUserTransactionEntity(),
		new FixedPriceUserTransactionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Expiration Time'] off begin
	@observable
	@attribute<TransactionEntity, Date>()
	@CRUD({
		name: 'Expiration Time',
		displayType: 'datetimepicker',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public expirationTime: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Expiration Time'] end

	// % protected region % [Modify props to the crud options here for attribute 'Status'] off begin
	@observable
	@attribute<TransactionEntity, Enums.transactionStatus>()
	@CRUD({
		name: 'Status',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.transactionStatusOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.transactionStatusOptions),
		displayFunction: (attr: Enums.transactionStatus) => Enums.transactionStatusOptions[attr],
	})
	public status: Enums.transactionStatus;
	// % protected region % [Modify props to the crud options here for attribute 'Status'] end

	// % protected region % [Modify props to the crud options here for attribute 'Access Code'] off begin
	/**
	 * The code provided by eway
	 */
	@observable
	@attribute<TransactionEntity, string>()
	@CRUD({
		name: 'Access Code',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public accessCode: string;
	// % protected region % [Modify props to the crud options here for attribute 'Access Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Invoice Number'] off begin
	@observable
	@attribute<TransactionEntity, string>()
	@CRUD({
		name: 'Invoice Number',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public invoiceNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'Invoice Number'] end

	// % protected region % [Modify props to the crud options here for attribute 'Form Action URL'] off begin
	@observable
	@attribute<TransactionEntity, string>()
	@CRUD({
		name: 'Form Action URL',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public formActionURL: string;
	// % protected region % [Modify props to the crud options here for attribute 'Form Action URL'] end

	// % protected region % [Modify props to the crud options here for attribute 'Invoiced'] off begin
	/**
	 * True if payment is completed by third party service, false otherwise
	 */
	@observable
	@attribute<TransactionEntity, boolean>()
	@CRUD({
		name: 'Invoiced',
		displayType: 'checkbox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public invoiced: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Invoiced'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] off begin
	@Validators.Integer()
	@observable
	@attribute<TransactionEntity, number>()
	@CRUD({
		name: 'Legacy Id',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyId: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Id'] off begin
	/**
	 * The ID that is displayed on the frontend
	 */
	@observable
	@attribute<TransactionEntity, string>()
	@CRUD({
		name: 'Human Readable Id',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public humanReadableId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Human Readable Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] off begin
		name: 'Alterations',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.AlterationEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'alterations',
			oppositeEntity: () => Models.AlterationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Alteration'] end
	})
	public alterations: Models.AlterationEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Discount'] off begin
		name: 'Discount',
		displayType: 'reference-multicombobox',
		order: 100,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.TransactionDiscount,
		optionEqualFunc: makeJoinEqualsFunc('discountId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'transactionEntity',
			oppositeEntityName: 'discountEntity',
			relationName: 'transaction',
			relationOppositeName: 'discount',
			entity: () => Models.TransactionEntity,
			joinEntity: () => Models.TransactionDiscount,
			oppositeEntity: () => Models.DiscountEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Discount'] end
	})
	public discounts: Models.TransactionDiscount[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Fees'] off begin
		name: 'Feess',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.FeeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'feess',
			oppositeEntity: () => Models.FeeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Fees'] end
	})
	public feess: Models.FeeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate Usage'] off begin
		name: 'Gift Certificate Usages',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.GiftCertificateUsageEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'giftCertificateUsages',
			oppositeEntity: () => Models.GiftCertificateUsageEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate Usage'] end
	})
	public giftCertificateUsages: Models.GiftCertificateUsageEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] off begin
		name: 'Gift Certificate',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.GiftCertificateEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'giftCertificates',
			oppositeEntity: () => Models.GiftCertificateEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Gift Certificate'] end
	})
	public giftCertificateId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public giftCertificate: Models.GiftCertificateEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	public hasOnlyNewBookings?: boolean;

	@observable
	public canInvoiceBookings?: boolean;

	@observable
	public secondsUntilExpiry?: number;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ITransactionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ITransactionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.expirationTime !== undefined) {
				if (attributes.expirationTime === null) {
					this.expirationTime = attributes.expirationTime;
				} else {
					this.expirationTime = moment(attributes.expirationTime).toDate();
				}
			}
			if (attributes.status !== undefined) {
				this.status = attributes.status;
			}
			if (attributes.accessCode !== undefined) {
				this.accessCode = attributes.accessCode;
			}
			if (attributes.invoiceNumber !== undefined) {
				this.invoiceNumber = attributes.invoiceNumber;
			}
			if (attributes.formActionURL !== undefined) {
				this.formActionURL = attributes.formActionURL;
			}
			if (attributes.invoiced !== undefined) {
				this.invoiced = attributes.invoiced;
			}
			if (attributes.legacyId !== undefined) {
				this.legacyId = attributes.legacyId;
			}
			if (attributes.humanReadableId !== undefined) {
				this.humanReadableId = attributes.humanReadableId;
			}
			if (attributes.alterations !== undefined && Array.isArray(attributes.alterations)) {
				for (const model of attributes.alterations) {
					if (model instanceof Models.AlterationEntity) {
						this.alterations.push(model);
					} else {
						this.alterations.push(new Models.AlterationEntity(model));
					}
				}
			}
			if (attributes.discounts !== undefined && Array.isArray(attributes.discounts)) {
				for (const model of attributes.discounts) {
					if (model instanceof Models.TransactionDiscount) {
						this.discounts.push(model);
					} else {
						this.discounts.push(new Models.TransactionDiscount(model));
					}
				}
			}
			if (attributes.feess !== undefined && Array.isArray(attributes.feess)) {
				for (const model of attributes.feess) {
					if (model instanceof Models.FeeEntity) {
						this.feess.push(model);
					} else {
						this.feess.push(new Models.FeeEntity(model));
					}
				}
			}
			if (attributes.giftCertificateUsages !== undefined && Array.isArray(attributes.giftCertificateUsages)) {
				for (const model of attributes.giftCertificateUsages) {
					if (model instanceof Models.GiftCertificateUsageEntity) {
						this.giftCertificateUsages.push(model);
					} else {
						this.giftCertificateUsages.push(new Models.GiftCertificateUsageEntity(model));
					}
				}
			}
			if (attributes.giftCertificateId !== undefined) {
				this.giftCertificateId = attributes.giftCertificateId;
			}
			if (attributes.giftCertificate !== undefined) {
				if (attributes.giftCertificate === null) {
					this.giftCertificate = attributes.giftCertificate;
				} else if (attributes.giftCertificate instanceof Models.GiftCertificateEntity) {
					this.giftCertificate = attributes.giftCertificate;
					this.giftCertificateId = attributes.giftCertificate.id;
				} else {
					this.giftCertificate = new Models.GiftCertificateEntity(attributes.giftCertificate);
					this.giftCertificateId = this.giftCertificate.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (isNotNullOrUndefined(attributes.canInvoiceBookings)) {
				this.canInvoiceBookings = attributes.canInvoiceBookings;
			}
			if (isNotNullOrUndefined(attributes.hasOnlyNewBookings)) {
				this.hasOnlyNewBookings = attributes.hasOnlyNewBookings;
			}
			if (isNotNullOrUndefined(attributes.secondsUntilExpiry)) {
				this.secondsUntilExpiry = attributes.secondsUntilExpiry;
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		secondsUntilExpiry
		discounts {
			${Models.TransactionDiscount.getAttributes().join('\n')}
			discount {
				${Models.DiscountEntity.getAttributes().join('\n')}
			}
		}
		alterations {
			${Models.AlterationEntity.getAttributes().join('\n')}
		}
		feess {
			${Models.FeeEntity.getAttributes().join('\n')}
		}
		giftCertificateUsages {
			${Models.GiftCertificateUsageEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			discounts: {},
			alterations: {},
			feess: {},
			giftCertificateUsages: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'discounts',
							'feess',
							'giftCertificateUsages',
							'giftCertificate',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(TransactionEntity.prototype, 'created');
CRUD(modifiedAttr)(TransactionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
