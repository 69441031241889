import * as React from 'react';
import BookingFormView from './BookingFormView';
import BookingFormEdit from './BookingFormEdit/BookingFormEdit';
import { DisableContinueState } from '../CheckInUtils';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';

export enum BookingFormMode {
	View,
	Edit,
	Add,
}

export interface BookingFormProps {
	booking: CheckInBookingOverviewDto;
	/**
	 * Default mode is VIEW
	 */
	mode?: BookingFormMode;
	checkIn?: () => void;
	/**
	 * True if changes to the booking is to be applied to the return trip as well. False otherwise.
	 */
	applyToReturn?: boolean;
	disableState: DisableContinueState;
	className?: string;
	oneInputPerRow?: boolean;
}

/**
 * It was decided it is easier to manage view/edit/add mode using two components.
 *
 * One for VIEW and one for EDIT/ADD. This would produce more readable code.
 */
function BookingForm({
	booking,
	mode = BookingFormMode.View,
	checkIn,
	applyToReturn,
	disableState,
	className,
	oneInputPerRow = false,
}: BookingFormProps) {
	if (mode !== BookingFormMode.View) {
		return (
			<BookingFormEdit
				key={booking.id}
				booking={booking}
				mode={mode}
				applyToReturn={applyToReturn}
				disableState={disableState}
				className={className}
				oneInputPerRow={oneInputPerRow}
			/>
		);
	}

	return (
		<BookingFormView
			key={booking.id}
			booking={booking}
			checkIn={checkIn}
			className={className}
		/>
	);
}

export default BookingForm;
