import axios from 'axios';
import { buildUrl } from '../FetchUtils';
import { PASSENGER_TYPE_ENTITY_URL } from '../../Constants';
import { IPassengerTypeEntityAttributes, PassengerTypeEntity } from '../../Models/Entities';

/**
 * Fetches all PassengerTypeEntitys from the database.
 */
export async function fetchPassengerTypes(): Promise<PassengerTypeEntity[]> {
	return axios.get(
		buildUrl(`${PASSENGER_TYPE_ENTITY_URL}/get-all-passenger-types`),
	).then(x => x.data
		.map((y: Partial<IPassengerTypeEntityAttributes>) => new PassengerTypeEntity(y)));
}
