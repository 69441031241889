import * as React from 'react';
import { action } from 'mobx';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import { getFormattedHoursDays } from 'Util/StringUtils';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import { FetchTrailerCancellationFee } from '../../../../../Services/Api/_HumanWritten/PricingService/PricingService';

export interface RemoveTrailerModalProps {
	refundedAmount: number,
	hoursBeforeDeparture: number,
}

export const ModalContent = ({
	refundedAmount,
	hoursBeforeDeparture,
}: RemoveTrailerModalProps) => {
	return (
		<div className="manager-cancel-booking-modal-content">
			{/* eslint-disable-next-line max-len */}
			<h5>As you are providing less than {getFormattedHoursDays(hoursBeforeDeparture)} notice, you will receive a credit of
				<span>
					<strong> {displayAsAud(refundedAmount)}</strong>.
				</span>
			</h5>
			<h5>Would you like to proceed?</h5>
		</div>
	);
};

export const RenderRemoveTrailerModal = async (
	alterReturn: boolean,
	bookingToEdit: string,
	hoursBeforeDeparture: number,
) => {
	const refundAmount = await FetchTrailerCancellationFee(bookingToEdit ?? '', alterReturn);

	return modalWithCustomInput(
		'Cancel trailer?',
		<ModalContent refundedAmount={refundAmount} hoursBeforeDeparture={hoursBeforeDeparture} />,
		{
			cancelText: 'Back',
			confirmText: 'Continue',
			resolveOnCancel: true,
			onConfirm: action(() => {
				return true;
			}),
		},
	);
};
