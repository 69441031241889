import { AdditionalBookingSectionEntity } from 'Models/Entities';
import { compareFnByNumberAsc } from 'Util/_HumanWritten/SortUtils';
import {
	getAllAdditionalOptionSections,
} from '../Services/Api/_HumanWritten/AdditionalBookingOptionsService';
import { AdditionalBookingOptionEntity } from './Entities';

interface IAddOnStore {
	addOnSections: AdditionalBookingSectionEntity[];
	retrieveAddOnsAsync(refresh?: boolean, userId?: string | null): Promise<void>;
	getFerriesAndRoutes(): Set<string>;
	getAddOnSectionsByFerriesAndRoutes(ids: string[]): AdditionalBookingSectionEntity[];
	hasPassengerAddOns: boolean;
	findAddOn: (addOnOptionId: string) => AdditionalBookingOptionEntity | null;
}

class AddOnStore implements IAddOnStore {
	public addOnSections: AdditionalBookingSectionEntity[] = [];
	private _fetched: boolean = false;

	/**
	 * This should be called asynchronously to ensure additional booking sections and options are populated.
	 */
	public async retrieveAddOnsAsync(refresh = false, userId: string | null = null) {
		if (refresh) {
			this._fetched = false;
		}
		if (this._fetched) {
			return;
		}

		const addOnSections = await getAllAdditionalOptionSections(userId);

		this.addOnSections = addOnSections.sort(compareFnByNumberAsc(x => x.order));
		this._fetched = true;
	}

	public getFerriesAndRoutes() {
		const set = new Set<string>();
		for (const addOnSection of this.addOnSections) {
			for (const ferry of addOnSection.ferriess) {
				set.add(ferry.ferriesId);
			}
			for (const route of addOnSection.routess) {
				set.add(route.routesId);
			}
		}

		return set;
	}

	public getAddOnSectionsByFerriesAndRoutes(ids: string[]): AdditionalBookingSectionEntity[] {
		const res = this.addOnSections.filter(x => {
			const ferriesIds = x.ferriess.map(y => y.ferriesId);
			const routesIds = x.routess.map(y => y.routesId);
			const ferriesAndRoutes = ferriesIds.concat(routesIds);

			for (const id of ids) {
				if (ferriesAndRoutes.includes(id)) {
					return true;
				}
			}

			return false;
		});
		return res;
	}

	public get hasPassengerAddOns(): boolean {
		for (const section of this.addOnSections) {
			const options = section.additionalBookingOptions ?? [];
			for (const option of options) {
				if (option.isvehicleonly !== true) {
					return true;
				}
			}
		}
		return false;
	}

	public findAddOn(addOnOptionId: string): AdditionalBookingOptionEntity | null {
		for (const section of this.addOnSections) {
			const addOn = section.additionalBookingOptions.find(x => x.id === addOnOptionId);
			if (addOn) {
				return addOn;
			}
		}
		return null;
	}
}

const addOnStore: IAddOnStore = new AddOnStore();
export default addOnStore;
