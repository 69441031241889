import { isNotNullOrUndefined, isNullOrUndefined } from '../../../Util/TypeGuards';

// Converts a hex colour value to RGB colour value
export function HexToRgb(hex: string) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16),
	} : null;
}

export function GetRgbColourString(rgbColour: unknown) {
	if (isNotNullOrUndefined(rgbColour)) {
		const colour = rgbColour as { r: number, g: number, b: number };
		const red = colour.r / 255;
		const green = colour.g / 255;
		const blue = colour.b / 255;

		return `${red}, ${green}, ${blue}`;
	}
	return '';
}
