/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsRouteReferenceManyToMany from '../Security/Acl/VisitorsRouteReferenceManyToMany';
import UserRouteReferenceManyToMany from '../Security/Acl/UserRouteReferenceManyToMany';
import StaffRouteReferenceManyToMany from '../Security/Acl/StaffRouteReferenceManyToMany';
import ManagerRouteReferenceManyToMany from '../Security/Acl/ManagerRouteReferenceManyToMany';
import AdminRouteReferenceManyToMany from '../Security/Acl/AdminRouteReferenceManyToMany';
import InvoicedUserRouteReferenceManyToMany from '../Security/Acl/InvoicedUserRouteReferenceManyToMany';
import FixedPriceUserRouteReferenceManyToMany from '../Security/Acl/FixedPriceUserRouteReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBookingCutoffRouteAttributes extends IModelAttributes {
	bookingCutoffId: string;
	routeId: string;

	bookingCutoff: Models.BookingCutoffEntity | Models.IBookingCutoffEntityAttributes;
	route: Models.RouteEntity | Models.IRouteEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('BookingCutoffRoute')
export default class BookingCutoffRoute
	extends Model
	implements IBookingCutoffRouteAttributes {
	public static acls: IAcl[] = [
		new VisitorsRouteReferenceManyToMany(),
		new UserRouteReferenceManyToMany(),
		new StaffRouteReferenceManyToMany(),
		new ManagerRouteReferenceManyToMany(),
		new AdminRouteReferenceManyToMany(),
		new InvoicedUserRouteReferenceManyToMany(),
		new FixedPriceUserRouteReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public bookingCutoffId: string;

	@observable
	@attribute()
	public routeId: string;

	@observable
	@attribute({ isReference: true })
	public bookingCutoff: Models.BookingCutoffEntity;

	@observable
	@attribute({ isReference: true })
	public route: Models.RouteEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IBookingCutoffRouteAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.bookingCutoffId) {
				this.bookingCutoffId = attributes.bookingCutoffId;
			}
			if (attributes.routeId) {
				this.routeId = attributes.routeId;
			}

			if (attributes.bookingCutoff) {
				if (attributes.bookingCutoff instanceof Models.BookingCutoffEntity) {
					this.bookingCutoff = attributes.bookingCutoff;
					this.bookingCutoffId = attributes.bookingCutoff.id;
				} else {
					this.bookingCutoff = new Models.BookingCutoffEntity(attributes.bookingCutoff);
					this.bookingCutoffId = this.bookingCutoff.id;
				}
			} else if (attributes.bookingCutoffId !== undefined) {
				this.bookingCutoffId = attributes.bookingCutoffId;
			}

			if (attributes.route) {
				if (attributes.route instanceof Models.RouteEntity) {
					this.route = attributes.route;
					this.routeId = attributes.route.id;
				} else {
					this.route = new Models.RouteEntity(attributes.route);
					this.routeId = this.route.id;
				}
			} else if (attributes.routeId !== undefined) {
				this.routeId = attributes.routeId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
