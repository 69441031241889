import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { reaction, runInAction } from 'mobx';
import classNames from 'classnames';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import Tabs from 'Views/Components/Tabs/Tabs';
import { FerryTripEntity, NotesEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {
	showTripNewDepartureEmailModal,
} from 'Views/Components/_HumanWritten/Modal/TripNewDepartureEmailModalContents/TripNewDepartureEmailModalContents';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { getFerryTripAlterationssCount } from 'Services/CustomGql/FerryTripEntityType';
import FerryTripDeleteBtn from './FerryTripDeleteBtn';
import { SecuredGroups } from 'Models/Security/UserGroups';
import If from 'Views/Components/If/If';
import {
	SendFerryTripCommunications,
} from 'Services/Api/_HumanWritten/CommunicationService';
import { FerryTripCommunicationTabComponent, CommunicationTabProps } from './FerryTripCommunicationTab';
import FerryTripPriceTabComponent from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripPricingTab';
import FerryTripDetailsTabComponent from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripDetailsTab';
import {
	WaitlistTabComponent,
} from 'Views/Components/_HumanWritten/FerrySchedule/FerryTripSideBar/FerryTripWaitlistTab';
import { store } from '../../../../../Models/Store';
import { FerryTripNotesTab } from './FerryTripNotesTab';
import { getAllFerryTripNotes, saveAllNotes } from '../../../../../Services/Api/_HumanWritten/NotesService';
import { INotesEntityAttributes } from '../../../../../Models/Entities/NotesEntity';

export interface ISidePanelProps {
	hidePanel: (confirm: boolean, needRefetch: boolean) => void;
	ferryTrip: FerryTripEntity;
	originalFerryTrip: FerryTripEntity;
}

function FerryTripSideBar({
	ferryTrip,
	originalFerryTrip,
	hidePanel,
}: ISidePanelProps) {
	const [viewMode, setViewMode] = useState(!!ferryTrip.id);
	const [tabNumber, setTabNumber] = useState(0);
	const [createMode] = useState(ferryTrip.id === undefined);
	const [loading, setLoading] = useState(false);
	const needConfirm = useRef(false);
	const [editNotesMode, setEditNotesMode] = useState<boolean>(false);
	const [existingNotes, setExistingNotes] = useState<NotesEntity[] | null>(null);

	const communicationTabModel = useLocalStore<CommunicationTabProps>(() => {
		return {
			ferryTrip: ferryTrip,
			model: {
				subject: null,
				message: '',
				sendEmail: false,
				sendSms: false,
				extraPhone: '',
			},
			isDisabled: createMode || !viewMode,
		};
	});

	useEffect(() => {
		const setNeedConfirm = () => {
			needConfirm.current = true;
		};
		// If any changes are made on any of these fields it will trigger the modal to confirm closing the side-bar
		const disposals = [
			reaction(() => ferryTrip.departureDateTime, setNeedConfirm),
			reaction(() => ferryTrip.routeId, setNeedConfirm),
			reaction(() => ferryTrip.adultPassengerPrice, setNeedConfirm),
			reaction(() => ferryTrip.childPassengerPrice, setNeedConfirm),
			reaction(() => ferryTrip.infantPassengerPrice, setNeedConfirm),
			reaction(() => ferryTrip.prices, setNeedConfirm),
		];

		// Clean up all of the reactions after one has been triggered
		return () => {
			disposals.map(disposal => disposal());
		};
	}, [ferryTrip]);

	const resetCommunicationTab = () => {
		runInAction(() => {
			communicationTabModel.model.message = '';
			communicationTabModel.model.sendSms = false;
			communicationTabModel.model.sendEmail = false;
			communicationTabModel.model.subject = null;
		});
	};
	const handleSave = async (trip : FerryTripEntity, newTrip: boolean) => {
		try {
			setLoading(true);
			if (isNullOrUndefined(trip.id)) {
				// Trip is new
				await trip.save({ prices: {} });
				await handleSaveSuccess(newTrip);
				setLoading(false);
				return;
			}

			const tripAlterationCount = await getFerryTripAlterationssCount(trip.id);

			if (isNotNullOrUndefined(tripAlterationCount) && tripAlterationCount === 0) {
				await trip.save({ prices: {} });
				await handleSaveSuccess(newTrip);
				setLoading(false);
				return;
			}

			// Need to compare dates using ISO string values
			if (originalFerryTrip.departureDateTime.toISOString() !== ferryTrip.departureDateTime.toISOString()) {
				showTripNewDepartureEmailModal({
					ferryTrip,
					onConfirm: async () => {
						await handleSaveSuccess(newTrip, true);
					},
				});
				setLoading(false);
				return;
			}

			await trip.save({ prices: {} });
			await handleSaveSuccess(newTrip);
			setLoading(false);
		} catch (e: any) {
			alertToast(
				'Ensure that you have selected a ferry and filled in the pricing for that trip.',
				'error',
				'Couldn\'t save ferry trip',
			);
			setLoading(false);
		}
	};

	const handleSaveSuccess = async (newTrip: boolean, emailsSent = false) => {
		hidePanel(false, true);
		let header;
		let body;

		if (newTrip) {
			header = 'New ferry trip added!';
			body = 'You have successfully created a new ferry trip.';
		} else {
			header = 'Ferry trip updated!';
			if (emailsSent) {
				body = 'The details for the ferry trip are updated and emails have been sent to customers.';
			} else {
				body = 'You have successfully updated the details for the ferry trip.';
			}
		}

		return alertToast(body, 'success', header);
	};

	const handleSaveNotes = async () => {
		if (existingNotes) {
			await saveAllNotes(existingNotes);
			const result = await getAllFerryTripNotes(ferryTrip.id);
			setExistingNotes(result.data.map((y: Partial<INotesEntityAttributes> | undefined) => new NotesEntity(y)));
			setEditNotesMode(false);
		}
	};

	// These fields are required and may need to be added to the add ferry trip modal in the future
	ferryTrip.passengerOverbookingAllowance = 0;
	ferryTrip.vehicleOverbookingAllowance = 0;

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<div className="sidebar custom-sidebar__container add-ferry-trip__sidebar">
				<div
					className="sidebar-click-interceptor"
					onClick={() => hidePanel(needConfirm.current, false)}
					role="button"
					onKeyDown={() => hidePanel(needConfirm.current, false)}
					tabIndex={0}
					style={{ height: '100%' }}
					aria-label="Close modal"
				/>
				<div className={classNames('sidebar-info')}>
					<div className="custom-sidebar__header">
						<div>
							{/* eslint-disable-next-line no-nested-ternary */}
							<h2>{createMode ? 'New trip' : (viewMode ? 'View trip' : 'Edit trip')}</h2>
							<h5>Enter details and save trip</h5>
						</div>
						<div className="custom-sidebar__private-toggle">
							<Button
								icon={{
									icon: ferryTrip.hidden
										? 'look-off'
										: 'look',
									iconPos: 'icon-right',
								}}
								disabled={viewMode}
								colors={Colors.Primary}
								onClick={() => {
									runInAction(() => {
										ferryTrip.hidden = !ferryTrip.hidden;
									});
								}}
							>
								<span className="tooltiptext">
									{
										viewMode
											? `This ferry trip is ${ferryTrip.hidden ? 'private' : 'public'}.`
											: `Make this ferry trip ${ferryTrip.hidden ? 'public' : 'private'}.`
									}
								</span>
							</Button>
						</div>
					</div>
					<Tabs
						tabs={[
							{
								name: 'Details',
								key: 'trip-details',
								component: <FerryTripDetailsTabComponent
									ferryTrip={ferryTrip}
									showGenerateManifest={!createMode}
									isDisabled={viewMode || !createMode}
									enableStartTripInput={!createMode && !viewMode}
								/>,
							},
							{
								name: 'Pricing',
								key: 'trip-pricing',
								component: <FerryTripPriceTabComponent
									ferryTrip={ferryTrip}
									isDisabled={viewMode}
								/>,
							},
							{
								name: 'Communication',
								key: 'trip-communication-service',
								component: <FerryTripCommunicationTabComponent
									ferryTrip={communicationTabModel.ferryTrip}
									model={communicationTabModel.model}
									isDisabled={!viewMode}
								/>,
								disabled: !viewMode,
							},
							{
								name: 'Waitlist',
								key: 'waitlist-list',
								component: <WaitlistTabComponent
									ferryTripId={communicationTabModel.ferryTrip.id}
									hasInitialCargo={communicationTabModel.ferryTrip?.ferry?.vehicleCapacity > 0}
								/>,
								disabled: !viewMode,
							},
							{
								name: 'Notes',
								key: 'notes-tab',
								component: <FerryTripNotesTab
									ferryTripId={ferryTrip.id}
									existingNotes={existingNotes}
									setExistingNotes={setExistingNotes}
									isDisabled={createMode || !editNotesMode}
									refresh={editNotesMode}
								/>,
								disabled: createMode,
							},
						]}
						onTabClicked={tabNum => {
							setTabNumber(tabNum);
						}}
					/>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="custom-sidebar__action-buttons">
						<If condition={tabNumber !== 2 && tabNumber !== 3 && tabNumber !== 4 && store.isManager}>
							{viewMode && <FerryTripDeleteBtn ferryTrip={ferryTrip} hidePanel={hidePanel} />}
							{!viewMode && (
								<Button
									className="cancel_edit_add_ferry_trip"
									display={Display.Outline}
									colors={Colors.Secondary}
									sizes={Sizes.Medium}
									buttonProps={{ id: 'cancel_edit_add_ferry_trip' }}
									onClick={() => hidePanel(needConfirm.current, false)}
								>
									Cancel changes
								</Button>
							)}
							<Button
								className="confirm_edit_add_ferry_trip"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={viewMode ? Colors.Secondary : Colors.Alternate}
								buttonProps={{ id: 'confirm_edit_add_ferry_trip' }}
								onClick={() => viewMode ? setViewMode(false) : handleSave(ferryTrip, createMode)}
							>
								{/* eslint-disable-next-line no-nested-ternary */}
								{viewMode ? 'Edit details' : (loading ? 'Saving...' : 'Save changes')}
							</Button>
						</If>
						<If condition={tabNumber === 2}>
							<Button
								className="cancel_edit_add_ferry_trip"
								display={Display.Outline}
								colors={Colors.Secondary}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'cancel_edit_add_ferry_trip' }}
								disabled={loading || (stringIsEmpty(communicationTabModel.model.message)
									&& stringIsEmpty(communicationTabModel.model.subject)
									&& !communicationTabModel.model.sendSms
									&& !communicationTabModel.model.sendEmail)}
								onClick={() => resetCommunicationTab()}
							>
								Clear
							</Button>
							<Button
								className="confirm_edit_add_ferry_trip"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={viewMode ? Colors.Secondary : Colors.Alternate}
								buttonProps={{ id: 'confirm_edit_add_ferry_trip' }}
								disabled={loading || stringIsEmpty(communicationTabModel.model.message)
									|| (!communicationTabModel.model.sendSms
										&& !communicationTabModel.model.sendEmail)
									|| stringIsEmpty(communicationTabModel.model.subject)}
								onClick={() => {
									let errorToastMessage = '';
									if (communicationTabModel.model.message === '') {
										errorToastMessage = 'Enter message to send';
									}
									if (!communicationTabModel.model.sendSms
										&& !communicationTabModel.model.sendEmail) {
										const errorMessage = 'Select mode of communication';
										errorToastMessage += errorToastMessage === ''
											? errorMessage
											: ` and ${errorMessage.toLowerCase()}`;
									}
									if (errorToastMessage !== '') {
										alertToast(errorToastMessage, 'error');
									} else {
										setLoading(true);
										SendFerryTripCommunications({
											ferryTripId: ferryTrip.id,
											sendEmail: communicationTabModel.model.sendEmail,
											sendSms: communicationTabModel.model.sendSms,
											bodyContent: communicationTabModel.model.message,
											sendToLoggedInUser: false,
											subjectHeader: communicationTabModel.model.subject,
										}).then(x => {
											resetCommunicationTab();
											if (isNotNullOrUndefined(x.SMS_SUCCESS)) {
												alertToast(x.SMS_SUCCESS, 'success');
											}
											if (isNotNullOrUndefined(x.SMS_ERROR)) {
												alertToast(x.SMS_ERROR, 'error');
											}
											if (isNotNullOrUndefined(x.EMAIL_SUCCESS)) {
												alertToast(x.EMAIL_SUCCESS, 'success');
											}
											if (isNotNullOrUndefined(x.EMAIL_ERROR)) {
												alertToast(x.EMAIL_ERROR, 'error');
											}
											setLoading(false);
										}).catch(() => {
											alertToast('Failed to send communications', 'error');
											setLoading(false);
										});
									}
								}}
							>
								{loading ? 'Sending...' : 'Send'}
							</Button>
						</If>
						<If condition={tabNumber === 4}>
							<If condition={editNotesMode}>
								<Button
									className="cancel-edit-notes"
									display={Display.Outline}
									colors={Colors.Secondary}
									sizes={Sizes.Medium}
									buttonProps={{ id: 'cancel-edit-notes' }}
									disabled={false}
									onClick={() => {
										setEditNotesMode(false);
									}}
								>
									Cancel
								</Button>
							</If>
							<Button
								className="edit_notes"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={viewMode ? Colors.Secondary : Colors.Alternate}
								buttonProps={{ id: 'edit_notes' }}
								disabled={false}
								onClick={async () => {
									if (editNotesMode) {
										setLoading(true);
										await handleSaveNotes();
										setLoading(false);
									} else {
										setEditNotesMode(true);
									}
								}}
							>
								{/* eslint-disable-next-line no-nested-ternary */}
								{!editNotesMode ? 'Edit notes' : (loading ? 'Saving...' : 'Save')}
							</Button>
						</If>
					</ButtonGroup>
				</div>
			</div>
		</SecuredPage>
	);
}

export default observer(FerryTripSideBar);
