/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { EventBookingWizardData } from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';
import { WhiteBackground } from 'Views/Components/_HumanWritten/WhiteBackground';
import { TextField } from 'Views/Components/TextBox/TextBox';
import InputCounter from 'Views/Components/_HumanWritten/PassengerAmount/InputCounter';
import { EventDetailEntity } from 'Models/Entities';
import { WizardErrors } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	GetFormattedDateTime,
} from 'Views/Components/_HumanWritten/Events/EventCards/EventUtils';
import If from 'Views/Components/If/If';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	stringIsEmpty,
	stringNotEmpty,
} from 'Util/TypeGuards';
import useStore from 'Hooks/useStore';
import { displayAsAud } from 'Util/_HumanWritten/CurrencyUtils';
import {
	ScrollToItem,
} from 'Validators/Functions/HumanWritten/ScrollToElement';

export interface EventTicketSelectionTabProps {
	wizardData: EventBookingWizardData;
	eventDetails: EventDetailEntity;
	onUpdateWizardData: (wizardData: EventBookingWizardData) => void;
	errors: WizardErrors<EventBookingWizardData>;
}

export function EventTicketTab({
	wizardData,
	onUpdateWizardData,
	eventDetails,
	errors,
}: EventTicketSelectionTabProps) {
	const store = useStore();

	useEffect(() => {
		ScrollToItem('.event-booking-wizard__tab-header', 'end');
	}, []);

	// Pre-fill all data if the field are empty and the logged in user is NOT a staff member
	useEffect(() => {
		if (store.loggedIn && !store.isStaff) {
			const newData = { ...wizardData };

			newData.firstName = (stringIsEmpty(wizardData.firstName) ? store.firstName : wizardData.firstName) ?? '';
			newData.lastName = (stringIsEmpty(wizardData.lastName) ? store.lastName : wizardData.lastName) ?? '';
			newData.phone = (stringIsEmpty(wizardData.phone) ? store.phone : wizardData.phone) ?? '';
			newData.email = (stringIsEmpty(wizardData.email) ? store.email : wizardData.email) ?? '';

			onUpdateWizardData(newData);
		}
	}, []);

	return (
		<div className="events-ticket-selection-tab-container">
			<h2 className="event-booking-wizard__tab-header">Purchase tickets</h2>
			<WhiteBackground
				noMargin
				content={(
					<div className="events-ticket-selection__details-container">
						<h4 className="events-ticket-tab__event-name">{eventDetails.name}</h4>
						<p className="events-ticket-tab__event-date">
							{GetFormattedDateTime(
								eventDetails.ferryTrip!.startDate,
								eventDetails.ferryTrip!.endDate,
								eventDetails.ferryTrip!.startTime,
								eventDetails.ferryTrip!.endTime,
							)}
						</p>
						<div className="events-ticket-tab__customer-details-section">
							<TextField
								id="first_name"
								model={wizardData}
								modelProperty="firstName"
								label="First name"
								placeholder="Enter first name"
								isRequired
								onAfterChange={() => {
									const newData = { ...wizardData };
									onUpdateWizardData(newData);
								}}
								errors={errors.firstName}
							/>
							<TextField
								id="last_name"
								model={wizardData}
								modelProperty="lastName"
								label="Last name"
								placeholder="Enter last name"
								isRequired
								onAfterChange={() => {
									const newData = { ...wizardData };
									onUpdateWizardData(newData);
								}}
								errors={errors.lastName}
							/>
							<TextField
								id="email"
								model={wizardData}
								modelProperty="email"
								label="Email"
								placeholder="Enter email"
								isRequired
								onAfterChange={() => {
									const newData = { ...wizardData };
									onUpdateWizardData(newData);
								}}
								errors={errors.email}
							/>
							<TextField
								id="phone"
								model={wizardData}
								modelProperty="phone"
								label="Phone"
								placeholder="Enter phone"
								isRequired
								inputProps={{
									inputMode: 'numeric',
								}}
								onAfterChange={() => {
									const newData = { ...wizardData };
									onUpdateWizardData(newData);
								}}
								errors={errors.phone}
							/>
						</div>
						<p className="ticket-type__label">Select tickets<span className="required">*</span></p>
						<If condition={stringNotEmpty(errors.selectedTickets)}>
							<p className="input-group--error">{errors.selectedTickets}</p>
						</If>
						<div className="ticket-type__counter-inputs">
							{eventDetails.customTicketTypes.map(x => {
								return (
									<InputCounter
										key={x.id}
										title={x.singularName}
										subtitle={displayAsAud(x.price)}
										tooltip={x.description}
										amount={wizardData.selectedTickets.find(y => y.customTicketTypeId === x.id)?.quantity ?? 0}
										subtractDisabled={isNullOrUndefined(wizardData?.selectedTickets?.find(t => t.customTicketTypeId === x.id))}
										onSubtract={() => {
											const newData: EventBookingWizardData = { ...wizardData };

											const existingTicket = newData.selectedTickets.find(t => t.customTicketTypeId === x.id);
											if (isNotNullOrUndefined(existingTicket)) {
												existingTicket.quantity--;
												if (existingTicket.quantity === 0) {
													newData.selectedTickets = newData.selectedTickets.filter(y => y.customTicketTypeId !== x.id);
												}
											}
											onUpdateWizardData(newData);
										}}
										onAdd={() => {
											const newData: EventBookingWizardData = { ...wizardData };

											const existingTicket = newData.selectedTickets.find(t => t.customTicketTypeId === x.id);
											if (isNotNullOrUndefined(existingTicket)) {
												existingTicket.quantity++;
											} else {
												newData.selectedTickets.push({
													customTicketTypeId: x.id,
													quantity: 1,
												});
											}
											onUpdateWizardData(newData);
										}}
									/>
								);
							})}
						</div>
					</div>
				)}
			/>
		</div>
	);
}
