import * as React from 'react';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import BtnAdd from 'images/btn-add.svg';
import BtnSubtract from 'images/btn-subtract.svg';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';

interface InputCounterProps {
	title: string;
	subtitle?: string;
	tooltip?: string;
	amount: number;
	subtractDisabled: boolean;
	addDisabled?: boolean;
	className?: string;
	onSubtract: () => void;
	onAdd: () => void;
}

export default function InputCounter({
	title,
	subtitle,
	tooltip,
	amount,
	subtractDisabled,
	addDisabled,
	className,
	onSubtract,
	onAdd,
}: InputCounterProps) {
	const subtractBtnAlt = `subtract-${title}`;
	const addBtnAlt = `add-${title}`;
	return (
		<div className={classNames('input-counter', className)}>
			<div className="input-counter__row">
				<div>
					<div className="input-counter-title-container">
						<p className="input-counter-title">
							{title}
							{tooltip && (
								<CustomTooltip key="test">
									{tooltip}
								</CustomTooltip>
							)}
						</p>
						<p className="input-counter-subtitle">{subtitle}</p>
					</div>
				</div>
				<div className="amount-controls-container">
					<Button className="amount-btn subtract" disabled={subtractDisabled} onClick={onSubtract}>
						<img alt={subtractBtnAlt} src={BtnSubtract} />
					</Button>
					<div className="amount-label-container">
						<h2 className="amount-label">{amount}</h2>
					</div>
					<Button className="amount-btn add" disabled={addDisabled} onClick={onAdd}>
						<img alt={addBtnAlt} src={BtnAdd} />
					</Button>
				</div>
			</div>
		</div>
	);
}
