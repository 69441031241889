import React from 'react';
import { Button, Colors, Display } from 'Views/Components/Button/Button';

export interface BulkBookingBottomBarProps {
	tripCount: number,
	onCancel: () => void,
	onBookTrips: () => void,
}

export function BulkBookingBottomBar({
	tripCount,
	onCancel,
	onBookTrips,
}: BulkBookingBottomBarProps) {
	return (
		<div className="bulk-booking-bottom-bar__wrap">
			<div className="bulk-booking-bottom-bar__counter">
				<span className="bulk-booking-bottom-bar__counter-number">
					<p>
						{tripCount}
					</p>
				</span>
				<p className="bulk-booking-bottom-bar__counter-text">
					Ferry trips selected
				</p>
			</div>
			<div className="bulk-booking-bottom-bar__btn-wrap">
				<Button
					className="bulk-booking-bottom-bar__cancel"
					display={Display.Solid}
					colors={Colors.Secondary}
					onClick={onCancel}
				>
					Cancel
				</Button>
				<Button
					className="bulk-booking-bottom-bar__book-trips"
					display={Display.Solid}
					colors={Colors.Primary}
					icon={{ icon: 'arrow-right', iconPos: 'icon-right' }}
					onClick={onBookTrips}
				>
					Book trips
				</Button>
			</div>
		</div>
	);
}
