import { BookingEntity } from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { getMostRecentAlterationFees } from 'Util/_HumanWritten/AlterationSortingUtils';
import { wizardModeOptions } from 'Models/Enums';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import {
	AdditionalOption,
	BookingWizardCartFields,
	BookingWizardData,
	getOldFerryBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	EventBookingWizardData,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/EventsBookingWizardData';

// Converts a bookingEntity to an instance of BookingWizardData to be used in the local storage
// primarily for editing bookings from the cart tab
export function ferryBookingToWizardData(
	bookingList: BookingEntity[],
	currentBooking?: BookingWizardData,
	selectedTrips?: SelectedTrips,
	alteration?: boolean,
	alterReturn: boolean = true,
): BookingWizardCartFields[] {
	let editBooking = false;
	const listOfBookings: BookingWizardCartFields[] = bookingList
		.sort((a: BookingEntity, b: BookingEntity) => a.created > b.created ? 1 : -1)
		.map(x => {
			if (x.alterations.length !== 0) {
				if (x.alterations[0]?.bookingId === currentBooking?.bookingToEdit) {
					editBooking = true;
					return {
						wizardData: currentBooking,
						selectedTrips: selectedTrips,
					} as BookingWizardCartFields;
				}
			}
			// This is to determine the return booking info as a booking can
			// either have a returnBooking or be the returnBookingFor another booking.
			// NOTE: There won't be an associated returnBooking for a one-way trip
			// eslint-disable-next-line no-nested-ternary
			let returnBookingInfo: BookingEntity | null = null;
			if (alterReturn) {
				returnBookingInfo = x.returnBooking ? x.returnBooking : x.returnBookingFor;
			}
			let bookingSummaryInfo = x.bookingSummaries.recentSummary;

			// Determine what the departure booking is and get the associated return trip
			if (alteration && alterReturn) {
				// eslint-disable-next-line no-nested-ternary
				returnBookingInfo = x.returnBooking ? x.returnBooking : (x.returnBookingFor !== null ? x : null);
				bookingSummaryInfo = x.returnBooking
					? x.bookingSummaries.recentSummary
					: (x.returnBookingFor?.bookingSummaries.recentSummary
						?? x.bookingSummaries.recentSummary);
			}

			const editingOneWayReturn = isNullOrUndefined(x.returnBooking)
				&& isNotNullOrUndefined(x.returnBookingFor)
				&& !alterReturn;

			// If the user is passing in a one way trip, we still need to know the associated ferry trip departure date
			// so that we can let the user know when the ticket is before/after the assoicated departure/return ticket
			// E.g. a user should not be able to book a departure ticket
			// that departs after the return ticket and likewise a user should not be able to book a return ticket
			// that has a departure time after the associated departure ticket time.
			const departureTicketDate = (isNotNullOrUndefined(x.returnBooking) || isNullOrUndefined(x.returnBookingFor))
				? x.bookingSummaries.recentSummary.ferryTrip.startDate
				: x.returnBookingFor.bookingSummaries.recentSummary.ferryTrip.startDate;
			const returnTicketDate = (isNullOrUndefined(x.returnBooking) && isNotNullOrUndefined(x.returnBookingFor))
				? x.bookingSummaries.recentSummary.ferryTrip.startDate
				: x.returnBooking?.bookingSummaries.recentSummary.ferryTrip.startDate;

			// This will let the wizard know which date is the associated booking dateTime
			const associatedDepartureDateTime = x.returnBooking
				? x.returnBooking?.bookingSummaries.recentSummary?.ferryTrip?.departureDateTime
				: (x.returnBookingFor?.bookingSummaries.recentSummary?.ferryTrip?.departureDateTime ?? undefined);

			const departureCancellationFee = x.cancellationFee;
			const departureCancellationCutoffHours = x.cancellationCutoffHours;
			const returnCancellationFee = returnBookingInfo?.cancellationFee;

			const bookingToEditId = (isNotNullOrUndefined(x.returnBooking) || isNullOrUndefined(x.returnBookingFor))
			|| editingOneWayReturn
				? x.id
				: x.returnBookingFor.id;

			return {
				wizardData: {
					wizardMode: alteration ? wizardModeOptions.ALTERATION : wizardModeOptions.CREATE,
					departureTrip: alterReturn
						? undefined
						: (isNotNullOrUndefined(x.returnBooking) || isNullOrUndefined(x.returnBookingFor)),
					associatedTripDateTime: (alteration && !alterReturn) ? associatedDepartureDateTime : undefined,
					bookingToEdit: bookingToEditId,
					tripType: returnBookingInfo && alterReturn ? 'return' : 'one way',
					fromLocationId: bookingSummaryInfo?.ferryTrip?.route?.departureId,
					toLocationId: bookingSummaryInfo?.ferryTrip?.route?.destinationId,
					startDate: departureTicketDate,
					ticketSelectionStartDate: departureTicketDate,
					endDate: returnTicketDate ?? departureTicketDate,
					ticketSelectionEndDate: returnTicketDate ?? departureTicketDate,
					adultsCount: bookingSummaryInfo.adultPassengerCount,
					childrenCount: bookingSummaryInfo.childPassengerCount,
					infantCount: bookingSummaryInfo.infantPassengerCount,
					passengerCountD: bookingSummaryInfo.passengerCountD,
					passengerCountE: bookingSummaryInfo.passengerCountE,
					passengerCountF: bookingSummaryInfo.passengerCountF,
					passengerCountG: bookingSummaryInfo.passengerCountG,
					passengerCountH: bookingSummaryInfo.passengerCountH,

					trailerCheckboxSelected: bookingSummaryInfo.towOnInfo !== null,
					tabSelected: bookingSummaryInfo.cargoInfo ? 'vehicle' : 'passenger',
					userId: x.userId,
					driverFirstName: bookingSummaryInfo.driverFirstName ?? '',
					driverLastName: bookingSummaryInfo.driverLastName ?? '',
					driverPhone: bookingSummaryInfo.driverPhone ?? '',
					cargoIdentification: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.cargoIdentification
						: '',
					cargoTypeId: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.cargoTypeId
						: '',
					cargoMake: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.cargoType.cargoMake
						: '',
					cargoModel: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.cargoType.cargoModel
						: '',
					vehicleLengthId: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.selectedLengthId
						: '',
					vehicleWeightId: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.selectedWeightId
						: '',
					hiredVehicle: bookingSummaryInfo.cargoInfo
						? bookingSummaryInfo.cargoInfo.tbc
						: false,
					// eslint-disable-next-line no-nested-ternary
					trailerLengthId: bookingSummaryInfo.towOnInfo
						? bookingSummaryInfo.towOnInfo.selectedLengthId
						: (alteration ? getOldFerryBookingWizardData()?.trailerLengthId : ''),
					trailerTypeId: bookingSummaryInfo.towOnInfo?.towOnTypeId ?? 'NO_TRAILER',
					departureTicketId: bookingSummaryInfo?.ferryTrip?.id,
					returningTicketId: isNotNullOrUndefined(returnBookingInfo) && alterReturn
						? returnBookingInfo.bookingSummaries?.recentSummary?.ferryTrip?.id
						: '',
					promoCode: '',
					departingTripOptions: bookingSummaryInfo.additionalBookingOptions.map(item => {
						return {
							optionId: item.option?.id ?? '',
							amount: item.quantity ?? 0,
							optionPrice: item.option?.staticPrice ?? 0,
							optionName: item.option?.name ?? '',
							gstExempt: item.option?.excludeGST,
						};
					}) as AdditionalOption[] ?? [],
					returningTripOptions: isNotNullOrUndefined(returnBookingInfo) && alterReturn
						? returnBookingInfo.bookingSummaries.recentSummary.additionalBookingOptions.map(item => {
							return {
								optionId: item.option?.id ?? '',
								amount: item.quantity ?? 0,
								optionPrice: item.option?.staticPrice ?? 0,
								optionName: item.option?.name ?? '',
								gstExempt: item.option?.excludeGST,
							};
						}) as AdditionalOption[]
						: [],
					acceptedTsAndCs: false,

					// Fees
					departingCancellationFee: departureCancellationFee,
					returningCancellationFee: returnCancellationFee,
					departingCancellationCutoffHours: departureCancellationCutoffHours,
				} as BookingWizardData,
				selectedTrips: {
					departingTrip: bookingSummaryInfo.ferryTrip,
					returningTrip: alterReturn
						? returnBookingInfo?.bookingSummaries.recentSummary.ferryTrip ?? undefined
						: undefined,
				},
			};
		});
	if (!!currentBooking && !!selectedTrips && !editBooking) {
		listOfBookings.push({
			wizardData: currentBooking,
			selectedTrips: selectedTrips,
		});
	}
	return listOfBookings;
}

// Used to check if any changes were made to a booking in the booking.
// This will be used to stop users from creating a new alteration on a booking
// if there have been no changes made.
export function isSameFerryBookingWizardData(newWizardData: BookingWizardData, oldWizardData: BookingWizardData): boolean {
	// Check differences of add-ons in departure trip
	if (hasAddOnsChanged(newWizardData.departingTripOptions, oldWizardData.departingTripOptions)) {
		return false;
	}

	// Check differences of add-ons in return trip
	if (hasAddOnsChanged(newWizardData.returningTripOptions, oldWizardData.returningTripOptions)) {
		return false;
	}

	// Check passenger changes
	if (hasPassengersChanged(newWizardData, oldWizardData)) {
		return false;
	}

	const changed = newWizardData.departureTicketId === oldWizardData.departureTicketId
		&& newWizardData.returningTicketId === oldWizardData.returningTicketId
		&& newWizardData.trailerLengthId === (oldWizardData.trailerLengthId === ''
			? ''
			: oldWizardData.trailerLengthId)
		&& newWizardData.trailerTypeId === oldWizardData.trailerTypeId
		&& newWizardData.vehicleLengthId === (oldWizardData.vehicleLengthId === ''
			? ''
			: oldWizardData.vehicleLengthId)
		&& newWizardData.note === oldWizardData.note
		&& newWizardData.cargoMake === oldWizardData.cargoMake
		&& newWizardData.cargoModel === oldWizardData.cargoModel
		&& newWizardData.cargoTypeId === oldWizardData.cargoTypeId
		&& newWizardData.cargoIdentification === oldWizardData.cargoIdentification
		&& newWizardData.hiredVehicle === oldWizardData.hiredVehicle
		&& newWizardData.driverFirstName === oldWizardData.driverFirstName
		&& newWizardData.driverLastName === oldWizardData.driverLastName
		&& newWizardData.driverPhone === oldWizardData.driverPhone
		&& newWizardData.hiredVehicle === oldWizardData.hiredVehicle;
	return changed;
}

export function isSameEventBookingWizardData(newWizardData: EventBookingWizardData, oldWizardData: EventBookingWizardData): boolean {
	return newWizardData.firstName === oldWizardData.firstName
		&& newWizardData.lastName === oldWizardData.lastName
		&& newWizardData.phone === oldWizardData.phone
		&& newWizardData.email === oldWizardData.email;
}

export function hasPassengersChanged(newWizardData: BookingWizardData, oldWizardData: BookingWizardData): boolean {
	if (newWizardData.adultsCount !== oldWizardData.adultsCount) {
		return true;
	}
	if (newWizardData.childrenCount !== oldWizardData.childrenCount) {
		return true;
	}
	if (newWizardData.infantCount !== oldWizardData.infantCount) {
		return true;
	}
	if (newWizardData.passengerCountD !== oldWizardData.passengerCountD) {
		return true;
	}
	if (newWizardData.passengerCountE !== oldWizardData.passengerCountE) {
		return true;
	}
	if (newWizardData.passengerCountF !== oldWizardData.passengerCountF) {
		return true;
	}
	if (newWizardData.passengerCountG !== oldWizardData.passengerCountG) {
		return true;
	}
	if (newWizardData.passengerCountH !== oldWizardData.passengerCountH) {
		return true;
	}
	return false;
}

export function hasAddOnsChanged(newAddOns: AdditionalOption[], oldAddOns: AdditionalOption[]): boolean {
	const optionIds = new Set<string>();

	for (const option of newAddOns) {
		optionIds.add(option.optionId);
	}
	for (const option of oldAddOns) {
		optionIds.add(option.optionId);
	}

	if (optionIds.size !== newAddOns.length) {
		return true;
	}

	// Check amount of each option
	for (const optionId of optionIds.values()) {
		const optionFromNew = newAddOns.find(x => x.optionId === optionId);
		const optionFromOld = oldAddOns.find(x => x.optionId === optionId);
		if (optionFromNew?.amount !== optionFromOld?.amount) {
			return true;
		}
	}

	return false;
}
