import { AxiosError } from 'axios';
import { store } from 'Models/Store';
import { QrCodeRedirectLocationState } from 'Models/_HumanWritten/LocationState';
import React from 'react';
import { useParams } from 'react-router';
import { checkInQrCode } from 'Services/Api/_HumanWritten/CheckInService';
import alertToast from 'Util/ToastifyUtils';
import { LottieSpinner } from '../Lottie/LottieSpinner';

interface QrCodeRedirectProps {}

function QrCodeRedirect() {
	const { id } = useParams<{ id: string }>();

	React.useEffect(() => {
		const request = async () => {
			try {
				const response = await checkInQrCode(id);
				const { data } = response;
				const locationState: QrCodeRedirectLocationState = { bookingId: id };
				store.routerHistory.replace(data, locationState);
			} catch (e: any) {
				if (e.isAxiosError && e.response?.data) {
					const error = e as AxiosError<string>;
					alertToast(error.response?.data, 'error');
				} else {
					alertToast('Something went wrong while redirecting', 'error');
				}
				store.routerHistory.replace('/ferry-schedule');
			}
		};
		request();
	}, [id]);

	return (
		<LottieSpinner />
	);
}

export default QrCodeRedirect;
