import { isApolloError } from '@apollo/client';

export function handleError(error: any) {
	if (isApolloError(error)) {
		if (error.graphQLErrors.length > 0) {
			throw error.graphQLErrors.map((x: { message: string }) => x.message);
		} else if (
			error.networkError !== null
			&& 'result' in error.networkError
			&& error.networkError.result.errors
			&& error.networkError.result.errors.length > 0) {
			throw error.networkError.result.errors.map((x: { message: string }) => x.message);
		} else {
			throw error.message;
		}
	} else {
		throw error.message;
	}
}
