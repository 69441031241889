/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsDefaultPricingEntity from 'Models/Security/Acl/VisitorsDefaultPricingEntity';
import UserDefaultPricingEntity from 'Models/Security/Acl/UserDefaultPricingEntity';
import StaffDefaultPricingEntity from 'Models/Security/Acl/StaffDefaultPricingEntity';
import ManagerDefaultPricingEntity from 'Models/Security/Acl/ManagerDefaultPricingEntity';
import AdminDefaultPricingEntity from 'Models/Security/Acl/AdminDefaultPricingEntity';
import InvoicedUserDefaultPricingEntity from 'Models/Security/Acl/InvoicedUserDefaultPricingEntity';
import FixedPriceUserDefaultPricingEntity from 'Models/Security/Acl/FixedPriceUserDefaultPricingEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IDefaultPricingEntityAttributes extends IModelAttributes {
	adultPrice: number;
	childPrice: number;
	infantPrice: number;
	passengerDPrice: number;
	passengerEPrice: number;
	passengerFPrice: number;
	passengerGPrice: number;
	passengerHPrice: number;

	addOnsPrices: Array<
		| Models.AddOnsPriceEntity
		| Models.IAddOnsPriceEntityAttributes
	>;
	prices: Array<
		| Models.PriceEntity
		| Models.IPriceEntityAttributes
	>;
	userDisplayName?: Models.UserDisplayNameEntity |
		Models.IUserDisplayNameEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('DefaultPricingEntity', 'Default Pricing')
// % protected region % [Customise your entity metadata here] end
export default class DefaultPricingEntity extends Model
	implements IDefaultPricingEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsDefaultPricingEntity(),
		new UserDefaultPricingEntity(),
		new StaffDefaultPricingEntity(),
		new ManagerDefaultPricingEntity(),
		new AdminDefaultPricingEntity(),
		new InvoicedUserDefaultPricingEntity(),
		new FixedPriceUserDefaultPricingEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Adult Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Adult Price',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public adultPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Adult Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Child Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Child Price',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public childPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Child Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Infant Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Infant Price',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public infantPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Infant Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger D Price'] on begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Passenger D Price',
		displayType: 'textfield',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerDPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger D Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger E Price'] on begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Passenger E Price',
		displayType: 'textfield',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerEPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger E Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger F Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Passenger F Price',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerFPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger F Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger G Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Passenger G Price',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerGPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger G Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Passenger H Price'] off begin
	@Validators.Numeric()
	@observable
	@attribute<DefaultPricingEntity, number>()
	@CRUD({
		name: 'Passenger H Price',
		displayType: 'textfield',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public passengerHPrice: number;
	// % protected region % [Modify props to the crud options here for attribute 'Passenger H Price'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] off begin
		name: 'Add Ons Prices',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.AddOnsPriceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'addOnsPrices',
			oppositeEntity: () => Models.AddOnsPriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Add Ons Price'] end
	})
	public addOnsPrices: Models.AddOnsPriceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price'] off begin
		name: 'Prices',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.PriceEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'prices',
			oppositeEntity: () => Models.PriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Price'] end
	})
	public prices: Models.PriceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User Display Name'] off begin
		name: 'User Display Name',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.UserDisplayNameEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'userDisplayNames',
			oppositeEntity: () => Models.UserDisplayNameEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'User Display Name'] end
	})
	public userDisplayName?: Models.UserDisplayNameEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IDefaultPricingEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IDefaultPricingEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.adultPrice !== undefined) {
				this.adultPrice = attributes.adultPrice;
			}
			if (attributes.childPrice !== undefined) {
				this.childPrice = attributes.childPrice;
			}
			if (attributes.infantPrice !== undefined) {
				this.infantPrice = attributes.infantPrice;
			}
			if (attributes.passengerDPrice !== undefined) {
				this.passengerDPrice = attributes.passengerDPrice;
			}
			if (attributes.passengerEPrice !== undefined) {
				this.passengerEPrice = attributes.passengerEPrice;
			}
			if (attributes.passengerFPrice !== undefined) {
				this.passengerFPrice = attributes.passengerFPrice;
			}
			if (attributes.passengerGPrice !== undefined) {
				this.passengerGPrice = attributes.passengerGPrice;
			}
			if (attributes.passengerHPrice !== undefined) {
				this.passengerHPrice = attributes.passengerHPrice;
			}
			if (attributes.addOnsPrices !== undefined && Array.isArray(attributes.addOnsPrices)) {
				for (const model of attributes.addOnsPrices) {
					if (model instanceof Models.AddOnsPriceEntity) {
						this.addOnsPrices.push(model);
					} else {
						this.addOnsPrices.push(new Models.AddOnsPriceEntity(model));
					}
				}
			}
			if (attributes.prices !== undefined && Array.isArray(attributes.prices)) {
				for (const model of attributes.prices) {
					if (model instanceof Models.PriceEntity) {
						this.prices.push(model);
					} else {
						this.prices.push(new Models.PriceEntity(model));
					}
				}
			}
			if (attributes.userDisplayName !== undefined) {
				if (attributes.userDisplayName === null) {
					this.userDisplayName = attributes.userDisplayName;
				} else if (attributes.userDisplayName instanceof Models.UserDisplayNameEntity) {
					this.userDisplayName = attributes.userDisplayName;
				} else {
					this.userDisplayName = new Models.UserDisplayNameEntity(attributes.userDisplayName);
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		addOnsPrices {
			${Models.AddOnsPriceEntity.getAttributes().join('\n')}
		}
		prices {
			${Models.PriceEntity.getAttributes().join('\n')}
		}
		userDisplayName {
			${Models.UserDisplayNameEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			addOnsPrices: {},
			prices: {},
			userDisplayName: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'addOnsPrices',
							'prices',
							'userDisplayName',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.userDisplayName?.displayName ?? this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(DefaultPricingEntity.prototype, 'created');
CRUD(modifiedAttr)(DefaultPricingEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
