import { IRouteEntityAttributes, LocationEntity, RouteEntity } from 'Models/Entities';
import React, { useState } from 'react';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import {
	isNotNullOrUndefined,
	isNullOrUndefined,
	StringKeysOfT,
} from 'Util/TypeGuards';
import alertToast from 'Util/ToastifyUtils';
import useAsync from 'Hooks/useAsync';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import _ from 'lodash';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import {
	getFerryScheduleSettingsFromStorage,
	setFerryScheduleSettingsInStorage,
} from 'Services/SessionStorage/LocationsSessionStorage';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Button/Button';

export interface TripDirectionSelectorProps<T> {
	model: Record<
		StringKeysOfT<T, string | number | Date | RouteEntity | boolean>,
		string | number | Date | RouteEntity | IRouteEntityAttributes | boolean
	>;
	fromLocationProperty: StringKeysOfT<T, string>;
	toLocationProperty: StringKeysOfT<T, string>;
	routeProperty: StringKeysOfT<T, RouteEntity | IRouteEntityAttributes>;
	updateModel?: (fromLocation: string, toLocation: string, route: RouteEntity) => void;
	disabled?: boolean;
	locations?: LocationEntity[];
	className?: string;
}

// eslint-disable-next-line prefer-arrow-callback, func-names
export const TripDirectionSelector = observer(function<T> ({
	model,
	fromLocationProperty,
	toLocationProperty,
	routeProperty,
	updateModel,
	disabled,
	locations,
	className,
}: TripDirectionSelectorProps<T>) {
	const [noOfOptions, setNoOfOptions] = useState(0);

	const switchLocations = action(() => {
		const tempFromLocation = model[fromLocationProperty];

		model[fromLocationProperty] = model[toLocationProperty];
		model[toLocationProperty] = tempFromLocation;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		model[routeProperty] = _.flatMap(locationList, location => location.departureRoutes)
			.find(route => route.departureId === model[fromLocationProperty]
				&& route.destinationId === model[toLocationProperty])!;

		setFerryScheduleSettingsInStorage((model[routeProperty] as RouteEntity).id);

		if (updateModel) {
			updateModel(
				model[fromLocationProperty] as string,
				model[toLocationProperty] as string,
				model[routeProperty] as RouteEntity,
			);
		}
	});
	const getComboboxData = async (abortSignal: AbortSignal): Promise<LocationEntity[]> => {
		try {
			const LocationList = locations;
			if (isNotNullOrUndefined(LocationList) && LocationList.length > 0 && model[fromLocationProperty] === '' && !abortSignal.aborted) {
				runInAction(() => {
					let initialRoute: LocationEntity | undefined = LocationList[0];
					if (getFerryScheduleSettingsFromStorage() !== null) {
						const match = LocationList.find(x => x.departureRoutes
							.some(y => y.id === getFerryScheduleSettingsFromStorage()));
						if (isNotNullOrUndefined(match)) {
							initialRoute = match;
						}
					} else if (isNotNullOrUndefined(whiteLabelStore?.defaultRouteId)) {
						const match = LocationList.find(x => x.departureRoutes
							.some(y => y.id === whiteLabelStore.defaultRouteId));
						if (isNotNullOrUndefined(match)) {
							initialRoute = match;
						}
					}
					model[fromLocationProperty] = initialRoute.id;
					model[toLocationProperty] = initialRoute.departureRoutes[0].destinationId;
					// eslint-disable-next-line prefer-destructuring
					model[routeProperty] = initialRoute.departureRoutes[0];
					setNoOfOptions(LocationList.length);
					setFerryScheduleSettingsInStorage(initialRoute.departureRoutes[0].id);

					if (updateModel) {
						updateModel(
							initialRoute.id,
							initialRoute.departureRoutes[0].destinationId,
							initialRoute.departureRoutes[0],
						);
					}
				});
			}
			return LocationList ?? [];
		} catch (e) {
			console.error(e);
			alertToast('Unable to fetch locations', 'error');
			return [];
		}
	};

	const response = useAsync(getComboboxData, [locations]);

	if (response?.type === 'error') {
		return <div>Something went wrong {response?.error}</div>;
	}

	const locationList = response?.data;

	return (
		<div className="trip-direction-selector__container">
			<Combobox
				className="departure-dropdown"
				model={model}
				modelProperty={fromLocationProperty}
				label="From"
				options={isNullOrUndefined(locationList)
					? []
					: locationList?.filter(x => x.departureRoutes.length !== 0).map(x => {
						return {
							display: x.name,
							value: x.id,
						};
					})}
				searchable={false}
				onAfterChange={() => {
					if (isNotNullOrUndefined(locationList)) {
						for (const location of locationList) {
							for (const route of location.destinationRoutes) {
								if (route.departureId === model[fromLocationProperty]) {
									model[toLocationProperty] = location.id;
									model[routeProperty] = route;

									if (updateModel) {
										updateModel(
											route.departureId,
											location.id,
											route,
										);
									}
									setFerryScheduleSettingsInStorage((model[routeProperty] as RouteEntity).id);
								}
							}
						}
					}
				}}
				isDisabled={(noOfOptions === 2) || disabled}
			/>
			<div className="flip-direction-button__container">
				<Button
					className="flip-direction-button"
					display={Display.Text}
					colors={Colors.None}
					sizes={Sizes.ExtraLarge}
					icon={{ icon: 'switch', iconPos: 'icon-right' }}
					onClick={() => switchLocations()}
					disabled={(!(locationList?.find(
						l => l.id === model[toLocationProperty],
					)?.departureRoutes.some(
						x => x.destinationId === model[fromLocationProperty],
					))) || disabled}
				/>
			</div>
			<Combobox
				className="destination-dropdown"
				model={model}
				modelProperty={toLocationProperty}
				label="To"
				options={isNullOrUndefined(locationList)
					? []
					: locationList
						?.filter(x => x.destinationRoutes && x.destinationRoutes
							.some(route => route.departureId === model[fromLocationProperty]))
						.map(x => {
							return {
								display: x.name,
								value: x.id,
							};
						})}
				searchable={false}
				isDisabled={((locationList
					?.filter(x => x.destinationRoutes && x.destinationRoutes
						.some(route => route.departureId === model[fromLocationProperty]))
					.length) === 1) || disabled}
				onAfterChange={() => {
					// If there is no route that matches this condition then there is a bigger problem
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					model[routeProperty] = _.flatMap(locationList, location => location.departureRoutes)
						.find(route => route.departureId === model[fromLocationProperty]
							&& route.destinationId === model[toLocationProperty])!;

					if (updateModel) {
						updateModel(
							model[fromLocationProperty] as string,
							model[toLocationProperty] as string,
							model[routeProperty] as RouteEntity,
						);
					}
					setFerryScheduleSettingsInStorage((model[routeProperty] as RouteEntity).id);
				}}
			/>
		</div>
	);
});
