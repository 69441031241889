import * as React from 'react';
import Modal from 'Views/Components/Modal/Modal';
import { Button } from 'Views/Components/Button/Button';
import * as uuid from 'uuid';
import { FerryTripEntity } from 'Models/Entities';
import If from 'Views/Components/If/If';

export interface OverbookingAllowanceModalProps {
	showModal: boolean;
	onClickCross: () => void;
	onClickSelect: () => void;
	bulkFerryTrips: FerryTripEntity[] | null;
}

export function OverbookingAllowanceModal({
	showModal,
	onClickSelect,
	onClickCross,
	bulkFerryTrips,
}: OverbookingAllowanceModalProps) {
	if (!showModal) {
		return <></>;
	}
	return (
		<Modal
			isOpen
			label="Overbooking allowance"
		>
			<>
				<div className="overbooking-allowance-modal__wrap">
					<div className="overbooking-allowance-modal__header">
						<h5 className="overbooking-allowance-modal__title">
							Overbooking allowance
						</h5>
						<Button
							className="overbooking-allowance-modal__cross-btn"
							icon={{ icon: 'cross', iconPos: 'icon-top' }}
							onClick={onClickCross}
						/>
					</div>
					<If condition={bulkFerryTrips === null}>
						<div className="overbooking-allowance-modal--body">
							<p className="overbooking-allowance-modal__body-text">
								{/* eslint-disable-next-line max-len */}
								Just to let you know, there are no spaces left on this ferry so you will be using the overbooking allowance.
							</p>
						</div>
					</If>
					<If condition={bulkFerryTrips !== null}>
						<div className="space-modal--body">
							<p className="space-modal__body-text" key={uuid.v4()}>
								{/* eslint-disable-next-line max-len */}
								You will need to use the overbooking allowance to complete the bookings on the following trips:
							</p>
							<If condition={bulkFerryTrips !== null}>
								<div className="space-modal__bulk-booking-trips">
									{bulkFerryTrips?.map(x => {
										return (
											<p>
												{x.getTripTimeFormatted}, {x?.getFormattedTripDateLong()}
											</p>
										);
									})}
								</div>
							</If>
						</div>
					</If>
				</div>
				<Button className="space-modal__button" onClick={onClickSelect}>
					<div className="space-modal__button-contents">
						<p>OK</p>
						<span className="btn--icon icon-arrow-right icon-right" />
					</div>
				</Button>
			</>
		</Modal>
	);
}
