import { buildUrl } from 'Util/FetchUtils';
import { SERVER_URL } from 'Constants';
import { serialiseDateTime } from 'Util/AttributeUtils';
import moment from 'moment';
import axios from 'axios';
import { AdditionalBookingOptionEntity } from 'Models/Entities';
import useAsync from 'Hooks/useAsync';
import { formatAsISO } from 'Util/_HumanWritten/DateUtils';

export function downloadGiftCertificateCsv(
	start: Date,
	end: Date,
	created: boolean,
) {
	const url = `${SERVER_URL}/api/reports/export-certificate-report?startDate=${
		encodeURIComponent(serialiseDateTime(start))
	}&endDate=${
		encodeURIComponent(serialiseDateTime(moment(end)
			.add(23, 'hours')
			.add(59, 'minutes')
			.add(59, 'seconds')))
	}&fetchByCreated=${created}`;

	window.open(url, '_blank')?.focus();
}

export function downloadRevenueReportCsv(start: Date, end: Date) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);
	const url = buildUrl(`${SERVER_URL}/api/reports/export-revenue-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadTakingsBreakdownReportCsv(start: Date, end: Date) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);
	const url = buildUrl(`${SERVER_URL}/api/reports/export-takings-breakdown-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadInvoiceReport(start: Date, end: Date, fetchByCreated: boolean) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-invoice-report`, {
		startDate,
		endDate,
		fetchByCreated: String(fetchByCreated),
	});

	window.open(url, '_blank')?.focus();
}

export function downloadCustomerReport(start: Date, end: Date) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
			0,
			0,
			0,
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
			23,
			59,
			59,
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-customer-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadCashReport(date: Date, startTime: Date, endTime: Date) {
	const startDate = formatAsISO(
		new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			startTime.getHours(),
			startTime.getMinutes(),
			0,
		),
	);
	const endDate = formatAsISO(
		new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			endTime.getHours(),
			endTime.getMinutes(),
			0,
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-cash-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadEftposReport(date: Date, startTime: Date, endTime: Date) {
	const startDate = formatAsISO(
		new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			startTime.getHours(),
			startTime.getMinutes(),
			0,
		),
	);
	const endDate = formatAsISO(
		new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			endTime.getHours(),
			endTime.getMinutes(),
			0,
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-eftpos-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadOccupancyReport(start: Date, end: Date) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-occupancy-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}

export function downloadOperationReport(start: Date, end: Date) {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-operation-report`, {
		startDate,
		endDate,
	});

	window.open(url, '_blank')?.focus();
}
export async function downloadAdditionalOptionReport(
	start: Date,
	end: Date,
	fetchByCreated: boolean,
	additionalOptionId: string,
): Promise<void> {
	const startDate = formatAsISO(
		new Date(
			start.getFullYear(),
			start.getMonth(),
			start.getDate(),
		),
	);
	const endDate = formatAsISO(
		new Date(
			end.getFullYear(),
			end.getMonth(),
			end.getDate(),
		),
	);

	const url = buildUrl(`${SERVER_URL}/api/reports/export-additional-option`, {
		startDate,
		endDate,
		fetchByCreated: String(fetchByCreated),
		additionalOptionId,
	});

	window.open(url, '_blank')?.focus();
}

export async function getReportableAdditionalBookingOptions(): Promise<AdditionalBookingOptionEntity[]> {
	const response = await axios.get(
		`${SERVER_URL}/api/reports/get-reportable-additional-options`,
		{
			validateStatus: () => true,
		},
	);

	return response.data;
}

export function useReportableAddOns() {
	return useAsync(() => getReportableAdditionalBookingOptions(), []);
}
