import React from 'react';
import { Button } from 'Views/Components/Button/Button';
import If from 'Views/Components/If/If';
import { isNotNullOrUndefined } from 'Util/TypeGuards';

export interface CartCalculationLineItemProps {
	lineItemDisplayName: string,
	lineItemPrice: string,
	className?: string,
	hasRemovalButton?: boolean,
	removalOnClick?: () => void,
	keyToUse?: string,
}

export function CartCalculationLineItem({
	lineItemDisplayName,
	lineItemPrice,
	className,
	hasRemovalButton = false,
	removalOnClick,
	keyToUse,
}: CartCalculationLineItemProps) {
	return (
		<div className={`cart-line-item__wrap ${className}`} key={`${keyToUse}-key`}>
			<div className="line-item-display-name__wrap">
				<If condition={hasRemovalButton && isNotNullOrUndefined(removalOnClick)}>
					<Button
						className="clear-line-item__btn"
						icon={{ icon: 'cross', iconPos: 'icon-top' }}
						onClick={removalOnClick}
					/>
				</If>
				<p className="line-item-display-name">{lineItemDisplayName}</p>
			</div>
			<p className="line-item-price">{lineItemPrice}</p>
		</div>
	);
}
