import React from 'react';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import classNames from 'classnames';

interface EventBookingWizardStepContentProps {
	contentClass?: string;
	children?: React.ReactNode;
}

export const EventBookingWizardStepContent = ({
	children,
	contentClass = 'event-booking-wizard-step-content',
}: EventBookingWizardStepContentProps) => {
	const className = [contentClass];

	if (getFerryBookingTransactionIdFromStorage() !== null) {
		className.push('event-booking-wizard__timer-active');
	}

	return (
		<div className={classNames(className)}>
			{children}
		</div>
	);
};
