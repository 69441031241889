import * as React from 'react';
import { observer } from 'mobx-react';
import { bookingType } from 'Models/Enums';
import { addToWaitList } from 'Services/Api/_HumanWritten/WaitlistService';
import { action, observable } from 'mobx';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { modalWithCustomInput } from 'Views/Components/Modal/CustomModal';
import alertToast from 'Util/ToastifyUtils';
import { RadioButtonGroup } from 'Views/Components/RadioButton/RadioButtonGroup';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { isEmail } from 'Validators/Functions/Email';
import {
	BookingWizardData,
	clearOldBookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface AddToWaitListModalProps {
	waitlistModel: {
		waitlistBookingType: bookingType,
		email: string,
		userId?: string,
		emailError: string,
	};
	hasInitialCargo: boolean;
}

export const ModalContent = observer(({
	waitlistModel,
	hasInitialCargo,
}: AddToWaitListModalProps) => {
	const waitlistOptions = [];
	if (hasInitialCargo) {
		waitlistOptions.push({ value: 'VEHICLE', display: whiteLabelStore.config.vehicleTripSelectionButtonLabel });
	}
	waitlistOptions
		.push({ value: 'PASSENGER_ONLY', display: whiteLabelStore.config.passengerTripSelectionButtonLabel });

	if (hasInitialCargo && whiteLabelStore.config.trailersEnabled) {
		waitlistOptions.push({ value: 'TRAILER', display: 'Trailer' });
	}
	return (
		<div className="add-to-waitlist-modal-content">
			{/* eslint-disable-next-line max-len */}
			<p className="modal-description">Everyone on this waitlist will receive an email when a space becomes available. First in, best dressed 😊.</p>
			<TextField
				id="cancellation-refund-textfield"
				className="email-textfield"
				label="Email"
				model={waitlistModel}
				modelProperty="email"
				placeholder="Enter email"
				onAfterChange={() => {
					if (waitlistModel.emailError !== '') {
						waitlistModel.emailError = '';
					}
				}}
				errors={waitlistModel.emailError}
			/>
			<RadioButtonGroup
				className="booking-type__radio-button"
				model={waitlistModel}
				modelProperty="waitlistBookingType"
				label="Type of notification"
				options={waitlistOptions}
			/>
		</div>
	);
});

export const RenderAddToWaitListModal = async (
	ferryTripId: string,
	hasInitialCargo: boolean,
	wizardData?: BookingWizardData,
	loggedInUserId?: string,
	loggedInUserEmail?: string,
	refresh?: () => void,
	isStaff: boolean = false,
) => {
	const waitlistModel = observable({
		waitlistBookingType: (wizardData?.tabSelected === 'vehicle' || isStaff)
			? 'VEHICLE'
			: 'PASSENGER_ONLY' as bookingType,
		email: !isStaff ? (loggedInUserEmail ?? '') : '',
		userId: !isStaff ? loggedInUserId : '',
		emailError: '',
	});

	const ObservableModalContent = observer(() => {
		return (
			<ModalContent
				waitlistModel={waitlistModel}
				hasInitialCargo={hasInitialCargo}
			/>
		);
	});

	const confirmed = await modalWithCustomInput(
		'Add to waitlist',
		<ObservableModalContent />,
		{
			cancelText: 'Cancel',
			confirmText: 'Confirm',
			actionClassName: 'add-to-waitlist-modal',
			resolveOnCancel: true,
			onConfirm: action(() => {
				if (waitlistModel.email === '') {
					waitlistModel.emailError = 'Email address is required';
					return false;
				}
				if (!isEmail(waitlistModel.email.trim())) {
					waitlistModel.emailError = 'Please enter a valid email address';
					return false;
				}

				waitlistModel.emailError = '';
				return true;
			}),
		},
	);

	if (!confirmed) {
		return;
	}

	const response = await addToWaitList({
		ferryTripId: ferryTripId,
		userId: loggedInUserId,
		bookingType: waitlistModel.waitlistBookingType as bookingType,
		email: waitlistModel.email.trim(),
	});
	switch (response.status) {
		// Status will be 200 if the booking was successfully cancelled
		case 200:
			// eslint-disable-next-line no-case-declarations
			clearOldBookingWizardData();
			if (refresh) {
				refresh();
				alertToast('Added to waitlist', 'success');
			} else {
				alertToast('Refresh the page', 'warning');
			}
			return;
		// Bad request... either user is not authorized or the cancellation failed
		case 400:
			return alertToast('Unable to add to waitlist', 'error');
	}
};
