import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { UserEntity } from 'Models/Entities';
import { EntityFormMode } from 'Views/Components/Helpers/Common';

interface AdditionalOptionPriceInputsProps {
	invoicedUser: UserEntity;
	mode: EntityFormMode;
	isReadOnly?: boolean;
}

function AdditionalOptionPriceInputs({
	mode,
	invoicedUser,
	isReadOnly = false,
}: AdditionalOptionPriceInputsProps) {
	return (
		<>
			{invoicedUser.addOnsPrices.map(x => {
				const id = `add-on-${x.additionalBookingOption.name}`;
				return (
					<TextField
						key={id}
						id={id}
						model={x}
						modelProperty="price"
						label={x.additionalBookingOption.name}
						isRequired
						placeholder={`Enter ${x.additionalBookingOption.name} price`}
						isReadOnly={mode === EntityFormMode.VIEW || isReadOnly}
						inputProps={{ type: 'number' }}
					/>
				);
			})}
		</>
	);
}

export default observer(AdditionalOptionPriceInputs);
