import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useAsync from 'Hooks/useAsync';
import {
	Button,
	Colors,
	Display,
} from '../Components/Button/Button';
import NavigationWrapper from '../Components/Navigation/NavigationWrapper';
import queryString from 'querystring';
import { removeFromWaitlist } from '../../Services/Api/_HumanWritten/WaitlistService';
import { LottieSpinner } from '../Components/_HumanWritten/Lottie/LottieSpinner';
import { LottieWaitlistRemoved } from '../Components/_HumanWritten/Lottie/LottieWaitlistRemoved';
import { store } from '../../Models/Store';
import moment from 'moment/moment';
import { REMOVE_FROM_WAITLIST_PAGE_TITLE } from '../../ConstantPageNames';

export default function RemoveFromWaitlistPage(props: RouteComponentProps) {
	return (
		<RemoveFromWaitlist routerProps={props} />
	);
}

export interface RemoveFromWaitlistProps {
	routerProps: RouteComponentProps;
}

export function RemoveFromWaitlist({ routerProps }: RemoveFromWaitlistProps) {
	document.title = REMOVE_FROM_WAITLIST_PAGE_TITLE;

	// Parse the query params from the page route
	const queryParams = routerProps.location.search.substring(1);
	const params = queryString.parse(queryParams);
	const waitlistId = params.waitlistItemId?.toString() ?? '';

	const response = useAsync(async () => {
		const result = await removeFromWaitlist(waitlistId);
		return result.data;
	}, []);

	if (response.type === 'loading') {
		return <LottieSpinner />;
	}

	if (response.type === 'error') {
		return <div>Something went wrong!?!?</div>;
	}

	return (
		<div className="body-content remove-from-waitlist-page">
			<NavigationWrapper className="booking-success__nav" {...routerProps} />
			<div className="waitlist-removed-content">
				<LottieWaitlistRemoved />
				<div className="waitlist-removed-text-and-button">
					<h3>Removed from waitlist</h3>
					<p>You have been removed from the waitlist for the ferry trip from
						<strong> {response.data.departureName} to {response.data.destinationName}</strong> on
						<strong> {moment(response.data.departureDateTime).format('dddd MMM Do, h:mm A')}.</strong>
					</p>
					<Button
						className="go-to-ferry-schedule-link"
						onClick={() => store.routerHistory.push('/ferry-schedule')}
						colors={Colors.Primary}
						display={Display.Solid}
					>
						Go to ferry schedule
					</Button>
				</div>
			</div>
		</div>
	);
}
