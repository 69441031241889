import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { getEventBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import classNames from 'classnames';
import If from 'Views/Components/If/If';
import { SidebarStatus, VisibilityStatus } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import { EventAuthStep } from 'Views/Components/_HumanWritten/EventsBookingWizard/WizardSteps/EventAuthStep';
import {
	EventBookingWizardData,
	getEventBookingWizardData, saveOldEventBookingWizardData,
} from './EventsBookingWizardData';
import {
	EventBookingWizardBottomBar,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/BottomBar/EventBookingWizardBottomBar';
import {
	EventBookingWizardStepContent,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/Components/EventBookingWizardStepContent';
import {
	EventBookingWizardProgressBar,
} from 'Views/Components/_HumanWritten/EventsBookingWizard/ProgressBar/EventBookingWizardProgressBar';

export interface EventBookingWizardProps {
	wizardData: EventBookingWizardData;
	onUpdateWizardData: (eventWizardData: EventBookingWizardData) => void;
	tabs: EventBookingWizardTab[];
	baseUrl: string;
	isIpad: boolean;
	isMobile: boolean
	disableBackAndContinueButtons: boolean;
	disableContinueButton: boolean;
	tabIsLoading: boolean;
	setDisableContinueButton: (disableContinueButton: boolean) => void;
}

export interface EventBookingWizardTab {
	name: string;
	displayName: string;
	path: string;
	visibility: VisibilityStatus; // If true, the step will be skipped
	validateStep: (updateErrorState: boolean) => boolean;
	sidebarStatus: SidebarStatus;
	component: ReactNode;
	authorize: boolean;
	className?: string;
	contentClass?: string;
	showTAndCsButton: boolean;
	hideBottomBar?: boolean;
	continueBtnText?: string;
	continueBtnLoadingText?: string;
	continueBtnProps?: React.ButtonHTMLAttributes<Element>;
	tabTitle: string;
	tabIsLoading?: boolean;
	disableContinueButton?: boolean;
}

export function EventBookingWizard({
	wizardData,
	onUpdateWizardData,
	tabs,
	baseUrl,
	isIpad,
	isMobile,
	disableBackAndContinueButtons,
	disableContinueButton,
	tabIsLoading,
	setDisableContinueButton,
}: EventBookingWizardProps) {
	const history = useHistory();
	const pathObjects = window.location.pathname.split('/');
	let currentTabIndex: number = -1;

	const setDocTitle = (tabToRender: EventBookingWizardTab) => {
		if (tabToRender.tabTitle !== undefined) {
			document.title = tabToRender.tabTitle;
		} else {
			document.title = 'Book event';
		}
	};
	const progressBarClass = 'event-booking-wizard-progress-bar';

	if (pathObjects.length === 2) {
		const firstTab = tabs.find(tab => (tab.visibility !== VisibilityStatus.EXCLUDED));
		history.push(!!firstTab ? `${baseUrl}/${firstTab.path}` : '/404');
		return <></>;
	}

	if (pathObjects.length === 3) {
		currentTabIndex = tabs.findIndex(
			tab => tab.path.toLowerCase() === pathObjects[2].toLowerCase(),
		);

		// This snippet is for if we land on an excluded page. upon landing on an excluded page we should navigate
		// forward to the next visible or hidden page. The reason for this logic is that if we are on a visible/hidden
		// page which changes to excluded, without this snippet we would be navigated to the 404 page immediately
		if (tabs[currentTabIndex].visibility === VisibilityStatus.EXCLUDED) {
			for (let i = currentTabIndex + 1; i < tabs.length; i++) {
				if (tabs[i].visibility !== VisibilityStatus.EXCLUDED) {
					history.push(`${baseUrl}/${tabs[i].path}`);
					return <></>;
				}
			}

			// At this point, we will have not found a valid step to navigate to when the tabs visibility is excluded.
			// If that is the case, then we will let the user be navigated to 404
			currentTabIndex = -1;
		}

		// This snippet will ensure that if the user lands on a page in the wizard and they haven't completed the
		// previous step, then they will be returned to the earliest step that they haven't completed
		for (let i = 0; i < currentTabIndex; i++) {
			if (tabs[i].visibility !== VisibilityStatus.EXCLUDED && !tabs[i].validateStep(false)) {
				history.push(`${baseUrl}/${tabs[i].path}`);
				return <></>;
			}
		}
	}

	// We don't need to do any other checks here, because it the path objects length isn't 3, then tab index won't be
	// reassigned from its initial value of -1
	if (currentTabIndex === -1) {
		history.push('/404');
		return <></>;
	}

	const tabToRender = tabs[currentTabIndex];

	setDocTitle(tabToRender);

	const timerShown = getEventBookingTransactionIdFromStorage() !== null;
	const containerClass = timerShown && isIpad
		? 'event-booking-wizard-step-content-container event-booking-wizard-mobile-timer-padding'
		: 'event-booking-wizard-step-content-container';
	const contentClass = [];

	if (!!tabToRender.contentClass) {
		contentClass.push(tabToRender.contentClass);
	}

	const onTimerExpiry = () => {
		const eventId = getEventBookingWizardData()?.eventId;
		if (isNotNullOrUndefined(eventId)) {
			history.push(`/event-details/${eventId}`);
		} else {
			history.push('/upcoming-events');
		}
	};

	const bookingWizardStep = (
		<div className="event-booking-wizard-step-container">
			<div className="event-booking-wizard-step-main-container-horizontal">
				<div className="event-booking-wizard-step-main-container-vertical">
					<EventBookingWizardProgressBar
						tabs={tabs}
						progressBarClass={progressBarClass}
						onClickTab={tab => {
							history.push(`${baseUrl}/${tab.path}`);
						}}
						currentTabIndex={currentTabIndex}
						isIpad={isIpad}
						onTimerExpiry={onTimerExpiry}
						ticketsPageUrl={`${baseUrl}/${tabs[1].path}`}
						forEventBooking
						isMobile={isMobile}
						disabled={disableBackAndContinueButtons || tabToRender.path === 'post-payment'}
					/>
					<div className={containerClass}>
						<div className={classNames('event-booking-wizard-step-content-background event-booking-wizard', tabToRender.className)}>
							<EventBookingWizardStepContent
								contentClass={contentClass.length > 0 ? classNames(contentClass) : undefined}
							>
								{tabs[currentTabIndex].component}
							</EventBookingWizardStepContent>
						</div>
						<If condition={!tabToRender.hideBottomBar}>
							<EventBookingWizardBottomBar
								disableContinueButton={disableContinueButton}
								tabIsLoading={tabIsLoading}
								continueBtnProps={tabToRender.continueBtnProps}
								continueBtnText={tabToRender.continueBtnText}
								continueBtnLoadingText={tabToRender.continueBtnLoadingText}
								onTimerExpiry={onTimerExpiry}
								wizardData={wizardData}
								onUpdateWizardData={onUpdateWizardData}
								onClickContinue={() => {
									if (tabToRender.path === 'payment' || disableBackAndContinueButtons) {
										// PaymentTab will handle everything.
										return;
									}

									// Find will get the first array element which fits the criteria, so we
									// can slice out our non completed steps and find the first one of those
									// which isn't excluded and navigate to that
									const nextStep = tabs
										.slice(currentTabIndex + 1)
										.find(tab => tab.visibility !== VisibilityStatus.EXCLUDED);
									if (tabToRender.validateStep(true)) {
										// If there is no next step then that means that the wizard has been
										// completed
										if (nextStep) {
											history.push(!!nextStep ? `${baseUrl}/${nextStep.path}` : '/404');
										}
									} else {
										ScrollToError();
									}
								}}
								onClickBack={() => {
									if (disableBackAndContinueButtons) {
										return;
									}

									setDisableContinueButton(false);

									let previousStep = null;
									if (tabs[currentTabIndex].name === 'Cart') {
										saveOldEventBookingWizardData(wizardData);
									}
									// For the going backwards, we can't use the same find method because we need the
									// last index instead of the first. We could try to slice out the relevant entries
									// and invert it, but I think this works better because it also allows us to more
									// easily handle what happens when the user presses back from the start of the
									// wizard
									for (let i = currentTabIndex - 1; i >= 0; i--) {
										if (tabs[i].visibility === VisibilityStatus.VISIBLE) {
											previousStep = tabs[i];
											break;
										}
									}
									// If previous step is null here, then the user has gone back from the start of the
									// wizard. If that is the case, then we don't allow the user to navigate
									if (previousStep !== null) {
										history.push(`${baseUrl}/${previousStep.path}`);
									}
								}}
								tabs={tabs}
								currentTabIndex={currentTabIndex}
								isIpad={isIpad}
								ticketsPageUrl={`${baseUrl}/${tabs[0].path}`}
							/>
						</If>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<If condition={tabToRender.authorize}>
				<EventAuthStep wizardData={wizardData} onUpdateWizardData={onUpdateWizardData}>
					{bookingWizardStep}
				</EventAuthStep>
			</If>
			<If condition={!tabToRender.authorize}>
				{bookingWizardStep}
			</If>
		</>
	);
}
