import React from 'react';

import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import useCheckInStore from 'Hooks/useCheckInStore';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { IssueIcon } from 'Views/Components/_HumanWritten/Icon/IssueIcon';
import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { useScanner } from '../../Stores/useScanner';
import { ScannerResultData } from '../../Types/scannerResultState';
import Details from './Details';

type ConfirmationProps = Pick<
	ScannerResultData,
	| 'id'
	| 'driver'
	| 'cargoId'
	| 'firstTimer'
	| 'tag'
	| 'passengers'
	| 'cargoLengthLabel'
	| 'cargoLengthValue'
	| 'towOnLengthLabel'
	| 'towOnLengthValue'
	| 'addOns'
>;

export default function Confirmation({
	id: bookingId,
	driver,
	cargoId,
	firstTimer,
	tag,
	passengers,
	cargoLengthLabel,
	cargoLengthValue,
	towOnLengthLabel,
	towOnLengthValue,
	addOns,
}: ConfirmationProps) {
	const checkInStore = useCheckInStore();
	const { goToEdit } = useCheckInRoutes();
	const { goToScanner } = useScannerLocation();

	const handleClicks = {
		onClose() {
			goToScanner();
		},
		onEdit() {
			goToEdit(bookingId);
		},
		async onCheckIn() {
			try {
				await checkInStore.checkInBooking(bookingId, true);
				console.log(useScanner.getState().bookings[0]);
				goToScanner();
			} catch (err) {
				console.log(err);
			}
		},
	};

	return (
		<div className="scanner-result__page">
			<button className="check-in-scanner__close-btn check-in-scanner__close-btn--black" title="Back to scanner" onClick={handleClicks.onClose}>
				<Icon name="cross" classname="check-in-scanner__cross" />
			</button>
			<div className="scanner-result__body">
				<h2 className="scanner-result__title">Confirm booking?</h2>
				<Details
					driver={driver}
					cargoId={cargoId}
					firstTimer={firstTimer}
					tag={tag}
					passengers={passengers}
					cargoLengthLabel={cargoLengthLabel}
					cargoLengthValue={cargoLengthValue}
					towOnLengthLabel={towOnLengthLabel}
					towOnLengthValue={towOnLengthValue}
					addOns={addOns}
				/>
			</div>
			<div className="scanner-result__footer">
				<Button
					className="edit-btn"
					onClick={handleClicks.onEdit}
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
				>
					Edit
				</Button>
				<Button
					className="check-in-btn"
					onClick={handleClicks.onCheckIn}
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
				>
					<IssueIcon /> Check in
				</Button>
			</div>
		</div>
	);
}
