import { AlterationEntity, BookingEntity } from '../../Models/Entities';
import { isNotNullOrUndefined, stringNotEmpty } from '../TypeGuards';
import { filter } from 'lodash';

/**
 * This function will sort alterations on a booking by date.
 * from the booking wizard data that is passed into the function.
 * @param alterations: The list of alterations to sort.
 * @param descending: Whether to sort by descending or not.
 */
export const sortAlterationsByDateCreated = (alterations: AlterationEntity[], descending: boolean = false) => {
	// Most recent first
	if (!descending) {
		return alterations
			.slice()
			.sort((date1, date2) => (date1.created < date2.created ? 1 : -1));
	}
	// Oldest first
	return alterations
		.slice()
		.sort((date1, date2) => (date1.created < date2.created ? -1 : 1));
};

/**
 * This function will filter alterations on a booking to only return RESERVED and BOOKED.
 * from the booking wizard data that is passed into the function.
 * @param alterations: The list of alterations to sort.
 */
export const filterReservedAndBookedAlterations = (alterations: AlterationEntity[]) => {
	const res = alterations.filter(x => x.status === 'BOOKED' || x.status === 'RESERVED');
	return res;
};

/**
 * This function will filter alterations on a booking to only return RESERVED
 * from the alterations that are passed into the function.
 * @param alterations: The list of alterations to sort.
 */
export const filterReservedAlterations = (alterations: AlterationEntity[]) => {
	return alterations.filter(x => x.status === 'RESERVED');
};

/**
 * This function will filter alterations on a booking to only return BOOKED.
 * @param alterations: The list of alterations to sort.
 */
export const filterBookedAlterationsOnly = (alterations: AlterationEntity[]) => {
	return alterations.filter(x => x.status === 'BOOKED');
};

/**
 * This function will find the most recent BOOKED alteration from a list of alterations.
 * @param alterations: The list of alterations to find the most recent alteration from.
 */
export const getMostRecentBookedAlteration = (alterations: AlterationEntity[], idToExclude?: string) => {
	if (stringNotEmpty(idToExclude)) {
		return filterBookedAlterationsOnly(sortAlterationsByDateCreated(alterations.filter(x => x.id !== idToExclude)))[0];
	}
	const sortedAlterations = sortAlterationsByDateCreated(alterations);
	return filterBookedAlterationsOnly(sortedAlterations)[0];
};

/**
 * This function will find the most recent BOOKED or RESERVED alteration from a list of alterations.
 * @param alterations: The list of alterations to find the most recent alteration from.
 */
export const getMostRecentBookedOrReservedAlteration = (alterations: AlterationEntity[]) => {
	const sortedAlterations = sortAlterationsByDateCreated(alterations);
	return filterReservedAndBookedAlterations(sortedAlterations)[0];
};

/**
 * This function will find the most recent BOOKED or RESERVED alteration from a list of alterations.
 * @param alterations: The list of alterations to find the most recent alteration from.
 * @param idToExclude: To find the next most recent alteration
 */
export const getMostRecentAlteration = (alterations: AlterationEntity[], idToExclude?: string) => {
	const sortedAlterations = sortAlterationsByDateCreated(alterations);
	const results = filterReservedAndBookedAlterations(sortedAlterations);

	if (stringNotEmpty(idToExclude)) {
		return results.filter(x => x.id !== idToExclude)[0];
	}
	return results[0];
};

export const getFerryTripChangeIndex = (alterations: AlterationEntity[]) => {
	const sortedAlterations = sortAlterationsByDateCreated(alterations, true);
	const results = filterReservedAndBookedAlterations(sortedAlterations);

	const mostRecentAlt = results[0];
	const res = results.findIndex(x => x.id !== mostRecentAlt.id && x.ferryTripId !== mostRecentAlt.ferryTripId);
	return res;
};

/**
 * This function will filter alterations on a booking to only return BOOKED.
 * @param alterations: The list of alterations to find the most recent alteration from.
 */
export const getMostRecentBookedAlterationWithFerryTrip = (alterations: AlterationEntity[], idToExclude?: string) => {
	const sortedAlterations = sortAlterationsByDateCreated(alterations);
	const bookedAlterations = filterBookedAlterationsOnly(sortedAlterations);

	let altToReturn = 0;

	if (stringNotEmpty(idToExclude)) {
		altToReturn = bookedAlterations.filter(x => x.id !== idToExclude).findIndex(x => x.ferryTripId !== null);
	} else {
		altToReturn = bookedAlterations.findIndex(x => x.ferryTripId !== null);
	}

	return bookedAlterations[altToReturn];
};

/**
 * This function will find the most recent alteration fee from a list of alterations.
 * @param alterations: The list of alterations to find the alteration fee from.
 */
export const getMostRecentAlterationFees = (alterations: AlterationEntity[]) => {
	const sortedAlterations = sortAlterationsByDateCreated(alterations);
	return filterReservedAndBookedAlterations(sortedAlterations)[0]?.fees
		.find(x => x.feeType === 'ALTERATION')?.amount ?? 0;
};

/**
 * This function will find the last index from a callback function.
 * @param items: The items to find the last index from.
 * @param callback: the function that will determine what index we are looking for.
 * Use case: Finding the last index of an alteration that has a ferry trip Id associated to it.
 */
export function findIndexLast<T>(items: T[], callback: (item: T) => boolean) {
	for (let i = items.length - 1; i >= 0; i--) {
		if (callback(items[i])) {
			return i;
		}
	}
	return -1;
}
