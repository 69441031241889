import {
	AdditionalBookingOptionEntity,
	AdditionalBookingSectionEntity,
	IAdditionalBookingSectionEntityAttributes,
} from '../../../Models/Entities';
import axios from 'axios';
import { ADDITIONAL_OPTION_ENTITY_URL, FERRY_BOOKING_URL } from '../../../Constants';
import { IAdditionalBookingOptionEntityAttributes } from 'Models/Entities/AdditionalBookingOptionEntity';
import { buildUrl } from '../../../Util/FetchUtils';

export async function getAllAdditionalBookingOptions(): Promise<AdditionalBookingOptionEntity[]> {
	const response = await axios.get(
		`${ADDITIONAL_OPTION_ENTITY_URL}/get-all-additional-options`,
	);

	return response.data
		.map((x: Partial<IAdditionalBookingOptionEntityAttributes>) => new AdditionalBookingOptionEntity(x));
}

export async function getAllAdditionalOptionSections(userId: string | null): Promise<AdditionalBookingSectionEntity[]> {
	const response = await axios.get(
		buildUrl(`${ADDITIONAL_OPTION_ENTITY_URL}/get-all-additional-option-sections`, { userId: userId ?? 'null' }),
	);

	return response.data
		.map((x: Partial<IAdditionalBookingSectionEntityAttributes>) => new AdditionalBookingSectionEntity(x));
}

export async function addNewAdditionalBookingOptionEntity(
	model: AdditionalBookingOptionEntity,
) {
	return axios.post(
		`${ADDITIONAL_OPTION_ENTITY_URL}/add-new-additional-booking-options`,
		model.toJSON(),
	);
}
