import * as React from 'react';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';

export function invoicedConfirmModal(setToInvoiced: boolean) {
	return confirmModal(
		setToInvoiced ? 'Set booking to invoiced?' : 'Set booking back to awaiting?',
		<div>
			<h5>{`This will update the status of the booking and mark it as ${setToInvoiced ? '\'Invoiced\'' : '\'Awaiting\''}.`}</h5>
		</div>,
		{
			confirmText: 'Confirm',
			cancelText: 'Close',
		},
	);
}
