import * as React from 'react';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { RouteComponentProps } from 'react-router-dom';
import { SecuredGroups } from 'Models/Security/UserGroups';
import {
	InvoicedUserCRUD,
	InvoicedUserCRUDProps,
} from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserCRUD';

export default function InvoicedUserCRUDPage(props: RouteComponentProps<InvoicedUserCRUDProps>) {
	return (
		<SecuredPage groups={SecuredGroups.create.onlyAdmin().groups}>
			<InvoicedUserCRUD {...props} />
		</SecuredPage>
	);
}
