import { gql } from '@apollo/client';
import { store } from 'Models/Store';
import {
	UserEntity,
	CargoEntity,
	CargoTypeEntity,
	TowOnTypeEntity,
} from 'Models/Entities';
import alertToast from '../ToastifyUtils';

const query = gql`
	query usersLatestVehicles($id: ID) {
		userEntity(id: $id) {
			id
			latestVehicles {
				id
				cargoType {
					id
					cargoMake
					cargoModel
				}
				cargoDetails {
					id
					contactFirstName
					contactLastName
					contactPhone
				}
				cargoIdentification
				selectedLengthId
				selectedWeightId
			}
		}
	}
`;

/**
 * Returns the top three most recent vehicles of the given user.
 */
export async function fetchPreviousVehicles(customerId: string): Promise<CargoEntity[]> {
	try {
		const response = await store.apolloClient.query<{ userEntity: UserEntity }, { id: string }>({
			query: query,
			variables: { id: customerId },
			fetchPolicy: 'network-only',
		});

		if (response.error || response.errors) {
			alertToast('Something went wrong user\'s vehicles', 'error');
			return [];
		}

		return response.data.userEntity.latestVehicles.map(x => {
			const vehicleEntity = new CargoEntity(x);
			vehicleEntity.cargoType = new CargoTypeEntity(x.cargoType);
			return vehicleEntity;
		});
	} catch (e: any) {
		console.error(e);
		return [];
	}
}
