import { SERVER_URL } from 'Constants';
import axios from 'axios';

export interface FerryTripCommunicationDto {
	ferryTripId: string,
	sendSms: boolean,
	sendEmail: boolean,
	bodyContent: string,
	subjectHeader?: string | null,
	sendToLoggedInUser?: boolean | null,
}

export const communicationBaseUrl = `${SERVER_URL}/api/communications`;

/**
 * Sends an SMS and/or Email to every BOOKED passenger on a ferry trip
 * @param ferryTripCommunicationDto: the data needed to send the SMS/emails.
 */
export async function SendFerryTripCommunications(ferryTripCommunicationDto: FerryTripCommunicationDto) {
	const result = await axios.post(
		`${communicationBaseUrl}/send-ferry-trip-communications`,
		ferryTripCommunicationDto,
	);
	return result.data;
}
