import moment from 'moment';

export function formatAMPM(date?: Date, lowerCase: boolean = true) {
	if (!date) {
		return '';
	}
	return moment(date).format(lowerCase ? 'h:mma' : 'h:mmA');
}

export function formatMilitary(date?: Date) {
	if (!date) {
		return '';
	}
	return moment(date).format('HH:mm');
}

/**
 * Return date time as 2022-10-31T08:19:12
 *
 * This is useful for sending server-side requests as this is the expected format to receive DateTime.
 */
export function formatDateTime(date: Date) {
	return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * Returns time as "1:46 pm". If useMilitary is true, then returns time as "13:46".
 */
export function formatShortTime(date: Date, useMilitary = false) {
	const options: Intl.DateTimeFormatOptions = {
		hour: '2-digit',
		minute: '2-digit',
		hourCycle: useMilitary ? 'h23' : 'h12',
	};
	return date.toLocaleTimeString('en-au', options);
}

/**
 * Returns date as "20/01/2023"
 */
export function formatShortDate(date: Date) {
	return new Intl.DateTimeFormat('en-AU').format(date);
}

/**
 * Returns date as "02 Dec"
 */
export function formatDateDayMonth(date: Date) {
	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: 'short',
	};
	return new Date(date).toLocaleDateString('en-AU', options)
		//
		// Some older browsers add '.', so we remove them
		//
		.replace('.', '');
}

export function formatDayMonthYear(date: Date) {
	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	};
	return new Date(date).toLocaleDateString('en-AU', options)
		//
		// Some older browsers add '.', so we remove them
		//
		.replace('.', '');
}
