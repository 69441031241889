import * as React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { showModal } from '../Base/BaseModalContents';
import { DualActionModalContent, DualActionModalContentProps } from '../Base/DualActionModalContent';
import TripNewDepartureEmailPreview from './TripNewDepartureEmailPreview';
import { FerryTripEntity } from 'Models/Entities';
import {
	Button, Colors, Display, Sizes,
} from 'Views/Components/Button/Button';
import { store } from 'Models/Store';
import Icon from '../../Icon/Icon';
import { stringIsEmpty } from 'Util/TypeGuards';
import {
	saveFerryTripAndSendEmails,
	TripNewDepartureEmailPreviewErrors,
	TripNewDepartureEmailPreviewState,
	useTripNewDepartureEmailPreview,
} from 'Hooks/useTripNewDepartureEmailPreview';

export const TRIP_NEW_DEPARTURE_EMAIL_FORM = 'trip-new-departure-email-form';

export interface TripNewDepartureEmailModalContentsProps
	extends Pick<DualActionModalContentProps, 'onCancel' | 'onConfirm'> {
	ferryTrip: FerryTripEntity;
}

function TripNewDepartureEmailModalContents({
	ferryTrip,
	onCancel,
	onConfirm,
}: TripNewDepartureEmailModalContentsProps) {
	const [confirmIsDisabled, setConfirmIsDisabled] = React.useState(false);
	const { state, errors } = useTripNewDepartureEmailPreview(ferryTrip.id, ferryTrip.departureDateTime);

	const onSubmit = async (e: any) => {
		e.preventDefault();

		if (!validate(state, errors)) {
			return;
		}

		setConfirmIsDisabled(true);

		await saveFerryTripAndSendEmails(ferryTrip, state.header, state.body, state.sendSms, state.sendEmail);

		setConfirmIsDisabled(false);

		store.modal.hide();

		if (onConfirm) {
			onConfirm();
		}
	};

	return (
		<DualActionModalContent
			className=""
			title="Send communications"
			body={(
				<form onSubmit={onSubmit} id={TRIP_NEW_DEPARTURE_EMAIL_FORM}>
					<TripNewDepartureEmailPreview
						ferryTrip={ferryTrip}
						state={state}
						errors={errors}
					/>
				</form>
			)}
			fixedCancelWidth
			cancelContent="Close"
			confirmContent={(
				<Button
					key="confirm"
					display={Display.Solid}
					colors={confirmIsDisabled ? Colors.Disabled : Colors.Alternate}
					sizes={Sizes.Medium}
					className="confirm"
					disabled={confirmIsDisabled}
					buttonProps={{
						form: TRIP_NEW_DEPARTURE_EMAIL_FORM,
						type: 'submit',
					}}
				>
					{confirmIsDisabled ? 'Sending...' : 'Send communications'}
					<Icon name="arrow-right" />
				</Button>
			)}
			onCancel={onCancel}
		/>
	);
}

export default observer(TripNewDepartureEmailModalContents);

export function showTripNewDepartureEmailModal(props: TripNewDepartureEmailModalContentsProps) {
	showModal({
		key: 'trip-date-time-email-preview',
		content: <TripNewDepartureEmailModalContents {...props} />,
		className: 'base-modal-width-lg',
	});
}

function validate(state: TripNewDepartureEmailPreviewState, errors: TripNewDepartureEmailPreviewErrors): boolean {
	let valid = true;
	if (stringIsEmpty(state.header.trim())) {
		runInAction(() => {
			errors.header = 'Required';
			valid = false;
		});
	}
	if (stringIsEmpty(state.body.trim())) {
		runInAction(() => {
			errors.body = 'Required';
			valid = false;
		});
	}
	if (!state.sendSms && !state.sendEmail) {
		runInAction(() => {
			errors.communicationType = 'Required';
			valid = false;
		});
	}
	return valid;
}
