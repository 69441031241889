import { RouteEntity } from 'Models/Entities';
import { FerryScheduleModel } from './FerryScheduleModel';

export function createDefaultFerryScheduleModel(): FerryScheduleModel {
	return {
		date: new Date(),
		numOfDays: 7,
		fromLocation: '',
		toLocation: '',
		route: new RouteEntity(),
	};
}

export function toggleTrip(
	bulkBookingTrips: string[] | null,
	tripId: string,
	updateTrips: (trips: string[]) => void,
) {
	if (bulkBookingTrips === null) {
		return;
	}

	const newTrips = bulkBookingTrips.filter(x => x !== tripId);

	// If newTrips and bulkBookingTrips have the same length, then that means that no elements were filtered out of the
	// array. This means that the trip id was NOT in the bulk booking list, so we need to add it
	if (newTrips.length === bulkBookingTrips.length) {
		newTrips.push(tripId);
	}

	// 2 scenarios here. A trip was filtered out or it wasn't. A trip was filtered out if the arrays have a different
	// length.
	// If a trip was filtered, then the trip id was in the array initially, so it needs to be removed. After the filter
	// we don't need to do anything else and can just run the update function
	// If a trip was not filtered, then it wasn't in the array initially, which means it needs to be added. In this
	// scenario, we will have entered the if statement and pushed the id into the new trips, so we can run the update
	// function with no further changes
	updateTrips(newTrips);
}
