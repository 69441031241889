import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { reaction, runInAction } from 'mobx';
import classNames from 'classnames';
import { Alignment, ButtonGroup } from 'Views/Components/Button/ButtonGroup';
import Tabs from 'Views/Components/Tabs/Tabs';
import {
	CustomTicketTypeEntity,
	EventDetailEntity, EventLabelEntity,
	UploadedImageEntity,
} from 'Models/Entities';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import alertToast from 'Util/ToastifyUtils';
import SecuredPage from '../../../Security/SecuredPage';
import { SecuredGroups } from '../../../../../Models/Security/UserGroups';
import EventDetailsTabComponent from './EventSideBarDetailsTab';
import If from '../../../If/If';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import EventPriceTabComponent from './EventSideBarPricingTab';
import { LottieSpinner } from '../../Lottie/LottieSpinner';
import { validateField } from 'Validators/Functions/HumanWritten/ValidateForm';
import {
	eventDetailsValidationRules,
	eventSidebarPropsValidationRules,
} from './ValidationRules';
import { ScrollToError } from 'Validators/Functions/HumanWritten/ScrollToError';
import EventsTermsAndConditionsTab from './TAndCsTab';
import { EventCommunicationTabComponent, EventCommunicationTabProps } from './EventCommunicationTab';
import { SendFerryTripCommunications } from '../../../../../Services/Api/_HumanWritten/CommunicationService';

export interface EventSideBarProps {
	eventDetails: EventDetailEntity | null;
	customTicketTypes: CustomTicketTypeEntity[];
	headerImage: UploadedImageEntity | null;
	supportingImages: UploadedImageEntity[];
}

export interface EventSideBarPropsErrors {
	name?: string;
	address?: string;
	googleMapUrl?: string;
	description?: string;
	termsAndConditions?: string;
	totalCapacity?: string;
	eventLabelId?: string;
	endDate?: string;
	headerImage?: string;
	supportingImages?: string;
	customTicketTypes?: string;
}

export interface ISidePanelProps {
	hidePanel: (confirm: boolean, needRefetch: boolean) => void;
	state: EventSideBarProps;
	originalEvent: EventDetailEntity;
}

function EventSideBar({
	state,
	originalEvent,
	hidePanel,
}: ISidePanelProps) {
	const [viewMode, setViewMode] = useState(!!state.eventDetails?.id);
	const [tabNumber, setTabNumber] = useState(0);
	const [createMode] = useState(state.eventDetails?.id === undefined);
	const [eventLabels, setEventLabels] = useState<EventLabelEntity[]>([]);
	const [loading, setLoading] = useState(false);

	const needConfirm = useRef(false);

	const errors = useLocalStore<EventSideBarPropsErrors>(() => ({}));

	const communicationTabModel = useLocalStore<EventCommunicationTabProps>(() => {
		return {
			eventDetails: state.eventDetails!,
			model: {
				subject: null,
				message: '',
				sendEmail: false,
				sendSms: false,
				extraPhone: '',
			},
			isDisabled: createMode || !viewMode,
		};
	});

	const resetCommunicationTab = () => {
		runInAction(() => {
			communicationTabModel.model.message = '';
			communicationTabModel.model.sendSms = false;
			communicationTabModel.model.sendEmail = false;
			communicationTabModel.model.subject = null;
		});
	};

	useEffect(() => {
		const setNeedConfirm = () => {
			needConfirm.current = true;
		};
		// If any changes are made on any of these fields it will trigger the modal to confirm closing the side-bar
		const disposals = [
			reaction(() => state.eventDetails!.ferryTrip?.startDate, setNeedConfirm),
			reaction(() => state.eventDetails!.ferryTrip?.endDate, setNeedConfirm),
			reaction(() => state.eventDetails!.name, setNeedConfirm),
			reaction(() => state.eventDetails!.totalCapacity, setNeedConfirm),
			reaction(() => state.eventDetails!.description, setNeedConfirm),
			reaction(() => state.eventDetails!.eventLabelId, setNeedConfirm),
			reaction(() => state.eventDetails!.termsAndConditions, setNeedConfirm),
			reaction(() => state.headerImage, setNeedConfirm),
			reaction(() => state.supportingImages, setNeedConfirm),
			reaction(() => state.customTicketTypes, setNeedConfirm),
		];

		// Clean up all of the reactions after one has been triggered
		return () => {
			disposals.map(disposal => disposal());
		};
	}, [state.eventDetails]);

	useEffect(() => {
		EventLabelEntity.fetch<EventLabelEntity>().then(x => {
			setEventLabels(x);
		});
	}, []);

	const handleSave = async (newEvent: boolean) => {
		try {
			setLoading(true);
			if (validateAllFields(state, errors)) {
				runInAction(() => {
					if (isNotNullOrUndefined(state.eventDetails)) {
						state.eventDetails.headerImage = state.headerImage!;
						state.eventDetails.supportingImages = state.supportingImages;
						state.eventDetails.customTicketTypes = state.customTicketTypes;
						state.eventDetails.ferryTrip!.startTime = state.eventDetails.ferryTrip!.startDate;
						state.eventDetails.ferryTrip!.endTime = state.eventDetails.ferryTrip!.endDate;
					}
				});

				if (isNullOrUndefined(state?.eventDetails?.id)) {
					// Event is new
					await state.eventDetails!.save(
						{
							ferryTrip: {},
							eventDetails: {},
							headerImage: {},
							supportingImages: {},
							customTicketTypes: {},
						},
						{ contentType: 'multipart/form-data' },
					);
					await handleSaveSuccess(newEvent);
					setLoading(false);
					return;
				}

				await state.eventDetails!.save(
					{
						ferryTrip: {},
						eventDetails: {},
						headerImage: {},
						customTicketTypes: {},
					},
					{ contentType: 'multipart/form-data' },
				);
				await handleSaveSuccess(newEvent);
			} else {
				if (errors.name || errors.description || errors.eventLabelId || errors.totalCapacity || errors.headerImage || errors.supportingImages || errors.endDate) {
					setTabNumber(0);
					ScrollToError();
				} else if (errors.customTicketTypes) {
					setTabNumber(1);
				} else {
					setTabNumber(2);
				}
				alertToast('Please complete all required fields', 'error');
				setLoading(false);
			}
		} catch (e: any) {
			alertToast(
				'There was an error saving the event.',
				'error',
				'Couldn\'t save event',
			);
			setLoading(false);
		}
	};

	const handleSaveSuccess = async (newEvent: boolean, emailsSent = false) => {
		hidePanel(false, true);
		let header;
		let body;

		if (newEvent) {
			header = 'New event added!';
			body = 'You have successfully created a new event.';
		} else {
			header = 'Event updated!';
			if (emailsSent) {
				body = 'The details for the event are updated and emails have been sent to customers.';
			} else {
				body = 'You have successfully updated the details for the event.';
			}
		}

		return alertToast(body, 'success', header);
	};

	if (isNullOrUndefined(state?.eventDetails?.ferryTrip)) {
		return <LottieSpinner />;
	}

	return (
		<SecuredPage groups={SecuredGroups.create.onlyManager().groups}>
			<div className="sidebar custom-sidebar__container add-event__sidebar">
				<div
					className="sidebar-click-interceptor"
					onClick={() => hidePanel(needConfirm.current, false)}
					role="button"
					onKeyDown={() => hidePanel(needConfirm.current, false)}
					tabIndex={0}
					style={{ height: '100%' }}
					aria-label="Close modal"
				/>
				<div className={classNames('sidebar-info')}>
					<div className="custom-sidebar__header">
						<div>
							{/* eslint-disable-next-line no-nested-ternary */}
							<h2>{createMode ? 'New event' : (viewMode ? 'View event' : 'Edit event')}</h2>
							<h5>Enter details and save event</h5>
						</div>
						<div className="custom-sidebar__private-toggle">
							<Button
								icon={{
									icon: state.eventDetails?.hidden
										? 'look-off'
										: 'look',
									iconPos: 'icon-right',
								}}
								disabled={viewMode}
								colors={Colors.Primary}
								onClick={() => {
									runInAction(() => {
										state.eventDetails!.hidden = !state.eventDetails?.hidden;
									});
								}}
							>
								<span className="tooltiptext">
									{
										viewMode
											? `This event is ${state.eventDetails?.hidden ? 'private' : 'public'}.`
											: `Make this event ${state.eventDetails?.hidden ? 'public' : 'private'}.`
									}
								</span>
							</Button>
						</div>
					</div>
					<Tabs
						tabs={[
							{
								name: 'Details',
								key: 'event-details',
								component: <EventDetailsTabComponent
									state={state}
									isDisabled={viewMode || !createMode}
									eventLabels={eventLabels}
									errors={errors}
								/>,
							},
							{
								name: 'Pricing',
								key: 'event-pricing',
								component: <EventPriceTabComponent
									state={state}
									isDisabled={viewMode}
									errors={errors}
								/>,
							},
							{
								name: 'T&Cs',
								key: 't-and-c',
								component: <EventsTermsAndConditionsTab
									state={state}
									isDisabled={viewMode}
									errors={errors}
								/>,
							},
							{
								name: 'Communication',
								key: 'communication',
								component: <EventCommunicationTabComponent
									eventDetails={state.eventDetails!}
									model={communicationTabModel.model}
									isDisabled={false}
								/>,
								disabled: !viewMode,
							},
						]}
						onTabClicked={tabNum => {
							setTabNumber(tabNum);
						}}
						currentTab={tabNumber}
					/>
					<ButtonGroup alignment={Alignment.HORIZONTAL} className="custom-sidebar__action-buttons">
						<If condition={tabNumber !== 3 && tabNumber !== 4 && !viewMode}>
							<Button
								className="cancel_edit_add_event"
								display={Display.Outline}
								colors={Colors.Secondary}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'cancel_edit_add_event' }}
								onClick={() => hidePanel(needConfirm.current, false)}
								disabled={loading}
							>
								Cancel changes
							</Button>
							<Button
								className="confirm_edit_add_event"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={viewMode ? Colors.Secondary : Colors.Alternate}
								buttonProps={{ id: 'confirm_edit_add_event' }}
								onClick={() => viewMode ? setViewMode(false) : handleSave(createMode)}
								disabled={loading}
							>
								{/* eslint-disable-next-line no-nested-ternary */}
								{viewMode ? 'Edit details' : (loading ? 'Saving...' : 'Save changes')}
							</Button>
						</If>
						<If condition={tabNumber === 3}>
							<Button
								className="cancel_edit_add_ferry_trip"
								display={Display.Outline}
								colors={Colors.Secondary}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'cancel_edit_add_ferry_trip' }}
								disabled={loading || (stringIsEmpty(communicationTabModel.model.message)
									&& stringIsEmpty(communicationTabModel.model.subject)
									&& !communicationTabModel.model.sendSms
									&& !communicationTabModel.model.sendEmail)}
								onClick={() => resetCommunicationTab()}
							>
								Clear
							</Button>
							<Button
								className="confirm_edit_add_ferry_trip"
								display={Display.Solid}
								sizes={Sizes.Medium}
								colors={viewMode ? Colors.Secondary : Colors.Alternate}
								buttonProps={{ id: 'confirm_edit_add_ferry_trip' }}
								disabled={loading || stringIsEmpty(communicationTabModel.model.message)
									|| (!communicationTabModel.model.sendSms
										&& !communicationTabModel.model.sendEmail)
									|| stringIsEmpty(communicationTabModel.model.subject)}
								onClick={() => {
									let errorToastMessage = '';
									if (communicationTabModel.model.message === '') {
										errorToastMessage = 'Enter message to send';
									}
									if (!communicationTabModel.model.sendSms
										&& !communicationTabModel.model.sendEmail) {
										const errorMessage = 'Select mode of communication';
										errorToastMessage += errorToastMessage === ''
											? errorMessage
											: ` and ${errorMessage.toLowerCase()}`;
									}
									if (errorToastMessage !== '') {
										alertToast(errorToastMessage, 'error');
									} else {
										setLoading(true);
										SendFerryTripCommunications({
											ferryTripId: state.eventDetails!.ferryTrip!.id,
											sendEmail: communicationTabModel.model.sendEmail,
											sendSms: communicationTabModel.model.sendSms,
											bodyContent: communicationTabModel.model.message,
											sendToLoggedInUser: false,
											subjectHeader: communicationTabModel.model.subject,
										}).then(x => {
											resetCommunicationTab();
											if (isNotNullOrUndefined(x.SMS_SUCCESS)) {
												alertToast(x.SMS_SUCCESS, 'success');
											}
											if (isNotNullOrUndefined(x.SMS_ERROR)) {
												alertToast(x.SMS_ERROR, 'error');
											}
											if (isNotNullOrUndefined(x.EMAIL_SUCCESS)) {
												alertToast(x.EMAIL_SUCCESS, 'success');
											}
											if (isNotNullOrUndefined(x.EMAIL_ERROR)) {
												alertToast(x.EMAIL_ERROR, 'error');
											}
											setLoading(false);
										}).catch(() => {
											alertToast('Failed to send communications', 'error');
											setLoading(false);
										});
									}
								}}
							>
								{loading ? 'Sending...' : 'Send'}
							</Button>
						</If>
					</ButtonGroup>
				</div>
			</div>
		</SecuredPage>
	);
}

function validateAllFields(state: EventSideBarProps, errors: EventSideBarPropsErrors): boolean {
	validateField(state.eventDetails!, 'name', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'address', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'googleMapUrl', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'description', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'totalCapacity', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'eventLabelId', errors, eventDetailsValidationRules);
	validateField(state.eventDetails!, 'termsAndConditions', errors, eventDetailsValidationRules);

	validateField(state, 'headerImage', errors, eventSidebarPropsValidationRules);
	validateField(state, 'supportingImages', errors, eventSidebarPropsValidationRules);
	validateField(state, 'customTicketTypes', errors, eventSidebarPropsValidationRules);

	// Handle validation of end date time
	runInAction(() => {
		delete errors.endDate;
	});

	if (state.eventDetails?.ferryTrip?.startDate
		&& state.eventDetails?.ferryTrip?.endDate
		&& state.eventDetails.ferryTrip.startDate.getFullYear() === state.eventDetails.ferryTrip.endDate.getFullYear()
		&& state.eventDetails.ferryTrip.startDate.getMonth() === state.eventDetails.ferryTrip.endDate.getMonth()
		&& state.eventDetails.ferryTrip.startDate.getDate() === state.eventDetails.ferryTrip.endDate.getDate()
		&& state.eventDetails.ferryTrip.startDate.getHours() === state.eventDetails.ferryTrip.endDate.getHours()
		&& state.eventDetails.ferryTrip.startDate.getMinutes() === state.eventDetails.ferryTrip.endDate.getMinutes()) {
		runInAction(() => {
			errors.endDate = 'Cannot be the same as start date';
		});
	}
	if (state.eventDetails!.ferryTrip!.startDate > state.eventDetails!.ferryTrip!.endDate) {
		runInAction(() => {
			errors.endDate = 'Cannot be before start date';
		});
	}

	return Object.keys(errors).length === 0;
}

export default observer(EventSideBar);
