import { isNotNullOrUndefined } from '../TypeGuards';

export function minDate(dates: (Date | undefined | null)[]) {
	const actualDates = dates.filter(x => isNotNullOrUndefined(x)).map(x => new Date(x as Date));

	if (actualDates.length < 1) {
		return undefined;
	}

	return new Date(Math.min(...actualDates.map(x => x.getTime())));
}

export function maxDate(dates: (Date | undefined | null)[]) {
	const actualDates = dates.filter(x => isNotNullOrUndefined(x)).map(x => new Date(x as Date));

	if (actualDates.length < 1) {
		return undefined;
	}

	return new Date(Math.max(...actualDates.map(x => x.getTime())));
}

function pad(value: number) {
	let r = String(value);
	if (r.length === 1) {
		r = `0${r}`;
	}
	return r;
}

/**
 * Format date as 2023-05-04T04:54:16.041Z
 *
 * This function ignores the local timezone in your computer.
 */
export function formatAsISO(date: Date) {
	return `${
		date.getFullYear()
	}-${
		pad(date.getMonth() + 1)
	}-${
		pad(date.getDate())
	}T${
		pad(date.getHours())
	}:${
		pad(date.getMinutes())
	}:${
		pad(date.getSeconds())
	}.${
		String((date.getMilliseconds() / 1000).toFixed(3)).slice(2, 5)
	}Z`;
}
