import React, { useEffect, useState } from 'react';
import If from 'Views/Components/If/If';
import classNames from 'classnames';

export interface EventBookingTimerProps {
	isMobile: boolean;
	pageWithSidebar: boolean;
	secondsUntilExpiry: number;
	expiringSoon: boolean;
	onResetTimer: (event: React.MouseEvent) => void;
	resetting?: boolean;
}

export function EventBookingTimer({
	isMobile, secondsUntilExpiry, onResetTimer, expiringSoon, pageWithSidebar, resetting,
}: EventBookingTimerProps) {
	const tabletBoundary = 1300;

	const [tablet, setTablet] = useState(window.innerWidth < tabletBoundary);

	const minutes = Math.floor((secondsUntilExpiry ?? 0) / 60);
	const seconds = (secondsUntilExpiry ?? 0) % 60;
	const secondsLeadingZero = seconds < 10 ? '0' : '';

	const countdownClass: string[] = ['booking-timer__time'];

	if (expiringSoon) {
		countdownClass.push('booking-timer__expires-soon');
	}

	useEffect(() => {
		const resizeHandler = () => {
			if (tablet && window.innerWidth >= tabletBoundary) {
				setTablet(false);
			}

			if (!tablet && window.innerWidth < tabletBoundary) {
				setTablet(true);
			}
		};

		window.addEventListener('resize', resizeHandler);

		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, [tablet]);

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
			<div
				className="booking-timer__timer-and-title"
				onClick={event => {
					event.stopPropagation();
				}}
			>
				<div className={classNames(countdownClass)}>
					<p className="icon-clock icon-left">
						{minutes}:{secondsLeadingZero}{seconds}
					</p>
				</div>
				<If condition={isMobile || !tablet || !pageWithSidebar || !expiringSoon}>
					<p>
						Time remaining
					</p>
				</If>
			</div>
			<If condition={expiringSoon && secondsUntilExpiry !== 0}>
				{/*
					I did this on a p tag instead of a button because this component is being rendered as part of the
					back button on web. That leads to a button being rendered inside a button, which will cause a
					warning in the console
				*/}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
				<p
					className={`booking-timer__reset-timer ${resetting ? 'resetting' : ''}`}
					onClick={onResetTimer}
				>
					{resetting ? 'Resetting...' : 'Reset timer'}
				</p>
			</If>
		</>
	);
}
