import { EntityFormMode } from '../../../Helpers/Common';
import { AccountDetailsTabHeader } from '../AccountDetailsTabHeader';
import { ReadOnlyField } from './ReadOnlyField';
import * as React from 'react';
import { UserEntity } from '../../../../../Models/Entities';
import { password } from './AccountFieldsUtils';
import { upperCaseFirst } from '../../../../../Util/StringUtils';

interface DetailFieldsReadOnlyProps {
	user: UserEntity;
	mode: EntityFormMode;
	setMode: (mode: EntityFormMode) => void;
	updatePasswordButton?: React.ReactNode;
}
export function DetailFieldsReadOnly({
	user,
	mode,
	setMode,
	updatePasswordButton,
}: DetailFieldsReadOnlyProps) {
	return (
		<div className="user-account-fields">
			<AccountDetailsTabHeader
				headerText="Account details"
				buttonText={mode === EntityFormMode.VIEW ? 'Edit' : ''}
				onClick={() => setMode(EntityFormMode.EDIT)}
			/>
			<ReadOnlyField
				fieldName="Name"
				fieldValue={`${upperCaseFirst(user.firstName.toLowerCase())} ${upperCaseFirst(user.lastName.toLowerCase())}`}
			/>
			<ReadOnlyField
				fieldName="Password"
				fieldValue={password.password}
				updatePasswordButton={updatePasswordButton}
			/>
			<ReadOnlyField fieldName="Email" fieldValue={user.email} />
			<ReadOnlyField fieldName="Mobile" fieldValue={user.phone} />
			<ReadOnlyField fieldName="Postcode" fieldValue={user.postcode} />
		</div>
	);
}
