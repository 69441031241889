import * as React from 'react';
import { SingleActionModalContent } from './Base/SingleActionModalContent';
import { store } from 'Models/Store';
import { showModal } from './Base/BaseModalContents';

export function CheckInBookingNotFoundModalContents() {
	const message = React.useMemo(() => {
		return 'This booking does not exist.';
	}, []);

	return (
		<SingleActionModalContent
			title="Error"
			body={message}
			onConfirm={() => store.modal.hide()}
			confirmContent="Ok"
		/>
	);
}

export function showCheckInBookingNotFoundModal() {
	showModal({
		key: 'boarding-booking-not-found',
		content: <CheckInBookingNotFoundModalContents />,
	});
}
