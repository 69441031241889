import React from 'react';
import { Text } from 'Views/Components/Text/Text';

function DeleteFerryTripStaticText() {
	return (
		<>
			<Text>
				Are you sure you want to delete this ferry trip?
			</Text>
			<Text>
				Related bookings will be cancelled, receive a gift
				certificate of the full amount, and notified via email.
			</Text>
			<Text>
				This action cannot be undone.
			</Text>
		</>
	);
}

const DeleteFerryTripStaticTextMemo = React.memo(DeleteFerryTripStaticText);

export default DeleteFerryTripStaticTextMemo;
