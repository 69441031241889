import React from 'react';
import classNames from 'classnames';

interface IIconsProps {
	classname?: string;
	name: string;
}
/**
 * Component for displaying only an icon, based on given name.
 */
export default function Icon({
	classname,
	name,
}: IIconsProps) {
	return <span className={classNames(`icon-${name} icon-only`, classname)} />;
}
