/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMeasurementEntity from 'Models/Security/Acl/VisitorsMeasurementEntity';
import UserMeasurementEntity from 'Models/Security/Acl/UserMeasurementEntity';
import StaffMeasurementEntity from 'Models/Security/Acl/StaffMeasurementEntity';
import ManagerMeasurementEntity from 'Models/Security/Acl/ManagerMeasurementEntity';
import AdminMeasurementEntity from 'Models/Security/Acl/AdminMeasurementEntity';
import InvoicedUserMeasurementEntity from 'Models/Security/Acl/InvoicedUserMeasurementEntity';
import FixedPriceUserMeasurementEntity from 'Models/Security/Acl/FixedPriceUserMeasurementEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMeasurementEntityAttributes extends IModelAttributes {
	label: string;
	value: number;
	measurementType: Enums.measurementType;

	cargoHeights: Array<
		| Models.CargoTypeEntity
		| Models.ICargoTypeEntityAttributes
	>;
	cargoLengths: Array<
		| Models.CargoTypeEntity
		| Models.ICargoTypeEntityAttributes
	>;
	cargoWeights: Array<
		| Models.CargoTypeEntity
		| Models.ICargoTypeEntityAttributes
	>;
	prices: Array<
		| Models.PriceEntity
		| Models.IPriceEntityAttributes
	>;
	selectedCargoHeights: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	selectedCargoLengths: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	selectedCargoWeights: Array<
		| Models.CargoEntity
		| Models.ICargoEntityAttributes
	>;
	spacingAddOns: Array<
		| Models.SpacingAddOnEntity
		| Models.ISpacingAddOnEntityAttributes
	>;
	towOnHeights: Array<
		| Models.TowOnEntity
		| Models.ITowOnEntityAttributes
	>;
	towOnLengths: Array<
		| Models.TowOnEntity
		| Models.ITowOnEntityAttributes
	>;
	towOnTypeHeights: Array<
		| Models.TowOnTypeEntity
		| Models.ITowOnTypeEntityAttributes
	>;
	towOnTypeLengths: Array<
		| Models.TowOnTypeEntity
		| Models.ITowOnTypeEntityAttributes
	>;
	towOnTypeWeights: Array<
		| Models.TowOnTypeEntity
		| Models.ITowOnTypeEntityAttributes
	>;
	towOnWeights: Array<
		| Models.TowOnEntity
		| Models.ITowOnEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MeasurementEntity', 'Measurement')
// % protected region % [Customise your entity metadata here] end
export default class MeasurementEntity extends Model
	implements IMeasurementEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMeasurementEntity(),
		new UserMeasurementEntity(),
		new StaffMeasurementEntity(),
		new ManagerMeasurementEntity(),
		new AdminMeasurementEntity(),
		new InvoicedUserMeasurementEntity(),
		new FixedPriceUserMeasurementEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Label'] on begin
	@Validators.Required()
	@observable
	@attribute<MeasurementEntity, string>()
	@CRUD({
		name: 'Measurement display name',
		description: 'The measurement name that will display around the application and be seen by customers.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public label: string;
	// % protected region % [Modify props to the crud options here for attribute 'Label'] end

	// % protected region % [Modify props to the crud options here for attribute 'Value'] on begin
	/**
	 * Value refers to the length or the weight of a vehicle or trailer
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<MeasurementEntity, number>()
	@CRUD({
		name: 'Measurement value',
		description: 'The value of the measurement. E.g. if it is a weight measurement type, the value could be '
			+ 'tonnes or kgs.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public value: number;
	// % protected region % [Modify props to the crud options here for attribute 'Value'] end

	// % protected region % [Modify props to the crud options here for attribute 'Measurement Type'] on begin
	@Validators.Required()
	@observable
	@attribute<MeasurementEntity, Enums.measurementType>()
	@CRUD({
		name: 'Measurement type',
		description: 'The type of measurement that the measurement will apply to. E.g. if it is a weight measurement, '
			+ 'it will only be considered in weight calculations.',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.measurementTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.measurementTypeOptions),
		displayFunction: (attr: Enums.measurementType) => Enums.measurementTypeOptions[attr],
	})
	public measurementType: Enums.measurementType;
	// % protected region % [Modify props to the crud options here for attribute 'Measurement Type'] end

	/**
	 * The minimum height required for this cargo item.
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Height'] on begin
		name: 'Cargo heights',
		displayType: 'hidden',
		order: 40,
		referenceTypeFunc: () => Models.CargoTypeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargoHeights',
			oppositeEntity: () => Models.CargoTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo Height'] end
	})
	public cargoHeights: Models.CargoTypeEntity[] = [];

	/**
	 * The minimum length required for this cargo item.
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Length'] on begin
		name: 'Cargo lengths',
		displayType: 'hidden',
		order: 50,
		referenceTypeFunc: () => Models.CargoTypeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargoLengths',
			oppositeEntity: () => Models.CargoTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo Length'] end
	})
	public cargoLengths: Models.CargoTypeEntity[] = [];

	/**
	 * The minimum weight required for this cargo item.
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Cargo Weight'] on begin
		name: 'Cargo weights',
		displayType: 'hidden',
		order: 60,
		referenceTypeFunc: () => Models.CargoTypeEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'cargoWeights',
			oppositeEntity: () => Models.CargoTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Cargo Weight'] end
	})
	public cargoWeights: Models.CargoTypeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Price'] on begin
		name: 'Prices',
		displayType: 'hidden',
		order: 40,
		referenceTypeFunc: () => Models.PriceEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'prices',
			oppositeEntity: () => Models.PriceEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Price'] end
	})
	public prices: Models.PriceEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Height'] on begin
		name: 'Selected cargo heights',
		displayType: 'hidden',
		order: 80,
		referenceTypeFunc: () => Models.CargoEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'selectedCargoHeights',
			oppositeEntity: () => Models.CargoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Height'] end
	})
	public selectedCargoHeights: Models.CargoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Length '] on begin
		name: 'Selected cargo lengths',
		displayType: 'hidden',
		order: 90,
		referenceTypeFunc: () => Models.CargoEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'selectedCargoLengths',
			oppositeEntity: () => Models.CargoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Length '] end
	})
	public selectedCargoLengths: Models.CargoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Weight'] on begin
		name: 'Selected cargo weights',
		displayType: 'hidden',
		order: 100,
		referenceTypeFunc: () => Models.CargoEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'selectedCargoWeights',
			oppositeEntity: () => Models.CargoEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Selected Cargo Weight'] end
	})
	public selectedCargoWeights: Models.CargoEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Spacing Add On'] on begin
		name: 'Spacing add ons',
		displayType: 'hidden',
		order: 110,
		referenceTypeFunc: () => Models.SpacingAddOnEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'spacingAddOns',
			oppositeEntity: () => Models.SpacingAddOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Spacing Add On'] end
	})
	public spacingAddOns: Models.SpacingAddOnEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Height'] on begin
		name: 'Tow on heights',
		displayType: 'hidden',
		order: 120,
		referenceTypeFunc: () => Models.TowOnEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnHeights',
			oppositeEntity: () => Models.TowOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Height'] end
	})
	public towOnHeights: Models.TowOnEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Length'] on begin
		name: 'Tow on lengths',
		displayType: 'hidden',
		order: 130,
		referenceTypeFunc: () => Models.TowOnEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnLengths',
			oppositeEntity: () => Models.TowOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Length'] end
	})
	public towOnLengths: Models.TowOnEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Height'] on begin
		name: 'Tow on type heights',
		displayType: 'hidden',
		order: 140,
		referenceTypeFunc: () => Models.TowOnTypeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnTypeHeights',
			oppositeEntity: () => Models.TowOnTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Height'] end
	})
	public towOnTypeHeights: Models.TowOnTypeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Length'] on begin
		name: 'Tow on type lengths',
		displayType: 'hidden',
		order: 150,
		referenceTypeFunc: () => Models.TowOnTypeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnTypeLengths',
			oppositeEntity: () => Models.TowOnTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Length'] end
	})
	public towOnTypeLengths: Models.TowOnTypeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Weight'] on begin
		name: 'Tow on type weights',
		displayType: 'hidden',
		order: 160,
		referenceTypeFunc: () => Models.TowOnTypeEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnTypeWeights',
			oppositeEntity: () => Models.TowOnTypeEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Type Weight'] end
	})
	public towOnTypeWeights: Models.TowOnTypeEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tow On Weight'] on begin
		name: 'Tow on weights',
		displayType: 'hidden',
		order: 170,
		referenceTypeFunc: () => Models.TowOnEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'towOnWeights',
			oppositeEntity: () => Models.TowOnEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tow On Weight'] end
	})
	public towOnWeights: Models.TowOnEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMeasurementEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMeasurementEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.label !== undefined) {
				this.label = attributes.label;
			}
			if (attributes.value !== undefined) {
				this.value = attributes.value;
			}
			if (attributes.measurementType !== undefined) {
				this.measurementType = attributes.measurementType;
			}
			if (attributes.cargoHeights !== undefined && Array.isArray(attributes.cargoHeights)) {
				for (const model of attributes.cargoHeights) {
					if (model instanceof Models.CargoTypeEntity) {
						this.cargoHeights.push(model);
					} else {
						this.cargoHeights.push(new Models.CargoTypeEntity(model));
					}
				}
			}
			if (attributes.cargoLengths !== undefined && Array.isArray(attributes.cargoLengths)) {
				for (const model of attributes.cargoLengths) {
					if (model instanceof Models.CargoTypeEntity) {
						this.cargoLengths.push(model);
					} else {
						this.cargoLengths.push(new Models.CargoTypeEntity(model));
					}
				}
			}
			if (attributes.cargoWeights !== undefined && Array.isArray(attributes.cargoWeights)) {
				for (const model of attributes.cargoWeights) {
					if (model instanceof Models.CargoTypeEntity) {
						this.cargoWeights.push(model);
					} else {
						this.cargoWeights.push(new Models.CargoTypeEntity(model));
					}
				}
			}
			if (attributes.prices !== undefined && Array.isArray(attributes.prices)) {
				for (const model of attributes.prices) {
					if (model instanceof Models.PriceEntity) {
						this.prices.push(model);
					} else {
						this.prices.push(new Models.PriceEntity(model));
					}
				}
			}
			if (attributes.selectedCargoHeights !== undefined && Array.isArray(attributes.selectedCargoHeights)) {
				for (const model of attributes.selectedCargoHeights) {
					if (model instanceof Models.CargoEntity) {
						this.selectedCargoHeights.push(model);
					} else {
						this.selectedCargoHeights.push(new Models.CargoEntity(model));
					}
				}
			}
			if (attributes.selectedCargoLengths !== undefined && Array.isArray(attributes.selectedCargoLengths)) {
				for (const model of attributes.selectedCargoLengths) {
					if (model instanceof Models.CargoEntity) {
						this.selectedCargoLengths.push(model);
					} else {
						this.selectedCargoLengths.push(new Models.CargoEntity(model));
					}
				}
			}
			if (attributes.selectedCargoWeights !== undefined && Array.isArray(attributes.selectedCargoWeights)) {
				for (const model of attributes.selectedCargoWeights) {
					if (model instanceof Models.CargoEntity) {
						this.selectedCargoWeights.push(model);
					} else {
						this.selectedCargoWeights.push(new Models.CargoEntity(model));
					}
				}
			}
			if (attributes.spacingAddOns !== undefined && Array.isArray(attributes.spacingAddOns)) {
				for (const model of attributes.spacingAddOns) {
					if (model instanceof Models.SpacingAddOnEntity) {
						this.spacingAddOns.push(model);
					} else {
						this.spacingAddOns.push(new Models.SpacingAddOnEntity(model));
					}
				}
			}
			if (attributes.towOnHeights !== undefined && Array.isArray(attributes.towOnHeights)) {
				for (const model of attributes.towOnHeights) {
					if (model instanceof Models.TowOnEntity) {
						this.towOnHeights.push(model);
					} else {
						this.towOnHeights.push(new Models.TowOnEntity(model));
					}
				}
			}
			if (attributes.towOnLengths !== undefined && Array.isArray(attributes.towOnLengths)) {
				for (const model of attributes.towOnLengths) {
					if (model instanceof Models.TowOnEntity) {
						this.towOnLengths.push(model);
					} else {
						this.towOnLengths.push(new Models.TowOnEntity(model));
					}
				}
			}
			if (attributes.towOnTypeHeights !== undefined && Array.isArray(attributes.towOnTypeHeights)) {
				for (const model of attributes.towOnTypeHeights) {
					if (model instanceof Models.TowOnTypeEntity) {
						this.towOnTypeHeights.push(model);
					} else {
						this.towOnTypeHeights.push(new Models.TowOnTypeEntity(model));
					}
				}
			}
			if (attributes.towOnTypeLengths !== undefined && Array.isArray(attributes.towOnTypeLengths)) {
				for (const model of attributes.towOnTypeLengths) {
					if (model instanceof Models.TowOnTypeEntity) {
						this.towOnTypeLengths.push(model);
					} else {
						this.towOnTypeLengths.push(new Models.TowOnTypeEntity(model));
					}
				}
			}
			if (attributes.towOnTypeWeights !== undefined && Array.isArray(attributes.towOnTypeWeights)) {
				for (const model of attributes.towOnTypeWeights) {
					if (model instanceof Models.TowOnTypeEntity) {
						this.towOnTypeWeights.push(model);
					} else {
						this.towOnTypeWeights.push(new Models.TowOnTypeEntity(model));
					}
				}
			}
			if (attributes.towOnWeights !== undefined && Array.isArray(attributes.towOnWeights)) {
				for (const model of attributes.towOnWeights) {
					if (model instanceof Models.TowOnEntity) {
						this.towOnWeights.push(model);
					} else {
						this.towOnWeights.push(new Models.TowOnEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = '';
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			cargoHeights: {},
			cargoLengths: {},
			cargoWeights: {},
			prices: {},
			selectedCargoHeights: {},
			selectedCargoLengths: {},
			selectedCargoWeights: {},
			spacingAddOns: {},
			towOnHeights: {},
			towOnLengths: {},
			towOnTypeHeights: {},
			towOnTypeLengths: {},
			towOnTypeWeights: {},
			towOnWeights: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'cargoHeights',
							'selectedCargoHeights',
							'towOnHeights',
							'towOnTypeHeights',
							'towOnTypeLengths',
							'towOnTypeWeights',
							'towOnWeights',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.label;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MeasurementEntity.prototype, 'created');
CRUD(modifiedAttr)(MeasurementEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
