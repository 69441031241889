import React from 'react';
import { SteeringWheelSpinner } from 'Views/Components/_HumanWritten/Lottie/SteeringWheelSpinner';
import classNames from 'classnames';

interface PostPaymentRenderProps {
	className?: string;
}

export function PostPaymentRender({ className }: PostPaymentRenderProps) {
	// This is a different component because we also render it on the payment screen when the user has submitted their
	// payment
	return (
		<div className={classNames('post-payment__wrap', className)}>
			<SteeringWheelSpinner postPayment />
			<h2 className="post-payment__header">Hold tight!</h2>
			<h5 className="post-payment__subheader">Your payment is being processed</h5>
			<p className="post-payment__text">
				<strong>
					Do not exit this page
				</strong> until your payment has been confirmed. If you do not see the confirmation message
				and you have not received your confirmation email after 10 minutes please contact us.
			</p>
		</div>
	);
}
