import React, { useEffect, useState } from 'react';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import useAsync from 'Hooks/useAsync';
import alertToast from 'Util/ToastifyUtils';
import { FetchGivenFerryTrips } from 'Util/_HumanWritten/FerryTrips/FetchGivenTrips';
import TripBookingSummaryInner from '../WizardSteps/Cart/TripBookingSummaryInner';
import { SelectedTrips } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardWrap';
import { FerryTripEntity } from 'Models/Entities';
import {
	BookingWizardCartFields,
	BookingWizardData,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	FetchBookingsForCart,
} from 'Services/Api/_HumanWritten/BookingService/FerryTripBookingService';

export interface TripSummaryProps {
	wizardData: BookingWizardData;
	selectedTrips: SelectedTrips;
	onUpdateWizardData: (newData: BookingWizardData) => void;
	refresh?: () => void;
}

export function TripSummary({
	wizardData,
	selectedTrips,
	refresh,
	onUpdateWizardData,
}: TripSummaryProps) {
	const transactionId = getFerryBookingTransactionIdFromStorage();
	const [
		bulkBookingTripEntities,
		setBulkBookingTripEntities,
	] = useState<FerryTripEntity[]>([]);

	const response = useAsync(() => FetchBookingsForCart(
		transactionId,
		wizardData.userId,
	), [
		wizardData.departureTicketId,
		wizardData.returningTicketId,
		wizardData.userId,
	]);

	useEffect(() => {
		if (wizardData.bulkBookingTripIds !== undefined) {
			FetchGivenFerryTrips(wizardData.bulkBookingTripIds).then(trips => {
				setBulkBookingTripEntities(trips);
			});
		}
	}, []);

	// The trip booking summary card on the sidebar is set up for showing a list of booking wizard cart field objects.
	// It will do this by using wizard data and bookings which were passed in. We can bypass this by creating the cart
	// data here and passing it in. The wizard data it is expecting is for departing/returning trips, so to avoid
	// rewriting a lot of that logic we can use our wizard data with bulk trips to construct cart fields which have just
	// departing trips. One for each trip in our bulk booking trips object
	const bulkBookingData: BookingWizardCartFields[] = [];
	if (wizardData.bulkBookingTripIds !== undefined) {
		wizardData.bulkBookingTripIds.forEach(id => {
			const selectedTrip = bulkBookingTripEntities.find(x => x.id === id);

			if (selectedTrip !== undefined) {
				// The fields are the same for all wizard data we are creating here (Except for departing trip id)
				// because the other details are all identical
				const newWizardData: BookingWizardData = { ...wizardData };
				newWizardData.bulkBookingTripIds = undefined;
				newWizardData.departureTicketId = id;

				bulkBookingData.push({
					wizardData: newWizardData,
					selectedTrips: {
						departingTrip: selectedTrip,
					},
				});
			}
		});
	}

	if (response.type === 'loading' || response.type === 'error') {
		return <></>;
	}

	const bookingList = response.data;

	return (
		<div className="trip-summary-container">
			<h3>Cart</h3>
			<TripBookingSummaryInner
				bookings={bookingList}
				selectedTrips={selectedTrips}
				refresh={refresh ?? (() => {})}
				onUpdateWizardData={onUpdateWizardData}
				wizardData={wizardData}
				tripSummaryLocation="sidebar"
				removePreProcessedBooking={tripId => {
					const newData = { ...wizardData };
					if (wizardData.bulkBookingTripIds !== undefined) {
						newData.bulkBookingTripIds = wizardData.bulkBookingTripIds.filter(x => x !== tripId);
						onUpdateWizardData(newData);
						alertToast('Booking Removed', 'success');
					}
				}}
				preProcessedCartFields={bulkBookingData.length > 0 ? bulkBookingData : undefined}
			/>
		</div>
	);
}
