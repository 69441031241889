import React from 'react';
import { observer } from 'mobx-react';
import { CustomTicketTypeEntity } from 'Models/Entities';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import If from 'Views/Components/If/If';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { validateField } from 'Validators/Functions/HumanWritten/ValidateForm';
import { eventDetailsValidationRules } from 'Views/Components/_HumanWritten/Events/EventSideBar/ValidationRules';
import { isNullOrUndefined, stringIsEmpty, stringNotEmpty } from 'Util/TypeGuards';
import {
	EventSideBarProps,
	EventSideBarPropsErrors,
} from 'Views/Components/_HumanWritten/Events/EventSideBar/EventSideBar';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';

interface CustomTicketPricingTableProps {
	state: EventSideBarProps;
	model: CustomTicketTypeEntity[];
	onDelete: (ticketToRemove: CustomTicketTypeEntity) => void;
	isReadOnly?: boolean;
	setNeedConfirm?: (value: boolean) => void;
	errors: EventSideBarPropsErrors;
}

function CustomTicketPricingTable({
	state,
	model,
	onDelete,
	isReadOnly = false,
	setNeedConfirm,
	errors,
}: CustomTicketPricingTableProps) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableCell textAlign="left">Ticket name (singular)</TableCell>
					<TableCell textAlign="left">Ticket name (plural)</TableCell>
					<TableCell textAlign="left">Description</TableCell>
					<TableCell textAlign="left">Quantity per ticket</TableCell>
					<TableCell textAlign="left">Max capacity</TableCell>
					<TableCell textAlign="left">Cost</TableCell>
					<TableCell className="small-column" />
				</TableRow>
			</TableHeader>
			<TableBody>
				{model.map((x, index) => {
					return (
						<TableRow key={x.id}>
							<TableCell textAlign="left">
								<TextField
									className="price-input-field"
									id={`custom-ticket-singular-name__${x.singularName}`}
									model={x}
									modelProperty="singularName"
									isDisabled={isReadOnly}
									placeholder="Singular name"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={stringIsEmpty(x.singularName) && stringNotEmpty(errors.customTicketTypes)
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<TextField
									className="price-input-field"
									id={`custom-ticket-plural-name__${x.pluralName}`}
									model={x}
									modelProperty="pluralName"
									isDisabled={isReadOnly}
									placeholder="Plural name"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={stringIsEmpty(x.pluralName) && errors.customTicketTypes
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<TextField
									className="price-input-field"
									id={`custom-ticket-description__${x.pluralName}`}
									model={x}
									modelProperty="description"
									isDisabled={isReadOnly}
									placeholder="Description"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={(stringIsEmpty(x.description)) && errors.customTicketTypes
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field"
									id={`custom-ticket-quantity__${x.pluralName}`}
									model={x}
									modelProperty="quantity"
									isDisabled={isReadOnly}
									placeholder="Quantity"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={(isNullOrUndefined(x.quantity) || x.quantity <= 0) && errors.customTicketTypes
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field"
									id={`custom-ticket-max-capacity__${x.pluralName}`}
									model={x}
									modelProperty="maxCapacity"
									isDisabled={isReadOnly}
									placeholder="Max capacity"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={(isNullOrUndefined(x.maxCapacity) || x.maxCapacity <= 0) && errors.customTicketTypes
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field icon-money icon-left"
									id={`custom-ticket-price-${x.pluralName}`}
									model={x}
									modelProperty="price"
									isDisabled={isReadOnly}
									placeholder="0"
									isRequired
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
										validateField(state.eventDetails!, 'customTicketTypes', errors, eventDetailsValidationRules);
									}}
									errors={(isNullOrUndefined(x.price) || x.price < 0) && errors.customTicketTypes
										? errors.customTicketTypes
										: ''}
								/>
							</TableCell>
							<TableCell textAlign="left">
								<If condition={index > 0 && !isReadOnly}>
									<Button
										colors={Colors.Black}
										display={Display.Text}
										icon={{ icon: 'cross', iconPos: 'icon-top' }}
										onClick={() => onDelete(x)}
									/>
								</If>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}

export default observer(CustomTicketPricingTable);
