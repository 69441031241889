import RouteMapRenderableObject, { RouteMapRenderableType }
	from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapObjects/RouteMapRenderableObject';
import { RouteEntity } from 'Models/Entities';
import RouteMapHandler, {
	ILocationAndRoutes,
	RouteData,
} from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapHandler';
import RouteMapCanvas from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapCanvas';
import RouteMapProjection from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapProjection';
import RouteMapPoint from 'Views/Components/RouteMap/RouteMapCanvas/RouteMapPrimitives/RouteMapPoint';
import { themeStore } from 'Models/ThemeStore';
import { clamp } from 'lodash';

const DURATION = 2000;

const LINE_WIDTH = 20;
const LINE_DASH_LENGTH = 50;
const LINE_GAP_LENGTH = 50;

const LINE_COLOUR = themeStore?.config?.brandColourPrimary ?? '#0180b5';

export default class RouteMapLine implements RouteMapRenderableObject {
	private readonly foregroundCanvas: RouteMapCanvas;

	private projectionHandler: RouteMapProjection;

	private to: RouteMapPoint;
	private from: RouteMapPoint;

	private startTime?: number = undefined;
	private finished: boolean = false;

	private projectedTo: RouteMapPoint;
	private projectedFrom: RouteMapPoint;

	constructor(mapHandler: RouteMapHandler) {
		this.foregroundCanvas = mapHandler.getForegroundCanvas();
		this.projectionHandler = mapHandler.getProjection();
	}

	getType(): RouteMapRenderableType {
		return 'line';
	}

	updateScale(): void {
		this.projectedTo = this.projectionHandler.projectPoint(this.to);
		this.projectedFrom = this.projectionHandler.projectPoint(this.from);
	}

	update(_route: RouteEntity, _locationsAndRoutes: ILocationAndRoutes, routeData: RouteData) {
		const { from, to } = routeData;
		this.to = to;
		this.from = from;

		this.startTime = undefined;

		this.updateScale();
	}

	render(timestamp: number): boolean {
		const ctx = this.foregroundCanvas.getContext();

		if (!this.startTime) {
			this.startTime = timestamp;
			this.finished = false;
		}

		const progress = this.finished
			? 1
			: this.cubicBezier((timestamp - this.startTime) / DURATION, 0.42, 0, 0.58, 1);

		ctx.save();

		ctx.lineWidth = this.projectionHandler.scaleSize(LINE_WIDTH);
		ctx.lineCap = 'round';
		ctx.strokeStyle = LINE_COLOUR;

		const to = this.projectedTo;
		const from = this.projectedFrom;

		const ax = from.getX();
		const ay = from.getY();
		const bx = to.getX();
		const by = to.getY();

		// Keep the old control points for now
		// const controlX = Math.min(from.getX(), to.getX()) + (from.getX() + to.getX()) / 5;
		// const controlY = Math.min(from.getY(), to.getY()) + (from.getY() - to.getY()) / 5;

		const squashCurveBy = this.projectionHandler.scaleSize(150);
		let controlX = (ax + bx) / 2 + (by - ay) / 2;
		let controlY = (ay + by) / 2 - (bx - ax) / 2;

		if (controlX > (ax + bx) / 2) {
			controlX -= squashCurveBy;
		} else {
			controlX += squashCurveBy;
		}

		if (controlY > (ay + by) / 2) {
			controlY -= squashCurveBy;
		} else {
			controlY += squashCurveBy;
		}

		this.drawCurvedDashedLine(
			from.getX(),
			from.getY(),
			to.getX(),
			to.getY(),
			controlX,
			controlY,
			this.projectionHandler.scaleSize(LINE_GAP_LENGTH),
			this.projectionHandler.scaleSize(LINE_DASH_LENGTH),
			progress,
		);

		ctx.restore();

		if (progress >= 1) {
			this.finished = true;
		}

		return !this.finished;
	}

	private drawCurvedDashedLine(
		startX: number,
		startY: number,
		endX: number,
		endY: number,
		controlX: number,
		controlY: number,
		dashLength = 5,
		gapLength = 5,
		progress = 1) {
		const ctx = this.foregroundCanvas.getContext();
		const currentProgress = clamp(progress, 1);

		ctx.beginPath();
		ctx.moveTo(startX, startY);

		const totalLength = Math.floor(this.getCurveLength(startX, startY, endX, endY, controlX, controlY));
		const dashGapLength = dashLength + gapLength;

		const currentLength = totalLength * currentProgress;

		for (let i = 0; i < currentLength; i += dashGapLength) {
			const t1 = i / totalLength;
			const t2 = Math.min((i + dashLength) / totalLength, currentProgress);

			const x1 = this.quadraticBezier(t1, startX, controlX, endX);
			const y1 = this.quadraticBezier(t1, startY, controlY, endY);
			const x2 = this.quadraticBezier(t2, startX, controlX, endX);
			const y2 = this.quadraticBezier(t2, startY, controlY, endY);

			ctx.moveTo(x1, y1);
			ctx.lineTo(x2, y2);
		}

		ctx.stroke();
	}

	private quadraticBezier(t: number, p0: number, p1: number, p2: number) {
		return (1 - t) * (1 - t) * p0 + 2 * (1 - t) * t * p1 + t * t * p2;
	}

	private getCurveLength(x1: number, y1: number, x2: number, y2: number, cx: number, cy: number) {
		const steps = 100;
		let length = 0;
		let prevX = x1;
		let prevY = y1;

		for (let i = 1; i <= steps; i++) {
			const t = i / steps;
			const x = this.quadraticBezier(t, x1, cx, x2);
			const y = this.quadraticBezier(t, y1, cy, y2);
			length += Math.sqrt((x - prevX) * (x - prevX) + (y - prevY) * (y - prevY));
			prevX = x;
			prevY = y;
		}

		return length;
	}

	private cubicBezier(t: number, p1x: number, p1y: number, p2x: number, p2y: number) {
		const cx = 3 * p1x;
		const bx = 3 * (p2x - p1x) - cx;
		const ax = 1 - cx - bx;

		const cy = 3 * p1y;
		const by = 3 * (p2y - p1y) - cy;
		const ay = 1 - cy - by;

		return (ay * t * t * t + by * t * t + cy * t) * 2;
	}
}
