import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import classNames from 'classnames';
import If from 'Views/Components/If/If';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export interface CheckInMoveButtonProps {
	className?: string;
	/**
	 * id helps with test selectors i.e. action-btn-{id}
	 */
	id?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	iconOnly?: boolean;
}

export function CheckInMoveButton({
	id,
	className,
	onClick,
	iconOnly = false,
}: CheckInMoveButtonProps) {
	return (
		<Button
			buttonProps={{ id: `action-btn-${id}` }}
			className={classNames(className)}
			onClick={onClick}
		>
			<Icon name="corner-down-right" />
			<If condition={!iconOnly}>
				Move
			</If>
		</Button>
	);
}
