import { Action } from 'history';
import { store } from 'Models/Store';

export function route(location: any, action: Action) {
	switch (action) {
		case 'PUSH':
			store.routerHistory.push(location);
			break;
		case 'REPLACE':
			store.routerHistory.replace(location);
			break;
		case 'POP':
			store.routerHistory.goBack();
	}
}
