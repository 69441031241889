import * as React from 'react';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

export interface CheckInTabLabelProps {
	id?: string;
	iconName?: string;
	content?: React.ReactNode;
}

export function CheckInTabLabel({ id, iconName, content }: CheckInTabLabelProps) {
	return (
		<div id={id} className="check-in__tab-label">
			{iconName && <Icon name={iconName} />}
			{content}
		</div>
	);
}
