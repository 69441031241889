import React from 'react';
import { Text } from 'Views/Components/Text/Text';

interface DeleteFerryTripBookedBookingsTextProps {
	count?: number;
}

function DeleteFerryTripBookedBookingsText({ count }: DeleteFerryTripBookedBookingsTextProps) {
	if (count === 0) {
		return (
			<Text color="gray">
				Currently no bookings in this trip.
			</Text>
		);
	}

	if (!count) {
		return null;
	}

	const message = count > 1
		? <>There are <strong>{count}</strong> booked bookings in this trip.</>
		: <>There is <strong>{count}</strong> booked booking in this trip.</>;

	return <Text color="gray">{message}</Text>;
}

export default DeleteFerryTripBookedBookingsText;
