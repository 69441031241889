import * as React from 'react';
import If from 'Views/Components/If/If';
import { isNotNullOrUndefined, isNullOrUndefined } from 'Util/TypeGuards';
import { observer, useLocalStore } from 'mobx-react';
import classNames from 'classnames';
import alertToast from 'Util/ToastifyUtils';
import { Badge, BadgeColor } from '../../FerryCheckIn/CheckInTable/Badge';
import EventBookingFormGroupHeader from './EventBookingFormGroupHeader';
import { transformEmail, transformMobileNumber } from '../EventCheckInUtils';
import { EventCheckInBookingOverviewDto } from '../EventCheckInEntities/EventCheckInOverviewDto';
import EventBookingFormViewTickets from './EventBookingFormViewPassengers';
import { EventCheckInOrUndoButton } from '../EventCheckInButtons/EventCheckInOrUndoButton';
import { runInAction } from 'mobx';

interface EventBookingFormFieldProps {
	label: string;
	value: string;
	customValue?: React.ReactNode;
}

function EventBookingFormField({
	label,
	value,
	customValue,
}: EventBookingFormFieldProps) {
	return (
		<div className="booking-form__field">
			<div className="booking-form__field--label">
				{label}
			</div>
			<If condition={isNotNullOrUndefined(customValue)}>
				{customValue}
			</If>
			<If condition={isNullOrUndefined(customValue)}>
				<div className="booking-form__field--value">
					{value}
				</div>
			</If>
		</div>
	);
}

export interface EventBookingFormProps {
	booking: EventCheckInBookingOverviewDto;
	checkIn?: () => Promise<boolean>;
	className?: string;
}

/**
 * It was decided it is easier to manage view/edit/add mode using two components.
 *
 * One for VIEW and one for EDIT/ADD. This would produce more readable code.
 */
function EventBookingFormView({
	booking,
	checkIn,
	className,
}: EventBookingFormProps) {
	const localState = useLocalStore(() => ({
		checkedIn: booking.checkedIn,
	}));

	if (isNullOrUndefined(booking.bookedSummary)) {
		alertToast('Could not find event booking', 'error');
		return <></>;
	}
	const {
		bookedSummary,
		transactionStatus,
	} = booking;

	return (
		<div className={classNames('booking-form', className)}>
			<div className="booking-form__title">
				<div className="text">
					{booking.getFullName()}
				</div>
				<If condition={isNotNullOrUndefined(checkIn)}>
					<EventCheckInOrUndoButton
						iconOnly
						id={booking.id}
						className={`check-in-btn ${!localState.checkedIn ? 'green-btn' : 'grey-btn'}`}
						forCheckIn={!localState.checkedIn}
						onClick={async () => {
							if (checkIn) {
								const response = await checkIn();
								if (response) {
									runInAction(() => {
										localState.checkedIn = !localState.checkedIn;
									});
								}
							}
						}}
					/>
				</If>
			</div>
			<If condition={transactionStatus === 'AWAITING'
				|| transactionStatus === 'INVOICED'}
			>
				<div className="booking-form__badges">
					<If condition={isNotNullOrUndefined(transactionStatus)
						&& (transactionStatus === 'AWAITING'
							|| transactionStatus === 'INVOICED')}
					>
						<Badge
							text={transactionStatus ?? ''}
							color={transactionStatus === 'AWAITING' ? BadgeColor.Awaiting : BadgeColor.Invoiced}
						/>
					</If>
					<If condition={isNotNullOrUndefined(transactionStatus)
						&& transactionStatus === 'CANCELLED'}
					>
						<Badge
							text={transactionStatus ?? 'CANCELLED'}
							color={BadgeColor.Cancelled}
						/>
					</If>
					<If condition={isNotNullOrUndefined(booking?.user?.userDisplayName)}>
						<Badge text={booking?.user?.userDisplayName?.displayName} />
					</If>
				</div>
			</If>
			<EventBookingFormGroupHeader title="Details">
				<EventBookingFormField
					label="Phone"
					value={transformMobileNumber(booking)}
					customValue={
						(
							<a
								href={`tel:${transformMobileNumber(booking)}`}
								className="booking-form__field--value hide-underline"
							>
								{transformMobileNumber(booking)}
							</a>
						)
					}
				/>
				<EventBookingFormField
					label="Email"
					value={transformEmail(booking)}
				/>
			</EventBookingFormGroupHeader>
			<EventBookingFormGroupHeader title="Tickets" id="passengers-group">
				<div>
					<EventBookingFormViewTickets summary={booking.bookedSummary} />
				</div>
			</EventBookingFormGroupHeader>
		</div>
	);
}

export default observer(EventBookingFormView);
