import { ComboboxOption } from '../Views/Components/Combobox/Combobox';
import { IWhereCondition } from '../Views/Components/ModelCollection/ModelQuery';
import { UserEntity } from '../Models/Entities';
import { isNotNullOrUndefined } from './TypeGuards';
import { fetchUsers, UserType } from './FetchUsers';
import { getLikeArgument } from './FetchUtils';
import { fetchUserById } from '../Services/Api/_HumanWritten/UserService';

/**
 * Takes a list of entities, and returns an array of options which can be supplied to a combobox.  The display and value
 * fields are determined based on properties which are passed in, so those variables need to correspond to properties on
 * the entity type which has been supplied
 *
 * @param entities The entities to transform into combobox props
 * @param displayTransformer A string to represents the string field to access from the entity, or a callback that will
 * transform the entity into a string.
 * @param valueTransformer A string to represent the field to access from the entity, or a callback that will transform
 * the entity into V.
 * @param disabledTransformer A string to represent the field to access form the entity.
 */
export function getComboboxOptions<T, V>(
	entities: T[],
	displayTransformer: ((entity: T) => string) | string,
	valueTransformer: ((entity: T) => V) | string,
	disabledTransformer?: ((entity: T) => boolean) | string,
	descriptionTransformer?: ((entity: T) => string) | string,
): ComboboxOption<V>[] {
	const display = (x: T) => {
		if (typeof displayTransformer === 'string') {
			return x[displayTransformer];
		}
		return displayTransformer(x);
	};

	const value = (x: T): V => {
		if (typeof valueTransformer === 'string') {
			return x[valueTransformer];
		}
		return valueTransformer(x);
	};

	const disabled = (x: T): boolean => {
		if (typeof disabledTransformer === 'string') {
			return x[disabledTransformer];
		}
		if (disabledTransformer) {
			return disabledTransformer(x);
		}

		return false;
	};

	const description = (x: T): string | null => {
		if (typeof descriptionTransformer === 'string') {
			return x[descriptionTransformer];
		}
		if (descriptionTransformer) {
			return descriptionTransformer(x);
		}

		return null;
	};

	return entities.map(entity => {
		return {
			display: display(entity),
			value: value(entity),
			disabled: disabled(entity),
			description: description(entity),
		};
	});
}

/* ----------------------------------------- Customer combobox options ----------------------------------------- */

/**
 * Gets the search arguments for a customer search. The search args can be used to fetch entities which are used to
 * populate the options for staff to select a customer to assign a booking to (#38)
 *
 * This was split out from the function itself because testing this individually is the best way to ensure our
 * acceptance criteria have been met
 *
 * @param searchTerm the text entered into the combobox search box
 * @param currentlySelectedId the id of the user currently selected in the combobox. They must be included in the
 * options to avoid unintended issues
 */
export function getCustomerComboboxSearchArgs(
	searchTerm?: string,
	currentlySelectedId?: string,
) {
	const searchArgs: IWhereCondition<UserEntity>[][] = [];

	if (isNotNullOrUndefined(searchTerm) && searchTerm !== '') {
		// We need to be able to search by a full name. If we don't want to have to add additional server logic, then
		// we need to split the search term by spaces (As this is the usual delimiter between names) and check that the
		// names match either first or last name on the user

		// If we have entered this loop, then there is text at the start of the search box. Therefore, we know names[0]
		// will always be defined
		searchArgs.push([
			getLikeArgument('fullName', searchTerm),
			getLikeArgument('phone', searchTerm),
			getLikeArgument('email', searchTerm),
		]);
	}

	if (!!currentlySelectedId) {
		// Adding this to our search arguments ensures that we don't fetch the current selection more than once, as the
		// currently selected user is being fetched elsewhere
		searchArgs.push([
			{
				path: 'id',
				comparison: 'equal',
				negate: true,
				value: currentlySelectedId,
			},
		]);
	}

	return searchArgs;
}

/**
 * Fetches a list of customers based on the search term and turns them into a list of combobox options
 *
 * @param searchTerm the text used to filter entities by. Entities can be filtered by their name, email address, or
 * phone number
 * @param currentlySelectedId the id of the currently selected user
 * @param take the number of entities to show in the list
 */
export async function fetchCustomerComboboxOptions(
	searchTerm?: string,
	currentlySelectedId?: string,
	take: number = 10,
) {
	const searchArgs: IWhereCondition<UserEntity>[][] = getCustomerComboboxSearchArgs(searchTerm, currentlySelectedId);
	let currentSelection: UserEntity | null = null;

	// If there is a current selection, it HAS to be included in the returned fields here.  I can't see a way to get
	// this with a single query, so we need to perform two queries.  One to get the current selection, and then one to
	// get the rest of the entities to populate the list
	let assignableTake: number = take;

	if (!!currentlySelectedId) {
		currentSelection = await fetchUserById(currentlySelectedId);
		// The number of entities that we wanted to fetch hasn't changed, but we've got one of our entities to return
		// already, so our later fetch doesn't need as many
		assignableTake -= 1;
	}

	let { data } = await fetchUsers(UserType.CUSTOMER, searchArgs, assignableTake);

	// In this scenario, we have performed our 2 queries, and need to add the results together into a single array. We
	// put the current selection first. This block will also prevent us from adding in the current selection if the id
	// supplied to the function was not a customer
	if (currentSelection !== null) {
		data = [currentSelection, ...data];
	}

	return getComboboxOptions<UserEntity, string>(
		data,
		(user: UserEntity) => `${user.firstName} ${user.lastName} (${user.email})`,
		'id',
		'disabled',
	);
}
