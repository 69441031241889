/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsSmsConfigEntity from 'Models/Security/Acl/VisitorsSmsConfigEntity';
import UserSmsConfigEntity from 'Models/Security/Acl/UserSmsConfigEntity';
import StaffSmsConfigEntity from 'Models/Security/Acl/StaffSmsConfigEntity';
import ManagerSmsConfigEntity from 'Models/Security/Acl/ManagerSmsConfigEntity';
import AdminSmsConfigEntity from 'Models/Security/Acl/AdminSmsConfigEntity';
import InvoicedUserSmsConfigEntity from 'Models/Security/Acl/InvoicedUserSmsConfigEntity';
import FixedPriceUserSmsConfigEntity from 'Models/Security/Acl/FixedPriceUserSmsConfigEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ISmsConfigEntityAttributes extends IModelAttributes {
	allowAutomatedSMS: boolean;
	reminderSmsHoursBeforeDeparture: boolean;
	sendToUnverifiedNumbers: boolean;

	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('SmsConfigEntity', 'Sms Config')
// % protected region % [Customise your entity metadata here] end
export default class SmsConfigEntity extends Model
	implements ISmsConfigEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsSmsConfigEntity(),
		new UserSmsConfigEntity(),
		new StaffSmsConfigEntity(),
		new ManagerSmsConfigEntity(),
		new AdminSmsConfigEntity(),
		new InvoicedUserSmsConfigEntity(),
		new FixedPriceUserSmsConfigEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Allow Automated SMS'] off begin
	/**
	 * Whether or not to allow automated SMS for the application.
	 */
	@observable
	@attribute<SmsConfigEntity, boolean>()
	@CRUD({
		name: 'Allow Automated SMS',
		displayType: 'checkbox',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public allowAutomatedSMS: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Allow Automated SMS'] end

	// % protected region % [Modify props to the crud options here for attribute 'Reminder Sms Hours Before Departure'] off begin
	/**
	 * The hours before a ferry departs that the SMS reminder should send.
	 */
	@Validators.Required()
	@observable
	@attribute<SmsConfigEntity, boolean>()
	@CRUD({
		name: 'Reminder Sms Hours Before Departure',
		displayType: 'checkbox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public reminderSmsHoursBeforeDeparture: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Reminder Sms Hours Before Departure'] end

	// % protected region % [Modify props to the crud options here for attribute 'Send To Unverified Numbers'] off begin
	/**
	 * Whether or not to send to unverified phon numbers. The default will be no.
	 */
	@observable
	@attribute<SmsConfigEntity, boolean>()
	@CRUD({
		name: 'Send To Unverified Numbers',
		displayType: 'checkbox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public sendToUnverifiedNumbers: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Send To Unverified Numbers'] end

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ISmsConfigEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ISmsConfigEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.allowAutomatedSMS !== undefined) {
				this.allowAutomatedSMS = attributes.allowAutomatedSMS;
			}
			if (attributes.reminderSmsHoursBeforeDeparture !== undefined) {
				this.reminderSmsHoursBeforeDeparture = attributes.reminderSmsHoursBeforeDeparture;
			}
			if (attributes.sendToUnverifiedNumbers !== undefined) {
				this.sendToUnverifiedNumbers = attributes.sendToUnverifiedNumbers;
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(SmsConfigEntity.prototype, 'created');
CRUD(modifiedAttr)(SmsConfigEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
