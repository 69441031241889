import * as React from 'react';
import { observer } from 'mobx-react';
import useScreenSize from 'Hooks/useScreenSize';
import CheckInTopNav from '../CheckInTopNav';
import CheckInTopNavMobile from '../CheckInTopNavMobile';
import CheckInMoveContent from './CheckInMoveContent';

function CheckInMove() {
	const { isMobile } = useScreenSize();
	return (
		<div id="check-in-body" className="body-content check-in">
			{
				isMobile
					? <CheckInTopNavMobile />
					: <CheckInTopNav showButtons={false} />
			}
			<CheckInMoveContent />
		</div>
	);
}

export default observer(CheckInMove);
