import * as React from 'react';
import { Button } from 'Views/Components/Button/Button';
import * as uuid from 'uuid';
import If from 'Views/Components/If/If';
import Modal from 'Views/Components/Modal/Modal';
import { FerryTripEntity } from 'Models/Entities';

export interface BulkBookingSpaceModalProps {
	showModal: boolean;
	onClickSelect: () => void;
	bulkFerryTrips: FerryTripEntity[] | null;
	bulkBookingBookingIds: string[] | null;
}

export function BulkBookingSpaceModal(props: BulkBookingSpaceModalProps) {
	const { showModal } = props;
	if (!showModal) {
		return <></>;
	}

	return (
		<Modal
			isOpen
			label="You no longer fit on this ferry"
		>
			<BulkBookingSpaceModalContents {...props} />
		</Modal>
	);
}

export function BulkBookingSpaceModalContents({
	onClickSelect,
	bulkFerryTrips,
}: BulkBookingSpaceModalProps) {
	return (
		<>
			<div className="space-modal__wrap">
				<div className="space-modal__header">
					<h5 className="space-modal__title">
						Bulk booking could not be completed
					</h5>
					<Button
						className="space-modal__cross-btn"
						icon={{ icon: 'cross', iconPos: 'icon-top' }}
						onClick={onClickSelect}
					/>
				</div>
				<div className="space-modal--body">
					<p className="space-modal__body-text" key={uuid.v4()}>
						The following selected trips did not have enough space to complete the booking:
					</p>
					<If condition={bulkFerryTrips !== null}>
						<div className="space-modal__bulk-booking-trips">
							{bulkFerryTrips?.map(x => {
								return (
									<p>
										{x.getTripTimeFormatted()}, {x?.getFormattedTripDateLong()}
									</p>
								);
							})}
						</div>
					</If>
				</div>
			</div>
			<Button className="space-modal__button" onClick={onClickSelect}>
				<div className="space-modal__button-contents">
					<p>
						Select new trips
					</p>
					<span className="btn--icon icon-arrow-right icon-right" />
				</div>
			</Button>
		</>
	);
}
