import { checkIfInvalidName } from 'Util/StringUtils';
import { UserEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import { isNotNullOrUndefined, isNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import { store } from 'Models/Store';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { doesUserExistViaEmail, doesUserExistViaMobile } from 'Services/Api/AccountService';
import { isEmail } from 'Validators/Functions/Email';
import { IsValidPhone } from 'Validators/Functions/HumanWritten/Phone';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';

export function validateForm(
	user: UserEntity,
	specialState: {
		email: string,
		phone: string,
	},
) {
	const newErrors: FormErrors<UserEntity> = {};

	if (stringIsEmpty(user.firstName)) {
		newErrors.firstName = 'First name required';
	}
	if (checkIfInvalidName(user.firstName)) {
		newErrors.firstName = 'Enter a valid first name (a-z, \'-\', no spaces)';
	}
	if (stringIsEmpty(user.lastName)) {
		newErrors.lastName = 'Last name required';
	}
	if (checkIfInvalidName(user.lastName)) {
		newErrors.firstName = 'Enter a valid last name (a-z, \'-\', no spaces)';
	}
	if (stringIsEmpty(specialState.email)) {
		newErrors.email = 'Email required';
	} else if (!isEmail(specialState.email)) {
		newErrors.email = 'Enter a valid email address (xx@xx.xx)';
	}
	if (stringIsEmpty(specialState.phone)) {
		newErrors.phone = 'Phone required';
	} else if (!IsValidPhone(specialState.phone)) {
		newErrors.phone = 'Enter a valid phone';
	}
	if (stringIsEmpty(user.postcode)) {
		newErrors.postcode = 'Postcode required';
	}

	// Validation for pricing and usertype
	if (isNotNullOrUndefined(user.userDisplayNameId) && store.isManager) {
		for (const passengerType of passengerTypeStore.passengerTypes) {
			const key = passengerType.passengerTypeKey;
			const modelProperty = passengerTypeStore.getPriceProperty(key, user);
			if (!passengerType.disabled && stringIsEmpty(user[modelProperty])) {
				newErrors.adultPrice = 'Required';
				break;
			}
		}

		for (const x of user.prices) {
			if (isNullOrUndefined(x.amount)) {
				newErrors.prices = 'Required';
				break;
			}
		}

		for (const x of user.addOnsPrices) {
			if (isNullOrUndefined(x.price)) {
				newErrors.addOnsPrices = 'Required';
				break;
			}
		}
	}

	return newErrors;
}

export async function isPhoneValid(orignalPhone: string, newPhone: string) {
	if (!IsValidPhone(newPhone)) {
		alertToast('Please enter a valid mobile', 'error', 'Invalid mobile');
		return false;
	}
	if (orignalPhone !== newPhone && await doesUserExistViaMobile(newPhone)) {
		alertToast('Mobile number is already taken', 'error', 'Invalid mobile');
		return false;
	}
	return true;
}

export async function isEmailValid(originalEmail: string, newEmail: string) {
	if (!isEmail(newEmail)) {
		alertToast('Please enter a valid email', 'error', 'Invalid email');
		return false;
	}
	if (originalEmail !== newEmail && await doesUserExistViaEmail(newEmail)) {
		alertToast('Email address is already in use', 'error', 'Invalid email');
		return false;
	}
	return true;
}
