import * as React from 'react';
import classNames from 'classnames';

interface IssueIconProps {
	className?: string;
}

/**
 * This icon uses a reference to the issue.svg file from images folder.
 */
export function IssueIcon({ className }: IssueIconProps) {
	return <div className={classNames('issue-icon', className)} />;
}
