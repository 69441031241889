import { EventDetailEntity, FerryTripEntity } from 'Models/Entities';
import { IWhereCondition } from 'Views/Components/ModelCollection/ModelQuery';
import { getEvent } from './FetchEventsUtils';
import { store } from '../../../Models/Store';
import { GetAllEvents } from '../../../Services/Api/_HumanWritten/EventDetailEntityService';
import { IEventDetailEntityAttributes } from 'Models/Entities/EventDetailEntity';

export async function FetchEvents(skip: number): Promise<EventDetailEntity[]> {
	try {
		const results = await GetAllEvents(skip);
		const events = results.map((x: Partial<IEventDetailEntityAttributes> | undefined) => new EventDetailEntity(x));
		return events;
	} catch (e) {
		console.error(e);
		return [];
	}
}
