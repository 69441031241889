import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SearchResults } from './SearchResults';
import { UserEntity } from '../../../../Models/Entities';
import alertToast from '../../../../Util/ToastifyUtils';
import useStore from 'Hooks/useStore';
import { fetchUserById } from '../../../../Services/Api/_HumanWritten/UserService';

export function SearchResultsWrap() {
	const store = useStore();
	const { id } = useParams<{ id: string }>();
	const [user, setUser] = useState<UserEntity | null>(null);

	useEffect(() => {
		fetchUserById(id).then(entity => {
			setUser(entity);
		}).catch(err => {
			alertToast('Could not fetch user', 'error');
			setUser(null);
		});
	}, [id]);

	return (
		<SearchResults
			user={user}
			onClickView={() => {
				store.routerHistory.push(`/staff-account-details/${id}`);
			}}
		/>
	);
}
