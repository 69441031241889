const locationsSessionStorage = 'ferry-schedule-selected-route';
export function getFerryScheduleSettingsFromStorage(): string | null {
	return sessionStorage?.getItem(locationsSessionStorage);
}

export function setFerryScheduleSettingsInStorage(routeId: string) {
	sessionStorage.setItem(
		locationsSessionStorage,
		routeId,
	);
}
