import * as React from 'react';
import passengerTypeStore from '../../../../../../../Models/PassengerTypeStore';
import InputCounter from '../../../../PassengerAmount/InputCounter';
import { BookingWizardData } from '../../../BookingWizardData';
import { PassengerTypeKey } from '../../../../../../../Models/Enums';
import { whiteLabelStore } from '../../../../../../../Models/WhiteLabelStore';
import { UpdateWizardData } from './FerryFilterPassengers';

export interface PassengerTypeInputsProps {
	wizardData: BookingWizardData;
	onUpdateFilters: UpdateWizardData;
}

export function PassengerTypeInputs({
	wizardData,
	onUpdateFilters,
}: PassengerTypeInputsProps) {
	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const key = x.passengerTypeKey;
				const propertyName = passengerTypeStore.getWizardDataProperty(key);

				const title = passengerTypeStore.getPluralName(key);
				const subtitle = passengerTypeStore.getDescription(key);
				const amount = wizardData[propertyName];
				const tooltip = passengerTypeStore.getTooltip(key);
				const subtractDisabled = isSubtractDisabled(wizardData, key);

				return (
					<InputCounter
						key={x.id}
						className={key}
						title={title}
						subtitle={subtitle}
						tooltip={tooltip}
						amount={amount}
						subtractDisabled={subtractDisabled}
						onSubtract={() => onPassengerSubtract(wizardData, onUpdateFilters, key)}
						onAdd={() => onPassengerAdd(wizardData, onUpdateFilters, key)}
					/>
				);
			})}
		</>
	);
}

function isSubtractDisabled(wizardData: BookingWizardData, key: PassengerTypeKey) {
	const { tabSelected } = wizardData;
	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengerCount = wizardData[propertyName];

	if (key === 'A' && tabSelected === 'vehicle') {
		return passengerCount <= whiteLabelStore.minAdultsForVehicle;
	}

	return passengerCount <= 0;
}

function onPassengerSubtract(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData, key: PassengerTypeKey) {
	const newData: BookingWizardData = { ...wizardData };

	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengerCount = newData[propertyName];
	newData[propertyName] = passengerCount - 1;

	onUpdateFilters(newData);
}

function onPassengerAdd(wizardData: BookingWizardData, onUpdateFilters: UpdateWizardData, key: PassengerTypeKey) {
	const newData: BookingWizardData = { ...wizardData };

	const propertyName = passengerTypeStore.getWizardDataProperty(key);
	const passengerCount = newData[propertyName];
	newData[propertyName] = passengerCount + 1;

	onUpdateFilters(newData);
}
