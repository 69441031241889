import React from 'react';
import classNames from 'classnames';
import { Button } from 'Views/Components/Button/Button';
import { upperCaseFirst } from 'Util/StringUtils';
import { BookingWizardTab } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizard';

export interface ProgressBarLinkProps {
	tab: BookingWizardTab;
	totalTabCount: number
	index: number;
	completedSection: boolean;
	onClickTab: () => void;
	isMobile: boolean;
}

function waitForElement(selector: string): Promise<Element> {
	return new Promise<Element>((resolve, _) => {
		const el = document.querySelector(selector);
		if (!!el) {
			resolve(el);
			return;
		}
		new MutationObserver((mutationRecords, observer) => {
			// Query for elements matching the specified selector
			Array.from(document.querySelectorAll(selector)).forEach(element => {
				resolve(element);
				// Once we have resolved we don't need the observer anymore.
				observer.disconnect();
			});
		})
			.observe(document.documentElement, {
				childList: true,
				subtree: true,
			});
	});
}

export function FerryProgressBarLink({
	tab,
	totalTabCount,
	index,
	completedSection,
	onClickTab,
}: ProgressBarLinkProps) {
	const baseItemClassName = 'booking-wizard-progress-bar-item';
	const baseLinkClassName = 'progress-bar-item-link';
	let progressBarItemStatusClass = `${baseItemClassName}-disabled`;
	let progressBarItemLinkClass = `${baseLinkClassName}-disabled`;

	if (completedSection) {
		if (index === 0 && totalTabCount === 1) {
			waitForElement(`#${tab.name}`).then(element => {
				element.scrollIntoView({
					block: 'start',
					inline: 'start',
				});
			}).catch(_ => {});
			progressBarItemStatusClass = `${baseItemClassName}-selected-first`;
			progressBarItemLinkClass = `${baseLinkClassName}-selected-first`;
		} else if (index === totalTabCount - 1) {
			waitForElement(`#${tab.name}`).then(element => {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
					inline: 'start',
				});
			}).catch(_ => {});
			// Last of the completed steps === the current step
			progressBarItemStatusClass = `${baseItemClassName}-selected`;
			progressBarItemLinkClass = `${baseLinkClassName}-selected`;
		} else {
			progressBarItemStatusClass = `${baseItemClassName}-completed`;
			progressBarItemLinkClass = `${baseLinkClassName}-completed`;
		}
	}

	const listItemClasses = classNames('booking-wizard-progress-bar-item', progressBarItemStatusClass);
	const linkClasses = classNames(
		'hide-underline',
		baseLinkClassName,
		progressBarItemLinkClass,
		'hide-button-style',
	);

	return (
		<li className={listItemClasses} id={tab.name}>
			{
				completedSection
					? (
						<Button
							className={linkClasses}
							onClick={() => {
								onClickTab();
							}}
							disabled={!completedSection}
						>
							{upperCaseFirst(tab.displayName.toLowerCase())}
						</Button>
					)
					: <div className={linkClasses}>{upperCaseFirst(tab.displayName.toLowerCase())}</div>
			}
		</li>
	);
}
