import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import NavigationWrapper from 'Views/Components/Navigation/NavigationWrapper';
import Reports from 'Views/Components/_HumanWritten/Reports/Reports';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { REPORTS_PAGE_TITLE } from '../../ConstantPageNames';

export default function ReportsPage(props: RouteComponentProps) {
	document.title = REPORTS_PAGE_TITLE;

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<NavigationWrapper className="reports-page__nav" {...props} />
			<div className="body-content">
				<Reports />
			</div>
		</SecuredPage>
	);
}
