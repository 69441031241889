import React from 'react';
import { observer } from 'mobx-react';
import { FerryTripEntity, UserEntity } from 'Models/Entities';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';
import {
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
} from 'semantic-ui-react';
import If from 'Views/Components/If/If';
import { isNullOrUndefined } from 'Util/TypeGuards';
import { FormErrors } from 'Views/Components/_HumanWritten/InvoicedUserForm/InvoicedUserFormValidation';

interface MeasurementsTableProps {
	model: UserEntity | FerryTripEntity;
	isReadOnly?: boolean;
	accountsPage?: boolean;
	setNeedConfirm?: (value: boolean) => void;
	errors?: FormErrors<UserEntity>;
}

function MeasurementsTable({
	model,
	isReadOnly = false,
	accountsPage = false,
	setNeedConfirm,
	errors,
}: MeasurementsTableProps) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableCell textAlign="left">Ticket type</TableCell>
					<If condition={!accountsPage}>
						<TableCell textAlign="left">Space units</TableCell>
					</If>
					<TableCell textAlign="left">Cost</TableCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{model.prices
					.slice()
					.sort((value1, value2) => (value1.measurement.value > value2.measurement.value ? 1 : -1))
					.map(price => (
						<TableRow key={price.id}>
							<TableCell textAlign="left">{price.measurement.label}</TableCell>
							<If condition={!accountsPage}>
								<TableCell textAlign="left">{price.measurement.value}</TableCell>
							</If>
							<TableCell textAlign="left">
								<NumberTextField
									className="price-input-field icon-money icon-left"
									id={price.id}
									model={price}
									modelProperty="amount"
									isDisabled={isReadOnly}
									placeholder="0"
									onAfterChange={() => {
										if (setNeedConfirm) {
											setNeedConfirm(true);
										}
									}}
									errors={isNullOrUndefined(price.amount) ? errors?.prices : ''}
								/>
							</TableCell>
						</TableRow>
					))}
			</TableBody>
		</Table>
	);
}

export default observer(MeasurementsTable);
