import React from 'react';
import ErrorIcon from 'images/error-icon.svg';
import classNames from 'classnames';

export interface ErrorCardProps {
	title: string;
	subtitle: string;
	className?: string;
}

export function ErrorCard({
	title,
	subtitle,
	className,
}: ErrorCardProps) {
	return (
		<div className={classNames('error-card__wrap', className)}>
			<div className="error-card__info">
				<img
					src={ErrorIcon}
					alt="error"
				/>
			</div>
			<div className="error-card__text">
				<p className="error-card__title">
					{title}
				</p>
				<p className="error-card__subtitle">
					{subtitle}
				</p>
			</div>
		</div>
	);
}
