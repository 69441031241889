import useAsync from 'Hooks/useAsync';
import { FerryEntity, RouteEntity } from 'Models/Entities';

// eslint-disable-next-line import/prefer-default-export
export function getFerriesAndRoutes(): Promise<[FerryEntity[], RouteEntity[]]> {
	return Promise.all([
		FerryEntity.fetchAndCount<FerryEntity>().then(x => x.data),
		RouteEntity.fetchAndCount<RouteEntity>().then(x => x.data),
	]);
}

export function useFerriesAndRoutes() {
	return useAsync(() => getFerriesAndRoutes(), []);
}
