import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { store } from 'Models/Store';
import { checkPaymentNotTaken } from 'Services/Api/_HumanWritten/PaymentService';
import { onWizardSuccess } from 'Util/_HumanWritten/BookingWizard/BookingWizardUtils';
import { isNullOrUndefined } from 'Util/TypeGuards';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { Flex } from 'Views/Components/Flex/Flex';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { SecuredGroups } from 'Models/Security/UserGroups';
import { getBookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import { getFerryBookingTransactionIdFromStorage } from 'Services/Api/_HumanWritten/BookingService/BookingService';
import {
	PostPaymentRender,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/PostPayment/PostPaymentRender';

/* 15 seconds */
export const AWAITING_PAYMENT_TIMEOUT = 15000;

/* 1 second */
export const AWAITING_PAYMENT_INTERVAL = 1000;

interface AwaitEwayPaymentPageProps extends RouteComponentProps {
}

export default function AwaitEwayPaymentPage(props: AwaitEwayPaymentPageProps) {
	const bookingWizardData = getBookingWizardData(null);
	const transactionId = getFerryBookingTransactionIdFromStorage();
	const loading = React.useRef(false);
	const timeout = React.useRef<any>();
	const interval = React.useRef<any>();
	const unblock = React.useRef<any>();
	const cleanUp = () => {
		clearTimeout(timeout.current);
		clearInterval(interval.current);
		if (unblock.current) {
			unblock.current();
		}
		store.modal.hide();
	};

	const handleTimeout = () => {
		cleanUp();
		store.routerHistory.replace({
			pathname: 'booking-wizard/payment',
			// Adding search param will trigger error msg in Payment tab
			search: '?ErrorCode=AwaitingPaymentTimeout',
		});
	};

	const checkPayment = async () => {
		if (loading.current || isNullOrUndefined(transactionId) || isNullOrUndefined(bookingWizardData)) {
			// We don't want to do the request until the previous request is complete
			return;
		}

		loading.current = true;

		try {
			const res = await checkPaymentNotTaken(transactionId);
			if (!res.data) {
				cleanUp();
				onWizardSuccess(
					store,
					transactionId,
					bookingWizardData.wizardMode === 'ALTERATION',
					bookingWizardData.returningTicketId !== '',
					// TODO: events payments
					false,
					bookingWizardData.userId,
				);
			} else {
				loading.current = false;
			}
		} catch {
			cleanUp();
			store.routerHistory.replace('/booking-wizard/reservation');
		}
	};

	const beforeUnload = (event: BeforeUnloadEvent) => {
		if (event) {
			event.returnValue = 'Sure?';
		}
		return 'Sure?';
	};

	React.useEffect(() => {
		timeout.current = setTimeout(handleTimeout, AWAITING_PAYMENT_TIMEOUT);
		interval.current = setInterval(checkPayment, AWAITING_PAYMENT_INTERVAL);
		unblock.current = store.routerHistory.block(tx => {
			confirmModal('Leaving page?', 'Are you sure you want to leave?')
				.then(() => {
					unblock.current();
					store.routerHistory.push(tx.pathname);
				}).catch(() => {});

			return false;
		});

		window.addEventListener('beforeunload', beforeUnload);
		return () => {
			cleanUp();
			window.removeEventListener('beforeunload', beforeUnload);
		};
	}, []);

	if (isNullOrUndefined(transactionId) || isNullOrUndefined(bookingWizardData)) {
		return <Redirect to="/home" />;
	}

	return (
		<SecuredPage groups={SecuredGroups.create.onlyLoggedIn().groups}>
			<div className="body-content">
				<Flex className="height-100" justify="center" align="center">
					<PostPaymentRender />
				</Flex>
			</div>
		</SecuredPage>
	);
}
