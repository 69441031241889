import React from 'react';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import passengerTypeStore from 'Models/PassengerTypeStore';
import classNames from 'classnames';
import { BookingSummary } from '../../../BookingSummaryDto';

export interface BookingFormViewPassengersProps {
	summary?: BookingSummary;
}

export default function BookingFormViewPassengers({ summary }: BookingFormViewPassengersProps) {
	usePassengerTypes();

	return (
		<>
			{passengerTypeStore.passengerTypes.map(x => {
				const key = x.passengerTypeKey;
				const propertyName = passengerTypeStore.getBookingSummaryProperty(key);

				const label = passengerTypeStore.getPluralName(key);

				let value = 0;

				if (summary) {
					const count = summary[propertyName] as number | undefined;
					if (count) {
						value = count;
					}
				}

				return (
					<div key={x.id} className={classNames({ gray: value < 1 })}>
						{value}x {label}
					</div>
				);
			})}
		</>
	);
}
