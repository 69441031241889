import React from 'react';

import alertToast from 'Util/ToastifyUtils';
import { NO_CAMERA_ERROR } from '../../Constants';
import { useScannerLocation } from '../../Hooks/useScannerLocation';
import { useVideoRef } from '../../Hooks/useVideoRef';
import { useScanner } from '../../Stores/useScanner';
import { ScannerModeMap } from '../../Types/scannerMode';
import QrCodeScanner from './QrCodeScanner';
import RegoScanner from './RegoScanner';

/**
 * This component handles the video feed for the qr-code and rego scanners.
 */
export default function Scanner() {
	const { goToCheckIn } = useScannerLocation();
	const mode = useScanner(x => x.mode);
	const { videoRef, isReady } = useVideoRef({
		onSetupError() {
			alertToast(NO_CAMERA_ERROR, 'error', undefined, { autoClose: 2200 });
			goToCheckIn();
		},
	});

	return (
		<>
			<video className="check-in-scanner" muted ref={videoRef} autoPlay playsInline />
			{mode === ScannerModeMap.QrCode && <QrCodeScanner videoRef={videoRef} disabled={!isReady} />}
			{mode === ScannerModeMap.Rego && <RegoScanner videoRef={videoRef} disabled={!isReady} />}
		</>
	);
}
