import { UserEntity } from '../../Models/Entities';
import axios from 'axios';
import { SERVER_URL } from '../../Constants';

export async function userSearchFetch(searchText: string): Promise<UserEntity[]> {
	const response = await axios.get(
		`${SERVER_URL}/api/entity/UserEntity/search?searchText=${searchText}`,
		{
			validateStatus: () => true,
		},
	);

	return response.data;
}
