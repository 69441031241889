import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RedirectLoader } from 'Views/Components/_HumanWritten/RedirectLoader/RedirectLoader';

function ChangeEmailPage(props: RouteComponentProps) {
	return (
		<RedirectLoader
			to="/ferry-schedule"
			title="Your email has been verified!"
			subtitle="Navigating to ferry schedule page..."
		/>
	);
}

export default ChangeEmailPage;
