import React, { useEffect } from 'react';
import { AsyncComboboxProps, Combobox } from 'Views/Components/Combobox/Combobox';
import { Divider, DropdownProps } from 'semantic-ui-react';
import { UserEntity } from 'Models/Entities';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { store } from 'Models/Store';
import { getComboboxOptions } from 'Util/ComboboxUtils';
import { observer } from 'mobx-react';
import { CustomerSelectionLocationState } from 'Models/_HumanWritten/LocationState';
import { searchCustomersByNameEmailPhone } from 'Views/Components/_HumanWritten/CustomerSelection/CustomerSelectionUtils';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { isNotNullOrUndefined } from 'Util/TypeGuards';
import If from 'Views/Components/If/If';
import { UserDisabledTag, UserDisplayNameTag } from 'Views/Components/_HumanWritten/UserDisplayNameTag';
import { BookingWizardData } from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';
import CustomerSelection, { customerDetailsRow } from './CustomerSelection';
import { EventBookingWizardData } from '../EventsBookingWizard/EventsBookingWizardData';

export interface EventCustomerSelectionProps {
	model: any;
	modelProperty: string;
	/**
	 * The url to return to when completing the register new customer flow.
	 */
	backUrl: string;
	combobox?: Partial<AsyncComboboxProps<any, string>>;
	onSelect?: (customer: UserEntity) => void;
	disabled: boolean;
	wizardData?: EventBookingWizardData;
	onUpdateData?: (wizardData: EventBookingWizardData) => void;
}

function EventCustomerSelection({
	model,
	modelProperty,
	backUrl,
	combobox = {},
	onSelect,
	disabled,
	wizardData,
	onUpdateData,
}: EventCustomerSelectionProps) {
	const customerResults = React.useRef<UserEntity[]>([]);
	const [customer, setCustomer] = React.useState<UserEntity | undefined>();
	const regoNumbers = customer?.latestVehicles?.map(x => x.cargoIdentification);

	useEffect(() => {
		if (isNotNullOrUndefined(wizardData)
			&& isNotNullOrUndefined(onUpdateData)
			&& isNotNullOrUndefined(customer)) {
			const newData = { ...wizardData };
			newData.firstName = customer?.firstName ?? '';
			newData.lastName = customer?.lastName ?? '';
			newData.phone = customer?.phone ?? '';
			newData.email = customer?.email ?? '';
			newData.userId = customer?.id ?? '';
			onUpdateData(newData);
		}
	}, [customer]);

	/**
	 * Returns the combobox options for the asynchronous combobox.
	 *
	 * The results of query will be stored so we can find and use the selected customer details for display.
	 */
	const searchFunction = React.useCallback(async (input: string, id?: string) => {
		const results = await searchCustomersByNameEmailPhone(input, id);
		customerResults.current = results;

		return getComboboxOptions<UserEntity, string>(
			results,
			(x: UserEntity) => `${x.firstName} ${x.lastName}`,
			'id',
			'disabled',
			(x: UserEntity) => `${x.email}${x.disabled ? ' - DEACTIVATED ACCOUNT' : ''}`,
		);
	}, []);

	/**
	 * On mount, we want to display customer details when model[modelProperty] already has a value.
	 */
	React.useEffect(() => {
		const initialResults = async () => {
			if (!!!model[modelProperty]) {
				// No value stored
				return;
			}
			if (customerResults.current.length === 0) {
				customerResults.current = await searchCustomersByNameEmailPhone('', model[modelProperty]);
			}
			const match = customerResults.current.find(x => x.id === model[modelProperty]);
			if (match) {
				setCustomer(match);
				if (onSelect) {
					onSelect(match);
				}
			}
		};

		if (model[modelProperty]) {
			initialResults();
		}
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedComboboxOptions = React.useCallback(AwesomeDebouncePromise(searchFunction, 300), []);

	const onAfterChange = (e: React.SyntheticEvent<HTMLElement, Event>, d: DropdownProps) => {
		const match = customerResults.current.find(x => x.id === model[modelProperty]);
		if (match) {
			setCustomer(match);
			if (onSelect) {
				onSelect(match);
			}
		}
		if (combobox.onAfterChange) {
			combobox.onAfterChange(e, d);
		}
	};

	return (
		<>
			<h4>{disabled ? 'Selected customer' : 'Existing customers'}</h4>
			<Combobox
				{...combobox}
				model={model}
				modelProperty={modelProperty}
				options={debouncedComboboxOptions}
				initialOptions={() => searchFunction('', model[modelProperty])}
				label="Customer name"
				placeholder="Search for name or email"
				isClearable={false}
				onAfterChange={onAfterChange}
				isDisabled={disabled}
			/>
			{
				customer && (
					<div className="customer-selection__details">
						<p className="customer-selection__details-title">
							{/* eslint-disable-next-line max-len */}
							Customer details {UserDisplayNameTag(customer?.userDisplayName?.displayName)} {customer.disabled && UserDisabledTag()}
						</p>
						{customerDetailsRow('Name', [`${customer.firstName} ${customer.lastName}`])}
						{customerDetailsRow('Mobile', [`${customer.phone}`])}
						{customerDetailsRow('Email', [`${customer.email}`])}
						{regoNumbers
							&& regoNumbers.length > 0
							&& whiteLabelStore.config.customerRegoDisplayEnabled
							&& customerDetailsRow('Rego', regoNumbers)}
					</div>
				)
			}

			<Divider className="customer-selection__divider" />

			<If condition={!disabled}>
				<h4 className="customer-selection__new-customer">
					New customer
				</h4>
				<Button
					className="customer-selection__new-customer-btn"
					display={Display.Solid}
					sizes={Sizes.Medium}
					colors={Colors.Alternate}
					onClick={() => {
						const locationState: CustomerSelectionLocationState = { backUrl: backUrl };
						store.routerHistory.push('/register-customer', locationState);
					}}
				>
					Create account
				</Button>
			</If>
		</>
	);
}

export default observer(EventCustomerSelection);
