import * as React from 'react';
import { EventDetailEntity } from 'Models/Entities';
import { useEffect, useState } from 'react';
import { CommunicationTabContactsCount } from 'Models/Entities/FerryTripEntity';
import { getTripContactCount } from 'Services/Api/_HumanWritten/FerryTripEntityService';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { TextArea } from 'Views/Components/TextArea/TextArea';
import { runInAction } from 'mobx';
import { DisplayType } from 'Views/Components/Models/Enums';
import { Checkbox } from 'Views/Components/Checkbox/Checkbox';
import { isNotNullOrUndefined } from '../../../../../Util/TypeGuards';

export interface EventCommunicationTabProps {
	eventDetails: EventDetailEntity;
	model: {
		subject: string | null;
		message: string,
		sendSms: boolean,
		sendEmail: boolean,
		extraPhone: string,
	},
	isDisabled: boolean,
}

export const EventCommunicationTabComponent = ({
	eventDetails,
	model,
	isDisabled,
}: EventCommunicationTabProps) => {
	const [smsCount, setSmsCount] = useState<number>(1);
	const [tripContactsCount, setTripContactsCount] = useState<CommunicationTabContactsCount>({
		mobileNumberCount: 0,
		emailAddressesCount: 0,
	});

	useEffect(() => {
		setSmsCount(Math.max(Math.ceil(model.message.length / 153), 1));
	}, [model.message]);

	useEffect(() => {
		if (isNotNullOrUndefined(eventDetails.ferryTrip?.id)) {
			getTripContactCount(eventDetails.ferryTrip!.id).then(x => {
				setTripContactsCount(x as CommunicationTabContactsCount);
				if (!(tripContactsCount?.emailAddressesCount > 0)) {
					runInAction(() => {
						model.sendEmail = false;
					});
				}
			});
		}
	}, []);

	return (
		<div className="custom-sidebar__tab-content communication-tab">
			<h6>Send message</h6>
			<p className="message-input-heading">Message subject <span className="red">*</span></p>
			<TextField
				id="message-subject-textfield"
				model={model}
				modelProperty="subject"
				labelVisible={false}
				placeholder="Enter email subject"
				className="email-subject-input"
				isDisabled={isDisabled}
			/>
			<p className="message-input-heading">Message to everyone on the trip <span className="red">*</span></p>
			<TextArea
				className="communication-message-textarea"
				id="communication-message-textarea"
				model={model}
				modelProperty="message"
				placeholder="Enter message to send"
				isRequired
				isDisabled={isDisabled}
			/>
			<p className="sms-count">{smsCount} credit(s)</p>
			<p className="communication-method-heading">
				Select communication method <span className="red">*</span>
			</p>
			<Checkbox
				id="send-sms-input"
				className="send-sms-input"
				model={model}
				modelProperty="sendSms"
				displayType={DisplayType.BLOCK}
				label={`Send SMS (${tripContactsCount?.mobileNumberCount ?? '?'})`}
				isDisabled={isDisabled || !(tripContactsCount?.mobileNumberCount > 0)}
			/>
			<Checkbox
				id="send-email-input"
				className="send-email-input"
				model={model}
				modelProperty="sendEmail"
				displayType={DisplayType.BLOCK}
				label={`Send Email (${tripContactsCount?.emailAddressesCount ?? '?'})`}
				isDisabled={isDisabled || !(tripContactsCount?.emailAddressesCount > 0)}
			/>
		</div>
	);
};
