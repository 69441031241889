import React from 'react';
import { whiteLabelStore } from '../../../Models/WhiteLabelStore';
import { stringNotEmpty } from '../../../Util/TypeGuards';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

const MarketingBanner = () => {
	const { bannerText, showBanner } = whiteLabelStore.config;
	const { isMobile } = useDeviceDetect();

	if (showBanner && stringNotEmpty(bannerText) && !isMobile) {
		return (
			<div className="marketing-banner-container">
				{/* eslint-disable-next-line react/no-danger */}
				<div dangerouslySetInnerHTML={{ __html: bannerText }} />
			</div>
		);
	}
	return <></>;
};

export default MarketingBanner;
