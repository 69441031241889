import { useEffect, useRef } from 'react';

/**
 * Instantiate an AbortController Ref with auto clean up when component unmounts.
 *
 * @returns A ref to store a AbortController.
 */
export const useAbortController = () => {
	const abortControllerRef = useRef<AbortController>(new AbortController());

	useEffect(() => {
		return () => {
			abortControllerRef.current.abort();
		};
	}, []);

	return abortControllerRef;
};
