import React from 'react';
import { observer } from 'mobx-react';
import { UserEntity } from 'Models/Entities';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { TableCell, TableRow } from 'semantic-ui-react';
import { NumberTextField } from 'Views/Components/NumberTextBox/NumberTextBox';

interface FixedPriceUserMeasurementPricesProps {
	invoicedUser: UserEntity;
	mode: EntityFormMode;
	isReadOnly?: boolean;
}

function MeasurementPriceInputs({
	mode,
	invoicedUser,
	isReadOnly = false,
}: FixedPriceUserMeasurementPricesProps) {
	return (
		<>
			{invoicedUser.prices
				.slice()
				.sort((value1, value2) => (value1.measurement.value > value2.measurement.value ? 1 : -1))
				.map(price => (
					<TableRow key={price.id}>
						<TableCell textAlign="left">{price.measurement.label}</TableCell>
						<TableCell textAlign="left">{price.measurement.value}</TableCell>
						<TableCell textAlign="left">
							<NumberTextField
								className="price-input-field icon-money icon-left"
								id={price.id}
								model={price}
								modelProperty="amount"
								placeholder="0"
							/>
						</TableCell>
					</TableRow>
				))}
		</>
	);
}

export default observer(MeasurementPriceInputs);
