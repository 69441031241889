/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AdminCustomTicketTypeEntity from 'Models/Security/Acl/AdminCustomTicketTypeEntity';
import ManagerCustomTicketTypeEntity from 'Models/Security/Acl/ManagerCustomTicketTypeEntity';
import StaffCustomTicketTypeEntity from 'Models/Security/Acl/StaffCustomTicketTypeEntity';
import UserCustomTicketTypeEntity from 'Models/Security/Acl/UserCustomTicketTypeEntity';
import InvoicedUserCustomTicketTypeEntity from 'Models/Security/Acl/InvoicedUserCustomTicketTypeEntity';
import FixedPriceUserCustomTicketTypeEntity from 'Models/Security/Acl/FixedPriceUserCustomTicketTypeEntity';
import VisitorsCustomTicketTypeEntity from 'Models/Security/Acl/VisitorsCustomTicketTypeEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICustomTicketTypeEntityAttributes extends IModelAttributes {
	singularName: string;
	pluralName: string;
	price: number;
	maxCapacity: number;
	quantity: number;
	description: string;

	eventDetailId?: string;
	eventDetail?: Models.EventDetailEntity | Models.IEventDetailEntityAttributes;
	tickets: Array<
		| Models.TicketEntity
		| Models.ITicketEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CustomTicketTypeEntity', 'Custom Ticket Type')
// % protected region % [Customise your entity metadata here] end
export default class CustomTicketTypeEntity extends Model
	implements ICustomTicketTypeEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AdminCustomTicketTypeEntity(),
		new ManagerCustomTicketTypeEntity(),
		new StaffCustomTicketTypeEntity(),
		new UserCustomTicketTypeEntity(),
		new InvoicedUserCustomTicketTypeEntity(),
		new FixedPriceUserCustomTicketTypeEntity(),
		new VisitorsCustomTicketTypeEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Singular Name'] on begin
	/**
	 * The name that will display for this passenger ticket pricing when only 1 ticket is selected.
	 */
	@Validators.Required()
	@observable
	@attribute<CustomTicketTypeEntity, string>()
	@CRUD({
		name: 'Singular name',
		description: 'The name that will display for this custom ticket pricing when only 1 ticket is selected.',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public singularName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Singular Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Plural Name'] on begin
	/**
	 * The plural name that will appear for this passenger ticket type.
	 */
	@Validators.Required()
	@observable
	@attribute<CustomTicketTypeEntity, string>()
	@CRUD({
		name: 'Plural name',
		description: 'The plural name that will appear for custom ticket pricing when more than 1 ticket is selected.',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public pluralName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Plural Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Price'] on begin
	/**
	 * The price of this passenger ticket type.
	 */
	@Validators.Required()
	@Validators.Numeric()
	@observable
	@attribute<CustomTicketTypeEntity, number>()
	@CRUD({
		name: 'Price',
		description: 'The price of the custom ticket.',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public price: number;
	// % protected region % [Modify props to the crud options here for attribute 'Price'] end

	// % protected region % [Modify props to the crud options here for attribute 'Max Capacity'] on begin
	/**
	 * The quantity of passenger spaces this ticket type will take up.
	 */
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<CustomTicketTypeEntity, number>()
	@CRUD({
		name: 'Max capacity',
		description: 'The maximum quantity of passenger spaces this ticket type will take up (if there is a limited number of tickets).',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public maxCapacity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Max Capacity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] on begin
	@Validators.Required()
	@Validators.Integer()
	@observable
	@attribute<CustomTicketTypeEntity, number>()
	@CRUD({
		name: 'Quantity',
		description: 'The quantity of passenger spaces this ticket type will take up per ticket.',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public quantity: number;
	// % protected region % [Modify props to the crud options here for attribute 'Quantity'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] on begin
	/**
	 * A description of the ticket type.
	 */
	@Validators.Required()
	@observable
	@attribute<CustomTicketTypeEntity, string>()
	@CRUD({
		name: 'Description',
		description: 'A description of the custom ticket that will be shown to customers.',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] on begin
		name: 'Event Detail',
		displayType: 'hidden',
		order: 70,
		referenceTypeFunc: () => Models.EventDetailEntity,
		// % protected region % [Modify props to the crud options here for reference 'Event Detail'] end
	})
	public eventDetailId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public eventDetail: Models.EventDetailEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] on begin
		name: 'Tickets',
		displayType: 'hidden',
		order: 80,
		referenceTypeFunc: () => Models.TicketEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'tickets',
			oppositeEntity: () => Models.TicketEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Ticket'] end
	})
	public tickets: Models.TicketEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICustomTicketTypeEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICustomTicketTypeEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.singularName !== undefined) {
				this.singularName = attributes.singularName;
			}
			if (attributes.pluralName !== undefined) {
				this.pluralName = attributes.pluralName;
			}
			if (attributes.price !== undefined) {
				this.price = attributes.price;
			}
			if (attributes.maxCapacity !== undefined) {
				this.maxCapacity = attributes.maxCapacity;
			}
			if (attributes.quantity !== undefined) {
				this.quantity = attributes.quantity;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.eventDetailId !== undefined) {
				this.eventDetailId = attributes.eventDetailId;
			}
			if (attributes.eventDetail !== undefined) {
				if (attributes.eventDetail === null) {
					this.eventDetail = attributes.eventDetail;
				} else if (attributes.eventDetail instanceof Models.EventDetailEntity) {
					this.eventDetail = attributes.eventDetail;
					this.eventDetailId = attributes.eventDetail.id;
				} else {
					this.eventDetail = new Models.EventDetailEntity(attributes.eventDetail);
					this.eventDetailId = this.eventDetail.id;
				}
			}
			if (attributes.tickets !== undefined && Array.isArray(attributes.tickets)) {
				for (const model of attributes.tickets) {
					if (model instanceof Models.TicketEntity) {
						this.tickets.push(model);
					} else {
						this.tickets.push(new Models.TicketEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		eventDetail {
			${Models.EventDetailEntity.getAttributes().join('\n')}
		}
		tickets {
			${Models.TicketEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			tickets: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'tickets',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CustomTicketTypeEntity.prototype, 'created');
CRUD(modifiedAttr)(CustomTicketTypeEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
