import * as React from 'react';
import { DualActionModalContent, DualActionModalContentProps } from './Base/DualActionModalContent';
import { showModal } from './Base/BaseModalContents';
import { BookingSummaryAddon } from '../BookingSummaryDto';
import { CheckInBookingOverviewDto } from '../CheckIn/FerryCheckIn/CheckInEntities/CheckInBookingOverviewDto';
import { GetModalContentForCheckIn } from '../CheckIn/FerryCheckIn/CheckInList/CheckInListRow';

export interface CheckInScanConfirmModalContentsProps extends Pick<DualActionModalContentProps, 'onConfirm'> {
	booking?: CheckInBookingOverviewDto;
	addOnOptions?: BookingSummaryAddon[];
}

export function CheckInScanConfirmModalContents({
	onConfirm,
	booking,
	addOnOptions,
}: CheckInScanConfirmModalContentsProps) {
	const title = 'Confirm booking?';

	const body = (
		<div>
			{GetModalContentForCheckIn(booking)}
		</div>
	);

	return (
		<DualActionModalContent
			title={title}
			body={body}
			onConfirm={onConfirm}
			fixedCancelWidth
			autoClose
		/>
	);
}

export function showCheckInScanConfirm(props: CheckInScanConfirmModalContentsProps) {
	showModal({
		key: 'check-in-scan-confirm-booking',
		content: <CheckInScanConfirmModalContents {...props} />,
	});
}
