import React from 'react';
import { FerryTripEntity } from 'Models/Entities';
import alertToast from 'Util/ToastifyUtils';
import {
	showDeleteFerryTripModal,
} from 'Views/Components/_HumanWritten/Modal/DeleteFerryTripModal/DeleteFerryTripModal';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from 'Views/Components/Button/Button';

export interface FerryTripDeleteBtnProps {
	ferryTrip: FerryTripEntity;
	hidePanel: (confirm: boolean, needRefetch: boolean) => void;
}

function FerryTripDeleteBtn({ ferryTrip, hidePanel }: FerryTripDeleteBtnProps) {
	if (ferryTrip.departureDateTime <= new Date()) {
		return null;
	}

	const handleDelete = () => {
		showDeleteFerryTripModal({
			onConfirm: () => {
				hidePanel(false, true);
				return alertToast(
					'You have successfully deleted the ferry trip',
					'success',
					'Ferry trip deleted!',
				);
			},
			ferryTripId: ferryTrip.id,
		});
	};

	return (
		<Button
			className="cancel_edit_add_ferry_trip"
			display={Display.Solid}
			colors={Colors.Red}
			sizes={Sizes.Medium}
			buttonProps={{ id: 'cancel_edit_add_ferry_trip' }}
			onClick={handleDelete}
		>
			Delete trip
		</Button>
	);
}

export default FerryTripDeleteBtn;
