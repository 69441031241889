import React from 'react';
import { RouteEntity } from 'Models/Entities';
import { tripDirectionTypes } from 'Models/Enums';

export function RouteName({ route, direction }: { route: RouteEntity, direction: tripDirectionTypes }) {
	const routeName = direction === 'DEPARTURE'
		? `${route.departure.name} to ${route.destination.name}`
		: `${route.destination.name} to ${route.departure.name}`;

	return (
		<h5>
			{routeName}
		</h5>
	);
}
