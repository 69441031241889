import React from 'react';
import * as uuid from 'uuid';
import { wizardModeOptions } from 'Models/Enums';
import { formatPriceDisplay } from 'Util/_HumanWritten/PriceFormattingUtils';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import { AdditionalItemLineItem } from './AdditionalItemLineItem';
import { BookingPricingResult } from 'Services/Api/_HumanWritten/PricingService/PricingResult';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import {
	TripBreakdownDescription,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/WizardSteps/Cart/TripBreakdownDescription';
import {
	isNullOrUndefined,
	stringIsEmpty,
} from 'Util/TypeGuards';
import {
	BookingWizardCartFields,
} from 'Views/Components/_HumanWritten/FerryTripBookingWizard/BookingWizardData';

export interface TripBreakdownProps {
	cartData: BookingWizardCartFields;
	departureTrip: boolean;
	bookingInfo?: BookingPricingResult;
}

export function BookingBreakdown({
	cartData,
	departureTrip,
	bookingInfo,
}: TripBreakdownProps) {
	const trip = departureTrip
		? cartData.selectedTrips.departingTrip
		: cartData.selectedTrips.returningTrip;

	if (isNullOrUndefined(trip) || stringIsEmpty(trip.id)) {
		return <LottieSpinner />;
	}

	return (
		<div key={uuid.v4()} className={`${departureTrip ? 'departing' : 'return'} trip-card`}>
			<p className="route-name">
				{trip?.getDepartureName()}
				<Icon name="arrow-right" />
				{trip?.getDestinationName()}
			</p>
			<div className="trip-details-overview">
				<div className="trip-details-breakdown">
					<p className="trip-details__date">
						<Icon name="calendar" />
						{trip?.getTripDateTimeFormatted()}
					</p>
					<p className="booked-trip-passenger-details">
						<TripBreakdownDescription bookingInfo={bookingInfo} />
					</p>
				</div>
				<p className="base-total-cost">
					{formatPriceDisplay(
						bookingInfo?.priceLineItems
							.filter(x => x.key === 'Passenger'
								|| x.key === 'BaseCargo'
								|| x.key === 'BaseTowOn'
								|| x.key === 'AlterationFee')
							?.map(x => x.price)
							?.reduce((a, b) => a + b, 0) ?? 0,
						cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
				</p>
			</div>
			<div className="additional-prices__container">
				{bookingInfo?.priceLineItems
					.filter(x => x.key !== 'Passenger'
						&& x.key !== 'BaseCargo'
						&& x.key !== 'BaseTowOn'
						&& x.key !== 'AlterationFee'
						&& x.key !== 'TowOnCancellationFee'
						&& x.key !== 'TicketPriceIncrease')
					.map(lineItem => {
						return (
							<AdditionalItemLineItem
								lineItem={lineItem}
								isAlteration={cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION}
							/>
						);
					})}
			</div>
			<div className="direction-total-cost">
				<p>{departureTrip ? 'Departing total' : 'Returning total'}</p>
				<p>{formatPriceDisplay(
					bookingInfo?.totalPrice ?? 0,
					cartData.wizardData.wizardMode === wizardModeOptions.ALTERATION)}
				</p>
			</div>
		</div>
	);
}
